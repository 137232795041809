import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Close, Menu} from '@material-ui/icons';

import Content from '../components/app/Content';
import AppHeader from '../components/app/Header';
import AppSplash from '../components/layout/AppSplash';
import AppNavigation from '../components/app/Navigation';
import AppCourse from '../pages/App/Course';
import AppExercise from '../pages/App/Exercise';
import AppLearn from '../pages/App/Learn';
import AppSelectCourse from '../pages/App/SelectCourse';
import AppUser from '../pages/App/User';
import {
    appSelector,
    fetchCurrentCourse,
    fetchCurrentCourseSubareas,
    setCourse,
    setCourseId,
    setCustomFontSize,
} from '../store/app/appSlice';
import PreviewLightbox from '../components/authoring/Preview/PreviewLightbox';
import FinishedCourseScreen from '../components/app/FinishedCourseScreen';
import axios from '../app/axios';
import {isPlatform} from '@ionic/core';
import LoadingOverlay from '../components/shared/LoadingOverlay';
import FontSizeToggle from '../components/app/FontSizeToggle';
import NoLicenseModal from '../components/app/selectCourse/NoLicenseModal';

const AppLayout: React.FunctionComponent<{}> = ({children}) => {
    const [params, setParams] = useState<string>('');
    const {page, subpage} = useParams<{ page: string; subpage?: string }>();
    const [showSplash, setShowSplash] = useState(
        isPlatform('desktop') ? true : false,
    );
    const location = useLocation();
    const {
        currentCourse,
        currentCourseSubareas,
        currentCourseId,
        showCourseFinished,
        isFetchingCourseForApp,
    } = useSelector(appSelector);
    const history = useHistory();
    const dispatch = useDispatch();

    const [licenseMissing, setLicenseMissing] = useState(false);
    const [initialFetching, setInitialFetching] = useState(true);
    const [lightboxSrc, setLightboxSrc] = useState('');

    useEffect(() => {
        document
            .querySelectorAll('.app-content-general img:not(.header)')
            .forEach((element) => {
                if (!element.closest('.preview-lightbox-wrapper')) {
                    const wrapper = document.createElement('div');
                    wrapper.setAttribute(
                        'src',
                        element.getAttribute('src') || '',
                    );
                    wrapper.classList.add('preview-lightbox-wrapper');
                    element.parentNode!.appendChild(wrapper);
                    wrapper.appendChild(element);
                }
            });
        document
            .querySelectorAll('.preview-lightbox-wrapper')
            .forEach((el) =>
                el.addEventListener('click', addLightboxFunctionality),
            );
        return () => {
            document
                .querySelectorAll('.preview-lightbox-wrapper')
                .forEach((el) =>
                    el.removeEventListener('click', addLightboxFunctionality),
                );
        };
    }, [params]);

    useEffect(() => {
        const initialNavigationParam = new URLSearchParams(location.search).get(
            'navigation',
        );
        const initialContentParam = new URLSearchParams(location.search).get(
            'content',
        );
        const newParams: { [index: string]: number } = {
            navigation:
                initialNavigationParam === null ? 0 : +initialNavigationParam,
            content: initialContentParam === null ? -1 : +initialContentParam,
        };
        let newParamsString = '?';
        Object.keys(newParams).forEach(
            (key: string) => (newParamsString += `${key}=${newParams[key]}&`),
        );
        setParams(new URLSearchParams(newParamsString).toString());
    }, []);

    useEffect(() => {
        const selectedCourse = !!window.localStorage.getItem('current_course')
            ? JSON.parse(window.localStorage.getItem('current_course')!)
            : null;

        const courseId = selectedCourse ? selectedCourse.id : null;

        if (courseId) {
            axios
                .get(`/app/courses/${courseId}/rights`)
                .then(function (response: any) {
                    if (!response.data.success) {
                        localStorage.setItem('current_course', '');
                        window.location.href =
                            '/app/select-course?navigation=0&content=-1';
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }, []);

    useEffect(() => {
        document.querySelector('body')?.classList.add('app-root');
        if (process.env.REACT_APP_COURSE_ID)
            document.querySelector('body')?.classList.add('appucations-app');
        const customFontSize = window.localStorage.getItem('custom_font_size');
        if (customFontSize) dispatch(setCustomFontSize(+customFontSize));
        // update queue results
        const results_queue = !!window.localStorage.getItem('results_queue')
            ? JSON.parse(window.localStorage.getItem('results_queue')!)
            : null;
        if (results_queue) {
            results_queue.forEach(async (que: any, key: number) => {
                try {
                    const res = await axios.post('/results', que);
                    if (key === results_queue.length - 1) {
                        localStorage.setItem('results_queue', '');
                    }
                } catch (err) {
                    console.log('Can not push result from queue');
                }
            });
        }
        return () => {
            document.querySelector('body')?.classList.remove('app-root');
            document.querySelector('body')?.classList.remove('appucations-app');
        };
    }, []);

    useEffect(() => {
        if (!currentCourse && !isFetchingCourseForApp) {
            const currentCourse = !!window.localStorage.getItem(
                'current_course',
            )
                ? JSON.parse(window.localStorage.getItem('current_course')!)
                : null;
            const currentCourseId =
                window.localStorage.getItem('current_course_id');
            if (!!currentCourse) dispatch(setCourse(currentCourse));
            if (!!currentCourseId) dispatch(setCourseId(+currentCourseId));
            if (!currentCourse || !currentCourseId) {
                if (process.env.REACT_APP_COURSE_ID) {
                    fetchSpecificCourseForApp();
                } else {
                    setInitialFetching(false);
                    handleNavigate('/app/select-course', {
                        navigation: '0',
                        content: '-1',
                    });
                }
            }
        }
    }, [currentCourse]);

    console.log(process.env.REACT_APP_COURSE_ID);

    useEffect(() => {
        if (!currentCourseSubareas && isFetchingCourseForApp && currentCourse) {
            setInitialFetching(false);
            dispatch({
                ...fetchCurrentCourseSubareas({
                    requestType: 'GET',
                    request: `/smartometer/courses/by-assignment/${currentCourseId}/subarea`,
                }),
            });
        }
    }, [currentCourse, currentCourseSubareas, isFetchingCourseForApp]);

    useEffect(() => {
        if (
            !currentCourseSubareas &&
            !isFetchingCourseForApp &&
            currentCourse
        ) {
            setInitialFetching(false);
            dispatch({
                ...fetchCurrentCourseSubareas({
                    requestType: 'GET',
                    request: `/smartometer/courses/by-assignment/${currentCourseId}/subarea`,
                }),
            });
        } else if (!!currentCourse && !!currentCourseSubareas) {
            setInitialFetching(false);
        }
    }, [currentCourse, currentCourseSubareas]);

    useEffect(() => {
        if (params.length) {
            history.replace({pathname: location.pathname, search: params});
        }
    }, [params]);

    const addLightboxFunctionality = (e: Event) => {
        const target = e.target as HTMLElement;
        setLightboxSrc(target.getAttribute('src') || '');
    };

    const handleNavigateToTab = (tab: string) => {
        if (tab === page) return;
        history.replace({pathname: `/app/${tab}`, search: params});
    };

    const updateParams = (param: string, value: string) => {
        const newParams = new URLSearchParams(params);
        newParams.set(param, value);
        setParams(newParams.toString());
    };

    const paramSetter = (param: string, fallback: number) => {
        const locationParams = new URLSearchParams(location.search);
        const stateParams = new URLSearchParams(params);
        if (stateParams.get(param)) {
            return +stateParams.get(param)!;
        } else if (locationParams.get(param)) {
            return +locationParams.get(param)!;
        } else return fallback;
    };

    const renderChildComponent = () => {
        switch (page) {
            case 'learn':
                return (
                    currentCourseSubareas && (
                        <AppLearn
                            handleNavigate={handleNavigate}
                            subareas={currentCourseSubareas}
                            contentId={contentId}
                        />
                    )
                );
            case 'exercise':
                return (
                    <AppExercise
                        handleNavigate={handleNavigate}
                        subareas={currentCourseSubareas}
                        contentId={contentId}
                    />
                );
            case 'user':
                return <AppUser/>;
            case 'course':
                return <AppCourse handleNavigate={handleNavigate}/>;
        }
    };

    const handleNavigate = (
        path: string | null,
        optParams?: { [type: string]: string },
    ) => {
        const newRoute: any = {
            pathname: path || location.pathname,
            search: location.search,
        };
        let newParams: any = null;
        if (optParams) {
            newParams = new URLSearchParams(params);
            Object.keys(optParams).map((key: string) =>
                newParams.set(key, optParams[key]),
            );
            setParams(newParams.toString());
            newRoute.search = newParams.toString();
        }
        history.replace(newRoute);
    };

    const showNavigation = paramSetter('navigation', 0);
    const contentId = paramSetter('content', -1);

    const fetchSpecificCourseForApp = async () => {
        const specificCourse = Number(process.env.REACT_APP_COURSE_ID);
        const res = await axios.get(`/app/courses`);
        const courses = res?.data?.success || null;
        if (courses && courses.length) {
            const usersCourse = courses.find(
                (course: { id: number; product_id: number }) =>
                    course.product_id === specificCourse,
            );
            if (usersCourse) {
                const {id} = usersCourse;
                await axios.put(`/app/courses/${id}`);
                dispatch(setCourseId(id));
                dispatch({
                    ...fetchCurrentCourse({
                        requestType: 'GET',
                        request: `/smartometer/courses/by-assignment/${id}?all=true`,
                    }),
                });
            } else setLicenseMissing(true);
        } else setLicenseMissing(true);
    };

    return (
        <div
            className={`app-wrapper ${
                process.env.REACT_APP_COURSE_ID ? 'app-wrapper-appucations' : ''
            }`}
        >
            {licenseMissing && <NoLicenseModal/>}
            {(isFetchingCourseForApp || initialFetching) && (
                <LoadingOverlay opaque isFetching={true}/>
            )}
            {showCourseFinished && (
                <FinishedCourseScreen
                    course={currentCourse}
                    courseId={currentCourseId}
                    handleNavigate={handleNavigate}
                />
            )}
            {currentCourse && currentCourseSubareas && (
                <Content
                    courseId={currentCourse.id}
                    subareas={currentCourseSubareas}
                    handleNavigate={handleNavigate}
                    contentId={contentId}
                    showNavigation={!!showNavigation}
                    handleToggleNavigation={() =>
                        updateParams('navigation', '1')
                    }
                />
            )}
            <div
                className={`app-navigation ${
                    showNavigation ? 'app-navigation-opened' : ''
                }
				${
                    showNavigation &&
                    page === 'course' &&
                    subpage === 'statistics'
                        ? 'app-navigation-statistics'
                        : ''
                }
				`}
            >
                <AppHeader
                    contentId={contentId}
                    handleNavigate={handleNavigate}
                    currentCourseSubareas={currentCourseSubareas}
                />
                {renderChildComponent()}
                <AppNavigation handleNavigate={handleNavigateToTab}/>
                <div
                    className="preview-window-navigation-desktop-toggle"
                    onClick={() =>
                        handleNavigate(null, {
                            navigation: showNavigation ? '0' : '1',
                        })
                    }
                >
                    {showNavigation ? <Close/> : <Menu/>}
                </div>
            </div>
            <div className="desktop-font-toggle">
                <FontSizeToggle/>
            </div>
            {page === 'select-course' && (
                <AppSelectCourse handleNavigate={handleNavigate}/>
            )}
            {lightboxSrc !== '' && (
                <PreviewLightbox
                    handleClose={() => setLightboxSrc('')}
                    src={lightboxSrc}
                />
            )}
            {showSplash && <AppSplash setShow={setShowSplash}/>}
        </div>
    );
};

export default AppLayout;
