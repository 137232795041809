import React from 'react';

interface PreviewLightboxProps {
	handleClose: React.MouseEventHandler;
	src: string;
}

const PreviewLightbox: React.FunctionComponent<PreviewLightboxProps> = ({
	handleClose,
	src,
}) => {
	return (
		<div className="preview-lightbox" onClick={handleClose}>
			<img src={src} />
		</div>
	);
};

export default PreviewLightbox;
