import { makeStyles } from "@material-ui/core/styles";
import {textSizes, color} from "../../../utils/styles"

export const useStyles = makeStyles(theme => ({
    Input: {
        // height: 24,
        width: "100%",
        position: "relative",
        paddingBottom: "20px",

        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "0"
        },
        // "& .MuiInputBase-input": {
        //     height: 20,
        // }
    },
    errorList: {
        color: 'red',
        paddingBottom: 5
    },
    Select: {
        height: 44,
        fontSize: textSizes.bodyNormal,
        width: "calc(50% - 12.5px) !important",
        marginBottom: "5px",
        marginTop: "4px",
        borderColor: color.borderGrey,
        "& .dropdown.icon": {
            top: "12px !important",
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    Label: {
        width: "100%",
        fontSize: textSizes.bodySmall,
        color: color.textDark,

    },
    subareaTitle: {
        fontSize: 14,
        textAlign: 'left',
        color: '#9FA2B4',
        marginTop: '10px !important',
        marginBottom: '0px !important'
    },
    header: {
        fontSize: 24,
        textAlign: 'left',
        paddingBottom: 24
    },
    searchBar: {
        width: "100%",
        paddingBottom: 24
    },
    paragraph: {
        fontSize: textSizes.bodyLarge,
        textAlign: 'center',
    },
    MainButtonWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: 'space-around',
        "& button:first-child": {
            marginRight: "10px",
        },
        "& button:last-child": {
            marginRight: "0 !important",
        }
    },
}))
