import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { NavLink, useParams } from 'react-router-dom';
import { useStyles } from './styles';
import {translate} from '../../../i18n/i18n';

interface AppNavigationProps {
	handleNavigate: Function;
}

const AppNavigation: React.FunctionComponent<AppNavigationProps> = ({
	handleNavigate,
}) => {
	const { page } = useParams<{ page: string }>();
	const avatarImage =
		window.localStorage.getItem('avatar') !== 'null'
			? window.localStorage.getItem('avatar')!
			: '/defaultUser.png';
	const classes = useStyles();
	return (
		<div className={classes.Navigation + ' preview-window-navigation-tabs'}>
			<a
				onClick={() => handleNavigate('learn')}
				className={page === 'learn' ? 'active-nav-link' : ''}
			>
				<span className={`preview-window-navigation-tabs-tab`}>
					<SvgIcon>
						<path
							id="learnicon"
							d="M18.666,20.957l-12-6.763,12-6.763,12,6.763Zm7.794,2.471a7.8,7.8,0,0,1-15.6,0V18.96l7.8,4.4,7.8-4.4v4.468Z"
							transform="translate(-6.667 -7.431)"
						/>
					</SvgIcon>
					<span>{translate('app.navigation.learn')}</span>
				</span>
			</a>
			<a
				onClick={() => handleNavigate('exercise')}
				className={page === 'exercise' ? 'active-nav-link' : ''}
			>
				<span className={`preview-window-navigation-tabs-tab `}>
					<SvgIcon>
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
					<span>{translate('app.navigation.exercise')}</span>
				</span>
			</a>
			<a
				onClick={() => handleNavigate('course')}
				className={page === 'course' ? 'active-nav-link' : ''}
			>
				<span className={`preview-window-navigation-tabs-tab `}>
					<SvgIcon>
						<path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"></path>
					</SvgIcon>
					<span>{translate('app.navigation.course')}</span>
				</span>
			</a>
			<a
				onClick={() => handleNavigate('user')}
				className={page === 'user' ? 'active-nav-link' : ''}
			>
				<span className={`preview-window-navigation-tabs-tab `}>
					<img
						className={classes.AvatarIcon}
						src={avatarImage}
						style={{ marginBottom: 3 }}
					/>
					<span>{translate('app.navigation.user')}</span>
				</span>
			</a>
		</div>
	);
};

export default AppNavigation;
