import React, {memo} from 'react';
import CustomUploadAdapterPlugin from './customUploadAdapterPlugin';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

interface CKEditorInterface {
    htmlContent: string | null,
    handleBlur: (event: any, editor: any) => void,
    handleChange: (event: any, editor: any) => void,
    handleReady: () => void
}

export const Editor: React.FunctionComponent<CKEditorInterface> = ({htmlContent, handleBlur, handleReady, handleChange}) => {
    // console.log("in editor:", htmlContent);
    return (
        <>{htmlContent && (<CKEditor
            editor={ClassicEditor}
            data={htmlContent}
            config={{
                extraPlugins: [CustomUploadAdapterPlugin],
                toolbar: ['fontSize', 'bold', 'italic', 'blockquote', '|', 'heading', 'alignment', '|', 'numberedList', 'bulletedList', '|', 'link', '|', 'image', 'uploadImage', 'imageStyle', 'imageToolbar', 'mediaEmbed', 'insertTable', 'undo', 'redo', '|'],
                image: {
                    // You need to configure the image toolbar, too, so it uses the new style buttons.
                    toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

                    styles: [
                        // This option is equal to a situation where no style is applied.
                        'full',

                        // This represents an image aligned to the left.
                        'alignLeft',

                        // This represents an image aligned to the right.
                        'alignRight'
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                link: {
                    addTargetToExternalLinks: true
                }
            }
            }
            onReady={(editor: any) => {
                // Add to test the loading
                setTimeout(function () {
                    handleReady()
                }, 500);

                // handleReady()
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event: any, editor: any) => {
                handleChange(event, editor);
            }}
            onBlur={(event: any, editor: any) => {
                handleBlur(event, editor);
            }}
            onFocus={(event: any, editor: any) => {
                // console.log('Focus.', editor);
            }}
        />)}

        </>
    );
}
const MemorizedEditor = memo(Editor);
export default MemorizedEditor;
