import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		background: '#ffffff',
		zIndex: 40,
		overflowY: 'auto',
		'-ms-overflow-style': 'none',
		scrollbarWidth: 'none',
		[theme.breakpoints.up('xs')]: {
			padding: 20,
			paddingTop: 30,
		},
		[theme.breakpoints.up('md')]: {
			padding: '85px 180px 0 180px',
		},

		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	logout: {
		color: color.link,
		cursor: 'pointer',
		'&:hover': {
			cursor: 'pointer !important',
			textDecoration: 'underline',
		},
	},
	textContainer: {
		marginBottom: 20,
		[theme.breakpoints.up('xs')]: {
			marginBottom: 20,
		},
		[theme.breakpoints.up('md')]: {
			marginBottom: 40,
		},
	},
	text: {
		fontSize: 18,
		fontWeight: 400,
		color: '#252733',
		[theme.breakpoints.up('xs')]: {
			marginBottom: 30,
		},
		[theme.breakpoints.up('md')]: {
			marginBottom: 40,
		},
	},
	selectButtonContainer: {
		position: 'fixed',
		bottom: 20,
		left: '50%',
		transform: 'translateX(-50%)',
		width: '100%',
		boxShadow: '0 -5px 20px 0 rgba(0, 0, 0, 0.15)',
		[theme.breakpoints.up('xs')]: {
			padding: 20,
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			width: 380,
		},
	},
}));
