import React from 'react';
import StatisticsItem from '../../../../components/shared/Statistics';

interface UserCoursesProps {
	selectedCourse: any;
	kpis: any;
	courses: any;
}

const UserStatistics: React.FunctionComponent<UserCoursesProps> = ({
	courses,
	selectedCourse,
	kpis,
}) => {
    return kpis ? (
		<>
			{Object.keys(kpis).map((course: any) => {
				if (
					selectedCourse === 0 ||
					selectedCourse === +course ||
					!selectedCourse
				) {
				    return (
						<StatisticsItem
							key={course + 'stat'}
							assignment={kpis[course]}
							course={courses.find(
								(item: any) => item.id === +course,
							)}
						/>
					);
				}
			})}
		</>
	) : null;
};

export default UserStatistics;
