import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useStyles } from './styles';
import { differenceInDays, parse } from 'date-fns';
import { ColumnChart } from './ColumnChart';
import { ContentTypes } from '../../../enums/courses/courses-enums';
import { DonutChart } from './DonutChart';
import { KpiColumn } from './KpiColumn';
import { LineChart } from './LineChart';
import { BarChart } from './BarChart';
import { GraphHeader } from './GraphHeader';
import { translate } from '../../../i18n/i18n';
import MultiSelect from '../MultiSelect';

interface StatisticsProps {
	assignment: any;
	course: any;
}

const Statistics: React.FunctionComponent<StatisticsProps> = ({
	assignment,
	course,
}) => {
	const [selected, setSelected] = useState<number>(0);
	const [filteredExercises, setFilteredExercises] = useState<number[]>([]);
	const classes = useStyles();
	const endDate = parse(course.end, 'yyyy-MM-dd', new Date());
	const startDate = parse(course.start, 'yyyy-MM-dd', new Date());
	const difference = differenceInDays(endDate, new Date());

	const courseProgressData = Object.keys(assignment.courseProgress).map(
		(date: any) => {
			return {
				x: parse(date, 'yy-MM-dd', new Date().getTimezoneOffset()),
				y: assignment.courseProgress[date].toFixed(0),
			};
		},
	);

	const calculateReadingDuration = () => {
		if (!assignment?.readingProgress) return '0';
		let totalTime = 0;
		Object.keys(assignment.readingProgress).forEach((date: string) => {
			totalTime += assignment.readingProgress[date];
		});
		const hours = Math.floor(totalTime / 3600);
		const min = Math.floor((totalTime % 3600) / 60);
		const seconds = totalTime % 60;
		return `${hours > 0 ? `${hours}h ` : ''}${`${min}min `} ${seconds}s`;
	};

	const displayedExercises = assignment.exerciseAndExamResults.filter(
		(item: { subareaId: number }, index: number) => {
			if (!!item && filteredExercises.length) {
				return filteredExercises.indexOf(item.subareaId) !== -1;
			} else return !!item;
		},
	);

	const existingExercises = assignment.exerciseAndExamResults.filter(
		(item: any) => !!item,
	);

	const calculateExerciseAmount = () => {
		let exerciseNo = 0;
		let examNo = 0;
		existingExercises.forEach((item: any) => {
			if (item.type === 'EXERCISE') exerciseNo += 1;
			else examNo += 1;
		});
		return `${exerciseNo} ${
			exerciseNo === 1
				? translate('shared.statisticsCourse.exercise')
				: translate('shared.statisticsCourse.exercises')
		}, ${examNo} ${
			examNo === 1
				? translate('shared.statisticsCourse.exam')
				: translate('shared.statisticsCourse.exams')
		}`;
	};

	const exerciseExamFilterOptions: () => any[] = () => {

		const exerciseAndExamResults: {}[] =
			assignment.exerciseAndExamResults.reduce(
				(
					itemsArray: any[],
					item: { subareaId: number; name: string },
				) => {
					const { subareaId, name } = item;
					if (
						!itemsArray?.find(
							(item: { key: number }) => item.key === subareaId,
						)
					) {
						itemsArray.push({
							key: subareaId,
							value: subareaId,
							text: name,
						});
					}
					return itemsArray;
				},
				[],
			);
		return exerciseAndExamResults;
	};

	const getExerciseAndExamValues = () =>
		displayedExercises.map(
			(item: {
				incorrectAnswers: number;
				correctAnswers: number;
				name: string;
				type: ContentTypes;
			}) => {
				if (!!item) {
					return {
						value:
							item.correctAnswers === 0
								? 0
								: item.incorrectAnswers > 0
								? (
										(item.correctAnswers /
											(item.incorrectAnswers +
												item.correctAnswers)) *
										100
								  ).toFixed(0)
								: 100,
						name: item.name,
						type: ContentTypes[item.type],
					};
				}
			},
		);

	return (
		<div className={`${classes.rowWrap} app-course-statistics-wrapper`}>
			<Grid
				className={`${classes.frameCourse} app-course-statistics-frame`}
			>
				<Grid.Column
					width={12}
					id="statwrapper"
					className={`app-course-statistics-fullcol`}
				>
					{selected === 0 && (
						<>
							<div
								className={`${classes.ChartHeaderCourse} course-header-intro`}
							>
								<GraphHeader
									headline={translate(
										'shared.statisticsCourse.learningProgress',
									)}
									subtitle={''}
								/>
							</div>
							<LineChart
								values={[
									{
										name: 'Fortschritt in %',
										data: courseProgressData,
									},
								]}
								header={
									<GraphHeader
										headline={translate(
											'shared.statisticsCourse.learningProgress',
										)}
										subtitle={translate(
											'shared.statisticsCourse.last30Days',
										)}
									/>
								}
							/>
						</>
					)}

					{selected === 1 && (
						<>
							<div
								className={`${classes.ChartHeaderCourse} course-header-intro`}
							>
								<GraphHeader
									headline={translate(
										'shared.statisticsCourse.readingTime',
									)}
									subtitle={''}
								/>
							</div>
							<BarChart
								values={[
									{
										name: translate(
											'shared.statisticsCourse.readingTimeInMinutes',
										),
										data: Object.keys(
											assignment.readingDoneByDate,
										).map((date: any) => {
											const timeInMinutes = (
												+assignment.readingDoneByDate[
													date
												] / 60
											).toFixed(2);
											return {
												x: parse(
													date,
													'yy-MM-dd',
													new Date().getTimezoneOffset(),
												),
												y: timeInMinutes,
											};
										}),
									},
								]}
							/>
						</>
					)}

					{selected === 2 && (
						<>
							<div
								className={`${classes.ChartHeaderCourse} course-header-intro`}
							>
								<div>
									<GraphHeader
										headline={translate(
											'shared.statisticsCourse.exercisesAndExams',
										)}
										subtitle={calculateExerciseAmount()}
									/>
								</div>
								<div style={{ margin: 20 }}>
									<MultiSelect
										handleChange={(newValues: number[]) =>
											setFilteredExercises(newValues)
										}
										inputTitle={translate(
											'shared.statistics.filters',
										)}
										modalTitle={translate(
											'shared.statisticsCourse.exercisesAndExams',
										)}
										maxItems={0}
										selected={filteredExercises}
										options={exerciseExamFilterOptions()}
										style={{
											minWidth: 130,
										}}
									/>
								</div>
							</div>

							<ColumnChart values={getExerciseAndExamValues()} />
						</>
					)}

					{selected === 3 && (
						<div className="donutwrapper">
							{' '}
							<DonutChart
								value={7}
								end={endDate}
								start={startDate}
							/>
						</div>
					)}
				</Grid.Column>
				<Grid.Column
					id="rowwrapper"
					className={`${classes.kpiFrame} row-wrapper`}
					width={4}
				>
					<KpiColumn
						value={
							((!!courseProgressData.length &&
								courseProgressData[
									courseProgressData.length - 1
								]?.y) ||
								0) + '%'
						}
						label={translate(
							'shared.statisticsCourse.learningProgress',
						)}
						index={0}
						setSelected={setSelected}
						selected={selected}
					/>
					<KpiColumn
						value={calculateReadingDuration()}
						label={translate('shared.statisticsCourse.readingTime')}
						index={1}
						setSelected={setSelected}
						selected={selected}
					/>
					<KpiColumn
						value={existingExercises.length}
						label={translate(
							'shared.statisticsCourse.exercisesAndExams',
						)}
						index={2}
						setSelected={setSelected}
						selected={selected}
					/>
					<KpiColumn
						value={
							difference > 0
								? difference.toString() +
								  (difference > 1 ? ' Tage' : ' Tag')
								: 'abgelaufen'
						}
						label={translate('shared.statisticsCourse.courseEnd')}
						index={3}
						setSelected={setSelected}
						selected={selected}
					/>
				</Grid.Column>
			</Grid>
		</div>
	);
};

export default Statistics;
