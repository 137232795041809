import React, {useContext, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import TabNavigator from '../../components/shared/TabNavigator';
import UserOverviewTab from '../../components/tabs/UserOverviewTab';

import { useDispatch, useSelector } from 'react-redux';

import { userSelector } from '../../store/users/userSlice';
import { groupSelector } from '../../store/groups/groupSlice';
import GroupOverviewTab from '../../components/tabs/GroupOverviewTab';
import UsersDetailPage from './Detail';
import { translate } from '../../i18n/i18n';
import GroupsDetailPage from '../../components/tabs/GroupOverviewTab/Detail';
import AssignmentOverviewTab from '../../components/tabs/AssignmentOverviewTab';
import { assignmentSelector } from '../../store/assignments/assignmentSlice';
import { licenseSelector } from '../../store/licenses/licenseSlice';
import {UserContext} from "../../contexts/UserContext";
import LicenseOverviewTab from "../../components/tabs/LicenseOverviewTab";
import {courseSelector, fetchAll} from "../../store/courses/courseSlice";

export default function Users() {
	const dispatch = useDispatch();
    const { user } = useContext(UserContext);
    const { data, isLoading: coursesAreLoading }: any = useSelector(courseSelector);
	const { data: userData }: any = useSelector(userSelector);
	const {
		data: groupData,
		isLoading: groupsLoading,
		error: groupsError,
	}: any = useSelector(groupSelector);
	const { data: assignmentData }: any = useSelector(assignmentSelector);
    const { data: licenseData }: any = useSelector(licenseSelector);

    useEffect(() => {
        if(!data && !coursesAreLoading) {
            dispatch({
                ...fetchAll({
                    requestType: 'GET',
                    request: `smartometer/courses?page=1&limit=100&sort=id&type=desc&published=1`,
                }),
            });
        }
    }, []);

	const userTabs = {
		users: {
			title: translate('users.users.headline'),
			component: <UserOverviewTab userData={userData} />,
			detailPage: true,
			detailComponent: <UsersDetailPage />,
		},
		groups: {
			title: translate('users.groups.headline'),
			component: (
				<GroupOverviewTab
					groupData={groupData}
					groupIsLoading={groupsLoading}
				/>
			),
			detailPage: true,
			detailComponent: <GroupsDetailPage />,
		},
		assignments: {
			title: translate('users.assignments.headline'),
			component: (
				<AssignmentOverviewTab assignmentData={assignmentData} />
			),
			detailPage: false,
		},
        licenses: {
            title: translate('users.licenses.headline'),
            component: (
                <LicenseOverviewTab licenseData={licenseData} />
            ),
            detailPage: false,
            hidden: user?.institution_id !== 1
        },
	};

	return (
		<div style={{ padding: '96px 30px 100px' }}>
			<Typography
				style={{ marginBottom: 32 }}
				variant="h4"
				component="h4"
			>
				{translate('users.headline')}
			</Typography>

			<TabNavigator tabs={userTabs} defaultActive="users" />
		</div>
	);
}
