import i18n, {StringMap, TOptions} from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: require('./translations/en').default,
      de: require('./translations/de').default,
    },
    debug: true,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  })

export const translate = (key: string) => i18n.t(key);
export const translateWithVariable = (key: string, options:  TOptions<StringMap> | string) => i18n.t(key, options)
export default i18n
