import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'semantic-ui-react';
import { useStyles } from './styles';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import { translate } from '../../../../../i18n/i18n';

export const RenderGapFills: React.FunctionComponent<{
	question: any;
	index: number;
	device: string;
	onChange?: Function;
	userChoice?: {};
	isValidated?: boolean;
    settings?: any;
    isExam?: boolean;
}> = ({ question, index, device, onChange, userChoice, isValidated,    settings,
                                       isExam }) => {
	const classes = useStyles();
	const [words, setWords] = useState<string[]>([]);
	const [positions, setPositions] = useState<any>({});
	const [showOptions, setShowOptions] = useState<number | boolean>(false);
	const [selected, setSelected] = useState<number | boolean>(false);
	useEffect(() => {
		setSelected(showOptions);
	}, [showOptions, setSelected]);
	const text = document.createElement('div');
	text.innerHTML = question.gap_fills[0].text;

	useEffect(() => {
		Array.from(
			document.querySelectorAll(`.gap_text word.gap_text_selected`),
		).map((el) => {
			el?.classList.remove('gap_text_selected');
		});
		if (showOptions !== false) {
			document
				.querySelector(`.gap_text word.word-${showOptions}`)
				?.classList.add('gap_text_selected');
		}
	}, [showOptions]);

	const selectAnswer = (termIndex: number, secondWord: string) => {
		if (typeof termIndex === 'number') {
			let newPositions = positions;
			for (const newPositionsKey in Object.keys(newPositions)) {
				const index = +Object.keys(newPositions)[newPositionsKey];
				if (
					newPositions[index] === secondWord &&
					+index !== termIndex
				) {
					newPositions[index] = null;
				}
			}
			newPositions[termIndex] = secondWord;
			setPositions({
				...newPositions,
			});
			if (onChange)
				onChange(QuestionTypes.GAP_FILLS, { order: newPositions });
		}
	};

	const setShowOptionsHandler = (i: number) => {
		setShowOptions(i);
	};

	useEffect(() => {
		if (!!userChoice) {
			setPositions(userChoice);
		} else {
			setPositions({});
		}
	}, [index]);

	useEffect(() => {
		const gap_texts = document.querySelectorAll('.gap_text word');
		let newWords = [];

		for (let i = 0; i < gap_texts.length; i++) {
			if (gap_texts.hasOwnProperty(i)) {
				const text = gap_texts[i];

				if (text) {
					newWords.push(text?.innerHTML);
					text.innerHTML = '';
					text.addEventListener(
						'click',
						setShowOptionsHandler.bind(null, i),
					);
					text.classList.add('hidden-word');
					text.classList.add(`word-${i}`);
				}
			}
		}
		setWords(newWords);
	}, []);

	useEffect(() => {
		let usedWords = words;
		if (isValidated && !usedWords.length) {
			let fakeEl = document.createElement('div');
			fakeEl.innerHTML = question.gap_fills[0].text;
			const correctWordOrder = fakeEl.querySelectorAll('word');
			usedWords = Array.from(correctWordOrder).map(
				(item: any) => item.innerHTML,
			);
		}
		const gap_texts = document.querySelectorAll('.gap_text word');
		let foundIndex: number | null = null,
			foundWord: string | null = null;
		for (let i = 0; i < gap_texts.length; i++) {
			if (gap_texts[i].innerHTML === positions[i]) {
				gap_texts[i].innerHTML = '';
				gap_texts[i].classList.add('hidden-word');
			}
			if (positions[i]) {
				gap_texts[i].innerHTML = positions[i];
				foundIndex = i;
				foundWord = positions[i];
				if (gap_texts[i].classList.contains('hidden-word')) {
					gap_texts[i].classList.remove('hidden-word');
				}
			} else {
				gap_texts[i].innerHTML = '';
				if (!gap_texts[i].classList.contains('hidden-word')) {
					gap_texts[i].classList.add('hidden-word');
				}
			}
			if (isValidated) {
				if (!gap_texts[i].innerHTML) {
					gap_texts[i].innerHTML = usedWords[i];
					gap_texts[i].classList.add('missed-word');
				} else if (gap_texts[i].innerHTML !== usedWords[i]) {
					gap_texts[i].classList.add('wrong-word');
					const correctedWord = document.createElement('word');
					correctedWord.innerHTML = usedWords[i];
					correctedWord.classList.add('missed-word');
					gap_texts[i].after(correctedWord);
				} else if (gap_texts[i].innerHTML === usedWords[i]) {
					gap_texts[i].classList.add('correct-word');
				}
			}
		}
	}, [positions, isValidated]);

	const renderedOptions = question.options || words;

	return (
		<div
			className={`${classes.questionWrapper} ${
				isValidated ? classes.questionWrapperValidated : ''
			} ${
				typeof showOptions === 'number' && !isValidated ? 'opened' : ''
			}`}
			key={index}
		>
			<p className={classes.question}>
				{question.question} (
                {!isExam && settings?.exercise_show_question_type ? translate(`authoring.questionDetails.fillGaps`):''}
                {isExam && settings?.exam_show_question_type ? translate(`authoring.questionDetails.fillGaps`):''})
			</p>
			<div
				className={'gap_text'}
				dangerouslySetInnerHTML={{ __html: question.gap_fills[0].text }}
			></div>
			<div id="control-wrapper-preview"></div>
			{typeof showOptions === 'number' &&
				!isValidated &&
				ReactDOM.createPortal(
					<FormControl
						className={`${classes.options} ${
							device === 'desktop' ? classes.desktopOptions : ''
						} field-selector`}
						component="fieldset"
					>
						<div className={classes.icons}>
							<span>Wählen Sie einen Text aus</span>
							<IconButton
								className={classes.button}
								color="inherit"
								onClick={() => {
									setShowOptions(false);
								}}
							>
								<Icon disabled size="small" name="close" />
							</IconButton>
						</div>
						<div className={classes.answerWrapper}>
							{renderedOptions.map(function (
								word: string,
								i: any,
							) {
								return (
									<li
										key={i}
										className={classes.ListItemOption}
										onClick={() => {
											selectAnswer(showOptions, word);
											setShowOptions(false);
										}}
									>
										<span
											className={`${classes.option} ${
												Object.values(
													positions,
												).includes(word)
													? classes.selected
													: ''
											}`}
										>
											{word}
										</span>
									</li>
								);
							})}
						</div>
					</FormControl>,
					onChange
						? document.getElementById('control-wrapper')!
						: document.getElementById('control-wrapper-preview')!,
				)}
		</div>
	);
};
