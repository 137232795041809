import { makeStyles } from "@material-ui/core/styles";
import {borderRadius, color, textSizes} from "../../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    headline: {
        fontSize: textSizes.bodyLarge,
        fontWeight: 400
    },
    subtitle: {
        color: color.lightGray,
        fontSize: textSizes.bodySmall,
        marginBottom: 50
    }
}))
