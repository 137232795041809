import { makeStyles } from "@material-ui/core/styles";
import {color, lineHeightSizes, textSizes} from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    headline: {
        padding: '40px 60px',
        textAlign: 'left',
        color: color.textGrey,
        fontSize: textSizes.headline,
        lineHeight: lineHeightSizes.headline,
    },
    Input: {
        width: "100%",
        position: "relative",
        paddingBottom: "20px",

        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "0"
        }

    },
    Label: {
        color: color.textDark,
        fontSize: textSizes.bodySmall
    },
    title: {
        fontSize: textSizes.bodyNormal,
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        marginBottom: 20,
        borderColor: color.borderGrey
    },
}))


