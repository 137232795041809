import { makeStyles } from '@material-ui/core/styles';
import { color, lineHeightSizes, textSizes } from '../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	/* NEW */
	FilterHeader: {
		height: 35,
		padding: '0 25px',
		background: '#ffffff',
		borderRadius: 20,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginLeft: 0,

		'& svg': {
			marginLeft: 10,
			marginTop: 2,
		},
	},
	FilterContent: {
		background: '#fff',
		borderRadius: 8,
		transition: '.1s ease-in-out',
		height: 0,
		position: 'absolute',
		border: '0px solid transparent',
		right: 0,
		top: 45,
		padding: '0 0',
		zIndex: 20,
		overflow: 'hidden',
	},
	FilterContentOpen: {
		height: 375,
		border: '1px solid #E0E0E0',
		padding: '10px 0',
	},
	FilterTab: {
		padding: '6px 15px',
		fontSize: textSizes.bodyNormal,
		color: color.textDark,
		borderRadius: 30,
		cursor: 'pointer',
		transition: '.1s',
	},
	FilterTabWrapper: {
		padding: '0 10px',
	},
	FilterTabActive: {
		background: color.link,
		color: '#fff',
	},
	DatePickerWrapper: {
		margin: '20px 0 0',
		padding: '20px 25px',
		borderTop: '1px solid #e0e0e0',
		fontSize: textSizes.bodyNormal,
	},
	datepicker: {
		height: 32,
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'block',
		minWidth: '200px !important',
		width: 'auto !important',
		marginTop: 3,
		marginBottom: '10px !important',
		marginRight: 0,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& i': {
			paddingTop: '8px !important',
		},
	},

	FilterButton: {
		height: 38,
		position: 'relative',
		float: 'right',
		marginRight: 0,
		'& .MuiFormControlLabel-label': {
			color: color.darkGray,
		},
		'& img': {
			height: 12,
			width: 12,
			marginRight: 8,
		},
		'& svg': {
			marginRight: 4,
		},
	},

	wrapper: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));
