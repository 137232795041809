export const CookieConfig: any = {
	hotjar: {
		name: 'Hotjar',
		company: 'Hotjar Ltd.',
		reason: 'Tool, um das Benutzerverhalten auf der Website zu analysieren. Wir nutzen es, um die Webseite für unsere Besucher effizienter zu gestalten.',
		moreInfo: 'https://www.hotjar.com/legal/policies/privacy/',
		host: '*.hotjar.com',
		cookies:
			'_hjClosedSurveyInvites, _hjDonePolls, _hjMinimizedPolls, _hjDoneTestersWidgets, _hjIncludedInSample, _hjShownFeedbackMessage, _hjid, _hjRecordingLastActivity, hjTLDTest, _hjUserAttributesHash, _hjCachedUserAttributes, _hjLocalStorageTest, _hjptid',
		ttl: 'Sitzung',
	},
	ga: {
		name: 'Google Analytics',
		company: 'Google LLC',
		reason: 'Tool von Google LLC zur Analyse der Nutzung einer Website.',
		moreInfo: 'https://policies.google.com/privacy',
		cookies: '_ga,_gat,_gid',
		ttl: '2 Jahre',
	},
};
