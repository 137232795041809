import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'semantic-ui-react';
import { useStyles } from './styles';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import { translate } from '../../../../../i18n/i18n';

export const RenderWordCombinations: React.FunctionComponent<{
	question: any;
	index: number;
	device: string;
	onChange?: Function;
	userChoice?: any[];
	isValidated?: boolean;
	correctOrder?: any[];
	options?: any[];
    settings?: any;
    isExam?: boolean;
}> = ({
	question,
	index,
	device,
	onChange,
	userChoice,
	isValidated,
	correctOrder,
	options,
    settings,
    isExam
}) => {
	const classes = useStyles();

	const firstWords = question.word_combinations.map(
		(wordCombination: any) => wordCombination.firstWord,
	);
	const secondWords =
		options ||
		question.word_combinations.map(
			(wordCombination: any) => wordCombination.secondWord,
		);

	const [positions, setPositions] = useState<any[]>([]);

	const [showOptions, setShowOptions] = useState<number | boolean>(false);

	const [selected, setSelected] = useState<number | boolean>(false);
	useEffect(() => {
		setSelected(showOptions);
	}, [showOptions, setSelected]);

	useEffect(() => {
		if (!!userChoice) {
			setPositions(userChoice);
		} else {
			setPositions([]);
		}
	}, [index]);

	const selectAnswer = (answerIndex: number, secondWord: string) => {
		if (typeof answerIndex === 'number') {
			let newPositions = positions;
			const previousIndex = newPositions.indexOf(secondWord);
			if (previousIndex !== -1) {
				newPositions[previousIndex] = undefined;
			}
			newPositions[answerIndex] = secondWord;
			setPositions(newPositions);
			if (onChange)
				onChange(QuestionTypes.WORD_COMBINATION, {
					order: newPositions,
				});
		}
	};

	return (
		<div
			className={`${classes.questionWrapper}  ${
				typeof showOptions === 'number' && !isValidated ? 'opened' : ''
			}`}
			key={index}
		>
			<p className={classes.question}>
				{question.question} (
                {!isExam && settings?.exercise_show_question_type ? translate(`authoring.questionDetails.AssignPairs`):''}
                {isExam && settings?.exam_show_question_type ? translate(`authoring.questionDetails.AssignPairs`):''})
			</p>

			<FormControl className={classes.fieldControl} component="fieldset">
				{firstWords.map(function (firstWord: string, i: number) {
					const isCorrectlyPositioned = !!correctOrder
						? positions[i] === correctOrder[i]
						: false;
					const correctItem =
						!isCorrectlyPositioned && !!correctOrder ? (
							<span
								className={`${classes.ListItemLabel} ${classes.ListItemLabelCorrected}`}
							>
								{correctOrder[i]}
							</span>
						) : null;
					return (
						<li
							key={i}
							className={`${classes.ListItemWordCombination} ${
								selected === i && classes.selected
							}`}
							onClick={() => {
								if (isValidated) return;
								setShowOptions(i as number);
							}}
						>
							<span className={classes.ListNumberWordCombination}>
								{firstWord}
							</span>
							{!!positions.length && positions[i] ? (
								<>
									<span
										className={`${classes.ListItemLabel} ${
											isValidated &&
											!isCorrectlyPositioned
												? classes.ListItemLabelWrong
												: ''
										} ${
											isValidated && isCorrectlyPositioned
												? classes.ListItemLabelRight
												: ''
										}`}
									>
										{positions[i]}
									</span>
									{isValidated &&
										!isCorrectlyPositioned &&
										correctItem}
								</>
							) : isValidated && !!correctOrder ? (
								correctItem
							) : (
								<></>
							)}
						</li>
					);
				})}
			</FormControl>
			<div id="control-wrapper-preview"></div>
			{typeof showOptions === 'number' &&
				ReactDOM.createPortal(
					<FormControl
						className={`${classes.options} ${
							device === 'desktop' ? classes.desktopOptions : ''
						} field-selector`}
						component="fieldset"
					>
						<div className={classes.icons}>
							<span>
								{translate(
									'authoring.renderWordCombinations.selectAnswer',
								)}
							</span>
							<IconButton
								className={classes.button}
								color="inherit"
								onClick={() => {
									setShowOptions(false);
								}}
							>
								<Icon disabled size="small" name="close" />
							</IconButton>
						</div>
						<div className={classes.answerWrapper}>
							{secondWords.map(function (
								secondWord: string,
								i: any,
							) {
								return (
									<li
										key={i}
										className={classes.ListItemOption}
										onClick={() => {
											selectAnswer(
												showOptions,
												secondWord,
											);
											setShowOptions(false);
										}}
									>
										<span
											className={`${classes.option} ${
												positions.includes(secondWord)
													? classes.selected
													: ''
											}`}
										>
											{secondWord}
										</span>
									</li>
								);
							})}
						</div>
					</FormControl>,
					onChange
						? document.getElementById('control-wrapper')!
						: document.getElementById('control-wrapper-preview')!,
				)}
		</div>
	);
};
