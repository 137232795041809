import {Redirect} from "react-router-dom";

// Layout Types
import {AppLayout, AuthLayout, DefaultLayout, NoneLayout} from "./layouts";


import Dashboard from "./pages/Dashboard/index";
import Settings from "./pages/Settings/index";
import AppsRedirect from "./pages/Redirects/AppsRedirect/index";
import Users from "./pages/Users/index";
import Courses from "./pages/Courses/index";
import AuthoringLayout from "./layouts/Authoring";
import AuthoringPreviewLayout from "./layouts/AuthoringPreview";
import React from "react";
import Institutions from "./pages/Institutions";


export default [
    {
        path: "/",
        exact: true,
        desktop: false,
        layout: DefaultLayout,
        component: () => <Redirect to="/auth/login"/>
    },
    {
        path: "/auth/:view",
        desktop: false,
        layout: AuthLayout,
    },
    {
        path: "/dashboard",
        desktop: true,
        layout: DefaultLayout,
        component: Dashboard
    },
    {
        path: "/users",
        layout: DefaultLayout,
        desktop: true,
        component: Users
    },
    {
        path: "/institutions",
        layout: DefaultLayout,
        desktop: true,
        component: Institutions
    },
    {
        path: "/courses",
        layout: DefaultLayout,
        desktop: true,
        component: Courses
    },
    {
        path: "/preview/:id/:parentid/:childid",
        layout: AuthoringPreviewLayout,
        desktop: false,
    },
    {
        path: "/authoring/:courseId?/:page?",
        layout: AuthoringLayout,
        desktop: true,
    },
    {
        path: "/settings",
        layout: DefaultLayout,
        component: Settings,
        desktop: true,
    },
    {
        path: "/apps-redirect",
        layout: NoneLayout,
        component: AppsRedirect,
        desktop: false,
    },
    {
        path: "/app/:page/:subpage?/",
        layout: AppLayout,
        exact: true,
        desktop: false,
    },
];
