import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
	selectAll: {
		position: 'absolute',
		top: 0,
		width: 100,
	},
	label: {
		position: 'relative',
	},
	HeaderComponent: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		justifyContent: 'flex-end',
	},
}));
