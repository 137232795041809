import React, {useEffect, useState} from 'react';
import {InstitutionGeneralInterface} from '../../../interfaces/institution-general';
import EditGeneralForm from './EditGeneralForm';
import {Loader} from 'semantic-ui-react';
import {fetchsetting, settingSelector,} from '../../../store/settings/settingSlice';
import {useDispatch, useSelector} from 'react-redux';

export default function GeneralTab() {
    const [isFetched, setIsFetched] = useState(false);
    const {data: settingsData, isLoading} = useSelector(settingSelector);
    const dispatch = useDispatch();

    const defaultState: InstitutionGeneralInterface = {
        subdomain: '',
        institution_name: '',
        street: '',
        number: '',
        postal_code: '',
        city: '',
        country: '',
        vat_id: '',
        invoiceProps: false,
        invoice_street: '',
        invoice_number: '',
        invoice_postal_code: '',
        invoice_city: '',
        first_name: '',
        last_name: '',
        contact_email: '',
        feedback_email: '',
        phone: '',
    };

    const [initialValues, setInitialValues] =
        useState<InstitutionGeneralInterface>(defaultState);

    useEffect(() => {
        console.log("should get new institutiondata", isLoading);
        if (!isLoading) {
            dispatch({
                ...fetchsetting({
                    requestType: 'GET',
                    request: `smartometer/institution`,
                }),
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (settingsData && !isLoading) {
            console.log("should set new institutiondata", isLoading, settingsData);
            setInitialValues({
                subdomain: settingsData.subdomain,
                institution_name: settingsData.institution_name,
                street: settingsData.address.street,
                number: settingsData.address.number,
                postal_code: settingsData.address.postal_code,
                city: settingsData.address.city,
                country: settingsData.address.country,
                vat_id: settingsData.vat_id,
                invoiceProps: settingsData.address.invoice_street || false,
                invoice_street: settingsData.address.invoice_street,
                invoice_number: settingsData.address.invoice_number,
                invoice_postal_code: settingsData.address.invoice_postal_code,
                invoice_city: settingsData.address.invoice_city,
                first_name: settingsData.first_name,
                last_name: settingsData.last_name,
                contact_email: settingsData.contact_email || '',
                feedback_email: settingsData.feedback_email || '',
                phone: settingsData.phone,
            });
            setIsFetched(true);
        }
        return () => {
            setInitialValues(defaultState);
            setIsFetched(false);
        }
    }, [settingsData, isLoading]);
    if(isFetched) {
        console.log(initialValues);
    }
    return (
        <div>
            <Loader active={!isFetched}/>
            {isFetched && <EditGeneralForm initialValues={initialValues}/>}
        </div>
    );
}
