import { makeStyles } from '@material-ui/core/styles';
import { color, lineHeightSizes, textSizes } from '../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	FilterButton: {
		height: 38,
		position: 'relative',
		marginRight: 0,
		marginLeft: 0,
		'& .MuiFormControlLabel-label': {
			color: color.darkGray,
		},
		'& img': {
			height: 12,
			width: 12,
			marginRight: 8,
		},
		'& svg': {
			marginRight: 4,
		},
	},
}));
