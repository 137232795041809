import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color, textSizes } from '../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	BackButton: {
		cursor: 'pointer',
		alignItems: 'center',
		display: 'flex',
		fontSize: textSizes.headline,
		marginBottom: 0,
	},
	ButtonWrapper: {
		alignItems: 'center',
		display: 'flex',
		'& button': {
			marginLeft: '12px',
		},
	},
	missedDeadlineDot: {
		background: color.not_published,
		display: 'inline-block',
		borderRadius: borderRadius.medium,
		width: 8,
		height: 8,
	},
	BarContainer: {
		padding: '0 32px',
	},
	missedDeadline: {
		padding: '4px 8px',
		background: color.not_published,
		borderRadius: borderRadius.medium,
		display: 'inline',
	},
}));
