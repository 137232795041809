import React, {useEffect} from "react";
import PropTypes from "prop-types";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import {useHistory} from 'react-router-dom';
import {UserContext} from "../contexts/UserContext";
import axios from "../app/axios";
import {translate} from "../i18n/i18n";
import {fetchRoles, roleSelector} from "../store/roles/roleSlice";
import {useDispatch, useSelector} from "react-redux";


const DefaultLayout = ({children, noNavbar, noFooter}) => {
    const {showBanner} = React.useContext(UserContext);
    const {data: rolesData, reload, rolesIsLoading} = useSelector(roleSelector);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!window.localStorage.getItem('user') || window.localStorage.getItem('userType') !== 'SOM') {
            history.push('/auth/login');
        } else {
            fetchSubdomain()
        }
    }, []);

    useEffect(() => {
        // Show freshdeks button
        var element = document.getElementById("freshdesk");
        element.classList.remove("hide");
        element.innerHTML = translate('help');
    }, []);

    useEffect( () => () => {
        //Unmound hide freshdesk
        var element = document.getElementById("freshdesk");
        document.getElementById("close_freshdesk").click();
        element.classList.add("hide");
    }, [] );

    const fetchSubdomain = async () => {
        await axios.get(`/institution/unauthorized/guidelines`)
            .then(function (response) {
                window.localStorage.setItem("institutionLogo", response.data.data.logo);
                window.localStorage.setItem("institutionColor", response.data.data.color);
                window.localStorage.setItem("institutionFavicon", response.data.data.favicon);
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    if(!rolesData && !rolesIsLoading) {
        dispatch({
            ...fetchRoles({
                requestType: "GET",
                request: 'smartometer/roles',
            }),
        });
    }


    return (
        <>
            <div style={{display: 'flex'}}>
                <MainNavbar/>
                <MainSidebar/>
                <main className="som-main-content" style={{
                    flexGrow: 1,
                    background: '#F7F8FC',
                    height: '100vh',
                    overflow: "auto",
                    position: "relative",
                    paddingTop: showBanner ? 24 : null
                }}>
                    {children}
                </main>
            </div>
        </>
    );
}

DefaultLayout.propTypes = {
    noNavbar: PropTypes.bool,
    noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
};

export default DefaultLayout;
