import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

interface initialStateInterface {
    data: any;
    courses: any;
    questionCount: number;
    appucationData: any;
    current: any;
    courseSubareas: Array<any>;
    isLoading: boolean;
    error: string;
}

const initialState: initialStateInterface = {
    data: null,
    courses: {},
    questionCount: 0,
    appucationData: {},
    current: null,
    courseSubareas: [],
    isLoading: false,
    error: '',
};

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        fetchAll: (state, action: any) => {

            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.data = {...action.response.success};
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else if (action.state === 'send') {
                state.isLoading = true;
            }
        },
        fetchCourses: (state, action: any) => {

            if (action.state === 'finished' && !!action.response.success) {
                state.courses = {...action.response.success};
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        fetchCurrentCourse: (state, action: any) => {
            state.isLoading = true;
            if (action.state === 'finished' && !!action.response.success) {
                state.current = {...action.response.success};
                state.questionCount = action.response.questionCount;
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        fetchCurrentCourseAuthoring: (state, action: any) => {
            if (action.state === 'finished' && !!action.response.success) {
                // console.log("RES HERE")
                // console.log(action.response.success)
                const course = {...action.response.success};
                delete course.learning_areas;
                state.current = course;
                const subareas: any[] = [];
                action.response.success.learning_areas.forEach((area: any) => area.subareas.forEach((area: any) => subareas.push(area)));
                state.courseSubareas = subareas;
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        fetchCourseQuestionCount: (state, action: any) => {
            if (action.state === 'finished' && !!action.response.success) {
                state.questionCount = action.response.questionCount;
                state.error = '';
            } else if (action.state === 'error') {
                state.error = 'Request error';
            }
        },
        fetchAppucationCourses: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.appucationData = {...action.response.success};
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        addCourse: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.current = {...action.response.success};
                state.isLoading = false;
            }
        },
        updateCourse: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.current = {...action.response.success};
                state.isLoading = false;
            }
        },
        deleteCourse: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
        unsetCourseData: (state) => {
            state.current = null;
            state.courseSubareas = [];
        },
        deleteCourses: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
        clearCache: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
    },
});

export const {
    fetchAll,
    fetchCourses,
    fetchAppucationCourses,
    addCourse,
    updateCourse,
    deleteCourse,
    deleteCourses,
    fetchCurrentCourse,
    fetchCurrentCourseAuthoring,
    unsetCourseData,
    fetchCourseQuestionCount,
    clearCache,
} = courseSlice.actions;
export const courseSelector = (state: RootState) => state.course;
export default courseSlice.reducer;
