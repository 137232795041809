import React from 'react';
import Modal from '../../shared/Modal';
import { color, textSizes } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';

interface EditGroupModalProps {
	showModal: boolean;
	closeModal: () => void;
	onDelete: () => void;
	message: string;
	confirmText: string;
	isLoading: boolean;
	additionalInfo?: string;
}

const ConfirmModal: React.FunctionComponent<EditGroupModalProps> = ({
	showModal,
	closeModal,
	onDelete,
	message,
	confirmText,
	isLoading,
	additionalInfo,
	children,
}) => {
	return (
		<Modal
			showModal={showModal}
			header={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
			onClose={closeModal}
			onSubmit={onDelete}
			isLoading={isLoading}
			submitActionTitle={confirmText}
			closeActionTitle={translate('modals.confirmModal.close')}
			submitActionColor="#1485ee"
			disabled={isLoading}
		>
			{additionalInfo && (
				<p style={{ fontSize: textSizes.bodyNormal }}>
					{additionalInfo}
				</p>
			)}
			{children ?? ''}
		</Modal>
	);
};

export default ConfirmModal;
