import React from 'react';
import HelpText from '../HelpText';
import { useStyles } from './styles';

interface ContentCardProps {
	title?: string;
	headerComponent?: JSX.Element;
	small?: boolean;
	helperText?: string;
	helperTextRight?: boolean;
}

const ContentCard: React.FunctionComponent<ContentCardProps> = ({
	title,
	headerComponent,
	small,
	children,
	helperText,
	helperTextRight,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.Card}>
			{title || headerComponent ? (
				<div
					className={`${classes.CardHeader} ${
						small && classes.CardSmall
					}`}
				>
					<h3
						className={
							small
								? classes.CardHeadlineSmall
								: classes.CardHeadline
						}
					>
						{title}{' '}
						{!!helperText && (
							<HelpText
								text={helperText!}
								position={helperTextRight ? 'right' : 'left'}
							/>
						)}
					</h3>
					{headerComponent && headerComponent}
				</div>
			) : null}

			<div>{children}</div>
		</div>
	);
};

export default ContentCard;
