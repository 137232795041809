import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextField, SvgIcon } from '@material-ui/core';

import { useStyles } from '../styles';
import { translate } from '../../../i18n/i18n';
import { loginSelector, sendForgotPassword } from '../Login/loginSlice';

const ForgotPassword = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isSuccess } = useSelector(loginSelector);
	const [email, setEmail] = useState('');

	const onSubmit = () => {
		dispatch({
			...sendForgotPassword({
				body: {
					email,
					requester: 'smartometer',
				},
				request: '/smartometer/forgot',
			}),
		});
	};

	const handleValidateEnter = (e: React.KeyboardEvent) => {
		if (
			e.keyCode === 13 &&
			!!email &&
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email.trim(),
			)
		) {
			onSubmit();
		}
	};

	return (
		<>
			<h1 className={classes.title}>{translate('resetPassword')}</h1>

			<p className={classes.subTitle} style={{ marginBottom: 20 }}>
				{isSuccess
					? translate('authentication.checkMailPassword')
					: translate('resetPasswordInfo')}
			</p>
			{isSuccess ? (
				<SvgIcon className={classes.CheckIcon}>
					<path
						id="checkbox-marked-circle-outline"
						d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
						transform="translate(-2 -2)"
					/>
				</SvgIcon>
			) : (
				<>
					<TextField
						label={translate(`authentication.register.email`)}
						variant="outlined"
						className={classes.TextField}
						value={email}
						type="email"
						onKeyDown={(e) => handleValidateEnter(e)}
						onChange={(
							e: React.ChangeEvent<
								HTMLTextAreaElement | HTMLInputElement
							>,
						) => setEmail(e.target.value)}
					/>
					<button
						type="submit"
						className={classes.SubmitButton}
						onClick={onSubmit}
						disabled={
							!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
								email,
							)
						}
					>
						{translate(`reset`)}
					</button>
				</>
			)}

			<div className={classes.PageLink}>
				<p>
					<Link
						className={`${classes.link} auth-text-link`}
						to="/auth/login"
					>
						{translate('authentication.logIn')}
					</Link>{' '}
					{translate('or')}{' '}
					<Link
						className={`${classes.link} auth-text-link`}
						to="/auth/register"
					>
						{translate('authentication.register.cta')}
					</Link>
				</p>
			</div>
		</>
	);
};

export default ForgotPassword;
