import {translate} from "../../../i18n/i18n";
import {fetchRoles, roleSelector} from "../../../store/roles/roleSlice";
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {Dropdown, DropdownProps, Message} from "semantic-ui-react";
import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {addUser, clearUser, fetchUsers, userSelector} from "../../../store/users/userSlice";

interface AddUserModalProps {
    showModal: boolean,
    closeModal: () => void
}

interface AddUserInterface {
    first_name?: string;
    last_name?: string;
    email?: string;
    role?: number;
}


interface AddMemberErrorInterface {
    first_name?: string;
    last_name?: string;
    email?: string;
    role?: string;
}

const AddMemberModal: React.FunctionComponent<AddUserModalProps> = ({showModal, closeModal}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {isLoading, error} = useSelector(userSelector);
    const {data: rolesData, reload} = useSelector(roleSelector);

    const allRoles = rolesData && rolesData.data && rolesData?.data.map(function (role: any) {
        return {
            key: role.id,
            text: role.name,
            value: role.id
        };
    });

    useEffect(() => {
        if (reload) {
            dispatch({
                ...fetchRoles({
                    requestType: "GET",
                    request: 'smartometer/roles',
                }),
            });
        }
    }, [reload])


    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();

    const initialValues: AddUserInterface = {
        first_name: "",
        last_name: "",
        email: "",
        role: 1
    }

    useEffect(() => {
        if (isSubmitting && !error) {
            setIsSubmitting(false);
            closeModal();
        }
        ;
        if (!!isLoading) setIsSubmitting(true)
    }, [error]);


    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = (values: AddUserInterface) => {
        setIsSubmitting(true);
        const {first_name, last_name, email, role} = values;
        dispatch({
            ...addUser({
                requestType: "POST",
                body: {
                    first_name: first_name!.trim(),
                    last_name: last_name!.trim(),
                    email: email!.trim(),
                    role: role
                },
                request: `/smartometer/members`,
            }),
        });
    };

    const onValidate = (val: any) => {
        const errors: AddMemberErrorInterface = {}
        if (!val.first_name.trim()) errors.first_name = translate('modals.addMemberModal.firstNameRequired');
        if (!val.last_name.trim()) errors.last_name = translate('modals.addMemberModal.lastNameRequired');
        if (!val.email.trim()) errors.email = translate('modals.addMemberModal.emailRequired');
        if (!val.role) errors.role = translate('modals.addMemberModal.roleRequired');
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val.email.trim())) errors.email = "Keine gültige E-Mail Adresse";
        return errors;
    }


    return (
        <>
            <Modal showModal={showModal} header={translate(`settings.team.members.create.headline`)} onClose={() => {
                dispatch({...clearUser()})
                closeModal();
            }} onSubmit={handleFormikSubmit} isLoading={isLoading} submitActionTitle={translate(`save`)}
                   closeActionTitle={translate(`cancel`)} submitActionColor={color.link} disabled={isLoading}
                   secondaryColor={color.link}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched, setFieldValue}) => {

                        const handleSelectChange = (e: any, dropdown: DropdownProps) => setFieldValue(dropdown.name, dropdown.value);

                        return (
                            <>
                                <div className={classes.InputWrapper}>
                                    <TextField className={`${classes.Input} ${classes.InputDemi}`}
                                               error={touched.first_name && !!errors.first_name}
                                               helperText={touched.first_name && !!errors.first_name && errors.first_name}
                                               id="first_name" value={values.first_name} placeholder={translate('modals.addMemberModal.firstname')}
                                               name="first_name" variant="outlined" onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    <TextField className={classes.InputDemi}
                                               error={touched.last_name && !!errors.last_name}
                                               helperText={touched.last_name && !!errors.last_name && errors.last_name}
                                               id="last_name" value={values.last_name} placeholder={translate('modals.addMemberModal.lastname')}
                                               name="last_name"
                                               variant="outlined" onChange={handleChange} onBlur={handleBlur}/>
                                </div>
                                <TextField className={classes.Input} error={touched.email && !!errors.email}
                                           helperText={touched.email && !!errors.email && errors.email} id="email"
                                           value={values.email} placeholder={translate('modals.addMemberModal.email')} name="email"
                                           variant="outlined"
                                           onChange={handleChange} onBlur={handleBlur}/>
                                <div className={classes.InputWrapper}>

                                    <label className={classes.Label} htmlFor="role">
                                        Rolle
                                        <Dropdown
                                            style={{marginBottom: '20px'}}
                                            className={classes.Select}
                                            fluid
                                            name="role"
                                            id="role"
                                            onChange={handleSelectChange}
                                            value={values.role}
                                            options={allRoles}
                                            placeholder={translate('modals.addMemberModal.role')}
                                            selection
                                        />
                                    </label>
                                </div>

                                {error && (
                                    <Message
                                        error
                                        content={error}
                                    />)}
                                <p className={classes.Note}>{translate('modals.addMemberModal.note')}</p>
                            </>
                        )
                    }}
                </Formik>
            </Modal>

        </>
    )
};

export default AddMemberModal;
