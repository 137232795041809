import { makeStyles } from "@material-ui/core/styles";
import { color, textSizes } from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    TitleInput: {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: color.textDark
        }
    },
    FileInput: {
        position: "relative",
        width: 100,
        height: 44,
        paddingLeft: 20,
        lineHeight: "44px",
        color: color.link,
        cursor: "pointer",
        fontSize: textSizes.bodyNormal,
        overflow: "hidden",
         marginRight: 20,

        "& input": {
            width: 85,
            height: 44,
            position: "absolute",
            left: 20,
            top: 0,
            opacity: 0,
            fontSize: 0,
            cursor: "pointer"
        },
        "& *": {
            cursor: "pointer"
        }
    },
    FileInputWrapper: {
        display: "flex",
        alignItems: "center",

        "& button": {
            marginRight: 20
        }
    },
    editorWrapper: {

    }
}))
