import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	ToggleButton: {
		cursor: 'pointer',
		fontSize: textSizes.bodyLarge,
		fontWeight: 600,
	},
	ToggleWrapper: {
		position: 'relative',
	},
	ToggleItems: {
		position: 'absolute',
		top: 55,
		right: -15,
		lineHeight: '42px',
		textAlign: 'center',
		maxHeight: 200,

		'&.hidden': {
			maxHeight: 0,
		},
	},
	ToggleItem: {
		width: 42,
		height: 42,
		cursor: 'pointer',
		background: '#fff',
		borderRadius: 21,
		boxShadow: '0 -5px 15px 0 rgba(0,0,0,0.05)',
		marginBottom: 10,
		fontWeight: 600,
		opacity: 0,
		transitionDuration: '.5s',
		display: 'block',
		color: color.appText,

		'&.selected': {
			backgroundColor: color.link,
			color: '#fff',
		},

		'&:first-child': {
			fontSize: 10,
			transition: 'backgroundColor 0.5s ease 0s, opacity .2s ease .2s',
		},

		'&:nth-child(2)': {
			fontSize: 13,
			transition: 'backgroundColor 0.5s ease 0s, opacity .2s ease .1s',
		},

		'&:nth-child(3)': {
			fontSize: 16,
			transition: 'backgroundColor 0.5s ease 0s, opacity .2s ease 0s',
		},

		'&.visible': {
			opacity: 1,

			'&:first-child': {
				transition: 'backgroundColor 0.5s ease 0s, opacity .2s ease 0s',
			},

			'&:nth-child(2)': {
				transition:
					'backgroundColor 0.5s ease 0s, opacity .2s ease .1s',
			},

			'&:nth-child(3)': {
				transition:
					'backgroundColor 0.5s ease 0s, opacity .2s ease .2s',
			},
		},
	},
}));
