import { translate } from '../../../i18n/i18n';
import React from 'react';
import { useStyles } from './styles';
import Modal from '../../shared/Modal';
import { color } from '../../../utils/styles';
import trialEndIcon from '../../../assets/images/trialend.svg';

interface PurchaseOrSupportProps {
	showModal: boolean;
	closeModal: () => void;
	onPurchase: () => void;
	onContactSupport: () => void;
	trialOver: boolean;
}

const PurchaseOrSupportModal: React.FunctionComponent<PurchaseOrSupportProps> =
	({ showModal, closeModal, onPurchase, onContactSupport, trialOver }) => {
		const classes = useStyles();

    return (<>

        <Modal showModal={showModal} header={''} onClose={!trialOver ? () => {
            closeModal()
        } : undefined}
               onSubmit={onPurchase} isLoading={false} submitActionTitle={translate(`purchase`)}
               secondaryActionTitle={translate(`contactSupport`)}
               secondaryAction={onContactSupport}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               buttonWrapper={classes.MainButtonWrapper}
			   additionalClasses={classes.ModalBtnsCentered}
        >
            <div className={classes.image}>
                <img src={trialEndIcon}/>
                {trialOver ? <>
                        <h2 className={classes.header}>{translate('modals.purchaseOrSupportModal.trialExpired')}</h2>
                        <p className={classes.paragraph}>{translate('modals.purchaseOrSupportModal.bookFullVersion')}</p>
                    </> :
                    <>
                        <h2 className={classes.header}>{translate('modals.purchaseOrSupportModal.activateSmartometer')}</h2>
                        <p className={classes.paragraph}>{translate('modals.purchaseOrSupportModal.bookFullVersion')}</p>
                    </>
                }

            </div>
        </Modal>
    </>);
};

export default PurchaseOrSupportModal;
