import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color } from '../../../utils/styles';
export const useStyles = makeStyles(() => ({
	selectAll: {
		position: 'absolute',
		top: 0,
		width: 100,
	},
	label: {
		position: 'relative',
	},
	formControl: {
		margin: 0,
		minWidth: 120,
	},
	missedDeadlineDot: {
		background: color.not_published,
		display: 'inline-block',
		borderRadius: borderRadius.medium,
		width: 8,
		height: 8,
	},
	HeaderComponent: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	FilterComponent: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: 5,
	},
}));
