import React, { useState, useEffect } from 'react';
import {translate} from '../../../../../i18n/i18n';

interface ExamCounterProps {
	handleTimeOver: Function;
	totalTime: number;
}

const ExamCounter: React.FunctionComponent<ExamCounterProps> = ({
	handleTimeOver,
	totalTime,
}) => {
	const [initialTime, setInitialTime] = useState(totalTime);

	useEffect(() => {
		let myInterval: NodeJS.Timeout;
		if (initialTime > 0) {
			myInterval = setInterval(() => {
				if (initialTime === 1) {
					clearInterval(myInterval);
					handleTimeOver();
				}
				setInitialTime(initialTime - 1);
			}, 1000);
		}

		return () => {
			clearInterval(myInterval);
		};
	});

	const formatTime = () => {
		let minutes = Math.floor(initialTime / 60).toFixed(0);
		let seconds = +(initialTime % 60).toFixed(0);
		return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
	};

	return (
		<div className="exam-counter-container">
			<p>{translate('app.examCounter.timeRemaining')}</p>
			<p className="verify-text">{formatTime()}</p>
		</div>
	);
};

export default React.memo(ExamCounter);
