import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

interface initialStateInterface {
    data: any;
    isLoading: boolean;
    currentQuestion: any
    error: string;
}

const initialState: initialStateInterface = {
    data: [],
    isLoading: false,
    currentQuestion: {},
    error: '',
};

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        fetchAll: (state, action: any) => {
            if (action.state === 'send') {

            }
            if (action.state === 'finished' && !!action.response.success) {
                state.data = {...action.response.success};
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        fetchOne: (state, action: any) => {
            if (action.state === 'send') {

            }
            if (action.state === 'finished' && !!action.response.success) {
                state.currentQuestion = action.response.success[0];
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        add: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
        update: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
            if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        deleteOne: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        }
    },
});

export const {
    fetchAll,
    fetchOne,
    add,
    update,
    deleteOne
} = questionSlice.actions;
export const questionSelector = (state: RootState) => state.question;
export default questionSlice.reducer;
