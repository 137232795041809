import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	Button: {
		fontSize: textSizes.bodyNormal,
		color: color.white,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		marginLeft: 30,
		width: 125,

		'& svg': {
			marginRight: 10,
			transition: '.2s',
			transform: 'rotate(45deg)',
		},
	},

	ButtonLandscape: {
		'& svg': {
			transform: 'rotate(-45deg)',
		},
	},
	ButtonDisabled: {
		opacity: 0.7,
		cursor: 'not-allowed',

		'& svg': {
			cursor: 'not-allowed !important',
		},
	},
}));
