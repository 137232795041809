import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';

import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import SideLabel from '../../../components/shared/SideLabel';
import QuestionValidationModal from '../../../components/modals/QuestionValidationModal';
import axios from '../../../app/axios';
import { translate } from '../../../i18n/i18n';

// helpers
import {
    formatSingleCorrectAnswer,
    formatSinglePopulateData,
    formatSingleUpdateData,
    validateSingleUpdateData,
} from '../../../helpers/Questions/SingleQuestions';
import {
    formatMultipleCorrectAnswers,
    formatMultiplePopulateData,
    formatMultipleUpdateData,
    formatOrderWordsUpdateData,
    validateMultipleUpdateData,
} from '../../../helpers/Questions/MultipleQuestions';
import { validateOrderWordsQuestions } from '../../../helpers/Questions/OrderWordsQuestions';

import { QuestionTypes } from '../../../enums/questions/questions-enums';
import OrderWords from './OrderWords';
import AssignPair from './AssignPair/AssignPair';
import GapFill from './GapFill';
import {
    formatAssignPairUpdateData,
    validateAssignPairUpdateData,
} from '../../../helpers/Questions/AssignPairQuestions';
import { formatGapFillUpdateData, validateGapFillUpdateData, } from '../../../helpers/Questions/GapFillQuestions';
import AssignTerm from './AssignTerm';
import { formatAssignTermSaveData, formatAssignTermUpdateData, } from '../../../helpers/Questions/AssignTerm';
import { fetchCourseQuestionCount } from '../../../store/courses/courseSlice';
import { useStyles } from './styles';

interface QuestionDetailsProps {
    onSave: Function;
    onAbort: Function;
    selectedSubAreaId: number;
    selectedQuestionId?: number;
    courseId?: number;
}

const QuestionDetails: React.FunctionComponent<QuestionDetailsProps> = ({
    onSave,
    onAbort,
    selectedSubAreaId,
    selectedQuestionId,
    courseId,
}) => {
    const classes = useStyles();
    const [options, setOptions] = React.useState('SINGLE_CHOICE');
    const [singleAnswers, setSingleAnswers] = React.useState('');
    const [singleCorrectAnswer, setSingleCorrectAnswer] = React.useState('');
    const [multipleAnswers, setMultipleAnswers] = React.useState('');
    const [assignPairs, setAssignPairs] = React.useState('');
    const [assignTerms, setAssignTerms] = React.useState('');
    const [gapFills, setGapFills] = React.useState('');
    const [multipleCorrectAnswers, setMultipleCorrectAnswers] =
        React.useState('');
    const [name, setName] = React.useState('');
    const dispatch = useDispatch();

    const [validateQuestionErrors, setValidateQuestionErrors] = React.useState(
        [],
    );

    useEffect(() => {
        if (selectedQuestionId && selectedQuestionId !== 0) {
            fetchAndPopulateQuestion(selectedQuestionId);
        }
    }, [selectedQuestionId]);

    const fetchAndPopulateQuestion = async (questionId: number) => {
        const question = await axios.get(
            `/subarea/${selectedSubAreaId}/questions/${questionId}`,
        );

        const questionType = question.data.success.type;
        let optionType;
        switch (questionType) {
            case QuestionTypes.SINGLE_CHOICE:
                populateSingleQuestion(question);
                break;
            case QuestionTypes.MULTIPLE_CHOICE:
                populateMultipleQuestion(question);
                break;
            case QuestionTypes.ORDER_WORDS:
                populateOrderWordsQuestion(question);
                break;
            case QuestionTypes.WORD_COMBINATION:
                populateAssignPairsQuestion(question);
                break;
            case QuestionTypes.GAP_FILLS:
                populateGapFillQuestion(question);
                break;
            case QuestionTypes.ASSIGN_TERM:
                populateAssignTermQuestion(question);
                break;
            default:
                optionType = QuestionTypes.SINGLE_CHOICE;
                break;
        }

        setOptions(questionType);
        setName(question.data.success.question);
    };

    const populateSingleQuestion = (question: any) => {
        const answers = formatSinglePopulateData(
            question.data.success.answers_by_order,
        );
        setSingleAnswers(answers);

        const correctAnswer = formatSingleCorrectAnswer(
            question.data.success.answers_by_order,
        );
        setSingleCorrectAnswer(correctAnswer);
    };

    const populateOrderWordsQuestion = (question: any) => {
        const answers = formatMultiplePopulateData(
            question.data.success.answers_by_order,
        );
        setMultipleAnswers(answers);
    };

    const populateAssignPairsQuestion = (question: any) => {
        const assignPairs = question.data.success.word_combinations;
        setAssignPairs(assignPairs);
    };

    const populateAssignTermQuestion = (question: any) => {
        const assignTerms = question.data.success.terms;
        setAssignTerms(assignTerms);
    };

    const populateGapFillQuestion = (question: any) => {
        const gapFills = question.data.success.gap_fills;
        setGapFills(gapFills);
    };

    const populateMultipleQuestion = (question: any) => {
        const answers = formatMultiplePopulateData(
            question.data.success.answers_by_order,
        );
        setMultipleAnswers(answers);

        const correctAnswer = formatMultipleCorrectAnswers(
            question.data.success.answers_by_order,
        );
        setMultipleCorrectAnswers(correctAnswer);
    };

    const handleSaveSingleQuestion = async (
        options: any,
        correctOption: any,
    ) => {
        const errors = validateSingleUpdateData(
            name,
            options,
            correctOption,
        ) as any;
        if (!errors.length) {
            const questionProperties = formatSingleUpdateData(
                options,
                correctOption,
                name,
            );
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditSingleQuestion = async (
        options: any,
        correctOption: any,
    ) => {
        const errors = validateSingleUpdateData(
            name,
            options,
            correctOption,
        ) as any;
        if (!errors.length) {
            const questionProperties = formatSingleUpdateData(
                options,
                correctOption,
                name,
            );
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleSaveOrderWordsQuestion = async (options: any) => {
        const errors = validateOrderWordsQuestions(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatOrderWordsUpdateData(
                options,
                name,
            );
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditOrderWordsQuestion = async (options: any) => {
        const errors = validateOrderWordsQuestions(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatOrderWordsUpdateData(
                options,
                name,
            );
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };
    const handleSaveGapFillQuestion = async (options: any) => {
        const errors = validateGapFillUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatGapFillUpdateData(options, name);
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditGapFillQuestion = async (options: any) => {
        const errors = validateGapFillUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatGapFillUpdateData(options, name);
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleSaveAssignTermQuestion = async (options: any) => {
        const errors = validateAssignPairUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatAssignTermSaveData(options, name);
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditAssignTermQuestion = async (options: any) => {
        const errors = validateAssignPairUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatAssignTermUpdateData(
                options,
                name,
            );
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleSaveAssignPairQuestion = async (options: any) => {
        const errors = validateAssignPairUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatAssignPairUpdateData(
                options,
                name,
            );
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditAssignPairQuestion = async (options: any) => {
        const errors = validateAssignPairUpdateData(name, options) as any;
        if (!errors.length) {
            const questionProperties = formatAssignPairUpdateData(
                options,
                name,
            );
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleSaveMultipleQuestion = async (
        options: any,
        correctOptions: any,
    ) => {
        const errors = validateMultipleUpdateData(
            name,
            options,
            correctOptions,
        ) as any;
        if (!errors.length) {
            const questionProperties = formatMultipleUpdateData(options, name);
            await axios
                .post(
                    `courses/${courseId}/subarea/${selectedSubAreaId}/questions`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const handleEditMultipleQuestion = async (
        options: any,
        correctOptions: any,
    ) => {
        const errors = validateMultipleUpdateData(
            name,
            options,
            correctOptions,
        ) as any;
        if (!errors.length) {
            const questionProperties = formatMultipleUpdateData(options, name);
            await axios
                .put(
                    `subarea/${selectedSubAreaId}/questions/${selectedQuestionId}`,
                    questionProperties,
                )
                .then((response: any) => {
                    dispatch({
                        ...fetchCourseQuestionCount({
                            requestType: 'GET',
                            request: `smartometer/courses/${courseId}`,
                        }),
                    });
                    onAbort();
                })
                .catch((error: any) => {
                    console.log(error);
                });
        } else {
            setValidateQuestionErrors(errors);
        }
    };

    const renderComponent = () => {
        switch (options) {
            case QuestionTypes.SINGLE_CHOICE:
                return (
                    <SingleChoice
                        onSave={handleSaveSingleQuestion}
                        onAbort={onAbort}
                        onEdit={handleEditSingleQuestion}
                        singleAnswers={singleAnswers}
                        singleCorrectAnswer={singleCorrectAnswer}
                    />
                );
            case QuestionTypes.MULTIPLE_CHOICE:
                return (
                    <MultipleChoice
                        onSave={handleSaveMultipleQuestion}
                        onAbort={onAbort}
                        onEdit={handleEditMultipleQuestion}
                        multipleAnswers={multipleAnswers}
                        multipleCorrectAnswers={multipleCorrectAnswers}
                    />
                );
            case QuestionTypes.ORDER_WORDS:
                return (
                    <OrderWords
                        onSave={handleSaveOrderWordsQuestion}
                        onAbort={onAbort}
                        multipleAnswers={multipleAnswers}
                        onEdit={handleEditOrderWordsQuestion}
                    />
                );
            case QuestionTypes.WORD_COMBINATION:
                return (
                    <AssignPair
                        onSave={handleSaveAssignPairQuestion}
                        onAbort={onAbort}
                        assignPairs={assignPairs}
                        onEdit={handleEditAssignPairQuestion}
                    />
                );
            case QuestionTypes.GAP_FILLS:
                return (
                    <GapFill
                        onSave={handleSaveGapFillQuestion}
                        onAbort={onAbort}
                        gapFills={gapFills}
                        onEdit={handleEditGapFillQuestion}
                    />
                );
            case QuestionTypes.ASSIGN_TERM:
                return (
                    <AssignTerm
                        onSave={handleSaveAssignTermQuestion}
                        onAbort={onAbort}
                        assignTerms={assignTerms}
                        onEdit={handleEditAssignTermQuestion}
                    />
                );
        }
    };

    const renderComponentExplanation = () => {
        switch (options) {
            case QuestionTypes.SINGLE_CHOICE:
                return translate(`authoring.singleChoice.explanation`);
            case QuestionTypes.MULTIPLE_CHOICE:
                return translate(`authoring.multipleChoice.explanation`);
            case QuestionTypes.ORDER_WORDS:
                return translate(`authoring.orderWords.explanation`);
            case QuestionTypes.WORD_COMBINATION:
                return translate(`authoring.assignPair.explanation`);
            case QuestionTypes.GAP_FILLS:
                return translate(`authoring.gapFills.explanation`);
            case QuestionTypes.ASSIGN_TERM:
                return translate(`authoring.assignTerm.explanation`);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions((event.target as HTMLInputElement).value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    return (
        <div>
            <div className="coursewizard-input-wrapper exercise-wrapper">
                <SideLabel text="Aufgabe" />
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="choices"
                        name="choices"
                        value={options}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value={QuestionTypes.SINGLE_CHOICE}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.singleChoice')}
                        />
                        <FormControlLabel
                            value={QuestionTypes.MULTIPLE_CHOICE}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.multipleChoice')}
                        />
                        <FormControlLabel
                            value={QuestionTypes.ORDER_WORDS}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.OrderWords')}
                        />
                        <FormControlLabel
                            value={QuestionTypes.GAP_FILLS}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.fillGaps')}
                        />
                        <FormControlLabel
                            value={QuestionTypes.WORD_COMBINATION}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.AssignPairs')}
                        />
                        <FormControlLabel
                            value={QuestionTypes.ASSIGN_TERM}
                            control={<Radio color="primary" />}
                            label={translate('authoring.questionDetails.assignToTerm')}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <br />
            <div className="coursewizard-input-wrapper exercise-wrapper">
                <SideLabel text={translate('authoring.questionDetails.questions')} />
                <input
                    className={classes.TitleInput}
                    name="question-title"
                    type="text"
                    value={name}
                    placeholder={translate('authoring.questionDetails.enterQuestion')}
                    onChange={handleNameChange}
                />
            </div>
            {options && (
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="coursewizard-input-wrapper exercise-wrapper"
                >
                    <SideLabel text={translate('authoring.questionDetails.explanation')} />
                    <p className={classes.explainBanner}>
                        {' '}
                        {renderComponentExplanation()}{' '}
                    </p>
                </div>
            )}
            <div className="coursewizard-input-wrapper exercise-wrapper">
                <SideLabel text={translate('authoring.questionDetails.answer')} />
                {renderComponent()}
            </div>
            <QuestionValidationModal
                showModal={validateQuestionErrors.length ? true : false}
                closeModal={() => setValidateQuestionErrors([])}
                errors={validateQuestionErrors}
            />
        </div>
    );
};

export default QuestionDetails;
