import React, {useState} from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import {translate} from "../../../i18n/i18n";


interface BaseModal {
    openModal: boolean,
    closeModal: () => void
}

const BaseModal: React.FunctionComponent<BaseModal> = ({ openModal, closeModal }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <>
      <Modal
        open={openModal}
        size="tiny"
        onClose={() => closeModal()}
      >
        <Modal.Header>{translate('shared.baseModal.createUserHeadline')}</Modal.Header>
        <Modal.Content>
          <p>{translate('shared.baseModal.passwordWillBeSetByUser')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic primary onClick={() => closeModal()}>
          {translate('shared.baseModal.addAnotherUser')}
          </Button>
          <Button primary onClick={() => closeModal()}>
          {translate('shared.baseModal.createUser')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default BaseModal