import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

interface initialStateInterface {
    data: any;
    single: any;
    preview: any;
    isLoading: any;
    test: any;
    isFetching: boolean;
    isFirst: boolean;
    error: any;
}

const initialState: initialStateInterface = {
    data: [],
    single: null,
    preview: null,
    isLoading: false,
    test: 'finished',
    error: '',
    isFetching: false,
    isFirst: true,
};

export const subareasSlice = createSlice({
    name: 'subareas',
    initialState,
    reducers: {
        fetchSubareas: (state, action: any) => {
            if (action.state === 'send') {
                state.isFetching = true;
                state.isFirst = false
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.data = action.response.success;
                state.isFetching = false;
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.isFetching = false;

                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        fetchSubarea: (state, action: any) => {

            if (action.state === 'finished' && !!action.response.success) {
                state.single = action.response.success.pop();
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        fetchSubareaForPreview: (state, action: any) => {
            state.isLoading = true;
            if (action.state === 'finished' && !!action.response.success) {
                state.preview = action.response.success;
                state.isLoading = false;
                state.error = '';
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        addSubarea: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.single = action.response.success;
                state.isLoading = false;
            }
        },
        updateSubArea: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
        duplicateSubarea: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
        unsetSubareaData: (state) => {
            state.data = [];
        },
        deleteSubarea: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
    },
});

export const {
    fetchSubareas,
    fetchSubarea,
    addSubarea,
    updateSubArea,
    deleteSubarea,
    duplicateSubarea,
    fetchSubareaForPreview,
    unsetSubareaData,
} = subareasSlice.actions;
export const subAreaSelector = (state: RootState) => state.subarea;
export const subAreaLoadingSelector = (state: any) => state.subarea.isLoading;
export default subareasSlice.reducer;
