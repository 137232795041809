import * as React from 'react';

type UserContextState = {
    user: any,
    permissions: any,
    showBanner: boolean | null,
    isTrial: boolean | null,
    paidPeriodStartAt: Date | null,
    trialEndAt: Date | null,
    isUnpaid: boolean,
    demoDataAdded: boolean,
    askedForDemoData: boolean,
    askedToRemoveDemoData: boolean,
    notAuthenticated: boolean,
    triggerRefetch: Function
};

const contextDefaultValues: UserContextState = {
    user: null,
    permissions: null,
    showBanner: null,
    isTrial: null,
    paidPeriodStartAt: null,
    trialEndAt: null,
    isUnpaid: false,
    demoDataAdded: false,
    askedForDemoData: true,
    askedToRemoveDemoData: true,
    notAuthenticated: false,
    triggerRefetch: ()=>{}
}

export const UserContext = React.createContext<UserContextState>(contextDefaultValues);


export default function useUserContext() {
    let context = React.useContext(UserContext);
    // If context is undefined, we know we used RadioGroupItem
    // outside of our provider so we can throw a more helpful
    // error!
    if (context === undefined) {
        throw Error(
            "no user loaded"
        );
    }

    // Because of TypeScript's type narrowing, if we make it past
    // the error the compiler knows that context is always defined
    // at this point, so we don't need to do any conditional
    // checking on its values when we use this hook!
    return context;
}
