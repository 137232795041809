import React, { useState } from 'react';
import { faChevronDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from '@material-ui/core';
import useStyles from './styles';
import { CookieConfig } from './config';
import { Close } from '@material-ui/icons';

const CookieBlocker: React.FunctionComponent<{
	handleAcceptCookies: Function;
	show: Boolean;
}> = ({ handleAcceptCookies }) => {
	const [showMore, setShowMore] = useState(false);
	const [showBanner, setShowBanner] = useState(true);
	const [showInfo, setShowInfo] = useState<any>('');
	const [selectedCookies, setSelectedCookies] = useState<any>({
		ga: false,
		hotjar: false,
	});

	const classes = useStyles();

	const toggleCookie = (property: string) => {
		setSelectedCookies({
			...selectedCookies,
			[property]: !selectedCookies[property],
		});
	};

	const handleSaveAll = () => {
		handleAcceptCookies({ ga: true, hotjar: true });
		setShowBanner(false);
	};

	const handleSave = () => {
		handleAcceptCookies(selectedCookies);
		setShowBanner(false);
	};

    const handleDeclineSave = () => {
        handleAcceptCookies({ ga: false, hotjar: false });
        setShowBanner(false);
    };


    const toggleInfo = (property: string) => {
		setShowInfo(property);
	};

	const returnInfo = () => {
		const { name, company, reason, moreInfo, host, cookies, ttl } =
			CookieConfig[showInfo];
		return (
			<div className={classes.CookieWrapper}>
				<div className={classes.MoreInfoModal}>
					<Close
						onClick={() => setShowInfo('')}
						className="close-icon"
					/>
					<div className={classes.MoreInfoItem}>
						<h5>Name</h5>
						<p>{name}</p>
					</div>
					{company && (
						<div className={classes.MoreInfoItem}>
							<h5>Anbieter</h5>
							<p>{company}</p>
						</div>
					)}
					{reason && (
						<div className={classes.MoreInfoItem}>
							<h5>Verwendung</h5>
							<p>{reason}</p>
						</div>
					)}
					{moreInfo && (
						<div className={classes.MoreInfoItem}>
							<h5>Mehr Informationen</h5>
							<p>
								<a href={moreInfo} target="_blank">
									{moreInfo}
								</a>
							</p>
						</div>
					)}
					{host && (
						<div className={classes.MoreInfoItem}>
							<h5>Host</h5>
							<p>{host}</p>
						</div>
					)}
					{cookies && (
						<div className={classes.MoreInfoItem}>
							<h5>Cookies</h5>
							<p>{cookies}</p>
						</div>
					)}
					{ttl && (
						<div className={classes.MoreInfoItem}>
							<h5>Speicherdauer</h5>
							<p>{ttl}</p>
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div
			className={classes.CookieWrapper}
			style={!showBanner ? { display: 'none' } : {}}
		>
			<div className={classes.Modal}>
				<img src="/somlogo.svg" className={classes.Logo} />
				<p className={classes.Intro}>
					Wir verwenden Cookies, um die Nutzererfahrung zu verbessern.{' '}
				</p>
				<div
					className={classes.AdjustSettings}
					onClick={() => setShowMore(!showMore)}
				>
					Benutzerdefinierte Einstellungen
					<FontAwesomeIcon
						icon={faChevronDown}
						className={showMore ? 'up' : ''}
					/>
				</div>
				<div
					className={`${classes.MoreContent} ${
						showMore ? 'open' : ''
					}`}
				>
					<div className={classes.CookieItem}>
						<div className={classes.CookieText}>
							<h4>Essentielle Cookies</h4>
							<p>Alle Cookies die zur Nutzung der Applikation smartOmeter notwendig sind.</p>
						</div>
						<Switch disabled defaultChecked />
					</div>
					<div className={classes.CookieItem}>
						<div className={classes.CookieText}>
							<h4>
								Google Analytics{' '}
								<FontAwesomeIcon
									icon={faInfoCircle}
									onClick={() => toggleInfo('ga')}
								/>{' '}
							</h4>
							<p>Tool von Google LLC zur Website-Analyse.</p>
						</div>
						<Switch
							disableRipple
							checked={selectedCookies['ga']}
							onChange={() => toggleCookie('ga')}
						/>
					</div>
					<div className={classes.CookieItem}>
						<div className={classes.CookieText}>
							<h4>
								Hotjar{' '}
								<FontAwesomeIcon
									icon={faInfoCircle}
									onClick={() => toggleInfo('hotjar')}
								/>{' '}
							</h4>
							<p>
								Wir nutzen Hotjar für eine anonymisierte Analyse
								des Nutzerverhaltens.
							</p>
						</div>
						<Switch
							disableRipple
							checked={selectedCookies['hotjar']}
							onChange={() => toggleCookie('hotjar')}
						/>
					</div>
				</div>
				{showMore && (
					<div className={classes.SaveButton} onClick={handleSave}>
						Einstellungen speichern
					</div>
				)}
                <div className={classes.AcceptButton} onClick={handleDeclineSave}>
                    Ablehnen
                </div>
				<div className={classes.AcceptButton} onClick={handleSaveAll}>
					Alle akzeptieren
				</div>
			</div>
			{showInfo !== '' && returnInfo()}
		</div>
	);
};

export default CookieBlocker;
