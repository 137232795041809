import { makeStyles } from "@material-ui/core/styles";
import { color, borderRadius } from '../../../utils/styles.js'

export const useStyles = makeStyles(theme => ({
    Button: {
        padding: "0 20px",
        height: "32px",
        lineHeight: "30px",
        display: "inline-block",
        borderRadius: borderRadius.large,
        border: `1px solid ${color.borderGrey}`,
        color: color.textDark,
        cursor: "pointer",
        transition: ".2s",

        "&:hover": {
            border: `1px solid ${color.primary}`,
            backgroundColor: color.primary,
            color: "#ffffff",
        }
    }
}))