import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { getPlatforms } from '@ionic/react';
import { clearState, logIn, loginSelector } from './loginSlice';
import { useStyles } from '../styles';
import { translate } from '../../../i18n/i18n';

import AuthLogo from '../../../assets/images/auth-logo.png';
import {fetchRoles} from "../../../store/roles/roleSlice";

interface State {
	username: string;
	password: string;
	isLoading: boolean;
	showPassword: boolean;
}

const Login = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		isSuccess,
		userType,
		isEndUser,
		selectedCourse,
		errorMessage,
		data,
		role,
	} = useSelector(loginSelector);

	const [values, setValues] = useState<State>({
		username: '',
		password: '',
		isLoading: false,
		showPassword: false,
	});

	useEffect(() => {
		if (isSuccess) {
			dispatch(clearState());
			if (getPlatforms().includes('mobile')) {
				history.push(`/app/learn?navigation=0&content=-1`);
			} else {
				if (
					userType === 'SOM' &&
					role.find((cRole: any) => cRole.name === 'FE_SOM_DASHBOARD')
				) {
					console.log('on login page');
					history.push('/dashboard');
				} else if (
					userType === 'SOM' &&
					role.find((cRole: any) => cRole.name === 'FE_SOM_USERS')
				) {
					history.push('/users');
				} else if (
					userType === 'SOM' &&
					role.find((cRole: any) => cRole.name === 'FE_SOM_COURSES')
				) {
					history.push('/courses');
				} else if (
					userType === 'SOM' &&
					role.find((cRole: any) => cRole.name === 'FE_SOM_SETTINGS')
				) {
					history.push('/settings');
				}

				if (userType === 'NON_SOM' && isEndUser === 1) {
					if (selectedCourse) {
						history.push(`/app/${selectedCourse}`);
					} else {
						history.push(`/app/course`);
					}
				}
			}
		}
	}, [isSuccess]);

	const handleChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		prop: keyof State,
	) => {
		setValues({ ...values, [prop]: e.target.value });
	};

	const onSubmit = () => {
		dispatch({
			...logIn({
				body: {
					email: values.username,
					password: values.password,
					requester: 'smartometer',
				},
				request: '/smartometer/login',
			}),
		});
	};

	const handleValidateEnter = (e: React.KeyboardEvent) => {
		if (
			e.keyCode === 13 &&
			!!values.password &&
			!!values.username &&
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				values.username.trim(),
			)
		) {
			onSubmit();
		}
	};

	return (
		<>
			{process.env.REACT_APP_COURSE_ID && (
				<img src={AuthLogo} className={classes.AuthLogo} />
			)}
			<h1 className={`${classes.title} auth-title`}>
				{translate('authentication.logIn')}
			</h1>
			<p className={`${classes.subTitle} auth-subtitle`}>
				{translate('authentication.subhead')}
			</p>
			<img
				className={`${classes.Logo} auth-som-logo`}
				src={data.logo || '/somlogo.svg'}
			/>
			<p className={classes.ErrorMessage}>{errorMessage}</p>
			<TextField
				error={!!errorMessage}
				type="email"
				label={translate(`authentication.register.email`)}
				variant="outlined"
				className={classes.TextField}
				onChange={(e) => handleChange(e, 'username')}
				onKeyDown={(e) => handleValidateEnter(e)}
			/>
			<TextField
				error={!!errorMessage}
				type={values.showPassword ? 'text' : 'password'}
				label={translate(`authentication.register.password`)}
				variant="outlined"
				className={classes.TextField}
				onChange={(e) => handleChange(e, 'password')}
				onKeyDown={(e) => handleValidateEnter(e)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{values.showPassword ? (
								<Visibility
									onClick={() =>
										setValues({
											...values,
											showPassword: !values.showPassword,
										})
									}
								/>
							) : (
								<VisibilityOff
									onClick={() =>
										setValues({
											...values,
											showPassword: !values.showPassword,
										})
									}
								/>
							)}
						</InputAdornment>
					),
				}}
			/>
			<button onClick={onSubmit} className={classes.SubmitButton}>
				{translate(`authentication.logIn`)}
			</button>
			<Link className={classes.SubLink} to="/auth/forgotten-password">
				{translate('forgetPassword')}
			</Link>
			<div className={classes.PageLink}>
				<p>
					{translate('authentication.stillDontHaveAccount')}{' '}
					<Link
						className={`${classes.link} auth-text-link`}
						to="/auth/register"
					>
						{translate('authentication.register.cta')}
					</Link>
				</p>
			</div>
		</>
	);
};

export default Login;
