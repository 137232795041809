import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

interface ActionModalProps {
	showModal: boolean;
	closeModal?: () => void;
	title: string;
	description: string;
	slot: JSX.Element;
}

const ActionModal: React.FunctionComponent<ActionModalProps> = ({
	showModal,
	closeModal,
	title,
	description,
	slot,
}) => {
	const classes = useStyles();

	return (
		<div>
			<Dialog fullScreen open={showModal} onClose={closeModal}>
				<div className={classes.textContainer}>
					<div className={classes.header}>
						<h1 className={classes.text}>{title}</h1>
						<span
							className={`${classes.logout} modal-button-wrapper`}
						>
							{!!closeModal && (
								// <IconButton
								// 	edge="start"
								// 	color="inherit"
								// 	onClick={closeModal}
								// 	aria-label="close"
								// >
								<CloseIcon
									color="inherit"
									onClick={closeModal}
								/>
								// </IconButton>
							)}
						</span>
					</div>
					<p className={classes.text}>{description}</p>
					{slot}
				</div>
			</Dialog>
		</div>
	);
};

export default ActionModal;
