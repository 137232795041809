import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form } from 'semantic-ui-react';
import { InputAdornment, TextField } from '@material-ui/core';

import { clearState, loginSelector, setPassword } from '../Login/loginSlice';
import { useStyles } from '../styles';
import { validationSchema } from './validationSchemaPassword';
import { SetPasswordInterface } from '../../../interfaces/set-password-interface';
import { translate } from '../../../i18n/i18n';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const SetPassword = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	var url = new URL(window.location.href);
	var params = url.searchParams;
	const history = useHistory();

	const { isSuccess, shouldRedirect } = useSelector(loginSelector);

	useEffect(() => {
		if (isSuccess) {
			dispatch(clearState());
			if (shouldRedirect) {
				history.push('/apps-redirect');
			} else {
				history.push('/dashboard');
			}
		}
	}, [isSuccess]);

	const onSubmit = (values: SetPasswordInterface) => {
		dispatch({
			...setPassword({
				body: {
					id: params.get('user'),
					password: values.password,
					c_password: values.passwordConfirm,
					requester: 'smartometer',
				},
				request: '/smartometer/set-password',
			}),
		});
	};

	const initialValues: SetPasswordInterface = {
		password: '',
		passwordConfirm: '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<>
			<h1 className={classes.title}>
				{translate('authentication.register.setPassword')}
			</h1>
			<p className={classes.subTitle}>
				{translate('authentication.register.setPasswordInfo')}
			</p>
			<p className={classes.ErrorMessage}>
				{formik.values.password && formik.errors.password}
				{formik.values.passwordConfirm && formik.errors.passwordConfirm}
			</p>

			<Form onSubmit={formik.handleSubmit}>
				<TextField
					error={false}
					type={showPassword ? 'text' : 'password'}
					label={translate(`authentication.register.password`)}
					variant="outlined"
					name="password"
					value={formik.values.password}
					className={classes.TextField}
					onChange={formik.handleChange}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{showPassword ? (
									<Visibility
										onClick={() => setShowPassword(false)}
									/>
								) : (
									<VisibilityOff
										onClick={() => setShowPassword(true)}
									/>
								)}
							</InputAdornment>
						),
					}}
				/>
				<TextField
					error={false}
					type={showConfirmPassword ? 'text' : 'password'}
					label={translate(`authentication.register.passwordCompare`)}
					variant="outlined"
					name="passwordConfirm"
					value={formik.values.passwordConfirm}
					className={classes.TextField}
					onChange={formik.handleChange}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{showConfirmPassword ? (
									<Visibility
										onClick={() =>
											setShowConfirmPassword(false)
										}
									/>
								) : (
									<VisibilityOff
										onClick={() =>
											setShowConfirmPassword(true)
										}
									/>
								)}
							</InputAdornment>
						),
					}}
				/>
				<button
					type="submit"
					className={classes.SubmitButton}
					disabled={
						formik.isSubmitting ||
						!formik.isValid ||
						!formik.values.password ||
						!formik.values.passwordConfirm
					}
				>
					{translate(`authentication.register.cta`)}
				</button>
			</Form>
		</>
	);
};

export default SetPassword;
