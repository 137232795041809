import * as yup from "yup";
import YupPassword from "yup-password";
import { translate } from '../../../../i18n/i18n';

YupPassword(yup);
export const validationSchema = yup.object().shape({
    invoiceProps: yup.boolean(),
    street: yup
        .string()
        .required('street is required'),
    city: yup
        .string()
        .required('city is required'),
    number: yup
        .string()
        .required('number is required'),
    country: yup
        .string()
        .required('country is required'),
    postal_code: yup
        .string()
        .required('post_code is required'),
    institution_name: yup
        .string()
        .required('name is required'),
    invoice_street: yup
        .string()
        .nullable()
        .when('invoiceProps', {
            is: true,
            then: yup.string().required('Must enter invoice street').nullable(),
        }),
    invoice_number: yup
        .string()
        .nullable()
        .when('invoiceProps', {
            is: true,
            then: yup.string().required('Must enter invoice number').nullable(),
        }),
    invoice_postal_code: yup
        .string()
        .nullable()
        .when('invoiceProps', {
            is: true,
            then: yup.string().required('Must enter invoice post code').nullable(),
        }),
    invoice_city: yup
        .string()
        .nullable()
        .when('invoiceProps', {
            is: true,
            then: yup.string().required('Must enter invoice city').nullable(),
        })
});
