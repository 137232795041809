import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useStyles } from './styles';
import AppSuccessModal from '../../SuccessModal';
import { validationSchema } from './validationSchema';
import axios from '../../../../app/axios';
import Button from '../../../../components/shared/Button';
import { color } from '../../../../utils/styles';
import { translate } from '../../../../i18n/i18n';
import {useDispatch} from "react-redux";
import {clearState} from "../../../../store/app/appSlice";
import {clearInstitutionState} from "../../../../store/institution/institutionSlice";
import {clearSettingsState} from "../../../../store/settings/settingSlice";

interface FeedbackInterface {
	text: string;
}

export default function AppProfileFeedback() {
	const history = useHistory();
    const dispatch = useDispatch();
	const classes = useStyles();
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const logout = () => {
		window.localStorage.clear();
        dispatch({
            ...clearState()
        })
        dispatch({
            ...clearSettingsState()
        })
		history.push('/auth/login');
	};

	const initialValues: FeedbackInterface = {
		text: '',
	};

	const onSubmit = async (values: FeedbackInterface) => {
		setIsLoading(true);
		await axios
			.post(`/app/feedback`, values)
			.then(function (response) {
				setIsLoading(false);
				formik.resetForm();
				setShowSuccess(true);
				setSuccessMessage(
					translate('app.successModal.FeedbackSuccess'),
				);
			})
			.catch(function (error) {
				setIsLoading(false);
				console.log(error);
			});
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<>
			<AppSuccessModal
				showModal={showSuccess}
				closeModal={() => setShowSuccess(false)}
				successMessage={successMessage}
			/>
			<div className={classes.Container}>
				<div className={classes.Label}>
					{translate('app.profile.feedback.label')}
				</div>
				<form onSubmit={formik.handleSubmit}>
					<textarea
						value={formik.values.text}
						onChange={formik.handleChange}
						name="text"
						className={classes.TextArea}
					></textarea>
					<div className={`${classes.Submit} som-button-wrapper`}>
						<Button
							title={translate('app.profile.feedback.submit')}
							type="solid-fluid"
							color={color.link}
							onClick={formik.handleSubmit}
							disabled={!formik.values.text}
							isLoading={isLoading}
						/>
					</div>
				</form>
				<div className={classes.LogoutWrapper}>
					<p className={classes.Label}>
						{translate('app.profile.actions.label')}
					</p>
					<a className={classes.LogoutLink} onClick={logout}>
						{translate('app.profile.actions.logout')}
					</a>
				</div>
			</div>
		</>
	);
}
