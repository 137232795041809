import React, { useState } from 'react';
import { useStyles } from './styles';
import axios from '../../../../app/axios';
import { Loader } from 'semantic-ui-react';
import AppSuccessModal from '../../SuccessModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { translate } from '../../../../i18n/i18n';

interface ServerData {
	path: string | null;
}

interface ServerResponse {
	response: ServerData;
}

export default function AppProfileChangeAvatar() {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const avatarImage =
		window.localStorage.getItem('avatar') !== 'null'
			? window.localStorage.getItem('avatar')!
			: '/defaultUser.png';

	const uploadedImage = React.useRef<any>(null);
	const imageUploader = React.useRef<any>(null);

	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const handleSuccess = (text: string) => {
		setShowSuccess(true);
		setSuccessMessage(text);
	};

	const handleImageUpload = async (e: any) => {
		setIsLoading(true);
		const [file] = e.target.files;
		if (file) {
			const reader = new FileReader();
			const { current } = uploadedImage;
			current.file = file;
			reader.onload = (e) => {
				current.src = e?.target?.result;
			};
			reader.readAsDataURL(file);

			let formData = new FormData();
			formData.append('file', file);

			axios
				.post<ServerResponse>(
					`${process.env.REACT_APP_API_URL}/api/file-upload`,
					formData,
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					},
				)
				.then((response: any) => {
					updateUserImage(response.data.default);
				})
				.catch((error: any) => {
					setIsLoading(false);
					console.log(error);
				});
		}
	};

	const updateUserImage = async (src: string) => {
		setIsLoading(true);
		await axios
			.put(`/app/user`, { avatar: src })
			.then(function (response) {
				setIsLoading(false);
				window.localStorage.setItem('avatar', src);
				handleSuccess(translate('app.successModal.imageSuccess'));
			})
			.catch(function (error) {
				setIsLoading(false);
				console.log(error);
			});
	};

	return (
		<div>
			<AppSuccessModal
				showModal={showSuccess}
				closeModal={() => setShowSuccess(false)}
				successMessage={successMessage}
			/>
			<div className={classes.Container}>
				<input
					type="file"
					accept="image/*"
					onChange={handleImageUpload}
					ref={imageUploader}
					name="avatar"
					style={{
						display: 'none',
					}}
				/>
				<div
					className={classes.ImageContainer}
					onClick={() => imageUploader.current.click()}
				>
					<img
						src={avatarImage}
						ref={uploadedImage}
						className={classes.Image}
					/>
					{isLoading && <CircularProgress />}
				</div>
			</div>
		</div>
	);
}
