import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import axios from "../../../app/axios";
import {fetchGroups, groupLoadingSelector} from "../../../store/groups/groupSlice";
import {translate} from "../../../i18n/i18n";
import {useHistory} from "react-router-dom";

interface AddGroupModalProps {
    showModal: boolean,
    closeModal: () => void,
}

interface AddGroupInterface {
    name: string;
}

interface AddGroupErrors {
    name?: string;
}

const AddGroupModal: React.FunctionComponent<AddGroupModalProps> = ({showModal, closeModal}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const groupSubmitting = useSelector(groupLoadingSelector);

    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();

    const history = useHistory();

    const initialValues: AddGroupInterface = {
        name: ""
    }

    useEffect(() => {
        if (isSubmitting) {
            setIsSubmitting(false);
            dispatch({
                ...fetchGroups({
                    requestType: "GET",
                    request: 'smartometer/groups',
                }),
            });
            closeModal();
        }
        ;
        if (!!groupSubmitting) setIsSubmitting(true)
    }, []);

    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = async (values: AddGroupInterface, {setErrors}: any) => {
        setIsSubmitting(true);
        const {name} = values;
        try {
            const createdGroupResponse = await axios.post("/groups", {name});
            closeModal();
            if(createdGroupResponse?.data?.success) {
                const { id } = createdGroupResponse?.data?.success;
                history.push(`/users/groups/detail/${id}`);
            }
        } catch (err) {
            if (err.response.data.error === "Name already exists") {
                setErrors({name: translate('modals.addGroupModal.errorNameExists')});
            }
        }
        setIsSubmitting(false)
    };

    const onValidate = (val: any) => {
        const errors: AddGroupErrors = {}
        if (!val.name) errors.name = translate('modals.addGroupModal.validateError');
        return errors;
    }

    return (
        <>
            <Modal showModal={showModal} header={translate('modals.addGroupModal.header')} onClose={closeModal}
                   onSubmit={handleFormikSubmit} isLoading={isSubmitting} submitActionTitle={translate('modals.addGroupModal.createGroup')}
                   closeActionTitle={translate('modals.addGroupModal.close')} submitActionColor={color.link} disabled={isSubmitting}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched}) => (
                        <TextField className={classes.Input} error={touched.name && !!errors.name}
                                   helperText={touched.name && !!errors.name && errors.name} id="name"
                                   value={values.name} label="Name" name="name" variant="outlined"
                                   onChange={handleChange} onBlur={handleBlur}/>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

export default AddGroupModal;
