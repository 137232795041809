import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    Divider: {
        margin: '0px !important'
    },
    Container: {
        margin: '32px !important'
    },
    TitleContainer: {
        padding: '0px !important'
    },
    LinkContainer: {
        padding: '0px !important',
        textAlign: 'center'
    },
    Link: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}))