import React from 'react';
import { useStyles } from './styles';
import { translate } from '../../../../i18n/i18n';
import CourseCompleteIcon from '../../../../assets/images/course-complete.png';
import CourseCompleteIconActive from '../../../../assets/images/course-complete-active.png';

interface ContentCardProps {
	data: CardData;
	isSelected?: boolean;
	isActive?: boolean;
	selectActiveCourse?: (id: number) => void;
	isCompleted?: boolean;
	isDelayed?: boolean;
	isFuture?: boolean;
	isBlocked?: boolean;
}

interface CardData {
	id?: number;
	product_id?: number;
	product_name?: string;
	start?: string;
	end?: string;
}

const ContentCard: React.FunctionComponent<ContentCardProps> = ({
	data,
	isSelected,
	selectActiveCourse,
	isCompleted,
	isActive,
	isDelayed,
	isFuture,
	isBlocked = false,
}) => {
	const classes = useStyles();

	return (
		<div
			onClick={
				selectActiveCourse
					? () => selectActiveCourse(data.id || 0)
					: undefined
			}
			className={`${classes.Card} ${isSelected && 'active'} ${
				isDelayed && 'delayed'
			} ${isFuture && 'future'} `}
		>
			<div className={classes.Header}>
				<div
					className={`${classes.Title} ${isSelected && 'active'}  ${
						isDelayed && 'delayed'
					} ${isFuture && 'future'} `}
				>
					{' '}
					{data.product_name}{' '}
				</div>
				{isActive && (
					<div
						className={`${classes.Active} ${
							isSelected ? 'active' : ''
						}  ${isCompleted && 'completed'}  ${
							isDelayed && 'delayed'
						} ${isFuture && 'future'} `}
					>
						{' '}
						{translate(`app.selectCourse.selectCard.active`)}{' '}
					</div>
				)}

				{!!isCompleted &&
					(isSelected ? (
						<img
							className={classes.CompleteIcon}
							src={CourseCompleteIconActive}
						/>
					) : (
						<img
							className={classes.CompleteIcon}
							src={CourseCompleteIcon}
						/>
					))}
			</div>

			<div className={classes.DatesContainer}>
				<div className={classes.startDate}>
					<div
						className={`${classes.dateLabel} ${
							isSelected && 'active'
						}  ${isDelayed && 'delayed'} `}
					>
						{translate(`app.selectCourse.selectCard.start`)}
					</div>
					<div
						className={`${classes.date} ${
							isSelected && 'active'
						}  ${isDelayed && 'delayed'} ${isFuture && 'future'} `}
					>
						{data.start}
					</div>
				</div>
				<div className={classes.endDate}>
					<div
						className={`${classes.dateLabel} ${
							isSelected && 'active'
						}  ${isDelayed && 'delayed'} `}
					>
						{translate(`app.selectCourse.selectCard.end`)}
					</div>
					<div
						className={`${classes.date} ${
							isSelected && 'active'
						}  ${isDelayed && 'delayed'} ${isFuture && 'future'} `}
					>
						{data.end}
					</div>
				</div>
			</div>
			{isBlocked && (
				<div className={`${classes.Blocker} app-blocker`}>
					<p>
						Ein Kurswechsel innerhalb der App ist nicht möglich.
						Bitte nutzen Sie die App{' '}
						<strong>{data.product_name}</strong> zum Lernen.
					</p>
				</div>
			)}
		</div>
	);
};

export default ContentCard;
