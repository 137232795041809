import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	node: {
		minWidth: '100%',
		position: 'relative',
	},
	rowWrapper: {
		height: '100%',
		boxSizing: 'border-box',
		cursor: 'pointer',
		lineHeight: '50px',

		'&:hover': {
			opacity: 0.7,
		},

		'&:active': {
			opacity: 1,
		},
	},

	rowWrapperDragDisabled: {
		cursor: 'default',
	},

	row: {
		maxWidth: '100%',
		height: '100%',
		whiteSpace: 'nowrap',
		display: 'flex',
		position: 'relative',
		'& > *': {
			boxSizing: 'border-box',
		},
	},
	rowLandingPad: {
		border: 'none',
		boxShadow: 'none',
		outline: 'none',

		'& *': {
			opacity: '0 !important',
		},

		'&::before': {
			backgroundColor: 'lightblue',
			border: '2px dotted black',
			content: '',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: -1,
		},
	},
	rowCancelPad: {
		border: 'none',
		boxShadow: 'none',
		outline: 'none',

		'& *': {
			opacity: '0 !important',
		},

		'&::before': {
			border: '2px dotted black',
			content: '',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: -1,
			backgroundColor: '#e6a8ad',
		},
	},

	/**
	 * Nodes matching the search conditions are highlighted
	 */
	rowSearchMatch: {
		boxShadow: 'inset 0 -7px 7px -3px #0080ff',
	},

	/**
	 * The node that matches the search conditions and is currently focused
	 */
	rowSearchFocus: {
		boxShadow: 'inset 0 -7px 7px -3px #fc6421',
	},

	toolbarButton: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},

	rowToolbar: {
		verticalAlign: 'middle',
		flex: '0 1 auto',
		display: 'flex',
	},

	rowLabel: {
		display: 'flex',
		flex: '0 1 auto',
		paddingRight: '20px',
		fontSize: 16,
		maxWidth: '100%',
		alignContent: 'center',

		'& div': {
			marginTop: 1,
			flexShrink: 0,
			flexGrow: 0,
		},

		'& span': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},

	rowLabelHidden: {
		// opacity: '.5',
	},

	rowContents: {
		verticalAlign: 'middle',
		position: 'relative',
		height: '100%',
		flex: '1 0 auto',
		maxWidth: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},

	collapseButton: {
		backgroundSize: 9.5,
		backgroundPosition: 'right top 11px',
		backgroundRepeat: 'no-repeat',
		appearance: 'none',
		border: 'none',
		background: 'transparent',
		padding: 0,
		zIndex: 2,
		position: 'absolute',
		top: '45%',
		width: 20,
		height: 20,
		transform: 'translate3d(-50%, -50%, 0)',
		cursor: 'pointer',

		'&::after': {
			content: '',
			position: 'absolute',
			transformOrigin: '7px 4px',
			transform: 'translate3d(-50%, -20%, 0)',
			border: 'solid transparent 10px',
			borderLeftWidth: 7,
			borderRightWidth: 7,
			borderTopColor: 'gray',
		},

		'&:hover::after': {
			borderTopColor: 'black',
		},

		'&:focus': {
			outline: 'none',
		},

		'&:focus::after': {
			filter: 'drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9)',
		},
	},

	expandButton: {
		backgroundSize: 9,
		backgroundPosition: 'right top 8px',
		backgroundRepeat: 'no-repeat',
		appearance: 'none',
		border: 'none',
		background: 'transparent',
		padding: 0,
		zIndex: 2,
		position: 'absolute',
		top: '45%',
		width: 20,
		height: 20,
		transform: 'translate3d(-50%, -50%, 0)',
		cursor: 'pointer',

		'&::after': {
			content: '',
			position: 'absolute',
			transformOrigin: '7px 4px',
			transform: 'transform: translate3d(-50%, -20%, 0) rotateZ(-90deg)',
			border: 'solid transparent 10px',
			borderLeftWidth: 7,
			borderRightWidth: 7,
			borderTopColor: 'gray',
		},

		'&:hover::after': {
			borderTopColor: 'black',
		},

		'&:focus': {
			outline: 'none',
		},

		'&:focus::after': {
			filter: 'drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9)',
		},
	},

	/**
	 * Line for under a node with children
	 */
	lineChildren: {
		height: '100%',
		display: 'inline-block',
	},

	/* ==========================================================================
         Scaffold
      
          Line-overlaid blocks used for showing the tree structure
         ========================================================================== */
	lineBlock: {
		height: '100%',
		position: 'relative',
		display: 'inline-block',
		flex: '0 0 auto',
	},

	absoluteLineBlock: {
		height: '100%',
		position: 'absolute',
		display: 'inline-block',
		flex: '0 0 auto',
		top: 0,
	},
}));
