import { makeStyles } from '@material-ui/core';
import { color } from '../../../utils/styles';

export const useStyles = makeStyles(theme => ({
	NavBar: {
		backgroundColor: color.textDark,
		height: 60,
		width: 'calc(100% + 60px)',
		position: 'absolute',
		left: -30,
		top: -40,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 50px 0 60px',
		color: color.white,
		zIndex: 2,

		'& .MuiFormControlLabel-label': {
			fontSize: 16,
		},

		'& .MuiSwitch-track': {
			backgroundColor: '#fff',
		},

		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: color.link,
		},

		'& .MuiSwitch-colorSecondary.Mui-checked': {
			color: color.link,
		},
	},
	ButtonWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	IconButton: {
		cursor: 'pointer',
		marginLeft: 40,
	},
	IconButtonDisabled: {
		opacity: 0.5,
		cursor: 'default',
	},
	dropdownIconWrapper: {
		display: 'flex'
	}
}));
