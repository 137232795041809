import React, {useCallback, useEffect, useState} from 'react';
import {useStyles} from './styles';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../../../components/shared/Button';
import {color} from '../../../../utils/styles';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {SingleWord} from "./SingleWord";
import {translate} from '../../../../i18n/i18n';

interface OrderWordsProps {
    onEdit: Function,
    onSave: Function,
    onAbort: Function,
    multipleAnswers?: any
}


const OrderWords: React.FunctionComponent<OrderWordsProps> = ({onEdit, onSave, onAbort, multipleAnswers}) => {
    const classes = useStyles();

    useEffect(() => {
        if (multipleAnswers) {
            setAnswers(multipleAnswers)
            setIsEditRequest(true)
        }
    }, []);


    const [isEditRequest, setIsEditRequest] = useState(false);
    const [answers, setAnswers] = useState<any>([]);
    const [newOption, setNewOption] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const [selectedAnswerToEdit, setAnswer] = useState('');

    const [hoveredAnswer, setHoveredAnswer] = useState(-1);
    const [editAnswer, setEditAnswer] = useState(-1);

    const [editError, setEditError] = useState(false)
    const [createError, setCreateError] = useState(false)

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewOption(event.target.value)
    }

    const handleSelectedOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption((event.target as HTMLInputElement).value);
    };

    const handleSaveQuestion = () => {
        onSave(answers)
    }

    const handleEditQuestion = () => {
        onEdit(answers)
    }

    const deleteOption = (index: number) => {
        var arrayOptions = answers
        arrayOptions.splice(index, 1);
        setAnswers(arrayOptions)
    }

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(event.target.value)
    }

    const handleEditAnswer = (i: number) => {
        setEditAnswer(i)
        const answerText = answers[i].text;
        setAnswer(answerText)
        setEditError(false)
    }

    const addNewOption = (e: any) => {
        if (e.key === 'Enter') {
            const checkForExistingAnswers = answers.filter((answer: any) => answer.text === newOption);
            if (checkForExistingAnswers.length >= 1) {
                setCreateError(true)
            } else if (newOption) {
                var newRecord = {
                    text: newOption,
                    value: false
                }

                var joined = answers.concat(newRecord);
                setAnswers(joined)
                setNewOption('')
                setCreateError(false)
            }
        }
    }

    const handleBlur = (e: any, index: number) => {

        const checkForExistingAnswers = answers.filter((answer: any, key: any) => answer.text === selectedAnswerToEdit && key !== index)
        if (checkForExistingAnswers.length >= 1) {
            setEditError(true)
        } else {
            const localAnswers = answers
            localAnswers[index].text = selectedAnswerToEdit
            setEditAnswer(-1);
            setEditError(false)
        }
    };

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragAnswer = answers[dragIndex]
            let filteredAnswers = answers.filter((answer: any) => dragAnswer.text !== answer.text);
            filteredAnswers.splice(hoverIndex, 0, dragAnswer);
            setAnswers(filteredAnswers);
        },
        [answers],
    )

    return (
        <div>
            <FormControl component="fieldset">
                <DndProvider backend={HTML5Backend}>
                    {answers.map(function (answer: any, i: any) {
                        return (
                            <SingleWord
                                key={`single-word-${i}`}
                                index={i}
                                moveCard={moveCard}
                                id={answer.id}
                                editAnswer={editAnswer}
                                editError={editError}
                                onClick={() => handleEditAnswer(i)}
                                answer={answer}
                                value={selectedAnswerToEdit}
                                onChange={handleAnswerChange}
                                onBlur={(event) => handleBlur(event, i)}
                                handleDelete={deleteOption}/>
                        );
                    })}
                </DndProvider>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Radio style={{margin: 0}} disabled/>
                    <input className={classes.TitleInput} name="new-question" type="text" value={newOption}
                           onChange={handleOptionChange} onKeyPress={addNewOption} placeholder={translate('authoring.orderWords.addAnswer')}/>
                </div>
                {createError && <span className={classes.errorLabel}>{translate('authoring.orderWords.answerExists')}</span>}
            </FormControl>
            <br/>
            <div style={{marginTop: 50}}>
                {isEditRequest && <Button
                    title={translate('authoring.orderWords.editQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => handleEditQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                {!isEditRequest && <Button
                    title={translate('authoring.orderWords.saveQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => handleSaveQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                <Button
                    title={translate('authoring.orderWords.cancel')}
                    type="outline"
                    color={color.link}
                    onClick={() =>
                        onAbort()
                    }
                />
            </div>
        </div>
    );
};

export default OrderWords;
