import { makeStyles } from '@material-ui/core/styles';
import { color, lineHeightSizes, textSizes } from '../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	selectAll: {
		position: 'absolute',
		top: 0,
		width: 100,
	},
	label: {
		position: 'relative',
	},
	formControl: {
		margin: 0,
		minWidth: 120,
	},
	Select: {
		height: 44,
		'& .MuiSelect-select:focus': {
			background: 'none',
		},
	},
	SelectLabel: {
		transform: 'translate(14px, 16px) scale(1)',
	},
	Multiselect: {
		height: 32,
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'inline-flex !important',
		minWidth: '230px !important',
		width: 'auto !important',
		marginTop: 3,
		marginBottom: '0 !important',
		marginRight: 32,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& i': {
			paddingTop: '8px !important',
		},
	},
	FilterButton: {
		height: 38,
		position: 'relative',
		float: 'right',
		marginRight: 0,
		'& .MuiFormControlLabel-label': {
			color: color.darkGray,
		},
		'& img': {
			height: 12,
			width: 12,
			marginRight: 8,
		},
		'& svg': {
			marginRight: 4,
		},
	},
	SearchButton: {
		height: 38,
		position: 'relative',
		float: 'right',
		marginRight: '37px',
		'& img': {
			height: 15,
			width: 15,
			marginRight: 8,
			'&.active': {
				height: 12,
				width: 12,
			},
		},
	},
	Input: {
		width: '363px',
		position: 'relative',
		marginRight: 22,
		height: 32,
		marginTop: 3,
		'& .MuiInputBase-root': {
			height: 32,
			'& .MuiOutlinedInput-input': {
				padding: '4px 24px',
			},
		},
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			bottom: '0',
		},
	},
	searchAndFilterInfo: {
		fontSize: textSizes.bodyNormal,
		color: color.lightGray,
		lineHeight: '35px',
		display: 'inline-block',
		marginRight: '10px',
		'&:last-of-type': {
			marginRight: '37px',
		},
	},
}));
