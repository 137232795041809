import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface initialStateInterface {
	data: any;
	currentGroup: any;
	currentKPIs: any;
	isLoading: boolean;
	error: string;
}

const initialState: initialStateInterface = {
	data: {},
	currentGroup: null,
	currentKPIs: null,
	isLoading: false,
	error: '',
};

export const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {
		fetchGroups: (state, action: any) => {
			if (action.state === 'send') {

			}
			if (action.state === 'finished' && !!action.response.success) {
				state.data = { ...action.response.success };
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		fetchGroup: (state, action: any) => {
			if (action.state === 'send') {

			}
			if (action.state === 'finished' && !!action.response.success) {
				state.currentGroup = action.response.success[0];
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		fetchGroupKPIs: (state, action: any) => {
			if (action.state === 'send') {

			}
			if (action.state === 'finished') {
				state.currentKPIs = action.response;
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		addGroup: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
		},
		updateGroup: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
			if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
        deleteGroup: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }
        },
		deleteGroups: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
		},
		resetGroupDetailData: (state) => {
			state.currentKPIs = null;
			state.currentGroup = null;
		},
	},
});

export const {
	fetchGroups,
	addGroup,
	updateGroup,
    deleteGroup,
	deleteGroups,
	fetchGroup,
	fetchGroupKPIs,
	resetGroupDetailData,
} = groupSlice.actions;
export const groupSelector = (state: RootState) => state.group;
export const groupLoadingSelector = (state: any) => state.group.isLoading;
export default groupSlice.reducer;
