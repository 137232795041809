import {translate} from "../../../i18n/i18n";
import React from 'react'

import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";

interface AddDemoDataProps {
    showModal: boolean,
    closeModal: () => void,
    onWithDemoData: () => void,
    onWithoutDemoData: () => void,
}

const AddDemoDataModal: React.FunctionComponent<AddDemoDataProps> = ({
                                                                         showModal,
                                                                         closeModal,
                                                                         onWithDemoData,
                                                                         onWithoutDemoData
                                                                     }) => {
    const classes = useStyles();

    return (<>

        <Modal showModal={showModal} header={''} onClose={() => {
            closeModal()
        }}
               onSubmit={onWithDemoData} isLoading={false} submitActionTitle={translate(`withExampleData`)}
               secondaryActionTitle={translate(`withoutExampleData`)}
               secondaryAction={onWithoutDemoData}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               buttonWrapper={classes.MainButtonWrapper}
        >
            <div className={classes.image}>
                <h2 className={classes.header}>{translate('modals.addDemoDataModal.header')}</h2>
            </div>
        </Modal>
    </>);
};

export default AddDemoDataModal;
