import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles(theme => ({
	ListWrapper: {
		marginTop: 25,
	},
	label: {
        position: "relative"
    },
    TitleInput: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: '#9C9C9C'
        }
    },

    explainBanner: {
        background: '#D0E9FF',
        padding: '4px 8px',
        borderRadius: '7px'
    },
	selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
}));
