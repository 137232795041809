import Chart from "react-apexcharts";
import React from "react";
import {color} from "../../../../utils/styles";
import {useStyles} from "./styles";
import {GraphHeader} from "../GraphHeader";

interface LineChartProps {
    values: number[],
    labels: string[]
}

export const PieChart: React.FunctionComponent<LineChartProps> = ({values, labels}) => {
    const classes = useStyles();
    return <div className={classes.wrapper}>

            <Chart options={{
                chart: {
                    type: 'pie',
                    height: '350px',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {  
                        show: false
                    }
                },
                labels,
                colors: ['#6CB9FF', '#FFDC5E', '#F2994A'],
                legend: {
                    show: true,
                },
                dataLabels: {
                    enabled: false
                },

            }} series={values} type="pie" height={350} width={230}/>

    </div>

}
