import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { Add } from '@material-ui/icons';
import { InputAdornment, Input, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Resizable } from 're-resizable';

import SortableTree, {
	getTreeFromFlatData,
	// @ts-ignore
} from '@nosferatu500/react-sortable-tree';

import { useStyles } from './styles';
import DnDArea from '../../authoring/DnDArea';
import { useParams } from 'react-router';
import { translate } from '../../../i18n/i18n';
import { AppucationsTheme } from './TreeRenderer';
import axios from '../../../app/axios';
import { whileStatement } from '@babel/types';

interface AuthoringSidebarProps {
	handleNavigateToSubarea: Function;
	handleNavigate: Function;
	disableActions: boolean;
	subareas: Array<any>;
	handleCreateArea: Function;
	refetchData: Function;
	subarea: any;
	nestedSubareas: any[];
}

const AuthoringSidebar: React.FunctionComponent<AuthoringSidebarProps> = ({
	handleNavigateToSubarea,
	handleNavigate,
	disableActions,
	subareas,
	handleCreateArea,
	refetchData,
	subarea,
	nestedSubareas,
}) => {
	const classes = useStyles();
	const [sidebarWidth, setSidebarWidth] = useState(300);
	const [treeData, setTreeData] = useState([]);
	const [subareaSearchValue, setSubareaSearchValue] = useState('');
	const { courseId, page } = useParams<{ courseId: string; page: string }>();
	const handleNavigateToSubareaById = (id: number) => {
		const index = subareas.indexOf(subareas.find((item) => item.id === id));
		handleNavigateToSubarea(index, id);
	};
	const [expandedNodes, setExpandedNodes] = useState<number[]>([]);

	useEffect(() => {
		setTimeout(() => {
			calculateHiddenRows();
		}, 100);
	});

	const calculateHiddenRows = () => {
		const treeItems = document.getElementsByClassName('tree-item');
		let hiddenIds: number[] = [];
		for (let i = 0; i < treeItems.length; i++) {
			const item = treeItems[i];
			if (item.classList.contains('rowHidden')) {
				hiddenIds.push(
					Number((item as HTMLElement).dataset.treeitemid),
				);
			} else {
				let shouldHide = false;
				let skip = false;
				hiddenIds.forEach((id) => {
					if (skip) return;
					if (item.classList.contains(`child-${id}`)) {
						shouldHide = true;
						skip = true;
					}
				});
				if (shouldHide) {
					item.classList.add('childHidden');
				} else {
					item.classList.remove('childHidden');
				}
			}
		}
	};

	useEffect(() => {
		if (!!subareas) {
			let expandedNodesCopy = [...expandedNodes];
			if (!!page && !isNaN(+page) && !expandedNodes.length) {
				// we initialize the page with a selected subpage (coming back from preview maybe)
				let subareaId: any = +page;
				while (subareaId !== null) {
					const subarea = subareas.find(
						(subarea: any) => subarea.id === subareaId,
					);
					if (subarea) {
						subareaId = subarea.sub_area_id;
						expandedNodesCopy.push(subareaId);
					} else subareaId = null;
				}
				setExpandedNodes(expandedNodesCopy);
			}
			const editedSubareas = subareas.map((subarea: any) => ({
				...subarea,
				expanded: expandedNodesCopy.indexOf(subarea.id) > -1,
			}));
			setTreeData(
				getTreeFromFlatData({
					flatData: editedSubareas,
					getKey: (item: any) => item.id,
					getParentKey: (item: any) => item.sub_area_id,
					rootKey: 'null',
				}),
			);
		} // edit subareas first to add expanded prop, and then settreefromflatdata with that data
	}, [subareas]);

	const handleSubareaClick = (e: any, node: any) => {
		if (e.target.classList.contains('expand-button')) return;
		e.stopPropagation();
		handleNavigateToSubareaById(node.node.id);
	};

	const getSubareaTreeData = () => {
		if (!subareaSearchValue) return treeData;
		return subareas.filter((subarea: any) =>
			subarea.headline
				.toLowerCase()
				.replace(' ', '')
				.includes(subareaSearchValue.toLowerCase().replace(' ', '')),
		);
	};

	const getReorderItems = (path: any, treeData: any) => {
		const reorders = [];
		let siblingSubareas = [...treeData];
		let parentSubarea: any = null;
		for (let i = 0; i < path.length; i++) {
			parentSubarea = siblingSubareas.find(
				(item: any) => item.id === path[i],
			);
			siblingSubareas = parentSubarea.children;
		}
		for (let i = 0; i < siblingSubareas.length; i++) {
			reorders.push({
				id: siblingSubareas[i].id,
				sub_area_id: parentSubarea ? parentSubarea.id : null,
				order: i + 1,
			});
		}
		return reorders;
	};

	const handleDragChange = async (params: any) => {
		const { path, prevPath, prevTreeIndex, nextTreeIndex, treeData } =
			params;
		const isSameParent = path.join(',') === prevPath.join(',');
		if (isSameParent && prevTreeIndex === nextTreeIndex) return; // nothing changed!
		let reorders: any[] = [];
		const parentPath = [...path];
		parentPath.pop();
		const parentReorders = getReorderItems(parentPath, treeData);
		reorders = [...reorders, ...parentReorders];
		if (!isSameParent) {
			const prevParentPath = [...prevPath];
			prevParentPath.pop();
			const prevParentReorders = getReorderItems(
				prevParentPath,
				treeData,
			);
			reorders = [...reorders, ...prevParentReorders];
		}
		setTreeData(treeData);
		try {
			await axios.put(`/courses/${courseId}/subarea-reorder`, {
				reorders,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleExpandItem = (props: any) => {
		const { node } = props;
		const newExpandedNodes = [...expandedNodes];
		const nodeIndexInExpanded = newExpandedNodes.indexOf(node.id);
		if (nodeIndexInExpanded > -1) {
			newExpandedNodes.splice(nodeIndexInExpanded, 1);
		} else {
			newExpandedNodes.push(node.id);
		}
		setExpandedNodes(newExpandedNodes);
		// calculateHiddenRows();
	};

	return (
		<Resizable
			className={classes.SideNavResizeable}
			size={{ width: sidebarWidth, height: 'calc(100vh - 108px)' }}
			enable={{
				top: false,
				right: true,
				bottom: false,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			maxWidth={500}
			minWidth={300}
			onResizeStop={(e, direction, ref, d) => {
				setSidebarWidth(sidebarWidth + d.width);
			}}
		>
			<div
				className={`${classes.SideNavTab} ${
					!page ? classes.SideNavTabActive : null
				} ${classes.SideNavTabIntro}`}
				onClick={() => {
					handleNavigate('home');
				}}
			>
				<FontAwesomeIcon icon={faChartPie} />
				<span>{translate('layout.authoringSidebar.introduction')}</span>
			</div>
			<Input
				className={classes.SearchInput}
				placeholder={translate('shared.tableSearch.nameAndEmail')}
				value={subareaSearchValue}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setSubareaSearchValue(e.target.value)
				}
				name="name"
				endAdornment={
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				}
			/>
			<div
				className={`${disableActions ? 'disabled' : ''} ${
					classes.AddItem
				}`}
			>
				<a
					onClick={
						!disableActions ? () => handleCreateArea() : () => {}
					}
				>
					<Add />
					{translate('layout.authoringSidebar.addSection')}
				</a>
			</div>
			<div className={classes.TreeWrapper}>
				<SortableTree
					getNodeKey={({ node }: { node: any }) => node.id}
					onMoveNode={handleDragChange}
					treeData={getSubareaTreeData()}
					canDrag={!!!subareaSearchValue}
					onChange={(treeData: any) => setTreeData(treeData)}
					theme={AppucationsTheme}
					generateNodeProps={(rowinfo: any) => ({
						onClick: (e: any) => handleSubareaClick(e, rowinfo),
					})}
					onVisibilityToggle={handleExpandItem}
				/>
			</div>
		</Resizable>
	);
};

export default AuthoringSidebar;
