import React, { useEffect, useState } from 'react';
import { Close, Menu } from '@material-ui/icons';

import PreviewListItem from '../PreviewListItem';
import PreviewNavigationHeader from '../PreviewNavigationHeader';
import PreviewTabNavigation from '../PreviewTabNavigation';

interface Subarea {
	id: number;
	subareas: Array<Subarea>;
	headline: string;
	is_exam: boolean;
	is_exercise: boolean;
	sub_area_id: number;
	wysiwyg: string;
}

interface PreviewNavigationProps {
	handleToggle: Function;
	showDetail: boolean;
	inExerciseNav: boolean;
	handleToggleTab: Function;
	subareas: Array<Subarea>;
	childId: number;
	parentId: number | null;
	courseName: string;
	displayParentName: string;
	handleNavigateDown: Function;
	handleNavigateUp: Function;
}

const PreviewNavigation: React.FunctionComponent<PreviewNavigationProps> = ({
	handleToggle,
	showDetail,
	inExerciseNav,
	handleToggleTab,
	handleNavigateDown,
	handleNavigateUp,
	subareas,
	childId,
	parentId,
	displayParentName,
	courseName,
}) => {
	const [regularSubareas, setRegularSubareas] = useState<Subarea[]>([]);
	const [exerciseSubareas, setExerciseSubareas] = useState<Subarea[]>([]);
	const [examSubareas, setExamSubareas] = useState<Subarea[]>([]);

	useEffect(() => {
		const contentAreas = subareas.filter(
			(area: Subarea) => !area.is_exercise,
		);
		const exerciseAreas = subareas.filter(
			(area: Subarea) => area.is_exercise && !area.is_exam,
		);
		const examAreas = subareas.filter(
			(area: Subarea) => area.is_exercise && area.is_exam,
		);

		setRegularSubareas(contentAreas);
		setExerciseSubareas(exerciseAreas);
		setExamSubareas(examAreas);
	}, [subareas]);

	return (
		<div
			className={`preview-window-navigation ${
				!showDetail ? 'preview-window-navigation-showing' : ''
			}`}
		>
			<PreviewNavigationHeader
				handleClose={handleToggle}
				handleNavigate={handleNavigateUp}
				subareaName={
					parentId !== -1
						? subareas.find((item) => item.id === parentId)!
								.headline
						: courseName
				}
				parentName={displayParentName}
				showClose={childId !== -1}
			/>
			<div className="preview-window-navigation-content">
				{regularSubareas.map((item) => (
					<PreviewListItem
						active={item.id === childId}
						key={item.id}
						item={item}
						parentId={parentId}
						handleClick={() => {
							handleNavigateDown(item.id);
						}}
					/>
				))}
				{!!exerciseSubareas.length && (
					<>
						<p className="preview-window-navigation-separator">
							Übungen
						</p>
						{exerciseSubareas.map((item) => (
							<PreviewListItem
								active={item.id === childId}
								key={item.id}
								item={item}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(item.id);
								}}
							/>
						))}
					</>
				)}
				{!!exerciseSubareas.length && (
					<>
						<p className="preview-window-navigation-separator">
							Prüfungen
						</p>
						{examSubareas.map((item) => (
							<PreviewListItem
								active={item.id === childId}
								key={item.id}
								item={item}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(item.id);
								}}
							/>
						))}
					</>
				)}
			</div>
			<PreviewTabNavigation
				inExerciseNav={inExerciseNav}
				handleToggleTab={handleToggleTab}
			/>
			<div
				className="preview-window-navigation-desktop-toggle"
				onClick={() => handleToggle()}
			>
				{showDetail ? <Menu /> : <Close />}
			</div>
		</div>
	);
};

export default PreviewNavigation;
