import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL+ "/api/smartometer",
//     headers: {
//         'Authorization': `Bearer ${window.localStorage.getItem("jwt")}`
//     }
// });
//
// export default instance;

const fetchClient = () => {
	const defaultOptions: AxiosRequestConfig = {
		baseURL: process.env.REACT_APP_API_URL + '/api/smartometer',
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// Create instance
	let instance = axios.create(defaultOptions);

	// Set the AUTH token for any request
	instance.interceptors.request.use(function (config) {
		const jwt = window.localStorage.getItem('jwt');
		config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';
		return config;
	});

	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response.status === 401) {
				window.localStorage.clear();
				window.location.reload();
			}
		},
	);

	return instance;
};

export default fetchClient();
