import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
	HelperWrapper: {
		width: 20,
		height: 20,
		marginLeft: 5,
		position: 'relative',
	},
	Icon: {
		'&:hover + div': {
			display: 'block',
		},
	},
	HelperText: {
		display: 'none',
		zIndex: 3000,
		position: 'absolute',
		top: 25,
		borderRadius: 5,
		padding: 5,
		width: 200,
		lineHeight: 1.5,
		fontWeight: 400,
		fontSize: 12,
		backgroundColor: '#fff',
		border: '1px solid #e0e0e0',
		whiteSpace: "normal",
	},
}));
