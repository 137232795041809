import React, { useEffect, useState } from 'react';
import PreviewContent from './PreviewContent';
import PreviewContentHeader from './PreviewContentHeader';
import PreviewNavigation from './PreviewNavigation/index';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchSubareaForPreview,
	subAreaSelector,
} from '../../../store/subareas/subareaSlice';
import { PreviewExercises } from './PreviewExercises';
import PreviewLightbox from './PreviewLightbox';

interface PreviewProps {
	course: any;
	device: string;
	parentId: number;
	childId: number;
	showDetail: boolean;
	inExerciseNav: boolean;
	handleUpdateParams: Function;
	handleNavigateTo: Function;
}

export interface SubAreaInterface {
	sub_area_id: number | null;
	id: number;
	wysiwyg: string;
	headline: string;
	is_exam: boolean;
	is_exercise: boolean;
	subareas: any[];
	is_finished: boolean;
}

const Preview: React.FunctionComponent<PreviewProps> = ({
	course,
	device,
	parentId,
	childId,
	showDetail,
	inExerciseNav,
	handleUpdateParams,
	handleNavigateTo,
}) => {
	const dispatch = useDispatch();
	const { preview } = useSelector(subAreaSelector);
	const [lightboxSrc, setLightboxSrc] = useState('');

	const [questions, setQuestions] = useState([]);
	useEffect(() => {
		dispatch({
			...fetchSubareaForPreview({
				requestType: 'GET',
				request: `smartometer/preview/course/${course.id}/subarea/${childId}`,
			}),
		});
	}, [course, childId]);

	useEffect(() => {
		if (preview && !!preview?.subarea?.questions.length) {
			setQuestions(preview.subarea.questions);
		}
		document
			.querySelectorAll('.preview-window-content-wysiwyg img')
			.forEach((element) => {
				if (!element.closest('.preview-lightbox-wrapper')) {
					const wrapper = document.createElement('div');
					wrapper.setAttribute(
						'src',
						element.getAttribute('src') || '',
					);
					wrapper.classList.add('preview-lightbox-wrapper');
					element.parentNode!.appendChild(wrapper);
					wrapper.appendChild(element);
				}
			});
		document
			.querySelectorAll('.preview-lightbox-wrapper')
			.forEach((el) =>
				el.addEventListener('click', addLightboxFunctionality),
			);
		return () => {
			document
				.querySelectorAll('.preview-lightbox-wrapper')
				.forEach((el) =>
					el.removeEventListener('click', addLightboxFunctionality),
				);
		};
	}, [preview]);

	const addLightboxFunctionality = (e: Event) => {
		const target = e.target as HTMLElement;
		setLightboxSrc(target.getAttribute('src') || '');
	};

	const visibleSubareas = course.subareas.filter((item: SubAreaInterface) => {
		if (!inExerciseNav) {
			if (parentId === -1) {
				return item.sub_area_id === null;
			} else return item.id === parentId || item.sub_area_id === parentId;
		} else {
			return item.is_exam || item.is_exercise;
		}
	});
	const currentItem: SubAreaInterface = visibleSubareas.find(
		(item: SubAreaInterface) => item.id === childId,
	);

	const handleToggleNavigation = () => {
		handleUpdateParams('show', showDetail ? 0 : 1);
	};

	const handleToggleTab = () => {
		handleUpdateParams('exercise', inExerciseNav ? 0 : 1);
	};

	const getParentSubareaDisplayName = () => {
		if (parentId === -1) return '';
		const upperElementId = course.subareas.find(
			(item: SubAreaInterface) => item.id === parentId,
		).sub_area_id;
		if (!upperElementId) return course.name;
		return course.subareas.find(
			(item: SubAreaInterface) => item.id === upperElementId,
		).headline;
	};

	const handleNavigateUp = () => {
		const upperElementId = course.subareas.find(
			(item: SubAreaInterface) => item.id === parentId,
		).sub_area_id;
		handleNavigateTo(upperElementId || -1, upperElementId || -1);
	};

	const handleNavigateDown = (id: number, optParentId?: number) => {
		let newParentId: number = id;
		const newChildId: number = id;
		const element = course.subareas.find(
			(item: SubAreaInterface) => item.id === id,
		);
		if (!element.subareas.length) {
			newParentId = parentId;
		}
		if (optParentId) {
			newParentId = optParentId;
		}
		handleNavigateTo(newParentId, newChildId);
	};

	const getNextItem = () => {
		// if item has subareas, first subarea is next item, if not, then following item
		let nextItem: SubAreaInterface | null = null;
		if (
			currentItem &&
			currentItem?.subareas &&
			currentItem?.subareas?.length
		) {
			nextItem = currentItem.subareas[0];
		} else {
			const index = visibleSubareas.indexOf(currentItem);
			if (index !== -1) {
				nextItem = visibleSubareas[index + 1] || null;
			}
		}
		// if there is still no next item, go back up and check for the first level on which we have a sibling area

		if (!nextItem && currentItem) {
			let onEnd = false;
			let crawledItem = currentItem;
			while (!onEnd && !nextItem) {
				const parentItem = course.subareas.find(
					(item: SubAreaInterface) =>
						item.id === crawledItem.sub_area_id,
				);
				if (parentItem) {
					let nextAvailableItem =
						parentItem.subareas[
							parentItem.subareas.indexOf(crawledItem) + 1
						];
					if (!nextAvailableItem) {
						crawledItem = parentItem;
					} else {
						nextItem = nextAvailableItem;
					}
				} else {
					const rootSubareas = course.subareas.filter(
						(item: SubAreaInterface) => item.sub_area_id === null,
					);
					const siblingParentArea =
						rootSubareas[rootSubareas.indexOf(crawledItem) + 1];
					nextItem = siblingParentArea;
					if (!siblingParentArea) onEnd = true;
				}
			}
		}

		return nextItem;
	};
	return (
		<>
			{' '}
			{currentItem && (
				<div className="preview-window-content">
					<PreviewContentHeader
						subareaName={currentItem?.headline || ''}
						parentName={course.name}
						handleToggleMenu={handleToggleNavigation}
					/>
					{!currentItem.is_exercise ? (
						<PreviewContent
							currentItem={currentItem}
							nextItem={getNextItem()}
							handleNavigateTo={handleNavigateDown}
						/>
					) : (
						<></>
					)}

					{currentItem.is_exercise ? (
						<PreviewExercises
							questions={questions}
							currentItem={currentItem}
							nextItem={getNextItem()}
							device={device}
							handleNavigateTo={handleNavigateDown}
						/>
					) : (
						<></>
					)}
				</div>
			)}
			<PreviewNavigation
				displayParentName={getParentSubareaDisplayName()}
				childId={childId}
				handleNavigateDown={handleNavigateDown}
				handleNavigateUp={handleNavigateUp}
				parentId={parentId}
				courseName={course.name}
				subareas={visibleSubareas}
				inExerciseNav={inExerciseNav}
				handleToggleTab={handleToggleTab}
				showDetail={showDetail}
				handleToggle={handleToggleNavigation}
			/>
			{lightboxSrc !== '' && (
				<PreviewLightbox
					handleClose={() => setLightboxSrc('')}
					src={lightboxSrc}
				/>
			)}
		</>
	);
};

export default Preview;
