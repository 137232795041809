import React, { useState } from 'react';
import { useStyles } from './styles';
import AppProfileChangePasswordModal from './Modal';
import AppSuccessModal from '../../SuccessModal';

export default function AppProfileChangePassword() {
	const classes = useStyles();
	const [showModal, setShowModal] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const handleSuccess = (text: string) => {
		setShowSuccess(true);
		setSuccessMessage(text);
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<AppProfileChangePasswordModal
				showModal={showModal}
				closeModal={() => setShowModal(false)}
				handleSuccess={handleSuccess}
			/>
			<AppSuccessModal
				showModal={showSuccess}
				closeModal={() => setShowSuccess(false)}
				successMessage={successMessage}
			/>
			<div className={classes.Container}>
				<div className={classes.NameContainer}>
					<p className={classes.Label}>PASSWORT</p>
					<span className={classes.Input}>*******</span>
				</div>
				<span
					onClick={() => setShowModal(true)}
					className={`${classes.Edit} profile-edit-button`}
				>
					ÄNDERN
				</span>
			</div>
		</div>
	);
}
