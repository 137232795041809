import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useStyles } from './styles';
import { appSelector, setCustomFontSize } from '../../../store/app/appSlice';

const FontSizeToggle = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [hideItemWrapper, setHideItemWrapper] = useState(true);
	const classes = useStyles();
	const { customFontSize } = useSelector(appSelector);

	const location = useLocation();
	const contentParam = new URLSearchParams(location.search).get('content');
	const navigationParam = new URLSearchParams(location.search).get(
		'navigation',
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (document.querySelector('.app-content-container') !== null) {
			document
				.querySelector('.app-content-container')!
				.addEventListener('scroll', onScroll);
		}
		return () => {
			if (document.querySelector('.app-content-container') !== null) {
				document
					.querySelector('.app-content-container')!
					.removeEventListener('scroll', onScroll);
			}
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			setHideItemWrapper(false);
		} else {
			setTimeout(() => {
				setHideItemWrapper(true);
			}, 1000);
		}
	}, [isOpen]);

	useEffect(() => {
		onScroll();
	}, [navigationParam, contentParam]);

	const onScroll = () => {
		setIsOpen(false);
		setHideItemWrapper(true);
	};

	return (
		<div className={`${classes.ToggleWrapper} toggleWrapper`}>
			<div
				className={`${classes.ToggleButton} toggleButton`}
				onClick={() => setIsOpen(!isOpen)}
			>
				A
			</div>
			<div
				className={`${classes.ToggleItems} ${
					hideItemWrapper ? 'hidden' : ''
				} toggleItems`}
			>
				<div
					onClick={() => dispatch(setCustomFontSize(1))}
					className={`${classes.ToggleItem} ${
						isOpen ? 'visible' : ''
					} ${customFontSize === 1 ? 'selected' : ''}`}
				>
					A
				</div>
				<div
					onClick={() => dispatch(setCustomFontSize(1.2))}
					className={`${classes.ToggleItem} ${
						isOpen ? 'visible' : ''
					} ${customFontSize === 1.2 ? 'selected' : ''}`}
				>
					A
				</div>
				<div
					onClick={() => dispatch(setCustomFontSize(1.4))}
					className={`${classes.ToggleItem} ${
						isOpen ? 'visible' : ''
					}  ${customFontSize === 1.4 ? 'selected' : ''}`}
				>
					A
				</div>
			</div>
		</div>
	);
};

export default FontSizeToggle;
