import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { isPlatform, getPlatforms } from '@ionic/react';

import { useStyles } from './styles';
import BackgroundImage from '../../../../assets/images/bg-som.png';
import { UserContext } from '../../../../contexts/UserContext';
import Button from '../../../shared/Button';
import { translate } from '../../../../i18n/i18n';
import { color } from '../../../../utils/styles';
import {clearState} from "../../../../store/app/appSlice";
import {clearInstitutionState} from "../../../../store/institution/institutionSlice";
import {useDispatch} from "react-redux";
import {clearSettingsState} from "../../../../store/settings/settingSlice";

interface NoCourseModalProps {
	feedbackMail: null | string;
}

const NoCourseModal: React.FunctionComponent<NoCourseModalProps> = ({
	feedbackMail,
}) => {
	const classes = useStyles();
	const history = useHistory();
    const dispatch = useDispatch();
	const { user } = useContext(UserContext);
	const logout = () => {
        dispatch({
            ...clearState()
        })
        dispatch({
            ...clearSettingsState()
        })
		window.localStorage.clear();
		history.push('/auth/login');
	};

	return (
		<div>
			<Dialog fullScreen open={feedbackMail !== null}>
				<div className={`app-exercise-loader ${classes.Container}`}>
					<div className="app-exercise-loader-content">
						<div
							className={classes.BGImage}
							style={{
								backgroundImage: `url("${BackgroundImage}")`,
							}}
						></div>
						<div className={classes.Content}>
							<HelpOutline />
							<h3>{translate('app.noCourse.headline')}</h3>
							<p>{translate('app.noCourse.content')}</p>
							{!!feedbackMail && (
								<>
									<h4>
										{translate('app.noCourse.yourAdmin')}:
									</h4>
									<a
										href={`mailto:${feedbackMail}`}
										className={classes.FeedbackWrapper}
									>
										{feedbackMail}
									</a>
								</>
							)}
						</div>
						{!!user &&
							!!user.roles &&
							!!user.roles?.length &&
							isPlatform('desktop') && (
								<a
									className={classes.BackButton}
									onClick={() => history.push('/dashboard')}
								>
									{translate('app.noCourse.back')}
								</a>
							)}
						<div className={classes.LogoutLink} onClick={logout}>
							{translate('app.noCourse.logout')}
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
};

export default NoCourseModal;
