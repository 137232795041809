import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    TabWrapper: {

    },
    TabHead: {
        width: "100%",
        marginBottom: "44px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    TabContent: {
        display: "none",
    },
    TabContentActive: {
        display: "block"
    },
    Tab: {
        "& a": {
            color: color.textDark,
            cursor: "pointer",
            position: "relative",
            marginRight: "40px",
            paddingBottom: "12px",
            fontFamily: "Open Sans, sans-serif",
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 600,
            '&::after': {
                content: '""',
                display: 'inline-block',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: 0,
                height: "4px",
                background: "#1485EE",
                transition: ".3s ease-out"
            }
        }
    },
    TabActive: {
        '&::after': {
            width: "100% !important"
        }
    }
}))