export const color = {
    primary: '#1485EE', // blue
    lightPrimary: '#56CCF2',
    white: '#FFFFFF',
    borderGrey: '#E0E0E0',
    darkBackground: '#363740',
    textGrey: '#9C9C9C',
    textDark: '#252733',
    grey: '#BDBDBD',
    lightBlue: '#D0E9FF',
    chartBlue: '#6CB9FF',
    background: '#F7F8FC',
    link: "#1485EE",
    red: '#FF6737',
    error: "#D65045",
    success: "#7A9B53",
    published: '#D0E9FF',
    not_published: '#FFDC5F',
    errorRed: "#EB5757",
    lightGray: "#9FA2B4",
    lightestGrey: "#EAEAEA",
    darkGray: "#4B506D",
    success: "#27AE60",
    appCardGrey: '#F6F7F9',
    appCardGreen: '#27AE60',
    appActive: '#3070ED',
    appText: '#252733',
    appTextSecondary: '#C7C7C7'
  }

  export const textSizes = {
    headline: 24,
    h3: 18,
    bodyLarge: 18,
    bodyNormal: 16,
    bodySmall: 14,
    notice: 12
  }

  export const lineHeightSizes = {
      headline: "32px",
      searchinfo: "38px"
  }

  export const borderRadius = {
    large: '16px',
    medium: '8px',
      small: '4px'
  }

  export const margins = {
    buttons: '12px'
  }

export const SettingsH3 = {
  fontSize: textSizes.bodyLarge,
  fontWeight: 600,
  color: "#000",
  marginBottom: 12
}

export const SettingsP = {
  fontSize: textSizes.bodyNormal,
  fontWeight: 400,
  color: color.textGrey,
  marginBottom: 30
}

export const SettingsDivider = {
  marginTop: 40
}
