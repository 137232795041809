import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { translate, translateWithVariable } from '../../i18n/i18n';
import { UsersErrors } from '../../errors-enums';

export interface bulkErrorInterface {
	type: string;
	message: string;
}

interface initialUsersStateInterface {
	data: any;
    trigger: boolean;
	myUser: any;
	selectedUser: any;
	notAuthenticated: boolean;
	selectedUserKPIs: null;
	selectedMember: {
		id?: number | undefined;
		avatar: string;
		first_name: string;
		last_name: string;
		role: number | undefined;
		email: string;
	};
	selectedUserCourseKPIs: any;
	isLoading: boolean;
	meIsLoading: boolean;
	courseKPIsFetched: boolean;
	error: string;
	bulkError: bulkErrorInterface | null;
}

const initialState: initialUsersStateInterface = {
	data: {},
    trigger: false,
	selectedUser: {
		avatar: '',
		first_name: '',
		last_name: '',
		email: '',
		groups: [] as any,
		groupNames: [] as any,
	},
	selectedUserKPIs: null,
	selectedUserCourseKPIs: {},
	courseKPIsFetched: false,
    notAuthenticated: false,
	selectedMember: {
		avatar: '',
		first_name: '',
		last_name: '',
		email: '',
		role: 1,
	},
	myUser: {},
	isLoading: false,
	meIsLoading: false,
	error: '',
	bulkError: {
		type: '',
		message: '',
	},
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		fetchUsers: (state, action: any) => {
			console.log(action.state);
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished' && !!action.response.success) {
				state.data = { ...action.response.success };
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		fetchSelf: (state, action: any) => {
			if (action.state === 'send') {
				state.meIsLoading = true;
			}
            state.notAuthenticated = false;
			if (action.state === 'finished') {
				state.myUser = { ...action.response.success };
				state.meIsLoading = false;
			} else if (action.state === 'error' && action.response.message === "Unauthenticated.") {
                state.meIsLoading = false;
                state.notAuthenticated = true;
            }
		},
		fetchUser: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
            if (action.state === 'finished') {
                state.isLoading = false;
                state.error = '';
            }

            if (action.state === 'finished' && !!action.response.success) {
                state.selectedUser = { ...action.response.success };
                var groups = state.selectedUser.groups.map(function (group: any) {
                    return group.id;
                });
				var groupNames = state.selectedUser.groups.map(function (group: any) {
                    return group.name;
                });
                state.selectedUser.groups = groups;
				state.selectedUser.groupNames = groupNames;
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },

		fetchUserKPIs: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

            if (action.state === 'finished') {
                state.isLoading = false;
                state.error = '';
            }

            if (action.state === 'finished' && !!action.response.success) {
                state.selectedUserKPIs = action.response.success;
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
		fetchUserKPIsPerCourse: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished' && !!action.response) {
				const id = action.payload.request.split('/')[3];
				state.selectedUserCourseKPIs = {
					...state.selectedUserCourseKPIs,
					[id]: action.response,
				};
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		fetchMember: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished' && !!action.response.success[0]) {
				state.selectedMember = { ...action.response.success[0] };
				state.selectedMember.role = {
					...action.response.success[0],
				}.roles[0].id;
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		addUser: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.error = '';
                state.trigger = !state.trigger
				state.isLoading = false;
			}
			if (action.state === 'error') {
				state.isLoading = false;
				if (
					!!action.response?.error?.email &&
					action.response?.error?.email[0] ===
						'The email has already been taken.'
				) {
					state.error = translate(
						`users.users.create.error.alreadyTaken`,
					);
				} else if (
					action.response?.error ===
					'The email has already been taken in another institution.'
				) {
					state.error = translate(
						`users.users.create.error.alreadyTakenOtherInstitution`,
					);
				} else {
					state.error = action.response?.error;
				}
			}
		},
		editUser: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }

		    if (action.state === 'finished') {
				const { success, data } = action.response;
                state.trigger = !state.trigger
				state.isLoading = false;
			}
		},
		uploadImage: (state, action: any) => {
			state.selectedUser.avatar = action.payload;
		},
		deleteUser: (state, action: any) => {
		    if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished') {
                state.trigger = !state.trigger
				state.isLoading = false;
				state.error = '';
			}
		},
		bulkEdit: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished' && action.response.success) {
                state.trigger = !state.trigger
				state.isLoading = false;
				state.error = '';
			}
		},
		bulkCreate: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
                state.trigger = !state.trigger
				const { success } = action.response;
                state.isLoading = false;
				if (success && success.already_in_db.length > 0) {
					state.bulkError = {
						type: UsersErrors.DUPLICATES,
						message: translateWithVariable(
							`users.users.bulkCreate.step2.error.duplicates`,
							{
								count: success.already_in_db.length,
								imported: success.imported.length,
							},
						),
					};
				} else {
					state.bulkError = null;
				}
			}

			if (action.state === 'error') {
				state.bulkError = {
					type: UsersErrors.MALFORMATTED,
					message: translate(
						`users.users.bulkCreate.step2.error.malformatted`,
					),
				};
			}
		},
		finishLoading(state) {
			state.isLoading = false;
		},
		resetBulk(state) {
			state.bulkError = null;
			state.isLoading = false;
		},
		clearUserDetails: (state) => {
			state.selectedUser = initialState.selectedUser;
			state.selectedUserKPIs = initialState.selectedUserKPIs;
			state.selectedUserCourseKPIs = initialState.selectedUserCourseKPIs;
			state.selectedMember = initialState.selectedMember;
			state.error = '';
			state.isLoading = false;
		},
		clearUser: (state) => {
			if (state.myUser) {
				state.myUser = null;
			} else {
				state.selectedUser = initialState.selectedUser;
				state.selectedUserKPIs = initialState.selectedUserKPIs;
			}

			state.selectedMember = initialState.selectedMember;
			state.error = '';
			state.isLoading = false;
		},
	},
});

export const {
	fetchSelf,
	fetchUsers,
	fetchUser,
	fetchMember,
	editUser,
	addUser,
	bulkCreate,
	resetBulk,
	clearUser,
	uploadImage,
	deleteUser,
	finishLoading,
	bulkEdit,
	fetchUserKPIs,
	clearUserDetails,
	fetchUserKPIsPerCourse,
} = userSlice.actions;
export const userSelector = (state: RootState) => state.user;
export const userLoadingSelector = (state: RootState) => state.user.isLoading;
export const bulkResponseSelector = (state: RootState) => [
	state.user.bulkError,
	state.user.isLoading,
];
export default userSlice.reducer;
