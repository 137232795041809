export enum userBulkFunctions {
    DELETE = "DELETE",
    ASSIGN_GROUP = "ASSIGN_GROUP",
    ASSIGN_COURSE = "ASSIGN_COURSE"
}

export enum userFunctions {
    SINGLE_DELETE = "SINGLE_DELETE",
    SINGLE_ASSIGN_COURSE = "SINGLE_ASSIGN_COURSE"
}


export enum deleteTypes {
    DELETE = "DELETE",
    REMOVE = "REMOVE"
}

export enum UsersBulkExample {
    template = `first_name;last_name;email
Max;Mustermann;max.mustermann@musterfirma.de
Maria;Mustermann;maria.mustermann@musterfirma.de
`,
    fileName = `example.csv`
};

export enum PERMISSIONS {
    FE_SOM_DASHBOARD = "FE_SOM_DASHBOARD",
    FE_SOM_USER = "FE_SOM_USERS",
    FE_SOM_COURSES = "FE_SOM_COURSES",
    FE_SOM_SETTINGS = "FE_SOM_SETTINGS"
};
