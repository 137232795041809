import axios, { AxiosResponse } from 'axios';
import hash from 'object-hash';

import { ERROR, FINISHED, SEND } from './constants';
import { addToQueue, getFromQueueByKey } from './offlineMode';

const apiBasePath = process.env.REACT_APP_API_URL + '/api';

function parseResponse(_action: any, response: AxiosResponse<any>) {
	_action.state = FINISHED;
	const isBlob = response.data instanceof Blob;

	_action.response = response.data;

	if (!isBlob && typeof _action.response === 'object') {
		_action.response = _action.response.result || _action.response;
	}
	return _action;
}

const requestMiddleware =
	(store: any) => (next: any) => async (action: any) => {
		const _action = { ...action };
		if (_action && _action.payload && action.payload.request) {
			if (
				_action &&
				_action.payload &&
				['QUEUE', 'GET_QUEUE'].includes(action.payload.requestType)
			) {
				return next(_action);
			}

			_action.state = SEND;

			next(_action);

			// Get the body.
			const body = Array.isArray(_action.payload.body)
				? _action.payload.body.concat([])
				: Object.assign({}, _action.payload.body);

			// Setup headers from defaults.
			const headers = Object.assign(
				{
					'Content-Type': 'application/json',
				},
				_action.headers,
			);

			const axiosDefinition = {
				method: _action.payload.requestType || 'POST',
				url: `${
					_action.payload.specificURL ||
					[
						_action.payload.rawRequest ? null : apiBasePath,
						_action.payload.request.startsWith('/')
							? _action.payload.request.substr(
									1,
									_action.payload.request.length,
							  )
							: _action.payload.request,
					]
						.filter((e) => e)
						.join('/')
				}`,
				headers,
				data: _action.payload.body,
				responseType: _action.payload.responseType || 'json',
			};

			const persistStore: any =
				window.localStorage.getItem('persist:root');

			let jwt: any = window.localStorage.getItem('jwt');
			const config = Object.assign(axiosDefinition, _action.axios || {});
			if (persistStore) {
				const authData = JSON.parse(JSON.parse(persistStore).auth);
				jwt = authData.token || '';
			}
			if (jwt) {
				config.headers['Authorization'] = 'Bearer ' + jwt;
			}

			// if (!navigator.onLine) {
			// 	const key = hash.MD5(
			// 		JSON.stringify({
			// 			url: config.url,
			// 			headers: {
			// 				Authorization: config.headers.Authorization,
			// 			},
			// 		}),
			// 	);
			// 	const oldAction = await getFromQueueByKey(key);
            //
			// 	if (oldAction) {
			// 		if (oldAction.response) {
			// 			_action.response = oldAction.response;
			// 			_action.state = oldAction.state;
			// 			next(_action);
			// 			return _action;
			// 		}
			// 	} else {
			// 		console.log(`not found`, key);
			// 	}
			// }

			return axios(config)
				.then(async (response) => {
					_action.response = parseResponse(
						_action,
						response,
					).response;

					const key = hash.MD5(
						JSON.stringify({
							url: response.config.url,
							headers: {
								Authorization: config.headers.Authorization,
							},
						}),
					);

					// await addToQueue(key, _action);

					if (_action) {
						next(_action);
					}

					return _action;
				})
				.catch((error) => {
				   if (error?.response?.status && [401].includes(error.response.status)) {
						window.localStorage.clear();
						if(!window.location.href.includes("/auth/login")) {
                            window.location.href = "/auth/login";
                        }
					}

					_action.state = ERROR;

					_action.response = error.response && error.response.data;

					// Check if response is empty, and generate an empty map.
					if (!_action.response) {
						if (
							error.hasOwnProperty('status') &&
							error.status === 0
						) {
							// Check for no connection error.
							_action.response = {
								message: 'No connection.',
							};
						} else if (error.isAxiosError) {
							const { data } = error.response || {};

							_action.response = {
								code: data && data.messageCode,
								message: data && data[0],
							};
						} else if (error.reason) {
							// If the error has a reason, set it under the message.
							_action.response = {
								message: error.reason,
							};
						} else {
						}
					}

					// Force logout users with expired session (unauthorized)
					if (
						error.response &&
						error.response &&
						error.response.shouldRelog
					) {
						// store.dispatch(shouldRelog());
					} else {
						next(_action);
					}

					return _action;
				});
		}

		return new Promise((resolve) => {
			if (_action.type === undefined) {
				_action.type = '';
			}

			next(_action);
			resolve(true);
		});
	};

export default requestMiddleware;
