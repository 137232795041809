import { makeStyles } from "@material-ui/core/styles";
import {color, lineHeightSizes, textSizes, borderRadius} from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    TitleInput: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: '#9C9C9C'
        }
    },
    Card: {
        backgroundColor: color.white,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.medium,
        border: `1px solid ${color.borderGrey}`,
        width: "100%",
        height: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        marginTop: 15,
        cursor: 'pointer'
    },
    CourseCardContent:{
        color: "#fff",
        textAlign: "center",
        padding: "0 20px 40px 20px",
        width: "100%",
        hyphens: "auto",
        whiteSpace: "normal",
    },
    CourseCardTitle: {
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 2,
    },
    CourseCardSubTitle: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "30px",
        textAlign: "center"
    }
}))
