import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	Multiselect: {
		height: 35,
		borderRadius: '18px !important',
		border: '1px solid',
		borderColor: `${color.appTextSecondary} !important`,
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'inline-flex !important',
		minWidth: '180px !important',
		width: 'auto !important',
		marginTop: 0,
		marginBottom: '0 !important',
		marginRight: 15,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& i': {
			paddingTop: '8px !important',
		},

		'&.active': {
			borderColor: '#96c8da !important',
		},
		'& div.text.divider': {
			paddingRight: 30,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			lineHeight: '35px',
			textOverflow: 'ellipsis',
			maxWidth: 180,
		},
	},
}));
