import { makeStyles } from "@material-ui/core/styles";
import {textSizes} from "../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    Modal: {
        padding: "40px 60px",
    },
    CentralModal: {
        padding: "40px 60px",
        "& .header": {
            textAlign: "center"
        }
    },
    Header: {
        padding: "0 0 25px 0 !important",
        position: "relative",
        fontSize: `${textSizes.headline}px !important`,
        borderBottom: "none !important",
        "font-weight": "400 !important",

        "& b":{
            fontWeight: 700
        }
    },

    Content: {
        padding: "0 !important"
    },
    CloseIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "-35px",
        top: 0,
        width: "25px",
        height: "25px"
    },
    Actions: {
        padding: "40px 0 0 0 !important",
        backgroundColor: "transparent !important",
        borderTop: "none !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    MainButtonWrapper: {
        display: "flex",

        "& button:first-child": {
            marginRight: "10px",
        },
        "& button:last-child": {
            marginRight: "0 !important",
        }
    },
    noCloseWrapper: {
        float: 'right',
        padding: "40px 0 0 0 !important",
        backgroundColor: "transparent !important",
        borderTop: "none !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}))
