import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import ContentCard from '../../../../components/app/selectCourse/Card';
import Button from '../../../../components/shared/Button';
import {
	appSelector,
	setCourseId,
	fetchCurrentCourse,
	fetchCurrentCourseSubareas,
} from '../../../../store/app/appSlice';
import { translate } from '../../../../i18n/i18n';
import axios from '../../../../app/axios';
import {parse} from "date-fns";
import {color} from "../../../../utils/styles";

const OverviewTab: React.FunctionComponent<{
	handleNavigate: Function;
}> = ({ handleNavigate }) => {
	const {
		allCourses,
		currentCourseId,
		isFetchingCourseForApp,
		currentCourseSubareas,
		currentCourse,
	} = useSelector(appSelector);
	const dispatch = useDispatch();
	const [isUpdatingCourse, setIsUpdatingCourse] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState<number | null>(
		process.env.REACT_APP_COURSE_ID ? null : currentCourseId,
	);
	const currentCourses =
		(allCourses && allCourses.filter((course) => !course.is_finished)) ||
		[];

	const pastCourses =
		(allCourses && allCourses.filter((course) => {
                return !!course.is_finished || parse(course.end, "yyyy-MM-dd", new Date()) <= new Date() ;
            })) ||
		[];

	useEffect(() => {
		if (
			!currentCourseSubareas &&
			!!currentCourse &&
			isFetchingCourseForApp &&
            selectedCourseId
		) {

			dispatch({
				...fetchCurrentCourseSubareas({
					requestType: 'GET',
					request: `/smartometer/courses/by-assignment/${selectedCourseId}/subarea`,
				}),
			});
		}
	}, [currentCourse, currentCourseSubareas, isFetchingCourseForApp, selectedCourseId]);

	useEffect(() => {
		if (!isFetchingCourseForApp && isUpdatingCourse) {
			handleNavigate('/app/learn', {
				navigation: '0',
				content: '-1',
			});
		}
	}, [isFetchingCourseForApp]);

	useEffect(() => {
		if(selectedCourseId !== currentCourseId && process.env.REACT_APP_COURSE_ID){
			console.log("not in current")
		}
	}, [selectedCourseId])

	const updateActiveCourse = async () => {
		setIsUpdatingCourse(true);
		await axios
			.put(`/app/courses/${selectedCourseId}`)
			.then(() => {
				const selectedCourse = allCourses!.find(
					(item: { id: number; product_id: number }) =>
						item.id === selectedCourseId,
				);
				if (!!selectedCourse!.is_finished) {
					localStorage.setItem('success_shown', 'true');
				} else {
					localStorage.setItem('success_shown', 'false');
				}
				handleNavigate('/app/course', {
					navigation: '1',
					content: '-1',
				});
				dispatch(setCourseId(selectedCourseId!));
				dispatch({
					...fetchCurrentCourse({
						requestType: 'GET',
						request: `/smartometer/courses/by-assignment/${selectedCourse?.id}?all=true`,
					}),
				});
			})
			.catch(function (error) {
				setIsUpdatingCourse(false);
				console.error(error);
			});
	};

	const handleSelectActiveCourse = (id:number) => {
		if(!process.env.REACT_APP_COURSE_ID) setSelectedCourseId(id)
	}

	return !allCourses ? (
		<div
			style={{
				height: 'calc(100% - 110px)',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress
				style={{ color: '#F6F7F9', width: 100, height: 100 }}
			/>
		</div>
	) : (
		<>
			{!!currentCourses.length && (
				<div className="app-course-section">
					<p className="app-course-section-headline">
						{translate('app.appCourseOverviewTab.currentCourse')}
					</p>
					{currentCourses.map((course) => {
						return (
							<ContentCard
								isBlocked={!!(course.id !== currentCourseId && process.env.REACT_APP_COURSE_ID)}
								key={course.id}
								data={{ ...course }}
								isSelected={selectedCourseId === course.id}
								isActive={currentCourseId === course.id}
								selectActiveCourse={handleSelectActiveCourse}
							/>
						);
					})}
				</div>
			)}
			{!!pastCourses.length && (
				<div
					className="app-course-section"
					style={
						allCourses.length > 1 &&
						selectedCourseId !== null &&
						selectedCourseId !== currentCourseId
							? { paddingBottom: 70 }
							: {}
					}
				>
					<p className="app-course-section-headline">
						{translate('app.appCourseOverviewTab.finishedCourse')}
					</p>
					{pastCourses.map((course) => {
						return (
							<ContentCard
								isBlocked={!!(course.id !== currentCourseId && process.env.REACT_APP_COURSE_ID)}
								key={course.id}
								isCompleted
								data={{ ...course }}
								isSelected={selectedCourseId === course.id}
								isActive={currentCourseId === course.id}
								selectActiveCourse={handleSelectActiveCourse}
							/>
						);
					})}
				</div>
			)}

			{selectedCourseId !== null && selectedCourseId !== currentCourseId && !process.env.REACT_APP_COURSE_ID && (
				<Button
					onClick={updateActiveCourse}
					styles={{
						borderRadius: 5,
						width: 180,
						marginTop: 10,
						height: 58,
						position: 'fixed',
						bottom: 120,
						left: '50%',
						transform: 'translateX(-50%)',
						boxShadow: '1px 5px 15px -3px rgb(0 0 0 / 35%)',
					}}
					title="Kurs wechseln"
					type="solid"
					color={color.link}
				/>
			)}
		</>
	);
};

export default OverviewTab;
