import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Dropdown, DropdownProps, Grid} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {format, parseJSON} from 'date-fns';
import ContentCard from '../../shared/ContentCard';
import ContentCardNumber from '../../shared/ContentCardNumber';
import Table from '../../shared/Table';
import TableAction from '../../shared/TableAction';
import {color, margins} from '../../../utils/styles';
import Button from '../../shared/Button';
import DeleteModal from '../../modals/DeleteModal';
import {courseBulkFunctions} from '../../../enums/courses/courses-enums';
import {
    clearCache,
    courseSelector,
    deleteCourses,
    fetchCourses,
    updateCourse,
} from '../../../store/courses/courseSlice';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import {BulkAction} from '../../shared/BulkAction';
import {useTableSearch} from '../../../helpers/useTableSearch/useTableSearch';
import {initialSorting, SortingInterface,} from '../../../interfaces/sorting-interface';
import {useStyles} from './styles';
import {usePagination} from '../../../helpers/usePagination';
import {useBulkFunction} from '../../../helpers/useBulkFunction';
import {translate} from '../../../i18n/i18n';
import TableSearch from '../../shared/TableSearch';
import {UserContext} from "../../../contexts/UserContext";
import {RoleInterface} from "../../../interfaces/roles-interface";
import {PERMISSIONS} from "../../../enums/users/users-enums";

const CourseTab = () => {
    const [selectedCourse, setSelectedCourse] = useState<{
        id: number;
        name: string;
    } | null>(null);
    const [shownModal, setShownModal] = useState<string | null>(null);
    const {isLoading, courses: courseData} = useSelector(courseSelector);
    const {
        handleSelectAll,
        handleSelect,
        isSelected,
        selected,
        selectedAll,
        setSelected,
    } = useBulkFunction(courseData);

    const {user} = useContext(UserContext);
    console.log(user)

    // DELETE LOGIC
    const [isDeleting, setIsDeleting] = useState(false);

    const searchProps = useTableSearch();
    const {search} = searchProps;

    const [sorting, setSorting] = useState<SortingInterface>(initialSorting);

    const {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        handleResetPage,
    } = usePagination();

    const [status, setStatus] = useState<string>('active');
    const allStatuses = [
        {
            key: 'active',
            text: translate('courses.filter.active'),
            value: 'active',
        },
        {
            key: 'archived',
            text: translate('courses.filter.archived'),
            value: 'archived',
        },
        {
            key: 'all',
            text: translate('courses.filter.all'),
            value: 'all',
        },
    ];

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const {pathname} = useLocation();

    useEffect(() => {
        handleResetPage();
    }, [sorting, search, rowsPerPage, status]);

    useEffect(() => {
        if (!isLoading) {
            dispatch({
                ...fetchCourses({
                    requestType: 'GET',
                    request: `smartometer/get-courses?page=${page}&limit=${rowsPerPage}&sort=${
                        sorting.column
                    }&type=${sorting.desc ? `desc` : `asc`}${
                        search !== '' ? `&search=${search}` : ''
                    }&status=${status}`,
                }),
            });
        }
    }, [sorting, search, page, rowsPerPage, status, isLoading]);

    const handleSelectChange = (e: any, dropdown: DropdownProps) => {
        setStatus(dropdown.value as string);
    };

    const handleEditCourse = (id: number, name: string) => {
        history.push(`/authoring/${id}`);
    };

    const handleDeleteCourse = () => {
        setIsDeleting(true);
        dispatch({
            ...updateCourse({
                requestType: 'PUT',
                request: `smartometer/get-courses/${selectedCourse!.id}`,
                body: {
                    restore: true,
                },
            }),
        });
        setIsDeleting(false);
        setSelected([]);
        resetModals();
    };

    const handleBulkDeleteCourses = () => {
        if (!!selected.length) {
            dispatch({
                ...deleteCourses({
                    requestType: 'DELETE',
                    body: {
                        courses: selected,
                    },
                    request: `smartometer/get-courses`,
                }),
            });
            setSelected([]);
            resetModals();
        }
    };

    const handleBulkRestoreCourses = () => {
        setIsDeleting(true);
        if (!!selected.length) {
            dispatch({
                ...deleteCourses({
                    requestType: 'DELETE',
                    body: {
                        courses: selected,
                    },
                    request: `smartometer/get-courses/restore`,
                }),
            });
            setIsDeleting(false);
            setSelected([]);
            resetModals();
        }
    };

    const handleBulkEdit = (
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
        switch (event.target.value) {
            case courseBulkFunctions.ARCHIVE:
                setShownModal(courseBulkFunctions.ARCHIVE);
                break;
            case courseBulkFunctions.RESTORE:
                setShownModal(courseBulkFunctions.RESTORE);
                break;
            default:
                break;
        }
    };

    const handleRestoreCourse = () => {
        setIsDeleting(true);
        dispatch({
            ...updateCourse({
                requestType: 'PUT',
                request: `smartometer/get-courses/${
                    selectedCourse!.id
                }/restore`,
            }),
        });
        setIsDeleting(false);
        setSelected([]);
        resetModals();
    };

    const handleClearAllCache = () => {
        dispatch({
            ...clearCache({
                requestType: 'POST',
                request: `smartometer/clearAllCache`,
            }),
        });
    };

    const setCourseToDelete = (id: number, name: string) => {
        setShownModal('delete');
        setSelectedCourse({id, name});
    };

    const setCourseToRestore = (id: number, name: string) => {
        setShownModal('restore');
        setSelectedCourse({id, name});
    };

    const handleChangeSorting = (column: string) => {
        if (column === sorting.column && sorting.desc)
            setSorting(initialSorting);
        else if (column === sorting.column && !sorting.desc)
            setSorting({...sorting, desc: true});
        else if (column !== sorting.column)
            setSorting({...sorting, column: column});
    };

    const resetModals = () => {
        setShownModal(null);
        setSelectedCourse(null);
    };

    const courseRowData = {
        select: {
            renderTitle: (): JSX.Element => (
                <label className={classes.label}>
                    <Checkbox
                        checked={selectedAll}
                        color="primary"
                        onChange={handleSelectAll}
                    />
                    <span className={classes.selectAll}>
						{translate('tabs.coursesTab.selectAll')}
					</span>
                </label>
            ),
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <Checkbox
                    checked={isSelected(item.id)}
                    color="primary"
                    onClick={(event) => handleSelect(event, item.id)}
                />
            ),
        },
        name: {
            title: translate('tabs.coursesTab.course'),
            sorting: 'name',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`/authoring/${item.id}`)}
                >
                    {item.name}
                </p>
            ),
        },
        count: {
            title: translate('tabs.coursesTab.user'),
            sorting: 'users_count',
            align: 'left',
            renderType: (item: any): JSX.Element => <p>{item.users_count}</p>,
        },
        created_at: {
            title: translate('tabs.coursesTab.created'),
            sorting: 'created_at',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p>
                    {item.created_at &&
                    format(
                        parseJSON(
                            item.created_at
                        ),
                        'dd.MM.yyyy',
                    )}
                </p>
            ),
        },
        updated_at: {
            title: translate('tabs.coursesTab.updatedAt'),
            sorting: 'updated_at',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p>
                    {item.updated_at &&
                    format(
                        parseJSON(
                            item.updated_at
                        ),
                        'dd.MM.yyyy',
                    )}
                </p>
            ),
        },
        edit: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <>
                    {' '}
                    {!item.deleted_at && (
                        <TableAction
                            handleClick={() =>
                                handleEditCourse(item.id, item.name)
                            }
                            tooltip={translate('tooltip.edit')}
                            type="edit"
                        />
                    )}{' '}
                </>
            ),
        },
        delete: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => {

                return (
                    <>
                        {' '}
                        {item.is_archived ? (
                            <TableAction
                                handleClick={() =>
                                    setCourseToRestore(item.id, item.name)
                                }
                                tooltip={translate('tooltip.restore')}
                                type="unarchive"
                            />
                        ) : (
                            <TableAction
                                handleClick={() =>
                                    setCourseToDelete(item.id, item.name)
                                }
                                tooltip={translate('tooltip.archive')}
                                type="archive"
                            />
                        )}{' '}
                    </>
                )
            },
        },
        isPublished: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <>
                    {' '}
                    {item.published === 1 ? (
                        <Chip
                            className={classes.published}
                            size="small"
                            label={translate('tabs.coursesTab.published')}
                        />
                    ) : (
                        <Chip
                            className={classes.not_published}
                            size="small"
                            label={translate('tabs.coursesTab.notPublished')}
                        />
                    )}{' '}
                </>
            ),
        },
    };

    const headerComponent = (
        <div className={classes.HeaderComponent}>
            {selected.length > 0 ? (
                <BulkAction
                    handleBulkEdit={handleBulkEdit}
                    bulkFunctions={courseBulkFunctions}
                />
            ) : (
                <>
                    <TableSearch searchProps={searchProps}/>
                    <Dropdown
                        className={classes.Multiselect}
                        onChange={handleSelectChange}
                        placeholder={translate('tabs.coursesTab.status')}
                        value={status}
                        selection
                        options={allStatuses}
                    />
                </>
            )}
        </div>
    );

    const allowsCacheClear = user?.institution_id === 1
        && user?.role.filter((role: RoleInterface) => role.name === PERMISSIONS.FE_SOM_COURSES).length > 0;

    return courseData && courseData.current_page ? (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <ContentCard
                            title={translate('tabs.coursesTab.ownCourses')}
                            small
                            helperText={translate(`kpis.courses.ownCourses`)}
                        >
                            {!!courseData && (
                                <ContentCardNumber value={courseData.total}/>
                            )}
                        </ContentCard>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Button
                            type="solid"
                            styles={{float: 'right'}}
                            onClick={() => history.push('authoring')}
                            color={color.link}
                            title={translate(`tabs.coursesTab.newCourse`)}
                        />
                        {allowsCacheClear
                            ? <Button
                                type="outline"
                                styles={{
                                    float: 'right',
                                    marginRight: margins.buttons,
                                }}
                                onClick={handleClearAllCache}
                                color={color.link}
                                title={translate(`tabs.coursesTab.clearCache`)}
                            />
                            : ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard
                            title={translate('tabs.coursesTab.course')}
                            headerComponent={headerComponent}
                        >
                            <Table
                                showHeader
                                rows={courseRowData}
                                data={courseData.data}
                                loading={isLoading}
                                handleChangeSorting={handleChangeSorting}
                                sorting={sorting}
                                total={courseData.total}
                                handleChangePage={handleChangePage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangeRowsPerPage={
                                    handleChangeRowsPerPage
                                }
                            />
                        </ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DeleteModal
                additionalInfo={translate('tabs.coursesTab.archiveConfirm')}
                isLoading={isDeleting}
                showModal={!!selectedCourse && shownModal === 'delete'}
                closeModal={resetModals}
                onDelete={handleDeleteCourse}
                confirmText={translate('tabs.coursesTab.archiveCourse')}
                message={`${translate('tabs.coursesTab.archive')} <b>'${
                    selectedCourse?.name
                }'</b> ${translate('tabs.coursesTab.course')}`}
            />
            <DeleteModal
                additionalInfo={translate('tabs.coursesTab.restoreConfirm')}
                isLoading={isDeleting}
                showModal={!!selectedCourse && shownModal === 'restore'}
                closeModal={resetModals}
                onDelete={handleRestoreCourse}
                confirmText={translate('tabs.coursesTab.restoreCourse')}
                message={`${translate('tabs.coursesTab.restore')} <b>'${
                    selectedCourse?.name
                }'</b> ${translate('tabs.coursesTab.course')}`}
            />
            <DeleteModal
                isLoading={isDeleting}
                showModal={
                    !!selected.length &&
                    shownModal === courseBulkFunctions.ARCHIVE
                }
                closeModal={resetModals}
                onDelete={handleBulkDeleteCourses}
                confirmText={translate('tabs.coursesTab.archiveCourse')}
                message={translate('tabs.coursesTab.archiveConfirm')}
            />
            <DeleteModal
                isLoading={isDeleting}
                showModal={
                    !!selected.length &&
                    shownModal === courseBulkFunctions.RESTORE
                }
                closeModal={resetModals}
                onDelete={handleBulkRestoreCourses}
                confirmText={translate('tabs.coursesTab.restoreCourse')}
                message={translate('tabs.coursesTab.restoreCourseConfirm')}
            />
        </>
    ) : null;
};

export default CourseTab;
