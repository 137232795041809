import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from '../../assets/images/bg-som.png';
import { textSizes } from '../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	BlockerWrapper: {
		position: 'relative',
		width: '100vw',
		height: '100vh',
		background:
			'linear-gradient(55deg, rgba(31,93,211,1) 0%, rgba(48,112,237,1) 100%)',
	},
	BGImage: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		left: 0,
		backgroundSize: '80%',
		backgroundPosition: ' right -80px top',
		backgroundRepeat: 'no-repeat',
	},
	Content: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 100,
		alignItems: 'center',
		padding: 30,
		width: '100%',
		height: '100%',
		color: '#ffffff',
		textAlign: 'center',
		position: 'relative',
		fontSize: textSizes.bodyNormal,

		'& h3': {
			fontSize: textSizes.bodyLarge,
			margin: '12px 0 15px 0',
		},

		'& p': {
			lineHeight: 1.5,
			margin: '0 0 5px 0',
			maxWidth: 350,
		},

		'& svg': {
			color: '#ffffff',
			fontSize: 46,
		},
	},
	Button: {
		background: '#fff',
		width: '100%',
		height: 58,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'rgba(31, 93, 211, 1)',
		borderRadius: 8,
		fontWeight: 600,
		fontSize: textSizes.bodyLarge,
	},

	ContentButton: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		width: '100%',
		maxWidth: 325,

		'& a': {
			cursor: 'pointer',
		},

		'& svg': {
			color: 'rgba(31, 93, 211, 1)',
			marginRight: 5,
			fontSize: 30,
		},
	},
	Footer: {
		color: '#fff',
		fontSize: textSizes.bodySmall,
		marginTop: 40,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',

		'& ul': {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},

		'& li': {
			display: 'Inline-block',
			margin: '0 10px',

			'& a': {
				color: '#fff',
			},
		},
	},
}));
