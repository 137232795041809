

export const useTracking = () => {
    const getLearningTime = (trackings:any[]) => {
        return trackings.reduce((sum: number, cur: any, index: number) => {
            if (cur.time_spent) {
                return sum + cur.time_spent;
            }
            return sum;
        }, 0)
    };

    const extractContent = (s: string) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    const calculateTimeRequired = (wysiwyg: any) => {
        return (extractContent(wysiwyg).split(' ').length / 400) * 60;
    };

    return {getLearningTime, calculateTimeRequired}
}
