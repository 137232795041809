import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import axios from '../../../app/axios';
import Button from '../../../components/shared/Button';
import Editor from '../../../components/shared/Editor';
import {addCourse, courseSelector, updateCourse,} from '../../../store/courses/courseSlice';
import FileThumbnail from '../../../components/tabs/VisualSettingsTab/components/FileThumbnail';
import {color} from '../../../utils/styles';
import {useStyles} from './styles';
import {translate} from '../../../i18n/i18n';
import {CourseTextTypes} from "../../../enums/courses/courses-enums";
import useUserContext from "../../../contexts/UserContext";

const CourseDetailPage = () => {
    const dispatch = useDispatch();
    const {user} = useUserContext();
    const {current: currentCourse} = useSelector(courseSelector);

    const [name, setName] = useState(currentCourse ? currentCourse.name : '');
    const [editorState, setEditorState] = useState<string | null>(
        currentCourse ? currentCourse.description : null,
    );
    const [generalInstructions, setGeneralInstructions] = useState<string | null>(
        currentCourse ? currentCourse.general_instructions : null,
    );

    const [writtenExamInstructions, setWrittenExamInstructions] = useState<string | null>(
        currentCourse ? currentCourse.written_exam_instructions : null,
    );
    const [oralExamInstructions, setOralExamInstructions] = useState<string | null>(
        currentCourse ? currentCourse.oral_exam_instructions : null,
    );
    const [partnerPage, setPartnerPage] = useState<string | null>(
        currentCourse ? currentCourse.partner_page : null,
    );
    const [thanksPage, setThanksPage] = useState<string | null>(
        currentCourse ? currentCourse.thanks_page : null,
    );
    const [savedEditorState, setSavedEditorState] = useState<string | null>(null);
    const [savedGIState, setSavedGIState] = useState<string | null>(null);
    const [savedWEIState, setSavedWEIState] = useState<string | null>(null);
    const [savedOEIState, setSavedOEIState] = useState<string | null>(null);
    const [savedPPState, setSavedPPState] = useState<string | null>(null);
    const [savedTPState, setSavedTPState] = useState<string | null>(null);
    const classes = useStyles();

    useEffect(() => {

        if (!!currentCourse) {
            if (currentCourse.name !== name) {
                setName(currentCourse.name);
            }
            if (!!currentCourse.description) {
                setEditorState(currentCourse.description);

            }
            if (!!currentCourse.general_instructions) {
                setGeneralInstructions(currentCourse.general_instructions);

            }
            if (!!currentCourse.written_exam_instructions) {
                setWrittenExamInstructions(currentCourse.written_exam_instructions);

            }
            if (!!currentCourse.oral_exam_instructions) {
                setOralExamInstructions(currentCourse.oral_exam_instructions);

            }
            if (!!currentCourse.partner_page) {
                setPartnerPage(currentCourse.partner_page);

            }
            if (!!currentCourse.thanks_page) {
                setThanksPage(currentCourse.thanks_page);

            }
        }
        if (!currentCourse || !currentCourse.description) {
            setEditorState('<p></p>');
        }
    }, [currentCourse]);

    const handleChangeEditor = (type: CourseTextTypes, event: any, editor: any) => {
        const data = editor.getData();
        switch (type) {
            case CourseTextTypes.SMARTOMETER:
                setSavedEditorState(data);
                break;
            case CourseTextTypes.GENERAL_INSTRUCTIONS:
                setSavedGIState(data);
                break;
            case CourseTextTypes.WRITTEN_EXAM_INSTRUCTIONS:
                setSavedWEIState(data);
                break;
            case CourseTextTypes.ORAL_EXAM_INSTRUCTIONS:
                setSavedOEIState(data);
                break;
            case CourseTextTypes.PARNTER_PAGE:
                setSavedPPState(data);
                break;
            case CourseTextTypes.THANKS_PAGE:
                setSavedTPState(data);
                break;
            default:
                setSavedEditorState(data);
                break;
        }

    };

    const handleSaveEditor = () => {
        if (!currentCourse) {
            dispatch({
                ...addCourse({
                    requestType: 'POST',
                    request: `smartometer/courses`,
                    body: {
                        description: savedEditorState || editorState,
                        general_instructions: savedGIState || generalInstructions,
                        written_exam_instructions: savedWEIState || writtenExamInstructions,
                        oral_exam_instructions: savedOEIState || oralExamInstructions,
                        partner_page: savedPPState || partnerPage,
                        thanks_page: savedTPState || thanksPage,
                    },
                }),
            });
        } else {
            dispatch({
                ...updateCourse({
                    requestType: 'PUT',
                    request: `smartometer/courses/${currentCourse.id}`,
                    body: {
                        description: savedEditorState || editorState,
                        general_instructions: savedGIState || generalInstructions,
                        written_exam_instructions: savedWEIState || writtenExamInstructions,
                        oral_exam_instructions: savedOEIState || oralExamInstructions,
                        partner_page: savedPPState || partnerPage,
                        thanks_page: savedTPState || thanksPage,
                    },
                }),
            });
        }
    }

    const handleChange = async (type: any, val: any) => {
        if (!currentCourse) {
            dispatch({
                ...addCourse({
                    requestType: 'POST',
                    request: `smartometer/courses`,
                    body: {
                        [type]: val,
                    },
                }),
            });
        } else {
            dispatch({
                ...updateCourse({
                    requestType: 'PUT',
                    request: `smartometer/courses/${currentCourse.id}`,
                    body: {
                        [type]: val,
                    },
                }),
            });
        }
    };

    const handleBlur = (e: any) => {
        const {name: targetName} = e.target;
        if (!name && currentCourse) return setName(currentCourse.name);
        if (!!name) {
            switch (targetName) {
                case 'course-title':
                    return handleChange('name', name);
                default:
                    break;
            }
        }
    };

    const handleFileUpload = async (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            let formData = new FormData();
            formData.append('file', file);
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/file-upload`,
                    formData,
                    {
                        headers: {
                            accept: 'application/json',
                            'Accept-Language': 'en-US,en;q=0.8',
                        },
                    },
                )
                .then((response: any) => {
                    handleChange('image', response.data.default);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    return (
        <div style={{paddingTop: 50}}>
            <div className="coursewizard-input-wrapper">
                <label className="coursewizard-label" htmlFor="course-title">
                    Titel
                </label>
                <input
                    className={classes.TitleInput}
                    name="course-title"
                    type="text"
                    value={name}
                    placeholder={translate('authoring.courseDetailPage.courseTitle')}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleBlur}
                />
            </div>
            {!!currentCourse && (
                <>
                    <div className="coursewizard-input-wrapper wysiwyg">
                        <label
                            className="coursewizard-label wysiwyg-open"
                            htmlFor="section-content"
                        >
                            {translate('authoring.courseDetailPage.content')}
                        </label>
                        {editorState && (
                            <div className={classes.editorWrapper}>
                                <Editor
                                    htmlContent={editorState}
                                    handleBlur={(data, editor) => {
                                    }}
                                    handleChange={(event, editor) => {
                                        handleChangeEditor(CourseTextTypes.SMARTOMETER, event, editor);
                                    }}
                                    handleReady={() => console.log('ready')}
                                />
                                {user?.institution?.id !== 1 ?
                                    <Button
                                        title={translate('authoring.sectionPage.save')}
                                        type="solid"
                                        color={color.link}
                                        onClick={async () => {
                                            await handleSaveEditor()
                                        }}
                                        icon="save"
                                        disabled={editorState === savedEditorState || savedEditorState === null}
                                        styles={{marginTop: 12, marginRight: 12, marginBottom: 12}}
                                        iconColor={color.white}
                                    /> : ""}
                            </div>
                        )}
                    </div>
                    {user?.institution?.id === 1 ? (<>
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >
                                {translate('authoring.courseDetailPage.generalInstructions')}
                            </label>
                            {generalInstructions && (
                                <div className={classes.editorWrapper}>
                                    <Editor
                                        htmlContent={generalInstructions}
                                        handleBlur={(data, editor) => {
                                        }}
                                        handleChange={(event, editor) => {
                                            handleChangeEditor(CourseTextTypes.GENERAL_INSTRUCTIONS, event, editor);
                                        }
                                        }
                                        handleReady={() => console.log('ready')}
                                    />

                                </div>
                            )}
                        </div>
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >
                                {translate('authoring.courseDetailPage.writtenExamInstructions')}
                            </label>
                            {writtenExamInstructions && (
                                <div className={classes.editorWrapper}>
                                    <Editor
                                        htmlContent={writtenExamInstructions}
                                        handleBlur={(data, editor) => {
                                        }}
                                        handleChange={(event, editor) => {
                                            handleChangeEditor(CourseTextTypes.WRITTEN_EXAM_INSTRUCTIONS, event, editor);
                                        }
                                        }
                                        handleReady={() => console.log('ready')}
                                    />

                                </div>
                            )}
                        </div>
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >
                                {translate('authoring.courseDetailPage.oralExamInstructions')}
                            </label>
                            {oralExamInstructions && (
                                <div className={classes.editorWrapper}>
                                    <Editor
                                        htmlContent={oralExamInstructions}
                                        handleBlur={(data, editor) => {
                                        }}
                                        handleChange={(event, editor) => {
                                            handleChangeEditor(CourseTextTypes.ORAL_EXAM_INSTRUCTIONS, event, editor);
                                        }
                                        }
                                        handleReady={() => console.log('ready')}
                                    />

                                </div>
                            )}
                        </div>
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >
                                {translate('authoring.courseDetailPage.partnerPage')}
                            </label>
                            {partnerPage && (
                                <div className={classes.editorWrapper}>
                                    <Editor
                                        htmlContent={partnerPage}
                                        handleBlur={(data, editor) => {
                                        }}
                                        handleChange={(event, editor) => {
                                            handleChangeEditor(CourseTextTypes.PARNTER_PAGE, event, editor);
                                        }
                                        }
                                        handleReady={() => console.log('ready')}
                                    />

                                </div>
                            )}
                        </div>
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >
                                {translate('authoring.courseDetailPage.thanksPage')}
                            </label>
                            {thanksPage && (
                                <div className={classes.editorWrapper}>
                                    <Editor
                                        htmlContent={thanksPage}
                                        handleBlur={(data, editor) => {
                                        }}
                                        handleChange={(event, editor) => {
                                            handleChangeEditor(CourseTextTypes.THANKS_PAGE, event, editor);
                                        }
                                        }
                                        handleReady={() => console.log('ready')}
                                    />

                                </div>

                            )}
                        </div>
                    </>) : ''}
                    {user?.institution?.id === 1 ?
                        <div className="coursewizard-input-wrapper wysiwyg">
                            <label
                                className="coursewizard-label wysiwyg-open"
                                htmlFor="section-content"
                            >

                            </label>
                            <div className={classes.editorWrapper}>
                                <Button
                                    title={translate('authoring.sectionPage.save')}
                                    type="solid"
                                    color={color.link}
                                    onClick={async () => {
                                        await handleSaveEditor()
                                    }}
                                    icon="save"
                                    disabled={(editorState === savedEditorState
                                        && generalInstructions === savedGIState
                                        && writtenExamInstructions === savedWEIState
                                        && oralExamInstructions === savedOEIState
                                        && partnerPage === savedPPState
                                        && thanksPage === savedTPState
                                    ) || (savedEditorState === null
                                        && savedGIState === null
                                        && savedWEIState === null
                                        && savedOEIState === null
                                        && savedPPState === null
                                        && savedTPState === null
                                    )}
                                    styles={{marginTop: 12, marginRight: 12, marginBottom: 12}}
                                    iconColor={color.white}
                                /></div>
                        </div>
                        : ""}
                    <div className="coursewizard-input-wrapper">
                        <label
                            className="coursewizard-label coursewizard-label-image"
                            htmlFor="course-image"
                        >
                            <span
                                className="coursewizard-label-image-top">{translate('authoring.courseDetailPage.image')}  </span>
                            <span className="coursewizard-label-image-sub">Formate: .JPG oder .PNG</span>
                            <span className="coursewizard-label-image-sub">Querformat, min. 1280×720</span>

                        </label>
                        <FileThumbnail
                            type="course_img"
                            file={currentCourse.image}
                            onClick={() =>
                                document.getElementById('img-uploader')?.click()
                            }
                        />
                        <div className={classes.FileInputWrapper}>
                            <label htmlFor="logo" className={classes.FileInput}>
                                <input
                                    id="img-uploader"
                                    name="logo"
                                    disabled={!name}
                                    accept=".png,.jpg"
                                    type="file"
                                    onChange={(
                                        e: React.FormEvent<HTMLInputElement>,
                                    ) => handleFileUpload(e)}
                                    className="form-control"
                                />
                                {translate('authoring.courseDetailPage.upload')}
                            </label>
                            {currentCourse.image && (
                                <Button
                                    title={translate('authoring.courseDetailPage.delete')}
                                    type="secondary"
                                    color={color.red}
                                    onClick={() => handleChange('image', '')}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CourseDetailPage;
