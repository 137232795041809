import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownProps, Grid } from 'semantic-ui-react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import TableAction from '../../shared/TableAction';
import DeleteModal from '../../modals/DeleteModal';
import {
	addAssignment,
	assignmentSelector,
	deleteAssignment,
	fetchAssignments,
	updateAssignment,
} from '../../../store/assignments/assignmentSlice';
import { Checkbox } from '@material-ui/core';
import { BulkAction } from '../../shared/BulkAction';
import {
	initialSorting,
	SortingInterface,
} from '../../../interfaces/sorting-interface';
import { useStyles } from './styles';
import { usePagination } from '../../../helpers/usePagination';
import { useBulkFunction } from '../../../helpers/useBulkFunction';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
	getMissedDeadlineTypesForDropdown,
	getHasFinishedTypesForDropdown,
} from '../../../helpers/Courses';

import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import Avatar from '../../shared/Avatar';
import { useTableSearch } from '../../../helpers/useTableSearch/useTableSearch';
import {
	assignmentBulkFunctions,
	assignmentFunctions,
	HasFinishedTypes,
	MissedDeadlineTypes,
} from '../../../enums/assignments/assignments-enums';
import { AssignUserModal } from '../../modals/AssignUserModal';
import { EditAssignmentModal } from '../../modals/EditAssignmentModal';
import Button from '../../shared/Button';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';
import TableSearch from '../../shared/TableSearch';
import MultiSelect from '../../shared/MultiSelect';
import axios from '../../../app/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cancel, CheckCircle } from '@material-ui/icons';

interface AssignmentOverviewTabProps {
	assignmentData: any;
}

const AssignmentOverviewTab: React.FunctionComponent<
	AssignmentOverviewTabProps
> = ({ assignmentData }) => {
	const [selectedAssignment, setSelectedAssignment] = useState<{
		id: number;
	} | null>(null);
	const [shownModal, setShownModal] = useState<string | null>(null);
	const {
		handleSelectAll,
		handleSelect,
		isSelected,
		selected,
		selectedAll,
		setSelected,
	} = useBulkFunction(assignmentData);
	const { isLoading, trigger } = useSelector(assignmentSelector);
	const [sorting, setSorting] = useState<SortingInterface>(initialSorting);
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
		null,
	);
	const searchProps = useTableSearch();
	const { search } = searchProps;
	const {
		handleChangePage,
		handleChangeRowsPerPage,
		page,
		rowsPerPage,
		handleResetPage,
	} = usePagination();
	const [hasMissedDeadline, setHasMissedDeadline] =
		useState<MissedDeadlineTypes>(MissedDeadlineTypes.ALL);
	const [hasFinishedFilter, setHasFinishedFilter] =
		useState<HasFinishedTypes>(HasFinishedTypes.ALL);
	const allMissedDeadlineTypes = getMissedDeadlineTypesForDropdown();
	const allFinishedTypes = getHasFinishedTypesForDropdown();

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();

	// MULTISELECT
	const [courses, setCourses] = useState<number[]>([]);
	const [multiSelectOptions, setMultiSelectOptions] = useState<number[]>([]);
	const [multiSelectFetching, setMultiSelectFetching] = useState(false);
	const handleMultiSelectSearch = async (searchValue: string) => {
		setMultiSelectFetching(true);
		if (!!searchValue) {
			try {
				const searchedData = await axios.get(
					`/courses?search=${searchValue}&published=1`,
				);
				const newItems: any = [];
				searchedData.data.success.data.map((course: any) => {
					const hasItem = multiSelectOptions.find(
						(item: any) => item.value === course.id,
					);
					if (!hasItem) {
						newItems.push({
							text: course.name,
							value: course.id,
						});
					}
				});
				setMultiSelectOptions([...multiSelectOptions, ...newItems]);
				setMultiSelectFetching(false);
			} catch (err) {
				setMultiSelectFetching(false);
			}
		} else {
			setTimeout(() => setMultiSelectFetching(false), 500);
		}
	};

	useEffect(() => {
		if (!multiSelectOptions.length) {
			axios
				.get('/courses?limit=10&published=1')
				.then((res: any) => {
					setMultiSelectOptions(
						res.data.success.data.map((course: any) => ({
							text: course.name,
							value: course.id,
						})),
					);
				})
				.catch((err: any) => {
					console.log(err);
				});
		}
	}, []);

	useEffect(() => {
		handleResetPage();
	}, [
		sorting,
		search,
		rowsPerPage,
		selectedStartDate,
		selectedEndDate,
		hasMissedDeadline,
		hasFinishedFilter,
	]);

	useEffect(() => {
		dispatch({
			...fetchAssignments({
				requestType: 'GET',
				request: `smartometer/assignments?page=${page}&limit=${rowsPerPage}&sort=${
					sorting.column
				}&type=${sorting.desc ? `desc` : `asc`}${
					search !== '' ? `&search=${search}` : ''
				}${
					hasMissedDeadline !== MissedDeadlineTypes.ALL
						? `&missedDeadline=${hasMissedDeadline}`
						: ''
				}${
					selectedStartDate
						? `&start=${format(selectedStartDate, 'yyyy-MM-dd')}`
						: ''
				}${
					selectedEndDate
						? `&end=${format(selectedEndDate, 'yyyy-MM-dd')}`
						: ''
				}${
					courses.length > 0
						? `&${courses
								.map((course) => `courses[]=${course}`)
								?.join('&')}`
						: ''
				}${
					hasFinishedFilter !== HasFinishedTypes.ALL
						? `&is_finished=${hasFinishedFilter}`
						: ''
				}`,
			}),
		});
		resetModals();
	}, [
		sorting,
		search,
		trigger,
		page,
		courses,
		rowsPerPage,
		selectedStartDate,
		selectedEndDate,
		hasMissedDeadline,
		hasFinishedFilter,
	]);

	const handleEdit = (id: number) => {
		setShownModal(assignmentFunctions.EDIT);
		setSelectedAssignment({ id });
	};

	const handleDeleteAssignment = () => {
		if (selectedAssignment?.id) {
			dispatch({
				...deleteAssignment({
					requestType: 'DELETE',
					request: `smartometer/assignments/${selectedAssignment.id}`,
				}),
			});
		}
		resetModals();
	};

	const handleBulkEdit = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		switch (event.target.value) {
			case assignmentBulkFunctions.DELETE:
				setShownModal(assignmentBulkFunctions.DELETE);
				break;
			default:
				break;
		}
	};

	const handleChangeSorting = (column: string) => {
		if (column === sorting.column && sorting.desc)
			setSorting(initialSorting);
		else if (column === sorting.column && !sorting.desc)
			setSorting({ ...sorting, desc: true });
		else if (column !== sorting.column)
			setSorting({ ...sorting, column: column });
	};

	const setToDelete = (id: number) => {
		setShownModal('delete');
		setSelectedAssignment({ id });
	};

	const resetModals = () => {
		setShownModal(null);
		setSelectedAssignment(null);
	};

	const handleBulkDeleteGroups = () => {
		if (!!selected.length) {
			dispatch({
				...deleteAssignment({
					requestType: 'DELETE',
					body: {
						assignments: selected,
					},
					request: `smartometer/assignments`,
				}),
			});
			setSelected([]);
			resetModals();
		}
	};

	const rowData = {
		select: {
			renderTitle: (): JSX.Element => (
				<label className={classes.label}>
					<Checkbox
						checked={selectedAll}
						color="primary"
						onChange={handleSelectAll}
					/>
					<span className={classes.selectAll}>
						{translate('tabs.assignmentOverviewTab.selectAll')}
					</span>
				</label>
			),
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Checkbox
					checked={isSelected(item.id)}
					color="primary"
					onClick={(event) => handleSelect(event, item.id)}
				/>
			),
		},
		avatar: {
			title: '',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Avatar
					src={item.user.avatar || '/defaultUser.png'}
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push(`/users/detail/${item.user.id}`)
					}
				/>
			),
		},
		name: {
			title: 'Name',
			sorting: 'first_name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push(`/users/detail/${item.user.id}`)
					}
				>
					{item.user.first_name} {item.user.last_name}
				</p>
			),
		},
		course: {
			title: 'Kurs',
			sorting: 'product_name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p>{item.product && item.product.name}</p>
			),
		},
		start: {
			title: 'Start',
			sorting: 'start',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p>
					{item.start &&
						format(
							parse(item.start, 'yyyy-mm-dd', new Date()),
							'dd.mm.yyyy',
						)}
				</p>
			),
		},
		end: {
			title: 'Ende',
			sorting: 'end',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				const deadline = parse(item.end, 'yyyy-MM-dd', new Date());
				const missedDeadline =
					item.is_finished === 0 &&
					deadline.getTime() < new Date().getTime();
				return (
					<p className={missedDeadline ? classes.missedDeadline : ''}>
						{item.end &&
							format(
								parse(item.end, 'yyyy-mm-dd', new Date()),
								'dd.mm.yyyy',
							)}
					</p>
				);
			},
		},
		isFinished: {
			title: 'Absolviert',
			align: 'right',
			renderType: (item: { is_finished: 1 | 0 }): JSX.Element =>
				!!item.is_finished ? (
					<CheckCircle className={classes.CourseFinishedIcon} />
				) : (
					<Cancel className={classes.CourseUnfinishedIcon} />
				),
		},
		edit: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => {
				return (
					<TableAction
						handleClick={() => handleEdit(item.id)}
						tooltip={translate('tooltip.edit')}
						type="edit"
					/>
				);
			},
		},
		delete: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => setToDelete(item.id)}
					tooltip={translate('tooltip.delete')}
					type="delete"
				/>
			),
		},
	};

	const handleStartDateChange = (date: Date | null) => {
		if (date && !isNaN(date.getTime())) {
			setSelectedStartDate(date);
		}
	};

	const handleEndDateChange = (date: Date | null) => {
		if (date && !isNaN(date.getTime())) {
			setSelectedEndDate(date);
		}
	};

	const handleMissedDeadlineSelectChange = (
		e: any,
		dropdown: DropdownProps,
	) => {
		const currentType: MissedDeadlineTypes =
			dropdown.value as MissedDeadlineTypes;

		if (currentType in MissedDeadlineTypes) {
			setHasMissedDeadline(currentType);
		}
	};

	const handleHasFinishedSelectChange = (e: any, dropdown: DropdownProps) => {
		setHasFinishedFilter(dropdown.value as HasFinishedTypes);
	};

	const headerComponent = (
		<div className={classes.HeaderComponent}>
			<div className={classes.FilterComponent}>
				{!selected.length && (
					<>
						<TableSearch searchProps={searchProps} />
						<div style={{ marginLeft: 15 }}>
							<MultiSelect
								bordered
								handleChange={setCourses}
								inputTitle={translate(
									'tabs.groupOverviewTab.course',
								)}
								modalTitle={translate(
									'shared.dashboardFilter.filterByCourse',
								)}
								selected={courses}
								options={multiSelectOptions}
								handleSearch={handleMultiSelectSearch}
								searchLoading={multiSelectFetching}
							/>
						</div>
						<Dropdown
							className={classes.Multiselect}
							fluid
							name="missedDeadline"
							id="missedDeadline"
							onChange={handleMissedDeadlineSelectChange}
							value={hasMissedDeadline}
							options={allMissedDeadlineTypes}
							placeholder={translate(
								'tabs.assignmentOverviewTab.assignment',
							)}
							selection
						/>
						<Dropdown
							className={classes.Multiselect}
							fluid
							name="hasFinishedFilter"
							id="hasFinishedFilter"
							onChange={handleHasFinishedSelectChange}
							value={hasFinishedFilter}
							options={allFinishedTypes}
							placeholder={translate(
								'tabs.assignmentOverviewTab.assignment',
							)}
							selection
						/>
					</>
				)}
			</div>
			<div className={classes.FilterComponent} style={{ marginTop: 15 }}>
				{selected.length > 0 ? (
					<BulkAction
						handleBulkEdit={handleBulkEdit}
						bulkFunctions={assignmentBulkFunctions}
					/>
				) : (
					<MuiPickersUtilsProvider
						utils={DateFnsUtils}
						locale={deLocale}
					>
						<KeyboardDatePicker
							margin="normal"
							id="date-picker-dialog"
							placeholder={translate(
								'tabs.assignmentOverviewTab.startDate',
							)}
							format="dd.MM.yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							className={classes.datepicker}
						/>

						<KeyboardDatePicker
							margin="normal"
							id="date-picker-dialog"
							placeholder={translate(
								'tabs.assignmentOverviewTab.endDate',
							)}
							format="dd.MM.yyyy"
							value={selectedEndDate}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							className={classes.datepicker}
						/>
					</MuiPickersUtilsProvider>
				)}
			</div>
		</div>
	);

	const handleCreateSubmit = (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		console.log(id, courses, start, end, send_email_date);
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		resetModals();
	};

	const handleEditSubmit = (
		id: number,
		start: Date | null,
		end: Date | null,
	) => {
		dispatch({
			...updateAssignment({
				requestType: 'PUT',
				body: JSON.stringify({
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		resetModals();
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column width={8}></Grid.Column>
					<Grid.Column width={8}>
						<Button
							type="solid"
							styles={{ float: 'right' }}
							onClick={() =>
								setShownModal(assignmentFunctions.CREATE)
							}
							color={color.link}
							title={translate(
								`users.assignments.createNewButton`,
							)}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<ContentCard
							title="Kurszuweisung"
							headerComponent={headerComponent}
						>
							<Table
								showHeader
								rows={rowData}
								data={assignmentData.data}
								loading={isLoading}
								handleChangeSorting={handleChangeSorting}
								sorting={sorting}
								total={assignmentData.total}
								handleChangePage={handleChangePage}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangeRowsPerPage={
									handleChangeRowsPerPage
								}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<AssignUserModal
				isLoading={isLoading}
				showModal={shownModal === assignmentFunctions.CREATE}
				closeModal={resetModals}
				onSubmit={handleCreateSubmit}
				confirmText={translate(
					'tabs.assignmentOverviewTab.addToCourse',
				)}
				message={translate(
					'tabs.assignmentOverviewTab.addSelectedUser',
				)}
			/>
			<EditAssignmentModal
				isLoading={isLoading}
				assignment={selectedAssignment?.id}
				showModal={shownModal === assignmentFunctions.EDIT}
				closeModal={resetModals}
				onSubmit={handleEditSubmit}
				confirmText={translate('tabs.assignmentOverviewTab.save')}
				message={translate('tabs.assignmentOverviewTab.editAssignment')}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={!!selectedAssignment && shownModal === 'delete'}
				closeModal={resetModals}
				onDelete={handleDeleteAssignment}
				confirmText={translate(
					'tabs.assignmentOverviewTab.deleteAssignment',
				)}
				message={translate(
					'tabs.assignmentOverviewTab.deleteAssignmentConfirm',
				)}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={
					!!selected.length &&
					shownModal === assignmentBulkFunctions.DELETE
				}
				closeModal={resetModals}
				onDelete={handleBulkDeleteGroups}
				confirmText={translate(
					'tabs.assignmentOverviewTab.deleteAssignment',
				)}
				message={translate(
					'tabs.assignmentOverviewTab.deleteAllAssignmentsConfirm',
				)}
			/>
		</>
	);
};

export default AssignmentOverviewTab;
