import React from 'react';
import { faAward, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, SubdirectoryArrowRight } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from '@material-ui/core';

interface PreviewItem {
	subareas: Array<PreviewItem>;
	headline: string;
	id: number;
	is_exercise: boolean;
	is_finished?: boolean;
	is_exam: boolean;
	wysiwyg: string;
	time_spent?: number;
}

interface PreviewListItemProps {
	item: PreviewItem;
	handleClick: React.MouseEventHandler;
	parentId: number | null;
	active: boolean;
	nosubareas?: boolean;
	showContentProgress?: boolean;
}

const PreviewListItem: React.FunctionComponent<PreviewListItemProps> = ({
	item,
	handleClick,
	parentId,
	nosubareas,
	active,
	showContentProgress,
}) => {
	const renderIcon = () => {
		if (item.is_exam)
			return (
				<FontAwesomeIcon className="exam-icon icon" icon={faAward} />
			); // IMPORTANT: return is_exam first because an exam is also an exercise
		if (item.is_exercise)
			return (
				<SvgIcon className="learn-icon icon">
					<path
						id="checkbox-marked-circle-outline"
						d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
						transform="translate(-2 -2)"
					/>
				</SvgIcon>
			);
		return item.id !== parentId && parentId !== -1 ? (
			<SubdirectoryArrowRight />
		) : (
			<SvgIcon className="learn-icon icon">
				<path
					id="learnicon"
					d="M18.666,20.957l-12-6.763,12-6.763,12,6.763Zm7.794,2.471a7.8,7.8,0,0,1-15.6,0V18.96l7.8,4.4,7.8-4.4v4.468Z"
					transform="translate(-6.667 -7.431)"
				/>
			</SvgIcon>
		);
	};
	const extractContent = (s: string) => {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText;
	};

	const calculateTimeRequired = (wysiwyg: any) => {
		return (extractContent(wysiwyg).split(' ').length / 400) * 60;
	};

	const calculateProgress = (wysiwyg: any, time_spent: number) => {
		let timeRequired = calculateTimeRequired(wysiwyg);
		const percentageProgress = (time_spent / timeRequired) * 100;
		return percentageProgress > 100 ? 100 : percentageProgress;
	};

	const renderItemProgress = () => {
		const noProgressIcon = (
			<CircularProgress
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'right',
					color: 'white',
				}}
				variant="determinate"
				value={100}
			/>
		);
		const finishedSectionIcon = (
			<SvgIcon className="progress-icon-complete">
				<path
					id="checkbox-marked-circle-outline"
					d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
					transform="translate(-2 -2)"
				/>
			</SvgIcon>
		);
		if (item.is_exercise) {
			return item.is_finished ? finishedSectionIcon : noProgressIcon;
		} else {
			if (!item.time_spent) {
				return noProgressIcon;
			} else {
				if (calculateProgress(item.wysiwyg, item.time_spent) === 100) {
					return finishedSectionIcon;
				} else {
					return (
						<CircularProgress
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'right',
							}}
							color="primary"
							variant="determinate"
							value={calculateProgress(
								item.wysiwyg,
								item.time_spent,
							)}
						/>
					);
				}
			}
		}
	};

	return (
		<div
			className={`preview-item-wrapper ${
				active ? 'preview-item-wrapper-active' : ''
			} ${parentId === item.id ? 'preview-item-wrapper-parent' : ''}`}
		>
			<div className="preview-item-tab" onClick={handleClick}>
				{renderIcon()}
				<div className="preview-item-tab-title">{item.headline}</div>
				{showContentProgress && (
					<div className="preview-item-progress">
						{renderItemProgress()}
					</div>
				)}
			</div>
			{!nosubareas && !!item.subareas.length && item.id !== parentId && (
				<div className="preview-item-tab-subareas">
					<SubdirectoryArrowRight />
					<span>...</span>
				</div>
			)}
		</div>
	);
};

export default PreviewListItem;
