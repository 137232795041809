import React, {useEffect, useRef, useState} from 'react';

import {useStyles} from './styles';
import Modal from '../../shared/Modal';
import {color} from '../../../utils/styles';
import {useDispatch, useSelector} from 'react-redux';
import {DropdownProps, Grid} from 'semantic-ui-react';
import Avatar from '../../shared/Avatar';
import {APPUCATION_LOGO_AS_BASE64} from '../../../enums/assignments/assignments-enums';
import DateFnsUtils from '@date-io/date-fns';
import {parse} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {fetchLicense, licenseSelector,} from '../../../store/licenses/licenseSlice';
import useUserContext from '../../../contexts/UserContext';
import {translate} from '../../../i18n/i18n';
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import {LicenseErrorInterface, LicenseInterface} from "../../../interfaces/license-interface";

interface AssignModalProps {
    showModal: boolean;
    closeModal: () => void;
    onSubmit?: (id: number, values: any) => void;
    message: string;
    confirmText: string;
    isLoading: boolean;
    additionalInfo?: string;
    license?: number;
}

export const EditLicenseModal: React.FunctionComponent<AssignModalProps> = ({
                                                                                showModal,
                                                                                closeModal,
                                                                                onSubmit,
                                                                                message,
                                                                                confirmText,
                                                                                isLoading,
                                                                                additionalInfo,
                                                                                license,
                                                                            }) => {
    const dispatch = useDispatch();
    const {user} = useUserContext();
    const formikRef: any = useRef();
    const classes = useStyles();
    const {
        single: licenseData,
        isLoading: licenseIsLoading,
        licenseError,
    }: any = useSelector(licenseSelector);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
        null,
    );

    useEffect(() => {
        if (licenseData?.date_of_activation) {
            setSelectedStartDate(
                parse(licenseData.date_of_activation, 'yyyy-MM-dd', new Date()),
            );
            setSelectedEndDate(
                parse(licenseData.time_to_live, 'yyyy-MM-dd', new Date()),
            );
        }
    }, [licenseData]);

    useEffect(() => {
        if (license) {
            dispatch({
                ...fetchLicense({
                    requestType: 'GET',
                    request: `smartometer/licenses/${license}`,
                }),
            });
        }
    }, [license]);


    const handleSubmit = (values: any) => {
        onSubmit &&
        license &&
        onSubmit(license, values);
    };

    const defaultValues: LicenseInterface = {
        user: null,
        product: null,
        deviceLimit: 3,
        dateOfActivation: new Date(),
        timeToLive: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    }

    const initialValues: LicenseInterface = licenseData ? {
        user: licenseData.user,
        product: licenseData.product,
        deviceLimit: licenseData.device_limit,
        dateOfActivation: parse(licenseData.date_of_activation, 'yyyy-MM-dd', new Date()),
        timeToLive: parse(licenseData.time_to_live, 'yyyy-MM-dd', new Date()),
    } : defaultValues;

    const onValidate = (val: any) => {
        const errors: LicenseErrorInterface = {}
        // if (!val.user) errors.user = translate('modals.addMemberModal.firstNameRequired');
        // if (!val.product) errors.product = translate('modals.addMemberModal.lastNameRequired');
        // if (!val.deviceLimit || val.deviceLimit < 1) errors.deviceLimit = translate('modals.addMemberModal.lastNameRequired');
        // if (!val.dateOfActivation) errors.dateOfActivation = translate('modals.addMemberModal.lastNameRequired');
        // if (!val.timeToLive) errors.timeToLive = translate('modals.addMemberModal.lastNameRequired');
        return errors;
    }

    const handleFormikSubmit = (val: any) => {
        console.log(val);
        debugger;
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    return licenseData ? (
        <Modal
            showModal={showModal}
            header={<span dangerouslySetInnerHTML={{__html: message}}></span>}
            onClose={closeModal}
            onSubmit={handleFormikSubmit}
            isLoading={isLoading}
            submitActionTitle={confirmText}
            closeActionTitle={translate('modals.close')}
            submitActionColor={color.link}
            disabled={isLoading}
        >

            <Formik
                validate={onValidate}
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({handleChange, handleBlur, values, errors, touched, setFieldValue}) => {
                    const handleDateChangeStart = (elem: any) => {
                        setFieldValue('dateOfActivation', elem)
                    };
                    const handleDateChangeEnd = (elem: any) => {
                        setFieldValue('timeToLive', elem)
                    };
                    return <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Avatar
                                    src={
                                        licenseData.user_avatar || '/defaultUser.png'
                                    }
                                    className={classes.Avatar}
                                    style={{display: 'inline-block'}}
                                />
                                <span className={classes.AvatarName}>
        {licenseData.user.first_name}{' '}
                                    {licenseData.user.last_name}
    </span>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <Avatar
                                    src={
                                        APPUCATION_LOGO_AS_BASE64
                                    }
                                    className={classes.Avatar}
                                    style={{display: 'inline-block'}}
                                />
                                <span className={classes.AvatarName}>
        {licenseData.product.name}
        </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>

                                <TextField className={`${classes.Input}`}
                                           error={touched.deviceLimit && !!errors.deviceLimit}
                                           helperText={touched.deviceLimit && !!errors.deviceLimit && errors.deviceLimit}
                                           id="deviceLimit" value={values.deviceLimit}

                                           onChange={handleChange}
                                           placeholder={translate('modals.addMemberModal.firstname')}
                                           name="deviceLimit" variant="outlined"
                                           onBlur={handleBlur}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={classes.row}>
                            <Grid.Column width={8}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={deLocale}
                                >
                                    <KeyboardDatePicker
                                        maxDate={values.timeToLive}
                                        margin="normal"
                                        id="dateOfActivation"
                                        placeholder={translate(
                                            'modals.assignUserModal.start',
                                        )}
                                        format="dd.MM.yyyy"
                                        value={values.dateOfActivation}
                                        onChange={handleDateChangeStart}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputProps={{readOnly: true, disabled: true}}
                                        autoOk={true}
                                        className={classes.datepicker}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={deLocale}
                                >
                                    <KeyboardDatePicker
                                        minDate={values.dateOfActivation}
                                        margin="normal"
                                        id="timeToLive"
                                        placeholder={translate(
                                            'modals.assignUserModal.end',
                                        )}
                                        format="dd.MM.yyyy"
                                        value={values.timeToLive}
                                        onChange={handleDateChangeEnd}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputProps={{readOnly: true, disabled: true}}
                                        autoOk={true}
                                        className={classes.datepicker}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }}
            </Formik>
        </Modal>
    ) : (
        <></>
    );
};
