import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import {
	assignmentBulkFunctions,
	assignmentFunctions,
} from '../../../../enums/assignments/assignments-enums';
import ContentCard from '../../../../components/shared/ContentCard';
import Table from '../../../../components/shared/Table';
import { Checkbox } from '@material-ui/core';
import { format, parse } from 'date-fns';
import TableAction from '../../../../components/shared/TableAction';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from '../../../../components/tabs/AssignmentOverviewTab/styles';
import { useTableSearch } from '../../../../helpers/useTableSearch/useTableSearch';
import { usePagination } from '../../../../helpers/usePagination';
import { useTableManage } from '../../../../helpers/useTableManage';

import { useBulkFunction } from '../../../../helpers/useBulkFunction';
import { useSorting } from '../../../../helpers/useSorting';
import TableSearch from '../../../../components/shared/TableSearch';
import { BulkAction } from '../../../../components/shared/BulkAction';
import DeleteModal from '../../../../components/modals/DeleteModal';
import {
	deleteAssignment,
	updateAssignment,
} from '../../../../store/assignments/assignmentSlice';
import { translate } from '../../../../i18n/i18n';
import { EditAssignmentModal } from '../../../../components/modals/EditAssignmentModal';

interface UserCoursesProps {
	assignments: any[];
	trackings: any[];
	isLoading: boolean;
	setCourses: Function;
}

const UserCourses: React.FunctionComponent<UserCoursesProps> = ({
	assignments,
	trackings,
	isLoading,
	setCourses,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { pathname } = useLocation();
	const [selectedAssignment, setSelectedAssignment] = useState<{
		id: number;
	} | null>(null);

	const { handleChangeSorting, sorting } = useSorting();

	const {
		handleSearchChange,
		search,
		setSearch,
		showSearch,
		setShowSearch,
		CurrentlySearching,
	} = useTableSearch();
	const {
		handleChangePage,
		handleChangeRowsPerPage,
		page,
		rowsPerPage,
		handleResetPage,
	} = usePagination();
	const { getResult } = useTableManage();

	const {
		handleSelectAll,
		handleSelect,
		isSelected,
		selected,
		selectedAll,
		setSelected,
		SelectType,
	} = useBulkFunction(assignments);
	const searchProps = {
		handleSearchChange,
		search,
		setSearch,
		showSearch,
		setShowSearch,
		CurrentlySearching,
	};
	const [shownModal, setShownModal] = useState<string | null>(null);

	const setToDelete = (id: number) => {
		setShownModal(assignmentFunctions.DELETE);
		setSelectedAssignment({ id });
	};

	const handleEdit = (id: number) => {
		setShownModal(assignmentFunctions.EDIT);
		setSelectedAssignment({ id });
	};

	const rowData = {
		select: {
			renderTitle: (): JSX.Element => (
				<label className={classes.label}>
					<Checkbox
						checked={selectedAll}
						color="primary"
						onChange={(event: any) => {
							handleSelectAll(
								event,
								event.target.checked,
								SelectType.ASSIGNMENT_PIVOT,
							);
						}}
					/>
					<span className={classes.selectAll}></span>
				</label>
			),
			align: 'left',
			renderType: (item: any, index: number): JSX.Element => (
				<Checkbox
					checked={isSelected(item.pivot.id)}
					color="primary"
					onClick={(event) => handleSelect(event, item.pivot.id)}
				/>
			),
		},
		course: {
			title: translate('users.courses.courses'),
			sorting: 'name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setCourses(item.pivot.id);
						history.push(`${pathname}/statistik/`);
					}}
				>
					{item.name}
				</p>
			),
		},
		start: {
			title: translate('users.courses.start'),
			sorting: 'pivot.start',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setCourses(item.pivot.id);
						history.push(`${pathname}/statistik/`);
					}}
				>
					{item.pivot.start &&
						format(
							parse(item.pivot.start, 'yyyy-mm-dd', new Date()),
							'dd.mm.yyyy',
						)}
				</p>
			),
		},
		end: {
			title: translate('users.courses.end'),
			sorting: 'pivot.end',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				const deadline = parse(
					item.pivot.end,
					'yyyy-MM-dd',
					new Date(),
				);
				const missedDeadline =
					item.pivot.is_finished === 0 &&
					deadline.getTime() < new Date().getTime();
				return (
					<p className={missedDeadline ? classes.missedDeadline : ''}>
						{item.pivot.end &&
							format(
								parse(item.pivot.end, 'yyyy-mm-dd', new Date()),
								'dd.mm.yyyy',
							)}
					</p>
				);
			},
		},
		timeSpend: {
			title: translate('users.courses.readingTime'),
			align: 'left',
			renderType: (item: any): JSX.Element => {
                const hours = Math.floor(item.tracking / 3600);
                const min = Math.floor((item.tracking % 3600) / 60);
                const seconds = item.tracking % 60;

                const timeSpend = `${hours > 0 ? `${hours}h ` : ''}${`${min}min `} ${seconds}s`;
                return (
                    <p
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            setCourses(item.pivot.id);
                            history.push(`${pathname}statistik/`);
                        }}
                    >
                        {timeSpend}
                    </p>
                )
            },
		},
		certificate: {
			title: translate('users.courses.certificate'),
			align: 'left',
            renderType: (item: any): JSX.Element => (
                <p>
                    {item.pivot.is_finished
                        ? translate('yes')
                        : translate('no')}
                </p>
            ),
		},
		finished: {
			title: translate('users.courses.finished'),
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p>
					{item.pivot.is_finished
						? translate('yes')
						: translate('no')}
				</p>
			),
		},
		edit: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => handleEdit(item.pivot.id)}
                    tooltip={translate('tooltip.edit')}
					type="edit"
				/>
			),
		},
		delete: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => setToDelete(item.pivot.id)}
                    tooltip={translate('tooltip.delete')}
					type="delete"
				/>
			),
		},
	};

	const handleBulkDeleteAssignments = () => {
		if (!!selected.length) {
			dispatch({
				...deleteAssignment({
					requestType: 'DELETE',
					body: {
						assignments: selected,
					},
					request: `smartometer/assignments`,
				}),
			});
			setSelected([]);
			resetModals();
		}
	};

	const handleDeleteAssignment = () => {
		if (selectedAssignment?.id) {
			dispatch({
				...deleteAssignment({
					requestType: 'DELETE',
					request: `smartometer/assignments/${selectedAssignment.id}`,
				}),
			});
		}
		resetModals();
	};

	const handleBulkEdit = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		switch (event.target.value) {
			case assignmentBulkFunctions.DELETE:
				setShownModal(assignmentBulkFunctions.DELETE);
				break;
			default:
				break;
		}
	};

	const resetModals = () => {
		setShownModal(null);
		setSelectedAssignment(null);
	};

	const headerComponent = (
		<div
			className={classes.HeaderComponent}
			style={{
				flexGrow: 1,
				justifyContent: 'flex-end',
				flexDirection: 'row',
			}}
		>
			{!!selected.length ? (
				<BulkAction
					handleBulkEdit={handleBulkEdit}
					bulkFunctions={assignmentBulkFunctions}
				/>
			) : (
				<TableSearch searchProps={searchProps} />
			)}
		</div>
	);

	const [filteredAssignments, setFilteredAssignments] = useState<any>(null);
	const [totalAssignments, setTotalAssignments] = useState(0);
	useEffect(() => {
	    const tableData = assignments?.map((assignment: any) => {
	        return {
				...assignment,
				tracking: trackings.reduce((prev: number, curr: any) => {
					if (curr.assignment_id === assignment.id) {
						return prev + curr.time_spent;
					}
					return prev;
				}, 0),
			};
		});

		const { result, total } = getResult(tableData, {
			sorting,
			search,
			page,
			rowsPerPage,
		});
		setFilteredAssignments(result);
		setTotalAssignments(total);
	}, [assignments, sorting, search, page, rowsPerPage]);

	useEffect(() => {
		handleResetPage();
	}, [search]);

	const handleEditSubmit = (
		id: number,
		start: Date | null,
		end: Date | null,
	) => {
		dispatch({
			...updateAssignment({
				requestType: 'PUT',
				body: JSON.stringify({
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		resetModals();
	};

	return (
		<Grid>
			{filteredAssignments && (
				<Grid.Row style={{ marginBottom: 50 }}>
					<Grid.Column width={16}>
						<ContentCard
							title={translate('users.courses.courses')}
							headerComponent={headerComponent}
						>
							<Table
								smallSelectAll={true}
								showHeader
								rows={rowData}
								data={filteredAssignments}
								handleChangeSorting={handleChangeSorting}
								sorting={sorting}
								total={totalAssignments}
								handleChangePage={handleChangePage}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangeRowsPerPage={
									handleChangeRowsPerPage
								}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			)}
			<EditAssignmentModal
				isLoading={isLoading}
				assignment={selectedAssignment?.id}
				showModal={shownModal === assignmentFunctions.EDIT}
				closeModal={resetModals}
				onSubmit={handleEditSubmit}
				confirmText={translate('tabs.assignmentOverviewTab.save')}
				message={translate('tabs.assignmentOverviewTab.editAssignment')}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={
					!!selectedAssignment &&
					shownModal === assignmentFunctions.DELETE
				}
				closeModal={resetModals}
				onDelete={handleDeleteAssignment}
				confirmText={translate('users.courses.deleteAssignment')}
				message={translate('users.courses.deleteAssignmentConfirm')}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={
					!!selected.length &&
					shownModal === assignmentBulkFunctions.DELETE
				}
				closeModal={resetModals}
				onDelete={handleBulkDeleteAssignments}
				confirmText={translate('users.courses.deleteAllAssignment')}
				message={translate('users.courses.deleteAllAssignmentConfirm')}
			/>
		</Grid>
	);
};

export default UserCourses;
