export default {
	translation: {
		basics: {
			clear: 'leeren',
		},
		app: {
			header: {
				personalArea: 'Persönlicher Bereich',
				courses: 'Kurse',
				practice: 'Üben',
			},
			courseIntro: {
				courseIntroduction: 'Kurseinführung',
				topics: 'Themenbereiche',
				courseStatistics: 'Kurs-Statistiken',
			},
			course: {
				nextContent: 'Nächster Inhalt',
				courseEnd: 'Kurseende',
				readingTime: 'Minute(n) geschätzte Lesezeit',
				backToStart: 'Zurück zum Anfang',
			},
			examCounter: {
				timeRemaining: 'Verbleibende Zeit:',
			},
			exerciseWizard: {
				correctAnswer: 'Richtige Antwort!',
				wrongAnswer: 'Falsche Antwort.',
				exam: 'Prüfung',
				exercise: 'Übung',
			},
			quitExerciseModal: {
				finish: '{{ type }} abschließen',
				timeExpired: 'Prüfungszeit abgelaufen',
				finishVerify: '{{ type }} wirklich beenden?',
				resultWillBeSaved: 'Ihre Ergebnisse werden gespeichert.',
				resultWillNotBeSaved: 'Ihre Punktzahl wird nicht gespeichert.',
				yourResult: 'Zum Ergebnis',
				close: '{{ type }} {{ value }}',
				cancel: 'Abbrechen',
				finishButton: 'abschließen',
				closeButton: 'beenden',
			},
			results: {
				exam: 'Prüfung',
				exercise: 'Übung',
				passed: 'bestanden',
				notPassed: 'nicht bestanden',
				congratsFinished: 'Herzlichen Glückwunsch, Sie haben die',
				succsessfully: 'erfolgreich bestanden!',
				toPass: 'Zum Bestehen der',
				youNeed: 'muss eine Mindestpunktzahl von',
				points: 'Punkten',
				passEnd: 'erreicht werden.',
				pointsLabel: 'Punktzahl',
				showResult: 'Zur Auswertung',
				continue: 'Nächster Inhalt',
			},
			splash: {
				exam: 'Prüfung',
				exercise: 'Übung',
				loadingQuestion: 'Übungsinhalte werden geladen ...',
				exerciseContent: 'Übungsinhalt:',
				questnions: 'Fragen',
				startExam: 'Prüfung starten',
				startExerise: 'Übung starten',
				close: 'Schließen',
			},
			summary: {
				summary: 'Zusammenfassung',
				correctAnswer: 'Richtige Antworten:',
			},
			finishedCourseScreen: {
				courseFinished: 'Kurs abgeschlossen',
				congratsFinished: 'Herzlichen Glückwunsch, Sie haben den Kurs',
				congratsEnd: 'vollständig abgeschlossen!',
				courseProgress: 'Kursfortschritt',
				showStatistics: 'Statistiken ansehen',
				close: 'Zurück',
			},
			navigation: {
				learn: 'Lernen',
				exercise: 'Üben',
				course: 'Kurse',
				user: 'Nutzer',
			},
			filterModal: {
				currentCourse: 'Aktuelle Kurse',
				finishedCourse: 'Abgeschlossene Kurse',
				save: 'Auswählen',
				showStatistics: 'Statistiken anzeigen',
				selectCourse:
					'Wählen Sie den Kurs, dessen Statistiken angezeigt werden sollen.',
			},
			selectCourseModal: {
				changeCourse: 'Kurs wechseln',
				selectCourseContent:
					'Wählen Sie den Kurs, dessen Lerninhalte angezeigt werden sollen.',
			},
			appCourseOverviewTab: {
				currentCourse: 'Aktuelle Kurse',
				finishedCourse: 'Abgeschlossene Kurse',
			},
			appCourse: {
				courses: 'Kurse',
				statistics: 'Statistiken',
			},
			appExercise: {
				exercises: 'Übungen',
				exams: 'Prüfungen',
				noContent: 'Keine Übungen oder Prüfungen gefunden.',
			},
			appLearn: {
				exercisesAndExams: 'Übungen & Prüfungen',
			},
			successModal: {
				imageSuccess: 'Erfolgreich hochgeladen',
				FeedbackSuccess: 'Erfolgreich gesendet',
				PasswordSuccess: 'Passwort erfolgreich geändert',
			},
			changeName: {
				change: 'Ändern',
			},
			selectCourse: {
				title: 'Kurs wählen',
				description:
					'Wählen Sie den Kurs, dessen Lerninhalte angezeigt werden sollen.',
				logout: 'Logout',
				selectCard: {
					active: 'AKTIV',
					start: 'START',
					end: 'ENDE',
				},
				currentCourse: 'Aktuelle Kurse',
				finishedCourse: 'Abgeschlossene Kurse',
				endedCourse: 'Abgelaufene Kurse',
				futureCourse: 'Zukünftige Kurse',
				updateCourseButton: 'Auswahl speichern',
			},
			profile: {
				changeName: {
					firstName: 'VORNAME',
					lastName: 'NACHNAME',
					title: 'Name ändern',
					description: 'Bitte geben Sie Ihre neuen Daten an.',
					networkingIssues: 'Networking issue',
					submit: 'Speichern',
				},
				ChangePassword: {
					oldPassword: 'Aktuelles Passwort',
					newPassword: 'Neues Passwort',
					confirmPassword: 'Neues Passwort wiederholen',
					title: 'Passwort ändern',
					description:
						'Bitte geben Sie Ihr altes sowie neues Passwort an, um es zu ändern.',
					networkingIssues: 'Falsches Password',
					submit: 'Speichern',
				},
				feedback: {
					label: 'DEIN FEEDBACK',
					submit: 'Feedback senden',
				},
				successModal: {
					submit: 'Schließen',
				},
				actions: {
					label: 'Aktionen',
					logout: 'Abmelden',
				},
			},
			noCourse: {
				back: 'Zum Dashboard',
				logout: 'Abmelden',
				headline: 'Keine Kurse gefunden',
				content:
					'Ihnen wurden bisher keine Kurse zugewiesen. Um das smartOmeter nutzen zu können, wenden Sie sich bitte an Ihren Administrator.',
				yourAdmin: 'Ihr Administrator',
			},
			noLicense: {
				logout: 'Abmelden',
				headline: 'Keine Lizenz',
				content:
					'Sie besitzen derzeit keine gültige Lizenz, um diese App zu nutzen. Bitte kontaktieren Sie uns, um Ihre App freizuschalten.',
				yourAdmin: 'Unser Kontakt',
			},
		},
		tooltip: {
			back: 'Zurück',
			delete: 'Löschen',
			edit: 'Editieren',
			archive: 'Archivieren',
			restore: 'Wiederherstellen',
			search: 'Suchen',
			add: 'Hinzufügen',
			create: 'Erstellen',
			globalSearch: 'Überall suchen',
			resend: 'Einladung neu verschicken',
		},
		ALL: 'Alle',
		purchase: 'smartOmeter freischalten',
		contactSupport: 'Support kontaktieren',
		callBack: 'Rückruf anfordern',
		withExampleData: 'Mit Beispieldaten starten',
		withoutExampleData: 'Ohne Beispieldaten starten',
		removeExampleData: 'Beispieldaten entfernen',
		notRemoveExampleData: 'Beispieldaten übernehmen',
		search: 'Suchen ...',
		editProfile: {
			myProfile: 'Mein Profil',
			firstNamePlaceholder: 'Alexander',
			secondNamePlaceholder: 'Alexander',
			cancel: 'Abbrechen',
			save: 'Speichern',
		},
		delete: 'Nutzer löschen',
		DELETE: 'Ausgewählte löschen',
		ASSIGN_COURSE: 'Kurs zuweisen',
		ASSIGN_GROUP: 'Gruppe zuweisen',
		ARCHIVE: 'Archivieren',
		RESTORE: 'Wiederherstellen',
		authentication: {
			header: 'Welcome to aPPucations',
			subhead: 'Die Schulungsplattform für Ihre Mitarbeiter',
			name: 'Your name',
			email: 'Email',
			password: 'Passwort',
			repeatPassword: ' Passwort wiederholen',
			forgottenPassword: 'Passwort vergessen?',
			alreadyHaveAccount: 'Ich bin schon registriert.',
			stillDontHaveAccount: 'Ich habe noch kein Konto.',
			checkMail:
				'Bitte überprüfen Sie Ihr Postfach. Wir haben Ihnen eine E-Mail zur Konto-Verifizierung gesendet.',
			checkMailPassword:
				'Bitte überprüfen Sie Ihr Postfach. Wir haben Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.',
			backToLogin: 'Zurück zur Anmeldung',
			logIn: 'Anmelden',
			registerRedirect: 'Registrieren',
			registerTitle: 'Registrierung',
			menu: {
				privacy: 'Datenschutzerklärung',
				terms: 'AGB',
				contact: 'Kontakt',
			},
			login: {
				error: 'E-Mail Adresse oder Passwort ungültig.',
			},
			register: {
				header: 'Registrierung',
				cta: 'Registrieren',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail',
				institution: 'Institution',
				password: 'Passwort',
				passwordCompare: 'Passwort wiederholen',
				setPassword: 'Ihr Account',
				fieldRequired: 'Feld wird benötigt',
				acceptance:
					"Ich habe die <a href='https://www.smartometer.de/agb/' target='_blank'>AGB</a> und die <a href='https://www.smartometer.de/datenschutz/' target='_blank'>Datenschutzerklärung</a> gelesen und stimme zu.",
				setPasswordInfo:
					'Bitte legen Sie ein Passwort fest, um Ihre Registrierung abzuschließen.',
				errors: {
					firstname: 'Firstname',
					lastname: 'Lastname',
					email: 'E-Mail',
					institution: 'Institution',
					password: 'Passwort',
					passwordCompare: 'Passwort wiederholen',
					passwordCompareNoMatch: 'Passwörter stimmen nicht überein',
					passwordRules:
						'Mindestens 8 Zeichen, davon je 1 Groß- und Kleinbuchstaben sowie eine Zahl. ',
				},
			},
		},
		or: 'oder',
		hello: 'Hello',
		headerTitle: 'SmartoMeter',
		firstName: 'First Name',
		name: 'Name',
		myProfile: 'My Profile',
		addStudent: 'Add Student',
		//navbar
		overview: 'Overview',
		qualifications: 'Qualifications',
		student: 'Student',
		autorentool: 'Authoring tool',
		loginHeader: 'Login',
		password: 'Password',
		forgetPassword: 'Passwort vergessen?',
		login: 'Login',
		resetPassword: 'Passwort zurücksetzen',
		resetPasswordInfo:
			'Gib bitte deine E-Mail Adresse ein. Du erhältst eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts.',
		reset: 'Zurücksetzen',
		goToLogin: 'Go to Login',
		logout: 'Logout',
		formError: 'Form Error',
		lastName: 'Surname',
		email: 'E-Mail',
		close: 'Close',
		save: 'Speichern',
		cancel: 'Abbrechen',
		authoring: {
			exerciseList: {
				questions: 'Fragen',
				deleteQuestionInfo: 'Lösche ausgewählte Frage.',
				deleteQuestion: 'Lösche Frage.',
				deleteQuestionConfirm:
					'Möchten Sie die Frage {{ question }} löschen?',
				detachQuestionInfo: 'Ausgewählte Frage löschen?',
				detachQuestion: 'Entferne Frage',
				detachQuestionConfirm:
					'Sind Sie sicher, dass Sie die Frage {{ question }} entfernen möchten?',
			},
			previewContent: {
				nextContent: 'Nächster Inhalt',
			},
			previewExercises: {
				nextContent: 'Nächster Inhalt',
			},
			renderAssignTerms: {
				selectText: 'Wählen Sie eine Antwort aus',
			},
			renderMultipleChoice: {
				multipleChoice: '(Mehrfach Auswahl)',
			},
			renderOrderWords: {
				selectText: 'Wählen Sie eine Antwort aus',
			},
			renderSingleChoice: {
				singleChoice: '(Einfach Auswahl)',
			},
			renderWordCombinations: {
				OrderWords: '(Wortreihenfolge)',
				selectAnswer: 'Wählen Sie eine Antwort aus',
			},
			previewTabNavigation: {
				learning: 'Lernen',
				exercise: 'Üben',
			},
			gapFills: {
				explanation:
					'Füllen Sie die Lücken im Fließtext mit den richtigen Antwortmöglichkeiten.',
			},
			assignPair: {
				answerExists: 'Anwort bereits vorhanden',
				firstWord: 'Erstes Wort',
				secondWord: 'Zweites Wort',
				saveQuestion: 'Frage speichern',
				editQuestion: 'Frage bearbeiten',
				abort: 'Abbrechen',
				explanation: 'Bilden Sie Paare aus den Antwortmöglichkeiten.',
			},
			saveQuestion: 'Frage speichern',
			editQuestion: 'Frage bearbeiten',
			abort: 'Abbrechen',
			assignTerm: {
				answerExists: 'Anwort bereits vorhanden',
				firstWord: 'Erstes Wort',
				secondWord: 'Zweites Wort',
				saveQuestion: 'Frage speichern',
				editQuestion: 'Frage bearbeiten',
				abort: 'Abbrechen',
				explanation:
					'Ordnen Sie einem Oberbegriff mehrere Antwortmöglichkeiten zu.',
			},
			multipleChoice: {
				answerExists: 'Anwort bereits vorhanden',
				addAnswer: 'Antwort hinzufügen',
				saveQuestion: 'Frage speichern',
				editQuestion: 'Frage bearbeiten',
				abort: 'Abbrechen',
				explanation:
					'Wählen Sie aus mehreren Antwortmöglichkeiten mehrere korrekte Antworten aus.',
			},
			orderWordsSingle: {
				abort: 'Answer already exist',
			},
			orderWords: {
				addAnswer: 'Antwort hinzufügen',
				answerExists: 'Anwort bereits vorhanden',
				editQuestion: 'Frage bearbeiten',
				saveQuestion: 'Frage speichern',
				cancel: 'Abbrechen',
				explanation:
					'Sortieren Sie die Antwortmöglichkeiten in die geforderte Reihenfolge.',
			},
			singleChoice: {
				explanation:
					'Wählen Sie aus mehreren Antwortmöglichkeiten 1 korrekte Antwort aus.',
				answerExists: 'Anwort bereits vorhanden',
				addAnswer: 'Antwort hinzufügen',
				editQuestion: 'Frage bearbeiten',
				saveQuestion: 'Frage speichern',
				cancel: 'Abbrechen',
			},
			questionDetails: {
				singleChoice: 'Single Choice',
				multipleChoice: 'Multiple Choice',
				OrderWords: 'Sortierung',
				AssignPairs: 'Zuordnung – Paare',
				fillGaps: 'Lückentext',
				assignToTerm: 'Zuordnung zu einem Oberbegriff',
				questions: 'Frage',
				enterQuestion: 'Geben Sie hier die Frage ein',
				explanation: 'Erklärung',
				answer: 'Antwort',
			},
			allQuestions: {
				subareas: 'Abschnitt',
				questionType: 'Fragentyp',
				selectAll: 'Alle',
				name: 'Name',
				exercise: 'Übung',
				type: 'Typ',
				newQuestion: 'Neue Frage',
				quetion: 'Fragen',
				deleteSelectedQuestion: 'Entferne ausgewählte Frage',
				deleteQuestion: 'Lösche Frage.',
				deleteConfirm:
					'Sind Sie sicher, dass Sie die Frage {Question} entfernen möchten?',
				deleteQuestionVerify:
					'Möchten Sie alle ausgewählten Fragen löschen?',
				placeholder: 'Name',
			},
			courseDetailPage: {
				courseTitle: 'Titel des Kurses',
				image: 'Bild',
				delete: 'Löschen',
				upload: 'Hochladen',
				content: 'Inhalt',
				generalInstructions: 'Allgemeine Hinweise',
				writtenExamInstructions: 'Prüfungshinweise',
				oralExamInstructions: 'Mündliche Prüfungshinweise',
				partnerPage: 'Partner',
				thanksPage: 'Danksagung',
			},
			sectionPage: {
				section: 'Abschnitt',
				content: 'Inhalt',
				task: 'Aufgabe',
				createNewTask: 'neue Aufgabe erstellen',
				addToThisExam: 'Alle Aufgaben dieser Prüfung hinzufügen',
				addExistingTask: 'Bestehende Aufgabe hinzufügen',
				canNotBeReverted:
					'Diese Aktion kann nicht rückgängig gemacht werden.',
				delete: 'Löschen',
				deleteSectionVerify: 'Möchten Sie den Abschnitt',
				deleteSectionVerifyEnd: 'wirklich löschen?',
				deleteAllQuestions:
					'Sollen alle Fragen ebenfalls gelöscht werden? Falls Fragen in anderen Abschnitten genutzt werden, werden Sie dort ebenfalls gelöscht.',
				save: 'Speichern',
			},
			authoringSettings: {
				courseSettings: 'Einstellungen des Kurses',
				examSettings: 'Prüfungseinstellungen',
				examDuration: 'Prüfungsdauer',
				showAmountCorrectAnswers:
					'Zeige Anzahl korrekter Antworten (nur Mehrfachauswahl)',
				percentageForPassing: 'Prozentzahl zum Bestehen einer Prüfung',
				percent: 'Prozent',
				maxQuestions: 'Max. Anzahl der Prüfungsfragen',
				removePoints: 'Abzugspunkte bei falscher Antwort',
				no: 'Nein',
				yes: 'Ja',
				showIntroduction: 'Einleitung vor dem Start der Prüfung zeigen',
				exerciseSettings: 'Übungseinstellungen',
				percentageForPassingExercise:
					'Prozentzahl zum Bestehen einer Übung?',
				maxAmountQuestions: 'Max. Anzahl der Übungsfragen',
				ex: 'bspw. 10',
				showIntroductionExercise:
					'Einleitung vor dem Start der Übung zeigen',
				saveQuestion: 'Speichern',
				inMinutes: 'in Minuten',
				showAnswerType: 'Fragetyp anzeigen',
			},
			layoutsAuthoring: {
				youCanRevert:
					'Diese Aktion kann nicht rückgängig gemacht werden und betrifft alle zugehörigen Unterbereiche',
				publishNow: 'Jetzt veröffentlichen',
				publishVerify: 'Möchten Sie den Kurs',
				publishVerifyEnd: 'veröffentlichen?',
			},
			questions: {
				type: 'Typ',
				questionTypes: {
					ALL: 'All',
					SINGLE_CHOICE: 'Single Choice',
					MULTIPLE_CHOICE: 'Multiple Choice',
					ORDER_WORDS: 'Sortierung',
					WORD_COMBINATION: 'Zuordnung – Paare',
					ASSIGN_TERM: 'Zuordnung zu einem Oberbegriff',
					GAP_FILLS: 'Lückentext',
				},
			},
			subNavigation: {
				hide: 'verstecken',
				show: 'anzeigen',
				duplicate: 'duplizieren',
				delete: 'löschen',
				createSubSection: 'Unterabschnitt erstellen',
			},
		},
		institutions: {
			headline: 'Institutionen',
			institutions: {
				headline: 'Institutionen',
			},
		},
		users: {
			assignments: {
				headline: 'Kurszuweisungsübersicht',
				createNewButton: 'Neue Kurszuweisung',
			},
			licenses: {
				headline: 'Lizenzen',
				createNewButton: 'Neue Lizenz',
			},
			courses: {
				courses: 'Kurse',
				deleteAssignment: 'Zuweisung löschen',
				deleteAssignmentConfirm:
					'Soll die Zuweisung wirklich gelöscht werden?',
				deleteAllAssignment: 'Zuweisung löschen',
				deleteAllAssignmentConfirm:
					'Sollen alle ausgewählten Zuweisungen gelöscht werden?',
				start: 'Start',
				end: 'Ende',
				readingTime: 'Lesezeit',
				certificate: 'Zertifikat',
				finished: 'Beendet',
			},
			detail: {
				assignCourse: 'Kurs zuweisen',
				writeEmail: 'E-Mail schreiben',
				readingTime: 'ø Lesezeit',
				results: 'ø Bestehensquote',
				finishedCourses: 'Absolvierte Kurse',
				missedDeadlines: 'Fristüberschreitung',
				selectCourse: 'Kurs auswählen',
				addToCourse: 'Zu Kurs hinzufügen',
				addUserToCourse: 'Ausgewählten Nutzer einem Kurs hinzufügen',
				userWillRecieveEmail:
					'Dieser Nutzer wird eine E-Mail erhalten welche einen Link enthält mit welchem man das Passwort zurücksetzen kann.',
				send: 'Senden',
				sendPasswordEmail: 'Sende Passwort-Zurücksetzen E-Mail an',
				courses: 'Kurse',
				statistics: 'Statistiken',
				userOverview: 'Zur Nutzerübersicht',
				changeDetails: 'Details ändern',
				resetPassword: 'Passwort zurücksetzen',
				completed: 'absolviert',
				allocated: 'zugeteilt',
				group: 'Gruppe',
				none: 'keine',
			},
			headline: 'Nutzer',
			users: {
				edit: {
					headline: 'Nutzer bearbeiten',
				},
				headline: 'Nutzerübersicht',
				createNewButton: 'Neuer Nutzer',
				importButton: 'Importieren',
				assignments: {
					ALL: 'Alle Zuweisungen',
					WITH_ASSIGNMENT: 'Nur mit Zuweisung',
					WITHOUT_ASSIGNMENT: 'Nur ohne Zuweisung',
				},
				deadline: {
					ALL: 'Alle Fristen',
					MISSED: 'Frist überschritten',
					NOT_MISSED: 'Frist nicht überschritten',
				},
				finished: {
					ALL: 'Alle Zustände',
					0: 'Nicht absolviert',
					1: 'Absolviert',
				},
				create: {
					error: {
						alreadyTaken: 'Dieser Nutzer existiert bereits',
						alreadyTakenOtherInstitution:
							'Dieser Nutzer existiert bereits in einer anderen Institution',
					},
				},
				bulkCreate: {
					step1: {
						headline: 'Importiere alle Teilnehmer',
						placeholder: 'CSV-Datei auswählen',
						or: 'oder',
						exampleDownload: 'CSV Vorlage herunterladen',
						startImportButtonText: 'Datei importieren',
					},
					step2: {
						importing: 'Datei wird importiert',
						success: {
							message: 'Datei wurde importiert',
						},
						error: {
							headline:
								'Die Datei konnte nicht importiert werden',
							duplicates:
								'{{count}} Nutzer konnte(n) nicht erstellt werden, da sie bereits vorhanden sind. {{imported}} wurde(n) erfolgreich erstellt.',
							malformatted:
								'Das Format der Datei ist nicht korrekt. Bitte nutzen Sie die Vorlage. Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an den Support',
							error: 'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support',
							tryAgain: 'Erneut versuchen',
							generalMessage:
								'Die Datei konnte nicht vollständig importiert werden',
							absoluteMessage:
								'Die Datei konnte nicht importiert werden',
						},
					},
				},
			},
			groups: {
				headline: 'Gruppenübersicht',
				createNewButton: 'Neue Gruppe',
				assignUsers: 'Nutzer hinzufügen',
				detail: {
					deadline: {
						ALL: 'Alle Fristen',
						MISSED: 'Frist überschritten',
						NOT_MISSED: 'Frist nicht überschritten',
					},
				},
			},
		},
		courses: {
			headline: 'Kurse',
			header: {
				courses: 'Kurse',
				bookedCourses: 'smartOmeter Kurse',
				shop: 'Shop',
			},
			filter: {
				active: 'Aktiv',
				archived: 'Archiviert',
				all: 'Alle',
			},
		},
		settings: {
			headline: 'Einstellungen',
			header: {
				general: 'Allgemein',
				presentation: 'Darstellung',
				team: 'Team',
				certificate: 'Zertifikate',
				invoice: 'Rechnungen',
			},
			team: {
				members: {
					headline: 'Teammitglieder',
					create: {
						headline: 'Neues Teammitglied',
					},
				},
				groups: {
					headline: 'Gruppen',
				},
				roles: {
					headline: 'Rollen',
					clarify:
						'Bitte beachten Sie, dass es sich bei den Rollen admin, manager und author um Standardrollen handelt. Diese können nicht von Ihnen angepasst werden. Falls Sie eine weitere Rolle mit personalisierten Rechten benötigen, klicken Sie auf Neue Rolle.',
					deleteMessage:
						'Soll die Rolle {{name}} wirklich gelöscht werden?',
					createNewButton: 'Neue Rolle',
					modal: {
						headline: 'Definiere eine neue Rolle',
						namePlaceholder: 'Neue Rolle eingeben',
					},
					PERMISSION: {
						FE_SOM_DASHBOARD: 'Zugriff auf das Dashboard',
						FE_SOM_USERS: 'Zugriff auf die Nutzerverwaltung',
						FE_SOM_COURSES: 'Zugriff auf die Kursverwaltung',
						FE_SOM_SETTINGS: 'Zugriff auf die Einstellungen',
						NOT_EDITABLE: '(nicht editierbar)',
					},
				},
			},
			general: {
				ownSubdomain: ' Eigene Subdomain',
				domain: 'domain',
				name: 'Firmenname',
				street: 'Straße',
				number: 'Nr.',
				post_code: 'PLZ',
				city: 'Ort',
				country: 'Land',
				vat_id: 'Ust-ID',
				invoice_street: 'Straße',
				invoice_number: 'Nr.',
				invoice_post_code: 'PLZ',
				invoice_city: 'Ort',
				first_name: 'Vorname',
				last_name: 'Name',
				email: 'E-Mail',
				phone: 'Telefonnummer',
			},
		},
		perPage: 'pro Seite',
		from: 'von',
		moreThan: 'mehr als',
		kpis: {
			dashboard: {
				usersAmountTitle: 'Nutzer',
				usersAmount: 'Anzahl an Nutzern (Lernenden) des smartOmeters',
				deviceUsageTitle: 'Gerätenutzung',
				deviceUsage:
					'Prozentuale Verteilung der Nutzung der mobilen, Tablet- oder desktopbasierten Version des smartOmeters',
				allCoursesTitle: 'Kurse',
				allCourses:
					'Gesamtzahl der eigens erstellten und gebuchten Kurse',
				delaysTitle: 'Fristüberschreitungen',
				delays: 'Anzahl der über das Kursende hinausgehenden und gleichzeitig noch nicht absolvierten Kurse bezogen auf den einzelnen Nutzer (1 Fristüberschreitung = 1 Kurs, der von 1 Nutzer noch nicht absolviert wurde)',
				courseProgressTitle: 'Lernfortschritt',
				courseProgress:
					'Prozentualer Fortschritt bezogen auf das Durcharbeiten der Kurse (Lerncontent, Übung und/oder Prüfung). Abgeschlossene Kurse werden nicht berücksichtigt.',
				successRateTitle: 'Bestehensquote',
				successRate:
					'Ergebnis, mit welchem die Nutzer ihre zugeteilten Kurse absolvieren',
				popularCoursesTitle: 'Meist besuchte Kurse',
				popularCourses: 'Kurse mit den höchsten Nutzerzahlen',
				loginsTitle: 'Anmeldungen',
				logins: 'Anzahl der Anmeldungen aller Nutzer in dem Lerntool smartOmeter (mobile, Tablet- und Desktopversion)',
				learningProgress: 'Lernfortschritt',
			},
			userDetail: {
				avgLearningTime:
					'Durchschnittliche Zeit, welche ein Nutzer für das Lesen der Lerncontents nutzt. Die Bearbeitung von Übungen und Prüfungen zählt nicht dazu.',
				avgSuccessRate:
					'Durchschnittliches Ergebnis, mit welchem der Nutzer seine zugeteilten Kurse absolviert',
				finishedCourses:
					'Anzahl der absolvierten Kurse im Verhältnis zur Anzahl aller zugeteilten Kurse',
				delays: 'Anzahl der über das Kursende hinausgehenden und gleichzeitig noch nicht absolvierten Kurse bezogen auf den einzelnen Nutzer (1 Fristüberschreitung = 1 Kurs, der von 1 Nutzer noch nicht absolviert wurde)',
			},
			userStatistics: {
				learningProgress:
					'Prozentualer Fortschritt bezogen auf das Durcharbeiten von Lerncontents/Lernabschnitten',
				readingTime:
					'Zeit, welche ein Nutzer für das Lesen der Lerncontents nutzt. Die Bearbeitung von Übungen und Prüfungen zählt nicht dazu.',
				exerciseExamNo:
					'Anzahl an durchgeführten Übungen und Prüfungen',
				courseEnd:
					'Anzahl der verbleibenden Tage, um den Kurs zu absolvieren',
			},
			groupDetail: {
				users: 'Anzahl an Nutzern (Lernenden) innerhalb der Gruppe',
				courseProgress:
					'Prozentualer Fortschritt bezogen auf das Durcharbeiten der Kurse (Lerncontent, Übung und/oder Prüfung)',
				finishedCourses:
					'Anzahl der absolvierten Kurse im Verhältnis zur Anzahl aller zugeteilten Kurse',
				delays: 'Anzahl der über das Kursende hinausgehenden und gleichzeitig noch nicht abgeschlossenen Kurse bezogen auf den einzelnen Nutzer (1 Fristüberschreitung = 1 Kurs, der von 1 Nutzer noch nicht absolviert wurde)',
			},
			courses: {
				ownCourses: 'Anzahl der eigens erstellten/angelegten Kurse',
				bookedCourses:
					'Anzahl der gebuchten Kurse (durch smartOmeter zur Verfügung gestellt)',
				licenses:
					'Anzahl der aktiven Kurslizenzen für die gebuchten Kurse',
			},
		},
		yes: 'Ja',
		no: 'Nein',
		help: 'Hilfe',
		ex: 'bspw.',
		modals: {
			close: 'Abbrechen',
			addDemoDataModal: {
				header: 'Möchten Sie automatisch erstellte Beispieldaten anlegen?',
			},
			addFromQuestionPoolModal: {
				primaryAction: 'Fragen übernehmen',
				secondaryAction: 'Abbrechen',
				close: 'Schließen',
				takeTask: 'Frage hinzufügen',
				questionsInstitution: 'Fragen aus allen Kursen',
				questionCurrentCourse: 'Fragen aus aktuellem Kurs',
				taksOptions: 'Beschreibung der Frage...',
				withoutSubarea: 'Nicht zugeordnet',
			},
			addGroupModal: {
				errorNameExists: 'Gruppenname existiert bereits',
				validateError: 'Feld wird benötigt',
				header: 'Erstelle eine neue Gruppe',
				createGroup: 'Gruppe erstellen',
				close: 'Abbrechen',
			},
			addMemberModal: {
				firstNameRequired: 'Feld wird benötigt',
				lastNameRequired: 'Feld wird benötigt',
				emailRequired: 'Feld wird benötigt',
				roleRequired: 'Feld wird benötigt',
				headline: 'Einstellungen',
				cancel: 'Abbrechen',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail Adresse',
				role: 'Rolle',
				note: 'Passwort wird vom Nutzer bei Neuanmeldung selbst erstellt',
				save: 'Speichern',
			},
			addUserToGroupModal: {
				addUsersMessage: 'Nutzer hinzufügen',
				close: 'Abbrechen',
				placeholder: 'Nutzer',
				modalTitle: 'Nutzer wählen',
				confirm: 'Speichern',
			},
			addRoleModal: {
				releaseRights: 'Zugriffsrechte für diese Rolle:',
			},
			addUserModal: {
				firstNameRequired: 'Feld wird benötigt',
				lastNameRequired: 'Feld wird benötigt',
				emailRequired: 'Feld wird benötigt',
				header: 'Lege einen Nutzer an',
				submit: 'Nutzer anlegen',
				close: 'Abbrechen',
				secondaryAction: 'Weitere Nutzer hinzufügen',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail Adresse',
				note: 'Passwort wird vom Nutzer bei Neuanmeldung selbst erstellt',
				placeholder: 'Kurse',
				modalTitle: 'Kurse wählen',
			},
			assignGroupModal: {
				close: 'Abbrechen',
				groups: 'Gruppen',
			},
			assignUserModal: {
				close: 'Abbrechen',
				selectUser: 'Nutzer auswählen',
				selectCourse: 'Kurs auswählen',
				start: 'Start',
				end: 'Ende',
				costsArise:
					'Bitte beachten Sie, dass <strong>Kosten entstehen</strong>, wenn Sie einem Nutzer oder einer Gruppe <strong>Smartometer-Kurse</strong> zuweisen. Durch das Zuweisen von selbst erstellten Kursen, entstehen keine zusätzlichen Kosten.',
				notificationDate: 'Benachrichtigungsdatum',
			},
			confirmModal: {
				close: 'Abbrechen',
			},
			deleteModal: {
				close: 'Cancel',
			},
			editAssignmentModal: {
				close: 'Abbrechen',
				start: 'Start',
				end: 'Ende',
			},
			editGroupModal: {
				submit: 'Speichern',
				close: 'Abbrechen',
				groupExists: 'Gruppe existiert bereits',
				fieldRequired: 'Feld wird benötigt',
				header: 'Gruppenname bearbeiten',
				name: 'Name',
			},
			editMemberModal: {
				firstnameRequired: 'Feld wird benötigt',
				lastNameRequired: 'Feld wird benötigt',
				emailRequired: 'Feld wird benötigt',
				roleRequired: 'Feld wird benötigt',
				headline: 'Teammitglieder',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail Adresse',
				role: 'Rolle',
				note: 'Passwort wird vom Nutzer bei Neuanmeldung selbst erstellt',
			},
			editRoleModal: {
				nameRequired: 'Feld wird benötigt',
				headline: 'Rollen',
				title: 'Gebe die Rechte für diese Rolle frei',
			},
			editUserDataModal: {
				required: 'Feld wird benötigt',
				noValidEmail: 'Keine gültige E-Mail Adresse',
				myProfile: 'Mein Profil',
				headline: 'Nutzer bearbeiten',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail Adresse',
				groups: 'Gruppen',
			},
			purchaseModal: {
				unlockSmartometer: 'smartOmeter freischalten',
				contactSupport: 'Support kontaktieren',
				nameRequired: 'Feld wird benötigt',
				emailRequired: 'Feld wird benötigt',
				messageRequired: 'Feld wird benötigt',
				fromRequired: 'Feld wird benötigt',
				phoneRequired: 'Feld wird benötigt',
				tillRequired: 'Feld wird benötigt',
				selectOneDay: 'Bitte wählen Sie mindestens einen Wochentag aus',
				noValIdEmail: 'Keine gültige E-Mail Adresse',
				contactSmartometerSupport: 'smartOmeter Support kontaktieren',
				requestActivation: 'Freischaltung des smartOmetes anfordern',
				fillDetails:
					'Bitte füllen Sie vorab wenige Details aus, damit wir Sie im nächsten Schritt kontaktieren können.',
				subject: 'Betreff',
				message: 'Nachricht',
				name: 'Name',
				email: 'E-Mail Adresse',
				phone: 'Telefon',
				monday: 'Montag',
				tuesday: 'Dienstag',
				wednesday: 'Mittwoch',
				thursday: 'Donnerstag',
				friday: 'Freitag',
				weekdayAlert: 'Bitte wähle mindestens einen Wochentag aus',
				from: 'Von',
				to: 'Bis',
			},
			purchaseOrSupportModal: {
				trialExpired: 'Ihre Testversion ist abgelaufen',
				bookFullVersion:
					'Hat Ihnen die Testversion gefallen? Dann buchen Sie die Vollversion und erstellen Sie einfach und schnell Schulungen für Ihre Mitarbeiter.',
				activateSmartometer:
					'smartOmeter Zugang freischalten oder Support kontaktieren?',
			},
			questionValidationModal: {
				submit: 'Weiter',
				header: 'Diese Eingabe ist ungültig',
			},
			removeDemoDataModal: {
				header: 'Möchten Sie die anfangs angelegten Beispieldaten weiterhin in Ihrem System nutzen?',
			},
			thankYouModal: {
				header: 'Vielen Dank, wir rufen Sie zurück.',
				subheader:
					'Wir werden Sie zum ausgewählten Zeitpunkt kontaktieren um die Freischaltung für Sie zu ermöglichen.',
			},
		},
		layout: {
			exerciseCountButton: {
				tasks: 'Fragen',
			},
			authoringSidebar: {
				addSection: 'Abschnitt hinzufügen',
				introduction: 'Kursinformationen',
			},
			authoringSubnavigation: {
				learningSection: 'Lernabschnitt',
				exercise: 'Übung',
				exam: 'Prüfung',
				selectPlaceholder: 'Bitte wählen',
			},
			authoringNavbar: {
				settings: 'Einstellungen',
				preview: 'Vorschau',
				published: 'Veröffentlicht',
				publish: 'Veröffentlichen',
				clearCache: 'Cache löschen',
				draft: 'Entwurf',
			},
			mainNavbar: {
				myProfile: 'Mein Profil',
				addUser: 'Nutzer hinzufügen',
				addCourse: 'Kurs hinzufügen',
				addGroup: 'Gruppe hinzufügen',
				addAssignment: 'Zuweisung hinzufügen',
				addMember: 'Mitglied hinzufügen',
				signedWith: 'Angemeldet als',
				trialVersion: 'Testversion noch',
				upgradeNow: 'jetzt Upgraden',
				welcomeBack: 'Willkommen zurück,',
				noDataFound: 'Keine Daten gefunden',
				addToCourse: 'Zu Kurs hinzufügen',
				addSelectedUser: 'Ausgewählten Nutzer einem Kurs hinzufügen',
				hello: 'Hallo',
				signOut: 'Abmelden',
			},
			mainSidebar: {
				overview: 'Übersicht',
				user: 'Nutzer',
				courses: 'Kurse',
				institutions: 'Institutionen',
				authoringTool: 'Autorentool',
				settings: 'Einstellungen',
				goToApp: 'Zur App',
			},
			orientationButton: {
				portrait: 'Hochformat',
				landscape: 'Querformat',
			},
			footer: {
				appucationApp: 'Appucation App',
			},
		},
		tabs: {
			institutionsOverviewTab: {
				id: 'ID',
				institutions: 'Institutionen',
				name: 'Name',
				creator_name: 'Ersteller',
				created_at: 'Erstellt am',
				trial_end_at: 'Testen bis',
				users_count: 'Benutzer',
				appusage: 'Appucations Apps Nutzer',
				paid_period_start_at: 'Kunde ab',
			},
			statisticsTab: {
				userLife: 'Nutzungsdauer',
				passRate: 'Bestehensquote',
				courseCompleted: 'Absolvierte Kurse',
				deadlineExceeded: 'Fristüberschreitung',
			},
			licenseOverviewTab: {
				new: 'Neue Lizenz',
				edit: 'Lizenz bearbeiten',
			},
			assignmentOverviewTab: {
				selectAll: 'Alle',
				nameOfCourse: 'Suche',
				start: 'Start',
				end: 'Ende',
				startDate: 'Start-Datum',
				changeDate: 'Datum anpassen',
				endDate: 'End-Datum',
				assignment: 'Zuweisung',
				addToCourse: 'Zu Kurs hinzufügen',
				addSelectedUser: 'Ausgewählten Nutzer einem Kurs hinzufügen',
				save: 'Speichern',
				editAssignment: 'Ausgewählte Zuweisung bearbeiten',
				deleteAssignment: 'Zuweisung löschen',
				deleteAssignmentConfirm:
					'Möchten Sie diese Zuweisung wirklich löschen?',
				deleteAllAssignmentsConfirm:
					'Möchten Sie alle ausgewählten Zuweisungen wirklich löschen?',
			},
			certificateTab: {
				certificateTemplate: 'Zertifikat Vorlage',
				preview: 'Vorschau',
				certificateTitle: 'Zertifikate',
			},
			coursesAppucationTab: {
				name: 'Name',
				externalCouse: 'Externe Kurse',
				licence: 'Lizenzen',
				course: 'Kurse',
				users: 'Nutzer',
				created: 'Erstellt',
				updated: 'Letzt. Bearb.',
			},
			coursesShopTab: {
				searchCourse: 'Suchen Sie nach einem Kurs',
				course: 'Kurs',
				addUserToCourse: 'Ausgewählten Nutzer einem Kurs hinzufügen',
				addCourse: 'Zu Kurs hinzufügen',
				assignCourse: 'Kurs zuweisen',
				courseOverview: 'Kursübersicht',
			},
			coursesTab: {
				selectAll: 'Alle',
				course: 'Kurs',
				user: 'Nutzer',
				created: 'Erstellt',
				updatedAt: 'Letzt. Bearb.',
				published: 'Veröffentlicht',
				notPublished: 'Nicht veröffentlicht',
				status: 'Status',
				filter: 'Filter',
				newCourse: 'Neuer Kurs',
				clearCache: 'Cache löschen',
				archiveConfirm: 'Möchten Sie diesen Kurs archivieren?',
				archiveCourse: 'Archiviere Kurs',
				archive: 'Archiviere',
				restoreConfirm: 'Möchten Sie diesen Kurs wiederherstellen?',
				restoreCourseConfirm:
					'Möchten Sie die ausgewählten Kurse wiederherstellen?',
				restoreCourse: 'Kurs wiederherstellen',
				restore: 'wiederherstellen',
				ownCourses: 'Eigene Kurse',
			},
			editGeneralForm: {
				selectCountry: 'Land auswählen',
				billingAddress: 'Rechnungsadresse abweichend',
				contactPerson: 'Ansprechpartner',
			},
			groupOverviewTabDetail: {
				courseProgress: 'Kursfortschritt',
				feedbackEmail: 'Feedback E-Mail',
				progress: 'Fortschritt',
				email: 'Filter',
				deleteGroup: 'Gruppe löschen',
				editGroup: 'Gruppenname ändern',
				import: 'Importieren',
				addUser: 'Nutzer hinzufügen',
				amountUsers: 'Anzahl der Nutzer',
				finishedCourse: 'Absolvierte Kurse',
				finished: 'absolviert',
				assigned: 'zugeteilt',
				delays: 'Fristüberschreitung',
				NoUserAssigned: 'Dieser Gruppe wurden keine Nutzer zugewiesen',
				deleteGroupMessage:
					'Durch das Löschen gehen auch die Verknüpfungen der ihr zugewiesenen Nutzern verloren.',
				deleteGroupConfirm: 'Soll die Gruppe',
				deleteGroupConfirmEnd: 'wirklich gelöscht werden?',
				detachUser: 'Entferne Nutzer',
				detachConfirm: 'Möchten Sie den Nutzer',
				detachConfirmEnd: 'von dieser Gruppe entfernen?',
			},
			groupOverviewTab: {
				name: 'Name',
				members: 'Mitglieder',
				assign: 'Zuweisen',
				course: 'Kurs',
				filter: 'Filter',
				groups: 'Gruppen',
				DeleteGroupMessage:
					'Durch das Löschen gehen auch die Verknüpfungen der ihr zugewiesenen Nutzern verloren.',
				deleteGroup: 'Gruppe löschen',
				deleteGroupConfirm: 'Soll die Gruppe',
				deleteGroupConfirmEnd: 'wirklich gelöscht werden',
				deleteSelectedGroup: 'Gruppe löschen',
				deleteSelectedGroupConfirm:
					'Sollen alle ausgewählten Gruppen gelöscht werden?',
				addCourse: 'Kurs hinzufügen',
				addUserToCourse: 'Ausgewählte Nutzer einem Kurs hinzufügen',
				addSelectedCourse: 'Kurs hinzufügen',
				addSelectedUserToCourse:
					'Ausgewählten Nutzer einem Kurs hinzufügen',
				selectAll: 'Alle',
				addUsersMessage: 'Nutzer hinzufügen',
			},
			invoiceTab: {
				infoiceFrom: 'Rechnung vom',
				years: 'Jahre',
				invoices: 'Rechnungen',
				download: 'Herunterladen',
			},
			memberOverviewTab: {
				selectAll: 'Alle',
				name: 'Name',
				role: 'Rolle',
				newMember: 'Neues Teammitglied',
				deleteTeamMember: 'Teammitglied löschen',
				deleteTeamMemberConfirm: 'Soll das Teammitglied',
				deleteTeamMemberConfirmEnd: 'wirklich gelöscht werden?',
				removeAccess: 'Nur Smartometer Zugang entziehen',
				deleteUser: 'Nutzer komplett löschen',
				teamMembers: 'Teammitglieder',
			},
			permissionList: {
				deleteRole: 'Rolle löschen',
				deleteRoleConfirm: 'Soll die Rolle',
				deleteRoleConfirmEnd: 'wirklich gelöscht werden?',
				loading: 'Wird geladen ...',
			},
			userOverviewTab: {
				selectAll: 'Alle',
				name: 'Name',
				group: 'Gruppe',
				course: 'Aktuelle Kurse',
				assign: 'Zuweisen',
				assignments: 'Zuweisungen',
				missedDeadline: 'Fristüberschreitung',
				groups: 'Gruppen',
				courses: 'Kurse',
				deleteUser: 'Nutzer löschen',
				deleteUserConfirm: 'Soll der Nutzer',
				deleteUserConfirmEnd: 'wirklich gelöscht werden?',
				userNoAccess:
					'Dieser Nutzer hat auch Zugriff auf das Smartometer',
				removeCourseAccess: 'Nur Kurszugang entziehen',
				addToGroup: 'Zu Gruppe hinzufügen',
				addUserToGrouo: 'Ausgewählte Nutzer einer Gruppe hinzufügen',
				addCourse: 'Kurs hinzufügen',
				addUserToCourse: 'Ausgewählten Nutzer einem Kurs hinzufügen',
				deleteModalConfirm: 'Nutzer löschen',
				deleteModalMessage:
					'Sollen alle ausgewählten Nutzer gelöscht werden?',
				users: 'Nutzer',
				statistics: 'Statistik',
			},
			visualSettingsTab: {
				colorScheme: 'Farbschema',
				changeColorMessage:
					'Mit dieser Auswahl ändern die die Aktzentfarbe der smartOmeter Benutzeroberfläche',
				selectColor: 'Farbe wählen',
				save: 'Speichern',
				cancel: 'Abbrechen',
				colorCode: 'Farbcode (Hex)',
				confirm: 'bestätigen',
				logo: 'Logo',
				uploadFaviconMessage:
					'Hier können Sie ihr Firmenlogo und das entsprechende Favicon hochladen. ',
				uploadLogo: 'Logo hochladen',
				recomendedDimentions: 'Empfohlene Größe: 250x70px',
				recomendedFileformat: 'Empfohlene Dateiformate: .png,.jpg',
				delete: 'Löschen',
				uploadFavicon: 'Favicon hochladen',
				recomendedFavDimentions: 'Empfohlene Größe: 32x32px',
				recomendedFavFileformat:
					'Empfohlene Dateiformate: .png,.jpg,.ico',
				changeSaved: 'Änderungen gespeichert.',
				upload: 'Hochladen',
				specifyHEX: 'HEX-Farbcode angeben',
			},
		},
		shared: {
			baseModal: {
				createUserHeadline: 'Lege einen Nutzer an',
				passwordWillBeSetByUser:
					'Passwort: Dieses wird vom Nutzer bei Neuanmeldung selbst erstellt',
				addAnotherUser: 'Weiteren Nutzer anlegen',
				createUser: 'Nutzer anlegen',
			},
			bulkAction: {
				action: 'Aktion',
			},
			confirmationModal: {
				cancel: 'Cancel',
				ok: 'OK',
			},
			dashboardFilterHasMany: {
				search: 'Suchen',
			},
			dashboardFilter: {
				timeframe: 'Gesamtzeitraum',
				startDate: 'Start-Datum',
				endDate: 'End-Datum',
				period: 'Zeitraum',
				last365Days: 'Letzte 365 Tage',
				last90Days: 'Letzte 90 Tage',
				last30Days: 'Letzte 30 Tage',
				last7Days: 'Letzte 7 Tage',
				last24Hours: 'Letzte 24 Stunden',
				groups: 'Gruppen',
				courses: 'Kurse',
				filterByGroup: 'Nach Gruppen filtern',
				filterByCourse: 'Nach Kursen filtern',
			},
			iconDropdown: {
				createNew: 'Neu erstellen',
				noPermissions: 'Keine Rechte',
			},
			multiselect: {
				all: 'Alle',
				save: 'Speichern',
				search: 'Suche...',
			},
			barChart: {
				readingTime: 'ø Lesezeit',
				last30Days: 'der letzten 30 Tage',
			},
			columnChart: {
				exercisesAndExams: 'Übungen & Prüfungen',
				exercise: 'Übung',
				exam: 'Prüfung',
			},
			donutChart: {
				courseEnd: 'Kursende',
				daysTillCourseEnd: 'Tage bis Kursende',
				days: 'Tage',
				deadline: 'Fristabgabe',
			},
			statisticsCourse: {
				exercise: 'Übung',
				exercises: 'Übungen',
				exam: 'Prüfung',
				exams: 'Prüfungen',
				learningProgress: 'Lernfortschritt',
				last30Days: 'die letzten 30 Tage',
				readingTime: 'Lesezeit',
				exercisesAndExams: 'Übungen & Prüfungen',
				courseEnd: 'Kursende',
				readingTimeInSeconds: 'Lesezeit in Sekunden',
				readingTimeInMinutes: 'Lesezeit in Minuten',
			},
			statistics: {
				exercise: 'Übung',
				exercises: 'Übungen',
				exam: 'Prüfung',
				exams: 'Prüfungen',
				filter: 'Filtern',
				filters: 'Filter',
				learningProgress: 'Lernfortschritt',
				last30Days: 'die letzten 30 Tage',
				readingTime: 'Lesezeit',
				exerciseAndExams: 'Übungen & Prüfungen',
				days: 'Tage',
				day: 'Tag',
				courseEnd: 'Kursende',
			},
			tableFilter: {
				filter: 'Filter',
			},
			tableSearch: {
				nameAndEmail: 'Suche',
			},
		},
		others: {
			blocker: {
				desktopRequired: 'Desktop-Endgerät benötigt',
				mobileDevicesNotAvailable:
					'Der Zugriff auf den smartOmeter-Administrationsbereich über mobile Endgeräte ist vorerst nicht möglich.',
				clickButtonBelow:
					'Möchten Sie stattdessen in den Lernmodus wechseln, klicken Sie auf untenstehenden Button.',
				learningMode: 'Zur Endnutzer-Ansicht',
				termsOfServices: 'AGB',
				legalNotice: 'Impressum',
				privacyPolicy: 'Datenschutzbestimmungen',
			},
		},
		helpers: {
			globalSearch: {
				dashboard: 'Dashboard',
				userOverview: 'Nutzerübersicht',
				groupOverview: 'Gruppenübersicht',
				assignmentOverview: 'Kurszuweisungsübersicht',
				courses: 'Kurse',
				appucationCourses: 'smartOmeter Kurse',
				shop: 'Shop',
				createNewCourse: 'Neuen Kurs erstellen',
				general: 'Allgemein',
				cisualSettings: 'Darstellung',
				team: 'Teammitglieder',
				roles: 'Rollen',
				invoices: 'Rechnung/en',
			},
			assignPairQuestions: {
				nameValidation: 'Geben Sie einen gültigen Namen ein',
				questionValidation: 'Geben Sie eine Frage ein',
			},
			assignTerm: {
				nameValidation: 'Geben Sie einen gültigen Namen ein',
				questionValidation: 'Geben Sie eine Frage ein',
			},
			gapFillQuestions: {
				nameValidation: 'Geben Sie einen gültigen Namen ein',
				textValidation: 'Bitte fügen Sie hier einen Text ein',
				gapValidation:
					'Bitte markieren Sie einen Textbaustein als Lücke',
			},
			multipleQuestions: {
				nameValidation: 'Geben Sie einen gültigen Namen ein',
				questionValidation: 'Geben Sie eine Frage ein',
				answerValidation: 'Bitte markieren Sie die korrekten Antwort',
			},
			orderWordsQuestions: {
				nameValidation: 'Geben Sie einen gültigen Namen ein',
				questionValidation: 'Geben Sie eine Frage ein',
			},
			singleQuestions: {
				titleValidation: 'Geben Sie einen gültigen Namen ein',
				questionValidation: 'Geben Sie eine Frage ein',
				answerValidation: 'Bitte legen Sie die korrekte Antwort fest',
			},
		},
	},
};
