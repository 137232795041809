import { Close, ArrowBack } from '@material-ui/icons';
import React from 'react';
import { color } from '../../../../utils/styles';

interface PreviewNavigationHeaderProps {
	handleClose: Function;
	handleNavigate: Function;
	showClose: boolean;
	subareaName: string;
	parentName: string | null;
}

const PreviewNavigationHeader: React.FunctionComponent<PreviewNavigationHeaderProps> =
	({ handleClose, handleNavigate, subareaName, parentName, showClose }) => {
		return (
			<div
				className="preview-window-navigation-header"
				style={{
					borderBottomColor:
						window.localStorage.getItem('institutionColor') || color.link,
				}}
			>
				<div
					className={`preview-window-navigation-header-left ${
						!parentName &&
						'preview-window-navigation-header-left-noback'
					}`}
				>
					<ArrowBack
						onClick={
							!!parentName ? () => handleNavigate() : () => {}
						}
					/>
					<div className="preview-window-navigation-header-headlines">
						{!!parentName && <h4>{parentName}</h4>}
						<h3>{subareaName}</h3>
					</div>
				</div>

				{showClose && (
					<Close
						onClick={() => handleClose()}
						className="hide-desktop"
					/>
				)}
			</div>
		);
	};

export default PreviewNavigationHeader;
