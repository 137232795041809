import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	Overlay: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: 999999999999,
		backdropFilter: 'blur(20px)',
		opacity: 0.9,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transition: '.5s',

		'&.opq': {
			opacity: 1,
		},

		'& svg': {
			color: 'rgba(255, 255, 255, 0.6)',
		},
	},
	Hidden: {
		display: 'none',
	},
	Hiding: {
		opacity: 0,

		'&.opq': {
			opacity: 0,
		},
	},
}));
