import React from "react";

interface SideLabelProps {
    text?: string;
}

const SideLabel: React.FunctionComponent<SideLabelProps> = ({ text }) => {
    return (
        <label className="coursewizard-label">
            { text }
        </label>
    )
}

export default SideLabel;
