import {translate} from "../../../i18n/i18n";
import React, {useRef} from 'react'
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {DropdownProps} from "semantic-ui-react";
import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {PurchaseOrSupportType} from "../../../enums/purchase/purchase-enums";
import useUserContext from "../../../contexts/UserContext";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Alert} from "@material-ui/lab";
import {useDispatch} from "react-redux";
import {createSupport} from "../../../store/supports/supportSlice";

interface PurchaseOrSupportProps {
    showModal: boolean,
    closeModal: () => void,
    type: PurchaseOrSupportType,
    trialOver?: boolean
}

interface PurchaseOrSupportInterface {
    subject: string;
    type: PurchaseOrSupportType;
    message: string;
    name: string;
    email: string;
    phone: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    from: string;
    till: string;
    weekday?: string;

}


interface PurchaseOrSupportErrorInterface {
    subject?: string;
    type?: PurchaseOrSupportType;
    message?: string;
    name?: string;
    email?: string;
    phone?: string;
    monday?: string;
    tuesday?: string;
    wednesday?: string;
    thursday?: string;
    friday?: string;
    from?: string;
    till?: string;
    weekday?: string;
}

const PurchaseModal: React.FunctionComponent<PurchaseOrSupportProps> = ({showModal, closeModal, type, trialOver}) => {
    const {user} = useUserContext()
    const classes = useStyles();
    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const initialForm: PurchaseOrSupportInterface = user && {
        subject: type === PurchaseOrSupportType.PURCHASE ? translate('modals.purchaseModal.unlockSmartometer') : type === PurchaseOrSupportType.SUPPORT ? translate('modals.purchaseModal.contactSupport') : '',
        type,
        message: '',
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: '',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        from: '08:00',
        till: '17:00'
    }

    const onValidate = (val: PurchaseOrSupportInterface) => {
        const errors: PurchaseOrSupportErrorInterface = {};
        if (!val.name.trim()) errors.name = translate('modals.purchaseModal.nameRequired');
        if (!val.email.trim()) errors.email = translate('modals.purchaseModal.emailRequired');
        if (!val.message.trim()) errors.message = translate('modals.purchaseModal.messageRequired');
        if (!val.from.trim()) errors.from = translate('modals.purchaseModal.fromRequired');
        if (!val.phone.trim()) errors.phone = translate('modals.purchaseModal.phoneRequired');
        if (!val.till.trim()) errors.till = translate('modals.purchaseModal.tillRequired');
        if (!val.monday
            && !val.tuesday
            && !val.wednesday
            && !val.thursday
            && !val.friday) errors.weekday = translate('modals.purchaseModal.selectOneDay');
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val.email.trim())) errors.email = translate('modals.purchaseModal.noValIdEmail');
        return errors;
    }

    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = (values: PurchaseOrSupportInterface) => {
        dispatch({
            ...createSupport({
                requestType: "POST",
                body: values,
                request: `/smartometer/support/`,
            }),
        });
        closeModal();
    }

    return (<>
        <Modal showModal={showModal} header={''} onClose={!trialOver ? () => {
            closeModal()
        } : undefined}
               onSubmit={handleFormikSubmit} isLoading={false} submitActionTitle={translate(`callBack`)}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               buttonWrapper={classes.MainButtonWrapper}
        >
            <Formik
                validate={onValidate}
                innerRef={formikRef}
                initialValues={initialForm}
                onSubmit={onSubmit}
            >
                {({handleChange, handleBlur, values, errors, touched, setFieldValue}) => {

                    const handleSelectChange = (e: any, dropdown: DropdownProps) => setFieldValue(dropdown.name, dropdown.value);

                    return (
                        <div>
                            <h2 className={classes.header}>{type === PurchaseOrSupportType.SUPPORT ? translate('modals.purchaseModal.contactSmartometerSupport') : translate('modals.purchaseModal.requestActivation')}</h2>
                            <p className={classes.paragraph}>{translate('modals.purchaseModal.fillDetails')}</p>
                            <div className={classes.FormWrapper}>
                                <TextField className={classes.Input}
                                           error={touched.subject && !!errors.subject}
                                           helperText={touched.subject && !!errors.subject && errors.subject}
                                           id="subject" value={values.subject} placeholder={translate('modals.purchaseModal.subject')}
                                           name="subject" variant="outlined" onChange={handleChange}
                                           onBlur={handleBlur}/>
                                <TextField className={classes.Input}
                                           error={touched.message && !!errors.message}
                                           helperText={touched.message && !!errors.message && errors.message}
                                           id="message" value={values.message} placeholder={translate('modals.purchaseModal.message')}
                                           name="message"
                                           multiline
                                           rows={4}
                                           variant="outlined" onChange={handleChange} onBlur={handleBlur}/>
                                <TextField className={classes.Input} error={touched.name && !!errors.name}
                                           helperText={touched.name && !!errors.name && errors.name} id="name"
                                           value={values.name} placeholder={translate('modals.purchaseModal.name')} name="name" variant="outlined"
                                           onChange={handleChange} onBlur={handleBlur}/>
                                <TextField className={classes.Input} error={touched.email && !!errors.email}
                                           helperText={touched.email && !!errors.email && errors.email} id="email"
                                           value={values.email} placeholder={translate('modals.purchaseModal.email')} name="email"
                                           variant="outlined"
                                           onChange={handleChange} onBlur={handleBlur}/>
                                <TextField className={classes.Input} error={touched.phone && !!errors.phone}
                                           helperText={touched.phone && !!errors.phone && errors.phone} id="phone"
                                           value={values.phone} placeholder={translate('modals.purchaseModal.phone')} name="phone" variant="outlined"
                                           onChange={handleChange} onBlur={handleBlur}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.monday}
                                            onChange={handleChange}
                                            name="monday"
                                            color="primary"
                                        />
                                    }
                                    label={translate('modals.purchaseModal.monday')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.tuesday}
                                            onChange={handleChange}
                                            name="tuesday"
                                            color="primary"
                                        />
                                    }
                                    label={translate('modals.purchaseModal.tuesday')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.wednesday}
                                            onChange={handleChange}
                                            name="wednesday"
                                            color="primary"
                                        />
                                    }
                                    label={translate('modals.purchaseModal.wednesday')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.thursday}
                                            onChange={handleChange}
                                            name="thursday"
                                            color="primary"
                                        />
                                    }
                                    label={translate('modals.purchaseModal.thursday')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.friday}
                                            onChange={handleChange}
                                            name="friday"
                                            color="primary"
                                        />
                                    }
                                    label={translate('modals.purchaseModal.friday')}
                                />
                                <div className={classes.ErrorWrapper}>
                                    {errors.weekday &&
                                    <Alert severity="error">{translate('modals.purchaseModal.weekdayAlert')}</Alert>}
                                </div>
                                <div className={classes.InputWrapper}>
                                    <TextField className={`${classes.Input} ${classes.InputDemi}`}
                                               error={touched.from && !!errors.from}
                                               helperText={touched.from && !!errors.from && errors.from}
                                               id="from" value={values.from} placeholder={translate('modals.purchaseModal.from')}
                                               type="time"
                                               name="from" variant="outlined" onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    <TextField className={classes.InputDemi}
                                               error={touched.till && !!errors.till}
                                               helperText={touched.till && !!errors.till && errors.till}
                                               id="till" value={values.till} placeholder={translate('modals.purchaseModal.to')}
                                               type="time"
                                               name="till"
                                               variant="outlined" onChange={handleChange} onBlur={handleBlur}/>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </Modal>
    </>);
};

export default PurchaseModal;
