import React, {useEffect, useState} from 'react';
import {Grid} from 'semantic-ui-react';

import {useStyles} from './styles';
import axios from '../../../app/axios';
import {usePagination} from '../../../helpers/usePagination';
import {initialSorting, SortingInterface,} from '../../../interfaces/sorting-interface';
import DeleteModal from '../../modals/DeleteModal';

import ContentCard from '../../shared/ContentCard';
import TableAction from '../../shared/TableAction';
import Table from '../../shared/Table';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCourseQuestionCount} from '../../../store/courses/courseSlice';
import {useParams} from 'react-router';
import {translate, translateWithVariable} from '../../../i18n/i18n';
import {fetchAll, questionSelector} from "../../../store/questions/questionSlice";

const ExerciseList: React.FunctionComponent<{
    subAreaId: number;
    selectToEdit: Function;
    triggerTable: boolean;
}> = ({subAreaId, selectToEdit, triggerTable}) => {
    const classes = useStyles();
    const [sorting, setSorting] = useState<SortingInterface>(initialSorting);
    const dispatch = useDispatch();

    const { page: currentPage } = useParams<{ page: string; subpage?: string }>();

    const {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        handleResetPage,
    } = usePagination();

    const [shownModal, setShownModal] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const {courseId} = useParams<{ courseId: string }>();
    const {isLoading, data} = useSelector(questionSelector);
    const [selectedQuestion, setSelectedQuestion] = useState<{
        id: number;
        name: string;
    } | null>(null);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        handleResetPage();
    }, [rowsPerPage, sorting, currentPage]);

    useEffect(() => {
        if (subAreaId && subAreaId !== 0) {
            fetchQuestions();
        }
    }, [subAreaId, trigger, sorting, page, rowsPerPage, triggerTable]);

    const resetModals = () => {
        setShownModal(null);
        setSelectedQuestion(null);
    };

    const handleDeleteQuestion = async () => {
        try {
            setIsDeleting(true);
            await axios
                .delete(
                    `subarea/${subAreaId}/questions/${
                        selectedQuestion && selectedQuestion.id
                    }`,
                );
            setIsDeleting(false);
            dispatch({
                ...fetchCourseQuestionCount({
                    requestType: 'GET',
                    request: `smartometer/courses/${courseId}`,
                }),
            });
            resetModals();
            setTrigger(!trigger);
        } catch (e: any) {
            console.log(e);
        }
    };

    const handleDetachQuestion = async () => {
        setIsDeleting(true);
        await axios
            .delete(
                `subarea/${subAreaId}/questions-reference/${
                    selectedQuestion && selectedQuestion.id
                }`,
            )
            .then((response: any) => {
                setIsDeleting(false);
                dispatch({
                    ...fetchCourseQuestionCount({
                        requestType: 'GET',
                        request: `smartometer/courses/${courseId}`,
                    }),
                });
                resetModals();
                setTrigger(!trigger);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const fetchQuestions = async () => {
        dispatch({
            ...fetchAll({
                requestType: 'GET',
                request: `smartometer/subarea/${subAreaId}/questions?page=${page}&limit=${rowsPerPage}&sort=${
                    sorting.column
                }&type=${sorting.desc ? `desc` : `asc`}`,
            }),
        });
    };

    const handleChangeSorting = (column: string) => {
        if (column === sorting.column && sorting.desc)
            setSorting(initialSorting);
        else if (column === sorting.column && !sorting.desc)
            setSorting({...sorting, desc: true});
        else if (column !== sorting.column)
            setSorting({...sorting, column: column});
    };

    const handleEditQuestion = (id: number, name: string) => {
        selectToEdit(id, name);
    };

    const setQuestionToDelete = (id: number, name: string) => {
        setShownModal('delete');
        setSelectedQuestion({id, name});
    };

    const setQuestionToDetach = (id: number, name: string) => {
        setShownModal('detach');
        setSelectedQuestion({id, name});
    };

    const questionRowData = {
        name: {
            title: 'Name',
            sorting: 'question',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p style={{cursor: 'pointer'}}>{item.question}</p>
            ),
        },

        type: {
            title: translate(`authoring.questions.type`),
            sorting: 'type',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p style={{cursor: 'pointer'}}>
                    {translate(
                        `authoring.questions.questionTypes.${item.type}`,
                    )}
                </p>
            ),
        },

        edit: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <TableAction
                    handleClick={() =>
                        handleEditQuestion(item.id, item.question)
                    }
                    tooltip={translate('tooltip.edit')}
                    type="edit"
                />
            ),
        },
        detach: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <TableAction
                    handleClick={() =>
                        setQuestionToDetach(item.id, item.question)
                    }
                    tooltip={translate('tooltip.archive')}
                    type="archive"
                />
            ),
        },
        delete: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <TableAction
                    handleClick={() =>
                        setQuestionToDelete(item.id, item.question)
                    }
                    tooltip={translate('tooltip.delete')}
                    type="delete"
                />
            ),
        },
    };

    const headerComponent = <>...</>;

    return (
        <div className={classes.ListWrapper}>
            <>
                <Grid style={{marginBottom: 50}}>
                    <ContentCard
                        title={translate('authoring.exerciseList.questions')}
                        headerComponent={headerComponent}
                    >
                        <Table
                            showHeader
                            rows={questionRowData}
                            data={data?.data}
                            loading={isLoading}
                            handleChangeSorting={handleChangeSorting}
                            sorting={sorting}
                            total={data?.total}
                            handleChangePage={handleChangePage}
                            page={data?.current_page}
                            rowsPerPage={data?.per_page && +data.per_page}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ContentCard>
                </Grid>
                <DeleteModal
                    additionalInfo={translate(
                        'authoring.exerciseList.deleteQuestionInfo',
                    )}
                    isLoading={isDeleting}
                    showModal={!!selectedQuestion && shownModal === 'delete'}
                    closeModal={resetModals}
                    onDelete={handleDeleteQuestion}
                    confirmText={translate(
                        'authoring.exerciseList.deleteQuestion',
                    )}
                    message={translateWithVariable(
                        'authoring.exerciseList.deleteQuestion',
                        {question: selectedQuestion?.name},
                    )}
                />
                <DeleteModal
                    additionalInfo={translate(
                        'authoring.exerciseList.detachQuestionInfo',
                    )}
                    isLoading={isDeleting}
                    showModal={!!selectedQuestion && shownModal === 'detach'}
                    closeModal={resetModals}
                    onDelete={handleDetachQuestion}
                    confirmText={translate(
                        'authoring.exerciseList.detachQuestion',
                    )}
                    message={translateWithVariable(
                        'authoring.exerciseList.detachQuestionConfirm',
                        {question: selectedQuestion?.name},
                    )}
                />
            </>
        </div>
    );
};

export default ExerciseList;
