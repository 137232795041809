import React, { ChangeEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { userBulkFunctions } from '../../../enums/users/users-enums';
import { courseBulkFunctions } from '../../../enums/courses/courses-enums';
import { translate } from '../../../i18n/i18n';
import { groupBulkFunctions } from '../../../enums/groups/groups-enums';
import { memberBulkFunctions } from '../../../enums/members/members-enums';
import { assignmentBulkFunctions } from '../../../enums/assignments/assignments-enums';
import { questionBulkFunction } from '../../../enums/questions/questions-enums';
import { useStyles } from './styles';
import {licenseBulkFunctions} from "../../../enums/licenses/licenses-enums";

interface BulkActionInterface {
	bulkFunctions:
		| typeof userBulkFunctions
		| typeof groupBulkFunctions
		| typeof memberBulkFunctions
		| typeof assignmentBulkFunctions
		| typeof courseBulkFunctions
		| typeof questionBulkFunction
        | typeof licenseBulkFunctions;
	handleBulkEdit: (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => void;
}

export const BulkAction: React.FunctionComponent<BulkActionInterface> = ({
	bulkFunctions,
	handleBulkEdit,
}) => {
	const classes = useStyles();

	const handleSelect = (e: any, dropdown: DropdownProps) => {
		handleBulkEdit({ ...e, target: { value: dropdown.value } });
	};

	const getDropdownOptions = () => {
		return Object.keys(bulkFunctions).map((key) => ({
			key,
			text: translate(key),
			value: key,
		}));
	};

	return (
		<Dropdown
			fluid
			className={classes.Multiselect}
			name="NAME"
			id="bulk-edit"
			onChange={handleSelect}
			value="placeholder"
			options={[
				{
					key: 'ACTION',
					value: 'placeholder',
					text: translate('shared.bulkAction.action'),
				},
				...getDropdownOptions(),
			]}
			selection
		/>
	);
};
