import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PreviewListItem from '../../../components/authoring/Preview/PreviewListItem';
import { translate } from '../../../i18n/i18n';
import AppSearch from '../../../components/app/AppSearch';

interface AppLearnProps {
	subareas: Array<Subarea>;
	handleNavigate: Function;
	contentId: number;
}

interface Subarea {
	id: number;
	subareas: Array<Subarea>;
	headline: string;
	is_exam: boolean;
	is_exercise: boolean;
	sub_area_id: number;
	wysiwyg: string;
}

const AppLearn: React.FunctionComponent<AppLearnProps> = ({
	subareas,
	handleNavigate,
	contentId,
}) => {
	const { subpage } = useParams<{ page: string; subpage?: string }>();
	const [searchValue, setSearchValue] = useState('');
	const [filteredSearchValue, setFilteredSearchValue] = useState('');
	const searchTimeout = useRef<NodeJS.Timeout>();
	const parentId = subpage ? parseInt(subpage) : null;
	let regularSubareas: Subarea[] = [];
	let exerciseAreas: Subarea[] = [];
	let examAreas: Subarea[] = [];

	useEffect(() => {
		if (searchTimeout.current) clearTimeout(searchTimeout.current);
		if (!!searchValue) {
			searchTimeout.current = setTimeout(() => {
				setFilteredSearchValue(searchValue);
			}, 500);
		} else {
			setFilteredSearchValue('');
		}
		return () => {
			if (searchTimeout.current) clearTimeout(searchTimeout.current);
		};
	}, [searchValue]);

	if (!!filteredSearchValue) {
		regularSubareas = subareas.filter(
			(area: Subarea) =>
				!area.is_exercise &&
				(area.headline
					.toLowerCase()
					.replace(' ', '')
					.replace('­', '')
					.includes(filteredSearchValue.toLowerCase()) ||
					area.wysiwyg
						.toLowerCase()
						.replace(' ', '')
						.replace('­', '')
						.includes(filteredSearchValue.toLowerCase())),
		);
		exerciseAreas = subareas.filter((area: Subarea) => {
			if (area.is_exercise) console.log(area, filteredSearchValue);
			return (
				area.is_exercise &&
				!area.is_exam &&
				`${area.headline}`
					.toLowerCase()
					.replace(' ', '')
					.replace('­', '')
					.includes(filteredSearchValue.toLowerCase())
			);
		});
		examAreas = subareas.filter(
			(area: Subarea) =>
				area.is_exercise &&
				area.is_exam &&
				area.headline
					.toLowerCase()
					.replace(' ', '')
					.replace('­', '')
					.includes(filteredSearchValue.toLowerCase()),
		);
	} else {
		regularSubareas = subareas.filter(
			(area: Subarea) =>
				!area.is_exercise && area.sub_area_id === parentId,
		);
		exerciseAreas = subareas.filter(
			(area: Subarea) =>
				area.is_exercise &&
				!area.is_exam &&
				area.sub_area_id === parentId,
		);
		examAreas = subareas.filter(
			(area: Subarea) =>
				area.is_exercise &&
				area.is_exam &&
				area.sub_area_id === parentId,
		);
	}

	console.log({ exerciseAreas });

	const currentSubareaItem = () => {
		return (
			subareas.find((subarea: any) => subarea.id === parentId) ||
			([] as any)
		);
	};

	const handleNavigateDown = (item: any) => {
		setSearchValue('');
		setFilteredSearchValue('');
		if (item && item.subareas && item.subareas.length === 0) {
			const redirect = parentId ? `/app/learn/${parentId}` : '/app/learn';
			handleNavigate(redirect, {
				content: item.id.toString(),
				navigation: '0',
			});
		} else {
			handleNavigate(`/app/learn/${item.id.toString()}`, {
				content: contentId,
				navigation: '1',
			});
		}
	};

	const handleActiveNavigateDown = (item: any) => {
		handleNavigate(`/app/learn/${item.id.toString()}`, {
			content: item.id.toString(),
			navigation: '0',
		});
	};

	return (
		<>
			<div className="preview-window-navigation-content app-navigation-content">
				{!parentId && (
					<AppSearch
						searchValue={searchValue}
						actualSearchValue={filteredSearchValue}
						setSearchValue={setSearchValue}
					/>
				)}
				{parentId && (
					<PreviewListItem
						active={parentId === contentId}
						key={currentSubareaItem().id}
						item={currentSubareaItem()}
						showContentProgress={true}
						parentId={parentId}
						handleClick={() => {
							handleActiveNavigateDown(currentSubareaItem());
						}}
					/>
				)}
				{regularSubareas ? (
					<>
						{regularSubareas.map((item: any) => (
							<PreviewListItem
								active={item.id === contentId}
								key={item.id}
								item={item}
								parentId={!parentId ? -1 : parentId}
								showContentProgress={true}
								handleClick={() => {
									handleNavigateDown(item);
								}}
							/>
						))}
					</>
				) : (
					''
				)}

				{(!!exerciseAreas.length || !!examAreas.length) && (
					<p className="preview-window-navigation-separator">
						{translate('app.appLearn.exercisesAndExams')}
					</p>
				)}

				{!!exerciseAreas.length && (
					<>
						{exerciseAreas.map((exercise) => (
							<PreviewListItem
								active={exercise.id === contentId}
								key={exercise.id}
								item={exercise}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(exercise);
								}}
								showContentProgress={true}
							/>
						))}
					</>
				)}

				{!!examAreas.length && (
					<>
						{examAreas.map((exam) => (
							<PreviewListItem
								active={exam.id === contentId}
								key={exam.id}
								item={exam}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(exam);
								}}
								showContentProgress={true}
							/>
						))}
					</>
				)}
				{!examAreas.length &&
					!exerciseAreas.length &&
					!regularSubareas.length && (
						<div
							style={{
								textAlign: 'center',
								marginTop: 20,
								fontSize: 16,
								color: '#c7c7c7',
							}}
						>
							Keine Ergebnisse.
						</div>
					)}
			</div>
		</>
	);
};

export default AppLearn;
