import { translate } from '../../../i18n/i18n';

export const formatSinglePopulateData = (answers: any) => {
	return answers.map((answer: any) => {
		return {
			label: answer.text,
			is_correct: answer.is_correct,
		};
	});
};

export const formatSingleCorrectAnswer = (answers: any) => {
	return answers.filter((answer: any) => answer.is_correct)[0]?.text || 'Möp';
};

export const formatSingleUpdateData = (
	options: any,
	correctOption: any,
	name: string,
) => {
	const bodyOptions = options.map((option: any) => {
		return {
			text: option.label,
			is_correct: option.label == correctOption,
		};
	});

	return {
		question: name,
		type: 'SINGLE_CHOICE',
		answers: bodyOptions,
	};
};

export const validateSingleUpdateData = (
	name: string,
	asnwers: any,
	correctAnswer: string,
) => {
	let errors = [];

	if (!name) {
		errors.push(translate('helpers.singleQuestions.titleValidation'));
	}
	if (!asnwers.length) {
		errors.push(translate('helpers.singleQuestions.questionValidation'));
	}
	if (!correctAnswer) {
		errors.push(translate('helpers.singleQuestions.answerValidation'));
	}

	return errors;
};
