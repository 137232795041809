import React, {useEffect, useState} from 'react';
import {ChromePicker, Color} from 'react-color';
import {Formik} from 'formik';

import {TextField} from '@material-ui/core';
import {useStyles} from './styles';
import Button from '../../shared/Button';
import ColorField from './components/ColorField';
import {color, SettingsDivider, SettingsH3, SettingsP,} from '../../../utils/styles';
import axios from '../../../app/axios';
import FileThumbnail from './components/FileThumbnail';
import {useFavicon} from '../../../helpers/useFavicon';
import {useDispatch} from 'react-redux';
import {fetchsettings} from '../../../store/settings/settingSlice';
import {translate} from "../../../i18n/i18n";
import useUserContext from "../../../contexts/UserContext";

const colorFieldColors = [
    {title: 'Rot', code: '#EB5757'},
    {title: 'Blau', code: '#1485EE'},
    {title: 'Gelb', code: '#FFDC5F'},
    {title: 'Grün', code: '#27AE60'},
    {title: 'Schwarz', code: '#333333'},
];

interface ImageBody {
    logo: string | null;
    favicon: string | null;
    color?: Color;
}

const VisualSettingsTab = () => {
    const dispatch = useDispatch();
    const {setFavicon} = useFavicon();
    const [choosingColor, setChoosingColor] = useState(false);
    const [institution, setInstitution] = useState<Object | any>(null);
    const [chosenColor, setChosenColor] = useState('');
    const [selectingByField, setSelectingByField] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);
    const {triggerRefetch} = useUserContext();
    const classes = useStyles();

    useEffect(() => {
        fetchInstitutions();
    }, []);
    const fetchInstitutions = async () => {
        try {
            const res: any = await axios.get('/institution');
            const {color, favicon, logo} = res.data.success;
            setInstitution(res.data.success);
            setChosenColor(color || '');
        } catch (err) {
            console.log(err);
        }
    };

    const handleToggleColorEdit = (show: boolean) => {
        setChoosingColor(show);
        if (!show) {
            setChosenColor(institution.color || '');
        } else {
            setSelectingByField(false);
        }
    };

    const handleAbortColorSelect = () => {
        setSelectingByField(false);
        setChosenColor(institution.color || '');
    };

    const handleSaveColor = async () => {
        try {
            await axios.put('/institution', {color: chosenColor});
            triggerRefetch();
        } catch (err) {
            setChosenColor(institution.color || '');
        }
        setChoosingColor(false);
        setSelectingByField(false);
    };

    const handleFileUpload = async (
        e: React.FormEvent<HTMLInputElement>,
        setFieldValue: Function,
        type: 'favicon' | 'logo',
    ) => {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            let formData = new FormData();
            formData.append('file', file);
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/file-upload`,
                    formData,
                    {
                        headers: {
                            accept: 'application/json',
                            'Accept-Language': 'en-US,en;q=0.8',
                        },
                    },
                )
                .then((response: any) => {
                    setFieldValue(type, response.data.default);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    const handleSubmitColor = async (values: ImageBody) => {
        setIsSubmitting(true);

        try {
            await axios.put('/institution', values);
            window.localStorage.setItem(
                'institutionColor',
                values.color ? values.color.toString() : '',
            );
            window.localStorage.setItem(
                'institutionLogo',
                values.logo ? values.logo.toString() : '',
            );
            window.localStorage.setItem(
                'institutionFavicon',
                values.favicon ? values.favicon.toString() : '',
            );
            setFavicon();
            triggerRefetch();
            setHasSaved(true);
        } catch (err) {
            console.log(err);
        }
        dispatch({
            ...fetchsettings({
                requestType: 'GET',
                request: `smartometer/institution`,
            }),
        });
        setIsSubmitting(false);
    };

    return (
        institution && (
            <>
                <Formik
                    initialValues={{
                        logo: institution.logo,
                        favicon: institution.favicon,
                        color: institution.color,
                    }}
                    onSubmit={handleSubmitColor}
                    render={({
                                 values,
                                 handleSubmit,
                                 setFieldValue,
                                 handleChange,
                             }) => {
                        const handleSelectFieldColor = (code: string) =>
                            setFieldValue('color', code);

                        return (
                            <>
                                <div>
                                    <h3 style={SettingsH3}>{translate('tabs.visualSettingsTab.colorScheme')}</h3>
                                    <p style={SettingsP}>
                                        {translate('tabs.visualSettingsTab.changeColorMessage')}
                                    </p>
                                    <div
                                        className={classes.ButtonWrapper}
                                        style={{marginBottom: 30}}
                                    >
                                        <div className={classes.Label}>
                                            {translate('tabs.visualSettingsTab.selectColor')}
                                        </div>
                                        <div
                                            className={
                                                classes.ColorFieldWrapper
                                            }
                                        >
                                            {colorFieldColors.map((field) => (
                                                <ColorField
                                                    key={field.code}
                                                    selectedColor={
                                                        values.color as string
                                                    }
                                                    color={field.code}
                                                    title={field.title}
                                                    handleSelect={() =>
                                                        handleSelectFieldColor(
                                                            field.code,
                                                        )
                                                    }
                                                />
                                            ))}
                                            {selectingByField && (
                                                <>
                                                    <Button
                                                        type="solid"
                                                        onClick={
                                                            handleSaveColor
                                                        }
                                                        color={color.link}
                                                        title={translate('tabs.visualSettingsTab.save')}
                                                    />
                                                    <Button
                                                        type="secondary"
                                                        onClick={
                                                            handleAbortColorSelect
                                                        }
                                                        color={color.textDark}
                                                        title={translate('tabs.visualSettingsTab.cancel')}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.ColorPickerWrapper}>
                                        <div className={classes.ButtonWrapper}>
                                            <div className={classes.Label}>
                                                HEX-Farbcode angeben
                                                {translate('tabs.visualSettingsTab.specifyHEX')}
                                            </div>
                                            <TextField
                                                label={
                                                    values.color ??
                                                    translate('tabs.visualSettingsTab.colorCode')
                                                }
                                                disabled
                                                value={values.color}
                                                variant="outlined"
                                                placeholder="keine"
                                                onClick={() =>
                                                    handleToggleColorEdit(true)
                                                }
                                            />
                                            {choosingColor && (
                                                <Button
                                                    type="secondary"
                                                    onClick={() =>
                                                        handleToggleColorEdit(
                                                            false,
                                                        )
                                                    }
                                                    color={color.link}
                                                    title={translate('tabs.visualSettingsTab.confirm')}
                                                />
                                            )}
                                        </div>
                                        {choosingColor && (
                                            <div
                                                className={classes.ColorPicker}
                                            >
                                                <ChromePicker
                                                    color={values.color}
                                                    onChangeComplete={(
                                                        color,
                                                    ) => {
                                                        handleSelectFieldColor(
                                                            color.hex,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={SettingsDivider}/>
                                <div>
                                    <h3 style={SettingsH3}>{translate('tabs.visualSettingsTab.logo')}</h3>
                                    <p style={SettingsP}>
                                        {translate('tabs.visualSettingsTab.uploadFaviconMessage')}
                                    </p>
                                    <div className="container">
                                        <form onSubmit={handleSubmit}>
                                            <div
                                                className={
                                                    classes.ButtonWrapper
                                                }
                                                style={{marginBottom: 30}}
                                            >
                                                <div className={classes.Label}>
                                                    <label htmlFor="logo">
                                                        {translate('tabs.visualSettingsTab.uploadLogo')}
                                                    </label>
                                                    <span>
													{translate('tabs.visualSettingsTab.recomendedDimentions')}
													</span>
                                                    <span>
                                                        {translate('tabs.visualSettingsTab.recomendedFileformat')}
                                                    </span>
                                                </div>
                                                <FileThumbnail
                                                    type="logo"
                                                    file={values.logo}
                                                />
                                                <div
                                                    className={
                                                        classes.FileInputWrapper
                                                    }
                                                >
                                                    <label
                                                        htmlFor="logo"
                                                        className={
                                                            classes.FileInput
                                                        }
                                                    >
                                                        <input
                                                            name="logo"
                                                            accept=".png,.jpg"
                                                            type="file"
                                                            onChange={(
                                                                e: React.FormEvent<HTMLInputElement>,
                                                            ) =>
                                                                handleFileUpload(
                                                                    e,
                                                                    setFieldValue,
                                                                    'logo',
                                                                )
                                                            }
                                                            className="form-control"
                                                        />
                                                        {translate('tabs.visualSettingsTab.upload')}
                                                    </label>
                                                    {values.logo && (
                                                        <Button
                                                            title={translate('tabs.visualSettingsTab.delete')}
                                                            type="secondary"
                                                            color={color.red}
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'logo',
                                                                    null,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.ButtonWrapper
                                                }
                                                style={{marginBottom: 32}}
                                            >
                                                <div className={classes.Label}>
                                                    <p>{translate('tabs.visualSettingsTab.uploadFavicon')}</p>
                                                    <span>
														{translate('tabs.visualSettingsTab.recomendedFavDimentions')}
													</span>
                                                    <span>
                                                        {translate('tabs.visualSettingsTab.recomendedFavFileformat')}
                                                    </span>
                                                </div>
                                                <FileThumbnail
                                                    type="favicon"
                                                    file={values.favicon}
                                                />
                                                <div
                                                    className={
                                                        classes.FileInputWrapper
                                                    }
                                                >
                                                    <label
                                                        htmlFor="favicon"
                                                        className={
                                                            classes.FileInput
                                                        }
                                                    >
                                                        <input
                                                            name="favicon"
                                                            accept=".ico,.png,.jpg"
                                                            type="file"
                                                            onChange={(
                                                                e: React.FormEvent<HTMLInputElement>,
                                                            ) =>
                                                                handleFileUpload(
                                                                    e,
                                                                    setFieldValue,
                                                                    'favicon',
                                                                )
                                                            }
                                                            className="form-control"
                                                        />
                                                        Hochladen
                                                    </label>
                                                    {values.favicon && (
                                                        <Button
                                                            title={translate('tabs.visualSettingsTab.delete')}
                                                            type="secondary"
                                                            color={color.red}
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    'favicon',
                                                                    null,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.FileInputWrapper
                                                }
                                            >
                                                <Button
                                                    isLoading={isSubmitting}
                                                    title={translate('tabs.visualSettingsTab.save')}
                                                    type="solid"
                                                    color={color.link}
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        );
                    }}
                />
            </>
        )
    );
};

export default VisualSettingsTab;
