import axios from "../../../app/axios";

class MyUploadAdapter {
    private loader: any;

    constructor(loader: any) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file: any) => new Promise((resolve, reject) => {

                const data = new FormData();
                data.append("file", file)
                return axios.post(`${process.env.REACT_APP_API_URL}/api/file-upload`, data).then(response => {
                    this.loader.uploaded = true;
                    resolve({
                        default: response.data.default
                    });

                });
            }));
    }
}

export default function MyCustomUploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
}
