import React, {useState} from 'react'
import {useStyles} from "./styles";

interface Banner {

}

const Banner: React.FunctionComponent<Banner> = ({children}) => {
   const classes = useStyles();

    return (
        <div className={classes.banner}>
            {children}
        </div>
    )
}

export default Banner
