import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes} from "../../../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    ToggleSet: {
        padding: 32,
        color: "pink",
        "& tr:hover":{
            background: "#D0E9FF",
        },
        "& td, th":{
            fontSize: "14px",
        },
        "& th": {
            color: "#9FA2B4",
        },

        "& th:first-child,td:first-child": {
            paddingLeft: "32px",
        },
        "& th:last-child,td:last-child": {
            paddingRight: "32px",
        }
    },
    Row: {
        position: "relative",
        marginBottom: 40
    },
    Actions: {
        position: "absolute",
        top: 0,
        right: 0
    },
    Legend: {
        marginBottom: 20,
        color: color.textDark,
        fontSize: textSizes.bodyLarge
    },
    PermissionGroup: {
        marginLeft: 68
    },
    Label: {
        color: color.textDark,
        fontSize: textSizes.bodySmall
    }
}))
