import * as yup from 'yup';
import YupPassword from 'yup-password';
import { translate } from '../../../i18n/i18n';

YupPassword(yup);
export const validationSchema = yup.object().shape({
	firstname: yup
		.string()
		.required(translate('authentication.register.errors.firstname')),
	lastname: yup
		.string()
		.required(translate('authentication.register.errors.lastname')),
	acceptTerms: yup
		.bool()
		.oneOf([true], translate('authentication.register.fieldRequired')),
	email: yup
		.string()
		.required(translate('authentication.register.errors.email'))
		.email(translate('authentication.register.errors.email')),
});
