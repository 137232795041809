import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

interface CKEditorInterface {
    htmlContent: string | null,
    handleBlur: ( event: any, editor: any ) => void
}

export const FillTheGapsEditor: React.FunctionComponent<CKEditorInterface> = ({htmlContent, handleBlur}) => {
    return (
        <div className="fill-the-gaps">{htmlContent && (<CKEditor
            editor={ ClassicEditor }
            data={htmlContent}
            config={{
                toolbar: ['word'],

            }
            }
            onReady={ (editor: any) => {
                // You can store the "editor" and use when it is needed.
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event: any, editor: any ) => {
                const data = editor.getData();
                console.log( { event, editor, data } );
            } }
            onBlur={ (event: any, editor: any) => {
                handleBlur(event, editor);
            }}
            onFocus={ ( event: any, editor: any ) => {
                console.log( 'Focus.', editor );
            } }
        />)}

        </div>
    );
}

export default FillTheGapsEditor;
