import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles(theme => ({
	ListWrapper: {
		marginTop: 25,
	},
	label: {
        position: "relative"
    },
	selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
}));
