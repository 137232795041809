import Chart from "react-apexcharts";
import React from "react";
import {color} from "../../../../utils/styles";
import {useStyles} from "./styles";

interface LineChartProps {
    values: any[],
    header?: any,
    small?: boolean
}

export const LineChart: React.FunctionComponent<LineChartProps> = ({values, header, small}) => {
    const classes = useStyles();

    return <div className={header ? classes.wrapper : classes.small}>
        {header && <header/>}
        <div>
            {!!values[0].data.length && <Chart options={{
                chart: {
                    type: 'area',
                    height: small? 250 : 350,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                labels: ['test'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1,
                    curve: 'smooth'
                },
                yaxis: {
                    opposite: true,
                    axisTicks: {
                        show: false,
                        color: color.lightGray
                    },
                    axisBorder: {
                        show: false,
                        color: color.lightGray
                    },
                    labels: {
                        style: {
                            colors: color.lightGray
                        }
                    },

                },
                xaxis: {
                    type: 'datetime',
                    axisTicks: {
                        show: false,
                        color: color.lightGray
                    },
                    axisBorder: {
                        show: false,
                        color: color.lightGray
                    },
                    labels: {
                        datetimeUTC: false,
                        style: {
                            colors: color.lightGray
                        }
                    },
                    tickAmount: 7
                },
                legend: {
                    horizontalAlign: 'left'
                }
            }} series={values} type="area" height={small? 250 : 350}/>}
        </div>
    </div>

}
