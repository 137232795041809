import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

interface PreviewContentHeaderProps {
	handleToggleMenu: React.MouseEventHandler;
	subareaName: string;
	parentName: string;
}

const PreviewContentHeader: React.FunctionComponent<PreviewContentHeaderProps> =
	({ handleToggleMenu, subareaName, parentName }) => {
		return (
			<div className={`preview-window-content-header hide-desktop`}>
				<MenuIcon onClick={handleToggleMenu} />
				<div className="preview-window-content-header-headlines">
					<h4>{parentName}</h4>
					<h3>{subareaName}</h3>
				</div>
			</div>
		);
	};

export default PreviewContentHeader;
