import React, {useEffect} from 'react';
import {useStyles} from './styles';
import {color} from '../../../utils/styles';
import Button from '../../shared/Button';
import {Dropdown, DropdownProps} from 'semantic-ui-react'
import {translate} from "../../../i18n/i18n";
import {Delete, FileCopy, Visibility, VisibilityOff} from '@material-ui/icons';
import {ContentTypes} from "../../../enums/courses/courses-enums";
import DynamicIcon from "../../../components/shared/DynamicIcon";
import Tooltip from '@material-ui/core/Tooltip';

const AuthoringSubnavigation: React.FunctionComponent<{
    id: number;
    handleDeleteSubarea: React.MouseEventHandler;
    handleAddChildarea: React.MouseEventHandler;
    isPublished: boolean;
    isExercise: boolean;
    isExam: boolean;
    handleChange: Function;
    handleDuplicateArea: React.MouseEventHandler;
}> = ({
          id,
          handleDeleteSubarea,
          handleAddChildarea,
          isPublished,
          isExercise,
          isExam,
          handleChange,
          handleDuplicateArea,
      }) => {
    const classes = useStyles();

    const togglePublish = () => handleChange(id, 'is_published', !isPublished);

    const contentTypeOptions = [
        {
            key: 'content',
            value: ContentTypes.CONTENT,
            content: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5} type="contentIcon"/> {translate('layout.authoringSubnavigation.learningSection')} </span>,
            text: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5} type="contentIcon"/> {translate('layout.authoringSubnavigation.learningSection')}</span>
        },
        {
            key: 'not_exercise',
            value: ContentTypes.EXERCISE,
            content: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5} type="exerciseIcon"/> {translate('layout.authoringSubnavigation.exercise')} </span>,
            text: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5}
                                                                             type="exerciseIcon"/> {translate('layout.authoringSubnavigation.exercise')}</span>
        },
        {
            key: 'exercise_exam',
            value: ContentTypes.EXAM,
            content: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5}
                                                                                type="awardIcon"/> {translate('layout.authoringSubnavigation.exam')} </span>,
            text: <span className={classes.dropdownIconWrapper}><DynamicIcon margin={5} type="awardIcon"/> {translate('layout.authoringSubnavigation.exam')}</span>
        }
    ]


    const getExerciseType = (isExercise: boolean, isExam: boolean) => {
        return isExercise ? (isExam ? ContentTypes.EXAM : ContentTypes.EXERCISE) : ContentTypes.CONTENT
    }

    const [exerciseStatus, setExerciseStatus] = React.useState<ContentTypes>(getExerciseType(isExercise, isExam));

    useEffect(() => {
        setExerciseStatus(getExerciseType(isExercise, isExam))
    }, [isExercise, isExam])

    const handleDropdownChange = (e: any, dropdown: DropdownProps) => {
        if (!dropdown.value) {
            return false;
        }

        setExerciseStatus(dropdown.value as ContentTypes);

        switch (dropdown.value) {
            case ContentTypes.EXERCISE:
                handleChange(id, 'is_exercise', true);
                handleChange(id, 'is_exam', false);
                break;
            case ContentTypes.CONTENT:
                handleChange(id, 'is_exercise', false);
                handleChange(id, 'is_exam', false);
                break;
            case ContentTypes.EXAM:
                handleChange(id, 'is_exercise', true);
                handleChange(id, 'is_exam', true);
                break;
        }

    }

    return (
        <div className={classes.NavBar}>
            <Dropdown
                placeholder={translate('layout.authoringSubnavigation.selectPlaceholder')}
                className="authoring_navigation_select"
                fluid
                value={exerciseStatus}
                selection
                options={contentTypeOptions}
                onChange={handleDropdownChange}
            />
            <div className={classes.ButtonWrapper}>
                <Button
                    type="outline"
                    color={color.white}
                    onClick={handleAddChildarea}
                    icon="subdirectory"
                    title={translate(`authoring.subNavigation.createSubSection`)}
                    iconColor={color.white}
                />
                {!isPublished ? (
                    <Tooltip title={translate(`authoring.subNavigation.hide`)}>
                        <Visibility
                            className={classes.IconButton}
                            onClick={togglePublish}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title={translate(`authoring.subNavigation.show`)}>
                        <VisibilityOff
                            className={classes.IconButton}
                            onClick={togglePublish}
                        />
                    </Tooltip>
                )}
                <Tooltip title={translate(`authoring.subNavigation.duplicate`)}>
                    <FileCopy
                        className={classes.IconButton}
                        onClick={handleDuplicateArea}
                    />
                </Tooltip>
                <Tooltip title={translate(`authoring.subNavigation.delete`)}>
                    <Delete
                        className={classes.IconButton}
                        onClick={handleDeleteSubarea}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default AuthoringSubnavigation;
