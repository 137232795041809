import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from './styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '../../../../components/shared/Button';
import {color} from '../../../../utils/styles';

import {Close, Delete} from "@material-ui/icons";
import {translate} from '../../../../i18n/i18n';

import pairIcon from '../../../../assets/images/pairIcon.svg';

interface SingleChoiceProps {
    onSave: Function,
    onAbort: Function,
    onEdit: Function,
    assignTerms?: any
}

const AssignTerm: React.FunctionComponent<SingleChoiceProps> = ({
                                                                    onSave, onAbort, onEdit, assignTerms
                                                                }) => {
    const classes = useStyles();

    useEffect(() => {
        if (assignTerms) {
            setPairs(assignTerms)
            setIsEditRequest(true)
        }
    }, [assignTerms]);

    const firstWordElement = useRef<HTMLInputElement | null>(null);
    const secondWordElement = useRef<HTMLInputElement | null>(null);

    const [isEditRequest, setIsEditRequest] = useState(false);

    const [pairs, setPairs] = useState<any>([]);
    const [newPair, setNewPair] = useState('');

    const [hoveredAnswerId, setHoveredAnswerId] = useState(-1);
    const initialEditState = {termIndex: -1, wordIndex: -1, isWord: -1};
    const [editPairId, setEditPairId] = useState<{
        termIndex: number,
        wordIndex: number,
        isWord: number
    }>(initialEditState);

    const [selectedAnswerText, setSelectedAnswerText] = useState('');

    const [editError, setEditError] = useState(false)
    const [createError, setCreateError] = useState(false)


    useEffect(() => {
        if (editPairId !== initialEditState) {
            const elem = editPairId.isWord ? document.getElementsByName(`word-${editPairId.termIndex}-${editPairId.wordIndex}-${editPairId.isWord}`) :
                document.getElementsByName(`term-${editPairId.termIndex}-${editPairId.wordIndex}-${editPairId.isWord}`);
            if (!!elem.length) {

                elem[0].focus();
            }
        }
    }, [editPairId])

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPair(event.target.value)
    };

    const handleEditAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // const localAnswer =
        const [type, termIndex, wordIndex, isWord] = event.target.name.split('-');

        switch (type) {
            case 'term':
                const newAssignPairs = pairs.map((pair: any, index: number) => {
                    let newpair = {...pair};
                    if (+termIndex === index) {
                        newpair.term = event.target.value;
                    }
                    return newpair;
                });
                setPairs(newAssignPairs);
                break;
            case 'word':
                const newAssignPairsWords = pairs.map((pair: any, itTermTndex: number) => {
                    let {words} = pair;
                    if (+termIndex !== itTermTndex) {
                        return pair;
                    }

                    words = words.map((word: any, iWordTndex: number) => {
                        if (+wordIndex === iWordTndex) {
                            word.term = event.target.value;
                        }
                        return word;
                    });
                    return {
                        ...pair,
                        words
                    };
                });

                setPairs(newAssignPairsWords);
                break;
        }
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setEditPairId(initialEditState);
            setEditError(false)
        }
    }

    const handleEnterAddNewWord = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const newTerms = pairs.map((term: any, cTermIndex: number) => {
                let {words} = term;
                if (editPairId.termIndex === cTermIndex) {
                    words.push({
                        term: ""
                    });
                }
                return {
                    ...term,
                    words
                };
            })
            setPairs(newTerms);
            setEditPairId({
                ...editPairId,
                wordIndex: editPairId.wordIndex + 1
            });

            setEditError(false);

        }
        var element = e.target as HTMLInputElement;
        if (e.key === 'Backspace' && element.value === "") {
            const newTerms = pairs.map((term: any, cTermIndex: number) => {
                let {words} = term;
                const newWords = words.splice(editPairId.wordIndex, 1);
                return {
                    ...term,
                    newWords
                }
            });
            setPairs(newTerms);
            setEditPairId({
                ...editPairId,
                wordIndex: editPairId.wordIndex - 1
            });

        }
    }


    const handleSelectEditedAnswer = (termIndex: number, wordIndex: number, isWord: number) => {
        setEditPairId({termIndex, wordIndex, isWord})
        const answerText = isWord > 0 ? pairs[termIndex].words[wordIndex].term : pairs[termIndex].term;
        setSelectedAnswerText(answerText)

    }


    const handleEditAnswerOnBlur = (e: any) => {
        const [type, termIndex, wordIndex, isWord] = e.target.name.split('-');
        const newPairs = pairs.map((pair: any) => {
            return {
                ...pair,
                words: pair.words.filter((word: any) => word.term)
            }
        })
        setPairs(newPairs);
        setEditPairId(initialEditState);
        setEditError(false)
    };

    const deleteWord = (cTermIndex: number, cWordIndex: number) => {
        const newPairs = pairs.map((pair: any, termIndex: number) => {
            let newWords = pair.words.filter((word: any, wordIndex: number) => !(cTermIndex === termIndex && cWordIndex === wordIndex));

            newWords = !!newWords.length ? newWords : [{
                term: ""
            }]
            return {
                ...pair,
                words: newWords
            }
        });
        setPairs(newPairs);
    }

    const deleteAnswer = (index: number) => {
        var newAnswers = pairs
        newAnswers.splice(index, 1);
        setPairs(newAnswers)
    }

    const addNewOption = (e: any) => {
        if (e.key === 'Enter') {
            setPairs([
                ...pairs,
                {
                    "term": firstWordElement.current?.value,
                    "words": [{
                        term: secondWordElement.current?.value
                    }, {
                        term: ""
                    }
                    ]
                }
            ]);
            if (firstWordElement.current?.value) {
                firstWordElement.current.value = '';
            }
            if (secondWordElement.current?.value) {
                secondWordElement.current.value = '';
            }
            setTimeout(() => {
                setEditPairId({
                    termIndex: pairs.length,
                    wordIndex: 1,
                    isWord: 1
                });
            }, 100)


        }
    }

    const handleSaveQuestion = () => {
        onSave(pairs)
    }

    const handleEditQuestion = () => {
        onEdit(pairs)
    }


    return (
        <div>
            <FormControl component="fieldset">
                <FormGroup>
                    {pairs.map(function (pair: any, termIndex: number) {
                        return (
                            <div style={{
                                display: 'flex',
                                flexFlow: "column",
                                alignItems: 'center',
                                position: 'relative'
                            }}
                                 onMouseOver={() => setHoveredAnswerId(termIndex)}
                                 onMouseOut={() => setHoveredAnswerId(-1)}>

                                {pair.words && pair.words.map((word: any, index: number) => {
                                    return <div className={classes.wordWrapper}>
                                        {index === 0 && editPairId.termIndex === termIndex && editPairId.wordIndex === index && editPairId.isWord === 0 &&
                                        <TextField
                                            error={editError}
                                            value={pair.term}
                                            className={classes.firstWord}
                                            name={`term-${termIndex}-${index}-0`}
                                            onChange={handleEditAnswerChange}
                                            onKeyPress={handleEnter}
                                            helperText={editError && translate('authoring.assignTerm.answerExists')}
                                        />}
                                        {index === 0 && !(editPairId.termIndex === termIndex && editPairId.wordIndex === index && editPairId.isWord === 0) &&
                                        <div style={{fontSize: 16}}
                                             onClick={() => handleSelectEditedAnswer(termIndex, index, 0)}
                                             className={classes.firstWord}> {pair.term} </div>}
                                        {index !== 0 && !(editPairId.termIndex === termIndex && editPairId.wordIndex === index && editPairId.isWord === 0) &&
                                        <div style={{fontSize: 16}} className={classes.firstWord}></div>}

                                        <img src={pairIcon} className={classes.pairIcon}/>
                                        {!(editPairId.termIndex === termIndex && editPairId.wordIndex === index && editPairId.isWord === 1) &&
                                        <div style={{fontSize: 16}} className={classes.secondWord}
                                             onClick={() => handleSelectEditedAnswer(termIndex, index, 1)}> {word.term} </div>}
                                        {editPairId.termIndex === termIndex && editPairId.wordIndex === index && editPairId.isWord === 1 &&
                                        <TextField error={editError}
                                                   value={word.term}
                                                   className={classes.secondWord}
                                                   name={`word-${termIndex}-${index}-1`}
                                                   onChange={handleEditAnswerChange}
                                                   onKeyDown={handleEnterAddNewWord}
                                                   onBlur={handleEditAnswerOnBlur}
                                                   helperText={editError && translate('authoring.assignTerm.answerExists')}
                                        />}
                                        <div className={classes.deleteWrapper}
                                             onClick={() => deleteWord(termIndex, index)}>
                                            <Close style={{width: 18, margin: 10}}/>
                                        </div>
                                    </div>
                                })}

                                <div className={classes.deleteAnswerWrapper} onClick={() => deleteAnswer(termIndex)}>
                                    <Delete style={{width: 18, margin: 10}}/>
                                </div>

                            </div>

                        );
                    })}

                </FormGroup>
                <div style={{display: 'flex', alignItems: 'center'}} className={classes.wordWrapper}>
                    <input className={classes.TitleInput} name="term" type="text" ref={firstWordElement}
                           placeholder={translate('authoring.assignTerm.firstWord')}/>
                    <img src={pairIcon} className={classes.pairIcon}/>
                    <input className={classes.TitleInput} name="word" type="text" ref={secondWordElement}
                           onKeyPress={addNewOption} placeholder={translate('authoring.assignTerm.secondWord')}/>
                </div>
                {createError && <span className={classes.errorLabel}>{translate('authoring.assignTerm.answerExists')}</span>}
            </FormControl>
            <br/>
            <div style={{marginTop: 50}}>

                {!isEditRequest && <Button
                    title={translate('authoring.assignTerm.saveQuestion')}    
                    type="solid"
                    color={color.link}
                    onClick={() => handleSaveQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                {isEditRequest && <Button
                    title={translate('authoring.assignTerm.editQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => handleEditQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                <Button
                    title={translate('authoring.assignTerm.abort')}
                    type="outline"
                    color={color.link}
                    onClick={() =>
                        onAbort()
                    }
                />
            </div>

        </div>
    );
};

export default AssignTerm;
