import React, {useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import deLocale from 'date-fns/locale/de';
import {format} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {useStyles} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import MultiSelect from '../MultiSelect';
import {translate} from '../../../i18n/i18n';

interface TableActionProps {
    setFilterValues: (e: any, name: string) => void;
    options: any;
    selectedStartDate: Date | null;
    selectedEndDate: Date | null;
    handleSetDate: Function;
    timeFilter: string | null;
    setTimeFilter: Function;
}


const DashboardFilter: React.FunctionComponent<TableActionProps> = ({
                                                                        setFilterValues,
                                                                        options,
                                                                        selectedStartDate,
                                                                        selectedEndDate,
                                                                        handleSetDate,
                                                                        timeFilter,
                                                                        setTimeFilter,
                                                                    }) => {
    const classes = useStyles();
    const [showFilterContent, setShowFilterContent] = useState(false);

    const intervalMapper: {
        [key: string]: number;
    } = {
        day: 1,
        week: 6,
        month: 29,
        quarter: 89,
        year: 364,
    };

    const formatDate = () => {
        if (timeFilter === 'all') {
            return 'Gesamtzeitraum';
        } else if (!!timeFilter) {
            const today = format(new Date(), 'yyyy-MM-dd');
            if (timeFilter === 'day') return today;
            const secondaryDate = new Date();
            secondaryDate.setDate(
                secondaryDate.getDate() - intervalMapper[timeFilter],
            );
            return format(secondaryDate, 'yyyy-MM-dd') + ' bis ' + today;
        } else if (!!selectedStartDate && !!selectedEndDate) {
            return (
                format(selectedStartDate, 'yyyy-MM-dd') +
                ' bis ' +
                format(selectedEndDate, 'yyyy-MM-dd')
            );
        } else if (!!selectedStartDate && !selectedEndDate) {
            return 'ab ' + format(selectedStartDate, 'yyyy-MM-dd');
        } else if (!!selectedEndDate && !selectedStartDate) {
            return 'bis ' + format(selectedEndDate, 'yyyy-MM-dd');
        }
    };

    const handleSetDateFilter = (interval: string) => {
        setTimeFilter(interval);
        let start = null;
        let end = null;
        if (interval !== 'all') {
            end = new Date();
            start = new Date();
            start.setDate(start.getDate() - intervalMapper[interval]);
        }
        handleSetDate(start, 'start');
        handleSetDate(end, 'end');
    };

    const handleSetDatePicker = (date: Date | null, type: string) => {
        setTimeFilter(null);
        handleSetDate(date, type);
    };

    return (
        <div className={classes.wrapper}>
            {options.map((option: any, index: number) => {
                return (
                    <div style={{marginRight: 20}} key={index}>
                        <MultiSelect
                            handleChange={(newValues: number[]) =>
                                setFilterValues(newValues, option.name)
                            }
                            inputTitle={option.placeholder}
                            modalTitle={option.filterTitle}
                            selected={option.value}
                            options={option.options}
                        />
                    </div>
                );
            })}
            <div
                className={classes.FilterHeader}
                onClick={() => {
                    setShowFilterContent(!showFilterContent);
                }}
            >
                <strong>{translate(`shared.dashboardFilter.period`)}: </strong>&nbsp;{formatDate()}
                <FontAwesomeIcon icon={faChevronDown}/>
            </div>
            <div
                className={`${classes.FilterContent} ${
                    showFilterContent ? classes.FilterContentOpen : ''
                }`}
            >
                <div className={classes.FilterTabWrapper}>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'day' ? classes.FilterTabActive : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('day');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.last24Hours')}
                    </div>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'week' ? classes.FilterTabActive : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('week');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.last7Days')}
                    </div>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'month'
                                ? classes.FilterTabActive
                                : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('month');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.last30Days')}
                    </div>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'quarter'
                                ? classes.FilterTabActive
                                : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('quarter');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.last90Days')}
                    </div>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'year' ? classes.FilterTabActive : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('year');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.last365Days')}
                    </div>
                    <div
                        className={`${classes.FilterTab} ${
                            timeFilter === 'all' ? classes.FilterTabActive : ''
                        }`}
                        onClick={() => {
                            handleSetDateFilter('all');
                            setShowFilterContent(false);
                        }}
                    >
                        {translate('shared.dashboardFilter.timeframe')}
                    </div>
                </div>
                <div className={classes.DatePickerWrapper}>
                    <p>{translate('shared.dashboardFilter.period')}</p>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={deLocale}
                    >
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            placeholder={translate(
                                'shared.dashboardFilter.startDate',
                            )}
                            format="dd.MM.yyyy"
                            value={selectedStartDate}
                            onChange={(date: Date | null) => {
                                if (date && !isNaN(date.getTime())) {
                                    handleSetDatePicker(date, 'start')
                                }
                            }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.datepicker}
                        />
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            placeholder={translate(
                                'shared.dashboardFilter.endDate',
                            )}
                            format="dd.MM.yyyy"
                            value={selectedEndDate}
                            onChange={(date: Date | null) => {
                                if (date && !isNaN(date.getTime())) {
                                    handleSetDatePicker(date, 'end')
                                }
                                if(selectedStartDate && !isNaN(selectedStartDate.getTime()) && date && !isNaN(date.getTime()) ) {
                                    setShowFilterContent(false);
                                }
                            }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.datepicker}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        </div>
    );
};

export default DashboardFilter;
