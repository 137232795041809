import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    Input: {
        width: "100%",
        position: "relative",
        paddingBottom: "20px",

        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "0"
        }

    }
}))