import React, { useState, useCallback, useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import {
	ArrowForwardIos,
	ArrowBackIos,
	Check,
	LastPage,
} from '@material-ui/icons';
import QuitExerciseModal from '../QuitExerciseModal';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import { RenderSingleChoice } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderSingleChoice';
import { RenderMultipleChoice } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderMultipleChoice';
import { RenderOrderWords } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderOrderWords';
import { RenderWordCombinations } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderWordCombinations';
import { RenderAssignTerms } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderAssignTerms';
import { RenderGapFills } from '../../../../authoring/Preview/PreviewQuestionTypes/RenderGapFills';
import ExamCounter from '../ExamCounter';
import { translate } from '../../../../../i18n/i18n';

interface ExerciseWizardProps {
	questions: any[];
	subarea: any;
	setResults: Function;
	previewedQuestion: number | null;
	defaultAnswers?: any;
	backToSummary: Function;
	settings: {
		is_exam?: 1 | 0;
		exam_time?: number;
		exam_percentage_for_pass?: number;
		exercise_percentage_for_pass?: number;
	};
	handleTimeOver: Function;
}

const ExerciseWizard: React.FunctionComponent<ExerciseWizardProps> = ({
	questions,
	subarea,
	setResults,
	previewedQuestion,
	defaultAnswers,
	backToSummary,
	settings,
}) => {
	const prefillAnswers = () => {
		let prefilledAnswers: any = {};
		questions.map((item: any, i: number) => {
			prefilledAnswers[i] = { id: item.id };
		});
		return prefilledAnswers;
	};

	const [showExitModal, setShowExitModal] = useState(false);
	const [showFinishModal, setShowFinishModal] = useState(false);
	const [showTimeoutModal, setShowTimeoutModal] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(
		previewedQuestion || 0,
	);
	const [answers, setAnswers]: any = useState(
		defaultAnswers || prefillAnswers(),
	);

	const isExam = subarea.is_exam;

	const handleSetAnswer = (type: any, data: any) => {
		const currentAnswers = { ...answers[currentQuestion] };
		switch (type) {
			case QuestionTypes.SINGLE_CHOICE:
				currentAnswers.value = data.i;
				break;
			case QuestionTypes.MULTIPLE_CHOICE:
				const values = currentAnswers.values
					? [...currentAnswers.values]
					: [];
				if (!!data.checked) {
					values.push(data.i);
				} else {
					values.splice(values.indexOf(data.i), 1);
				}
				currentAnswers.values = values;
				break;
			case QuestionTypes.ORDER_WORDS:
				currentAnswers.order = data.order;
				break;
			case QuestionTypes.WORD_COMBINATION:
				currentAnswers.order = data.order;
				break;
			case QuestionTypes.ASSIGN_TERM:
				currentAnswers.order = data.order;
				break;
			case QuestionTypes.GAP_FILLS:
				currentAnswers.order = data.order;
				break;
			default:
				break;
		}
		setAnswers({ ...answers, [currentQuestion]: currentAnswers });
	};

	const renderQuestion = () => {
		const index = currentQuestion;
		const question = questions[index];

		switch (question.type) {
			case QuestionTypes.SINGLE_CHOICE:
				return (
					<RenderSingleChoice
						question={question}
						index={index}
						selectedIndex={answers[currentQuestion]?.value}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
						onChange={handleSetAnswer}
					/>
				);
			case QuestionTypes.MULTIPLE_CHOICE:
				return (
					<RenderMultipleChoice
						question={question}
						index={index}
						onChange={handleSetAnswer}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
						selectedIndizes={answers[currentQuestion]?.values}
					/>
				);
			case QuestionTypes.ORDER_WORDS:
				return (
					<RenderOrderWords
						question={question}
						index={index}
						device={'mobile'}
						onChange={handleSetAnswer}
						userChoice={answers[currentQuestion]?.order}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
						correctOrder={
							questions[currentQuestion].answers_by_order
						}
					/>
				);
			case QuestionTypes.WORD_COMBINATION:
				return (
					<RenderWordCombinations
						question={question}
						index={index}
						device={'mobile'}
						onChange={handleSetAnswer}
						userChoice={answers[currentQuestion]?.order}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
						correctOrder={questions[
							currentQuestion
						].word_combinations.map((item: any) => item.secondWord)}
						options={question.options}
					/>
				);
			case QuestionTypes.ASSIGN_TERM:
				return (
					<RenderAssignTerms
						question={question}
						index={index}
						device={'mobile'}
						onChange={handleSetAnswer}
						userChoice={answers[currentQuestion]?.order}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
						correctOrder={questions[currentQuestion].terms}
						predefinedOptions={question.predefinedOptions}
					/>
				);
			case QuestionTypes.GAP_FILLS:
				return (
					<RenderGapFills
						question={question}
						index={index}
						device={'mobile'}
						onChange={handleSetAnswer}
						userChoice={answers[currentQuestion]?.order}
						isValidated={
							(!isExam || previewedQuestion !== null) &&
							!!answers[currentQuestion]?.hasOwnProperty(
								'validation',
							)
						}
                        isExam={isExam}
                        settings={settings}
					/>
				);
			default:
				return <></>;
		}
	};

	useEffect(() => {
		if (showTimeoutModal) verifyQuestion();
	}, [showTimeoutModal]);

	const verifyQuestion = (finishCourse?: boolean) => {
		const verifiedQuestion = questions[currentQuestion];
		let validation = false;

		switch (verifiedQuestion.type) {
			case QuestionTypes.SINGLE_CHOICE:
				const itemAnswers = answers[currentQuestion]?.value;
				if (itemAnswers >= 0) {
					if (
						verifiedQuestion.answers[itemAnswers] &&
						!!verifiedQuestion.answers[itemAnswers]?.is_correct
					)
						validation = true;
				}
				break;
			case QuestionTypes.MULTIPLE_CHOICE:
				const correctAnswers: number[] = [];
				verifiedQuestion.answers.map((item: any, i: number) => {
					if (item.is_correct) correctAnswers.push(i);
				});
				if (
					(!answers[currentQuestion] ||
						!answers[currentQuestion].values ||
						!answers[currentQuestion]?.values.length) &&
					correctAnswers.length > 0
				) {
					break;
				} else {
					const answersSorted = answers[currentQuestion].values
						.slice()
						.sort();
					validation =
						correctAnswers.length === answersSorted.length &&
						correctAnswers.every(function (value, index) {
							return value === answersSorted[index];
						});
				}
				break;
			case QuestionTypes.ORDER_WORDS:
				const userOrder = answers[currentQuestion]?.order || null;
				if (!userOrder) break;
				const orderedIndizes: number[] = [];
				const orderedQuestions =
					questions[currentQuestion].answers_by_order;
				orderedQuestions.map((item: any, i: any) => {
					const unorderedItemIndex = verifiedQuestion.answers.indexOf(
						verifiedQuestion.answers.find(
							(i: any) => i.id === item.id,
						),
					);
					orderedIndizes.push(unorderedItemIndex);
				});
				validation =
					orderedIndizes.length === userOrder.length &&
					orderedIndizes.every(function (value: any, index: any) {
						return value === userOrder[index];
					});

				break;
			case QuestionTypes.WORD_COMBINATION:
				const userCombinations =
					answers[currentQuestion]?.order || null;
				if (!userCombinations) break;
				const orderedCombinations = questions[
					currentQuestion
				].word_combinations.map((item: any) => item.secondWord);
				let isCorrect = true;
				for (let i = 0; i < orderedCombinations.length; i++) {
					if (orderedCombinations[i] !== userCombinations[i]) {
						isCorrect = false;
						break;
					}
				}
				validation = isCorrect;
				break;
			case QuestionTypes.ASSIGN_TERM:
				const userAssignments = answers[currentQuestion]?.order || null;
				if (!userAssignments) break;
				const correctTerms = questions[currentQuestion].terms;
				let termsCorrect = true;
				for (let i = 0; i < correctTerms.length; i++) {
					if (!userAssignments[i]) {
						termsCorrect = false;
						break;
					}
					const correctTermsArray = correctTerms[i].words
						.map((item: any) => item.term)
						.sort();
					const correspondingUserArray = [...userAssignments[i]];
					correspondingUserArray.sort();
					const isCorrect =
						correctTermsArray.length ===
							correspondingUserArray.length &&
						correctTermsArray.every(function (
							value: any,
							index: number,
						) {
							return value === correctTermsArray[index];
						});
					if (!isCorrect) {
						termsCorrect = false;
						break;
					}
				}
				validation = termsCorrect;
				break;
			case QuestionTypes.GAP_FILLS:
				const userGaps = answers[currentQuestion]?.order || null;
				if (!!userGaps) {
					const { correctOrder } = verifiedQuestion;
					let gapsCorrect = true;
					for (let i = 0; i < correctOrder.length; i++) {
						if (correctOrder[i] !== userGaps[i]) {
							gapsCorrect = false;
							break;
						}
					}
					validation = gapsCorrect;
				}
				break;
			default:
				break;
		}

		setAnswers({
			...answers,
			[currentQuestion]: {
				...answers[currentQuestion],
				validation,
			},
		});

		if (finishCourse) {
			const validatedAnswers = {
				...answers,
				[currentQuestion]: {
					...answers[currentQuestion],
					validation,
				},
			};
			Object.keys(validatedAnswers).forEach((key: any) => {
				if (validatedAnswers[key].validation === undefined) {
					validatedAnswers[key].validation = false;
				}
			});

			setResults(validatedAnswers);
		}
	};

	const handleGoNext = () => {
		if (isExam) {
			verifyQuestion();
		}
		setCurrentQuestion(currentQuestion + 1);
	};

	const handleGoPrev = () => {
		if (isExam) {
			verifyQuestion();
		}
		setCurrentQuestion(currentQuestion - 1);
	};

	const handleFinish = () => {
		if (previewedQuestion !== null) {
			backToSummary();
		} else {
			if (isExam) {
				verifyQuestion();
			}
			setShowFinishModal(true);
		}
	};

	const handleAbort = () => {
		verifyQuestion(true);
	};

	const onTimerEnds = useCallback(() => {
		setShowTimeoutModal(true);
	}, []);

	const answerValidated =
		!!answers[currentQuestion] &&
		!!answers[currentQuestion]?.hasOwnProperty('validation');
	const answerValidatedTrue =
		answerValidated && !!answers[currentQuestion].validation;

	return questions.length ? (
		<div className="app-exercise-content">
			<div className="app-exercise-header preview-window-content-header">
				<div className="preview-window-content-header-headlines">
					<h3>{subarea.headline}</h3>
				</div>
				<div className="exam-counter">
					{currentQuestion + 1} / {questions.length}
				</div>
				<Close
					onClick={
						!!defaultAnswers
							? () => backToSummary()
							: () => setShowExitModal(true)
					}
				/>
			</div>
			<div className="app-exercise-question-wrapper">
				<div className="app-content-general question-content">
					{renderQuestion()}
				</div>
				<div className="button-wrapper">
					{currentQuestion > 0 ? (
						<button
							className="nav-button prev"
							onClick={handleGoPrev}
						>
							<ArrowBackIos />
						</button>
					) : (
						<button className="nav-button prev disabled">
							<ArrowBackIos />
						</button>
					)}
					{answerValidated &&
					(!isExam || previewedQuestion !== null) ? (
						answerValidatedTrue ? (
							<div className="verify-text correct">
								{translate('app.exerciseWizard.correctAnswer')}
							</div>
						) : (
							<div className="verify-text wrong">
								{translate('app.exerciseWizard.wrongAnswer')}
							</div>
						)
					) : null}
					{!!isExam && !!settings.exam_time! && (
						<ExamCounter
							totalTime={settings.exam_time! * 60}
							handleTimeOver={onTimerEnds}
						/>
					)}
					{(answerValidated || isExam) &&
					currentQuestion < questions.length - 1 ? (
						<button className="nav-button" onClick={handleGoNext}>
							<ArrowForwardIos />
						</button>
					) : null}
					{(answerValidated || isExam) &&
					currentQuestion === questions.length - 1 ? (
						<button className="nav-button" onClick={handleFinish}>
							<LastPage />
						</button>
					) : null}
					{!answerValidated && !isExam ? (
						<button
							className="nav-button verify"
							onClick={() => verifyQuestion()}
						>
							<Check />
						</button>
					) : null}
				</div>
			</div>

			{showExitModal && (
				<QuitExerciseModal
					type={
						isExam
							? translate('app.exerciseWizard.exam')
							: translate('app.exerciseWizard.exercise')
					}
					handleAbort={() => setShowExitModal(false)}
					handleConfirm={handleAbort}
				/>
			)}
			{showFinishModal && (
				<QuitExerciseModal
					finish
					type={
						isExam
							? translate('app.exerciseWizard.exam')
							: translate('app.exerciseWizard.exercise')
					}
					handleAbort={() => setShowFinishModal(false)}
					handleConfirm={() => setResults(answers)}
				/>
			)}
			{showTimeoutModal && (
				<QuitExerciseModal
					timeout
					type={
						isExam
							? translate('app.exerciseWizard.exam')
							: translate('app.exerciseWizard.exercise')
					}
					handleAbort={() => setShowFinishModal(false)}
					handleConfirm={() => setResults(answers)}
				/>
			)}
			<div id="control-wrapper"></div>
		</div>
	) : null;
};

export default ExerciseWizard;
