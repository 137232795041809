import React, {useState, useEffect, useRef} from 'react';
import {useStyles} from './styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {ContactSupportOutlined} from '@material-ui/icons';
import Button from '../../../../components/shared/Button';
import {color} from '../../../../utils/styles';

import {Edit, Delete} from "@material-ui/icons";

import pairIcon from '../../../../assets/images/pairIcon.svg';
import FillTheGapsEditor from "../../../shared/FillTheGaps";
import {translate} from "../../../../i18n/i18n";

interface SingleChoiceProps {
    onSave: Function,
    onAbort: Function,
    onEdit: Function,
    gapFills?: any
}

const GapFill: React.FunctionComponent<SingleChoiceProps> = ({onSave, onAbort, onEdit, gapFills}) => {
    const classes = useStyles();

    useEffect(() => {
        if (gapFills) {
            setCurrentGapFills(gapFills)
            setIsEditRequest(true)
        }
    }, [gapFills]);

    const firstWordElement = useRef<HTMLInputElement | null>(null);
    const secondWordElement = useRef<HTMLInputElement | null>(null);

    const [isEditRequest, setIsEditRequest] = useState(false);

    const [currentGapFills, setCurrentGapFills] = useState<any>([]);
    const [newPair, setNewPair] = useState('');

    const [hoveredAnswerId, setHoveredAnswerId] = useState(-1);

    const [editPairId, setEditPairId] = useState(-1);
    const [selectedAnswerText, setSelectedAnswerText] = useState('');

    const [editError, setEditError] = useState(false)
    const [createError, setCreateError] = useState(false)


    const addNewOption = (e: any) => {
        if (e.key === 'Enter') {
            setCurrentGapFills([
                ...currentGapFills,
                {
                    "firstWord": firstWordElement.current?.value,
                    "secondWord": secondWordElement.current?.value
                }
            ]);
            if(firstWordElement.current?.value) {
                firstWordElement.current.value = '';
                firstWordElement.current.focus();
            }
            if(secondWordElement.current?.value) {
                secondWordElement.current.value = '';
            }

        }
    }

    const handleSaveQuestion = () => {
        onSave(currentGapFills)
    }

    const handleEditQuestion = () => {
        onEdit(currentGapFills)
    }

    const handleBlur = (event: any, editor:any) => {
        const newGap = !!currentGapFills.length ? currentGapFills.map((gap:any) => {
            return {...gap,
            text: editor.getData()
            }
        }) : [{text: editor.getData()
        }];
        setCurrentGapFills(newGap);

    }

    return (
        <div>
            <FormControl component="fieldset">
                <FormGroup>
                    {isEditRequest && !!currentGapFills.length && <FillTheGapsEditor htmlContent={`<span>${currentGapFills[0].text}</span>`} handleBlur={handleBlur} /> }
                    {!isEditRequest && <FillTheGapsEditor htmlContent={`<span></span>`} handleBlur={handleBlur} /> }
                </FormGroup>

            </FormControl>
            <br/>
            <div style={{marginTop: 50}}>

                {!isEditRequest && <Button
                    title={translate('authoring.saveQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => handleSaveQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                {isEditRequest && <Button
                    title={translate('authoring.saveQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => handleEditQuestion()}
                    styles={{marginRight: 12}}
                    iconColor={color.white}
                />}
                <Button
                    title={translate('authoring.abort')}
                    type="outline"
                    color={color.link}
                    onClick={() =>
                        onAbort()
                    }
                />
            </div>

        </div>
    );
};

export default GapFill;
