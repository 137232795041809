import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import Modal from '../../shared/Modal';
import { color } from '../../../utils/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import Avatar from '../../shared/Avatar';
import { APPUCATION_LOGO_AS_BASE64 } from '../../../enums/assignments/assignments-enums';
import DateFnsUtils from '@date-io/date-fns';
import { parse } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
	assignmentSelector,
	fetchAssignment,
} from '../../../store/assignments/assignmentSlice';
import useUserContext from '../../../contexts/UserContext';
import { translate } from '../../../i18n/i18n';

interface AssignModalProps {
	showModal: boolean;
	closeModal: () => void;
	onSubmit?: (id: number, start: Date | null, end: Date | null) => void;
	message: string;
	confirmText: string;
	isLoading: boolean;
	additionalInfo?: string;
	assignment?: number;
}

export const EditAssignmentModal: React.FunctionComponent<AssignModalProps> = ({
	showModal,
	closeModal,
	onSubmit,
	message,
	confirmText,
	isLoading,
	additionalInfo,
	assignment,
}) => {
	const dispatch = useDispatch();
	const { user } = useUserContext();

	const classes = useStyles();
	const {
		single: assignmentData,
		isLoading: assignmentIsLoading,
		assignmentError,
	}: any = useSelector(assignmentSelector);
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
		null,
	);

	useEffect(() => {
		if (assignmentData?.start) {
			setSelectedStartDate(
				parse(assignmentData.start, 'yyyy-MM-dd', new Date()),
			);
			setSelectedEndDate(
				parse(assignmentData.end, 'yyyy-MM-dd', new Date()),
			);
		}
	}, [assignmentData]);

	useEffect(() => {
		if (assignment) {
			dispatch({
				...fetchAssignment({
					requestType: 'GET',
					request: `smartometer/assignments/${assignment}`,
				}),
			});
		}
	}, [assignment]);

	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const handleSubmit = () => {
		onSubmit &&
			assignment &&
			onSubmit(assignment, selectedStartDate, selectedEndDate);
	};

	return assignmentData ? (
		<Modal
			showModal={showModal}
			header={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
			onClose={closeModal}
			onSubmit={handleSubmit}
			isLoading={isLoading}
			submitActionTitle={confirmText}
			closeActionTitle={translate('modals.editAssignmentModal.close')}
			submitActionColor={color.link}
			disabled={isLoading}
		>
			<Grid>
				<Grid.Row>
					<Grid.Column width={16}>
						<Avatar
							src={
								assignmentData.user_avatar || '/defaultUser.png'
							}
							className={classes.Avatar}
							style={{ display: 'inline-block' }}
						/>
						<span className={classes.AvatarName}>
							{assignmentData.user_first_name}{' '}
							{assignmentData.user_last_name}
						</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Avatar
							src={
								assignmentData.product_institution_id === null
									? APPUCATION_LOGO_AS_BASE64
									: user?.institution?.logo
							}
							className={classes.Avatar}
							style={{ display: 'inline-block' }}
						/>
						<span className={classes.AvatarName}>
							{assignmentData.product_name}
						</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className={classes.row}>
					<Grid.Column width={8}>
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={deLocale}
						>
							<KeyboardDatePicker
								maxDate={selectedEndDate}
								margin="normal"
								id="date-picker-start"
								placeholder={translate(
									'modals.assignUserModal.start',
								)}
								format="dd.MM.yyyy"
								value={selectedStartDate}
								onChange={handleStartDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true, disabled: true }}
								autoOk={true}
								className={classes.datepicker}
							/>
						</MuiPickersUtilsProvider>
					</Grid.Column>
					<Grid.Column width={8}>
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={deLocale}
						>
							<KeyboardDatePicker
								minDate={selectedStartDate}
								margin="normal"
								id="date-picker-end"
								placeholder={translate(
									'modals.assignUserModal.end',
								)}
								format="dd.MM.yyyy"
								value={selectedEndDate}
								onChange={handleEndDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true, disabled: true }}
								autoOk={true}
								className={classes.datepicker}
							/>
						</MuiPickersUtilsProvider>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal>
	) : (
		<></>
	);
};
