import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import {Delete} from '@material-ui/icons';
import Button from '../../../../components/shared/Button';
import {color} from '../../../../utils/styles';
import {translate} from '../../../../i18n/i18n';

interface SingleChoiceProps {
    onSave: Function,
    onAbort: Function,
    onEdit: Function,
    multipleCorrectAnswers?: any,
    multipleAnswers?: any
}

const MultipleChoice:  React.FunctionComponent<SingleChoiceProps> = ({onSave, onAbort,onEdit, multipleCorrectAnswers, multipleAnswers}) => {
	const classes = useStyles();

    useEffect(() => {
        if (multipleCorrectAnswers && multipleAnswers) {
            setAnswers(multipleAnswers)
            setCorrectAnswers(multipleCorrectAnswers)
            setIsEditRequest(true)
        }
	}, [multipleCorrectAnswers, multipleAnswers]);

    const [isEditRequest, setIsEditRequest] = useState(false);

    const [answers, setAnswers] = useState<any>([]);
    const [newAnswer, setNewAnswer] = useState('');
    const [correctAnswers, setCorrectAnswers] = useState<any>([])

    const [hoveredAnswerId, setHoveredAnswerId] = useState(-1);

    const [editAnswerId, setEditAnswerId] = useState(-1);
    const [selectedAnswerText, setSelectedAnswerText] = useState('');

    const [editError, setEditError] = useState(false)
    const [createError, setCreateError] = useState(false)


    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAnswer(event.target.value)
    };

    const handleEditAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAnswerText(event.target.value)
    }

    const handleSelectEditedAnswer = (i:number) => {
        setEditAnswerId(i)
        const answerText = answers[i].text
        setSelectedAnswerText(answerText)
        setEditError(false)
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const newAnswers = answers
        newAnswers[i].is_correct = event.target.checked
        setAnswers(newAnswers)
    };

    const handleEditAnswerOnBlur = (e: any, index: number) => {
        const checkForExistingAnswers = answers.filter( (answer: any, key: any ) => answer.text === selectedAnswerText && key !== index)
        if (checkForExistingAnswers.length) {
            setEditError(true)
        } else {
            const localAnswers = answers
            localAnswers[index].text = selectedAnswerText
            setAnswers(localAnswers)
            setEditAnswerId(-1);
            setEditError(false)
        }
	};

    const deleteAnswer = (index:number) => {
        var newAnswers = answers
        newAnswers.splice(index, 1);
        setAnswers(newAnswers)
    }

    const addNewOption = (e: any) => {
        if (e.key === 'Enter') {
            const checkForExistingAnswers = answers.filter( (answer: any ) => answer.text === newAnswer)
            if (checkForExistingAnswers.length) {
                setCreateError(true)
            }
            if (newAnswer && checkForExistingAnswers.length === 0) {
                var newRecord = {
                    text: newAnswer,
                    is_correct: false
                }

                var joined = answers.concat(newRecord);
                setAnswers(joined)
                setNewAnswer('')
                setCreateError(false)
            }
        }
    }

    const handleSaveQuestion = () => {
        onSave(answers, correctAnswers)
    }

    const handleEditQuestion = () => {
        onEdit(answers, correctAnswers)
    }


	return (
    <div>
        <FormControl component="fieldset">
            <FormGroup>
            {answers.map(function(answer : any, i : any){
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} onMouseOver={() => setHoveredAnswerId(i)} onMouseOut={() => setHoveredAnswerId(-1)}>
                        <FormControlLabel
                            control={<Checkbox style={{ margin: 0 }} color="primary" checked={answers[i].is_correct} onChange={(event) => handleCheckboxChange(event, i)} name={answer.text} />}
                            label=''
                        />
                        { editAnswerId !== i && <span onClick={() => handleSelectEditedAnswer(i)} style={{ fontSize: 16 }}> {answer.text} </span> }
                        { editAnswerId === i &&
                            <TextField
                            error={editError}
                            autoFocus={true}
                            id="standard-basic"
                            value={selectedAnswerText}
                            onChange={handleEditAnswerChange}
                            onBlur={(event) => handleEditAnswerOnBlur(event, i)}
                            helperText={editError && translate('authoring.multipleChoice.answerExists') }
                            />
                        }
                        <div className={classes.deleteWrapper} onClick={() => deleteAnswer(i)}>
                            <Delete style={{ width: 18, margin: 10}} />
                        </div>
                    </div>

                );
            })}

            </FormGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox disabled style={{ margin: 0 }} />
                <input className={classes.TitleInput} name="new-question" type="text" value={newAnswer} onChange={handleAnswerChange} onKeyPress={addNewOption} placeholder={translate('authoring.multipleChoice.addAnswer')} />
            </div>
            {createError && <span className={classes.errorLabel}>Answer Already exist</span>}
        </FormControl>
        <br/>
        <div style={{ marginTop: 50 }}>

        { !isEditRequest && <Button
                title={translate('authoring.multipleChoice.saveQuestion')}
                type="solid"
                color={color.link}
                onClick={() => handleSaveQuestion()}
                styles={{ marginRight: 12 }}
                iconColor={color.white}
            /> }
         { isEditRequest && <Button
                title={translate('authoring.multipleChoice.editQuestion')}
                type="solid"
                color={color.link}
                onClick={() => handleEditQuestion()}
                styles={{ marginRight: 12 }}
                iconColor={color.white}
            /> }
            <Button
                title={translate('authoring.multipleChoice.abort')}
                type="outline"
                color={color.link}
                onClick={() =>
                    onAbort()
                }
            />
        </div>

    </div>
    );
};

export default MultipleChoice;
