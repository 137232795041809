import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { SvgIcon } from '@material-ui/core';
import axios from '../../../../../app/axios';
import {
    appSelector,
    setShowFinishCourse,
    updateCourseSubareas,
} from '../../../../../store/app/appSlice';
import { translate } from '../../../../../i18n/i18n';

interface ResultsProps {
	subarea: any;
	answers: any;
	questions: number;
	settings: {
		exercise_percentage_for_pass?: number;
		exam_percentage_for_pass?: number;
	};
	handleNavigate: Function;
	handleShowDetails: Function;
	handleGoToNext: Function;
	handleClose: Function;
	nextSubarea: any;
	resultsSent: boolean;
	setResultsSent: Function;
}

const Results: React.FunctionComponent<ResultsProps> = ({
	subarea,
	settings,
	answers,
	questions,
	handleShowDetails,
	handleGoToNext,
	nextSubarea,
	handleClose,
	resultsSent,
	setResultsSent,
}) => {
	const dispatch = useDispatch();

    const {
        currentCourseId,
        currentCourseSubareas
    } = useSelector(appSelector);

	useEffect(() => {
		if (!resultsSent) {
			submitResults();
			setResultsSent(true);
		}
	}, []);

	const updateSuccessInLocalStorage = () => {
		let storageSubareas = [...currentCourseSubareas];
		const foundAreaIndex = storageSubareas.indexOf(storageSubareas.find(
			(area: { id: number; is_finished: boolean }) =>
			area.id === subarea.id,
		));
		if(foundAreaIndex > -1){
			let foundArea = {...storageSubareas[foundAreaIndex], is_finished: true}
			storageSubareas.splice(foundAreaIndex,1,foundArea);
		}
		dispatch(updateCourseSubareas(storageSubareas));
	};

	const submitResults = async () => {
	    if(!currentCourseId) return;
		const data: {
            assignment_id: number;
			sub_area_id: number;
			is_success: boolean;
			questions: {
				question_id: number;
				is_success: boolean;
				points: number;
			}[];
		} = {
            assignment_id: currentCourseId,
			sub_area_id: subarea.id,
			is_success: hasSucceeded,
			questions: [],
		};

		Object.keys(answers).forEach((index: any) => {
			const { id, validation } = answers[index];
			data.questions.push({
				question_id: id,
				is_success: validation ? !!validation : false,
				points: validation && !!validation ? 1 : 0,
			});
		});

		if (hasSucceeded) updateSuccessInLocalStorage();

		try {
			const res = await axios.post('/results', data);
			if (res.data.success && hasSucceeded) {
				const subareasPending = JSON.parse(
					localStorage.getItem('subareas_pending') || '',
				);

				if (subareasPending.indexOf(subarea.id) > -1) {
					subareasPending.splice(
						subareasPending.indexOf(subarea.id),
						1,
					);

					localStorage.setItem(
						'subareas_pending',
						JSON.stringify(subareasPending),
					);
					if (!subareasPending.length)
						setTimeout(() => {
							dispatch(setShowFinishCourse(true));
						}, 500);
				}
			}
		} catch (err) {
			if (!err.response) {
				/** ADD DATA SENT ABOVE TO QUEUE */
				/* do queue stuff here */
				let results_queue = !!window.localStorage.getItem(
					'results_queue',
				)
					? JSON.parse(window.localStorage.getItem('results_queue')!)
					: [];

				results_queue.push(data);

				localStorage.setItem(
					'results_queue',
					JSON.stringify(results_queue),
				);
				console.log('Offline: Saved result to queue');
				/** THEN: IS COURSE FINISHED OR NOT LOGIC */
				if (hasSucceeded) {
					const subareasPending = JSON.parse(
						localStorage.getItem('subareas_pending') || '',
					);
					const subareaIndex = subareasPending.indexOf(subarea.id);
					if (subareaIndex > -1) {
						subareasPending.splice(subareaIndex, 1);
						localStorage.setItem(
							'subareas_pending',
							JSON.stringify(subareasPending),
						);
						if (!subareasPending.length) {
							setTimeout(() => {
								dispatch(setShowFinishCourse(true));
							}, 500);
						}
					}
				}
			}
		}
	};

	const successfulAnswers = Object.keys(answers).filter(
		(key: any) => !!answers[key].validation,
	).length;
	const minAnswers = Math.floor(
		(questions *
			(subarea.is_exam
				? settings.exam_percentage_for_pass!
				: settings.exercise_percentage_for_pass!)) /
			100,
	);
	const hasSucceeded = successfulAnswers >= minAnswers;

	return (
		<div
			className={`app-exercise-loader app-exercise-wrapper ${
				hasSucceeded ? 'succeeded' : 'failed'
			}`}
		>
			<div className="app-exercise-loader-content">
				{hasSucceeded ? (
					<SvgIcon className="background-icon">
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
				) : (
					<SvgIcon className="background-icon">
						<path
							d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m2.59 6L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8z"
							fill="currentColor"
						/>
					</SvgIcon>
				)}
				{hasSucceeded ? (
					<SvgIcon className="header-icon">
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
				) : (
					<SvgIcon className="header-icon">
						<path
							d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m2.59 6L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8z"
							fill="currentColor"
						/>
					</SvgIcon>
				)}
				<h3>
					{subarea.is_exam
						? translate('app.results.exam')
						: translate('app.results.exercise')}{' '}
					{hasSucceeded
						? translate('app.results.passed')
						: translate('app.results.notPassed')}
				</h3>
				{hasSucceeded ? (
					<p>
						{translate('app.results.congratsFinished')}{' '}
						{subarea.is_exam
							? translate('app.results.exam')
							: translate('app.results.exercise')}{' '}
						<span>{subarea.headline}</span>{' '}
						{translate('app.results.succsessfully')}
					</p>
				) : (
					<p>
						{translate('app.results.toPass')}{' '}
						{subarea.is_exam
							? translate('app.results.exam')
							: translate('app.results.exercise')}{' '}
						{translate('app.results.youNeed')}{' '}
						<span>
							{minAnswers} {translate('app.results.points')}{' '}
						</span>
						{translate('app.results.passEnd')}
					</p>
				)}
				<h5>{translate('app.results.pointsLabel')}:</h5>
				<p className="app-exercise-total-points">
					<span>{successfulAnswers}</span> / {questions}
				</p>
			</div>

			<button
				onClick={() => handleShowDetails()}
				className="app-exercise-loader-button primary"
				disabled={!questions}
			>
				{translate('app.results.showResult')}
			</button>
			{!!nextSubarea ? (
				<a
					onClick={() => handleGoToNext()}
					className="app-exercise-loader-button secondary"
				>
					{translate('app.results.continue')}
				</a>
			) : (
				<a
					onClick={() => handleClose()}
					className="app-exercise-loader-button secondary"
				>
					{translate('app.splash.close')}
				</a>
			)}
		</div>
	);
};

export default Results;
