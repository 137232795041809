import React, {useEffect} from "react";
import TabNavigator from "../../shared/TabNavigator";
import MemberOverviewTab from "./MemberOverviewTab";
import {useDispatch, useSelector} from "react-redux";

import {fetchMembers, memberSelector} from "../../../store/members/memberSlice";
import {RolesOverviewTab} from "./RolesOverviewTab";
import {fetchRoles, roleSelector} from "../../../store/roles/roleSlice";
import {translate} from "../../../i18n/i18n";


const TeamTab: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const {data: userData, isMemberLoading, error} = useSelector(memberSelector);

    const {data: rolesData, rolesIsLoading, rolesHasError, reload} = useSelector(roleSelector);

    useEffect(() => {
        if(reload) {
            dispatch({
                ...fetchRoles({
                    requestType: "GET",
                    request: 'smartometer/roles',
                }),
            });
        }
    }, [reload])

    useEffect(() => {
        dispatch({
            ...fetchMembers({
                requestType: "GET",
                request: 'smartometer/members',
            }),
        });
    }, [])

    const teamTabs = {
        members: {
            title: translate('settings.team.members.headline'),
            component: <MemberOverviewTab userData={userData} isMemberLoading={isMemberLoading} />
        },
        roles: {
            title: translate('settings.team.roles.headline'),
            component: <RolesOverviewTab  rolesData={rolesData}/>
        }
    }


    return (
        <div>
            <TabNavigator tabs={teamTabs} defaultActive="members" />
        </div>
    );
}

export default TeamTab;
