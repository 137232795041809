import React, { ChangeEvent, useState } from 'react';
import { deleteTypes, userBulkFunctions } from '../enums/users/users-enums';

export const useBulkFunction = (data: any) => {
	const [selectedAll, setSelectedAll] = useState<boolean>(false);

	const [selected, setSelected] = useState<number[]>([]);

	enum SelectType {
		ASSIGNMENT_PIVOT = 'ASSIGNMENT_PIVOT',
	}

	const handleSelectAll = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
		type?: SelectType,
	) => {
		if (event.target.checked) {
			setSelectedAll(true);
			const cData = data.data ? data.data : data;
			switch (type) {
				case SelectType.ASSIGNMENT_PIVOT:
					setSelected(cData.map((item: any) => item.pivot.id));
					break;
				default:
					setSelected(cData.map((item: any) => item.id));
			}
		} else {
			setSelected([]);
			setSelectedAll(false);
		}
	};

	const handleSelect = (event: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;
	const hasSelected = selected.length > 0;
	return {
		handleSelectAll,
		handleSelect,
		isSelected,
		selected,
		selectedAll,
		setSelectedAll,
		setSelected,
		hasSelected,
		SelectType,
	};
};
