import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import AddGroupModal from '../../modals/AddGroupModal';
import TableAction from '../../shared/TableAction';
import EditGroupModal from '../../modals/EditGroupModal';
import DeleteModal from '../../modals/DeleteModal';
import {
	deleteGroup,
	deleteGroups,
	fetchGroups,
	groupSelector,
	updateGroup,
} from '../../../store/groups/groupSlice';
import { Checkbox } from '@material-ui/core';
import { BulkAction } from '../../shared/BulkAction';
import { courseSelector } from '../../../store/courses/courseSlice';
import { getCoursesForDropdown } from '../../../helpers/Courses';
import { userBulkFunctions } from '../../../enums/users/users-enums';
import {
	initialSorting,
	SortingInterface,
} from '../../../interfaces/sorting-interface';
import { useStyles } from './styles';
import { useTableSearch } from '../../../helpers/useTableSearch/useTableSearch';
import { usePagination } from '../../../helpers/usePagination';
import { useBulkFunction } from '../../../helpers/useBulkFunction';
import {
	groupBulkFunctions,
	groupFunctions,
} from '../../../enums/groups/groups-enums';
import TableButton from '../../shared/TableButton';
import { AssignUserModal } from '../../modals/AssignUserModal';
import { addAssignment } from '../../../store/assignments/assignmentSlice';
import { format } from 'date-fns';
import Button from '../../shared/Button';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';
import MultiSelect from '../../shared/MultiSelect';
import TableSearch from '../../shared/TableSearch';
import { userSelector } from '../../../store/users/userSlice';

interface GroupOverviewTabProps {
	groupData: any; // fix when time
	groupIsLoading: boolean;
}

const GroupOverviewTab: React.FunctionComponent<GroupOverviewTabProps> = ({
	groupData,
	groupIsLoading,
}) => {
	const [showAddGroupModal, setShowAddGroupModal] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState<{
		id: number;
		name: string;
	} | null>(null);
	const [shownModal, setShownModal] = useState<string | null>(null);
	const {
		handleSelectAll,
		handleSelect,
		isSelected,
		selected,
		selectedAll,
		setSelected,
	} = useBulkFunction(groupData);

	const [isDeleting, setIsDeleting] = useState(false);

	const [sorting, setSorting] = useState<SortingInterface>(initialSorting);

	const {
		handleChangePage,
		handleChangeRowsPerPage,
		page,
		rowsPerPage,
		handleResetPage,
	} = usePagination();
	const [courses, setCourses] = useState<number[]>([]);
	const { isLoading } = useSelector(groupSelector);
	const { data: courseData }: any = useSelector(courseSelector);
	const allCourses = getCoursesForDropdown(courseData);

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { pathname } = useLocation();
	const searchProps = useTableSearch();
	const { search } = searchProps;

	useEffect(() => {
		handleResetPage();
	}, [sorting, search, rowsPerPage, courses]);

	useEffect(() => {
		if (!isDeleting && !isLoading) {
			dispatch({
				...fetchGroups({
					requestType: 'GET',
					request: `smartometer/groups?page=${page}&
                    ${
						courses.length > 0 &&
						courses
							.map((course) => `courses[]=${course}`)
							?.join('&')
					}&
                    limit=${rowsPerPage}&
                    sort=${sorting.column}&
                    type=${sorting.desc ? `desc` : `asc`}${
						search !== '' ? `&search=${search}` : ''
					}`,
				}),
			});
			resetModals();
		}
	}, [
		isDeleting,
		sorting,
		search,
		page,
		rowsPerPage,
		courses,
		dispatch,
		isLoading,
	]);

	const handleEditGroup = (id: number, name: string) => {
		setShownModal('edit');
		setSelectedGroup({ id, name });
	};

	const handleDeleteGroup = () => {
		dispatch({
			...deleteGroup({
				requestType: 'DELETE',
				request: `smartometer/groups/${selectedGroup!.id}`,
			}),
		});
		resetModals();
	};

	const handleBulkEdit = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		switch (event.target.value) {
			case groupBulkFunctions.ASSIGN_COURSE:
				setShownModal(groupBulkFunctions.ASSIGN_COURSE);
				break;
			case userBulkFunctions.DELETE:
				setShownModal(groupBulkFunctions.DELETE);
				break;
			default:
				break;
		}
	};

	const handleChangeSorting = (column: string) => {
		if (column === sorting.column && sorting.desc)
			setSorting(initialSorting);
		else if (column === sorting.column && !sorting.desc)
			setSorting({ ...sorting, desc: true });
		else if (column !== sorting.column)
			setSorting({ ...sorting, column: column });
	};

	const setGroupToDelete = (id: number, name: string) => {
		setShownModal('delete');
		setSelectedGroup({ id, name });
	};

	const resetModals = () => {
		setShownModal(null);
		setSelectedGroup(null);
	};

	const handleAssignGroup = (id: number, name: string) => {
		setSelectedGroup({ id, name });
		setShownModal(groupFunctions.SINGLE_ASSIGN_COURSE);
	};

	// const handleCourseSelectChange = (e: any, dropdown: DropdownProps) => {
	// 	if (Array.isArray(dropdown.value)) {
	// 		const newCourses: number[] = dropdown.value as number[];
	// 		setCourses(newCourses);
	// 	}
	// };

	// const handleSetGroups = (values: any, name: string) => {
	// 	let localFilterValues = { ...filterValues, [name]: values };
	// 	setFilterValues(localFilterValues);
	// };

	const handleBulkDeleteGroups = () => {
		setIsDeleting(true);
		if (!!selected.length) {
			dispatch({
				...deleteGroups({
					requestType: 'DELETE',
					body: {
						groups: selected,
					},
					request: `smartometer/groups`,
				}),
			});
			setIsDeleting(false);
			setSelected([]);
			resetModals();
		}
	};

	const groupRowData = {
		select: {
			renderTitle: (): JSX.Element => (
				<label className={classes.label}>
					<Checkbox
						checked={selectedAll}
						color="primary"
						onChange={handleSelectAll}
					/>
					<span className={classes.selectAll}>
						{translate('tabs.groupOverviewTab.selectAll')}
					</span>
				</label>
			),
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Checkbox
					checked={isSelected(item.id)}
					color="primary"
					onClick={(event) => handleSelect(event, item.id)}
				/>
			),
		},
		name: {
			title: translate('tabs.groupOverviewTab.name'),
			sorting: 'name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push(`${pathname}/detail/${item.id}`)
					}
				>
					{item.name}
				</p>
			),
		},
		count: {
			title: translate('tabs.groupOverviewTab.members'),
			sorting: 'users_count',
			align: 'left',
			renderType: (item: any): JSX.Element => <p>{item.users_count}</p>,
		},
		assignment: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableButton
					title={translate('tabs.groupOverviewTab.assign')}
					handleClick={() => handleAssignGroup(item.id, item.name)}
				/>
			),
		},
		edit: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => handleEditGroup(item.id, item.name)}
					tooltip={translate('tooltip.edit')}
					type="edit"
				/>
			),
		},
		delete: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => setGroupToDelete(item.id, item.name)}
					tooltip={translate('tooltip.delete')}
					type="delete"
				/>
			),
		},
	};

	const handleSubmitBulkAssignGroupToCourse = (
		groups: number[],
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					groups,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments_groups/`,
			}),
		});
		resetModals();
	};

	const headerComponent = (
		<div className={classes.HeaderComponent}>
			{!selected.length ? (
				<>
					<TableSearch searchProps={searchProps} />
					<div style={{ marginLeft: 15 }}>
						<MultiSelect
							handleChange={(newValues: number[]) =>
								setCourses(newValues)
							}
							inputTitle={translate(
								'tabs.groupOverviewTab.course',
							)}
							modalTitle={translate(
								'shared.dashboardFilter.filterByCourse',
							)}
							selected={courses}
							options={allCourses}
							bordered
						/>
					</div>
				</>
			) : (
				<BulkAction
					handleBulkEdit={handleBulkEdit}
					bulkFunctions={groupBulkFunctions}
				/>
			)}
		</div>
	);

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column width={16}>
						<Button
							type="solid"
							styles={{ float: 'right' }}
							onClick={() => setShowAddGroupModal(true)}
							color={color.link}
							title={translate(`users.groups.createNewButton`)}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<ContentCard
							title={translate('tabs.groupOverviewTab.groups')}
							headerComponent={headerComponent}
						>
							<Table
								showHeader
								loading={groupIsLoading}
								rows={groupRowData}
								data={groupData.data}
								handleChangeSorting={handleChangeSorting}
								sorting={sorting}
								total={groupData.total}
								handleChangePage={handleChangePage}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangeRowsPerPage={
									handleChangeRowsPerPage
								}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<AddGroupModal
				showModal={showAddGroupModal}
				closeModal={() => setShowAddGroupModal(false)}
			/>
			<EditGroupModal
				showModal={!!selectedGroup && shownModal === 'edit'}
				closeModal={resetModals}
				selectedGroup={selectedGroup!}
			/>
			<DeleteModal
				additionalInfo={translate(
					'tabs.groupOverviewTab.DeleteGroupMessage',
				)}
				isLoading={isDeleting}
				showModal={!!selectedGroup && shownModal === 'delete'}
				closeModal={resetModals}
				onDelete={handleDeleteGroup}
				confirmText={translate('tabs.groupOverviewTab.deleteGroup')}
				message={`${translate(
					'tabs.groupOverviewTab.deleteGroupConfirm',
				)} <b>'${selectedGroup?.name}'</b> ${translate(
					'tabs.groupOverviewTab.deleteGroupConfirmEnd',
				)}`}
			/>
			<DeleteModal
				isLoading={isDeleting}
				showModal={
					!!selected.length && shownModal === userBulkFunctions.DELETE
				}
				closeModal={resetModals}
				onDelete={handleBulkDeleteGroups}
				confirmText={translate(
					'tabs.groupOverviewTab.deleteSelectedGroup',
				)}
				message={translate(
					'tabs.groupOverviewTab.deleteSelectedGroupConfirm',
				)}
			/>
			<AssignUserModal
				isLoading={isDeleting}
				users={selected}
				showModal={shownModal === groupBulkFunctions.ASSIGN_COURSE}
				closeModal={resetModals}
				onBulkSubmit={handleSubmitBulkAssignGroupToCourse}
				confirmText={translate('tabs.groupOverviewTab.addCourse')}
				message={translate('tabs.groupOverviewTab.addUserToCourse')}
			/>
			<AssignUserModal
				isLoading={isDeleting}
				users={selectedGroup ? [selectedGroup.id] : []}
				showModal={shownModal === groupFunctions.SINGLE_ASSIGN_COURSE}
				closeModal={resetModals}
				onBulkSubmit={handleSubmitBulkAssignGroupToCourse}
				confirmText={translate(
					'tabs.groupOverviewTab.addSelectedCourse',
				)}
				message={translate(
					'tabs.groupOverviewTab.addSelectedUserToCourse',
				)}
			/>
		</>
	);
};

export default GroupOverviewTab;
