import { makeStyles } from '@material-ui/core/styles'
import {color} from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    banner: {
        background: '#FF6737',
        width: '100vw',
        height: '24px',
        position: 'absolute',
        top:0,
        textAlign: 'center',
        color: color.white
    }
}))
