import React, { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import {
    appSelector, setCurrentCourseSubareas,
    setShowFinishCourse,
    updateCourseSubareas,
} from '../../../store/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import CourseIntro from './CourseIntro';
import axios from '../../../app/axios';
import GeneralContent from './GeneralContent';
import ExerciseContent from './ExerciseContent';
import { useTracking } from '../../../helpers/useTracking';
import FontSizeToggle from '../FontSizeToggle';

interface ContentProps {
	showNavigation: boolean;
	handleNavigate: Function;
	handleToggleNavigation: Function;
	contentId: number;
	subareas: any;
	courseId: number;
}

const Content: React.FunctionComponent<ContentProps> = ({
	showNavigation,
	handleToggleNavigation,
	contentId,
	handleNavigate,
	subareas,
	courseId,
}) => {
	const [scrollDir, setScrollDir] = useState('up');
	const { calculateTimeRequired } = useTracking();
	const { currentCourse, currentCourseId, currentCourseSubareas } = useSelector(appSelector);

	const location = useLocation();
	const contentParam = new URLSearchParams(location.search).get('content');
	const navigationParam = new URLSearchParams(location.search).get(
		'navigation',
	);
	const [currentTrack, setCurrentTrack] = useState<any>({
		start: '',
		sub_area_id: 0,
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (navigationParam === '0') {
			startTimerForSelectedSubarea();
		}
		if (navigationParam === '1') {
			stopTimerForSelectedSubarea(currentTrack);
		}
	}, [contentParam, navigationParam, currentTrack.sub_area_id]);

	useEffect(() => {
		const queue_tracking = window.localStorage.getItem(
			'queue_tracking',
		) as any;
		if (queue_tracking) {
			const queue_tracking_array = JSON.parse(queue_tracking);
			queue_tracking_array.forEach((que: any) => {
				axios
					.post(`/app/tracking`, que)
					.then(function (response: any) {
						window.localStorage.setItem(
							'queue_tracking',
							JSON.stringify([]),
						);
						console.log(
							'Back online - filled Database from window.localStorage',
						);
						if (
							response.data.success &&
							response.data.success?.is_finished
						) {
							setTimeout(() => {
								dispatch(setShowFinishCourse(true));
							}, 500);
						}
					})
					.catch(function (error: any) {
						console.log(error);
					});
			});
		}
	}, []);

	function startTimerForSelectedSubarea() {
		var now = new Date();
		setCurrentTrack({ start: now, sub_area_id: contentParam });
	}

	function stopTimerForSelectedSubarea(currentTrackParm: any | null) {
		var now = new Date();
		let copy = currentTrackParm
			? { ...currentTrackParm }
			: { ...currentTrack };

		if (copy && copy.start) {
			updateTracking(now);
		}
	}

	async function updateTracking(endTime: any) {
		const { start, sub_area_id } = currentTrack;
		if (
			start &&
			sub_area_id &&
			endTime &&
			!!currentSubarea.learning_content &&
			sub_area_id !== 'undefined' &&
			sub_area_id !== 'NaN' &&
			sub_area_id !== '-1'
		) {
			const currentSubarea =
				contentId && contentId !== -1
					? subareas.find((item: any) => item.id === contentId)
					: {};

			const values = currentCourseId && {
				sub_area_id: sub_area_id,
				time_spent: Math.round(
					Math.abs((start.getTime() - endTime.getTime()) / 1000),
				),
                assignment_id: currentCourseId,
				learning_content_id: currentSubarea!.learning_content!.id,
				time_required: calculateTimeRequired(currentSubarea.wysiwyg),
			};

			udpateGlobally(values);
			updateLocally(values);
		}
	}

	function updateLocally(values: any) {
		if (subareas) {
			const updatedSubareas = subareas.map((sub: any) => {
				return sub.id === parseInt(values.sub_area_id)
					? { ...sub, time_spent: sub.time_spent + values.time_spent }
					: sub;
			});
			dispatch(updateCourseSubareas(updatedSubareas));
		}
	}

	async function udpateGlobally(values: any) {

		await axios
			.post(`/app/tracking`, values)
			.then(function (response: any) {
				setCurrentTrack({});
				const subareasPending = JSON.parse(
					localStorage.getItem('subareas_pending') || '',
				);
				if (
					subareasPending.indexOf(+values.sub_area_id) > -1 &&
					values.time_spent >= values.time_required
				) {
					subareasPending.splice(
						subareasPending.indexOf(values.sub_area_id),
						1,
					);
					localStorage.setItem(
						'subareas_pending',
						JSON.stringify(subareasPending),
					);
				}
				if (
					response.data.success &&
					response.data.success?.is_finished
				) {
					setTimeout(() => {
						dispatch(setShowFinishCourse(true));
					}, 500);
				}
			})
			.catch(function (error: any) {
				setCurrentTrack({});
				const currentSubareaInStorage = currentCourseSubareas.find(
					(area: any) => area.id === +values.sub_area_id,
				);
				const newTotalTime =
					currentSubareaInStorage.time_spent + values.time_spent;
				currentCourseSubareas[
					currentCourseSubareas.indexOf(currentSubareaInStorage)
				].time_spent = newTotalTime;

				dispatch({
                    ...setCurrentCourseSubareas(currentCourseSubareas)
                })
				let queue_tracking = !!window.localStorage.getItem(
					'queue_tracking',
				)
					? JSON.parse(window.localStorage.getItem('queue_tracking')!)
					: [];
				queue_tracking.push({
					sub_area_id: values.sub_area_id,
					time_spent: values.time_spent,
					time_required: values.time_required,
					learning_content_id: values.learning_content_id,
				});
				window.localStorage.setItem(
					'queue_tracking',
					JSON.stringify(queue_tracking),
				);
				const subareasPending = JSON.parse(
					localStorage.getItem('subareas_pending') || '',
				);

				if (
					subareasPending.indexOf(+values.sub_area_id) > -1 &&
					newTotalTime >= values.time_required
				) {
					subareasPending.splice(
						subareasPending.indexOf(values.sub_area_id),
						1,
					);
					localStorage.setItem(
						'subareas_pending',
						JSON.stringify(subareasPending),
					);
					if (!subareasPending.length) {
						setTimeout(() => {
							dispatch(setShowFinishCourse(true));
						}, 500);
					}
				}
				console.log('saved queue_tracking');
				console.log(error);
			});
	}

	useEffect(() => {
		const threshold = 0;
		let lastScrollY = document.getElementById('scrolllistener')!.scrollTop;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY =
				document.getElementById('scrolllistener')!.scrollTop;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};
		document
			.getElementById('scrolllistener')!
			.removeEventListener('scroll', onScroll);
		document
			.getElementById('scrolllistener')!
			.addEventListener('scroll', onScroll);

		return () => {
			if (document.getElementById('scrolllistener')) {
				document
					.getElementById('scrolllistener')!
					.removeEventListener('scroll', onScroll);
			}
		};
	}, [scrollDir]);

	const currentSubarea =
		contentId && contentId !== -1
			? subareas?.find((item: any) => item.id === contentId)
			: {};

	const renderHeaderContent = () => {
		if (contentId === -1) {
			return <h3>{currentCourse!.name}</h3>;
		} else {
			return (
				<>
					<h4>{getParentName()}</h4>
					<h3>{currentSubarea?.headline}</h3>
				</>
			);
		}
	};

	const getParentName = () => {
		return currentSubarea?.sub_area_id
			? subareas.find(
					(item: any) => item.id === currentSubarea.sub_area_id,
			  ).headline
			: currentCourse?.name;
	};

	const getNextItem = (id: number) => {
		let nextItem: any = null;
		const currentItemSubareas = subareas.filter(
			(item: any) => item.sub_area_id === currentSubarea.id,
		);
		if (currentItemSubareas.length) {
			nextItem = currentItemSubareas[0];
		}
		if (!nextItem && currentSubarea) {
			let isOnEnd = false;
			let crawledItem = currentSubarea;
			while (!isOnEnd && !nextItem) {
				const parentItem = subareas.find(
					(item: any) => item.id === crawledItem.sub_area_id,
				);
				if (parentItem) {
					const siblingAreas = subareas.filter(
						(item: any) => item.sub_area_id === parentItem.id,
					);
					const nextSibling =
						siblingAreas[siblingAreas.indexOf(crawledItem) + 1];
					if (nextSibling) nextItem = nextSibling;
					else crawledItem = parentItem;
				} else {
					const rootSubareas =
						subareas?.filter(
							(item: any) => item.sub_area_id === null,
						) || [];
					const siblingParentArea =
						rootSubareas[rootSubareas.indexOf(crawledItem) + 1];
					nextItem = siblingParentArea;
					if (!siblingParentArea) isOnEnd = true;
				}
			}
		}
		return nextItem;
	};

	return (
		<div className="app-content">
			<div
				className={`preview-window-content-header app-content-header ${
					scrollDir === 'down' || showNavigation ? 'scrolling' : ''
				} ${contentId === -1 ? 'course-intro' : ''}`}
			>
				{contentId === -1 ? (
					<CloseIcon onClick={() => handleToggleNavigation()} />
				) : (
					<MenuIcon onClick={() => handleToggleNavigation()} />
				)}

				<div className="preview-window-content-header-headlines">
					{renderHeaderContent()}
				</div>
				<div style={{ marginLeft: 15 }}>
					<FontSizeToggle />
				</div>
			</div>
			{contentId === -1 ? (
				<div
					className="app-content-container app-content-intro"
					id="scrolllistener"
				>
					<CourseIntro handleNavigate={handleNavigate} />
				</div>
			) : !currentSubarea.is_exercise ? (
				<div className="app-content-container" id="scrolllistener">
					<GeneralContent
						parentName={getParentName()}
						handleNavigate={(f: any, m: any) => {
							stopTimerForSelectedSubarea(currentTrack);
							handleNavigate(f, m);
						}}
						subarea={currentSubarea}
						nextSubarea={getNextItem(currentSubarea.id)}
					/>
				</div>
			) : (
				<div className="app-exercise-overlay" id="scrolllistener">
					<ExerciseContent
						handleNavigate={handleNavigate}
						subarea={currentSubarea}
						courseId={courseId}
						showNavigation={showNavigation}
						nextSubarea={getNextItem(currentSubarea.id)}
					/>
				</div>
			)}
		</div>
	);
};

export default Content;
