import React, { useEffect } from 'react';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';

import { useStyles } from './styles';
import BackgroundImage from '../../assets/images/bg-som.png';
import { UserContext } from '../../contexts/UserContext';
import { translate } from '../../i18n/i18n';

const Blocker = () => {
	const { user } = React.useContext(UserContext);
	const classes = useStyles();

	useEffect(() => {
		document.querySelector('body')?.classList.add('app-root');
		if (process.env.REACT_APP_COURSE_ID)
			document.querySelector('body')?.classList.add('appucations-app');

		return () => {
			document.querySelector('body')?.classList.remove('app-root');
			document.querySelector('body')?.classList.remove('appucations-app');
		};
	}, []);

	return user && user?.id ? (
		<div className={classes.BlockerWrapper}>
			<div
				className={classes.BGImage}
				style={{
					backgroundImage: `url("${BackgroundImage}")`,
				}}
			></div>
			<div className={classes.Content}>
				<MobileOffIcon />
				<h3>{translate('others.blocker.desktopRequired')}</h3>
				<p>{translate('others.blocker.mobileDevicesNotAvailable')}</p>
				{!!user.isEndUser && (
					<p>{translate('others.blocker.clickButtonBelow')}</p>
				)}
				<div className={classes.ContentButton}>
					{!!user.isEndUser && (
						<a
							onClick={() =>
								(window.location.href =
									'/app/learn?navigation=1&content=-1')
							}
							className={classes.Button}
						>
							<MobileScreenShareIcon />
							{translate('others.blocker.learningMode')}
						</a>
					)}
				</div>
				<div className={classes.Footer}>
					<p>&copy; {new Date().getFullYear()} APPucations GmbH</p>
					<ul>
						<li>
							<a
								href="https://www.appucations.de/agb/"
								target="_blank"
							>
								{translate('others.blocker.termsOfServices')}
							</a>
						</li>
						<li>
							<a
								href="https://www.appucations.de/impressum/"
								target="_blank"
							>
								{translate('others.blocker.legalNotice')}
							</a>
						</li>
						<li>
							<a
								href="https://www.appucations.de/datenschutzerklaerung/"
								target="_blank"
							>
								{translate('others.blocker.privacyPolicy')}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	) : null;
};

export default Blocker;
