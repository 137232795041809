import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {Message} from "semantic-ui-react";
import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {addUser, clearUser, userSelector} from "../../../store/users/userSlice";
import {translate} from "../../../i18n/i18n";

interface AddUserModalProps {
    showModal: boolean,
    closeModal: () => void,
    isSOMUser?: boolean
}

interface AddUserInterface {
    first_name?: string;
    last_name?: string;
    email?: string;
}

const AddUserModal: React.FunctionComponent<AddUserModalProps> = ({showModal, closeModal, isSOMUser}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addingMultipleUsers, setAddingMultipleUsers] = useState(false);
    const {isLoading, error, trigger} = useSelector(userSelector);

    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();

    const initialValues: AddUserInterface = {
        first_name: "",
        last_name: "",
        email: ""
    }

    useEffect(() => {
        if (isSubmitting && !error) {
            setIsSubmitting(false);
            console.log('')
            // dispatch({
            //     ...fetchUsers({
            //         requestType: "GET",
            //         request: 'smartometer/users',
            //     }),
            // });
            if (addingMultipleUsers) {
                setAddingMultipleUsers(false);
                if (formikRef.current) formikRef.current.resetForm();
            } else {
                closeModal();
            }
        }
        ;
        if (!!isLoading) setIsSubmitting(true)
    }, [error, trigger]);


    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = (values: AddUserInterface) => {
        setIsSubmitting(true);
        const {first_name, last_name, email} = values;
        dispatch({
            ...addUser({
                requestType: "POST",
                body: {
                    first_name: first_name!.trim(),
                    last_name: last_name!.trim(),
                    email: email!.trim()
                },
                request: `/smartometer/users`,
            }),
        });
    };

    const onValidate = (val: any) => {
        const errors: AddUserInterface = {}
        if (!val.first_name.trim()) errors.first_name = translate('modals.addUserModal.firstNameRequired');
        if (!val.last_name.trim()) errors.last_name = translate('modals.addUserModal.lastNameRequired');
        if (!val.email.trim()) errors.email = translate('modals.addUserModal.emailRequired');
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val.email.trim())) errors.email = "Keine gültige E-Mail Adresse";
        return errors;
    }

    const onSubmitMultiple = () => {
        setAddingMultipleUsers(true);
        handleFormikSubmit();
    }

    return (
        <>
            <Modal showModal={showModal} header={translate('modals.addUserModal.header')} onClose={() => {
                dispatch({...clearUser()})
                closeModal();
            }} onSubmit={handleFormikSubmit} isLoading={isLoading} submitActionTitle={translate('modals.addUserModal.submit')}
                   closeActionTitle={translate('modals.addUserModal.close')}secondaryAction={onSubmitMultiple} submitActionColor={color.link}
                   disabled={isLoading} secondaryColor={color.link} secondaryActionTitle={translate('modals.addUserModal.secondaryAction')}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched}) => (
                        <>
                            <div className={classes.InputWrapper}>
                                <TextField className={`${classes.Input} ${classes.InputDemi}`}
                                           error={touched.first_name && !!errors.first_name}
                                           helperText={touched.first_name && !!errors.first_name && errors.first_name}
                                           id="first_name" value={values.first_name} label={translate('modals.addUserModal.firstname')} name="first_name"
                                           variant="outlined" onChange={handleChange} onBlur={handleBlur}/>
                                <TextField className={classes.InputDemi} error={touched.last_name && !!errors.last_name}
                                           helperText={touched.last_name && !!errors.last_name && errors.last_name}
                                           id="last_name" value={values.last_name} label={translate('modals.addUserModal.lastname')} name="last_name"
                                           variant="outlined" onChange={handleChange} onBlur={handleBlur}/>
                            </div>
                            <TextField className={classes.Input} error={touched.email && !!errors.email}
                                       helperText={touched.email && !!errors.email && errors.email} id="email"
                                       value={values.email} label={translate('modals.addUserModal.email')} name="email" variant="outlined"
                                       onChange={handleChange} onBlur={handleBlur}/>
                            {error && (
                                <Message
                                    error
                                    content={error}
                                />)}
                            <p className={classes.Note}>{translate('modals.addUserModal.note')}</p>
                        </>
                    )}
                </Formik>
            </Modal>
        </>
    )
};

export default AddUserModal;
