import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface initialStateInterface {
	data: any;
	single: any;
	isLoading: boolean;
	error: string;
}

const initialState: initialStateInterface = {
	data: null,
	single: null,
	isLoading: false,
	error: '',
};

export const settingSlice = createSlice({
	name: 'setting',
	initialState,
	reducers: {
		fetchsettings: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			// state.isLoading = true;
			// console.log(state.isLoading);
			if (action.state === 'finished' && !!action.response.success) {
				state.data = { ...action.response.success };
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			} else {
				state.isLoading = false;
			}
		},
		fetchsetting: (state, action: any) => {
			state.isLoading = true;
			if (action.state === 'finished' && !!action.response.success) {
				state.data = action.response.success;
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			} else {
				state.isLoading = false;
			}
		},
		addsetting: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
		},
		updatesetting: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
		},
		deletesetting: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.isLoading = false;
			}
		},
        clearSettingsState: (state) => {
            state.data = null
        }
	},
});

export const {
	fetchsetting,
	fetchsettings,
	addsetting,
	updatesetting,
	deletesetting,
    clearSettingsState,
} = settingSlice.actions;
export const settingSelector = (state: RootState) => state.setting;
export default settingSlice.reducer;
