import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@material-ui/core';
import { format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import IconDropdown from '../../../components/shared/IconDropdown';
import BaseModal from '../../../components/shared/BaseModal';
import EditUserDataModal from '../../modals/EditUserDataModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../../store/users/userSlice';
import Avatar from '../../shared/Avatar';
import { useStyles } from './style';
import useUserContext, { UserContext } from '../../../contexts/UserContext';
import Banner from '../../shared/Banner';
import PurchaseOrSupportModal from '../../modals/PurchaseOrSupportModal';
import PurchaseModal from '../../modals/PurchaseModal';
import { PurchaseOrSupportType } from '../../../enums/purchase/purchase-enums';
import AddDemoDataModal from '../../modals/AddDemoDataModal';
import {
	clearSettingsState,
	updatesetting,
} from '../../../store/settings/settingSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import RemoveDemoDataModal from '../../modals/RemoveDemoDataModal';
import ThankYouModal from '../../modals/ThankYouModal';
import { getGlobalSearch } from '../../../helpers/GlobalSearch';
import axios from '../../../app/axios';
import AddUserModal from '../../modals/AddUserModal';
import AddGroupModal from '../../modals/AddGroupModal';
import { AssignUserModal } from '../../modals/AssignUserModal';
import Tooltip from '@material-ui/core/Tooltip';
import {
	addAssignment,
	assignmentSelector,
} from '../../../store/assignments/assignmentSlice';
import AddMemberModal from '../../modals/AddMemberModal';
import { translate } from '../../../i18n/i18n';
import { clearState } from '../../../store/app/appSlice';
import { clearInstitutionState } from '../../../store/institution/institutionSlice';

interface MainNavbar {
	logo: string;
	color: string;
}

const MainNavbar: React.FunctionComponent<MainNavbar> = ({ logo, color }) => {
	const {
		user,
		showBanner,
		isTrial,
		trialEndAt,
		paidPeriodStartAt,
		isUnpaid,
		askedForDemoData,
		askedToRemoveDemoData,
	} = React.useContext(UserContext);

	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [auth, setAuth] = React.useState(true);
	const [openAddCourse, setOpenAddCourse] = React.useState(false);
	const [openEditUser, setOpenEditUser] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openAddDemoData, setOpenAddDemoData] = useState<boolean>(false);
	const [openRemoveDemoData, setOpenRemoveDemoData] =
		useState<boolean>(false);
	const [trialRunningOut, setTrialRunningOut] = useState<string | null>(null);
	const [trialTimer, setTrialTimer] = useState<boolean>(false);
	const prepCurrentUserId = window.localStorage.getItem('id');
	const [openPurchasOrContactSupport, setOpenPurchasOrContactSupport] =
		useState<boolean>(false);
	const [openThankYouModal, setOpenThankYouModal] = useState<boolean>(false);

	const [purchase, setPurchase] = useState<boolean>(false);
	const [contactSupport, setContactSupport] = useState<boolean>(false);
	const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
	const [showAddGroupModal, setShowAddGroupModal] = useState<boolean>(false);
	const [showAddAssignmentModal, setShowAddAssignmentModal] =
		useState<boolean>(false);
	const [showAddMemberModal, setShowAddMemberModal] =
		useState<boolean>(false);
	const [search, setSearch] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchResult, setSearchResult] = useState([]);
	const [searchResultAll, setSearchResultAll] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const searchInput = useRef<HTMLInputElement | null>(null);

	let avatarImage = '/defaultUser.png';
	if (window.localStorage.getItem('avatar') != 'null') {
		avatarImage =
			window.localStorage.getItem('avatar') || '/defaultUser.png';
	}

	const { isLoading: isAssignmentLoading } = useSelector(assignmentSelector);

	useEffect(() => {
		if (search) {
			searchInput.current?.focus();
		}
	}, [search]);

	useEffect(() => {
		if (isUnpaid === true) {
			setOpenPurchasOrContactSupport(true);
		}
		if (askedForDemoData === false) {
			setOpenAddDemoData(true);
		}

		if (
			isTrial === false &&
			isUnpaid === false &&
			askedToRemoveDemoData === false &&
			askedForDemoData === true
		) {
			setOpenRemoveDemoData(true);
		}
	}, [isUnpaid, askedForDemoData, askedToRemoveDemoData, isTrial]);

	useEffect(() => {
		const now = new Date();
		if (trialEndAt && trialEndAt > now && !trialTimer) {
			const difference = trialEndAt.getTime() - now.getTime();

			const differenceInDays = Math.floor(
				difference / (1000 * 3600 * 24),
			);

			const differenceInHours = Math.floor(
				(difference - differenceInDays * (1000 * 3600 * 24)) /
					(1000 * 3600),
			);

			const differenceInMinutes = Math.floor(
				(difference -
					(differenceInDays * (1000 * 3600 * 24) +
						differenceInHours * (1000 * 3600))) /
					(1000 * 60),
			);

			const differenceInSeconds = Math.floor(
				(difference -
					(differenceInDays * (1000 * 3600 * 24) +
						differenceInHours * (1000 * 3600) +
						differenceInMinutes * (1000 * 60))) /
					1000,
			);
			setTrialRunningOut(
				`${differenceInDays} Tage ${differenceInHours}Std ${differenceInMinutes}min ${differenceInSeconds}sek`,
			);
			setTrialTimer(true);
			setTimeout(() => {
				setTrialTimer(false);
			}, 1000);
		}
	}, [trialEndAt, trialTimer]);

	const open = Boolean(anchorEl);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAuth(event.target.checked);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const trigger = (
		<span>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ marginRight: '10px' }}>
					<Avatar src={avatarImage} />
				</div>
				{translate('layout.mainNavbar.hello')},{' '}
				{window.localStorage.getItem('firstname')}{' '}
				{window.localStorage.getItem('lastname')}
			</div>
		</span>
	);

	const logout = () => {
		dispatch({
			...clearState(),
		});
		dispatch({
			...clearSettingsState(),
		});
		window.localStorage.clear();
		history.push('/auth/login');
	};

	const addNewCourseModal = () => {
		setOpenAddCourse(true);
	};

	const addNewUserModal = () => {
		setShowAddUserModal(true);
	};

	const addNewCourse = () => {
		history.push('/authoring');
	};
	const addNewGroup = () => {
		setShowAddGroupModal(true);
	};

	const addNewAssignment = () => {
		setShowAddAssignmentModal(true);
	};

	const addNewMember = () => {
		setShowAddMemberModal(true);
	};

	const openEditUserDataModal = () => {
		dispatch(clearUser());
		setOpenEditUser(true);
	};

	const openPurchaseOrContactSupport = () => {
		setOpenPurchasOrContactSupport(true);
	};

	const addNewOptions = [
		{
			key: 'users',
			text: translate('layout.mainNavbar.addUser'),
			onClick: addNewUserModal,
			permission: 'FE_SOM_USERS',
		},
		{
			key: 'courses',
			text: translate('layout.mainNavbar.addCourse'),
			onClick: addNewCourse,
			permission: 'FE_SOM_COURSES',
		},
		{
			key: 'groups',
			text: translate('layout.mainNavbar.addGroup'),
			onClick: addNewGroup,
			permission: 'FE_SOM_USERS',
		},
		{
			key: 'assignment',
			text: translate('layout.mainNavbar.addAssignment'),
			onClick: addNewAssignment,
			permission: 'FE_SOM_USERS',
		},
		{
			key: 'member',
			text: translate('layout.mainNavbar.addMember'),
			onClick: addNewMember,
			permission: 'FE_SOM_SETTINGS',
		},
	];

	const options = [
		{
			key: 'user',
			text: (
				<span>
					{translate('layout.mainNavbar.signedWith')}{' '}
					<strong>{user && user.email}</strong>
				</span>
			),
			disabled: true,
		},
		{
			key: 'edit-profile',
			text: translate('layout.mainNavbar.myProfile'),
			onClick: openEditUserDataModal,
		},
		{
			key: 'sign-out',
			text: translate('layout.mainNavbar.signOut'),
			onClick: logout,
		},
	];

	const openPurchase = () => {
		setPurchase(true);
		setOpenPurchasOrContactSupport(false);
	};

	const openContactSupport = () => {
		setContactSupport(true);
		setOpenPurchasOrContactSupport(false);
	};

	const handleOpenSearch = () => {
		setSearch(true);
	};

	const handleCloseSearch = () => {
		setSearchValue('');
		setSearch(false);
	};

	const noDemoData = () => {
		dispatch({
			...updatesetting({
				requestType: 'PUT',
				request: `smartometer/institution`,
				body: {
					asked_for_demo_data: true,
					demo_data_added: false,
				},
			}),
		});
		setOpenAddDemoData(false);
	};

	const addDemoData = () => {
		dispatch({
			...updatesetting({
				requestType: 'PUT',
				request: `smartometer/institution`,
				body: {
					asked_for_demo_data: true,
					demo_data_added: true,
				},
			}),
		});
		setOpenAddDemoData(false);
	};

	const notRemoveDemoData = () => {
		dispatch({
			...updatesetting({
				requestType: 'PUT',
				request: `smartometer/institution`,
				body: {
					asked_to_remove_demo_data: true,
					remove_demo_data: false,
				},
			}),
		});
		setOpenRemoveDemoData(false);
	};

	const removeDemoData = () => {
		dispatch({
			...updatesetting({
				requestType: 'PUT',
				request: `smartometer/institution`,
				body: {
					asked_to_remove_demo_data: true,
					remove_demo_data: true,
				},
			}),
		});
		setOpenRemoveDemoData(false);
	};

	const handleAssignmentSubmit = (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
				}),
				send_email_date:
					send_email_date && format(send_email_date, 'yyyy-MM-dd'),
				request: `smartometer/assignments/${id}`,
			}),
		});
		setShowAddAssignmentModal(false);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
		filterAllPageSearch(event.target.value);
		fetchGlobalSearch(event.target.value);
	};

	const fetchGlobalSearch = async (localSearch: string) => {
		setIsLoading(true);
		const result = await axios
			.get(`/global-search?search=${localSearch}&limit=5`)
			.then(function (response) {
				setSearchResult(response.data.success);
				setIsLoading(false);
			})
			.catch(function (error) {
				setIsLoading(false);
				setSearchResult([]);
				console.log(error);
			});
	};

	const highlighSearchValue = async (content: any) => {
		const h = content
			.toLowerCase()
			.replace(new RegExp(searchValue.toLowerCase()), (match: any) => {
				return `<span style="background: red">${match}</span>`;
			});
		return `<span> ${h} </span>`;
	};

	const getResultSearch = (result: any) => {
		return result.search.find((fi: string) =>
			fi.toLowerCase().includes(searchValue.toLowerCase()),
		);
	};

	const filterAllPageSearch = async (localSearch: string) => {
		const allSearchValues = getGlobalSearch();
		let results = allSearchValues.filter(
			(value) =>
				value.search.filter((fil) =>
					fil.toLowerCase().includes(localSearch.toLowerCase()),
				).length > 0,
		);
		if (results.length) {
			console.log(results);
			setSearchResultAll(results);
		}
	};

	const searchAllRedirect = (redirect: string) => {
		history.push(redirect);
		handleCloseSearch();
	};

	const searchRedirect = (type: string, id: number) => {
		switch (type) {
			case 'USER':
				history.push(`/users/detail/${id}`);
				break;
			case 'GROUP':
				history.push(`/users/groups/detail/${id}`);
				break;
			case 'COURSE':
				history.push(`/authoring/${id}`);
				break;
			default:
				break;
		}
		handleCloseSearch();
	};

	return (
		<div>
			<Menu
				fixed="top"
				style={{ zIndex: 9999, paddingTop: showBanner ? 24 : 0 }}
			>
				{isTrial === true && (
					<Banner>
						{translate('layout.mainNavbar.trialVersion')}{' '}
						<b>{trialRunningOut}</b>{' '}
						<Link
							className={classes.upgradeLink}
							onClick={openPurchaseOrContactSupport}
						>
							{translate('layout.mainNavbar.upgradeNow')}
						</Link>
					</Banner>
				)}
				{isUnpaid === true && (
					<Banner>
						{translate('layout.mainNavbar.trialVersion')}{' '}
						<Link
							className={classes.upgradeLink}
							onClick={openPurchaseOrContactSupport}
						>
							{translate('layout.mainNavbar.upgradeNow')}
						</Link>
					</Banner>
				)}
				<Menu.Item>
					{user && user.institution && (
						<Image
							size="small"
							onClick={() => history.push('/dashboard')}
							style={{
								height: '45px',
								objectFit: 'contain',
								cursor: 'pointer',
							}}
							src={user.institution.logo || '/somlogo.svg'}
						/>
					)}
					{!search && (
						<Typography style={{ paddingLeft: 90 }} paragraph>
							{translate('layout.mainNavbar.welcomeBack')}{' '}
							{window.localStorage.getItem('firstname')}{' '}
							{window.localStorage.getItem('lastname')}
						</Typography>
					)}
					{search && (
						<div
							style={{ marginBottom: 0, paddingLeft: 90 }}
							className="coursewizard-input-wrapper exercise-wrapper"
						>
							<input
								className={classes.TitleInput}
								name="search"
								ref={searchInput}
								type="text"
								placeholder={translate(`search`)}
								value={searchValue}
								onChange={handleSearch}
							/>
						</div>
					)}
				</Menu.Item>
				<Menu.Menu position="right">
					<Menu.Item>
						{search && (
							<IconButton
								color="inherit"
								onClick={handleCloseSearch}
							>
								<Icon disabled size="small" name="close" />
							</IconButton>
						)}
						<Tooltip title={translate('tooltip.globalSearch')}>
							<IconButton
								color="inherit"
								onClick={handleOpenSearch}
							>
								<Icon disabled size="small" name="search" />
							</IconButton>
						</Tooltip>
						{!search && (
							<>
								<Tooltip title={translate('tooltip.create')}>
									<IconButton color="inherit">
										<IconDropdown
											icon="plus circle"
											options={addNewOptions}
										/>
									</IconButton>
								</Tooltip>
								<BaseModal
									openModal={openAddCourse}
									closeModal={() => setOpenAddCourse(false)}
								/>
							</>
						)}
					</Menu.Item>
					{!search && (
						<Menu.Item>
							<Dropdown trigger={trigger} options={options} />
						</Menu.Item>
					)}
				</Menu.Menu>
			</Menu>

			<div
				className={classes.colorBar}
				style={{
					background:
						window.localStorage.getItem('institutionColor') || '',
				}}
			></div>
			{!!searchValue && (
				<div
					style={{
						width: 800,
						backgroundColor: 'white',
						position: 'fixed',
						top: showBanner ? 95 : 75,
						left: 250,
						zIndex: 9997,
					}}
				>
					{isLoading && <CircularProgress />}

					{searchResultAll &&
						!!searchResultAll.length &&
						!isLoading &&
						searchResultAll.map(function (result: any, i: any) {
							return (
								<>
									{result.search.find((fi: string) =>
										fi
											.toLowerCase()
											.includes(
												searchValue.toLowerCase(),
											),
									) && (
										<p
											onClick={() =>
												searchAllRedirect(
													result.redirect,
												)
											}
											className={classes.searchItem}
										>
											{' '}
											{result.name}
											{' - '}
											<span
												dangerouslySetInnerHTML={{
													__html:
														String(
															result.search
																.find(
																	(
																		fi: string,
																	) =>
																		fi
																			.toLowerCase()
																			.includes(
																				searchValue.toLowerCase(),
																			),
																)
																.toLowerCase()
																.replace(
																	new RegExp(
																		searchValue.toLowerCase(),
																	),
																	(
																		match: any,
																	) => {
																		return `<span style="background: #ffff7e">${match}</span>`;
																	},
																),
														) || '',
												}}
											/>
										</p>
									)}
								</>
							);
						})}

					{!!searchResult.length &&
						!isLoading &&
						searchResult.map(function (result: any, i: any) {
							return (
								<p
									onClick={() =>
										searchRedirect(result.type, result.id)
									}
									className={classes.searchItem}
								>
									{' '}
									<span
										dangerouslySetInnerHTML={{
											__html:
												String(
													result.name.replace(
														new RegExp(
															searchValue,
															'i',
														),
														(match: any) => {
															return `<span style="background: #ffff7e">${match}</span>`;
														},
													),
												) || '',
										}}
									/>{' '}
								</p>
							);
						})}
					{!searchResult.length &&
						!searchResultAll.length &&
						!isLoading && (
							<p>
								{' '}
								{translate(
									'layout.mainNavbar.noDataFound',
								)}{' '}
							</p>
						)}
				</div>
			)}
			<PurchaseOrSupportModal
				showModal={openPurchasOrContactSupport}
				onPurchase={() => {
					openPurchase();
				}}
				onContactSupport={() => {
					openContactSupport();
				}}
				closeModal={() => setOpenPurchasOrContactSupport(false)}
				trialOver={!isTrial}
			/>
			<PurchaseModal
				showModal={purchase}
				closeModal={() => {
					setPurchase(false);
					setOpenThankYouModal(true);
				}}
				type={PurchaseOrSupportType.PURCHASE}
				trialOver={!isTrial}
			/>
			<PurchaseModal
				showModal={contactSupport}
				closeModal={() => {
					setContactSupport(false);
					setOpenThankYouModal(true);
				}}
				type={PurchaseOrSupportType.SUPPORT}
				trialOver={!isTrial}
			/>
			<AddDemoDataModal
				showModal={openAddDemoData}
				onWithDemoData={() => {
					addDemoData();
				}}
				onWithoutDemoData={() => {
					noDemoData();
				}}
				closeModal={() => setOpenAddDemoData(false)}
			/>
			<RemoveDemoDataModal
				showModal={openRemoveDemoData}
				onRemoveDemoData={() => {
					removeDemoData();
				}}
				onNotRemoveDemoData={() => {
					notRemoveDemoData();
				}}
				closeModal={() => {
					setOpenRemoveDemoData(false);
				}}
			/>
			<ThankYouModal
				showModal={openThankYouModal}
				denyClose={!isTrial && isUnpaid}
				onClose={() => setOpenThankYouModal(false)}
			/>
			<AddUserModal
				showModal={showAddUserModal}
				closeModal={() => setShowAddUserModal(false)}
			/>
			<AddGroupModal
				showModal={showAddGroupModal}
				closeModal={() => setShowAddGroupModal(false)}
			/>
			<AssignUserModal
				isLoading={isAssignmentLoading}
				showModal={showAddAssignmentModal}
				closeModal={() => setShowAddAssignmentModal(false)}
				onSubmit={handleAssignmentSubmit}
				confirmText={translate('layout.mainNavbar.addToCourse')}
				message={translate('layout.mainNavbar.addSelectedUser')}
			/>
			<AddMemberModal
				showModal={showAddMemberModal}
				closeModal={() => setShowAddMemberModal(false)}
			/>
			<EditUserDataModal
				onSave={() => console.log('Save')}
				ownProfile
				openModal={openEditUser}
				closeModal={() => {
					setOpenEditUser(false);
				}}
				userId={prepCurrentUserId}
			/>
		</div>
	);
};

export default MainNavbar;
