import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
	successMessage: {
		fontSize: 18,
		marginTop: 13
	},
	successContainer: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center'
	},
	Container: {
		marginTop: 100,
		display: 'flex',
		justifyContent: 'center'
	},
	Success: {
		margin: '0 auto'
	}
}))
