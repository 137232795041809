import { makeStyles } from '@material-ui/core/styles'
import { color } from '../../../utils/styles.js'

export const useStyles = makeStyles(theme => ({
    Icon: {
        color: color.darkGray,
        cursor: "pointer",
        transition: ".3s",

        "&:hover": {
            color: color.primary,
        }
    }
}))
