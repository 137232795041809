import Chart from "react-apexcharts";
import React from "react";
import {color} from "../../../../utils/styles";
import {GraphHeader} from "../GraphHeader";
import {useStyles} from "./styles";
import {translate} from "../../../../i18n/i18n";

interface BarChartProps {
    values: any[],
    header?: boolean,
    small?: boolean
}

export const BarChart: React.FunctionComponent<BarChartProps> = ({values, header,small}) => {

    const classes = useStyles();

    return <div className={classes.wrapper}>
        {header && <GraphHeader headline={translate('shared.barChart.readingTime')} subtitle={translate('shared.barChart.last30Days')}/>}
        <div><Chart options={{
            chart: {
                type: 'bar',
                height: small ? 250 : 350,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    // columnWidth: '15%',
                    borderRadius: 5
                },
            },
            yaxis: {
                opposite: true,
                axisTicks: {
                    show: false,
                    color: color.lightGray
                },
                axisBorder: {
                    show: false,
                    color: color.lightGray
                },
                labels: {

                    style: {
                        colors: color.lightGray
                    }
                }
            },
            xaxis: {
                type: 'datetime',
                axisTicks: {
                    show: true,
                    color: color.lightGray
                },
                axisBorder: {
                    show: false,
                    color: color.lightGray
                },
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: color.lightGray
                    }
                }
            },
            legend: {
                horizontalAlign: 'left'
            }
        }} series={values} type="bar" height={small ? 250 : 350}/>
        </div>
    </div>
}
