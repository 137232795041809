import React from "react";
import { useStyles } from "./style"
import PersonIcon from '@material-ui/icons/Person';

interface AvatarProps{
    src: string;
    style?: any | undefined;
    onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    className?: string
}

const Avatar: React.FunctionComponent<AvatarProps> = ({ src , style, onClick, className}) => {

    const classes = useStyles();

    return <div className={`user-avatar ${classes.Avatar} ${className}`} style={{ ...style, backgroundImage: `url(${src})` }} onClick={onClick} >{!src && <PersonIcon />}</div>
}

export default Avatar;
