import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Input: {
		width: '100%',
		position: 'relative',
		paddingBottom: '20px',

		'& .MuiFormHelperText-root': {
			position: 'absolute',
			bottom: '0',
		},
	},
	Label: {
		color: color.textDark,
		fontSize: textSizes.bodySmall,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	title: {
		fontSize: textSizes.bodyNormal,
		fontWeight: 700,
		lineHeight: '22px',
		textAlign: 'left',
		marginBottom: 20,
		borderColor: color.borderGrey,
	},
	Singleselect: {
		height: 44,
		borderRadius: '8px !important',
	},
	Multiselect: {
		height: 44,
		borderRadius: '8px !important',
		'& .label': {
			background: color.lightBlue,
		},
		'& .divider': {
			marginTop: '10px !important',
		},
	},
	Avatar: {
		display: 'inline-block',
		float: 'left',
		marginRight: '10px',
	},
	AvatarName: {
		lineHeight: '44px',
	},
	row: {
		padding: '0 !important',
	},
	datepicker: {
		height: 55,
		background: '#f8f8f8',
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'inline-flex !important',
		width: '100% !important',
		marginBottom: '0 !important',
		borderRadius: 30,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& i': {
			paddingTop: '8px !important',
		},
		'& .MuiInput-underline::after, & .MuiInput-underline::before': {
			display: 'none',
		},
		'& .Mui-disabled': {
			color: color.darkBackground,
		},
		'& .MuiFormHelperText-root.Mui-error': {
			position: 'absolute',
			bottom: -15,
			left: 24,
		},
		'& .MuiInputBase-root': {
			height: '100%',
			'& input': {
				padding: '0 24px',
			},
		},
	},
}));
