import { makeStyles } from '@material-ui/core/styles';
import NoneLayout from '../../../layouts/None.js';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles(theme => ({
	AddItem: {
		padding: '0 13px 0 22px',
		marginBottom: 0,
		cursor: 'pointer',
		fontSize: 16,

		'&.disabled': {
			cursor: 'not-allowed',
			opacity: '.6',
		},

		'& a': {
			color: color.link,
			display: 'flex',

			'& svg': {
				marginRight: 10,
			},
		},
	},
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: 300,
        overflow: "scroll",
		flexShrink: 0,
		fontSize: textSizes.bodyNormal,
	},
	drawerPaper: {
		width: 300,
        overflow: "scroll",
		background: color.white,
		borderRight: 'none',
		color: color.borderGrey,
		padding: '108px 14px 0',
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
	},
	paper: {
		background: 'black',
		color: 'white',
	},
	activeNav: {
		listItem: {
			background: '#3F4049',
			borderRadius: 6,
			outlineLeft: `5px solid ${color.lightPrimary}`,
		},
	},
	SideNavTab: {
		height: 56,
		padding: '18px 18px 18px 13px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: color.textDark,
		borderRadius: 8,
		borderLeft: '9px solid transparent',
		cursor: 'pointer',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',

		'& span': {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			maxWidth: 'calc(100% - 20px)',
		},

		'& svg': {
			color: '#eaeaea',
			marginRight: 13,
		},

		'&.level-1': {
			marginLeft: 15,
		},

		'&.level-2': {
			marginLeft: 30,
		},

		'&.level-3': {
			marginLeft: 45,
		},
	},
	SideNavTabActive: {
		borderLeftColor: color.textDark,
		backgroundColor: '#eaeaea',

		'& svg': {
			color: color.darkBackground,
		},
	},
	// NEW SIDEBAR
	SideNavResizeable:{
		padding: '108px 0 0 14px',
		backgroundColor: "#ffffff",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		maxHeight: "100vh",
		overflowY: "auto",
		"-ms-overflow-style": "none",
  		"scrollbar-width": "none",
		flexDirection: "column",
		display: "flex",

		"&::-webkit-scrollbar":{
			display: "none",
		}
	},

	SideNavTabIntro:{
		marginTop: 15,
		marginRight: 15,
		fontSize: 16
	},
	SearchInput:{
		height: 40,
		marginTop: 30,
		boxSizing: "content-box",
		borderBottom: '1px solid #dfe0eb',
		paddingBottom: 30,
		marginBottom: 30,
		width: "calc(100% - 15px)",
		"&.MuiInput-underline:before":{
			display: "none",
		},
		"&.MuiInput-underline:after":{
			display: "none",
		},
		'& input.MuiInputBase-input': {
			height: 40,
			borderRadius: 20,
			border: '1px solid #c7c7c7',
			padding: '0 40px 0 20px',
			minWidth: '180px',
			boxSizing: 'border-box',
			'&:focus': {
				border: '1px solid rgb(20, 133, 238)',

				"& + .MuiInputAdornment-root svg":{
					color: "rgb(20, 133, 238)"
				}
			},
		},
		'& .MuiInput-underline:before,.MuiInput-underline:after': {
			display: 'none !important',
		},

		"& .MuiInputAdornment-root":{
			position: "absolute",
			right: 20,
			color: "#EAEAEA"
		}
	},
	TreeWrapper:{
		flexGrow: 1,
		position: "relative",
		"& .rst__virtualScrollOverride":{
			"-ms-overflow-style": "none",
  			"scrollbar-width": "none",
			"&::-webkit-scrollbar":{
				display: "none",
			}
		},

		"& .ReactVirtualized__Grid__innerScrollContainer":{
			marginBottom: 30,
			marginTop: 30
		},

		"&::after":{
			content: "''",
			display: "inline-block",
			position: "absolute",
			bottom: 0,
			left: 0,
			right: 0,
			height: 30,
			background: "linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%)",
			zIndex: 12,
		},
		"&::before":{
			content: "''",
			display: "inline-block",
			position: "absolute",
			top:0,
			left: 0,
			right: 0,
			height: 30,
			background: "linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%)",
			zIndex: 12,
		}
	}
}));
