import {makeStyles} from '@material-ui/core/styles'
import {color} from '../../../utils/styles.js'

export const useStyles = makeStyles(theme => ({
    SectionPage: {
        position: "relative",
        paddingTop: 50
    },
    TitleInput: {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "44px",
        color: color.textDark
    },
    ButtonRow: {
        marginBottom: 25,

    },
    Button: {
        marginRight: 12
    },
    SubmitSpinner: {
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 12,
        display: 'flex',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute'
    },
    editorWrapper: {
        // display: 'inline-flex',
        // flexDirection: 'column'
        "&.disabled": {
            pointerEvents: 'none',
        }
    }
}))
