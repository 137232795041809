import React, {useState, useEffect} from "react";
import { useHistory, useLocation } from 'react-router-dom';
import {Dropdown, DropdownProps, Grid} from "semantic-ui-react";
import {format, parse, parseISO, Locale} from 'date-fns'
import { useDispatch, useSelector } from "react-redux";
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import {Link} from "@material-ui/core";
import {initialSorting, SortingInterface} from "../../../interfaces/sorting-interface";
import {useStyles} from "./styles";
import {usePagination} from "../../../helpers/usePagination";
import {
    invoiceSelector,
    fetchInvoices,
    downloadInvoiceAsPdf,
    clearDownload, fetchYears
} from "../../../store/invoices/invoiceSlice";
import {translate} from '../../../i18n/i18n';


interface InvoiceTabProps {
}

const InvoiceTab: React.FunctionComponent<InvoiceTabProps> = () => {

       // DELETE LOGIC
    const {isLoading, data: invoiceData, file, years} = useSelector(invoiceSelector);
    const [invoiceNumber, setInvoiceNumber] = useState<string | null>();
    const [year, setYear] = useState<number>( new Date().getFullYear());

    const [sorting, setSorting] = useState<SortingInterface>(initialSorting);
    const {handleChangePage, handleChangeRowsPerPage, page, rowsPerPage} = usePagination();
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
            dispatch({
                ...fetchYears({
                    requestType: "GET",
                    request: `smartometer/invoices/years`,
                }),
            });
    }, []);

    useEffect(() => {
            dispatch({
                ...fetchInvoices({
                    requestType: "GET",
                    request: `smartometer/invoices/?page=${page}&limit=${rowsPerPage}&sort=${sorting.column}&type=${sorting.desc ? `desc` : `asc`}&year=${year}`,
                }),
            });
    }, [sorting, page, rowsPerPage,year]);

    useEffect( () => {
        if(file) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `${invoiceNumber}.pdf`
            link.href = URL.createObjectURL(
                new Blob([file], { type: "applucation/pdf" })
            );
            link.click();
            dispatch({...clearDownload});
            setInvoiceNumber(null);
        }

    }, [file])
    const handleChangeSorting = (column: string) => {
        if(column === sorting.column && sorting.desc) setSorting(initialSorting);
        else if(column === sorting.column && !sorting.desc) setSorting({...sorting, desc: true});
        else if(column !== sorting.column) setSorting({...sorting, column: column});
    }

    const downloadInvoice = (id: number, invoiceNumber: string) => {
        setInvoiceNumber(invoiceNumber);
        dispatch({
            ...downloadInvoiceAsPdf({
                requestType: "GET",
                request: `smartometer/invoices/download/${id}`,
                headers: {'Content-Type': 'application/pdf'},
                responseType: "blob"
            }),
        });
    }

    const rowData = {
        name: {
            align: "left",
            renderType: (item: any): JSX.Element => <p>{translate('tabs.invoiceTab.infoiceFrom')} {item.created_at && format(parseISO(item.created_at), 'dd.MM.yyyy')}</p>
        },
        download: {
            align: "right",
            renderType: (item: any): JSX.Element => <p><Link href="#" onClick={(e:any) => {
                e.preventDefault();
                downloadInvoice(item.id, item.invoice_number);
            }
            }>{translate('tabs.invoiceTab.download')}</Link></p>
        },

    }


    const yearsForDropdown = years?.map( (year: any) => {
        return {
            key: year.year,
            text: year.year,
            value: year.year
        };
    });

    const handleYearSelectChange = (e: any, dropdown: DropdownProps) => {
        if(dropdown.value) {
            setYear(+dropdown.value);
        }
    }

    const headerComponent =
        <Dropdown
            style={{}}
            className={classes.Multiselect}
            fluid
            name="years"
            id="years"
            onChange={handleYearSelectChange}
            value={year}
            options={yearsForDropdown}
            placeholder={new Date().getFullYear().toString()}
            selection
        />;

    return  (
        <>
            {invoiceData && (<Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard small={true} title={translate('tabs.invoiceTab.invoices')} headerComponent={headerComponent}>
                            <Table showHeader={false} loading={isLoading} rows={rowData} data={invoiceData.data}  handleChangeSorting={handleChangeSorting} sorting={sorting} handleChangePage={handleChangePage} page={page} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                        </ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid>)}
        </>
    )
};

export default InvoiceTab;
