import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	DeviceToggle: {
		textTransform: 'capitalize',
		cursor: 'pointer',
		borderRadius: 8,
		border: `1px solid ${color.link}`,
		background: color.white,
		color: color.link,
		fontSize: textSizes.bodyNormal,
		padding: '0 30px',
		lineHeight: '44px',
		margin: '0 10px',
		opacity: 1,
		transition: '.3s',

		'&:hover': {
			opacity: 0.8,
		},
	},
	DeviceToggleActive: {
		background: color.link,
		color: color.white,
		opacity: 1,

		'&:hover': {
			opacity: 1,
		},
	},
}));
