import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/CallMade';

import { useStyles } from './styles';
import { LinearProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { translate } from '../../../i18n/i18n';
import useUserContext from '../../../contexts/UserContext';

interface SubpageProps {
	backButtonText?: string;
	headerComponent?: JSX.Element;
	isLoading?: boolean;
	onClickBackButton?: Function;
}

const Subpage: React.FunctionComponent<SubpageProps> = ({
	backButtonText,
	headerComponent,
	children,
	isLoading,
	onClickBackButton,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { showBanner } = useUserContext();

	return (
		<div
			className={`${classes.SubpageWrapper} ${
				showBanner ? classes.SubpageWrapperBannered : ''
			}`}
		>
			{isLoading ? (
				<LinearProgress />
			) : (
				<>
					{headerComponent ? (
						headerComponent
					) : (
						<div
							onClick={() =>
								onClickBackButton
									? onClickBackButton()
									: history.goBack()
							}
							className={classes.BackLink}
						>
							<Tooltip title={translate('tooltip.back')}>
								<ArrowBack />
							</Tooltip>
							{backButtonText}
						</div>
					)}
					<div className={classes.SubpageContent}>{children}</div>
				</>
			)}
		</div>
	);
};

export default Subpage;
