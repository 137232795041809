import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appSelector } from '../../../../store/app/appSlice';
import Button from '../../../shared/Button';
import { translate } from '../../../../i18n/i18n';
import {color} from "../../../../utils/styles";

const CourseIntro: React.FunctionComponent<{ handleNavigate: Function }> = ({
	handleNavigate,
}) => {
	const { currentCourse, currentCourseSubareas, customFontSize } =
		useSelector(appSelector);
	const rootSubareas =
		currentCourseSubareas?.filter(
			(item: any) => item.sub_area_id === null,
		) || [];
	return (
		<div className="app-content-general">
			<img className="header" src={currentCourse!.image} />
			<div className="intro">
				<h4>{translate('app.courseIntro.courseIntroduction')}</h4>
				<h2>{currentCourse!.name}</h2>
			</div>
			<div
				style={{
					fontSize: `${customFontSize}rem`,
					lineHeight: '1.5em',
				}}
				dangerouslySetInnerHTML={{
					__html: currentCourse!.description,
				}}
			></div>
			<div className="app-intro-subareas">
				<h3>{translate('app.courseIntro.topics')}</h3>
				{rootSubareas.map((area: any) => {
					if (!area.is_exercise) {
						return (
							<div
								key={area.id}
								className="app-intro-learningarea"
								onClick={() =>
									handleNavigate(`/app/learn/${area.id}`, {
										content: area.id,
										navigation: '0',
									})
								}
							>
								<p>{area.headline}</p>
								<span className="progress"></span>
							</div>
						);
					}
				})}
				<div className="som-button-wrapper">
					<Button
						onClick={() =>
							handleNavigate('/app/course/statistics', {
								navigation: '1',
							})
						}
						styles={{
							width: '100%',
							borderRadius: 5,
							height: 58,
							marginTop: 10,
						}}
						title={translate('app.courseIntro.courseStatistics')}
						type="solid"
						color={color.link}
					/>
				</div>
			</div>
		</div>
	);
};

export default CourseIntro;
