import React, {useState} from "react";
import {initialSorting, SortingInterface} from "../interfaces/sorting-interface";

export const useSorting = () => {

    const [sorting, setSorting] = useState<SortingInterface>(initialSorting);

    const handleChangeSorting = (column: string) => {
        if(column === sorting.column && sorting.desc) setSorting(initialSorting);
        else if(column === sorting.column && !sorting.desc) setSorting({...sorting, desc: true});
        else if(column !== sorting.column) setSorting({...sorting, column: column});
    }

    return {
        handleChangeSorting, sorting, setSorting
    }
}
