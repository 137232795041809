import React, { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { getPlatforms, IonApp, isPlatform } from '@ionic/react';
import { hotjar } from 'react-hotjar';
import { IonReactRouter } from '@ionic/react-router';
import { SplashScreen } from '@capacitor/splash-screen';
import ReactGA from 'react-ga4';

import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

import './App.css';
import { useFavicon } from './helpers/useFavicon';
import PageContextProvider from './contexts/UserProvider';
import Routing from './routing';
import CookieBlocker from './components/modals/CookieBlocker';

const App: React.FC = () => {
	const { setFavicon } = useFavicon();
	setFavicon && setFavicon();
	const [showCookieBlocker, setShowCookieBlocker] = useState(false);
	const [cookies, setCookie, removeCookie] = useCookies([
		'bbcookie-shown',
		'bbcookie-hotjar',
		'bbcookie-ga',
	]);

	useEffect(() => {
		document.addEventListener('deviceready', () => {
			setTimeout(() => {
				SplashScreen.hide({
					fadeOutDuration: 1000,
				});
			}, 2000);
		});
	}, []);

	useEffect(() => {
		if (isPlatform('desktop')) {
			if (
				!cookies['bbcookie-shown'] ||
				cookies['bbcookie-shown'] !== 'true'
			) {
				setShowCookieBlocker(true);
			}
			if (
				!!cookies['bbcookie-hotjar'] &&
				cookies['bbcookie-hotjar'] === 'allow'
			) {
				hotjar.initialize(
					+process.env.REACT_APP_HOTJAR_HJID!,
					+process.env.REACT_APP_HOTJAR_HJSV!,
				);
			}
			if (
				!!cookies['bbcookie-ga'] &&
				cookies['bbcookie-ga'] === 'allow'
			) {
				ReactGA.initialize(process.env.REACT_APP_GTAG!);
				ReactGA.pageview(
					window.location.pathname + window.location.search,
				);
			}
		} else if (isPlatform('ios')) {
			getTrackingStatus();
		}
	}, [cookies]);

	const handleAcceptCookies = (cookies: any) => {
		Object.keys(cookies).map((cookie) => {
			setCookie(
				//@ts-ignore
				`bbcookie-${cookie}`,
				cookies[cookie] ? 'allow' : 'disallow',
			);
		});
		setCookie('bbcookie-shown', true);
	};

	const getTrackingStatus = async () => {
		const response = await AppTrackingTransparency.getStatus();
		if (response.status === 'notDetermined') {
			requestPermission();
		} else if (response.status === 'authorized') {
			enableTracking();
		}
	};

	const requestPermission = async () => {
		const response = await AppTrackingTransparency.requestPermission();
		if (response.status === 'authorized') enableTracking();
	};

	const enableTracking = () => {
		ReactGA.initialize(process.env.REACT_APP_GTAG!);
		ReactGA.pageview(window.location.pathname + window.location.search);
		hotjar.initialize(
			+process.env.REACT_APP_HOTJAR_HJID!,
			+process.env.REACT_APP_HOTJAR_HJSV!,
		);
	};

	return (
		// @ts-ignore
		<IonApp>
			<CookiesProvider>
				{showCookieBlocker ? (
					<CookieBlocker
						handleAcceptCookies={handleAcceptCookies}
						show={showCookieBlocker}
					/>
				) : null}
				<div className="App">
					<IonReactRouter>
						<PageContextProvider>
							<Routing />
						</PageContextProvider>
					</IonReactRouter>
				</div>
			</CookiesProvider>
		</IonApp>
	);
};

export default App;
