import React, { useEffect, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, useHistory } from 'react-router-dom';
import { color } from '../../../utils/styles.js';
import useUserContext from '../../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import { translate } from '../../../i18n/i18n';

import {
	faCog,
	faChartPie,
	faUsers,
	faAward,
    faBuilding
} from '@fortawesome/free-solid-svg-icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
			background: 'white',
			color: color.borderGrey,
		},
		drawerContainer: {
			overflow: 'auto',
			flexGrow: 1,
		},
		content: {
			flexGrow: 1,
		},
		paper: {
			background: 'black',
			color: 'white',
		},
		activeNav: {
			listItem: {
				background: '#EAEAEA',
				borderRadius: 6,
				outlineLeft: `5px solid ${color.lightPrimary}`,
			},
		},
		BottomContent: {
			padding: '0 16px 50px',
		},

		AppButton: {
			background: color.link,
			height: 45,
			borderRadius: 6,
			width: '100%',
			cursor: 'pointer',
			color: '#fff',
			display: 'flex',
			alignItems: 'center',
			paddingLeft: 21,

			'&:hover': {
				color: '#fff',
			},

			'& svg': {
				marginRight: 12,
			},
		},
	}),
);

export default function ClippedDrawer() {
	const classes = useStyles();
	const [navigationItems, setNavigationItems] = useState<any[]>([]);
	const { user, permissions, showBanner } = useUserContext();

	const history = useHistory();
	const navigationItemsPrep = [
		{
			name: translate('layout.mainSidebar.overview'),
			permission: 'FE_SOM_DASHBOARD',
			link: '/dashboard',
			iconComponent: () => (
				<div style={{ paddingLeft: '2px' }}>
					<FontAwesomeIcon
						icon={faChartPie}
						style={{ fontSize: '17px' }}
					/>
				</div>
			),
		},
		{
			name: translate('layout.mainSidebar.user'),
			link: '/users',
			permission: 'FE_SOM_USERS',
			iconComponent: () => (
				<div style={{ paddingLeft: '0px' }}>
					<FontAwesomeIcon
						icon={faUsers}
						style={{ fontSize: '17px' }}
					/>
				</div>
			),
		},
        {
            name: translate('layout.mainSidebar.institutions'),
            link: '/institutions',
            permission: 'FE_SOM_INSTITUTIONS',
            iconComponent: () => (
                <div style={{ paddingLeft: '0px' }}>
                    <FontAwesomeIcon
                        icon={faBuilding}
                        style={{ fontSize: '17px' }}
                    />
                </div>
            ),
        },
		{
			name: translate('layout.mainSidebar.courses'),
			link: '/courses',
			permission: 'FE_SOM_COURSES',
			iconComponent: () => (
				<div style={{ paddingLeft: '4px' }}>
					<FontAwesomeIcon
						icon={faAward}
						style={{ fontSize: '17px' }}
					/>
				</div>
			),
		},
		{
			name: translate('layout.mainSidebar.settings'),
			link: '/settings',
			permission: 'FE_SOM_SETTINGS',
			iconComponent: () => (
				<div style={{ paddingLeft: '2px' }}>
					<FontAwesomeIcon
						icon={faCog}
						style={{ fontSize: '17px' }}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		if (permissions && !!permissions.length) {
			const navItems = navigationItemsPrep.filter((nav) =>
				permissions.includes(nav.permission) || (nav.permission === "FE_SOM_INSTITUTIONS" && user?.institution?.id === 1),
			);
			setNavigationItems(navItems);
		}
	}, [permissions]);
	const handleNavigate = () => {
		document.querySelector('.som-main-content')?.scrollTo(0, 0);
	};
	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Toolbar />
			<div
				style={{ marginTop: showBanner ? 24 : 0 }}
				className={classes.drawerContainer}
			>
				<List style={{ padding: '50px 16px 16px' }}>
					{navigationItems.map((item, index) => (
						<NavLink
							key={index}
							style={{
								color: '#252733',
								textDecoration: 'none',
							}}
							onClick={handleNavigate}
							to={item.link}
							className="sidebarLink"
							activeClassName="activeItem"
							activeStyle={{
								borderLeftColor: user?.institution?.color || '',
							}}
						>
							<ListItem
								button
								key={item.name}
								className="drawerItem"
								style={{
									margin: '10px 0',
								}}
							>
								<ListItemIcon
									style={{
										color: '#252733',
										minWidth: '40px',
									}}
								>
									{' '}
									<item.iconComponent />{' '}
								</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItem>
						</NavLink>
					))}
				</List>
			</div>
			{!!user?.isEndUser && (
				<div className={classes.BottomContent}>
					<a
						onClick={() =>
							history.push('/app/learn?navigation=1&content=-1')
						}
						className={classes.AppButton}
					>
						<MobileScreenShareIcon />
						{translate('layout.mainSidebar.goToApp')}
					</a>
				</div>
			)}
		</Drawer>
	);
}
