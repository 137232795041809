import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useStyles } from './styles';
import { differenceInDays, parse } from 'date-fns';
import { ColumnChart } from './ColumnChart';
import ContentCard from '../ContentCard';
import { ContentTypes } from '../../../enums/courses/courses-enums';
import { DonutChart } from './DonutChart';
import { KpiColumn } from './KpiColumn';
import { LineChart } from './LineChart';
import { BarChart } from './BarChart';
import { GraphHeader } from './GraphHeader';
import { translate } from '../../../i18n/i18n';
import MultiSelect from '../MultiSelect';

interface StatisticsProps {
	assignment: any;
	course: any;
}

const Statistics: React.FunctionComponent<StatisticsProps> = ({
	assignment,
	course,
}) => {
	const [selected, setSelected] = useState<number>(0);
	const [filteredExercises, setFilteredExercises] = useState<number[]>([]);
	const classes = useStyles();

	const endDate = parse(course.end, 'yyyy-MM-dd', new Date());
	const startDate = parse(course.start, 'yyyy-MM-dd', new Date());
	const difference = differenceInDays(endDate, new Date());

	const courseProgressData = Object.keys(assignment.courseProgress).map(
		(date: any) => {
			return {
				x: parse(date, 'yy-MM-dd', new Date()),
				y: assignment.courseProgress[date].toFixed(0),
			};
		},
	);

	const calculateReadingDuration = () => {
		if (!assignment?.readingDoneByDate) return '0';
		let totalTime = 0;
		Object.keys(assignment.readingDoneByDate).forEach((date: string) => {
			totalTime += assignment.readingDoneByDate[date];
		});
		const hours = Math.floor(totalTime / 3600);
		const min = Math.floor((totalTime % 3600) / 60);
		const seconds = totalTime % 60;
		return `${hours > 0 ? `${hours}h ` : ''}${`${min}min `} ${seconds}s`;
	};

	const displayedExercises = assignment.exerciseAndExamResults.filter(
		(item: { subareaId: number }, index: number) => {
			if (!!item && filteredExercises.length) {
				return filteredExercises.indexOf(item.subareaId) !== -1;
			} else return !!item;
		},
	);

	const existingExercises = assignment.exerciseAndExamResults.filter(
		(item: any) => !!item,
	);

	const calculateExerciseAmount = () => {
		let exerciseNo = 0;
		let examNo = 0;
		existingExercises.forEach((item: any) => {
			if (item.type === 'EXERCISE') exerciseNo += 1;
			else examNo += 1;
		});
		return `${exerciseNo} ${
			exerciseNo === 1
				? translate('shared.statistics.exercise')
				: translate('shared.statistics.exercises')
		}, ${examNo} ${
			examNo === 1
				? translate('shared.statistics.exam')
				: translate('shared.statistics.exams')
		}`;
	};

	const exerciseExamFilterOptions: () => any[] = () => {
		const exerciseAndExamResults: {}[] =
			assignment.exerciseAndExamResults.reduce(
				(
					itemsArray: any[],
					item: { subareaId: number; name: string },
				) => {
					const { subareaId, name } = item;
					if (
						!itemsArray?.find(
							(item: { key: number }) => item.key === subareaId,
						)
					) {
						itemsArray.push({
							key: subareaId,
							value: subareaId,
							text: name,
						});
					}
					return itemsArray;
				},
				[],
			);
		return exerciseAndExamResults;
	};

	const getExerciseAndExamValues = () =>
		displayedExercises.map(
			(item: {
				incorrectAnswers: number;
				correctAnswers: number;
				name: string;
				type: ContentTypes;
			}) => {
				if (!!item) {
					return {
						value:
							item.correctAnswers === 0
								? 0
								: item.incorrectAnswers > 0
								? (
										(item.correctAnswers /
											(item.incorrectAnswers +
												item.correctAnswers)) *
										100
								  ).toFixed(0)
								: 100,
						name: item.name,
						type: ContentTypes[item.type],
					};
				}
			},
		);

	return (
		<div className={classes.rowWrap}>
			<ContentCard title={assignment.title}>
				<Grid className={classes.frame}>
					<Grid.Column width={12}>
						{selected === 0 && (
							<>
								<div className={classes.ChartHeader}>
									<GraphHeader
										headline={translate(
											'shared.statistics.learningProgress',
										)}
										subtitle={''}
									/>
								</div>
								<LineChart
									values={[
										{
											name: 'Fortschritt',
											data: courseProgressData,
										},
									]}
									header={
										<GraphHeader
											headline={translate(
												'shared.statistics.learningProgress',
											)}
											subtitle={translate(
												'shared.statistics.last30Days',
											)}
										/>
									}
								/>
							</>
						)}

						{selected === 1 && (
							<>
								<div className={classes.ChartHeader}>
									<GraphHeader
										headline={translate(
											'shared.statistics.readingTime',
										)}
										subtitle={''}
									/>
								</div>
								<BarChart
									values={[
										{
											name: translate(
												'shared.statisticsCourse.readingTimeInMinutes',
											),
											data: Object.keys(
												assignment.readingDoneByDate,
											).map((date: any) => {
												const timeInMinutes = (
													+assignment
														.readingDoneByDate[
														date
													] / 60
												).toFixed(2);
												return {
													x: parse(
														date,
														'yy-MM-dd',
														new Date(),
													),
													y: timeInMinutes,
												};
											}),
										},
									]}
								/>
							</>
						)}

						{selected === 2 && (
							<>
								<div className={classes.ChartHeader}>
									<div>
										<GraphHeader
											headline={translate(
												'shared.statistics.exerciseAndExams',
											)}
											subtitle={calculateExerciseAmount()}
										/>
									</div>
									<div>
										<MultiSelect
											handleChange={(
												newValues: number[],
											) =>
												setFilteredExercises(newValues)
											}
											inputTitle={translate(
												'shared.statistics.exerciseAndExams',
											)}
											modalTitle={translate(
												'shared.statistics.exerciseAndExams',
											)}
											selected={filteredExercises}
											options={exerciseExamFilterOptions()}
											bordered
										/>
									</div>
								</div>
								<ColumnChart
									values={getExerciseAndExamValues()}
								/>
							</>
						)}

						{selected === 3 && endDate && startDate && (
							<DonutChart
								value={7}
								end={endDate}
								start={startDate}
							/>
						)}
					</Grid.Column>
					<Grid.Column className={classes.kpiFrame} width={4}>
						<KpiColumn
							value={
								((!!courseProgressData.length &&
									courseProgressData[
										courseProgressData.length - 1
									]?.y) ||
									0) + '%'
							}
							label={translate(
								'shared.statistics.learningProgress',
							)}
							index={0}
							setSelected={setSelected}
							selected={selected}
							helperText={translate(
								'kpis.userStatistics.learningProgress',
							)}
						/>
						<KpiColumn
							value={calculateReadingDuration()}
							label={translate('shared.statistics.readingTime')}
							index={1}
							setSelected={setSelected}
							selected={selected}
							helperText={translate(
								'kpis.userStatistics.readingTime',
							)}
						/>
						<KpiColumn
							value={existingExercises.length}
							label={translate(
								'shared.statistics.exerciseAndExams',
							)}
							index={2}
							setSelected={setSelected}
							selected={selected}
							helperText={translate(
								'kpis.userStatistics.exerciseExamNo',
							)}
						/>
						<KpiColumn
							value={
								difference > 0
									? difference.toString() +
									  (difference > 1
											? ' ' +
											  translate(
													'shared.statistics.days',
											  )
											: ' ' +
											  translate(
													'shared.statistics.day',
											  ))
									: 'abgelaufen'
							}
							label={translate('shared.statistics.courseEnd')}
							index={3}
							setSelected={setSelected}
							selected={selected}
							helperText={translate(
								'kpis.userStatistics.courseEnd',
							)}
						/>
					</Grid.Column>
				</Grid>
			</ContentCard>
		</div>
	);
};

export default Statistics;
