import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import SelectCourseCards from '../../../components/app/selectCourse';
import { translate } from '../../../i18n/i18n';
import { useHistory } from 'react-router-dom';
import {clearState} from "../../../store/app/appSlice";
import {clearInstitutionState} from "../../../store/institution/institutionSlice";
import {useDispatch} from "react-redux";
import {clearSettingsState} from "../../../store/settings/settingSlice";

const AppSelectCourse: React.FunctionComponent<{ handleNavigate: Function }> =
	({ handleNavigate }) => {
		const classes = useStyles();
		const [isLoading, setIsLoading] = useState(true);
		const history = useHistory();
        const dispatch = useDispatch();
		const logout = () => {
            dispatch({
                ...clearState()
            })
            dispatch({
                ...clearSettingsState()
            })
			window.localStorage.clear();
			history.push('/auth/login');
		};

		return (
			<div className={classes.container}>
				{!isLoading && (
					<div className={classes.textContainer}>
						<div className={classes.header}>
							<h1 className={classes.text}>
								{translate(`app.selectCourse.title`)}
							</h1>
							<span onClick={logout} className={classes.logout}>
								{translate(`app.selectCourse.logout`)}{' '}
							</span>
						</div>
						<p className={classes.text}>
							{translate(`app.selectCourse.description`)}{' '}
						</p>
					</div>
				)}
				<SelectCourseCards
					handleNavigate={handleNavigate}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
				/>
			</div>
		);
	};

export default AppSelectCourse;
