import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	Container: {
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		position: 'fixed',
		background:
			'linear-gradient(236deg, rgba(48,112,237,1) 0%, rgba(31,93,211,1) 100%)',

		'& .app-exercise-loader-content': {
			padding: 20,
			width: '100%',
		},
	},

	BGImage: {
		position: 'absolute',
		right: 0,
		top: 0,
		height: 600,
		maxWidth: 600,
		width: '100%',
		backgroundSize: '80%',
		backgroundPosition: ' right -80px top',
		backgroundRepeat: 'no-repeat',
	},
	Content: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 60,
		alignItems: 'center',
		paddingBottom: 30,
		width: '100%',
		height: '100%',
		color: '#ffffff',
		textAlign: 'center',
		position: 'relative',
		fontSize: textSizes.bodyNormal,

		'& h3': {
			fontSize: textSizes.bodyLarge,
			margin: '12px 0 15px 0',
		},

		'& p': {
			lineHeight: 1.5,
			margin: '0 0 5px 0',
			maxWidth: 350,
		},

		'& svg': {
			color: '#ffffff',
			fontSize: 46,
		},

		'& h4': {
			marginTop: 40,
			fontSize: 16,
			fontWeight: 600,
		},
	},
	FeedbackWrapper: {
		backgroundColor: 'rgba(255,255,255,.06)',
		borderRadius: 5,
		height: 58,
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		lineHeight: '58px',
		padding: '0 20px',
		cursor: 'pointer',
		textAlign: 'center',

		'&:hover': {
			color: '#fff',
			textDecoration: 'underline',
		},
	},
	LogoutLink: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 60,
		cursor: 'pointer',
	},
	BackButton: {
		background: '#fff',
		width: '100%',
		maxWidth: 300,
		marginBottom: 25,
		height: 58,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'rgba(31, 93, 211, 1)',
		borderRadius: 8,
		fontWeight: 600,
		fontSize: textSizes.bodyLarge,
		cursor: 'pointer',
	},
}));
