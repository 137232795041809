export interface RoleModalPropsInterface {
    closeModal: () => void,
    showModal: boolean,
    roleId?: number
}

export interface RoleErrors {
    name?: string;
}

export interface RoleWithPermissionInterface {
    name: string,
    FE_SOM_DASHBOARD: boolean,
    FE_SOM_USERS: boolean,
    FE_SOM_COURSES: boolean,
    FE_SOM_SETTINGS: boolean,
    FE_SOM_INSTITUTIONS: boolean,
}

export const initialRoleValues: RoleWithPermissionInterface = {
    name: "",
    FE_SOM_DASHBOARD: false,
    FE_SOM_USERS: false,
    FE_SOM_COURSES: false,
    FE_SOM_SETTINGS: false,
    FE_SOM_INSTITUTIONS: true
}

export interface RoleInterface {
    name: string,
}
