import React from "react";
import { Icon, Dropdown } from 'semantic-ui-react'
import { useStyles } from "./styles";
import useUserContext from "../../../contexts/UserContext";
import {translate} from "../../../i18n/i18n";

interface IconDropDown {
    icon: string,
    options: ItemOption[]
}
interface ItemOption {
    text: string,
    permission: string,
    onClick: () => void
}

const IconDropDown: React.FunctionComponent<IconDropDown> = ({ icon, options }) => {
    const classes = useStyles();
    const { permissions } = useUserContext();

    const triggerIcon = (
        <span>
           <Icon disabled size='small' name='plus circle' />
        </span>
      )

    return (
        <Dropdown
            trigger={triggerIcon}
            icon={false}
            className='icon'
            >
            <Dropdown.Menu className="add_dropdown">
                <Dropdown.Item className={classes.dropdownItem} disabled>
                    {translate('shared.iconDropdown.createNew')}
                </Dropdown.Item>
                <Dropdown.Divider className={classes.dropdownDivider}/>
                { permissions && permissions.length ?
                <>
                {options.map((option, index) => !!permissions.includes(option.permission) &&
                    <Dropdown.Item key={index} className={classes.dropdownItem} onClick={() => option.onClick()}>
                        { option.text }
                    </Dropdown.Item>
                )}
                </> : <span>{translate('shared.iconDropdown.noPermissions')}</span>
            }

            </Dropdown.Menu>
        </Dropdown>
    )
}

export default IconDropDown;
