import React, {useState} from 'react';
import {ArrowBack} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useStyles} from './style';
import {color as stylesColor} from '../../../utils/styles.js';
import {useSelector} from 'react-redux';
import Button from '../../shared/Button';
import ExerciseCountButton from './components/ExerciseCountButton';
import {courseSelector} from '../../../store/courses/courseSlice';
import {SubAreaInterface} from '../../authoring/Preview';
import {translate} from '../../../i18n/i18n';
import axios from "../../../app/axios";

interface AuthoringNavbar {
    handleNavigate: Function;
    name: string;
    isPublished: boolean;
    handlePublish: Function;
    disableActions: boolean;
    courseId: number;
    selectedSubarea: SubAreaInterface;
}

const AuthoringNavbar: React.FunctionComponent<AuthoringNavbar> = ({
                                                                       handleNavigate,
                                                                       name,
                                                                       isPublished,
                                                                       handlePublish,
                                                                       disableActions,
                                                                       courseId,
                                                                       selectedSubarea,
                                                                   }) => {
    const classes = useStyles();
    const history = useHistory();
    const {questionCount} = useSelector(courseSelector);
    const [isClearingCache, setIsClearningCache] = useState<boolean>(false);

    const calculatePreviewLink = () => {
        let parentId, childId, show;
        if (!selectedSubarea) {
            parentId = -1;
            childId = -1;
            show = 0;
        } else {
            show = 1;
            const {id, sub_area_id, subareas} = selectedSubarea;
            childId = id;
            if (subareas) {
                parentId = id;
            } else {
                parentId = sub_area_id;
            }
            if (!parentId) parentId = -1;
        }
        return `/preview/${courseId}/${parentId}/${childId}?show=${show}&exercise=0&device=mobile&orientation=portrait`;
    };

    const clearCache = async () => {
        setIsClearningCache(true);
        await axios.post(`/clearCache`, {
            id: courseId
        });
        setIsClearningCache(false);
    }

    return (
        <div>
            <div
                style={{
                    borderBottomColor:
                        window.localStorage.getItem('institutionColor')!,
                    zIndex: 9999,
                }}
                className={classes.menuBar}
            >
                <div className={classes.LeftMenu}>

                    <ArrowBack onClick={() => history.push('/courses')}/>
                    <h2 onClick={() => handleNavigate('home')}>
                        {name || 'Titel des Kurses'}
                    </h2>
                </div>
                <div className={classes.RightMenu}>
                    <ExerciseCountButton
                        count={questionCount}
                        handleClick={() =>
                            history.replace(
                                `/authoring/${courseId}/all-questions`,
                            )
                        }
                        disabled={disableActions}
                    />
                    <Button
                        type="outline"
                        color={stylesColor.link}
                        onClick={() =>
                            history.replace(`/authoring/${courseId}/settings`)
                        }
                        icon="settings"
                        iconColor={stylesColor.link}
                        disabled={disableActions}
                        title={translate('layout.authoringNavbar.settings')}
                    />
                    <Button
                        type="outline"
                        color={stylesColor.link}
                        onClick={() =>
                            clearCache()
                        }
                        icon="refresh"
                        iconColor={stylesColor.link}
                        disabled={isClearingCache}
                        title={translate('layout.authoringNavbar.clearCache')}
                    />
                    <Button
                        type="outline"
                        color={stylesColor.link}
                        onClick={() => history.push(calculatePreviewLink())}
                        icon="preview"
                        iconColor={stylesColor.link}
                        disabled={disableActions}
                        title={translate('layout.authoringNavbar.preview')}
                    />
                    <Button
                        title={
                            isPublished
                                ? translate('layout.authoringNavbar.published')
                                : translate('layout.authoringNavbar.publish')
                        }
                        type="outline"
                        color={
                            isPublished ? stylesColor.success : stylesColor.link
                        }
                        onClick={isPublished ? () => {
                        } : handlePublish}
                        styles={
                            isPublished
                                ? {
                                    padding: '8px',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    transitionDuration: '0s',
                                    cursor: 'not-allowed',
                                    marginRight: 0,
                                }
                                : {
                                    fontSize: 14,
                                    fontWeight: 500,
                                    transitionDuration: '0s',
                                    marginRight: 0,
                                }
                        }
                        icon={isPublished ? '' : 'publish'}
                        iconColor={stylesColor.link}
                        disabled={disableActions}
                    />
                </div>
            </div>
        </div>
    );
};

export default AuthoringNavbar;
