import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../pages/auth/Login/loginSlice';
import userReducer from '../store/users/userSlice';
import groupReducer from '../store/groups/groupSlice';
import memberReducer from '../store/members/memberSlice';
import assignmentReducer from '../store/assignments/assignmentSlice';
import roleReducer from '../store/roles/roleSlice';
import institutionReducer from '../store/institution/institutionSlice';
import courseReducer from '../store/courses/courseSlice';
import settingReducer from '../store/settings/settingSlice';
import invoiceReducer from '../store/invoices/invoiceSlice';
import supportReducer from '../store/supports/supportSlice';
import subareaReducer from '../store/subareas/subareaSlice';
import resultReducer from '../store/results/resultSlice';
import questionReducer from '../store/questions/questionSlice';
import trackingReducer from '../store/trackings/trackingSlice';
import licenseReducer from '../store/licenses/licenseSlice';
import appReducer from '../store/app/appSlice';
import requestMiddleware from '../libs/index';
import offlineMiddleware from '../libs/offlineMiddleware';

export const store = configureStore({
	reducer: {
		login: loginReducer,
		user: userReducer,
		member: memberReducer,
		role: roleReducer,
		group: groupReducer,
		institution: institutionReducer,
		assignment: assignmentReducer,
		course: courseReducer,
		setting: settingReducer,
		invoice: invoiceReducer,
		support: supportReducer,
		subarea: subareaReducer,
		result: resultReducer,
		tracking: trackingReducer,
		app: appReducer,
        question: questionReducer,
        license: licenseReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(offlineMiddleware)
			.concat(requestMiddleware),
});

/* const store = configureStore({
	reducer:  {
		login: loginReducer,
	},
	middleware: (getDefaultMiddleware) =>
	  getDefaultMiddleware({
		thunk: {
		  extraArgument: myCustomApiService,
		},
		serializableCheck: false,
	  }),
  }) */

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

/* import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to window.localStorage for web

import { InterfaceAppState, appReducer } from '../modules';
import { authReducer, InterfaceAuthState } from '../modules/auth';
import { InterfaceNotifyState, notifyReducer } from '../modules/notify';
import { InterfaceStudentState, studentReducer } from '../modules/student/store';

const persistConfig = {
	key: 'root',
	timeout: 3000,
	storage,
	blacklist: [],
};

const reducers = persistReducer(
	persistConfig,
	combineReducers({
		app: appReducer,
		auth: authReducer,
		notify: notifyReducer,
		student: studentReducer,
	}),
);

export interface InterfaceStore {
	app: InterfaceAppState;
	auth: InterfaceAuthState;
	notify: InterfaceNotifyState;
	student: InterfaceStudentState;
}

export function configureStore(initialState: any = {}) {
	// Create the store with two middlewares
	const middleWares: any = [thunk];
	const enhancers = compose(applyMiddleware(...middleWares));
	const store = createStore(reducers, { ...initialState }, composeWithDevTools(enhancers));
	// @ts-ignore
	store.asyncReducers = {}; // Async reducer registry
	return {
		store,
		persistor: persistStore(store),
	};
}
 */
