import { makeStyles } from "@material-ui/core/styles";
import {borderRadius, color, textSizes} from "../../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: 32
    },
    small: {
        margin: '0 32px 32px 32px'
    },
}))
