import { QuestionTypes } from '../../../../enums/questions/questions-enums';
import { RenderSingleChoice } from '../PreviewQuestionTypes/RenderSingleChoice';
import { RenderMultipleChoice } from '../PreviewQuestionTypes/RenderMultipleChoice';
import { RenderOrderWords } from '../PreviewQuestionTypes/RenderOrderWords';
import { RenderWordCombinations } from '../PreviewQuestionTypes/RenderWordCombinations';
import { RenderAssignTerms } from '../PreviewQuestionTypes/RenderAssignTerms';
import { RenderGapFills } from '../PreviewQuestionTypes/RenderGapFills';
import React from 'react';
import { SubAreaInterface } from '../index';
import PreviewListItem from '../PreviewListItem';
import {translate} from '../../../../i18n/i18n';

export const PreviewExercises: React.FunctionComponent<{
	questions: any[];
	currentItem: any;
	nextItem: SubAreaInterface | null;
	handleNavigateTo: Function;
	device: string;
}> = ({ questions, currentItem, nextItem, handleNavigateTo, device }) => {
	const switchToNextItem = () => {
		document!
			.querySelector('.preview-window-content-inner')!
			.scrollTo(0, 0);
		handleNavigateTo(
			nextItem!.id,
			!!nextItem!.sub_area_id ? nextItem!.sub_area_id : -1,
		);
	};

	return (
		<div
			className="preview-window-content-inner"
			id="PreviewContentScrollListener"
		>
			<h3>{currentItem?.headline || 'Kein Inhalt ausgewählt'}</h3>
			<div
				id="PreviewContent"
				className="preview-window-content-wysiwyg"
				dangerouslySetInnerHTML={{
					__html: currentItem?.wysiwyg || '',
				}}
			/>
			{!!questions.length ? (
				questions.map((question: any, index: number) => {
					switch (question.type) {
						case QuestionTypes.SINGLE_CHOICE:
							return (
								<RenderSingleChoice
									question={question}
									index={index}
								/>
							);
						case QuestionTypes.MULTIPLE_CHOICE:
							return (
								<RenderMultipleChoice
									question={question}
									index={index}
								/>
							);
						case QuestionTypes.ORDER_WORDS:
							return (
								<RenderOrderWords
									question={question}
									index={index}
									device={device}
								/>
							);
						case QuestionTypes.WORD_COMBINATION:
							return (
								<RenderWordCombinations
									question={question}
									index={index}
									device={device}
								/>
							);
						case QuestionTypes.ASSIGN_TERM:
							return (
								<RenderAssignTerms
									question={question}
									index={index}
									device={device}
                                    correctOrder={question.terms}
								/>
							);
						case QuestionTypes.GAP_FILLS:
							return (
								<RenderGapFills
									question={question}
									index={index}
									device={device}
								/>
							);
						default:
							return <></>;
					}
				})
			) : (
				<></>
			)}
			{nextItem && (
				<div className="preview-window-content-next">
					{translate('authoring.previewExercises.nextContent')}
					<PreviewListItem
						item={nextItem}
						nosubareas
						handleClick={switchToNextItem}
						parentId={null}
						active={false}
					/>
				</div>
			)}
		</div>
	);
};
