import React from 'react';
import { Modal as SUIModal } from 'semantic-ui-react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button';
import { useStyles } from './styles';
import { color } from '../../../utils/styles';

interface ModalProps {
	header: string | JSX.Element;
	showModal: boolean;
	disabled: boolean;
	isLoading?: boolean;
	onSubmit?: Function;
	onClose?: Function;
	submitActionTitle?: string;
	closeActionTitle?: string;
	submitActionColor?: string;
	secondaryAction?: Function;
	secondaryActionTitle?: string;
	secondaryColor?: string;
	noAction?: boolean | null;
	additionalClasses?: string;
	buttonWrapper?: string;
}

const Modal: React.FunctionComponent<ModalProps> = ({
	header,
	secondaryAction,
	secondaryActionTitle,
	secondaryColor,
	showModal,
	onSubmit,
	submitActionTitle,
	submitActionColor,
	closeActionTitle,
	onClose,
	children,
	isLoading,
	disabled,
	noAction,
	additionalClasses,
	buttonWrapper,
}) => {
	const classes = useStyles();
	return (
		<SUIModal
			className={`${additionalClasses} ${classes.Modal}`}
			open={showModal}
			size="small"
			onClose={() => onClose && onClose()}
		>
			<SUIModal.Header className={classes.Header}>
				{header}
				{onClose && (
					<CloseIcon
						className={classes.CloseIcon}
						onClick={() => onClose()}
					/>
				)}
			</SUIModal.Header>
			<SUIModal.Content className={classes.Content}>
				{children}
			</SUIModal.Content>
			{!noAction && (
				<SUIModal.Actions
					className={
						!closeActionTitle
							? classes.noCloseWrapper
							: classes.Actions
					}
				>
					{closeActionTitle && onClose && (
						<Button
							title={closeActionTitle}
							type="secondary"
							color={color.textDark}
							onClick={onClose}
						/>
					)}
					<div className={buttonWrapper || classes.MainButtonWrapper}>
						{secondaryAction && (
							<Button
								title={secondaryActionTitle!}
								isLoading={isLoading}
								disabled={disabled}
								type="outline"
								color={secondaryColor || ''}
								onClick={secondaryAction}
							/>
						)}
						{onSubmit && submitActionColor && (
							<Button
								title={submitActionTitle}
								isLoading={isLoading}
								disabled={disabled}
								type="solid"
								color={submitActionColor}
								onClick={onSubmit}
							/>
						)}
					</div>
				</SUIModal.Actions>
			)}
		</SUIModal>
	);
};

export default Modal;
