import { orderBy } from 'lodash';

interface useTableManageProps {
    sorting: any,
    search: any,
    page: any,
    rowsPerPage: any
}

export const useTableManage = () => {
    const getResult = (tableData:any, {sorting, search, page, rowsPerPage}: useTableManageProps) => {
        
        page--

        const afterSort = handleSorting(tableData, sorting)
        const afterSearch = handleSearch(afterSort, search)
        const afterPaginate = handlePaginate(afterSearch, rowsPerPage, page)
        
        return {result: afterPaginate, total: afterSearch.length}
    }


    const handlePaginate = (result:any, rowsPerPage: any, page: any ) => {
        let start = rowsPerPage * page
        return result.slice(start, start + rowsPerPage)
    }

    const handleSearch = (tableData: any, search: any) => {
        return tableData.filter((data: any) => {
            const values = Object.values(data)
            const findMatch = values.filter((value: any) => value ? value.toString().toLowerCase().includes(search.toLowerCase()) : false)
            
            return findMatch.length > 0
        })
    }

    const handleSorting = (result: any, sorting: any) => {
        return orderBy(result, sorting.column, sorting.desc ? 'desc' : 'asc' )
    }

    return {getResult}
}   
