import React from "react";
import { useHistory } from 'react-router-dom';


const NoneLayout = ({ children }) => {

  return (
    <>
    {children}
    </>
  );
}

export default NoneLayout;
