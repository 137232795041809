import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams  } from 'react-router-dom';
import PreviewHeader from "../components/layout/PreviewHeader";
import Preview from "../components/authoring/Preview";
import axios from "../app/axios";
import LoadingOverlay from "../components/shared/LoadingOverlay";
import {translate} from "../i18n/i18n";


const AuthoringPreview = (props) => {
  const { id, parentid: paramparentid, childid: paramchildid } = useParams();
  const { location, ...history } = useHistory();
  const [params, setParams] = useState("")
  const [course, setCourse] = useState(null);

  // SEARCH PARAM EXTRACTION
  const device = new URLSearchParams(params).get("device");
  const orientation = new URLSearchParams(params).get("orientation");
  const showDetail = !!(+(new URLSearchParams(params).get("show")));
  const inExerciseNav = !!(+(new URLSearchParams(params).get("exercise")));
  const [parentid, setParentId] = useState(paramparentid);
  const [childid, setChildId] = useState(paramchildid);

  useEffect(() => {
      // Show freshdeks button
      var element = document.getElementById("freshdesk");
      element.classList.remove("hide");
      element.innerHTML = translate('help');
  }, []);

  useEffect( () => () => {
      //Unmound hide freshdesk
      var element = document.getElementById("freshdesk");
      document.getElementById("close_freshdesk").click()
      element.classList.add("hide");
  }, [] );

  useEffect(() => {
    if (!window.localStorage.getItem('user') || window.localStorage.getItem('userType') !== 'SOM') {
      history.push('/auth/login');
    }
    fetchContent();
    setParams(new URLSearchParams(location.search).toString());
  }, [])

  useEffect(() => {
    history.replace({ pathname: `/preview/${id}/${parentid}/${childid}`, search: params });
    history.listen((location, action) => {
      if(action === "POP") handleClosePreview();
    })
}, [params, parentid, childid])


  const updateParams = (param, value) => {
    if (param === "exercise" && new URLSearchParams(params).get("exercise") !== value) {
      setParentId(-1);
      setChildId(-1);
    }
    const newParams = new URLSearchParams(params);
    newParams.set(param, value);
    setParams(newParams.toString());
  }

  const fetchContent = async () => {
    try {
      const res = await axios.get(`/courses/${id}`);
      const course = { ...res.data.success };
      const subareas = await axios.get(`/courses/${id}/subarea`);
      course.subareas = [...subareas.data.success];
      course.subareas.map(area => {
        area.subareas = course.subareas.filter(item => item.sub_area_id === area.id);
      })
      setCourse(course);
    } catch (err) {
      console.log(err);
    }
  }

  const handleNavigation = (newParent, newChild) => {
    setParentId(newParent);
    setChildId(newChild);
    if (newParent === +parentid) updateParams("show", 1);
  }

  const handleClosePreview = () => {
    history.push(`/authoring/${id}${childid > -1 ? `/${childid}` : ""}`)
  }


  return (
    <>
     <LoadingOverlay isFetching={!course} />
      <div className="preview-page">
          <PreviewHeader device={device} orientation={orientation} handleClose={handleClosePreview} handleUpdateParams={updateParams}/>
          <div className="preview-wrapper">
            <div className={`preview-window preview-window-${device} preview-window-${orientation} ${!showDetail ? "nav-open" : ""}`}>
            {course && <Preview handleNavigateTo={handleNavigation} device={device} handleUpdateParams={updateParams} parentId={+parentid} childId={+childid} course={course} showDetail={showDetail} inExerciseNav={inExerciseNav}/>}
            </div>
          </div>
      </div>
    </>
  )

}

export default AuthoringPreview;
