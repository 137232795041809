import React from 'react';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@material-ui/core';
import {translate} from '../../../../../i18n/i18n';
interface SplashProps {
	subarea: any;
	questions: number;
	handleNavigate: Function;
	handleStart: Function;
}

const Splash: React.FunctionComponent<SplashProps> = ({
	subarea,
	handleNavigate,
	questions,
	handleStart,
}) => {
	return (
		<div className="app-exercise-loader app-exercise-wrapper">
			<div className="app-exercise-loader-content">
				{' '}
				{subarea.is_exam ? (
					<FontAwesomeIcon
						className="background-icon exam"
						icon={faAward}
					/>
				) : (
					<SvgIcon className="background-icon">
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
				)}
				{subarea.is_exam ? (
					<FontAwesomeIcon
						className="header-icon exam"
						icon={faAward}
					/>
				) : (
					<SvgIcon className="header-icon">
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
				)}
				<h3>{subarea.is_exam ? translate('app.splash.exam') : translate('app.splash.exercise')}</h3>
				<p>{subarea.headline}</p>
				<p>
					{!questions
						? translate('app.splash.loadingQuestion')
						: `${translate('app.splash.exerciseContent')} ${questions} ${translate('app.splash.questnions')}`}
				</p>
			</div>

			<button
				onClick={() => handleStart()}
				className="app-exercise-loader-button"
				disabled={!questions}
			>
				{subarea.is_exam ? translate('app.splash.startExam') : translate('app.splash.startExerise')}
			</button>
			<a
				onClick={() => {
					handleNavigate(null, { navigation: '1' });
				}}
				className="app-exercise-loader-button secondary"
			>
				{translate('app.splash.close')}
			</a>
		</div>
	);
};

export default Splash;
