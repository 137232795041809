import { makeStyles } from "@material-ui/core/styles";
import { textSizes } from "../../../utils/styles"

export const useStyles = makeStyles(theme => ({
    Button: {
        height: "44px",
        borderRadius: "8px",
        padding: "0 23px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: textSizes.bodyNormal,
        transition: ".2s",
        cursor: "pointer",
        border: "1px solid"
    },
    Solid: {
        border: 1
    },
    Secondary: {
        background: "transparent",
        borderColor: "transparent",
    },
    LoadingSpinner: {
        color: "#fff",
        width: "20px !important",
        height: "20px !important",
        marginRight: "10px"
    },
    ButtonDisabled:{
        opacity: ".5",
        cursor: "not-allowed",
    },
    ButtonSecondary:{
        padding: "0",
    },
}))