import React from "react";
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from "./styles";
import {Add, Publish, Settings, Visibility, SubdirectoryArrowRight, Refresh, Save} from "@material-ui/icons";

interface ButtonProps{
    title?: string;
    isLoading?: boolean;
    type: string;
    color: string;
    icon?: string;
    iconColor?: string;
    styles?: Object;
    disabled?: boolean;
    onClick: Function;
    backgroundColor?: string;
    borderColor?: string;
    fluid?: boolean;
}

interface ButtonStyles{
    backgroundColor: string;
    borderColor: string;
    display?: string;
    color: string;
    padding?: string;
    marginLeft?: string;
    marginRight?: string;
    width?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({ title, disabled, isLoading, type, color, borderColor, backgroundColor, styles, onClick, icon, iconColor, fluid }) => {

    const classes = useStyles();

    const buttonClasses = [classes.Button, disabled || isLoading ? classes.ButtonDisabled : "", type === "secondary" ? classes.ButtonSecondary : ""];

    let buttonStyle: ButtonStyles = {
        backgroundColor: "transparent",
        borderColor: "transparent",
        color: "",
        display: "inline"
    }

    switch (type) {
        case "solid":
            buttonStyle.backgroundColor = color;
            buttonStyle.borderColor = color;
            buttonStyle.color = "#ffffff";
            break;
        case "outline":
            buttonStyle.backgroundColor = backgroundColor || "transparent";
            buttonStyle.borderColor = borderColor || color;
            buttonStyle.color = color;
            break;
        case "secondary":
            buttonStyle.color = color;
            break;
        case "blank":
            buttonStyle.color = color;
            buttonStyle.borderColor = "transparent";
            break;
        case "solid-fluid":
                buttonStyle.backgroundColor = color;
                buttonStyle.borderColor = color;
                buttonStyle.color = "#ffffff";
                buttonStyle.width = "100%";
            break;
    }

    let iconComponent: JSX.Element | null = null;

    if (icon) {
        const iconStyles = { color: iconColor, marginRight: title ? "10px" : 0 };
        switch (icon) {
            case "add":
                iconComponent = <Add style={iconStyles} />
                break;
            case "import":
                iconComponent = <Publish style={iconStyles} />
                break;
             case "settings":
                iconComponent = <Settings style={iconStyles} />
                break;
            case "publish":
                iconComponent = <Publish style={iconStyles} />
                break;
             case "preview":
                iconComponent = <Visibility style={iconStyles} />
                break;
            case "subdirectory":
                iconComponent = <SubdirectoryArrowRight style={iconStyles} />
                break;
            case "refresh":
                iconComponent = <Refresh style={iconStyles} />
                break;
            case "save":
                iconComponent = <Save style={iconStyles} />
                break;
        }

    }
    return <IconButton disableRipple style={{ ...buttonStyle, ...styles }} className={buttonClasses.join(" ")} onClick={() => onClick()} disabled={isLoading || disabled}>{!!iconComponent && iconComponent}{isLoading && type !== "secondary" && <CircularProgress className={classes.LoadingSpinner} style={{color: type === "outline" ? color : "#fff"}}/>} {title}</IconButton>
}

export default Button;
