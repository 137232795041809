import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { ArrowBackIos } from '@material-ui/icons';
import { format, parse } from 'date-fns';
import { editUser } from '../../../../store/users/userSlice';

import axios from '../../../../app/axios';
import ContentCard from '../../../../components/shared/ContentCard';
import Subpage from '../../../../components/shared/Subpage';
import ContentCardNumber from '../../../../components/shared/ContentCardNumber';
import {
	fetchGroup,
	fetchGroupKPIs,
	fetchGroups,
	groupSelector,
	resetGroupDetailData,
} from '../../../../store/groups/groupSlice';
import { useStyles } from './style';
import Avatar from '../../../../components/shared/Avatar';
import Button from '../../../../components/shared/Button';
import { color } from '../../../../utils/styles';
import TableAction from '../../../shared/TableAction';
import Table from '../../../shared/Table';
import DeleteModal from '../../../modals/DeleteModal';
import { useSorting } from '../../../../helpers/useSorting';
import { MissedDeadlineTypes } from '../../../../enums/assignments/assignments-enums';
import { useMissedDeadlines } from '../../../../helpers/useMissedDeadlines/useMissedDeadlines';
import DuoBar from '../../../shared/Statistics/DuoBar';
import { translate } from '../../../../i18n/i18n';
import { AddUserToGroupModal } from '../../../modals/AddUserToGroupModal';
import EditGroupModal from '../../../modals/EditGroupModal';

interface GroupDetailPageParams {
	id: string;
}

export default function GroupsDetailPage() {
	const [isFetching, setIsFetching] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const params: GroupDetailPageParams = useParams();
	const classes = useStyles();
	const {
		data,
		currentGroup: group,
		isLoading,
		currentKPIs: kpis,
	} = useSelector(groupSelector);
	console.log(group, isLoading, kpis);
	const { sorting, handleChangeSorting } = useSorting();
	const [showAddUsersModal, setShowAddUsersModal] = useState(false);
	const [triggerFetchGroups, setTriggerFetchGroups] = useState(false);
	const [userDeleteModal, setUserDeleteModal] = useState(false);
	const [userTableData, setUserTableData] = useState([]);
	const [assignmentTableData, setAssignmentTableData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [selectedUser, setSelectedUser] = useState<{
		id: number;
		name: string;
		groups: any;
	} | null>(null);

	const dispatch = useDispatch();
	const { goBack, push } = useHistory();
	const { MissedDeadlineDropdown, missedDeadline } = useMissedDeadlines();

	useEffect(() => {
		dispatch({
			...fetchGroupKPIs({
				requestType: 'GET',
				request: `smartometer/dashboardKpis?courseProgressGroupId[]=${params.id}`,
			}),
		});
		return () => {
			dispatch(resetGroupDetailData());
		};
	}, []);

	useEffect(() => {
		fetchGroupUsers();
	}, [sorting, missedDeadline, dispatch, triggerFetchGroups, data]);

	useEffect(() => {
		dispatch({
			...fetchGroup({
				requestType: 'GET',
				request: `smartometer/groups/${params.id}?sort=id&type=asc`,
			}),
		});
	}, [triggerFetchGroups, data]);

	const fetchGroupUsers = async () => {
		setIsFetchingData(true);
		const res = await axios.get(`/groups/${params.id}?sort=${
			sorting.column
		}&type=${sorting.desc ? `desc` : `asc`}
                    ${
						missedDeadline !== MissedDeadlineTypes.ALL
							? `&missed_deadline=${missedDeadline}`
							: ''
					}`);
		if (res.data.success) {
			setUserTableData(res.data.success[0].users);
			setAssignmentTableData(res.data.success[0].group_assignments);
			setIsFetchingData(false);
		}
	};

	const handleDeleteGroup = async () => {
		setIsFetching(true);
		try {
			await axios.delete(`/groups/${params.id}`);
			dispatch({
				...fetchGroups({
					requestType: 'GET',
					request: 'smartometer/groups',
				}),
			});
			goBack();
		} catch (err) {
			console.log(err);
		}
		setShowDeleteModal(false);
		setIsFetching(false);
	};
	const groupTableData = {
		avatar: {
			title: '',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Avatar src={item.avatar} />
			),
		},
		name: {
			title: 'Name',
			sorting: 'first_name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() => push(`/users/detail/${item.id}`)}
				>
					{item.first_name} {item.last_name}
				</p>
			),
		},
		deadlineMissed: {
			title: '',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				return !!item.assignments.filter(
					(assignment: any, index: number) => {
						const deadline = parse(
							assignment.pivot.end,
							'yyyy-MM-dd',
							new Date(),
						);
						return (
							assignment.pivot.is_finished === 0 &&
							deadline.getTime() < new Date().getTime()
						);
					},
				).length ? (
					<span className={classes.missedDeadlineDot}></span>
				) : (
					<></>
				);
			},
		},
		group: {
			title: translate('tabs.groupOverviewTabDetail.progress'),
			align: 'center',
			renderType: (item: any): JSX.Element => <p>--</p>,
		},
		delete: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() =>
						setUserToDelete(item.id, item.first_name, item.groups)
					}
					tooltip={translate('tooltip.delete')}
					type="delete"
				/>
			),
		},
	};

	const groupAssignmentTableData = {
		name: {
			title: 'Kurs',
			align: 'left',
			renderType: (item: any): JSX.Element => <p>{item.product.name}</p>,
		},
		start: {
			title: 'Start',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p>
					{item.start &&
						format(
							parse(item.start, 'yyyy-mm-dd', new Date()),
							'dd.mm.yyyy',
						)}
				</p>
			),
		},
		end: {
			title: 'Ende',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				return (
					<p>
						{item.end &&
							format(
								parse(item.end, 'yyyy-mm-dd', new Date()),
								'dd.mm.yyyy',
							)}
					</p>
				);
			},
		},
	};

	const setUserToDelete = (id: number, name: string, groups: any) => {
		setUserDeleteModal(true);
		setSelectedUser({ id, name, groups });
	};

	const handleDetachUser = () => {
		const id = selectedUser ? selectedUser.id : null;
		let groups = selectedUser ? selectedUser.groups : null;

		if (id && groups && groups.length) {
			const userGroups = groups.map((group: any) => group.id);
			const newGroups = userGroups.filter(
				(g: any) => g !== parseInt(params.id),
			);
			dispatch({
				...editUser({
					requestType: 'PUT',
					body: {
						groups: newGroups,
					},
					request: `/smartometer/users/${id}`,
				}),
			});
		}
		setTimeout(function () {
			setTriggerFetchGroups(!triggerFetchGroups);
		}, 500);

		setUserDeleteModal(false);
		setSelectedUser(null);
	};

	const calculateCourseProgress = () => {
		const courseProgressValues = Object.keys(kpis.courseProgress);
		if (!courseProgressValues.length) return 0;
		const lastData =
			kpis.courseProgress[
				courseProgressValues[courseProgressValues.length - 1]
			];
		return lastData.toFixed(2) + ' %';
	};

	const handleSubmitBulkAssignUserToGroup = async (users: number[]) => {
		setIsFetching(true);
		try {
			await axios.post('/users/addGroups', {
				users,
				groups: [params.id],
			});
			setIsFetching(false);
			setShowAddUsersModal(false);
			setTriggerFetchGroups(!triggerFetchGroups);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<Subpage
				headerComponent={
					<div className={classes.Header}>
						<p className={classes.BackButton} onClick={goBack}>
							<ArrowBackIos />
							{group?.name}
						</p>
						<div className={classes.ButtonWrapper}>
							<Button
								type="outline"
								styles={{ marginRight: 12 }}
								onClick={() => setShowEditModal(true)}
								color={color.link}
								title={translate(
									`tabs.groupOverviewTabDetail.editGroup`,
								)}
							/>
							<Button
								type="outline"
								styles={{ marginRight: 12 }}
								onClick={() => setShowAddUsersModal(true)}
								color={color.link}
								title={translate(`users.groups.assignUsers`)}
							/>
							<Button
								title={translate(
									'tabs.groupOverviewTabDetail.deleteGroup',
								)}
								type="outline"
								color={color.error}
								backgroundColor={color.background}
								borderColor={color.error}
								onClick={() => setShowDeleteModal(true)}
							/>
						</div>
					</div>
				}
				isLoading={!group || !kpis}
			>
				{!!group && !!kpis && (
					<Grid>
						<Grid.Row>
							<Grid.Column width={4}>
								<ContentCard
									title={translate(
										'tabs.groupOverviewTabDetail.amountUsers',
									)}
									small
								>
									<ContentCardNumber
										value={group!.users.length}
									/>
								</ContentCard>
							</Grid.Column>
							<Grid.Column width={4}>
								<ContentCard
									title={translate(
										'tabs.groupOverviewTabDetail.courseProgress',
									)}
									small
								>
									<ContentCardNumber
										value={calculateCourseProgress()}
									/>
								</ContentCard>
							</Grid.Column>
							<Grid.Column width={4}>
								<ContentCard
									title={translate(
										'tabs.groupOverviewTabDetail.finishedCourse',
									)}
									small
								>
									<div className={classes.BarContainer}>
										<DuoBar
											percentage={
												group &&
												!!group?.finished_courses &&
												!!group?.unfinished_courses
													? +(
															(group.unfinished_courses /
																(group.finished_courses +
																	group.unfinished_courses)) *
															100
													  ).toFixed(2)
													: 0
											}
											leftTitle={
												group && group.finished_courses
													? `${
															group.finished_courses
													  } ${translate(
															'tabs.groupOverviewTabDetail.finished',
													  )}`
													: ''
											}
											rightTitle={`${
												group.unfinished_courses
											} ${translate(
												'tabs.groupOverviewTabDetail.assigned',
											)}`}
										/>
									</div>
								</ContentCard>
							</Grid.Column>
							<Grid.Column width={4}>
								<ContentCard
									title={translate(
										'tabs.groupOverviewTabDetail.delays',
									)}
									small
								>
									<ContentCardNumber value={group.delays} />
								</ContentCard>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginBottom: 50 }}>
							<Grid.Column width={16}>
								<ContentCard
									title={
										group.users.length
											? group!.name
											: `${group!.name}: ${translate(
													'tabs.groupOverviewTabDetail.NoUserAssigned',
											  )}.`
									}
									headerComponent={<MissedDeadlineDropdown />}
								>
									{userTableData ? (
										<Table
											showHeader
											rows={groupTableData}
											loading={isFetchingData}
											data={userTableData}
											handleChangeSorting={
												handleChangeSorting
											}
											sorting={sorting}
										/>
									) : null}
								</ContentCard>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginBottom: 50 }}>
							<Grid.Column width={16}>
								<ContentCard title={'Kurszuweisungen'}>
									{assignmentTableData ? (
										<Table
											showHeader
											rows={groupAssignmentTableData}
											loading={isFetchingData}
											data={assignmentTableData}
										/>
									) : null}
								</ContentCard>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}
			</Subpage>
			<DeleteModal
				additionalInfo={translate(
					'tabs.groupOverviewTabDetail.deleteGroupMessage',
				)}
				isLoading={isFetching}
				showModal={!!showDeleteModal}
				closeModal={() => setShowDeleteModal(false)}
				onDelete={handleDeleteGroup}
				confirmText={translate(
					'tabs.groupOverviewTabDetail.deleteGroup',
				)}
				message={`${translate(
					'tabs.groupOverviewTabDetail.deleteGroupConfirm',
				)} <b>'${group?.name}'</b> ${translate(
					'tabs.groupOverviewTabDetail.deleteGroupConfirmEnd',
				)}`}
			/>
			<EditGroupModal
				showModal={showEditModal}
				closeModal={() => setShowEditModal(false)}
				selectedGroup={group!}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={!!selectedUser && userDeleteModal}
				closeModal={() => setUserDeleteModal(false)}
				onDelete={handleDetachUser}
				confirmText={translate(
					'tabs.groupOverviewTabDetail.detachUser',
				)}
				message={`${translate(
					'tabs.groupOverviewTabDetail.detachConfirm',
				)} <b>'${selectedUser?.name}'</b> ${translate(
					'tabs.groupOverviewTabDetail.detachConfirmEnd',
				)}`}
			></DeleteModal>
			<AddUserToGroupModal
				currentUsers={
					!!group
						? group.users.map((user: { id: number }) => user.id)
						: null
				}
				isLoading={isFetching}
				showModal={showAddUsersModal}
				closeModal={() => setShowAddUsersModal(false)}
				onSubmit={handleSubmitBulkAssignUserToGroup}
			/>
		</>
	);
}
