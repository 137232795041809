import React, {useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {useStyles} from "./styles"
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {createRole} from "../../../store/roles/roleSlice";
import {FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import {Toggle} from "../../shared/Toggle";
import {translate} from "../../../i18n/i18n";
import {
    initialRoleValues,
    RoleErrors,
    RoleModalPropsInterface,
    RoleWithPermissionInterface
} from "../../../interfaces/roles-interface";


export const AddRoleModal: React.FunctionComponent<RoleModalPropsInterface> = ({showModal, closeModal}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = (values: RoleWithPermissionInterface) => {
        setIsSubmitting(true);
        dispatch({
            ...createRole({
                requestType: "POST",
                body: {...values},
                request: `/smartometer/roles`,
            }),
        });
        closeModal();
    };

    const onValidate = (val: any) => {
        const errors: RoleErrors = {}
        if (!val.name) errors.name = "Feld wird benötigt";
        return errors;
    }

    return (
        <>
            <Modal showModal={showModal} header={translate(`settings.team.roles.modal.headline`)} onClose={closeModal}
                   onSubmit={handleFormikSubmit} isLoading={isSubmitting} submitActionTitle={translate(`save`)}
                   closeActionTitle={translate(`cancel`)} submitActionColor={color.link} disabled={isSubmitting}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={initialRoleValues}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched}) => (
                        <FormGroup>
                            <TextField className={classes.Input} value={values.name}
                                       placeholder={translate(`settings.team.roles.modal.namePlaceholder`)}
                                       onChange={handleChange} name="name" variant="outlined"/>

                            <Typography className={classes.title} component="h1" variant="h4">{translate('modals.addRoleModal.releaseRights')}</Typography>

                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_DASHBOARD} name="FE_SOM_DASHBOARD"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_DASHBOARD`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_USERS} name="FE_SOM_USERS"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_USERS`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_COURSES} name="FE_SOM_COURSES"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_COURSES`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_SETTINGS} name="FE_SOM_SETTINGS"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_SETTINGS`)}
                            />
                        </FormGroup>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

