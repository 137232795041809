import React from 'react';
import { Grid } from 'semantic-ui-react';
import HelpText from '../../HelpText';
import { useStyles } from './styles';

interface KpiColumnProps {
	label: string;
	value: string;
	index: number;
	setSelected: Function;
	selected: number;
	helperText?: string;
}

export const KpiColumn: React.FunctionComponent<KpiColumnProps> = ({
	label,
	value,
	index,
	setSelected,
	selected,
	helperText,
}) => {
	const classes = useStyles();
	return (
		<Grid.Row className={classes.blockRow}>
			<Grid.Column
				onClick={() => setSelected(index)}
				width={16}
				className={`${
					selected === index && `${classes.active} col-active`
				} ${classes.block}`}
			>
				<label className={classes.label}>{label}</label>
				<div className={classes.kpi}>{value}</div>
				{!!helperText && (
					<HelpText text={helperText!} position="right" />
				)}
			</Grid.Column>
		</Grid.Row>
	);
};
