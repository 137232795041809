import { Close, ArrowBack, Info, InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { appSelector } from '../../../store/app/appSlice';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';

interface AppHeaderProps {
	handleNavigate: Function;
	currentCourseSubareas: any;
	contentId: number;
}

const AppHeader: React.FunctionComponent<AppHeaderProps> = ({
	handleNavigate,
	currentCourseSubareas,
	contentId,
}) => {
	const { page, subpage } = useParams<{ page: string; subpage: string }>();
	const { currentCourse } = useSelector(appSelector);
	let currentSubarea = [] as any;

	if (subpage && currentCourseSubareas) {
		const result = currentCourseSubareas.find(
			(subarea: any) => subarea.id === parseInt(subpage),
		);
		currentSubarea = result;
	}

	const getHeaderTitle = () => {
		switch (page) {
			case 'user':
				return <h3>{translate('app.header.personalArea')}</h3>;
			case 'course':
				return <h3>{translate('app.header.courses')}</h3>;
			case 'learn':
				let icon = (
					<InfoOutlined
						onClick={() =>
							handleNavigate(null, {
								navigation: '0',
								content: '-1',
							})
						}
					/>
				);
				let headline = <h3>{currentCourse?.name}</h3>;
				let subheadline = null;
				if (!!subpage) {
					const redirectLink =
						currentSubarea && currentSubarea.sub_area_id
							? `/app/learn/${currentSubarea.sub_area_id}`
							: '/app/learn/';
					icon = (
						<ArrowBack
							onClick={() =>
								handleNavigate(redirectLink, {
									content: contentId,
									navigation: '1',
								})
							}
						/>
					);
					const parentSubarea = currentSubarea.sub_area_id
						? currentCourseSubareas.find(
								(item: any) =>
									item.id === currentSubarea.sub_area_id,
						  ).headline
						: currentCourse?.name;
					subheadline = <h4>{parentSubarea}</h4>;
					headline = <h3>{currentSubarea?.headline}</h3>;
				}

				return (
					<>
						{icon}{' '}
						<div className="preview-window-navigation-header-headlines">
							{subheadline}
							{headline}
						</div>
					</>
				);
			case 'exercise':
				return <h3>{translate('app.header.practice')}</h3>;
		}
	};
	return currentCourseSubareas ? (
		<div
			className="preview-window-navigation-header app-navigation-header"
			style={{
				borderBottomColor:
					window.localStorage.getItem('institutionColor') || color.link,
			}}
		>
			<div className="preview-window-navigation-header-left">
				{getHeaderTitle()}
			</div>
			<Close
				onClick={() => handleNavigate(null, { navigation: '0' })}
				className="hide-desktop"
			/>
		</div>
	) : null;
};

export default AppHeader;
