import { makeStyles } from '@material-ui/core/styles';
import { color, borderRadius } from '../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	Card: {
		backgroundColor: color.white,
		borderRadius: borderRadius.medium,
		border: `1px solid ${color.borderGrey}`,
		width: '100%',
		height: '100%',
	},
	CardTiny: {
		padding: '20px 32px !important',
	},
	CardSmall: {
		padding: '20px 32px !important',
	},
	CardHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '32px',
	},
	CardHeadlineSmall: {
		fontFamily: 'Open Sans, sans-serif',
		fontSize: '18px',
		display: 'flex',
		alignItems: 'center',
		paddingRight: 40,
		fontWeight: 600,
		lineHeight: '24px',
		marginBottom: 0,
	},
	CardHeadline: {
		fontFamily: 'Open Sans, sans-serif',
		fontSize: '18px',
		display: 'flex',
		alignItems: 'center',
		paddingRight: 40,
		fontWeight: 600,
		lineHeight: '24px',
		marginBottom: 0,
	},
}));
