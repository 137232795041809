import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface AppState {
	currentCourse: null | {
		product_id: number;
        assignment_id: number;
		id: number;
		name: string;
		image: string;
		description: string;
		learning_areas: { id: number; name: string }[];
	};
	currentCourseSubareas: any;
	navigation: 0 | 1;
	currentCourseId: null | number;
	contentId: number;
	showCourseFinished: boolean;
	allCourses:
		| null
		| {
				is_finished: number;
				start: string;
				end: string;
				product_name: string;
                product_id: number;
				id: number;
		  }[];
	courseKPIs: null | {
		usage: number;
		passedScore: number;
		passedCourses: {}[];
		assignedCourses: {}[];
		delayedAssignments: {}[];
		data: {};
	};
	isFetchingCourseForApp: boolean;
	customFontSize: number;
}

const initialState: AppState = {
	currentCourse: null,
	currentCourseSubareas: null,
	navigation: 0,
	currentCourseId: null,
	contentId: -1,
	allCourses: null,
	courseKPIs: null,
	showCourseFinished: false,
	isFetchingCourseForApp: false,
	customFontSize: 1,
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		fetchCurrentCourse: (state, action: any) => {
			if (action.state === 'send') {
				state.currentCourse = null;
				state.currentCourseSubareas = null;
				state.isFetchingCourseForApp = true;
			}
			if (action.state === 'finished' && action.response.success) {
				window.localStorage.setItem(
					'current_course',
					JSON.stringify(action.response.success),
				);
				state.currentCourse = action.response.success;
			}
		},
        setCurrentCourseSubareas: (state, action) => {
            state.currentCourseSubareas = action.payload.subareas;
        },
		fetchCurrentCourseSubareas: (state, action: any) => {
			if (action.state === 'send') {
				state.isFetchingCourseForApp = true;
			}
			if (action.state === 'finished' && action.response.success) {
				let subareas = action.response.success as any;
				subareas.map((area: any) => {
					area.subareas = subareas.filter(
						(item: any) => item.sub_area_id === area.id,
					);
				});
				// window.localStorage.setItem(
				// 	'current_course_subareas',
				// 	JSON.stringify(subareas),
				// );
				state.currentCourseSubareas = subareas;
				const availableTypes = {
					exam: false,
					exercise: false,
					content: false,
				};

				const unfinishedContent: {
					exam: number[];
					exercise: number[];
					content: number[];
				} = {
					exam: [],
					exercise: [],
					content: [],
				};
				subareas.map(
					(subarea: {
						id: number;
						is_exam: 0 | 1;
						is_exercise: 0 | 1;
						is_finished: boolean;
					}) => {
						const type = subarea.is_exam
							? 'exam'
							: subarea.is_exercise
							? 'exercise'
							: 'content';
						if (!availableTypes[type]) availableTypes[type] = true;
						if (!subarea.is_finished)
							unfinishedContent[type].push(subarea.id);
					},
				);

				const pendingSubareas = availableTypes.exam
					? unfinishedContent.exam
					: availableTypes.exercise
					? unfinishedContent.exercise
					: unfinishedContent.content;
				localStorage.setItem(
					'subareas_pending',
					JSON.stringify(pendingSubareas),
				);
				if (!pendingSubareas.length) {
					localStorage.setItem('success_shown', 'true');
				} else {
					localStorage.setItem('success_shown', 'false');
				}
				state.isFetchingCourseForApp = false;
			}
		},
		updateParam: (
			state: any,
			action: { payload: { type: string; value: number } },
		) => {
			const { type, value } = action.payload;
			state[type] = value;
		},
		setCourseId: (state: any, action: { payload: number }) => {
			window.localStorage.setItem(
				'current_course_id',
				action.payload.toString(),
			);
			state.currentCourseId = action.payload;
		},
		setCourse: (state, action: any) => {
			state.currentCourse = action.payload;
		},
		setAllCourses: (state, action: any) => {
			window.localStorage.setItem(
				'all_courses',
				JSON.stringify(action.payload),
			);
			state.allCourses = action.payload;
		},
		setCourseKPIs: (state, action: any) => {
			// window.localStorage.setItem(
			// 	'course_kpis',
			// 	JSON.stringify(action.payload),
			// );
			state.courseKPIs = action.payload;
		},
		setCourseSubareas: (state, action: any) => {
			state.currentCourseSubareas = action.payload;
		},
		setShowFinishCourse: (state, action: any) => {
			const hasShown = window.localStorage.getItem('success_shown');
			if (action.payload) {
				if (hasShown !== 'true') {
					state.showCourseFinished = true;
				}
			} else {
				state.showCourseFinished = false;
				window.localStorage.setItem('success_shown', 'true');
			}
		},
		updateCourseSubareas: (state, action: any) => {
			// window.localStorage.setItem(
			// 	'current_course_subareas',
			// 	JSON.stringify(action.payload),
			// );
			state.currentCourseSubareas = action.payload;
		},
		setCustomFontSize: (state, action: { payload: number }) => {
			window.localStorage.setItem(
				'custom_font_size',
				action.payload.toString(),
			);
			state.customFontSize = action.payload;
		},
        clearState: (state: AppState) => {
            state = initialState;
        }
	},
});

export default appSlice.reducer;
export const appSelector = (state: RootState) => state.app;
export const {
    setCurrentCourseSubareas,
	fetchCurrentCourse,
	setCourse,
	setCourseId,
	setAllCourses,
	setCourseKPIs,
	fetchCurrentCourseSubareas,
	setCourseSubareas,
	updateCourseSubareas,
	setShowFinishCourse,
	setCustomFontSize,
    clearState,
} = appSlice.actions;
