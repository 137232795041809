export enum QuestionTypes {
    SINGLE_CHOICE = "SINGLE_CHOICE",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    ORDER_WORDS = "ORDER_WORDS",
    WORD_COMBINATION = "WORD_COMBINATION",
    ASSIGN_TERM = "ASSIGN_TERM",
    GAP_FILLS = "GAP_FILLS"
}

export enum questionBulkFunction {
    DELETE = "DELETE"
}