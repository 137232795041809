import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelf, userSelector } from '../store/users/userSlice';
import { UserContext } from './UserContext';
import { loginSelector } from '../pages/auth/Login/loginSlice';
import { settingSelector } from '../store/settings/settingSlice';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
	children: React.ReactNode;
};

const PageContextProvider = ({ children }: Props) => {
	const [user, setUser] = React.useState<any>(null);
	const [permissions, setPermissions] = React.useState<any>(null);
	const { isLoading, myUser, notAuthenticated } = useSelector(userSelector);
	const { isLoading: isLoginLoading } = useSelector(loginSelector);
	const { isLoading: settingsIsLoading } = useSelector(settingSelector);
	const trialEndAt: Date | null = user?.institution?.trial_end_at
		? new Date(user.institution.trial_end_at)
		: null;

	const history = useHistory();
	const { pathname } = useLocation();
	const askedForDemoData: boolean =
		user?.institution && user.institution.asked_for_demo_data === 0
			? false
			: true;
	const askedToRemoveDemoData: boolean =
		user?.institution && user.institution.asked_to_remove_demo_data === 0
			? false
			: true;
	const demoDataAdded: boolean = user?.institution?.demo_data_added
		? user.institution.demo_data_added
		: null;
	const isTrial = trialEndAt && trialEndAt > new Date();
	const paidPeriodStartAt = user?.institution?.paid_period_start_at
		? new Date(user?.institution?.paid_period_start_at)
		: null;
	const showBanner = isTrial || !paidPeriodStartAt;
	const isUnpaid =
		(user?.institution && !isTrial && !paidPeriodStartAt) || false;



	const dispatch = useDispatch();

	React.useEffect(() => {
		if (!isLoginLoading && !notAuthenticated && !pathname.includes('auth')) {
			dispatch({
				...fetchSelf({
					requestType: 'GET',
					request: `smartometer/users/me`,
				}),
			});
		}
	}, [isLoginLoading, settingsIsLoading, notAuthenticated, pathname]);

	const triggerRefetch = () => {
        dispatch({
            ...fetchSelf({
                requestType: 'GET',
                request: `smartometer/users/me`,
            }),
        });
    }

	React.useEffect(() => {
		if (!isLoading && myUser) {
			setUser(myUser);
		}
	}, [isLoading, myUser]);

	React.useEffect(() => {
		const myPermissions = user?.role?.map((role: any) => role.name);
		setPermissions(myPermissions);
        if(user?.institution) {
            window.localStorage.setItem(
                'institutionLogo',
                user.institution.logo,
            );
            window.localStorage.setItem(
                'institutionColor',
                user.institution.color,
            );
            window.localStorage.setItem(
                'institutionFavicon',
                user.institution.favicon,
            );
        }
	}, [user]);

	return (
		<UserContext.Provider
			value={{
				user,
				permissions,
				showBanner,
				isTrial,
				trialEndAt,
				paidPeriodStartAt,
				isUnpaid,
				askedForDemoData,
				demoDataAdded,
				askedToRemoveDemoData,
				notAuthenticated,
                triggerRefetch
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
export default PageContextProvider;
