import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useUserContext from '../contexts/UserContext';
import { getPlatforms } from '@ionic/react';
import Blocker from '../pages/Blocker';

interface PrivateRouteProps extends RouteProps {
	// tslint:disable-next-line:no-any
	component: any;
	desktop?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
	const { component: Component, ...rest } = props;
	const { user, permissions } = useUserContext();
    return (
		<Route
			{...rest}
			render={(routeProps) => {
              	const matchingPermissions = permissions?.map(
					(permission: any) =>
						permission
							.split('_')
							[permission.split('_').length - 1].toLowerCase(),
				);
              	if (
					matchingPermissions &&
					matchingPermissions.indexOf('courses') > -1
				) {
					matchingPermissions.push('authoring');
				}

                if (
                    matchingPermissions &&
                    matchingPermissions.indexOf('settings') > -1 &&
                    user.institution.id === 1

                ) {
                    matchingPermissions.push('institutions');
                }

				let allowed = matchingPermissions
					? matchingPermissions.find((permission: string) => {
							return (
								routeProps.match.path.replace('/', '') ===
								permission
							);
					  })
					: true;

				if (
					(routeProps.match.path.indexOf('authoring') != -1 ||
						routeProps.match.path.indexOf('preview') != -1) &&
					matchingPermissions &&
					matchingPermissions.includes('authoring')
				) {
					allowed = true;
				}
				if (
					routeProps.match.path.indexOf('app') != -1 ||
					routeProps.match.path.indexOf('/auth/') != -1
				) {
					allowed = true;
				}

				if (
					user &&
					user.isEndUser === 1 &&
					allowed &&
					getPlatforms().includes('mobile') &&
					rest.desktop
				) {
					return (
						<Redirect
							to={{
								pathname: '/app/learn',
								state: { from: routeProps.location },
							}}
						/>
					);
				} else if (
					user &&
					!!Object.keys(user).length &&
					allowed &&
					getPlatforms().includes('mobile') &&
					!getPlatforms().includes('mobileweb') &&
					rest.desktop
				) {
					return <Blocker />;
				}

				return allowed ? (
					<Component {...routeProps} />
				) : (
					<Redirect
						to={{
							pathname: '/dashboard',
							state: { from: routeProps.location },
						}}
					/>
				);
			}}
		/>
	);
};

export default PrivateRoute;
