import React from "react";
import { useStyles } from "./style";
import {Edit, MailOutline, Delete, TrendingUp, FilterList, Archive, Unarchive, LockRounded, Refresh} from '@material-ui/icons';
import {SvgIcon} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

import filterIcon from '../../../assets/images/filterIcon.svg'

const FilterIcon = () => <img src={filterIcon} />;

const ActionComponents: any = {
    edit: Edit,
    mail: MailOutline,
    delete: Delete,
    chart: TrendingUp,
    filter: FilterIcon,
    archive: Archive,
    unarchive: Unarchive,
    resetPassword: LockRounded,
    Refresh: Refresh
}

interface TableActionProps{
    type: string;
    tooltip?: string;
    handleClick: Function;
}

const TableAction: React.FunctionComponent<TableActionProps> = ({ type, handleClick, tooltip }) => {
    const classes = useStyles();
    const Component = ActionComponents[type];
    return (
        <>
            {tooltip &&
            <Tooltip title={tooltip}>
                <Component className={classes.Icon} onClick={handleClick} />
            </Tooltip>}
            {!tooltip && <Component className={classes.Icon} onClick={handleClick} />}
        </>
    )
}

export default TableAction;
