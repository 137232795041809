import { makeStyles } from "@material-ui/core/styles";
import { color, textSizes } from "../../../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    ColorFieldWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 18,
        height: 44,
    },
    ColorField: {
        cursor: "pointer",
        width: 38,
        height: 16,
        marginBottom: 4,
    },
    ColorLabel: {
        color: color.textGrey,
        fontSize: textSizes.notice,

        "&.active": {
            color: color.link
        }
    }
}))
