import React, {useEffect, useRef, useState} from 'react'
import Modal from '../../shared/Modal';
import {Formik} from "formik";
import {editRole, fetchRole, RoleInterface, roleSelector} from "../../../store/roles/roleSlice";
import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "./styles";
import {translate} from "../../../i18n/i18n";
import {color} from "../../../utils/styles";
import {FormControlLabel, FormGroup, TextField, Typography} from "@material-ui/core";
import {Toggle} from "../../shared/Toggle";
import {RoleErrors, RoleModalPropsInterface} from "../../../interfaces/roles-interface";

export const EditRoleModal: React.FunctionComponent<RoleModalPropsInterface> = ({showModal, closeModal, roleId}) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {role} = useSelector(roleSelector);
    const formikRef: any = useRef();
    const classes = useStyles();

    useEffect(() => {
        dispatch({
            ...fetchRole({
                requestType: "GET",
                request: `smartometer/roles/${roleId}`,
            }),
        });
    }, [roleId])

    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = (values: RoleInterface) => {
        setIsSubmitting(true);
        dispatch({
            ...editRole({
                requestType: "PUT",
                body: values,
                request: `/smartometer/roles/${roleId}`,
            }),
        });
        closeModal();
    };

    const onValidate = (val: any) => {
        const errors: RoleErrors = {}
        if (!val.name) errors.name = translate('modals.editRoleModal.nameRequired');
        return errors;
    }

    return (
        <> {role.id && (
            <Modal showModal={showModal} header={translate(`settings.team.roles.modal.headline`)} onClose={closeModal}
                   onSubmit={handleFormikSubmit} isLoading={isSubmitting} submitActionTitle={translate(`save`)}
                   closeActionTitle={translate(`cancel`)} submitActionColor={color.link} disabled={isSubmitting}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={role}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched}) => (
                        <FormGroup>
                            <TextField className={classes.Input} value={values.name} onChange={handleChange} name="name"
                                       variant="outlined"/>

                            <Typography className={classes.title} component="h1" variant="h4">{translate('modals.editRoleModal.title')}</Typography>


                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_DASHBOARD} name="FE_SOM_DASHBOARD"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_DASHBOARD`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_USERS} name="FE_SOM_USERS"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_USERS`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_COURSES} name="FE_SOM_COURSES"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_COURSES`)}
                            />
                            <FormControlLabel
                                className={classes.Label}
                                control={<Toggle checked={values.FE_SOM_SETTINGS} name="FE_SOM_SETTINGS"
                                                 onChange={handleChange}/>}
                                label={translate(`settings.team.roles.PERMISSION.FE_SOM_SETTINGS`)}
                            />
                        </FormGroup>
                    )}
                </Formik>
            </Modal>
        )}
        </>
    )
}


