import React, {useEffect, useRef, useState} from 'react';

import {useStyles} from "./styles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import {translate} from "../../../i18n/i18n";
import axios from "../../../app/axios";
import {groupBy} from "lodash";
import {useParams} from "react-router";
import {fetchCourseQuestionCount} from "../../../store/courses/courseSlice";
import {useDispatch} from "react-redux";
import {fetchAll} from "../../../store/questions/questionSlice";


interface AddFromQuestionPoolModalProps {
    showModal: boolean,
    closeModal: () => void,
    subAreaId: number
}

interface ExerciseInterface {
    headline: string;
    id?: string;
    is_exercise?: string;
    is_published: number;
    order: number;
    sub_area_id: number;
}

interface QuestionInterface {
    type?: string;
    question?: string;
    is_exercisable?: string;
}

const AddFromQuestionPoolModal: React.FunctionComponent<AddFromQuestionPoolModalProps> = ({
                                                                                              showModal,
                                                                                              closeModal,
                                                                                              subAreaId
                                                                                          }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { courseId, page } = useParams<{ page: string; courseId: string }>();
    const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [questionPoolSearch, setQuestionPoolSearch] = useState('');
    const [questionPoolShowAll, setQuestionPoolShowAll] = useState(false);
    const [questions, setQuestions] = useState<any>([]);

    let searchTimeout: any = useRef();

    useEffect(() => {
        setSelectedAnswers([])
    }, [questions]);


    const handleSearchChange =(e: React.ChangeEvent<HTMLInputElement>) => {
        setQuestionPoolSearch(
            e.target.value,
        )
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let mySelectedAnswers = selectedAnswers

        if (event.target.checked) {
            mySelectedAnswers.push(event.target.value)
        } else {
            const selectedID = mySelectedAnswers.indexOf(event.target.value)
            mySelectedAnswers.splice(selectedID, 1);
        }
        setSelectedAnswers(mySelectedAnswers)
    };

    useEffect(() => {
        if(showModal) {
            if(searchTimeout.current) {
                clearTimeout(searchTimeout.current)
            }
            searchTimeout.current = setTimeout( ()=> {
                fetchAllQuestions();
            }, 250)

        }
    }, [questionPoolSearch, showAll, showModal]);

    const fetchAllQuestions = async () => {
        setIsLoading(true);

        let showOption = 'format';
        if (showAll) {
            showOption = 'all';
        }
        const formatedQuestions = await axios.get(
            `/courses/${courseId}/questions-${showOption}?subareaId=${page}&search=${questionPoolSearch}`,
        );
        const result = formatedQuestions.data.success;

        const groupResult = groupBy(result, 'course_id') as any;

        const groupSubAreas = Object.keys(groupResult).map((course: any) => {
            const subAreas = groupBy(groupResult[course], 'sub_area_id') as any;

            return {
                course_name: groupResult[course][0].course_name,
                subareas: Object.keys(subAreas).map((subarea: any) => {
                    return {
                        subarea_name: subAreas[subarea][0].sub_area_name,
                        questions: subAreas[subarea],
                    };
                }),
            };
        });
        setQuestions(groupSubAreas);
        setIsLoading(false);
    }

    const handleSubmitBulkQuestions = async (selectedAnswers: any) => {
        await axios
            .post(`/subarea/${subAreaId}/questions-bulk`, {
                questions: selectedAnswers,
            })
            .then((response: any) => {
                dispatch({
                    ...fetchCourseQuestionCount({
                        requestType: 'GET',
                        request: `smartometer/courses/${courseId}`,
                    }),
                });
                dispatch({
                    ...fetchAll({
                        requestType: 'GET',
                        request: `smartometer/subarea/${subAreaId}/questions?page=1&limit=${10}&type=asc`,
                    }),
                });
                closeModal()
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    return (<>

        <Modal showModal={showModal} header={''} onClose={() => {
            closeModal()
            setSelectedAnswers([])
        }}
               onSubmit={() => handleSubmitBulkQuestions(selectedAnswers)}
               isLoading={isLoading}
               submitActionTitle={translate('modals.addFromQuestionPoolModal.primaryAction')}
               secondaryActionTitle={translate('modals.addFromQuestionPoolModal.secondaryAction')}
               closeActionTitle={translate('modals.addFromQuestionPoolModal.close')}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               buttonWrapper=''
        >
            <h2 className={classes.header}>Aufgabe in ‘Prüfung’ übernehmen</h2>
            <FormControlLabel
                control={<Switch checked={showAll} onChange={(e) => {
                    setShowAll(!showAll)
                }
                }
                                 name="showAll"/>}
                label={showAll ? translate('modals.addFromQuestionPoolModal.questionsInstitution') : translate('modals.addFromQuestionPoolModal.questionCurrentCourse')}
            />
            <TextField
                className={classes.searchBar}
                id="search-pool"
                label="Aufgabe, Single-Choice, Zuordnung von Paaren,..."
                onChange={handleSearchChange}
                variant="outlined"
            />

            {isLoading && <CircularProgress/>}
            {!isLoading && <>
                {!!questions.length &&
                <div className={classes.questionBox}>
                    {!!questions.length && questions.map((course: any, index: number) => {
                        return (<div key={index}>
                            <h2 className={classes.courseHeader}> {course.course_name} </h2>
                            {!!course.subareas.length && course.subareas.map((subarea: any, index: number) => {
                                return (<div key={index}>

                                    <h4 className={classes.subheader}> {subarea.subarea_name ? subarea.subarea_name : translate('modals.addFromQuestionPoolModal.withoutSubarea')} </h4>
                                    <FormGroup>
                                        {!!subarea.questions.length && subarea.questions.map((question: any, index:number) => {

                                            return (
                                                <FormControlLabel key={index}
                                                    control={
                                                    <Checkbox
                                                        disabled={question.disabled}
                                                        color="primary"
                                                        value={question.id}
                                                        onChange={(event) => handleCheckboxChange(event)}
                                                    />}
                                                    label={question.question}
                                                />
                                            );
                                        })}
                                    </FormGroup>

                                </div>);
                            })}
                        </div>);
                    })}
                </div>}
            </>}
        </Modal>
    </>);
};

export default AddFromQuestionPoolModal;
