import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type Institution = {
    id: number;
    institution_name: string;
    first_name: string;
    last_name: string;
    vat_id: string;
    tax_id: string;
    created_at: string;
    contact_email: string;
    trial_end_at: string | null;
    paid_period_start_at: string | null;
    users_count: number;
    appusage: number
}

interface InstitutionInitialState {
	isFetching: boolean;
    institutions?: Institution[];
	logo: string;
	color: string;
	favicon: string;
	hasError: string | null;
}

const initialState: InstitutionInitialState = {
	logo: window.localStorage.getItem('institutionLogo') || '',
	color: window.localStorage.getItem('institutionColor') || '',
	favicon: window.localStorage.getItem('institutionFavicon') || '',
	isFetching: false,
	hasError: null,
};

export const institutionSlice = createSlice({
	name: 'institution',
	initialState,
	reducers: {
		fetchInstitution: (state, action: any) => {
			state.isFetching = true;
			if (action.state === 'finished' && !!action.response.success) {
				const { color, logo, favicon } = action.response.success;
				state.color = color;
				state.logo = logo;
				state.favicon = favicon;
			} else if (action.state === 'error') {
				state.isFetching = false;
				state.hasError = 'Request error';
			}
		},
        fetchInstitutions: (state, action: any) => {
            state.isFetching = true;
            if (action.state === 'finished' && !!action.response.success) {
                const institutions = action.response.success;
                state.institutions = institutions;
            } else if (action.state === 'error') {
                state.isFetching = false;
                state.hasError = 'Request error';
            }
        },
        clearInstitutionState: (state: InstitutionInitialState) => {
            state = initialState;
        }
	},
});

export const { fetchInstitution, fetchInstitutions, clearInstitutionState } = institutionSlice.actions;
export const institutionSelector = (state: RootState) => state.institution;
export default institutionSlice.reducer;
