import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from 'semantic-ui-react';
import { Storage } from '@material-ui/icons';
import ContentCard from '../../components/shared/ContentCard';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';

import {
	dashboard,
	filteredDashboard,
	resultSelector,
} from '../../store/results/resultSlice';
import { groupSelector } from '../../store/groups/groupSlice';
import { courseSelector, fetchAll } from '../../store/courses/courseSlice';

import { PieChart } from '../../components/shared/Statistics/PieChart';
import { LineChart } from '../../components/shared/Statistics/LineChart';
import { ContentTypes } from '../../enums/courses/courses-enums';
import { ColumnChart } from '../../components/shared/Statistics/ColumnChart';
import { BarChart } from '../../components/shared/Statistics/BarChart';
import Table from '../../components/shared/Table';
import { getGroupsForDropdown } from '../../helpers/Groups';
import { getCoursesForDropdown } from '../../helpers/Courses';
import DashboardFilter from '../../components/shared/DashboardFilter';
import LoadingOverlay from '../../components/shared/LoadingOverlay';
import { translate } from '../../i18n/i18n';
import { settingSelector } from '../../store/settings/settingSlice';
import Button from '../../components/shared/Button';
import { color } from '../../utils/styles';
import { set } from 'lodash';

export default function Dashboard() {
	const [progress, setProgress] = useState<any[]>();
	const userRowData = {
		name: {
			title: 'Name',
			align: 'left',
			renderType: (item: any): JSX.Element => <p>{item.name}</p>,
		},
		total: {
			title: 'total',
			align: 'left',
			renderType: (item: any): JSX.Element => <p>{item.total}</p>,
		},
	};

	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		data,
		filteredData,
		isLoadingFiltered,
		initiallyLoaded,
		isLoading,
	} = useSelector(resultSelector);

	const { data: groupData }: any = useSelector(groupSelector);
	const { data: courseData, isLoading: coursesAreLoading }: any =
		useSelector(courseSelector);
	const { isLoading: settingsLoading }: any = useSelector(settingSelector);
	const allGroups = getGroupsForDropdown(groupData);
	const allCourses = getCoursesForDropdown(courseData);

	const [filterValues, setFilterValues] = useState<any>({
		groups: [] as number[],
		courses: [] as number[],
	});

	const [timeFilter, setTimeFilter] = useState('all');

	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
	const [loadDashboard, setLoadDashboard] = useState<boolean>(false);
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
		null,
	);
	useEffect(() => {
		if (!settingsLoading && !isLoading && loadDashboard) {
			dispatch({
				...dashboard({
					requestType: 'GET',
					request: `smartometer/dashboardKpis`,
				}),
			});
		} else if (isLoading && loadDashboard) {
			setLoadDashboard(false);
		}
	}, [settingsLoading, loadDashboard]);
	useEffect(() => {
		if (
			!settingsLoading &&
			!isLoadingFiltered &&
			(filterValues.groups.length ||
				filterValues.courses.length ||
				selectedEndDate !== null ||
				selectedStartDate !== null)
		) {
			dispatch({
				...filteredDashboard({
					requestType: 'GET',
					request: `smartometer/dashboardKpis?${
						filterValues.groups.length > 0
							? filterValues.groups
									.map(
										(group: any) =>
											`courseProgressGroupId[]=${group}`,
									)
									?.join('&')
							: ''
					}${
						filterValues.courses.length > 0
							? `&` +
							  filterValues.courses
									.map(
										(course: any) =>
											`courseProgressProductId[]=${course}`,
									)
									?.join('&')
							: ''
					}${
						selectedStartDate
							? `&start=${format(
									selectedStartDate,
									'yyyy-MM-dd',
							  )}`
							: ''
					}${
						selectedEndDate
							? `&end=${format(selectedEndDate, 'yyyy-MM-dd')}`
							: ''
					}`,
				}),
			});
		}
	}, [filterValues, selectedEndDate, selectedStartDate, settingsLoading]);

	useEffect(() => {
		if (!courseData && !coursesAreLoading) {
			dispatch({
				...fetchAll({
					requestType: 'GET',
					request: `smartometer/courses?page=1&limit=100&sort=id&type=desc&published=1`,
				}),
			});
		}
	}, []);

	useEffect(() => {
		if (filteredData?.courseProgress) {
			const ys = Object.keys(filteredData.courseProgress);
			const progress = ys.map((dates: string) => {
				return {
					x: parse(dates, 'yy-MM-dd', new Date()),
					y: filteredData.courseProgress[dates].toFixed(2),
				};
			});
			setProgress([
				{
					name: translate(`kpis.dashboard.learningProgress`),
					type: 'area',
					data: progress,
				},
			]);
		}
	}, [filteredData, setProgress]);

	const handleSetFilterValues = (values: any, name: string) => {
		let localFilterValues = { ...filterValues, [name]: values };
		setFilterValues(localFilterValues);
	};

	const calculatePercentage = (devices: any, current: number) => {
		let sum = 0;
		devices.forEach((device: any) => {
			sum = sum + device.total;
		});
		return Math.round((current / sum) * 100);
	};

	const handleSetDate = (date: Date | null, type: string) => {
		if (type === 'end') {
			setSelectedEndDate(date);
		} else setSelectedStartDate(date);
	};
	const filterOptions = [
		{
			type: 'hasMany',
			name: 'groups',
			filterTitle: translate('shared.dashboardFilter.filterByGroup'),
			placeholder: translate('shared.dashboardFilter.groups'),
			value: filterValues.groups,
			previewValue: filterValues.groups,
			options: allGroups,
		},
		{
			type: 'hasMany',
			name: 'courses',
			filterTitle: translate('shared.dashboardFilter.filterByCourse'),
			placeholder: translate('shared.dashboardFilter.courses'),
			value: filterValues.courses,
			previewValue: filterValues.courses,
			options: allCourses,
		},
	];
	return (
		<div style={{ padding: '96px 30px 100px' }}>
			<LoadingOverlay
				opaque
				isFetching={!initiallyLoaded && loadDashboard}
			/>
			<Typography
				style={{ paddingBottom: 32 }}
				variant="h4"
				component="h4"
			>
				{translate(`layout.mainSidebar.overview`)}
				<Button
					title={translate('Load Dashboard')}
					type="solid-fluid"
					styles={{
						borderRadius: 5,
						width: '200px',
						height: 36,
						marginLeft: 10,
					}}
					disabled={isLoading}
					onClick={() => setLoadDashboard(!loadDashboard)}
					isLoading={isLoading}
					color={color.link}
				/>
			</Typography>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(`kpis.dashboard.usersAmountTitle`)}
							small
							helperText={translate(`kpis.dashboard.usersAmount`)}
						>
							<p className={classes.kpi}>{data?.endUsers}</p>
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(`kpis.dashboard.deviceUsageTitle`)}
							small
							helperText={translate(`kpis.dashboard.deviceUsage`)}
						>
							{data && (
								<PieChart
									values={data.userDevices.map(
										(type: any) => type.total,
									)}
									labels={data.userDevices.map(
										(type: any) =>
											`${
												type.device_type
											} ${calculatePercentage(
												data.userDevices,
												type.total,
											)}%`,
									)}
								/>
							)}
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(`kpis.dashboard.allCoursesTitle`)}
							small
							helperText={translate(`kpis.dashboard.allCourses`)}
							helperTextRight
						>
							<p className={classes.kpi}>
								{data?.overallCourses}
							</p>
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(`kpis.dashboard.delaysTitle`)}
							small
							helperText={translate(`kpis.dashboard.delays`)}
							helperTextRight
						>
							<p className={classes.kpi}>{data?.delays}</p>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<DashboardFilter
							timeFilter={timeFilter}
							setTimeFilter={setTimeFilter}
							handleSetDate={handleSetDate}
							selectedEndDate={selectedEndDate}
							selectedStartDate={selectedStartDate}
							options={filterOptions}
							setFilterValues={handleSetFilterValues}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={8}>
						<ContentCard
							title={translate(
								`kpis.dashboard.courseProgressTitle`,
							)}
							helperText={translate(
								`kpis.dashboard.courseProgress`,
							)}
						>
							{filteredData && progress && (
								<LineChart values={progress} small={true} />
							)}
							{(!filteredData ||
								!progress ||
								!progress[0].data.length) && (
								<div className={classes.noDataWrapper}>
									<Storage />
									<span>
										{translate(
											'layout.mainNavbar.noDataFound',
										)}
									</span>
								</div>
							)}
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={8}>
						<ContentCard
							title={translate(`kpis.dashboard.successRateTitle`)}
							helperText={translate(`kpis.dashboard.successRate`)}
						>
							{filteredData?.passRate && (
								<ColumnChart
									values={Object.keys(filteredData.passRate)
										.filter(
											(name: string, index: number) =>
												index < 4,
										)
										.map((name: string, index: number) => {
											return {
												value: filteredData.passRate[
													name
												].toFixed(2),
												name,
												type: ContentTypes.EXERCISE,
											};
										})}
								/>
							)}
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={8}>
						<ContentCard
							title={translate(
								`kpis.dashboard.popularCoursesTitle`,
							)}
							helperText={translate(
								`kpis.dashboard.popularCourses`,
							)}
						>
							{filteredData?.mostBookedCourses && (
								<Table
									rows={userRowData}
									data={filteredData?.mostBookedCourses.filter(
										(obj: any, index: number) => index < 5,
									)}
								/>
							)}
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={8}>
						<ContentCard
							title={translate(`kpis.dashboard.loginsTitle`)}
							helperText={translate(`kpis.dashboard.logins`)}
						>
							{filteredData?.loginsPerDay && (
								<BarChart
									small={true}
									values={[
										{
											name: translate(
												`kpis.dashboard.loginsTitle`,
											),
											data: filteredData.loginsPerDay.map(
												(login: any, index: number) => {
													return {
														x: parse(
															login.date,
															'yyyy-MM-dd',
															new Date(),
														),
														y: login.views,
													};
												},
											),
										},
									]}
								/>
							)}
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}
