import {translate} from '../../i18n/i18n';


export const getGlobalSearch = () => {
    return [
        {
            search: ["Dashboard", "Statistik", "smartOmeter Kurse", "Lernfortschritt", "Frist", "Kursfortschritt", "Anmeldungen"],
            type: "ALL_DASHBOARD",
            name: translate('helpers.globalSearch.dashboard'),
            redirect: "/dashboard"
        },
        {
            search: ["Nutzer", "Statistik", "Nutzer anlegen / hinzufügen / erstellen"],
            type: "ALL_USERS",
            name: translate('helpers.globalSearch.userOverview'),
            redirect: "/users"
        },
        {
            search: ["Gruppe", "Gruppe anlegen / hinzufügen / erstellen", "Statistik"],
            type: "ALL_GROUPS",
            name: translate('helpers.globalSearch.groupOverview'),
            redirect: "/users/groups"
        },
        {
            search: ["Kurs zuteilen", "Kurszuteilung"],
            type: "ALL_ASSIGNMENTS",
            name: translate('helpers.globalSearch.assignmentOverview'),
            redirect: "/users/assignments"
        },
        {
            search: ["Kursübersicht", "Kurs", "Kurs bearbeiten"],
            type: "ALL_COURSES",
            name: translate('helpers.globalSearch.courses'),
            redirect: "/courses"
        },
        {
            search: ["smartOmeter Kurse", "Externer Kurs", "Gebuchte Kurse"],
            type: "ALL_APPUCATIONS_COURSES",
            name: translate('helpers.globalSearch.appucationCourses'),
            redirect: "/courses/coursesBooked"
        },
        {
            search: ["Shop", "Kurs buchen"],
            type: "SHOP",
            name: translate('helpers.globalSearch.shop'),
            redirect: "/courses/coursesShop"
        },
        {
            search: ["Kurs anlegen", "Kurs erstellen", "Kurs bearbeiten", "Autorentool"],
            type: "AUTHORINGTOOL",
            name: "Autorentool",
            redirect: "/authoring"
        },
        {
            search: ["Trainingsseite", "URL", "Firma", "Unternehmen", "(Firmen)Adresse", "Rechnungsadresse", "Ansprechpartner", "Feedback E-Mail", "Einstellungen"],
            type: "SETTINGS_GENERAL",
            name: translate('helpers.globalSearch.general'),
            redirect: "/settings"
        },
        {
            search: ["Design", "Darstellung", "Farbschema", "Farbe", "Logo", "Favicon"],
            type: "SETTINGS_PRESENTATION",
            name: translate('helpers.globalSearch.cisualSettings'),
            redirect: "/settings/presentation"
        },
        {
            search: ["Teammitglieder", "Team", "smartOmeter Nutzer", "Nutzer", "Mitarbeiter"],
            type: "ALL_TEAM_MEMBERS",
            name: translate('helpers.globalSearch.team'),
            redirect: "/settings/team"
        },
        {
            search: ["Rolle", "Zugriff"],
            type: "ALL_TEAM_ROLES",
            name: translate('helpers.globalSearch.roles'),
            redirect: "/settings/team/roles"
        },
        {
            search: ["Zertifikatsdesign", "Zertifikatsvorlage", "Zertifikat"],
            type: "ALL_CERTIFICATES",
            name: translate('helpers.globalSearch.invoices'),
            redirect: "/settings/certificate"
        },
        {
            search: ["Rechnung", "Abrechnung"],
            type: "ALL_INVOICES",
            name: translate('helpers.globalSearch.invoices'),
            redirect: "/settings/invoice"
        },
    ]
}