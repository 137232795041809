import React, {useEffect, useRef, useState} from 'react';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Editor from '../../../components/shared/Editor';
import AddFromQuestionPoolModal from '../../../components/modals/AddFromQuestionPoolModal';
import AuthoringSubnavigation from '../../../components/layout/AuthoringSubnavigation';
import {useDispatch, useSelector} from 'react-redux';
import {useStyles} from './styles';
import Button from '../../../components/shared/Button';
import {color} from '../../../utils/styles';
import ExerciseList from '../../../components/authoring/ExerciseList';
import QuestionDetails from '../../../components/authoring/QuestionDetails/QuestionDetails';
import {fetchSubareas, subAreaSelector, updateSubArea,} from '../../../store/subareas/subareaSlice';
import DeleteModal from '../../../components/modals/DeleteModal';
import DynamicIcon from '../../../components/shared/DynamicIcon';
import {useParams} from 'react-router';
import {translate} from '../../../i18n/i18n';
import {CircularProgress} from '@material-ui/core';

interface SectionPageProps {
    handleCreateSubarea: (id: number) => {};
}

const SectionPage: React.FunctionComponent<SectionPageProps> = ({
                                                                    handleCreateSubarea,
                                                                }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [modal, setModal] = useState('');
    const [createQuestion, setCreateQuestion] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(0);
    const [editorState, setEditorState] = useState<string | null>(null);
    const [savedEditorState, setSavedEditorState] = useState<string | null>(null);
    const [openQuestionPoolModal, setOpenQuestionPoolModal] =
        useState<boolean>(false);

    const [triggerTable, setTriggerTable] = useState(false);
    const [editorLoading, setEditorLoading] = useState(true);
    const [deleteQuestions, setDeleteQuestions] = useState<boolean>(false);

    const {isLoading, isFetching, data, isFirst} = useSelector(subAreaSelector);
    const {courseId, page} = useParams<{ page: string; courseId: string }>();
    const [selectedSubarea, setSelectedSubarea] = useState(
        data.find((x: any) => x.id == page) || null,
    );
    const [headline, setHeadline] = useState(
        selectedSubarea ? selectedSubarea.headline : '',
    );
    const timeoutFnRef = useRef<any>(null);

    useEffect(() => {
        if (!isLoading && !isFirst) {
            dispatch({
                ...fetchSubareas({
                    requestType: 'GET',
                    request: `smartometer/courses/${courseId}/subarea`,
                }),
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (!isFetching) {
            setTimeout(function () {
                setEditorLoading(false)
            }, 500);
        }
    }, [isFetching]);

    useEffect(() => {
        const subarea = data.find((x: any) => x.id == page) || null;
        if (subarea) {
            setSelectedSubarea(subarea);
        }
    }, [page, data]);

    const handleBlur = (e: any) => {
        const {name} = e.target;
        switch (name) {
            case 'section-title':
                return handleUpdateSubarea(
                    selectedSubarea ? selectedSubarea.id : null,
                    'headline',
                    headline,
                );
            default:
                break;
        }
    };

    const handleChangeEditor = (event: any, editor: any) => {
        const data = editor.getData();
        setSavedEditorState(data);
    };

    const handleSaveEditor = async () => {
        setEditorLoading(true);
        await handleUpdateSubarea(selectedSubarea!.id, 'wysiwyg', savedEditorState);
        setEditorLoading(false);
    };

    const handleSave = () => {
        setCreateQuestion(false);
    };

    const handleAbort = () => {
        setCreateQuestion(false);
    };

    const handleSelectEdit = (id: number, name: string) => {
        setCreateQuestion(true);
        setSelectedQuestionId(id);
    };

    const handleDeleteSubarea = async () => {
        if (page && courseId) {
            dispatch({
                ...updateSubArea({
                    requestType: 'DELETE',
                    request: `smartometer/courses/${courseId}/subarea/${page}/${deleteQuestions.toString()}`,
                    data: {
                        id: page,
                    }
                }),
            })
            setModal('');
        }
    };

    const handleDuplicateSubarea = async () => {
        if (page && courseId) {
            dispatch({
                ...updateSubArea({
                    requestType: 'POST',
                    request: `smartometer/courses/${courseId}/subarea-duplicate`,
                    data: {
                        id: page,
                    }
                }),
            })
        }
    };

    const handleUpdateSubarea = async (id: number, type: any, val: any) => {
        if (page && courseId) {
            dispatch({
                ...updateSubArea({
                    requestType: 'PUT',
                    request: `smartometer/courses/${courseId}/subarea/${id}`,
                    body: {
                        [type]: val,
                    }
                }),
            })
        }
    };

    const handleOpenQuestionPool = () => {
        setOpenQuestionPoolModal(true);
    };

    const handleCloseModal = () => {
        setOpenQuestionPoolModal(false);
    };

    useEffect(() => {

        if (!!selectedSubarea) {

            if (!!timeoutFnRef.current) {
                clearTimeout(timeoutFnRef.current);
            }
            setEditorLoading(true)
            setHeadline(selectedSubarea.headline || '');
            if (!selectedSubarea.is_exercise) {
                if (!!selectedSubarea.wysiwyg) {
                    timeoutFnRef.current = setTimeout(setEditorState.bind(null, selectedSubarea.wysiwyg), 100);
                } else {
                    timeoutFnRef.current = setTimeout(setEditorState.bind(null, '<p></p>'), 100);
                }
                setEditorLoading(false)
            } else {
                setEditorState('');
                setEditorLoading(false)
            }
        }
    }, [selectedSubarea]);


    const handleAddToThisExam = () => {
        dispatch({
            ...updateSubArea({
                requestType: 'PUT',
                request: `smartometer/courses/${courseId}/subarea/assign_questions_to_exam/${selectedSubarea.id}`,
            }),
        })
    }

    const handleDeleteQuestions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteQuestions(event?.target?.checked);
    }

    return (
        <>
            {selectedSubarea && (
                <div className={classes.SectionPage}>
                    <AuthoringSubnavigation
                        id={selectedSubarea.id}
                        handleDuplicateArea={() => {
                            handleDuplicateSubarea();
                        }}
                        handleDeleteSubarea={() => {
                            setModal('deleteSubarea');
                        }}
                        handleAddChildarea={() => {
                            handleCreateSubarea(+page);
                        }}
                        isPublished={selectedSubarea.is_published}
                        isExercise={selectedSubarea.is_exercise}
                        isExam={selectedSubarea.is_exam}
                        handleChange={handleUpdateSubarea}
                    />
                    <div className="coursewizard-input-wrapper">
                        <label
                            className="coursewizard-label"
                            htmlFor="section-title"
                        >
                            {!selectedSubarea.is_exercise && (
                                <DynamicIcon color="black" type="contentIcon"/>
                            )}
                            {!!selectedSubarea.is_exercise &&
                            !selectedSubarea.is_exam && (
                                <DynamicIcon
                                    color="black"
                                    type="exerciseIcon"
                                />
                            )}
                            {!!selectedSubarea.is_exercise &&
                            !!selectedSubarea.is_exam && (
                                <DynamicIcon
                                    color="black"
                                    type="awardIcon"
                                />
                            )}
                        </label>
                        <input
                            className={classes.TitleInput}
                            name="section-title"
                            type="text"
                            value={headline}
                            placeholder={translate('authoring.sectionPage.section')}
                            onChange={(e) => setHeadline(e.target.value)}
                            onBlur={handleBlur}
                        />
                    </div>
                    {selectedSubarea && !selectedSubarea.is_exercise ? (
                        <>
                            {(isLoading || isFetching) &&
                            <div className={classes.SubmitSpinner}>
                                <CircularProgress/>
                            </div>
                            }
                            <div style={{display: editorLoading ? 'none' : 'inline'}}>
                                <div className="coursewizard-input-wrapper wysiwyg">
                                    <label
                                        className="coursewizard-label wysiwyg-open"
                                        htmlFor="section-content"
                                    >
                                        {translate('authoring.sectionPage.content')}
                                    </label>
                                    {editorState && (
                                        <div className={`${classes.editorWrapper} ${isLoading || isFetching ? 'disabled' : ''}`}>
                                            <Editor
                                                key={selectedSubarea.id}
                                                htmlContent={editorState}
                                                handleChange={handleChangeEditor}
                                                handleBlur={(data, editor) => {}}
                                                handleReady={() => setEditorLoading(false)}
                                            />
                                            <Button
                                                title={translate('authoring.sectionPage.save')}
                                                type="solid"
                                                color={color.link}
                                                onClick={async () => {
                                                    await handleSaveEditor()
                                                }}
                                                disabled={editorState === savedEditorState || savedEditorState === null || isLoading || isFetching}
                                                icon="save"
                                                styles={{marginTop: 12, marginRight: 12, marginBottom: 12}}
                                                iconColor={color.white}
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>
                        </>
                    ) : (
                        <>

                            <div className="coursewizard-input-wrapper exercise-wrapper">
                                {!createQuestion && (
                                    <label
                                        className="coursewizard-label"
                                        htmlFor="course-description"
                                    >
                                        {translate('authoring.sectionPage.task')}
                                    </label>
                                )}
                                <div style={{width: '100%'}}>
                                    {!createQuestion && (
                                        <div className={classes.ButtonRow}>
                                            <Button
                                                title={translate('authoring.sectionPage.createNewTask')}
                                                type="solid"
                                                color={color.link}
                                                onClick={() => {
                                                    setCreateQuestion(true);
                                                    setSelectedQuestionId(0);
                                                }}
                                                icon="add"
                                                styles={{marginRight: 12, marginBottom: 12}}
                                                iconColor={color.white}
                                            />
                                            <Button
                                                title={translate('authoring.sectionPage.addExistingTask')}
                                                type="outline"
                                                color={color.link}
                                                styles={{marginRight: 12, marginBottom: 12}}
                                                onClick={() =>
                                                    handleOpenQuestionPool()
                                                }
                                            />
                                            {selectedSubarea && selectedSubarea.is_exam ? <Button
                                                title={translate('authoring.sectionPage.addToThisExam')}
                                                type="outline"
                                                color={color.link}
                                                styles={{marginRight: 12, marginBottom: 12}}
                                                onClick={() =>
                                                    handleAddToThisExam()
                                                }
                                            /> : ''}

                                        </div>
                                    )}

                                    {createQuestion && (
                                        <QuestionDetails
                                            onSave={handleSave}
                                            onAbort={handleAbort}
                                            courseId={+courseId}
                                            selectedSubAreaId={
                                                selectedSubarea.id
                                            }
                                            selectedQuestionId={
                                                selectedQuestionId
                                            }
                                        />
                                    )}
                                    {!createQuestion && (isLoading || isFetching) &&
                                    <div className={classes.SubmitSpinner}>
                                        <CircularProgress/>
                                    </div>
                                    }
                                    {!createQuestion && (
                                        <ExerciseList
                                            subAreaId={selectedSubarea.id}
                                            selectToEdit={handleSelectEdit}
                                            triggerTable={triggerTable}
                                        />
                                    )}
                                    <AddFromQuestionPoolModal
                                        showModal={openQuestionPoolModal}
                                        subAreaId={selectedSubarea.id}
                                        closeModal={handleCloseModal}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <DeleteModal
                        additionalInfo={translate('authoring.sectionPage.canNotBeReverted')}
                        isLoading={isLoading}
                        showModal={modal === 'deleteSubarea'}
                        closeModal={() => setModal('')}
                        onDelete={handleDeleteSubarea}
                        confirmText={translate('authoring.sectionPage.delete')}
                        message={`${translate('authoring.sectionPage.deleteSectionVerify')} <b>${
                            selectedSubarea ? headline : ''
                        }</b> ${translate('authoring.sectionPage.deleteSectionVerifyEnd')}`}
                        handleChecked={handleDeleteQuestions}
                        checkboxText={translate('authoring.sectionPage.deleteAllQuestions')}
                    />
                </div>
            )}
        </>
    );
};

export default SectionPage;
