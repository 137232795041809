import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../../../components/shared/Button';
import { color } from '../../../../utils/styles';

import { Delete } from '@material-ui/icons';
import { translate } from '../../../../i18n/i18n';

interface SingleChoiceProps {
	onEdit: Function;
	onSave: Function;
	onAbort: Function;
	singleAnswers?: any;
	singleCorrectAnswer?: any;
}

const SingleChoice: React.FunctionComponent<SingleChoiceProps> = ({
	onEdit,
	onSave,
	onAbort,
	singleAnswers,
	singleCorrectAnswer,
}) => {
	const classes = useStyles();

	useEffect(() => {
		if (singleAnswers && singleCorrectAnswer) {
			setAnswers(singleAnswers);
			setSelectedOption(singleCorrectAnswer);
			setIsEditRequest(true);
		}
	}, [singleAnswers, singleCorrectAnswer]);

	const [isEditRequest, setIsEditRequest] = useState(false);
	const [answers, setAnswers] = useState<any>([]);
	const [newOption, setNewOption] = useState('');
	const [selectedOption, setSelectedOption] = useState('');

	const [selectedAnswerToEdit, setAnswer] = useState('');

	// const [hoveredAnswer, setHoveredAnswer] = useState(-1);
	const [editAnswer, setEditAnswer] = useState(-1);

	const [editError, setEditError] = useState(false);
	const [createError, setCreateError] = useState(false);

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOption(event.target.value);
	};

	const handleSelectedOptionChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setSelectedOption((event.target as HTMLInputElement).value);
	};

	const handleSaveQuestion = () => {
		onSave(answers, selectedOption);
	};

	const handleEditQuestion = () => {
		onEdit(answers, selectedOption);
	};

	const deleteOption = (index: number) => {
		var arrayOptions = answers;
		if (answers[index].label === selectedOption) setSelectedOption('');
		arrayOptions.splice(index, 1);
		setAnswers(arrayOptions);
	};

	const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAnswer(event.target.value);
	};

	const handleEditAnswer = (i: number) => {
		setEditAnswer(i);
		const answerText = answers[i].label;
		setAnswer(answerText);
		setEditError(false);
	};

	const addNewOption = (e: any) => {
		if (e.key === 'Enter') {
			const checkForExistingAnswers = answers.filter(
				(answer: any) => answer.label === newOption,
			);
			if (checkForExistingAnswers.length) {
				setCreateError(true);
			}
			if (newOption && checkForExistingAnswers.length === 0) {
				var newRecord = {
					label: newOption,
					value: false,
				};
				var joined = answers.concat(newRecord);
				setAnswers(joined);
				setNewOption('');
				setCreateError(false);
			}
		}
	};

	const handleBlur = (e: any, index: number) => {
		const checkForExistingAnswers = answers.filter(
			(answer: any, key: number) =>
				answer.label === selectedAnswerToEdit && key !== index,
		);
		if (checkForExistingAnswers.length) {
			setEditError(true);
		} else {
			const localAnswers = answers;
			localAnswers[index].label = selectedAnswerToEdit;
			setEditAnswer(-1);
			setEditError(false);
		}
	};

	return (
		<div>
			<FormControl component="fieldset">
				{answers.map(function (answer: any, i: any) {
					return (
						<div
							style={{ display: 'flex', alignItems: 'center' }}
							// onMouseOver={() => setHoveredAnswer(i)}
							// onMouseOut={() => setHoveredAnswer(-1)}
						>
							<RadioGroup
								value={selectedOption}
								onChange={handleSelectedOptionChange}
							>
								<FormControlLabel
									value={answer.label}
									control={
										<Radio
											color="primary"
											style={{ margin: 0 }}
										/>
									}
									label=""
								/>
							</RadioGroup>
							{editAnswer !== i && (
								<span
									onClick={() => handleEditAnswer(i)}
									style={{ fontSize: 16 }}
								>
									{' '}
									{answer.label}{' '}
								</span>
							)}
							{editAnswer === i && (
								<TextField
									error={editError}
									autoFocus={true}
									id="standard-basic"
									value={selectedAnswerToEdit}
									onChange={handleAnswerChange}
									onBlur={(event) => handleBlur(event, i)}
									helperText={
										editError &&
										translate(
											'authoring.singleChoice.answerExists',
										)
									}
								/>
							)}

							<div
								className={classes.deleteWrapper}
								onClick={() => deleteOption(i)}
							>
								<Delete style={{ width: 18, margin: 10 }} />
							</div>
						</div>
					);
				})}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Radio style={{ margin: 0 }} disabled />
					<input
						className={classes.TitleInput}
						name="new-question"
						type="text"
						value={newOption}
						onChange={handleOptionChange}
						onKeyPress={addNewOption}
						placeholder={translate(
							'authoring.singleChoice.addAnswer',
						)}
					/>
				</div>
				{createError && (
					<span className={classes.errorLabel}>
						{translate('authoring.singleChoice.answerExists')}t
					</span>
				)}
			</FormControl>
			<br />
			<div style={{ marginTop: 50 }}>
				{isEditRequest && (
					<Button
						title={translate('authoring.singleChoice.saveQuestion')}
						type="solid"
						color={color.link}
						onClick={() => handleEditQuestion()}
						styles={{ marginRight: 12 }}
						iconColor={color.white}
					/>
				)}
				{!isEditRequest && (
					<Button
						title={translate('authoring.singleChoice.saveQuestion')}
						type="solid"
						color={color.link}
						onClick={() => handleSaveQuestion()}
						styles={{ marginRight: 12 }}
						iconColor={color.white}
					/>
				)}
				<Button
					title={translate('authoring.singleChoice.cancel')}
					type="outline"
					color={color.link}
					onClick={() => onAbort()}
				/>
			</div>
		</div>
	);
};

export default SingleChoice;
