import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, Container } from '@material-ui/core';

import { useStyles } from '../pages/auth/styles';
import {
	getInstitution,
	loginSelector,
	clearState,
} from '../pages/auth/Login/loginSlice';
import LoadingOverlay from '../components/shared/LoadingOverlay';
import { useFavicon } from '../helpers/useFavicon';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Register from '../pages/auth/Register';
import Login from '../pages/auth/Login';
import SetPassword from '../pages/auth/Register/setPassword';
import { translate } from '../i18n/i18n';
import { getPlatforms } from '@ionic/react';

const AuthLayout: React.FunctionComponent = () => {
	const history = useHistory();
	const { view } = useParams<{ view: string }>();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { setFavicon } = useFavicon();
	const { data, isSubmitting, role } = useSelector(loginSelector);

	useEffect(() => {
		if (!data) {
			dispatch({
				...getInstitution({
					requestType: 'get',
					request: `/smartometer/institution/unauthorized/guidelines`,
				}),
			});
		}
	}, []);

	useEffect(() => {
		if (data) {
			setFavicon(data.favicon);
		}
	}, [data]);

	useEffect(() => {
		dispatch(clearState());
	}, [view]);

	useEffect(() => {
		const roleprep = window.localStorage.getItem('role') || null;
		const role = roleprep ? JSON.parse(roleprep) : [];
		if (
			role.length &&
			(getPlatforms().includes('mobile') ||
				getPlatforms().includes('mobileweb'))
		) {
			history.push('/app/learn?navigation=0&content=-1');
		} else {
			if (
				window.localStorage.getItem('userType') === 'SOM' &&
				role.find((cRole: any) => cRole.name === 'FE_SOM_DASHBOARD')
			) {
				history.push('/dashboard');
			} else if (
				window.localStorage.getItem('userType') === 'SOM' &&
				role.find((cRole: any) => cRole.name === 'FE_SOM_USERS')
			) {
				history.push('/users');
			} else if (
				window.localStorage.getItem('userType') === 'SOM' &&
				role.find((cRole: any) => cRole.name === 'FE_SOM_COURSES')
			) {
				history.push('/courses');
			} else if (
				window.localStorage.getItem('userType') === 'SOM' &&
				role.find((cRole: any) => cRole.name === 'FE_SOM_SETTINGS')
			) {
				history.push('/settings');
			}
		}
		//  else if (window.localStorage.getItem('isEndUser') === '1') {
		//     history.push('/app/learn?navigation=0&content=-1');
		// }
	}, [role]);

	const renderChild = () => {
		if (view === 'forgotten-password') {
			return <ForgotPassword />;
		}
		if (view === 'register') {
			return <Register />;
		}
		if (view === 'set-password') {
			return <SetPassword />;
		}
		return <Login />;
	};

	const isAppucationsApp = !!process.env.REACT_APP_COURSE_ID;

	return (
		<>
			<LoadingOverlay isFetching={!data} opaque />
			{!!data && (
				<div
					className={`${classes.root} ${
						isAppucationsApp ? 'appucations-auth' : ''
					}`}
				>
					<Container component="main" maxWidth="xs">
						<div className={classes.paper}>
							{isSubmitting && (
								<div className={classes.SubmitSpinner}>
									<CircularProgress />
								</div>
							)}
							{renderChild()}
						</div>
					</Container>
					<div className={classes.footerMenu}>
						<a
							href="https://www.smartometer.de/datenschutz/"
							target="_blank"
						>
							{translate('authentication.menu.privacy')}
						</a>
						<a
							href="https://www.smartometer.de/agb/"
							target="_blank"
						>
							{translate('authentication.menu.terms')}
						</a>
						<a
							href="https://www.smartometer.de/kontakt/"
							target="_blank"
						>
							{translate('authentication.menu.contact')}
						</a>
					</div>
				</div>
			)}
		</>
	);
};

export default AuthLayout;
