import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import axios from '../../../app/axios';
import Button from '../../../components/shared/Button';
import SelectCourseCard from '../../../components/app/selectCourse/Card';
import { translate } from '../../../i18n/i18n';
import {
	appSelector,
	fetchCurrentCourse,
	setCourseId,
	fetchCurrentCourseSubareas,
} from '../../../store/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import NoCourseModal from './NoCourseModal';
import LoadingOverlay from '../../shared/LoadingOverlay';
import {parse} from "date-fns";
import {color} from "../../../utils/styles";

const AppSelectCourseCards: React.FunctionComponent<{
	handleClose?: Function;
	handleNavigate: Function;
	isLoading: boolean;
	setIsLoading: Function;
}> = ({ handleClose, handleNavigate, isLoading = false, setIsLoading }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [feedbackMail, setFeedbackMail] = useState<string | null>(null);
	const [updateLoading, setUpdateLoading] = useState<boolean>(false);
	const [updatingActiveCourse, setUpdatingActiveCourse] = useState(false);
	const [courses, setCourses] = useState<any>([]);
	const [hasFetched, setHasFetched] = useState(false);
	const [activeCourseId, setActiveCourseId] = useState<number>(0);
	const {
		currentCourse,
		currentCourseId,
		currentCourseSubareas,
		isFetchingCourseForApp,
	} = useSelector(appSelector);

	useEffect(() => {
		fetchCourses();
	}, []);

	useEffect(() => {
		if (
			!currentCourseSubareas &&
			!!currentCourse &&
			isFetchingCourseForApp &&
            currentCourseId
		) {
			dispatch({
				...fetchCurrentCourseSubareas({
					requestType: 'GET',
					request: `/smartometer/courses/by-assignment/${currentCourseId}/subarea`,
				}),
			});
		}
	}, [currentCourse, currentCourseSubareas, isFetchingCourseForApp, currentCourseId]);

	useEffect(() => {
		if (!isFetchingCourseForApp && updatingActiveCourse) {
			handleNavigate('/app/learn', { navigation: '0', content: '-1' });
			setUpdateLoading(false);
			setIsLoading(false);
			setHasFetched(true);
		}
	}, [isFetchingCourseForApp]);

	useEffect(() => {
		if (!!currentCourse && hasFetched) {
			if (handleClose) {
				handleClose();
			}
			localStorage.setItem('success_shown', 'false');
			if (
				courses &&
				courses.find(
					(course: any) =>
						!!course.is_finished && course.id === currentCourseId,
				)
			) {
				localStorage.setItem('success_shown', 'true');
			}

			handleNavigate('/app/learn', { navigation: '0', content: '-1' });
		}
	}, [currentCourse, hasFetched]);

	const fetchCourses = async () => {
		setIsLoading(true);
		await axios
			.get(`/app/courses`)
			.then(async function (response) {
				if (!response.data.success.length) {
					setIsLoading(false);
					let mail = '';
					const settingsRes = await axios.get(`/institution`);
					if (
						settingsRes.data.success &&
						settingsRes.data.success.feedback_email
					) {
						const { feedback_email, contact_email } =
							settingsRes.data.success;
						if (feedback_email) mail = feedback_email;
						if (!feedback_email && contact_email)
							mail = contact_email;
					}
					setFeedbackMail(mail);
				} else if (response.data.success.length === 1 && parse(response.data.success[0].start, 'yyyy-MM-dd', new Date()) <= new Date() && parse(response.data.success[0].end, 'yyyy-MM-dd', new Date()) >= new Date() ) {
				    await updateActiveCourse(
						response.data.success[0].id,
						response.data.success,
					);
				} else {
					setIsLoading(false);
					setCourses(response.data.success);
				}
				if (response.data.selectedCourse) {
				    const currentCourseInDeadline = response.data.success.filter( (course:any) => course.id === response.data.selectedCourse && parse(course.start, "yyyy-MM-dd", new Date()) >= new Date() && parse(course.end, "yyyy-MM-dd", new Date()) <= new Date());
				    if(!!currentCourseInDeadline?.length) {
                        setActiveCourseId(response.data.selectedCourse);
                    }

				}
			})
			.catch(function (error) {
				setIsLoading(false);
				console.error(error);
			});
	};

	const setSuccessShown = async (courseArray: any, validCourseId: any) => {
		const currentCourse = courseArray.find(
			(course: any) => course.id === validCourseId,
		);
		if (currentCourse) {
			switch (currentCourse.is_finished) {
				case 0:
					localStorage.setItem('success_shown', 'false');
					break;
				case 1:
					localStorage.setItem('success_shown', 'true');
					break;
			}
		}
		return;
	};

	const updateActiveCourse = async (
		courseId?: number,
		fetchedCourses?: any[],
	) => {
		const validCourseId = courseId || activeCourseId;
		const courseArray = fetchedCourses || courses;
		setUpdateLoading(true);
		setUpdatingActiveCourse(true);
		await setSuccessShown(courseArray, validCourseId);
		await axios
			.put(`/app/courses/${validCourseId}`)
			.then(function (response) {
				const productId = courseArray.find(
					(item: { id: number; product_id: number }) =>
						item.id === validCourseId,
				).product_id;
				dispatch(setCourseId(validCourseId));
				dispatch({
					...fetchCurrentCourse({
						requestType: 'GET',
						request: `/smartometer/courses/by-assignment/${validCourseId}?all=true`,
					}),
				});
			})
			.catch(function (error) {
				setUpdateLoading(false);
				console.error(error);
			});
	};

	const currentCourses =
		(courses && courses.filter((course: any) => !course.is_finished && parse(course.end, "yyyy-MM-dd", new Date()) >= new Date() && parse(course.start, "yyyy-MM-dd", new Date()) <= new Date() ) ) || [];


    const pastCourses =
        (courses && courses.filter((course: any) => {
            return !course.is_finished && parse(course.end, "yyyy-MM-dd", new Date()) < new Date() ;
        })) ||
        [];

    const futureCourses =
        (courses && courses.filter((course: any) => {
            return !course.is_finished && parse(course.start, "yyyy-MM-dd", new Date()) > new Date() ;
        })) ||
        [];

    const finishedCourses =
        (courses && courses.filter((course: any) => {
            return !!course.is_finished && parse(course.end, "yyyy-MM-dd", new Date()) < new Date() ;
        })) ||
        [];

	return (
		<div>
			{isLoading && <LoadingOverlay isFetching={true} opaque />}
			<div className={classes.courseContainer}>
				{!!currentCourses.length && (
					<div className="app-course-section">
						<p className="app-course-section-headline">
							{translate('app.selectCourse.currentCourse')}
						</p>
						{currentCourses.map((course: any) => {
							return (
								<SelectCourseCard
									key={course.id}
									selectActiveCourse={(id: number) =>
										setActiveCourseId(id)
									}
									data={{ ...course }}
									isSelected={activeCourseId === course.id}
									isActive={currentCourseId === course.id}
								/>
							);
						})}
					</div>
				)}

                {!!futureCourses.length && (
                    <div className="app-course-section">
                        <p className="app-course-section-headline">
                            {translate('app.selectCourse.futureCourse')}
                        </p>
                        {futureCourses.map((course: any) => {
                            return (
                                <SelectCourseCard
                                    key={course.id}
                                    isFuture
                                    data={{ ...course }}
                                />
                            );
                        })}
                    </div>
                )}

                {!!pastCourses.length && (
                    <div className="app-course-section">
                        <p className="app-course-section-headline">
                            {translate('app.selectCourse.endedCourse')}
                        </p>
                        {pastCourses.map((course: any) => {
                            return (
                                <SelectCourseCard
                                    key={course.id}
                                    isDelayed
                                    data={{ ...course }}
                                />
                            );
                        })}
                    </div>
                )}

				{!!finishedCourses.length && (
					<div className="app-course-section">
						<p className="app-course-section-headline">
							{translate('app.selectCourse.finishedCourse')}
						</p>
						{finishedCourses.map((course: any) => {
							return (
								<SelectCourseCard
									key={course.id}
									selectActiveCourse={(id: number) =>
										setActiveCourseId(id)
									}
									isCompleted
									data={{ ...course }}
									isSelected={activeCourseId === course.id}
									isActive={currentCourseId === course.id}
								/>
							);
						})}
					</div>
				)}
			</div>
			<div className={classes.selectButtonContainer}>
				<Button
					onClick={updateActiveCourse}
					isLoading={updateLoading}
					disabled={activeCourseId === 0}
					styles={{ width: '100%' }}
					title={translate(`app.selectCourse.updateCourseButton`)}
					type="solid"
					color={color.link}
				/>
			</div>
			<NoCourseModal feedbackMail={feedbackMail} />
		</div>
	);
};

export default AppSelectCourseCards;
