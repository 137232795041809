import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { store } from './app/store';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';
import App from './app';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#339BF2',
		},
	},
});

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
	<ThemeProvider theme={theme}>
      <App />
	  </ThemeProvider>
    </Provider>
  // </React.StrictMode>
,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
