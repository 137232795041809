import React from 'react';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './styles';
import { TablePagination } from '@material-ui/core';
import { translate } from '../../../i18n/i18n';
import { SortingInterface } from '../../../interfaces/sorting-interface';
import { IconAction } from '../IconAction';
import { LiveTvSharp, Storage } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

interface TableProps {
	showHeader?: boolean;
	data: any[];
	rows: Object;
	page?: number;
	loading?: boolean;
	handleChangePage?: (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => void;
	rowsPerPage?: number;
	handleChangeRowsPerPage?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	total?: number;
	handleChangeSorting?: (column: string) => void;
	sorting?: SortingInterface;
	smallSelectAll?: boolean;
}
const Table: React.FunctionComponent<TableProps> = ({
	showHeader,
	data,
	rows,
	page,
	loading,
	handleChangePage,
	rowsPerPage,
	handleChangeRowsPerPage,
	total,
	handleChangeSorting,
	sorting,
	smallSelectAll,
}) => {
	const classes = useStyles();

	const renderRows = () => {
		let rowsArray: Array<JSX.Element> = [];
		if (!!data.length) {
			data.map((item: any, index) => {
				const tableRow = (
					<TableRow key={index}>
						{Object.keys(rows).map((key, index) => (
							<TableCell
								key={index}
								align={(rows as any)[key].align}
								component="td"
							>
								{(rows as any)[key].renderType(item)}
							</TableCell>
						))}
					</TableRow>
				);
				rowsArray.push(tableRow);
			});
		}

		return rowsArray;
	};

	return data ? (
		<>
			<MUITable
				className={`${classes.Table} ${
					smallSelectAll ? 'smallSelectAll' : ''
				}`}
				aria-label="simple table"
			>
				{showHeader && (
					<TableHead>
						<TableRow>
							{Object.keys(rows).map((key, index: number) => {
								const item = (rows as any)[key];
								return (
									<TableCell
										key={index}
										align={item.align}
										component="th"
										onClick={() => {
											item.sorting &&
												handleChangeSorting &&
												handleChangeSorting(
													item.sorting,
												);
										}}
									>
										<span
											className={`${classes.title} ${
												item.sorting ? `sorting` : ''
											}`}
										>
											{item.renderTitle
												? item.renderTitle()
												: item.title}{' '}
											{sorting?.column === item.sorting &&
											sorting &&
											!sorting.desc ? (
												<IconAction
													className={
														classes.sortingIcon
													}
													type="ArrowDropDown"
												/>
											) : sorting &&
											  sorting.column === item.sorting &&
											  sorting.desc ? (
												<IconAction
													className={
														classes.sortingIcon
													}
													type="ArrowDropUp"
												/>
											) : (
												''
											)}
										</span>
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
				)}
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell
								style={{ padding: 0 }}
								colSpan={Object.keys(rows).length}
							>
								<LinearProgress />
							</TableCell>
						</TableRow>
					)}
					{renderRows()}
				</TableBody>
			</MUITable>
			{!data.length && !loading && (
				<div className={classes.noData}>
					<Storage style={{ height: 50, width: 50, color: 'grey' }} />
					<span style={{ color: 'grey' }}>
						{translate('layout.mainNavbar.noDataFound')}
					</span>
				</div>
			)}
			{page &&
			handleChangePage &&
			rowsPerPage &&
			handleChangeRowsPerPage &&
			total ? (
				<TablePagination
					component="div"
					className={classes.Pagination}
					count={total}
					page={page - 1}
					onChangePage={handleChangePage}
					rowsPerPage={rowsPerPage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					labelRowsPerPage={translate(`perPage`)}
					labelDisplayedRows={(paginationInfo) => {
						const { from, to, count } = paginationInfo;
						return `${from}-${to} ${translate(`from`)} ${
							count !== -1
								? count
								: `${translate(`moreThan`)} ${to}`
						}`;
					}}
				/>
			) : (
				<> </>
			)}
		</>
	) : (
		<div className={classes.SubmitSpinner}>
			<CircularProgress />
		</div>
	);
};

export default Table;
