import {translate} from "../../../i18n/i18n";
import React from 'react'

import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";

interface RemoveDemoDataProps {
    showModal: boolean,
    closeModal: () => void,
    onRemoveDemoData: () => void,
    onNotRemoveDemoData: () => void,
}

const RemoveDemoDataModal: React.FunctionComponent<RemoveDemoDataProps> = ({
                                                                               showModal,
                                                                               closeModal,
                                                                               onRemoveDemoData,
                                                                               onNotRemoveDemoData
                                                                           }) => {
    const classes = useStyles();

    return (<>

        <Modal showModal={showModal} header={''} onClose={() => {
            closeModal()
        }}
               onSubmit={onNotRemoveDemoData} isLoading={false} submitActionTitle={translate(`notRemoveExampleData`)}
               secondaryActionTitle={translate(`removeExampleData`)}
               secondaryAction={onRemoveDemoData}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               buttonWrapper={classes.MainButtonWrapper}
        >
            <div className={classes.image}>
                <h2 className={classes.header}>{translate('modals.removeDemoDataModal.header')}</h2>
            </div>
        </Modal>
    </>);
};

export default RemoveDemoDataModal;
