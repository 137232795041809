import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	SearchButton: {
		height: 35,
		marginRight: 0,
		marginLeft: 0,
		'& img': {
			height: 15,
			width: 15,
			'&.active': {
				height: 12,
				width: 12,
			},
		},
	},
	Input: {
		marginRight: 15,
		height: 35,
		'& input.MuiInputBase-input': {
			height: 35,
			borderRadius: 18,
			border: '1px solid #c7c7c7',
			padding: '0 20px',
			minWidth: '180px',
			boxSizing: 'border-box',
			maxWidth: 300,

			'&:focus': {
				border: '1px solid rgb(20, 133, 238)',
			},
		},
		'& .MuiInput-underline:before,.MuiInput-underline:after': {
			display: 'none !important',
		},
	},
	SearchFieldWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));
