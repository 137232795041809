import React, {useState} from "react";
import { Grid } from "semantic-ui-react";
import ContentCard from "../../../shared/ContentCard";
import {PermissionList} from "./PermissionList";
import {AddRoleModal} from "../../../modals/AddRoleModal";
import Button from "../../../shared/Button";
import {color} from "../../../../utils/styles";
import {translate} from "../../../../i18n/i18n";
import {EditRoleModal} from "../../../modals/EditRoleModal";
import { useStyles } from "./styles";
import useUserContext from "../../../../contexts/UserContext";


interface RolesOverviewTabProps {
    rolesData: any, // fix when time
}

export const RolesOverviewTab: React.FunctionComponent<RolesOverviewTabProps> = ({ rolesData }) => {
    const {user} = useUserContext();
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    interface IINITIAL_STATE {
        id: number | null,
        open: boolean
    }
    const INITIAL_STATE: IINITIAL_STATE = {
        id: null,
        open: false
    }

    const [editRoleModal, setEditRoleModal] = useState(INITIAL_STATE);


    const closeModal = () => {
        setEditRoleModal(INITIAL_STATE);
    }

    const handleEditRole = (id: number) => {
        setEditRoleModal({
            id: id,
            open: true
        })
    }

    const classes = useStyles();

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button type="solid" styles={{ float: "right"}} onClick={() => setShowAddRoleModal(true) }  color={color.link}  title={translate(`settings.team.roles.createNewButton`)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard title={translate(`settings.team.roles.headline`)} helperText={user?.institution?.id !== 1 ? translate(`settings.team.roles.clarify`) : ""}>
                            <PermissionList onEditRole={handleEditRole} data={rolesData.data} />
                        </ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <AddRoleModal showModal={showAddRoleModal} closeModal={() => setShowAddRoleModal(false) }/>
            {editRoleModal.id && <EditRoleModal showModal={editRoleModal.open} closeModal={() => closeModal() } roleId={editRoleModal.id} />}
        </>
    )
}


