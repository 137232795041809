import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes} from "../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    Input: {
        width: "100%",
        position: "relative",
        padding: "0 24px",
        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "0"
        },
        "& .MuiButton-label": {
            justifyContent: "flex-start",
            lineHeight: "48px",
            fontSize: textSizes.bodyNormal,
            color: color.textGrey
        }
    },
    Or: {
        textTransform: "uppercase",
        color: color.textGrey,
        marginTop: "8px",
        marginBottom: "8px",
    },
    Root: {
        width: '100%',
    },
    Button: {
        textAlign: "center"
    },
    ErrorHeader: {
        fontSize:  textSizes.headline,
        marginBottom: 28
    },
    ErrorMessage: {
        marginTop: 10,
        fontSize: textSizes.bodySmall,
        color: color.errorRed,
    }
}))
