import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	SearchContainer: {
		position: 'relative',
		marginTop: -30,
		height: 70,
		transition: '.2s',
	},
	SearchContainerOpen: {
		height: 90,

		'& .search-field': {
			transform: 'translateY(0)',
		},
	},
	SearchContainerSearching: {
		height: 125,
	},
	SearchText: {
		color: '#DFE0EB',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		fontSize: 18,
		transition: '.2s',
		cursor: 'pointer',
		paddingTop: 30,

		'& svg': {
			marginLeft: 5,
			width: 20,
			height: 20,
		},
	},
	SearchField: {
		width: 'calc(100% + 40px)',
		marginLeft: -20,
		height: 70,
		transform: 'translateY(-100%)',
		transition: '.2s',
		background: 'rgb(20, 133, 238)',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		padding: '25px 23px 0',

		'@media screen and (min-width: 768px)': {
			width: 'calc(100% + 120px)',
			marginLeft: -60,
			padding: '25px 60px 0',
		},

		'@media screen and (min-width: 1400px)': {
			width: 'calc(100% + 160px)',
			marginLeft: -80,
			padding: '25px 80px 0',
		},
	},
	SearchInput: {
		width: '100%',

		'& svg': {
			cursor: 'pointer',
			color: 'rgba(255,255,255,.8)',
		},

		'& .MuiInput-input': {
			fontSize: 18,
			color: '#fff',

			'&::placeholder': {
				color: 'rgba(255,255,255,.8)',
				opacity: 1,
			},
		},

		'&:before': {
			borderBottom: '2px solid #fff',
		},

		'&:after': {
			borderBottom: '2px solid #fff',
		},

		'&:hover::before, &:focus::before': {
			borderBottom: '2px solid #fff !important',
		},
	},
	ResultsText: {
		fontSize: 18,
		color: '#252733',
		opacity: 0,
		transitionDelay: '0s',
		transitionDuration: '0s',
		paddingTop: 20,

		'& strong': {
			fontWeight: 600,
		},
	},
	ResultsTextVisible: {
		opacity: 1,
		transitionDelay: '.2s',
		transitionDuration: '.2s',
	},
}));
