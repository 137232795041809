import { Input, InputAdornment, TextField } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import React, {useState} from "react";
import {useStyles} from "./styles"

interface AppSearchProps{
    searchValue: string;
    actualSearchValue: string;
    setSearchValue: (val: string) => void;
}

const AppSearch:React.FunctionComponent<AppSearchProps> = ({searchValue, actualSearchValue, setSearchValue}) => {
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const handleClearSearch = () => {
        setSearchValue("");
        setIsOpen(false);
    }

    return (
        <div className={`${styles.SearchContainer} ${isOpen ? styles.SearchContainerOpen : ""} ${!!actualSearchValue ? styles.SearchContainerSearching : ""}`}>
            <p className={`${styles.SearchText} search-text`} onClick={() => setIsOpen(true)}>Suche <Search/></p>
            <div className={`${styles.SearchField} search-field`}>
                <Input placeholder="Suche" className={styles.SearchInput} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} endAdornment={
                    <InputAdornment position="end">
                        <Close onClick={handleClearSearch}/>
                    </InputAdornment>
                }/>
            </div>
            <div className={`${styles.ResultsText} ${!!actualSearchValue ? styles.ResultsTextVisible : ""}`}>Suchergebnisse für: <strong>{actualSearchValue}</strong></div>
        </div>
    );
}

export default AppSearch;