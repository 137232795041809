import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	courseContainer: {
		paddingBottom: 100
	},
	container: {
		padding: 20,
		[theme.breakpoints.up("xs")]: {
			padding: 20,
			paddingTop: 30
		},
		[theme.breakpoints.up("md")]: {
			padding: 180,
			paddingTop: 85
		},
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	logout: {
		color: color.link,
		cursor: 'pointer',
		"&:hover": {
            cursor: 'pointer !important',
			textDecoration: 'underline'
        }
	},
	textContainer: {
		marginBottom: 20,
		[theme.breakpoints.up("xs")]: {
			marginBottom: 20,
		},
		[theme.breakpoints.up("md")]: {
			marginBottom: 40
		},
	},
	text: {
		fontSize: 18,
		fontWeight: 400,
		color: '#252733',
		[theme.breakpoints.up("xs")]: {
			marginBottom: 30,
		},
		[theme.breakpoints.up("md")]: {
			marginBottom: 40
		},
	},
	selectButtonContainer: {
		position: 'fixed',
		bottom: 20,
		left: "50%",
		transform: "translateX(-50%)",
		width: '100%',
		[theme.breakpoints.up("xs")]: {
			padding: 20,
			width: '100%',
		},
		[theme.breakpoints.up("md")]: {
			width: 380,
		},
	}
}));
