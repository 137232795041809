import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color, textSizes } from '../../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	questionWrapper: {
		marginBottom: 40,

		'&.opened': {
			paddingBottom: 80,
		},
	},
	options: {
		width: '100%',
		position: 'absolute',
		height: 100,
		bottom: 78,
		left: 0,
		background: color.lightestGrey,
		display: 'block',
		zIndex: 1,
		padding: '10px 0',
		boxShadow: '0 -5px 20px 0 rgba(0, 0, 0, 0.15)',

		'& div': {
			'-ms-overflow-style': 'none',
			scrollbarWidth: 'none',
		},
		'& div::-webkit-scrollbar': {
			display: 'none',
		},

		'&::after': {
			position: 'absolute',
			content: '" "',
			right: 0,
			height: 45,
			top: 39,
			bottom: 0,
			width: 20,
			background:
				'linear-gradient(90deg, rgba(234,234,234,0) 0%, rgba(234,234,234,1) 70%)',
		},
	},
	answerWrapper: {
		display: 'flex',
		flexWrap: 'nowrap',
		overflowX: 'auto',
	},
	question: {
		fontSize: textSizes.bodyLarge,
	},
	fieldControl: {
		width: '100%',
		position: 'initial',
		maxWidth: '480px',
	},
	ListItem: {
		cursor: 'pointer',
		width: '100%',
		minHeight: '48px',
		lineHeight: '24px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 16,
		background: color.lightestGrey,
		borderRadius: borderRadius.small,
		padding: '0 6px',
		flexWrap: 'wrap',
	},
	ListNumber: {
		color: color.textGrey,
		width: '48px',
		height: '20px',
		borderRight: `1px solid ${color.textGrey}`,
		textAlign: 'center',
		fontSize: textSizes.bodySmall,
		lineHeight: '20px',
		fontWeight: 600,
		margin: '6px 10px 6px 0',
	},
	ListItemLabel: {
		color: color.textDark,
		background: color.white,
		fontSize: textSizes.bodyNormal,
		padding: '6px 15px',
		marginRight: 8,
		borderRadius: borderRadius.small,
		margin: '5px 8px 5px 0',
	},
	option: {
		color: color.textDark,
		background: color.white,
		fontSize: textSizes.bodyNormal,
		padding: '0 25px',
		height: 45,
		lineHeight: '45px',
		borderRadius: borderRadius.small,
	},
	ListItemOption: {
		cursor: 'pointer',
		width: 'auto',
		minHeight: '36px',
		lineHeight: '24px',
		display: 'inline-flex',
		alignItems: 'center',
		marginBottom: 10,
		marginLeft: 10,
		flexShrink: 0,
		background: color.lightestGrey,
		borderRadius: borderRadius.small,
		'&:last-child': {
			marginRight: 10,
		},
	},
	ListItemWordCombination: {
		cursor: 'pointer',
		width: '100%',
		minHeight: '48px',
		lineHeight: '24px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 16,
		background: color.lightestGrey,
		borderRadius: borderRadius.small,
		padding: '0 12px',
		flexWrap: 'wrap',
	},
	ListNumberWordCombination: {
		color: color.textGrey,
		minWidth: '48px',
		height: '20px',
		borderRight: `1px solid ${color.textGrey}`,
		textAlign: 'center',
		fontSize: textSizes.bodySmall,
		lineHeight: '20px',
		fontWeight: 600,
		marginRight: 10,
		paddingRight: 10,
	},
	icons: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 10,
		alignItems: 'center',
		padding: '0 10px',
	},
	button: {
		padding: 0,
	},
	selected: {
		border: `1px solid ${color.chartBlue}`,
	},
	desktopOptions: {
		position: 'fixed',
		width: '80vw',
		bottom: '10%',
		left: '50%',
		transform: 'translateX(-50%)',
	},
	ListItemLabelCorrected: {
		backgroundColor: 'transparent',
		border: '1px dashed #7A9B53',
		color: '#7A9B53',
	},
	ListItemLabelWrong: {
		backgroundColor: '#E8CCCC',
		border: '1px solid #D65045',
		color: '#D65045',
	},
	ListItemLabelRight: {
		backgroundColor: '#D2E2C0',
		border: '1px solid #7A9B53',
		color: '#7A9B53',
	},
}));
