import React, {ChangeEvent, useState} from "react";
import {IconAction} from "../../components/shared/IconAction";
import {useStyles} from "./styles";

export const useTableSearch = () => {
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const classes = useStyles();

    const handleSearchChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setSearch(event.target.value as string)
    }

    const CurrentlySearching = () => {
        return (
            <>
              {(!showSearch && search) ? 
              <>
                <span className={classes.searchAndFilterInfo}><IconAction handleClick={()=>{setSearch('')}} type="close" /></span>
                <span className={classes.searchAndFilterInfo}>Suche: "{search}"</span>
              </> : 
              <></>}
            </>
        )
        
    };

    return {
        handleSearchChange,search, setSearch, showSearch, setShowSearch, CurrentlySearching
    }
};
