import { makeStyles } from "@material-ui/core/styles";
import {textSizes, color} from "../../../../utils/styles"

export const useStyles = makeStyles(theme => ({
    Note: {
        fontSize: textSizes.notice,
        color: color.textGrey,
        marginTop: 12
    },
    Link: {
        fontSize: textSizes.notice,
        color: color.link,
        cursor: "pointer"
    },
    Error: {
        fontSize: textSizes.notice,
        color: color.red
    },
    Title: {
        fontSize: textSizes.h3,
        paddingTop: 32,
        color: color.textDark,
        paddingBottom: 12,
    },
    FormContainer: {
        marginBottom: '20px !important'
    },
    country: {
        "& .divider": {
            marginLeft: "0 !important"
        }
    },
    Input: {
        fontSize: "14px"
    },
    Form: {
        "& input":{
            fontSize: "16px !important"
        }
    }
}))
