import React, { useRef, useEffect, useState, useContext } from 'react';
import { StarOutline } from '@material-ui/icons';
import Confetti from 'react-canvas-confetti';
import { UserContext } from '../../../contexts/UserContext';
import axios from '../../../app/axios';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { setShowFinishCourse } from '../../../store/app/appSlice';
import { useDispatch } from 'react-redux';
import { translate } from '../../../i18n/i18n';

interface FinishedCourseProps {
	course: null | {
		id: number;
		name: string;
		image: string;
		description: string;
		learning_areas: { id: number; name: string }[];
	};
	courseId: null | number;
	handleNavigate: Function;
}

function randomInRange(min: number, max: number) {
	return Math.random() * (max - min) + min;
}

const FinishedCourseScreen: React.FunctionComponent<FinishedCourseProps> = ({
	course,
	courseId,
	handleNavigate,
}) => {
	const dispatch = useDispatch();
	const { user } = useContext(UserContext);
	const [resultsPerArea, setResultsPerArea] = useState<any>(null);
	useEffect(() => {
		if (user && course) {
			fetchUserResults();
		}
	}, [user]);

	const fetchUserResults = async () => {
		await axios
			.get(`/courseResultsByUser/${courseId}/${user.id}`)
			.then(async function (response) {
				if (response.data.learningAreas)
					setResultsPerArea(response.data.learningAreas);
				else setResultsPerArea({});
			})
			.catch(function (error) {
				if (!error.response) {
					setResultsPerArea({});
				}
			});
	};

	// CONFETTI FUN

	const confettiRef = useRef<any>();
	useEffect(() => {
		if (confettiRef.current) {
			nextTickAnimation();
		}
	}, [confettiRef]);

	const nextTickAnimation = () => {
		confettiRef.current &&
			confettiRef.current({
				particleCount: 1,
				startVelocity: 0,
				ticks: 100,
				gravity: 0.2,
				origin: {
					x: Math.random(),
					y: Math.random() * 0.999 - 0.2,
				},
				colors: ['#ffffff'],
				scalar: randomInRange(0.4, 1),
			});
		requestAnimationFrame(nextTickAnimation);
	};

	// CONFETTI FUN END

	return (
		<div className="app-finished-course-container">
			<LoadingOverlay isFetching={!resultsPerArea} opaque />
			<div
				className="app-finished-course-content"
				onClick={() => {
					confettiRef.current({
						particleCount: 3,
						spread: 55,
						origin: { y: 0, x: 0 },
						colors: ['#bb0000', '#ffffff'],
					});
				}}
			>
				<Confetti
					style={{
						width: '100%',
						height: 'calc(100vh - 206px)',
						left: 0,
						top: 0,
						position: 'absolute',
					}}
					refConfetti={(instance: any) => {
						confettiRef.current = instance;
					}}
				/>
				<div className="app-finished-course-content-headline">
					<StarOutline />
					<h3>
						{translate('app.finishedCourseScreen.courseFinished')}
					</h3>
					<p>
						{translate('app.finishedCourseScreen.congratsFinished')}{' '}
						<strong>{course ? course.name : ''} </strong>
						{translate('app.finishedCourseScreen.congratsEnd')}
					</p>
				</div>
				{resultsPerArea && !!Object.keys(resultsPerArea).length ? (
					<div className="app-finished-course-content-details">
						<h4 className="list-item">
							<span>
								{translate(
									'app.finishedCourseScreen.courseProgress',
								)}
							</span>
							<span className="item-value">100 %</span>
						</h4>
						{Object.keys(resultsPerArea).map((key: any) => {
							const { name, score } = resultsPerArea[key];
							return (
								<p className="list-item">
									<span>{name}</span>
									<span className="item-value">
										{Math.ceil(score * 100).toFixed(0)} %
									</span>
								</p>
							);
						})}
					</div>
				) : (
					<div className="app-finished-course-content-details no-results">
						<h4 className="list-item">
							<span>
								{translate(
									'app.finishedCourseScreen.courseProgress',
								)}
							</span>
							<span className="item-value">100 %</span>
						</h4>
					</div>
				)}
			</div>
			<div className="app-finished-course-buttons">
				<div
					className="app-finished-course-buttons-button"
					onClick={() => {
						dispatch(setShowFinishCourse(false));
						handleNavigate(`/app/course/statistics`, {
							content: -1,
							navigation: '1',
						});
					}}
				>
					{translate('app.finishedCourseScreen.showStatistics')}
				</div>
				<div
					className="app-finished-course-buttons-button secondary"
					onClick={() => {
						dispatch(setShowFinishCourse(false));
					}}
				>
					{translate('app.finishedCourseScreen.close')}
				</div>
			</div>
		</div>
	);
};

export default FinishedCourseScreen;
