import React from 'react';
import { SubAreaInterface } from '..';
import PreviewListItem from '../PreviewListItem';
import {translate} from '../../../../i18n/i18n';

interface PreviewContentProps {
	currentItem: SubAreaInterface;
	nextItem: SubAreaInterface | null;
	handleNavigateTo: Function;
}

const PreviewContent: React.FunctionComponent<PreviewContentProps> = ({
	currentItem,
	nextItem,
	handleNavigateTo,
}) => {
	const switchToNextItem = () => {
		document!
			.querySelector('.preview-window-content-inner')!
			.scrollTo(0, 0);
		handleNavigateTo(
			nextItem!.id,
			!!nextItem!.sub_area_id ? nextItem!.sub_area_id : -1,
		);
	};

	return currentItem ? (
		<div className="preview-window-content-inner">
			<div className="preview-window-content-wrapper">
				<h3>{currentItem?.headline || 'Kein Inhalt ausgewählt'}</h3>
				<div
					className="preview-window-content-wysiwyg"
					dangerouslySetInnerHTML={{
						__html: currentItem?.wysiwyg || '',
					}}
				/>
				{nextItem && (
					<div className="preview-window-content-next">
							{translate('authoring.previewContent.nextContent')}
						<PreviewListItem
							item={nextItem}
							nosubareas
							handleClick={switchToNextItem}
							parentId={null}
							active={false}
						/>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default PreviewContent;
