import { makeStyles } from '@material-ui/core/styles';
import { color, borderRadius } from '../../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	Card: {
		backgroundColor: color.appCardGrey,
		borderRadius: 5,
		padding: '13px 20px 20px',
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		marginBottom: 10,
		fontWeight: 600,
		position: 'relative',
        '&.completed': {
            cursor: 'not-allowed',
        },
		'&.active': {
			background: color.link,
		},
        '&.delayed': {
            background: color.errorRed,
            cursor: 'not-allowed',
        },
        '&.future': {
            background: color.appCardGreen,
            cursor: 'not-allowed',
        },
	},
	CompleteIcon: {
		width: 30,
		height: 30,
		position: 'absolute',
		right: -4,
		top: -4,
	},
	Header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	Title: {
		fontSize: 18,
		'&.active': {
			color: 'white',
		},
        '&.delayed, &.future': {
            color: 'white',
        },
	},
	Active: {
		fontSize: 12,
		color: color.appTextSecondary,
		'&.active': {
			color: 'white',
		},
        '&.delayed, &.future': {
            color: 'white',
        },
		'&.completed': {
			marginRight: 15,
		},
	},
	DatesContainer: {
		display: 'flex',
	},
	startDate: {
		marginRight: 30,
	},
	endDate: {},
	dateLabel: {
		color: color.appTextSecondary,
		fontSize: 12,
		'&.active': {
			color: 'white',
		},
        '&.delayed, &.future': {
            color: 'white',
        },
	},
	date: {
		color: color.appText,
		fontSize: 18,
		'&.active': {
			color: 'white',
		},
        '&.delayed, &.future': {
            color: 'white',
        },
	},
	Blocker: {
		borderRadius: 5,
		position: "absolute",
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		background: "rgba(48,112,237,.95)",
		display: "flex",
		padding: "20px 40px",
		color: "#fff",
		fontWeight: 400,
		alignItems: "center",
		textAlign: "center",
		cursor: "default",
	}
}));
