import React from 'react';
// import {useStyles} from './styles';

interface DatePickerProps {
	type: string;
	margin?: number;
	color?: string;
}

export const DynamicIcon: React.FunctionComponent<DatePickerProps> = ({
	type,
	margin,
	color = '#c7c7c7',
}) => {
	// const classes = useStyles();
	const createIcon = (type: string) => {
		if (type) {
			switch (type) {
				case 'exerciseIcon':
					return (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16.137"
							height="16.137"
							viewBox="0 0 16.137 16.137"
						>
							<path
								id="checkbox-marked-circle-outline"
								d="M16.523,10.068a6.46,6.46,0,1,1-4.68-6.2L13.11,2.6A7.925,7.925,0,0,0,10.068,2a8.068,8.068,0,1,0,8.068,8.068M6.768,8.519,5.631,9.665,9.262,13.3,17.33,5.227,16.192,4.082,9.262,11.012Z"
								transform="translate(-2 -2)"
								fill={color}
							/>
						</svg>
					);
				case 'contentIcon':
					return (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16.097"
							height="15.96"
							viewBox="0 0 16.097 15.96"
						>
							<path
								id="learnicon"
								d="M14.715,16.5,6.667,11.967l8.048-4.536,8.049,4.536Zm5.227,1.658a5.231,5.231,0,0,1-10.462,0v-3l5.232,2.948,5.231-2.948v3Z"
								transform="translate(-6.667 -7.431)"
								fill={color}
							/>
						</svg>
					);
				case 'awardIcon':
					return (
						<svg
							width="16.097"
							height="15.96"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 384 512"
						>
							<path
								fill={color}
								d="M97.12 362.63c-8.69-8.69-4.16-6.24-25.12-11.85-9.51-2.55-17.87-7.45-25.43-13.32L1.2 448.7c-4.39 10.77 3.81 22.47 15.43 22.03l52.69-2.01L105.56 507c8 8.44 22.04 5.81 26.43-4.96l52.05-127.62c-10.84 6.04-22.87 9.58-35.31 9.58-19.5 0-37.82-7.59-51.61-21.37zM382.8 448.7l-45.37-111.24c-7.56 5.88-15.92 10.77-25.43 13.32-21.07 5.64-16.45 3.18-25.12 11.85-13.79 13.78-32.12 21.37-51.62 21.37-12.44 0-24.47-3.55-35.31-9.58L252 502.04c4.39 10.77 18.44 13.4 26.43 4.96l36.25-38.28 52.69 2.01c11.62.44 19.82-11.27 15.43-22.03zM263 340c15.28-15.55 17.03-14.21 38.79-20.14 13.89-3.79 24.75-14.84 28.47-28.98 7.48-28.4 5.54-24.97 25.95-45.75 10.17-10.35 14.14-25.44 10.42-39.58-7.47-28.38-7.48-24.42 0-52.83 3.72-14.14-.25-29.23-10.42-39.58-20.41-20.78-18.47-17.36-25.95-45.75-3.72-14.14-14.58-25.19-28.47-28.98-27.88-7.61-24.52-5.62-44.95-26.41-10.17-10.35-25-14.4-38.89-10.61-27.87 7.6-23.98 7.61-51.9 0-13.89-3.79-28.72.25-38.89 10.61-20.41 20.78-17.05 18.8-44.94 26.41-13.89 3.79-24.75 14.84-28.47 28.98-7.47 28.39-5.54 24.97-25.95 45.75-10.17 10.35-14.15 25.44-10.42 39.58 7.47 28.36 7.48 24.4 0 52.82-3.72 14.14.25 29.23 10.42 39.59 20.41 20.78 18.47 17.35 25.95 45.75 3.72 14.14 14.58 25.19 28.47 28.98C104.6 325.96 106.27 325 121 340c13.23 13.47 33.84 15.88 49.74 5.82a39.676 39.676 0 0 1 42.53 0c15.89 10.06 36.5 7.65 49.73-5.82zM97.66 175.96c0-53.03 42.24-96.02 94.34-96.02s94.34 42.99 94.34 96.02-42.24 96.02-94.34 96.02-94.34-42.99-94.34-96.02z"
							></path>
						</svg>
					);
				case 'hiddenIcon':
					return (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							role="img"
							width="1em"
							height="1em"
							preserveAspectRatio="xMidYMid meet"
							viewBox="0 0 24 20"
						>
							<path
								fill={color}
								d="M12 7c2.76 0 5 2.24 5 5c0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28l.46.46A11.804 11.804 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22L21 20.73L3.27 3L2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65c0 1.66 1.34 3 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53c-2.76 0-5-2.24-5-5c0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15l.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
							/>
						</svg>
					);
			}
		}
	};
	return (
		<div style={{ marginRight: margin ? margin : 0 }}>
			{createIcon(type)}
		</div>
	);
};

export default DynamicIcon;
