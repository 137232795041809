import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes} from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    Table: {
        color: "pink",
        "& tr:hover":{
            background: "#D0E9FF",
        },
        "& thead tr:hover":{
            background: "white",
        },
        "& td, th":{
            fontSize: "14px",
            padding: "16px 4px",
        },
        "& th:first-child,td:first-child": {
            paddingLeft: "32px",

        },
        "& th": {
            color: "#9FA2B4",
            "& .sorting": {
                cursor: "pointer"
            },
            "& .sorting:hover":{
                color: "#212126",
            },
        },
        "&.smallSelectAll th:first-child,td:first-child": {
            minWidth: 0
        },
        "& th:last-child,td:last-child": {
            paddingRight: "32px",
        }
    },
    Pagination: {
        width: "100%",
        "& .MuiSelect-select:focus": {
            background: "none"
        },
        "& .MuiTablePagination-input": {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: color.link,
            fontSize: textSizes.bodySmall,

            "& .MuiSelect-icon": {
                color: color.lightGray,
            }
        },
        "& .MuiTablePagination-caption:first-of-type": {
            position: "absolute",
            left: "50%",
            transform: "translateX(40px)",
            color: color.lightGray,
            fontSize: textSizes.bodySmall
        },
        "& .MuiTablePagination-caption:last-of-type": {
            color: color.lightGray,
            fontSize: textSizes.bodySmall
        },
        "& .MuiTablePagination-actions": {
            marginLeft: 0
        }
    },
    title: {
        display: "inline-block",
        fontWeight: 600,
        fontSize: 14,
    },
    sortingIcon: {
        float: "right"
    },
    sortingIconHover: {
        display: 'none'
    },
    noData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'black',
        marginTop: 50,
        marginBottom: 50
    },
    SubmitSpinner: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		left: 0,
		background: 'rgba(255,255,255,.8)',
		zIndex: 12,
		display: 'flex',
		borderRadius: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
}))
