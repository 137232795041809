import React, { FunctionComponent, useRef } from 'react';

import { FormControl, TextField, FormGroup } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import Button from '../../../shared/Button';
import { color } from '../../../../utils/styles';
import { translate } from '../../../../i18n/i18n';

import { useStyles } from './styles';
import pairIcon from '../../../../assets/images/pairIcon.svg';
import { useAssignPair } from './useAssignPair';

export interface PairInterface {
    firstWord: string,
    secondWord: string
    id?: number
    question_id?: number
}

interface AssignPairProps {
    onSave: Function,
    onAbort: Function,
    onEdit: Function,
    assignPairs?: any
}

const AssignPair: React.FunctionComponent<AssignPairProps> = ({ onSave, onAbort, onEdit, assignPairs }) => {
    const classes = useStyles();

    const firstWordElement = useRef<HTMLInputElement | null>(null);
    const secondWordElement = useRef<HTMLInputElement | null>(null);

    const { handleSelectEditedAnswer,
        addNewOption,
        deleteAnswer,
        handleEnter,
        handleEditAnswerChange,
        isEditRequest,
        pairs,
        editPairId,
        createError,
        editError } = useAssignPair(assignPairs, firstWordElement, secondWordElement);

    const ViewPair: FunctionComponent<{ pair: PairInterface, i: number }> = ({ pair, i }) => {
        return <div onClick={() => handleSelectEditedAnswer(i)} className={classes.wordWrapper}>
            <div className={classes.firstWord}> {pair.firstWord} </div>
            <img src={pairIcon} className={classes.pairIcon} />
            <div className={classes.secondWord}> {pair.secondWord} </div>
        </div>
    }

    const EditPair: FunctionComponent<{ pair: PairInterface, i: number }> = ({ pair, i }) => {
        return <div className={classes.wordWrapper}>
            <TextField
                error={editError}
                value={pair.firstWord}
                className={classes.firstWord}
                name={`firstWord-${i}`}
                onChange={handleEditAnswerChange}
                onKeyPress={handleEnter}
                helperText={editError && translate('authoring.assignPair.answerExists')}
            />
            <img src={pairIcon} className={classes.pairIcon} />
            <TextField
                error={editError}
                value={pair.secondWord}
                className={classes.secondWord}
                name={`secondWord-${i}`}
                onChange={handleEditAnswerChange}
                onKeyPress={handleEnter}
                helperText={editError && translate('authoring.assignPair.answerExists')}
            />
        </div>
    }

    const CreatePair: FunctionComponent<{}> = () => {
        return <div className={classes.wordWrapperBottom}>
            <input className={classes.TitleInput} name="firstName" type="text" ref={firstWordElement}
                placeholder={translate('authoring.assignPair.firstWord')} />
            <img src={pairIcon} className={classes.pairIcon} />
            <input className={classes.TitleInput} name="secondName" type="text" ref={secondWordElement}
                onKeyPress={addNewOption} placeholder={translate('authoring.assignPair.secondWord')} />
        </div>;
    }

    return (
        <>
            <FormControl component="fieldset">
                <FormGroup>
                    {pairs.map((pair: PairInterface, i: number) => {
                        return (
                            <div key={`pairs-${i}`} className={classes.pair}>
                                {editPairId !== i ? <ViewPair pair={pair} i={i} /> : <EditPair pair={pair} i={i} />}
                                <div className={classes.deleteWrapper} onClick={() => deleteAnswer(i)}>
                                    <Delete style={{ width: 18, margin: 10 }} />
                                </div>
                            </div>
                        );
                    })}
                    <CreatePair />
                </FormGroup>

                {createError && <span className={classes.errorLabel}>{translate('authoring.assignPair.answerExists')}</span>}
            </FormControl>
            <br />
            <div style={{ marginTop: 50 }}>
                <Button
                    title={translate(isEditRequest ? 'authoring.assignPair.editQuestion' : 'authoring.assignPair.saveQuestion')}
                    type="solid"
                    color={color.link}
                    onClick={() => isEditRequest ? onEdit(pairs) : onSave(pairs)}
                    styles={{ marginRight: 12 }}
                    iconColor={color.white}
                />
                <Button
                    title={translate('authoring.assignPair.abort')}
                    type="outline"
                    color={color.link}
                    onClick={onAbort}
                />
            </div>
        </>
    );
};
export default AssignPair;
