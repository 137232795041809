import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Grid, Loader} from 'semantic-ui-react';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {Formik} from 'formik';

import {CourseSettingsInterface} from '../../../interfaces/course-settings';
import {courseSelector, updateCourse,} from '../../../store/courses/courseSlice';
import {useStyles} from './styles';
import {validationSchema} from './validationSchema';

import Button from '../../../components/shared/Button';
import { color } from '../../../utils/styles';
import {translate} from "../../../i18n/i18n";

const AuthoringSettings = () => {
    const dispatch = useDispatch();
    const {current: currentCourse, isLoading} = useSelector(courseSelector);
    const [initialValues, setInitialValues] =
        useState<CourseSettingsInterface | null>(null);
    const [showRemovePoints, setShowRemovePoints] = useState('false');
    const [showExerciseRemovePoints, setShowExerciseRemovePoints] = useState('false');


    const classes = useStyles();

    useEffect(() => {
        if (currentCourse) {
            const {settings} = currentCourse;

            setInitialValues({
                exam_time: settings.exam_time,
                exam_show_right_answers: String(!!settings.exam_show_right_answers),
                exam_percentage_for_pass: settings.exam_percentage_for_pass,
                exam_max_questions: settings.exam_max_questions,
                exam_remove_points_on_wrong:
                settings.exam_remove_points_on_wrong,
                exam_show_introduction: String(!!settings.exam_show_introduction),
                exercise_show_right_answers:
                    String(!!settings.exercise_show_right_answers),
                exercise_max_questions: settings.exercise_max_questions,
                exercise_show_introduction:
                    String(!!settings.exercise_show_introduction),
                exercise_percentage_for_pass: settings.exercise_percentage_for_pass,
                exercise_remove_points_on_wrong: settings.exercise_remove_points_on_wrong,
                exercise_show_question_type: String(!!settings.exercise_show_question_type),
                exam_show_question_type: String(!!settings.exam_show_question_type)
            });
            setShowRemovePoints(
                settings.exam_remove_points_on_wrong === null
                    ? 'false'
                    : 'true',
            );
            setShowExerciseRemovePoints(
                settings.exercise_remove_points_on_wrong === null
                    ? 'false'
                    : 'true',
            );

        }
    }, [currentCourse]);

    const handleSubmit = async (values: any) => {

        let body = {
            settings: {
                ...values,
                exam_show_right_answers:
                    values.exam_show_right_answers == 'true',
                exam_remove_points_on_wrong:
                    showRemovePoints == 'true'
                        ? values.exam_remove_points_on_wrong
                        : null,
                exam_show_introduction: values.exam_show_introduction == 'true',
                exercise_show_right_answers:
                    values.exercise_show_right_answers == 'true',
                exercise_show_introduction: values.exercise_show_introduction == 'true',
                exercise_remove_points_on_wrong:
                    showExerciseRemovePoints == 'true'
                        ? values.exercise_remove_points_on_wrong
                        : null,
                exercise_show_question_type: values.exercise_show_question_type === 'true',
                exam_show_question_type: values.exam_show_question_type === 'true',
            },
        };
        console.log(values, body);
        dispatch({
            ...updateCourse({
                requestType: 'PUT',
                request: `smartometer/courses/${currentCourse.id}`,
                body,
            }),
        });
    };

    const handleRemoveExercisePointsRadioChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setShowExerciseRemovePoints((event.target as HTMLInputElement).value);
    };

    const handleRemovePointsRadioChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setShowRemovePoints((event.target as HTMLInputElement).value);
    };

    return (
        <>
            <Loader active={!currentCourse}/>
            {initialValues && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={handleSubmit}
                >
                    {({handleSubmit, handleChange, values, errors}) => (
                        <Form
                            onSubmit={handleSubmit}
                            style={{width: '100%'}}
                            size="big"
                        >
                            <h2 className={classes.Title}>
                                {translate('authoring.authoringSettings.courseSettings')}
                            </h2>
                            <h3 className={classes.SubTitle}>
                                {' '}
                                {translate('authoring.authoringSettings.examSettings')}{' '}
                            </h3>
                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                {translate('authoring.authoringSettings.examDuration')}
								</span>
                                <Grid.Column width={6}>
                                    <Form.Input
                                        size="small"
                                        placeholder={translate('authoring.authoringSettings.inMinutes')}
                                        name="exam_time"
                                        value={values.exam_time}
                                        onChange={handleChange}
                                    />
                                    <p className={classes.Error}>
                                        {errors.exam_time || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.showAmountCorrectAnswers')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exam_show_right_answers"
                                        value={String(
                                            values.exam_show_right_answers,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exam_show_right_answers || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.showAnswerType')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exam_show_question_type"
                                        value={String(
                                            values.exam_show_question_type,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exam_show_question_type ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.percentageForPassing')}
								</span>
                                <Grid.Column width={6}>
                                    <Form.Input
                                        className={classes.inputNumber}
                                        size="small"
                                        placeholder={translate('authoring.authoringSettings.percent')}
                                        name="exam_percentage_for_pass"
                                        type="number"
                                        max="100"
                                        min="1"
                                        value={values.exam_percentage_for_pass}
                                        onChange={handleChange}
                                    />
                                    <p className={classes.Error}>
                                        {errors.exam_percentage_for_pass || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                {translate('authoring.authoringSettings.maxQuestions')}
								</span>
                                <Grid.Column width={6}>
                                    <Form.Input
                                        size="small"
                                        placeholder={`${translate('ex')} 20`}
                                        name="exam_max_questions"
                                        value={values.exam_max_questions}
                                        onChange={handleChange}
                                    />
                                    <p className={classes.Error}>
                                        {errors.exam_max_questions || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.removePoints')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exam_remove_points_on_wrong"
                                        value={showRemovePoints}
                                        onChange={handleRemovePointsRadioChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                        {showRemovePoints === 'true' && (
                                            <Form.Input
                                                size="small"
                                                placeholder={`${translate('ex')} 2`}
                                                name="exam_remove_points_on_wrong"
                                                value={
                                                    values.exam_remove_points_on_wrong
                                                }
                                                onChange={handleChange}
                                            />
                                        )}
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exam_remove_points_on_wrong ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.showIntroduction')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exam_show_introduction"
                                        value={String(
                                            values.exam_show_introduction,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exam_show_introduction || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <h3 className={classes.SubTitle}>
                            {translate('authoring.authoringSettings.exerciseSettings')}
                            </h3>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.percentageForPassingExercise')}
								</span>
                                <Grid.Column width={6}>
                                    <Form.Input
                                        className={classes.inputNumber}
                                        size="small"
                                        placeholder={translate('authoring.authoringSettings.percent')}
                                        name="exercise_percentage_for_pass"
                                        type="number"
                                        min="1"
                                        max="100"
                                        value={values.exercise_percentage_for_pass}
                                        onChange={handleChange}
                                    />
                                    <p className={classes.Error}>
                                        {errors.exercise_percentage_for_pass || ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.showAmountCorrectAnswers')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exercise_show_right_answers"
                                        value={String(
                                            values.exercise_show_right_answers,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exercise_show_right_answers ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.showAnswerType')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exercise_show_question_type"
                                        value={String(
                                            values.exercise_show_question_type,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exercise_show_question_type ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.maxAmountQuestions')}
								</span>
                                <Grid.Column width={6}>
                                    <Form.Input
                                        size="small"
                                        placeholder={`${translate('ex')} 10`}
                                        name="exercise_max_questions"
                                        value={values.exercise_max_questions}
                                        onChange={handleChange}
                                    />
                                    <p className={classes.Error}>
                                        {errors.exercise_max_questions || ''}
                                    </p>
                                </Grid.Column>
                            </div>
                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                    {translate('authoring.authoringSettings.removePoints')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exercise_remove_points_on_wrong"
                                        value={showExerciseRemovePoints}
                                        onChange={handleRemoveExercisePointsRadioChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                        {showExerciseRemovePoints === 'true' && (
                                            <Form.Input
                                                size="small"
                                                placeholder={`${translate('ex')} 2`}
                                                name="exercise_remove_points_on_wrong"
                                                value={
                                                    values.exercise_remove_points_on_wrong
                                                }
                                                onChange={handleChange}
                                            />
                                        )}
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exercise_remove_points_on_wrong ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>
                            <div className={classes.inputGroup}>
								<span className={classes.Label}>
                                {translate('authoring.authoringSettings.showIntroductionExercise')}
								</span>
                                <Grid.Column width={6}>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        name="exercise_show_introduction"
                                        value={String(
                                            values.exercise_show_introduction,
                                        )}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio/>}
                                            label={translate('no')}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio/>}
                                            label={translate('yes')}
                                        />
                                    </RadioGroup>
                                    <p className={classes.Error}>
                                        {errors.exercise_show_introduction ||
                                        ''}
                                    </p>
                                </Grid.Column>
                            </div>

                            <Button
                                title={translate('authoring.authoringSettings.saveQuestion')}
                                type="solid"
                                color={color.link}
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default AuthoringSettings;
