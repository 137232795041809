import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes} from "../../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    Button: {
        float: "right",
    },
    Clarify: {
        paddingLeft: 32,
        paddingRight: 32,
        color: "#9C9C9C",
        fontSize: "12px",
        whiteSpace: "pre-line"
    }
}))
