import { makeStyles } from '@material-ui/core/styles';
import { textSizes, color } from '../../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	questionWrapper: {
		marginBottom: 40,
	},
	question: {
		fontSize: textSizes.bodyLarge,
	},
	VerifiedQuestionRight: {
		'& .MuiIconButton-label, .MuiFormControlLabel-label': {
			color: color.success,
		},
	},
	VerifiedQuestionWrong: {
		'& .MuiIconButton-label, .MuiFormControlLabel-label': {
			color: color.error,
		},
	},
	VerifiedQuestionMissed: {
		'& .MuiIconButton-label, .MuiFormControlLabel-label': {
			color: color.success,
		},
	},
}));
