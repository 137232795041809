import { makeStyles } from '@material-ui/core/styles';
import {
	borderRadius,
	color,
	lineHeightSizes,
	textSizes,
} from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	selectAll: {
		position: 'absolute',
		top: 0,
		width: 50,
	},
	label: {
		position: 'relative',
	},
	Multiselect: {
		height: 35,
		borderRadius: '18px !important',
		border: '1px solid',
		borderColor: `${color.appTextSecondary} !important`,
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'inline-flex !important',
		minWidth: '180px !important',
		width: 'auto !important',
		marginTop: 0,
		marginBottom: '0 !important',
		marginLeft: 15,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& i': {
			paddingTop: '8px !important',
		},

		'&.active': {
			borderColor: '#96c8da !important',
		},
		'& div.text.divider': {
			paddingRight: 30,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			lineHeight: '35px',
			textOverflow: 'ellipsis',
			maxWidth: 180,
		},
	},
	HeaderComponent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	FilterComponent: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	datepicker: {
		height: 35,
		padding: '0 !important',
		minHeight: 'auto !important',
		display: 'inline-flex !important',
		minWidth: '230px !important',
		width: 'auto !important',
		marginBottom: '0 !important',
		marginLeft: 15,
		marginTop: 0,
		'& .label': {
			background: color.lightBlue,
			height: '24px',
			lineHeight: '10px !important',
			padding: '0 4px !important',
			marginLeft: '4px !important',
			marginRight: '0 !important',
		},
		'& .MuiInput-root': {
			height: '35px',
		},
		'& i': {
			paddingTop: '8px !important',
		},
	},
	FilterButton: {
		height: 35,
		position: 'relative',
		marginRight: 0,
		marginLeft: 0,
		'& .MuiFormControlLabel-label': {
			color: color.darkGray,
		},
		'& img': {
			height: 12,
			width: 12,
			marginRight: 8,
		},
		'& svg': {
			marginRight: 4,
		},
	},
	CourseFinishedIcon: {
		width: 20,
		height: 20,
		color: color.success,
	},
	CourseUnfinishedIcon: {
		width: 20,
		height: 20,
		color: color.appTextSecondary,
	},
	searchAndFilterInfo: {
		fontSize: textSizes.bodyNormal,
		color: color.lightGray,
		lineHeight: lineHeightSizes.searchinfo,
		display: 'inline-block',
		marginRight: '10px',
		'&:last-of-type': {
			marginRight: '37px',
		},
	},
	missedDeadline: {
		padding: '4px 8px',
		background: color.not_published,
		borderRadius: borderRadius.medium,
		display: 'inline',
	},
}));
