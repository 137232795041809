import React from 'react';
import {Dropdown, DropdownProps, Form, Grid, Input, Label, Message} from 'semantic-ui-react';
import {InstitutionGeneralInterface} from '../../../../interfaces/institution-general';
import {validationSchema} from './validationSchema';
import {settingSelector, updatesetting} from '../../../../store/settings/settingSlice';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useStyles} from './styles';
import {translate} from '../../../../i18n/i18n';
import {COUNTRY_OPTIONS} from '../countriesData';

import {Formik} from 'formik';
import {useDispatch, useSelector} from "react-redux";

import Button from '../../../../components/shared/Button';
import { color } from '../../../../utils/styles';

interface editGeneralForm {
	initialValues: InstitutionGeneralInterface;
}

const EditGeneralForm: React.FunctionComponent<editGeneralForm> = ({
	initialValues,
}) => {
	const classes = useStyles();
    const {isLoading, error} = useSelector(settingSelector);
	const dispatch = useDispatch();

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={async (values) => {
					const {
						subdomain,
						institution_name,
						street,
						number,
						postal_code,
						city,
						country,
						vat_id,
						first_name,
						last_name,
						contact_email,
						phone,
						feedback_email,
					} = values;

					let data = {
						subdomain,
						institution_name,
						address: {
							street,
							number,
							postal_code,
							country,
							city,
						},
						vat_id,
						first_name,
						last_name,
						contact_email,
						phone,
						feedback_email,
					} as any;

					if (!values.invoiceProps) {
						values.invoice_street = '';
						values.invoice_number = '';
						values.invoice_postal_code = '';
						values.invoice_city = '';
					}

					let {
						invoice_street,
						invoice_number,
						invoice_postal_code,
						invoice_city,
					} = values;
					data = {
						...data,
						address: {
							...data.address,
							invoice_street,
							invoice_number,
							invoice_postal_code,
							invoice_city,
						},
					};

					dispatch({
                        ...updatesetting({
                            requestType: 'PUT',
                            request: `smartometer/institution`,
                            body: data
                        })
                    })
				}}
			>
				{({
					handleSubmit,
					handleChange,
					values,
					errors,
					isSubmitting,
					isValid,
					dirty,
					setFieldValue,
				}) => {
					const handleSelectChange = (
						e: any,
						dropdown: DropdownProps,
					) => setFieldValue(dropdown.name, dropdown.value);
					return (
						<Form
							onSubmit={handleSubmit}
							style={{ width: '100%' }}
							size="big"
                            className={"Form"}
						>
							<Grid className={classes.FormContainer}>
								<Grid.Column width={12}>
									<p className={classes.Title}>
										{translate(`settings.general.ownSubdomain`)}
									</p>
								</Grid.Column>
								<Grid.Column width={12}>
									<Input
										size="small"
										disabled={!!initialValues.subdomain}
										placeholder={translate(
											`settings.general.domain`,
										)}
										name="subdomain"
										value={values.subdomain}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									>
										<Label>http://</Label>
										<input />
										<Label>.smartometer.de</Label>
									</Input>
									<p className={classes.Note}>
										Über den{' '}
										<a className={classes.Link} onClick={ ()=> {
                                            // @ts-ignore
                                            window?.triggerFreshdesk();
                                        }}>
											{' '}
											smartOmeter-Support{' '}
										</a>{' '}
										änderbar.
									</p>
								</Grid.Column>
								<Grid.Column width={12}>
									<p className={classes.Title}>Firma</p>
								</Grid.Column>
								<Grid.Column width={12}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.name`,
										)}
										name="institution_name"
										value={values.institution_name}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
									<p className={classes.Error}>
										{' '}
										{errors.institution_name || ''}{' '}
									</p>
								</Grid.Column>
								<Grid.Column width={8}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.street`,
										)}
										name="street"
										value={values.street}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
									<p className={classes.Error}>
										{' '}
										{errors.street || ''}
									</p>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.number`,
										)}
										name="number"
										value={values.number}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
									<p className={classes.Error}>
										{' '}
										{errors.number || ''}
									</p>
								</Grid.Column>

								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.post_code`,
										)}
										name="postal_code"
										value={values.postal_code}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
									<p className={classes.Error}>
										{' '}
										{errors.postal_code || ''}
									</p>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.city`,
										)}
										name="city"
										value={values.city}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
									<p className={classes.Error}>
										{errors.city || ''}
									</p>
								</Grid.Column>
								<Grid.Column width={6}>
									<Dropdown
                                        className={classes.country}
										placeholder={translate('tabs.editGeneralForm.selectCountry')}
										selection
										search
										name="country"
										value={values.country}
										onChange={handleSelectChange}
										options={COUNTRY_OPTIONS}
                                        style={{
                                            fontSize: "16px"
                                        }}

									/>
									<p className={classes.Error}>
										{errors.country || ''}
									</p>
								</Grid.Column>
								<Grid.Column width={6}></Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.vat_id`,
										)}
										name="vat_id"
										value={values.vat_id}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
								<Grid.Column width={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={values.invoiceProps}
												onChange={handleChange}
												name="invoiceProps"
												color="primary"
											/>
										}
										label={translate('tabs.editGeneralForm.billingAddress')}
									/>
								</Grid.Column>
								{values.invoiceProps && (
									<>
										<Grid.Column width={8}>
											<Form.Input
												size="small"
												placeholder={translate(
													`settings.general.invoice_street`,
												)}
												name="invoice_street"
												value={values.invoice_street}
												onChange={handleChange}
                                                style={{
                                                    fontSize: "16px"
                                                }}
											/>
											<p className={classes.Error}>
												{errors.invoice_street || ''}
											</p>
										</Grid.Column>

										<Grid.Column width={4}>
											<Form.Input
												size="small"
												placeholder={translate(
													`settings.general.invoice_number`,
												)}
												name="invoice_number"
												value={values.invoice_number}
												onChange={handleChange}
                                                style={{
                                                    fontSize: "16px"
                                                }}
											/>
											<p className={classes.Error}>
												{errors.invoice_number || ''}
											</p>
										</Grid.Column>

										<Grid.Column width={6}>
											<Form.Input
												size="small"
												placeholder={translate(
													`settings.general.invoice_post_code`,
												)}
												name="invoice_postal_code"
												value={
													values.invoice_postal_code
												}
												onChange={handleChange}
                                                style={{
                                                    fontSize: "16px"
                                                }}
											/>
											<p className={classes.Error}>
												{errors.invoice_postal_code ||
													''}
											</p>
										</Grid.Column>
										<Grid.Column width={6}>
											<Form.Input
												size="small"
												placeholder={translate(
													`settings.general.invoice_city`,
												)}
												name="invoice_city"
												value={values.invoice_city}
												onChange={handleChange}
                                                style={{
                                                    fontSize: "16px"
                                                }}
											/>
											<p className={classes.Error}>
												{errors.invoice_city || ''}
											</p>
										</Grid.Column>
									</>
								)}
								<Grid.Column width={12}>
									<p className={classes.Title}>
										{translate('tabs.editGeneralForm.contactPerson')}
									</p>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.first_name`,
										)}
										name="first_name"
										value={values.first_name}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.last_name`,
										)}
										name="last_name"
										value={values.last_name}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.email`,
										)}
										name="contact_email"
										value={values.contact_email}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.phone`,
										)}
										name="phone"
										value={values.phone}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
								<Grid.Column width={12}>
									<p className={classes.Title}>
										Feedback E-Mail
									</p>
								</Grid.Column>
								<Grid.Column width={6}>
									<Form.Input
										size="small"
										placeholder={translate(
											`settings.general.email`,
										)}
										name="feedback_email"
										value={values.feedback_email}
										onChange={handleChange}
                                        style={{
                                            fontSize: "16px"
                                        }}
									/>
								</Grid.Column>
							</Grid>
							{error && (
								<Message error content={error} />
							)}
							<Button
                                title={translate(`save`)}
                                type="solid"
                                color={color.link}
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            />
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default EditGeneralForm;
