import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	kpi: {
		marginLeft: 32,
		marginRight: 32,
		fontSize: 40,
	},
	noDataWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		padding: 20,
		'& svg': {
			height: 50,
			width: 50,
			color: color.lightGray,
		},
		'& span': {
			color: color.lightGray,
		},
	},
}));
