import React from 'react';
import { useStyles } from './style';

interface DeviceToggleButtonProps {
	type: string;
	active: string;
	handleSetDevice: Function;
}

const DeviceToggleButton: React.FunctionComponent<DeviceToggleButtonProps> = ({
	type,
	active,
	handleSetDevice,
}) => {
	const classes = useStyles();

	return (
		<div
			onClick={
				type !== active
					? () => handleSetDevice('device', type)
					: () => {}
			}
			className={`${classes.DeviceToggle} ${
				type === active ? classes.DeviceToggleActive : null
			}`}
		>
			{type}
		</div>
	);
};

export default DeviceToggleButton;
