import {translate} from '../../../i18n/i18n';

export const validateOrderWordsQuestions = (name:string, answers:any) => {
    let errors = []
    

    if (!name) {
        errors.push(translate('helpers.orderWordsQuestions.nameValidation'))
    }
    if (!answers.length) {
        errors.push(translate('helpers.orderWordsQuestions.questionValidation'))
    }
    
    return errors
}
