export enum courseBulkFunctions {
    ARCHIVE = "ARCHIVE",
    RESTORE = "RESTORE"
}

export enum ContentTypes {
    CONTENT = "CONTENT",
    EXERCISE = "EXERCISE",
    EXAM = "EXAM"
}

export enum CourseTextTypes {
    SMARTOMETER = "SMARTOMETER",
    GENERAL_INSTRUCTIONS = "GENERAL_INSTRUCTIONS",
    WRITTEN_EXAM_INSTRUCTIONS = "WRITTEN_EXAM_INSTRUCTIONS",
    ORAL_EXAM_INSTRUCTIONS = "ORAL_EXAM_INSTRUCTIONS",
    PARNTER_PAGE = "PARNTER_PAGE",
    THANKS_PAGE = "THANKS_PAGE"
}
