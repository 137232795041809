import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";


export interface RoleInterface {
    id?: number,
    name?: string,
    FE_SOM_DASHBOARD?: boolean,
    FE_SOM_USERS?: boolean,
    FE_SOM_COURSES?: boolean,
    FE_SOM_SETTINGS?: boolean,
}

interface initialStateInterface {
    data:  any,
    role: RoleInterface,
    rolesIsLoading: boolean,
    reload: boolean,
    rolesHasError: string
}

const initialState: initialStateInterface = {
    data:  {},
    role: <RoleInterface> {},
    rolesIsLoading: false,
    reload: true,
    rolesHasError: ""
};

export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        fetchRoles: (state, action: any) => {
            state.rolesIsLoading = true;
            state.reload = false;
            if (action.state === 'finished' && !!action.response.success) {
                state.data = {...action.response.success}
                state.rolesIsLoading = false;
                state.rolesHasError = "";
            } else if (action.state === 'error') {
                state.rolesIsLoading = false;
                state.rolesHasError = 'Request error';
            }
        },
        fetchRole: (state, action: any) => {
            state.rolesIsLoading = true;
            state.reload = false;
            if (action.state === 'finished' && !!action.response.success) {
                state.role = {...action.response.success}
                state.rolesIsLoading = false;
                state.rolesHasError = "";
            } else if (action.state === 'error') {
                state.rolesIsLoading = false;
                state.rolesHasError = 'Request error';
            }
        },
        editRole: (state, action: any) => {
            state.rolesIsLoading = true;
            if (action.state === 'finished' && !!action.response.success) {
                state.role = {};
                state.rolesIsLoading = false;
                state.reload = true;
                state.rolesHasError = "";
            } else if (action.state === 'error') {
                state.rolesIsLoading = false;
                state.rolesHasError = 'Request error';
            }
        },
        createRole: (state, action: any) => {
            state.rolesIsLoading = true;
            if (action.state === 'finished' && !!action.response.success) {
                state.role = {};
                state.rolesIsLoading = false;
                state.reload = true;
                state.rolesHasError = "";
            } else if (action.state === 'error') {
                state.rolesIsLoading = false;
                state.rolesHasError = 'Request error';
            }
        },
        deleteRole: (state, action: any) => {
            state.rolesIsLoading = true;
            if (action.state === 'finished' && !!action.response.success) {
                state.role = {};
                state.rolesIsLoading = false;
                state.reload = true;
                state.rolesHasError = "";
            } else if (action.state === 'error') {
                state.rolesIsLoading = false;
                state.rolesHasError = 'Request error';
            }
        },
        clearRole: (state) => {
            state.role = {};
        },
    }
})

export const { fetchRoles, editRole, fetchRole, deleteRole, clearRole, createRole } = roleSlice.actions;
export const roleSelector = (state: RootState) => state.role;
export const roleIsLoadingSelector = (state: RootState) => state.role.rolesIsLoading;
export default roleSlice.reducer
