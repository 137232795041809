import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";


const initialState = {
    data: {},
    single: null,
    trigger: false,
    isLoading: false,
    error: "",
};

export const assignmentSlice = createSlice({
    name: "assignment",
    initialState,
    reducers: {
        fetchAssignments: (state, action: any) => {
            if (action.state === 'send') {
				state.isLoading = true;
			}
            if (action.state === 'finished' && !!action.response.success) {
                state.data = {...action.response.success}
                state.isLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        fetchAssignment: (state, action: any) => {
            if (action.state === 'send') {
				state.isLoading = true;
			}
            if (action.state === 'finished' && !!action.response.success) {
                state.single = action.response.success.pop();
                state.isLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        addAssignment: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                const { success, data } = action.response;
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        },
        updateAssignment: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        },
        deleteAssignment: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        }
    }
})

export const { fetchAssignment, fetchAssignments, addAssignment, updateAssignment, deleteAssignment } = assignmentSlice.actions;
export const assignmentSelector = (state: RootState) => state.assignment;
export const groupLoadingSelector = (state: any) => state.group.isLoading;
export default assignmentSlice.reducer
