import React, { useState, useEffect, useRef } from 'react';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	CircularProgress,
	Dialog,
	LinearProgress,
	TextField,
} from '@material-ui/core';
import { useStyles } from './styles';
import Close from '@material-ui/icons/Close';
import { translate } from '../../../i18n/i18n';

interface MultiSelectProps {
	options: any[];
	selected: number[];
	handleChange: Function;
	inputTitle: string;
	modalTitle: string;
	maxItems?: number;
	bordered?: boolean;
	type?: string;
	position?: string;
	searchLoading?: boolean;
	handleSearch?: Function;
	style?: {};
}

const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
	options,
	selected,
	handleChange,
	inputTitle,
	type = 'filter',
	maxItems = 1,
	style,
	modalTitle,
	bordered,
	position = 'table',
	searchLoading,
	handleSearch,
}) => {
	const classes = useStyles();

	const [isOpen, setIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [preSelected, setPreSelected] = useState<number[]>(selected);
	const [renderedItems, setRenderedItems] = useState<any>();
	let searchTimeout = useRef<any>(null);

	useEffect(() => {
		if (handleSearch) {
			clearTimeout(searchTimeout.current);
			searchTimeout.current = setTimeout(() => {
				handleSearch(searchValue);
			}, 1000);
		}
	}, [searchValue]);

	useEffect(() => {
		if (!searchLoading && options) {
			const items: any[] = [];
			const filteredOptions = options.filter((item) => {
				const searchValTrimmed = searchValue.trim().toLowerCase();
				return (
					item.text
						.replace(' ', '')
						.toLowerCase()
						.indexOf(searchValTrimmed) > -1 &&
					preSelected.indexOf(item.value) === -1
				);
			});
			filteredOptions.map((option, index) =>
				items.push(
					<p
						key={index}
						className={classes.OptionItem}
						onClick={() => handlePreselect(option.value)}
					>
						{option.text}
						<Close />
					</p>,
				),
			);
			setRenderedItems(
				<>
					<h4 className={classes.FilterHeadline}>
						{!!items.length
							? !!searchValue
								? 'Suchergebnisse'
								: `Alle ${inputTitle}`
							: `Keine ${inputTitle} gefunden.`}
					</h4>
					<div className={classes.FilteredOptions}>{items}</div>
				</>,
			);
		}
	}, [searchLoading, preSelected]);

	const handleOpenModal = () => {
		setPreSelected(selected);
		setSearchValue('');
		setIsOpen(true);
	};

	const renderFilteredOptions = () => {
		if (options) {
			const items: any[] = [];
			const filteredOptions = options.filter((item) => {
				const searchValTrimmed = searchValue.trim().toLowerCase();
				return (
					item.text
						.replace(' ', '')
						.toLowerCase()
						.indexOf(searchValTrimmed) > -1 &&
					preSelected.indexOf(item.value) === -1
				);
			});
			filteredOptions.map((option, index) =>
				items.push(
					<p
						key={index}
						className={classes.OptionItem}
						onClick={() => handlePreselect(option.value)}
					>
						{option.text}
						<Close />
					</p>,
				),
			);
			return (
				<>
					<h4 className={classes.FilterHeadline}>
						{!!items.length
							? !!searchValue
								? 'Suchergebnisse'
								: `Alle ${inputTitle}`
							: `Keine ${inputTitle} gefunden.`}
					</h4>
					<div className={classes.FilteredOptions}>{items}</div>
				</>
			);
		}
	};

	const renderSelectedOptions = () => {
		const filteredOptions = options.filter(
			(item: any) => selected.indexOf(item.value) > -1,
		);
		const renderedOptions =
			maxItems >= filteredOptions.length
				? filteredOptions
				: filteredOptions.slice(0, maxItems);
		return (
			<>
				{renderedOptions.map((item: any) => {
					const displayedText =
						item.text.length > 15
							? item.text.substr(0, 15) + '...'
							: item.text;

					return (
						<p
							className={classes.SelectedOptionPreview}
							key={item.value}
						>
							{displayedText}
						</p>
					);
				})}
				{selected.length > maxItems && (
					<span className={classes.SelectedOptionCounter}>
						{maxItems > 0 ? '+' : ''}
						{selected.length - maxItems}
					</span>
				)}
			</>
		);
	};

	const renderPreSelectedItems = () => {
		return (
			<>
				<p
					onClick={() => setPreSelected([])}
					className={`${classes.SelectedOption} ${classes.RemoveAllFilters}`}
				>
					<Close />
					Filter entfernen
				</p>
				{preSelected.map((id, index) => {
					const optionItem = options.find(
						(option) => option.value === id,
					);
					if (!optionItem) return;
					const displayedText =
						optionItem.text.length > 15
							? optionItem.text.substr(0, 15) + '...'
							: optionItem.text;
					return (
						<p
							key={index}
							onClick={() => handleDeleteOne(id)}
							className={`${classes.SelectedOption}`}
						>
							<Close />
							{displayedText}
						</p>
					);
				})}
			</>
		);
	};

	const handlePreselect = (id: number) => {
		setPreSelected([...preSelected, id]);
	};

	const handleDeleteOne = (id: number) => {
		const newPreSelected = [...preSelected];
		newPreSelected.splice(newPreSelected.indexOf(id), 1);
		setPreSelected(newPreSelected);
	};

	const handleSaveSelection = () => {
		handleChange(preSelected);
		setIsOpen(false);
	};

	return (
		<>
			<div
				className={`${classes.SelectionPreview} ${
					bordered ? classes.SelectionPreviewBordered : ''
				} ${
					position === 'modal' ? classes.SelectionPreviewModal : ''
				} multiselect-wrapper`}
				style={style ? { ...style } : {}}
			>
				{!selected.length ? (
					<p className={classes.SelectionPreviewPlaceholder}>
						{inputTitle}: {translate(`shared.multiselect.all`)}
						<FontAwesomeIcon
							className={`${classes.FilterIcon} filter-icon`}
							icon={type === 'search' ? faSearch : faFilter}
							onClick={handleOpenModal}
						/>
					</p>
				) : (
					<p className={classes.SelectionPreviewSelected}>
						{inputTitle}:{renderSelectedOptions()}
						<FontAwesomeIcon
							className={`${classes.FilterIcon} filter-icon`}
							icon={type === 'search' ? faSearch : faFilter}
							onClick={handleOpenModal}
						/>
					</p>
				)}
			</div>
			<Dialog open={isOpen} onClose={() => setIsOpen(false)}>
				<div className={classes.Modal}>
					<div className={classes.ModalHeader}>
						<h2>{modalTitle}</h2>
						<p onClick={handleSaveSelection}>
							{translate(`shared.multiselect.save`)}
						</p>
					</div>
					<TextField
						className={classes.TextField}
						label={translate(`shared.multiselect.search`)}
						variant="outlined"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<div style={{ position: 'relative', minHeight: 200 }}>
						{searchLoading && <LinearProgress />}
						<>
							<div className={classes.ModalSelectionWrapper}>
								{preSelected &&
									!!preSelected.length &&
									renderPreSelectedItems()}
							</div>
							{handleSearch
								? renderedItems
								: renderFilteredOptions()}
						</>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default MultiSelect;
