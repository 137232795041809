export default {
	translation: {
		basics: {
			clear: 'Clear',
		},
		app: {
			header: {
				personalArea: 'Personal area',
				courses: 'Courses',
				practice: 'Practice',
			},
			courseIntro: {
				courseIntroduction: 'Course introduction',
				topics: 'Topics',
				courseStatistics: 'Course statistics',
			},
			course: {
				nextContent: 'Next Content',
				courseEnd: 'Course End',
				readingTime: 'minute(s) estimated Readingtime',
				backToStart: 'Back to start',
			},
			examCounter: {
				timeRemaining: 'Remaining time:',
			},
			exerciseWizard: {
				correctAnswer: 'Correct answer!',
				wrongAnswer: 'Wrong answer.',
				exam: 'Exam',
				exercise: 'Exercise',
			},
			quitExerciseModal: {
				finish: 'Finish {{ type }}',
				timeExpired: 'Exam time expired',
				finishVerify: 'Really finish {{ type }}? ',
				resultWillBeSaved: 'Your results will be saved.',
				resultWillNotBeSaved: 'Your achieved points will not be saved.',
				yourResult: 'Your results',
				close: '{{ type }} {{ value }}',
				cancel: 'Cancel',
				finishButton: 'finish',
				closeButton: 'close',
			},
			results: {
				exam: 'Exam',
				exercise: 'Exercise',
				passed: 'passed',
				notPassed: 'not passed',
				congratsFinished: 'Congratulations you finished the',
				succsessfully: 'successfully!',
				toPass: 'To pass the',
				youNeed: 'you need to achieve at least',
				points: 'points',
				passEnd: '.',
				pointsLabel: 'Points',
				showResult: 'Show results',
				continue: 'Continue',
			},
			splash: {
				exam: 'Exam',
				exercise: 'Exercise',
				loadingQuestion: 'Loading questions ...',
				exerciseContent: 'Exercise content:',
				questnions: 'Questions',
				startExam: 'Start exam',
				startExerise: 'Start exercise',
				close: 'Close',
			},
			summary: {
				summary: 'Summary',
				correctAnswer: 'Correct answers:',
			},
			finishedCourseScreen: {
				courseFinished: 'Course finished',
				congratsFinished: 'Congratulations you finished the course',
				congratsEnd: '!',
				courseProgress: 'Course progress',
				showStatistics: 'Show statistics',
				close: 'Back',
			},
			navigation: {
				learn: 'Learn',
				exercise: 'Exercise',
				course: 'Courses',
				user: 'User',
			},
			filterModal: {
				currentCourse: 'Current courses',
				finishedCourse: 'Finished courses',
				save: 'Select',
				showStatistics: 'Show statistics',
				selectCourse:
					'Select the course you want to see the statistics for.',
			},
			selectCourseModal: {
				changeCourse: 'Change course',
				selectCourseContent:
					'Select the course you want to see the content for. ',
			},
			appCourseOverviewTab: {
				currentCourse: 'Current courses',
				finishedCourse: 'Finished courses',
			},
			appCourse: {
				courses: 'Courses',
				statistics: 'Statistics',
			},
			appExercise: {
				exercises: 'Exercises',
				exams: 'Exams',
				noContent: 'No exercises or exams found.',
			},
			appLearn: {
				exercisesAndExams: 'Exercises & Exams',
			},
			successModal: {
				imageSuccess: 'Successfully uploaded image',
				FeedbackSuccess: 'Successfully sent feedback',
				PasswordSuccess: 'Successfully changed password',
			},
			changeName: {
				change: 'Change',
			},
			selectCourse: {
				title: 'Select course',
				description:
					'Wählen Sie den Kurs, dessen Lerninhalte angezeigt werden sollen.',
				logout: 'Logout',
				selectCard: {
					active: 'AKTIV',
					start: 'START',
					end: 'ENDE',
				},
				currentCourse: 'Current courses',
				finishedCourse: 'Finished courses',
				endedCourse: 'Missed deadline courses',
				futureCourse: 'Future courses',
				updateCourseButton: 'Auswahl speichern',
			},
			profile: {
				changeName: {
					firstName: 'VORNAME',
					lastName: 'NACHNAME',
					title: 'Name ändern',
					description: 'Bitte geben Sie Ihre neuen Daten an.',
					networkingIssues: 'Networking issue',
					submit: 'Speichern',
				},
				ChangePassword: {
					oldPassword: 'Aktuelles Passwort',
					newPassword: 'Neues Passwort',
					confirmPassword: 'Neues Passwort wiederholen',
					title: 'Passwort ändern',
					description:
						'Bitte geben Sie Ihr altes sowie neues Passwort an, um es zu ändern.',
					networkingIssues: 'Wrong password',
					submit: 'Speichern',
				},
				feedback: {
					label: 'Dein Feedback',
					submit: 'Feedback senden',
				},
				successModal: {
					submit: 'Close',
				},
				actions: {
					label: 'Actions',
					logout: 'Log Out',
					headline: 'No course found',
					content:
						"You haven't been assigned to a course yet. Contact your administrator or our support if you think this is a mistake.",
					yourAdmin: 'Your administrator',
				},
			},
			noCourse: {
				back: 'Go to Dashboard',
				logout: 'Log Out',
			},
		},
		tooltip: {
			back: 'Back',
			delete: 'Delete',
			edit: 'Edit',
			archive: 'Archive',
			restore: 'Restore',
			search: 'Search',
			add: 'Add',
			create: 'Create',
			globalSearch: 'Global search',
		},
		ALL: 'All',
		delete: 'delete User',
		DELETE: 'Delete Selection',
		ARCHIVE: 'Archive Selection',
		RESTORE: 'Restore Selection',
		ASSIGN_COURSE: 'Assign to course',
		ASSIGN_GROUP: 'Assign to group',
		callBack: 'Request callback',
		withExampleData: 'Start with example data',
		withoutExampleData: 'Start without example data',
		removeExampleData: 'Remove example data',
		notRemoveExampleData: 'Keep example data',
		search: 'Search ...',
		editProfile: {
			myProfile: 'My Profile',
			firstNamePlaceholder: 'Alexander',
			secondNamePlaceholder: 'Alexander',
			cancel: 'Abbrechen',
			save: 'Speichern',
		},
		authentication: {
			header: 'Welcome to aPPucations',
			subhead: 'Join our growing community',
			name: 'Your name',
			email: 'Email',
			password: 'Password',
			repeatPassword: 'Repeat Password',
			forgottenPassword: 'Forgotten Password?',
			alreadyHaveAccount: 'Already have account?',
			stillDontHaveAccount: "Still don't have account?",
			backToLogin: 'Back to login',
			logIn: 'Log in',
			checkMail:
				'Please check your inbox. We just sent you an e-mail with a verification link.',
			checkMailPassword:
				'Please check your inbox. We just sent you an e-mail with a password reset link.',
			login: {
				error: 'E-mail or password not found.',
			},
			register: {
				header: 'Sign Up',
				cta: 'Register',
				firstname: 'Firstname',
				lastname: 'Lastname',
				email: 'E-Mail',
				institution: 'Institution',
				password: 'Password',
				passwordCompare: 'Repeat Password',
				setPassword: 'Set Password',
				setPasswordInfo:
					'Please set a password to complete account activation.',
				fieldRequired: 'Field is required',
				acceptance:
					"I have read the <a href='https://www.smartometer.de/agb/' target='_blank'>Terms</a> and <a href='https://www.smartometer.de/datenschutz/' target='_blank'>Privacy Policy</a> and agree.",

				errors: {
					firstname: 'Firstname',
					lastname: 'Lastname',
					email: 'E-Mail',
					institution: 'Institution',
					password: 'Password',
					passwordCompare: 'Repeat Password',
					passwordCompareNoMatch: "Passwords don't match",
					passwordRules:
						'8 Characters required, of which 1 Uppercase, one Lowercase and one Numeric character. ',
				},
			},
			menu: {
				privacy: 'Privacy Policy',
				contact: 'contact',
				terms: 'Terms of Service',
			},
		},
		or: 'or',
		hello: 'Hello',
		headerTitle: 'SmartoMeter',
		firstName: 'First Name',
		name: 'Name',
		myProfile: 'My Profile',
		addStudent: 'Add Student',
		//navbar
		overview: 'Overview',
		qualifications: 'Qualifications',
		student: 'Student',
		autorentool: 'Authoring tool',
		loginHeader: 'Login',
		password: 'Password',
		forgetPassword: 'Forgot Password?',
		login: 'Login',
		resetPassword: 'Reset Password',
		resetPasswordInfo:
			'Please enter your e-mail address. You will receive an e-mail with instructions how to reset your password.',
		reset: 'Reset',
		goToLogin: 'Go to Login',
		logout: 'Logout',
		formError: 'Form Error',
		lastName: 'Surname',
		email: 'E-Mail',
		close: 'Close',
		save: 'Save',
		cancel: 'Cancel',
		authoring: {
			exerciseList: {
				questions: 'Questions',
				deleteQuestionInfo: 'Delete selected question.',
				deleteQuestion: 'Delete question',
				deleteQuestionConfirm:
					'Are you sure you want to delete {{ question }}?',
				detachQuestionInfo: 'Detach selected question.',
				detachQuestion: 'Detach question',
				detachQuestionConfirm:
					'Are you sure you want to detach {{ question }}',
			},
			previewContent: {
				nextContent: 'Next content',
			},
			previewExercises: {
				nextContent: 'Next content',
			},
			renderAssignTerms: {
				selectText: 'Please select an answer',
			},
			renderMultipleChoice: {
				multipleChoice: '(Multiple Choice)',
			},
			renderOrderWords: {
				selectText: 'Please select an answer',
			},
			renderSingleChoice: {
				singleChoice: '(Single Choice)',
			},
			renderWordCombinations: {
				OrderWords: '(Order Words)',
				selectAnswer: 'Please select an answer',
			},
			previewTabNavigation: {
				learning: 'Learning',
				exercise: 'Exercise',
			},
			gapFills: {
				explanation:
					'Pick the correct text parts that complete the text.',
			},
			assignPair: {
				answerExists: 'Answer already exist',
				firstWord: 'first word',
				secondWord: 'second word',
				saveQuestion: 'Save Question',
				editQuestion: 'Edit Question',
				abort: 'Cancel',
				explanation: 'Assign pairs of words.',
			},
			assignTerm: {
				answerExists: 'Answer already exist',
				firstWord: 'first word',
				secondWord: 'second word',
				saveQuestion: 'Save Question',
				editQuestion: 'Edit Question',
				abort: 'Cancel',
				explanation: 'Assign one or more words to one or more terms',
			},
			saveQuestion: 'Save Question',
			editQuestion: 'Edit Question',
			abort: 'Cancel',
			multipleChoice: {
				answerExists: 'Answer already exist',
				addAnswer: 'Add answer',
				saveQuestion: 'Save Question',
				editQuestion: 'Edit Question',
				abort: 'Abort',
				explanation:
					'Mark multiple answers as correct from multiple options.',
			},
			orderWordsSingle: {
				abort: 'Anwort bereits vorhanden',
			},
			orderWords: {
				addAnswer: 'Add answer',
				answerExists: 'Answer Already exist',
				editQuestion: 'Edit Question',
				saveQuestion: 'Save Question',
				cancel: 'Cancel',
				explanation: 'Sort the words in the correct order',
			},
			singleChoice: {
				answerExists: 'Answer already exist',
				addAnswer: 'Add answer',
				editQuestion: 'Edit Question',
				saveQuestion: 'Save Question',
				cancel: 'Cancel',
				explanation: 'Pick out of multiple options 1 correct answer.',
			},
			questionDetails: {
				singleChoice: 'Single Choice',
				multipleChoice: 'Multiple Choice',
				OrderWords: 'Order Words',
				AssignPairs: 'Assign Pairs',
				fillGaps: 'Fill Gaps',
				assignToTerm: 'Assign to terms',
				questions: 'Questions',
				enterQuestion: 'Enter here your question',
				explanation: 'Explanation',
				answer: 'Answer',
			},
			allQuestions: {
				subareas: 'Subareas',
				questionType: 'Question Type',
				selectAll: 'Select all',
				name: 'Name',
				exercise: 'Exercise',
				type: 'Type',
				newQuestion: 'New question',
				quetion: 'Questions',
				deleteSelectedQuestion: 'Delete selected question.',
				deleteQuestion: 'Delete question',
				deleteConfirm: 'Are you sure you want to detach {Question}',
				deleteQuestionVerify:
					'Do you want to delete all selected questions?',
				placeholder: 'Name',
			},
			courseDetailPage: {
				courseTitle: 'Title of the course',
				image: 'Image',
				delete: 'delete',
				upload: 'Upload',
				content: 'Content',
				generalInstructions: 'General iInstruction',
				writtenExamInstructions: 'Written exam instructions',
				oralExamInstructions: 'Oral exam instructions',
				partnerPage: 'Partner',
				thanksPage: 'Thankyou page',
			},
			sectionPage: {
				section: 'Section',
				content: 'Content',
				task: 'Task',
				createNewTask: 'Create new task',
				addToThisExam: 'Add all tasks to current exam',
				addExistingTask: 'Add existing task',
				canNotBeReverted: 'This can not be reverted.',
				delete: 'Delete',
				deleteSectionVerify: 'Do you really want to delete Section',
				deleteSectionVerifyEnd: '?',
				deleteAllQuestions:
					'Should all tasks be deleted as well? If any of the tasks are also used in other sections, they will be removed from those.',
				save: 'Save',
			},
			authoringSettings: {
				courseSettings: 'Settings of the course',
				examSettings: 'Exam settings',
				examDuration: 'Exam duration',
				showAmountCorrectAnswers:
					'Show amount of correct answers per question (multiple choice)',
				percentageForPassing: 'Percentage for passing an exam',
				percent: 'Percent',
				maxQuestions: 'Max amount of questions per exam',
				removePoints: 'Remove points for wrong answers',
				no: 'No',
				yes: 'Yes',
				showIntroduction:
					'Show introduction before the start of an exam',
				exerciseSettings: 'Exercise settings',
				percentageForPassingExercise:
					'Percentage for passing an exercise',
				maxAmountQuestions: 'Max amount of questions per exercise',
				ex: 'e.g. 10',
				showIntroductionExercise:
					'Show introduction before the start of an exercise',
				saveQuestion: 'Save Question',
				inMinutes: 'in Minutes',
				showAnswerType: 'Show question type',
			},
			layoutsAuthoring: {
				youCanRevert:
					'You can not revert this action and will impact all sub sections. ',
				publishNow: 'Publish now',
				publishVerify: 'Do you want to publish the course',
				publishVerifyEnd: '?',
			},
			questions: {
				type: 'Type',
				questionTypes: {
					ALL: 'All',
					SINGLE_CHOICE: 'SINGLE_CHOICE',
					MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
					ORDER_WORDS: 'ORDER_WORDS',
					WORD_COMBINATION: 'WORD_COMBINATION',
					ASSIGN_TERM: 'ASSIGN_TERM',
					GAP_FILLS: 'GAP_FILLS',
				},
			},
			subNavigation: {
				hide: 'hide',
				show: 'show',
				duplicate: 'duplicate',
				delete: 'delete',
				createSubSection: 'Create subsection',
			},
		},
		users: {
			assignments: {
				headline: 'Course assignments',
				createNewButton: 'New course assignment',
			},
			licenses: {
				headline: 'Licenses',
				createNewButton: 'New License',
			},
			courses: {
				courses: 'Courses',
				deleteAssignment: 'Delete assignment',
				deleteAssignmentConfirm:
					'Are you sure, that you want to delete the assignment?',
				deleteAllAssignment: 'Delete assignment',
				deleteAllAssignmentConfirm:
					'Are you sure that you want to delete all selected assignments?',
				start: 'Start',
				end: 'End',
				readingTime: 'Readingtime',
				certificate: 'Certificate',
				finished: 'Finished',
			},
			detail: {
				assignCourse: 'Assign course',
				writeEmail: 'Write email',
				readingTime: 'ø Reading time',
				results: 'ø Results',
				finishedCourses: 'Finished courses',
				missedDeadlines: 'Missed deadlines',
				selectCourse: 'Select course',
				addToCourse: 'Add to course',
				addUserToCourse: 'Add selected user to a course',
				userWillRecieveEmail:
					'This user will receive a password reset email and he will be able to change his password from there.',
				send: 'Send',
				sendPasswordEmail: 'Send reset password email to',
				courses: 'Courses',
				statistics: 'Statistics',
				userOverview: 'User Overview',
				changeDetails: 'Change details',
				resetPassword: 'Reset password',
				completed: 'Completed',
				allocated: 'Allocated',
				group: 'Group',
				none: 'None',
			},
			headline: 'User',
			users: {
				headline: 'Usersoverview',
				createNewButton: 'New user',
				importButton: 'Bulk Import',
				assignments: {
					ALL: 'All',
					WITH_ASSIGNMENT: 'Only with assignment',
					WITHOUT_ASSIGNMENT: 'Only without assignment',
				},
				deadline: {
					ALL: 'All',
					MISSED: 'Deadline missed',
					NOT_MISSED: 'Deadline not missed',
				},
				finished: {
					ALL: 'All',
					UNFINISHED: 'Unfinished',
					FINISHED: 'Finished',
				},
				edit: {
					headline: 'Edit User',
				},
				create: {
					error: {
						alreadyTaken: 'This user already exists',
						alreadyTakenOtherInstitution:
							'This user already exists in another institution',
					},
				},
				bulkCreate: {
					step1: {
						headline: 'Import all users',
						placeholder: 'select .csv file',
						or: 'or',
						exampleDownload: 'download CSV example file',
						startImportButtonText: 'Import file',
					},
					step2: {
						importing: 'File will be imported',
						success: {
							message: 'File imported',
						},
						error: {
							headline:
								'The file could not be imported successfully',
							duplicates:
								'{{count}} user(s) could not be created, because their email is already in the database. {{imported}} user(s) has been created successfully.',
							malformatted:
								'The format of the uploaded file is incorrect. Please use the template.',
							error: 'An error occurred. Please contact the support. ',
							tryAgain: 'Try again',
							generalMessage:
								'The file could not be imported completely',
							absoluteMessage: 'The file could not be imported',
						},
					},
				},
			},
			groups: {
				headline: 'Groupsoverview',
				createNewButton: 'New Group',
				assignUsers: 'Add Users',
				detail: {
					deadline: {
						ALL: 'All',
						MISSED: 'Deadline missed',
						NOT_MISSED: 'Deadline not missed',
					},
				},
			},
		},
		settings: {
			headline: 'Settings',
			header: {
				general: 'General',
				presentation: 'Presentation',
				team: 'Team',
				certificate: 'Certificates',
				invoice: 'Invoices',
			},
			team: {
				members: {
					headline: 'Teammembers',
					create: {
						headline: 'New Teammember',
					},
				},
				groups: {
					headline: 'Groups',
				},
				roles: {
					headline: 'Roles',
					clarify:
						'Please note that the roles admin, manager and author are standard roles. These cannot be customized by you. If you need another role with personalized rights, click New Role.',
					deleteMessage:
						'Are you sure, that you want to delete the role {{name}}?',
					createNewButton: 'New Role',
					modal: {
						headline: 'Define a new Role',
						namePlaceholder: 'Neue Rolle eingeben',
					},
					PERMISSION: {
						FE_SOM_DASHBOARD: 'Access to the Dashboard',
						FE_SOM_USERS: 'Access to the Usermanagement',
						FE_SOM_COURSES: 'Access to the Coursemanagement',
						FE_SOM_SETTINGS: 'Access to the Settings',
						NOT_EDITABLE: '(not editable)',
					},
				},
			},
			general: {
				ownSubdomain: 'Own subdomain',
				domain: 'domain',
				name: 'Firmenname',
				street: 'Straße',
				number: 'Nr.',
				post_code: 'PLZ',
				city: 'Ort',
				country: 'Land',
				vat_id: 'Ust-ID',
				invoice_street: 'Straße',
				invoice_number: 'Nr.',
				invoice_post_code: 'PLZ',
				invoice_city: 'Ort',
				first_name: 'Vorname',
				last_name: 'Name',
				email: 'E-Mail',
				phone: 'Telefonnummer',
			},
		},
		perPage: 'per Page',
		from: 'of',
		moreThan: 'more than',
		kpis: {
			dashboard: {
				usersAmountTitle: 'User',
				usersAmount:
					'Amount of users (learning) that could access smartOmeter courses, if they are assigned to one',
				deviceUsageTitle: 'Device usage',
				deviceUsage:
					'Device usage in percent, splitted by mobile, tablet and desktop usage.',
				allCoursesTitle: 'Courses',
				allCourses:
					'Overall amount of courses created by you or your team.',
				delaysTitle: 'Delays',
				delays: 'Amount of delayed assignments. (1 Delay = 1 course, that has not been finished by 1 user on time)',
				courseProgressTitle: 'Learning progress',
				courseProgress:
					'Course progress considering the content, exercises and exams of all currently assigned and unfinished courses. Finished courses are not considered.',
				successRateTitle: 'Successrate',
				successRate: 'Results of latest finished courses.',
				popularCoursesTitle: 'Most popular courses',
				popularCourses:
					'Courses with the highest amount of users assigned to it.',
				loginsTitle: 'Logins',
				logins: 'Amount of logins of all users to the learning app. (mobile, Tablet- und Desktopversion)',
				learningProgress: 'Learning progress',
			},
			userDetail: {
				avgLearningTime:
					'Average time that a user spends on reading the content of a course. Solving questions in exercises and exams will not be considered here.',
				avgSuccessRate:
					'Average result, by which a user has completed a course.',
				finishedCourses:
					'Amount of finished courses in relation to assigned courses.',
				delays: 'Amount of assignments that have not been completed on time. (1 delay = 1 couse that has not been finished on time by 1 user)',
			},
			userStatistics: {
				learningProgress:
					'Prozentualer Fortschritt bezogen auf das Durcharbeiten von Lerncontents/Lernabschnitten',
				readingTime:
					'Zeit, welche ein Nutzer für das Lesen der Lerncontents nutzt. Die Bearbeitung von Übungen und Prüfungen zählt nicht dazu.',
				exerciseExamNo:
					'Anzahl an durchgeführten Übungen und Prüfungen',
				courseEnd:
					'Anzahl der verbleibenden Tage, um den Kurs zu absolvieren',
			},
			groupDetail: {
				users: 'Anzahl an Nutzern (Lernenden) innerhalb der Gruppe',
				courseProgress:
					'Prozentualer Fortschritt bezogen auf das Durcharbeiten der Kurse (Lerncontent, Übung und/oder Prüfung)',
				finishedCourses:
					'Anzahl der absolvierten Kurse im Verhältnis zur Anzahl aller zugeteilten Kurse',
				delays: 'Anzahl der über das Kursende hinausgehenden und gleichzeitig noch nicht abgeschlossenen Kurse bezogen auf den einzelnen Nutzer (1 Fristüberschreitung = 1 Kurs, der von 1 Nutzer noch nicht absolviert wurde)',
			},
			courses: {
				ownCourses: 'Anzahl der eigens erstellten/angelegten Kurse',
				bookedCourses:
					'Anzahl der gebuchten Kurse (durch smartOmeter zur Verfügung gestellt)',
				licenses:
					'Anzahl der aktiven Kurslizenzen für die gebuchten Kurse',
			},
		},
		yes: 'Yes',
		no: 'No',
		help: 'Help',
		ex: 'e.g.',
		modals: {
			close: 'Close',
			addDemoDataModal: {
				header: 'Do you want to create example data?',
			},
			addFromQuestionPoolModal: {
				primaryAction: 'Add question',
				secondaryAction: 'Cancel',
				close: 'Close',
				takeTask: 'Add question',
				questionsInstitution: 'All questions',
				questionCurrentCourse: 'Questions for current course',
				taksOptions: 'Description of question...',
				withoutSubarea: 'not assigned',
			},
			addGroupModal: {
				errorNameExists: 'Group name already exists',
				validateError: 'Field is required',
				header: 'Create a new group',
				createGroup: 'Create group',
				close: 'Cancel',
			},
			addMemberModal: {
				firstNameRequired: 'Field is required',
				lastNameRequired: 'Field is required',
				emailRequired: 'Field is required',
				roleRequired: 'Field is required',
				headline: 'Settings',
				cancel: 'Cancel',
				firstname: 'Firstname',
				lastname: 'Lastname',
				email: 'Email address',
				role: 'Role',
				note: 'The password will be created by the user on the first signup.',
				save: 'Save',
			},
			addUserToGroupModal: {
				addUsersMessage: 'Add Users',
				close: 'Close',
				placeholder: 'Users',
				modalTitle: 'Select Users',
				confirm: 'Save',
			},
			addRoleModal: {
				releaseRights: 'Gebe die Rechte für diese Rolle frei',
			},
			addUserModal: {
				firstNameRequired: 'Field is required',
				lastNameRequired: 'Field is required',
				emailRequired: 'Field is required',
				header: 'Create new user',
				submit: 'Create user',
				close: 'Cancel',
				secondaryAction: 'Add another user',
				firstname: 'Firstname',
				lastname: 'Lastname',
				email: 'Email address',
				note: 'The password will be created by the user on the first signup.',
			},
			assignGroupModal: {
				close: 'Cancel',
				groups: 'Groups',
			},
			assignUserModal: {
				close: 'Cancel',
				selectUser: 'Select user',
				selectCourse: 'Select course',
				start: 'Start',
				end: 'End',
				costsArise:
					'Please note that <strong>costs arise</strong> when you assign <strong>Smartometer courses</strong> to a user or a group. There are no additional costs by assigning courses you have created yourself.',
				notificationDate:
					'The user will receive a notification email on the selected date.',
			},
			confirmModal: {
				close: 'Cancel',
			},
			deleteModal: {
				close: 'Abbrechen',
			},
			editAssignmentModal: {
				close: 'Cancel',
				start: 'Start',
				end: 'End',
			},
			editGroupModal: {
				submit: 'Save',
				close: 'Cancel',
				groupExists: 'Group already exists',
				fieldRequired: 'Field is required',
				header: 'Edit group name',
				name: 'Name',
			},
			editMemberModal: {
				firstnameRequired: 'Field is required',
				lastNameRequired: 'Field is required',
				emailRequired: 'Field is required',
				roleRequired: 'Field is required',
				headline: 'Teammembers',
				firstname: 'Firstname',
				lastname: 'Lastname',
				email: 'Email address',
				role: 'Role',
				note: 'The password will be created by the user on the first signup.',
			},
			editRoleModal: {
				nameRequired: 'Field is required',
				headline: 'Roles',
				title: 'Assign rights for this role',
			},
			editUserDataModal: {
				firstnamaRequired: 'Field is required',
				lastNameRequired: 'Field is required',
				emailRequired: 'Field is required',
				noValidEmail: 'Not a valid email address',
				myProfile: 'My profile',
				headline: 'Edit User',
				firstname: 'First Name',
				lastname: 'Last Name',
				email: 'Email address',
				groups: 'Groups',
			},
			purchaseModal: {
				unlockSmartometer: 'Unlock smartOmeter',
				contactSupport: 'Contact suppport',
				nameRequired: 'Field is required',
				emailRequired: 'Field is required',
				messageRequired: 'Field is required',
				fromRequired: 'Field is required',
				phoneRequired: 'Field is required',
				tillRequired: 'Field is required',
				selectOneDay: 'Please select at least one week day',
				noValIdEmail: 'Not a valid email address',
				contactSmartometerSupport: 'Contact smartOmeter support',
				requestActivation: 'Request unlocking of smartOmeter',
				fillDetails:
					'Please fill out a few details, to allow us to contact you. ',
				subject: 'Subject',
				message: 'Message',
				name: 'Name',
				email: 'Email address',
				phone: 'Phone',
				monday: 'Monday',
				tuesday: 'Tuesday',
				wednesday: 'Wednesday',
				thursday: 'Thursday',
				friday: 'Friday',
				weekdayAlert: 'Please select at least one week day',
				from: 'From',
				to: 'To',
			},
			purchaseOrSupportModal: {
				trialExpired: 'Your demo expired',
				bookFullVersion:
					'Did you enjoy the smartOmeter? Please book the full version and start creating courses, fast and easy for your team.',
				activateSmartometer:
					'Request unlocking smartOmeter or contact support',
			},
			questionValidationModal: {
				submit: 'Proceed',
				header: 'Your input is not valid',
			},
			removeDemoDataModal: {
				header: 'Do you want to continue using the initially created demo data?',
			},
			thankYouModal: {
				header: 'Thank you very much, we will call you back. ',
				subheader: 'We will contact you at your selected timeframe',
			},
		},
		layout: {
			exerciseCountButton: {
				tasks: 'Questions',
			},
			authoringSidebar: {
				addSection: 'Add section',
				introduction: 'Introduction',
			},
			authoringSubnavigation: {
				learningSection: 'Content section',
				exercise: 'Exercise',
				exam: 'Exam',
				selectPlaceholder: 'Please select',
			},
			authoringNavbar: {
				settings: 'Settings',
				preview: 'Preview',
				published: 'Published',
				publish: 'Publish',
				clearCache: 'Clear Cache',
				draft: 'Draft',
			},
			mainNavbar: {
				myProfile: 'My Profile',
				addUser: 'Add user',
				addCourse: 'Add course',
				addGroup: 'Add group',
				addAssignment: 'Add assignment',
				addMember: 'Add member',
				signedWith: 'Signed in as',
				trialVersion: 'Demo available for',
				upgradeNow: 'Upgrade now',
				welcomeBack: 'Welcome back,',
				noDataFound: 'No data found',
				addToCourse: 'Add to course',
				addSelectedUser: 'Add selected user to a course',
				hello: 'Hello',
				signOut: 'Sign out',
			},
			mainSidebar: {
				overview: 'Overview',
				user: 'User',
				courses: 'Courses',
				institutions: 'Institutions',
				authoringTool: 'AutoringTool',
				settings: 'Settings',
				goToApp: 'Enter App',
			},
			orientationButton: {
				portrait: 'Portrait',
				landscape: 'Landscape mode',
			},
			footer: {
				appucationApp: 'Appucation App',
			},
		},
		tabs: {
			statisticsTab: {
				userLife: 'Nutzungsdauer',
				passRate: 'Bestehensquote',
				courseCompleted: 'Absolvierte Kurse',
				deadlineExceeded: 'Fristüberschreitung',
			},
			licenseOverviewTab: {
				new: 'New License',
				edit: 'Edit License',
			},
			assignmentOverviewTab: {
				selectAll: 'Select all',
				nameOfCourse: 'Name or course',
				start: 'start',
				end: 'end',
				startDate: 'Start date',
				changeDate: 'change date',
				endDate: 'End date',
				assignment: 'Assignment',
				addToCourse: 'Add to course',
				addSelectedUser: 'Add selected user to a course',
				save: 'Save',
				editAssignment: 'Edit selected assignment',
				deleteAssignment: 'Delete assignment',
				deleteAssignmentConfirm:
					'Do you want to delete this assignment?',
				deleteAllAssignmentsConfirm:
					'Do you want to delete all selected assignments?',
			},
			certificateTab: {
				certificateTemplate: 'Certificate Template',
				preview: 'Preview',
				certificateTitle: 'Certificate',
			},
			coursesAppucationTab: {
				name: 'Name',
				externalCouse: 'External Course',
				licence: 'Licence',
				course: 'Course',
				users: 'Users',
				created: 'Created',
				updated: 'Updated at',
			},
			coursesShopTab: {
				searchCourse: 'Search for a Course',
				course: 'Course',
				addUserToCourse: 'Add selected user to a course',
				addCourse: 'Add course',
				assignCourse: 'Assign course',
				courseOverview: 'Course overview',
			},
			coursesTab: {
				selectAll: 'Select all',
				course: 'Course',
				user: 'User',
				created: 'Created',
				updatedAt: 'updated at',
				published: 'published',
				notPublished: 'not published',
				status: 'Status',
				filter: 'Filter',
				newCourse: 'New Course',
				clearCache: 'Clear Cache',
				archiveConfirm: 'Are you sure you want to archive this course?',
				archiveCourse: 'Archive course',
				archive: 'Archive',
				restoreConfirm: 'Are you sure you want to restore this course?',
				restoreCourse: 'Restore course',
				restore: 'Restore',
				restoreCourseConfirm:
					'Do you want to restore selected courses?',
				ownCourses: 'Own courses',
			},
			editGeneralForm: {
				selectCountry: 'Select your country',
				billingAddress: 'Billing address differs',
				contactPerson: 'Contact person',
			},
			groupOverviewTabDetail: {
				courseProgress: 'Course progress',
				feedbackEmail: 'Feedback E-Mail',
				progress: 'Progress',
				email: 'Filter',
				deleteGroup: 'Delete group',
				editGroup: 'Edit group name',
				import: 'import',
				addUser: 'Add User',
				amountUsers: 'Amount of users',
				finishedCourse: 'Finished courses',
				finished: 'finished',
				assigned: 'assigned',
				delays: 'delays',
				NoUserAssigned: 'No user was assigned to this group',
				deleteGroupMessage:
					'By deleting this group. All users will loose the assignment to this group.',
				deleteGroupConfirm: 'Are you sure that the group',
				deleteGroupConfirmEnd: 'should be deleted?',
				detachUser: 'Detach user',
				detachConfirm: 'Are you sure you want to detach',
				detachConfirmEnd: 'from this group',
			},
			groupOverviewTab: {
				name: 'Name',
				members: 'Members',
				assign: 'Assign',
				course: 'Course',
				filter: 'Filter',
				groups: 'Groups',
				DeleteGroupMessage:
					'By deleting this group. All users will loose the assignment to this group.',
				deleteGroup: 'Delete group',
				deleteGroupConfirm: 'Are you sure that the group',
				deleteGroupConfirmEnd: 'should be deleted?',
				deleteSelectedGroup: 'Delete group',
				deleteSelectedGroupConfirm:
					'Are you sure that you want to delete all selected groups?',
				addCourse: 'Add course',
				addUserToCourse: 'Add selected users to a course',
				addSelectedCourse: 'Add course',
				addSelectedUserToCourse: 'Add selected user to a course',
				selectAll: 'Select All',
				addUsersMessage: 'Add Users',
			},
			invoiceTab: {
				infoiceFrom: 'Invoice from',
				years: 'years',
				invoices: 'Invoices',
				download: 'Download',
			},
			memberOverviewTab: {
				selectAll: 'Select all',
				name: 'Name',
				role: 'Role',
				deleteTeamMember: 'Delete team member',
				newMember: 'New team member',
				deleteTeamMemberConfirm:
					'Are you sure that you want to delete the team member',
				deleteTeamMemberConfirmEnd: '?',
				removeAccess: 'Only remove access to the smartometer',
				deleteUser: 'Delete user completely',
				teamMembers: 'Team members',
			},
			permissionList: {
				deleteRole: 'Delete role',
				deleteRoleConfirm:
					'Are you sure that you want to delete the role',
				deleteRoleConfirmEnd: '?',
				loading: 'Loading ...',
			},
			userOverviewTab: {
				selectAll: 'Select all',
				name: 'Name',
				group: 'Group',
				course: 'Current Courses',
				assign: 'Assign',
				assignments: 'Assignments',
				missedDeadline: 'MissedDeadline',
				groups: 'Groups',
				courses: 'Courses',
				deleteUserConfirm:
					'Are you sure that you want to delete the user',
				deleteUserConfirmEnd: '?',
				userNoAccess: 'This user has access to the smartOmeter',
				removeCourseAccess: 'Only remove course access',
				deleteUser: 'Delete user completely',
				addToGroup: 'Add to group',
				addUserToGrouo: 'Add selected users to a group',
				addCourse: 'Add course',
				addUserToCourse: 'Add selected user to a course',
				deleteModalConfirm: 'Delete user completely',
				users: 'Users',
				statistics: 'Statistics',
			},
			visualSettingsTab: {
				colorScheme: 'Color schema',
				changeColorMessage:
					'With this selection you can change the accent color of the smartOmeter user interface.',
				selectColor: 'Select color',
				save: 'Save',
				cancel: 'Cancel',
				colorCode: 'Colorcode (HEX)',
				confirm: 'confirm',
				logo: 'Logo',
				uploadFaviconMessage:
					'Here you can upload your company logo and your favicon.',
				uploadLogo: 'Upload logo',
				recomendedDimentions: 'Recommended dimensions: 250x70px',
				recomendedFileformat: 'Recommended fileformats: .png,.jpg,.ico',
				delete: 'Delete',
				uploadFavicon: 'Upload favicon',
				recomendedFavDimentions: 'Recommended dimensions: 32x32px',
				recomendedFavFileformat:
					'Recommended fileformats: .png,.jpg,.ico',
				changeSaved: 'Changes saved.',
				upload: 'Upload',
				specifyHEX: 'Specify HEX color code',
			},
		},
		shared: {
			baseModal: {
				createUserHeadline: 'Create a user',
				passwordWillBeSetByUser:
					'Password: This will be set by the user on his or her first signup',
				addAnotherUser: 'Add another user',
				createUser: 'Create user',
			},
			bulkAction: {
				action: 'Action',
			},
			confirmationModal: {
				cancel: 'Cancel',
				ok: 'OK',
			},
			dashboardFilterHasMany: {
				search: 'Search',
			},
			dashboardFilter: {
				timeframe: 'Timeframe',
				startDate: 'Startdate',
				endDate: 'Enddate',
				period: 'Period',
				last365Days: 'Last 365 days',
				last90Days: 'Last 90 days',
				last30Days: 'Last 30 days',
				last7Days: 'Last 7 days',
				last24Hours: 'Last 24 hours',
				groups: 'Groups',
				courses: 'Courses',
				filterByGroup: 'Filter by groups',
				filterByCourse: 'Filter by courses',
			},
			iconDropdown: {
				createNew: 'Create new',
				noPermissions: 'No permissions',
			},
			multiselect: {
				all: 'all',
				save: 'Save',
				search: 'Search...',
			},
			barChart: {
				readingTime: 'Reading time',
				last30Days: 'last 30 days',
			},
			columnChart: {
				exercisesAndExams: 'Exercises & Exams',
				exercise: 'Exercise',
				exam: 'Exam',
			},
			donutChart: {
				courseEnd: 'Course end',
				daysTillCourseEnd: 'Days until course end',
				days: 'Days',
				deadline: 'Deadline submission',
			},
			statisticsCourse: {
				exercise: 'Exercise',
				exercises: 'Exercises',
				exam: 'Exam',
				exams: 'Exams',
				learningProgress: 'Learning progress',
				last30Days: 'the last 30 days',
				readingTime: 'Reading time',
				exercisesAndExams: 'Exercises & Exams',
				courseEnd: 'Course end',
				readingTimeInSeconds: 'Reading time in seconds',
				readingTimeInMinutes: 'Reading time in minutes',
			},
			statistics: {
				exercise: 'Exercise',
				exercises: 'Exercises',
				exam: 'Exam',
				exams: 'Exams',
				filter: 'Filter',
				filters: 'Filter',
				learningProgress: 'Learning progress',
				last30Days: 'the last 30 days',
				readingTime: 'Reading time',
				exerciseAndExams: 'Exercises & Exams',
				days: 'Days',
				day: 'Day',
				courseEnd: 'Course end',
			},
			tableFilter: {
				filter: 'Filter',
			},
			tableSearch: {
				nameAndEmail: 'Name and email',
			},
		},
		others: {
			blocker: {
				desktopRequired: 'Desktop device required',
				mobileDevicesNotAvailable:
					'The access to the smartOmeter-administration panel is not available for mobile devices. ',
				clickButtonBelow:
					'If you would like to use the learning mode, please click on the button below',
				learningMode: 'Learning Mode',
				termsOfServices: 'Terms of Service',
				legalNotice: 'Legal Notice',
				privacyPolicy: 'Privacy Policy',
			},
		},
		helpers: {
			globalSearch: {
				dashboard: 'Dashboard',
				userOverview: 'User overview',
				groupOverview: 'Group overview',
				assignmentOverview: 'Assignment overview',
				courses: 'Courses',
				appucationCourses: 'smartOmeter courses',
				shop: 'Shop',
				createNewCourse: 'Create new course',
				general: 'General',
				cisualSettings: 'Visual settings',
				team: 'Team',
				roles: 'Roles',
				invoices: 'Invoices',
			},
			assignPairQuestions: {
				nameValidation: 'Please enter valid name',
				questionValidation: 'Please enter a question',
			},
			assignTerm: {
				nameValidation: 'Please enter valid name',
				questionValidation: 'Please enter a question',
			},
			gapFillQuestions: {
				nameValidation: 'Please enter valid name',
				textValidation: 'Please enter a text',
				gapValidation: 'Please add a gap',
			},
			multipleQuestions: {
				nameValidation: 'Please enter valid name',
				questionValidation: 'Please enter a question',
				answerValidation: 'Please enter correct answers',
			},
			orderWordsQuestions: {
				nameValidation: 'Please enter valid name',
				questionValidation: 'Please enter a question',
			},
			singleQuestions: {
				titleValidation: 'Please enter valid name',
				questionValidation: 'Please enter a question',
				answerValidation: 'Please enter correct answer',
			},
		},
	},
};
