import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from '../../../../app/axios';
import { QuestionTypes } from '../../../../enums/questions/questions-enums';
import ExerciseWizard from './ExerciseWizard';
import Results from './Results';
import Splash from './Splash';
import Summary from './Summary';

interface ExerciseContentProps {
	handleNavigate: Function;
	subarea: any;
	courseId: number;
	showNavigation: boolean;
	nextSubarea?: any;
}

export interface SettingsKeys {
	is_exam: 1 | 0;
	exam_time: number;
	exam_percentage_for_pass: number;
	exercise_percentage_for_pass: number;
}

const ExerciseContent: React.FunctionComponent<ExerciseContentProps> = ({
	handleNavigate,
	subarea,
	courseId,
	showNavigation,
	nextSubarea,
}) => {
	const [questions, setQuestions] = useState<any[]>([]);
	const [settings, setSettings] = useState<
		| {
				is_exam: 1 | 0;
				exam_time: number;
				exam_percentage_for_pass: number;
				exercise_percentage_for_pass: number;
                exam_max_questions: number;
                exercise_max_questions: number;
		  }
		| {}
	>({});

	const [isRunning, setIsRunning] = useState(false);
	const [userResults, setUserResults] = useState<any>(null);
	const [resultsSent, setResultsSent] = useState(false);
	const [showingDetails, setShowingDetails] = useState<null | number>(null);
	const location = useLocation();
	const contentParam = new URLSearchParams(location.search).get('content');
	const navigationParam = new URLSearchParams(location.search).get(
		'navigation',
	);

	useEffect(() => {
		setResultsSent(false);
	}, [contentParam, navigationParam]);

	useEffect(() => {
		if (!!userResults) setIsRunning(false);
	}, [userResults]);

	useEffect(() => {
		// TODO: FIND MORE ELEGANT SOLUTION
		if (!!subarea && !!subarea.is_exercise && !showNavigation) {
			document
				.querySelector('.app-navigation')
				?.classList.add('exercise-open');
			fetchSubareaQuestions();
		} else {
			document
				.querySelector('.app-navigation')
				?.classList.remove('exercise-open');
		}
		return () => {
			document
				.querySelector('.app-navigation')
				?.classList.remove('exercise-open');
			setUserResults(null);
			setQuestions([]);
			setIsRunning(false);
		};
	}, [subarea]);

	useEffect(() => {
		if (showNavigation) {
			document
				.querySelector('.app-navigation')
				?.classList.remove('exercise-open');
		} else {
			document
				.querySelector('.app-navigation')
				?.classList.add('exercise-open');
		}
	}, [showNavigation]);

	const switchToNextItem = () => {
		let nextParentId = nextSubarea.sub_area_id;
		if (subarea.id === nextSubarea.sub_area_id) {
			// next subarea is child of current subarea (parent id stays the same)
			nextParentId = subarea.id;
		}
		if (nextSubarea.subareas.length) {
			// next subarea has subareas on its own!
			nextParentId = nextSubarea.id;
		} else if (nextSubarea.sub_area_id === subarea.sub_area_id) {
			// next item is regular sibling
			nextParentId = nextSubarea.sub_area_id;
		}
		handleNavigate(`/app/learn/${nextParentId || ''}`, {
			navigation: '0',
			content: nextSubarea.id.toString(),
		});
	};

	const shuffleArray = (array: any[]) => {
		var currentIndex = array.length,
			randomIndex;
		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex],
				array[currentIndex],
			];
		}
		return array;
	};

	const fetchSubareaQuestions = async () => {
		try {
			const res: any = await axios.get(
				`preview/course/${courseId}/subarea/${subarea.id}`,
			);
			if (res.data.success) {
				const maxQuestions = subarea.is_exam ? res.data.success.settings.exam_max_questions :
					res.data.success.settings.exercise_max_questions ||
					res.data.success.subarea.questions.length;
				const questions = shuffleArray(
					res.data.success.subarea.questions,
				)!.slice(0, maxQuestions || res.data.success.subarea.questions.length);
				const preparedQuestions = questions.map((question: any) => {
					// pre-shuffle order for certain types of questions
					const item = { ...question };
					switch (question.type) {
						case QuestionTypes.WORD_COMBINATION: {
							item.options = shuffleArray(
								question.word_combinations.map(
									(wordCombination: any) =>
										wordCombination.secondWord,
								),
							);
							break;
						}
						case QuestionTypes.GAP_FILLS: {
							let fakeEl = document.createElement('div');
							fakeEl.innerHTML = question.gap_fills[0].text;
							const words = fakeEl.querySelectorAll('word');
							const correctWordOrder = Array.from(words).map(
								(item: any) => item.innerHTML,
							);
							item.correctOrder = [...correctWordOrder];
							item.options = shuffleArray(correctWordOrder);
							break;
						}
						case QuestionTypes.ASSIGN_TERM: {
							item.predefinedOptions = shuffleArray(
								question.terms
									.map((term: any) =>
										term.words.map(
											(word: any) => word.term,
										),
									)
									.flat(),
							);
							break;
						}
					}
					return item;
				});
				setQuestions(preparedQuestions);
				setSettings(res.data.success.settings);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleClose = () => {
		handleNavigate(null, { navigation: '1', content: '-1' });
	};

	const handleStartExercise = () => {
		setIsRunning(true);
	};

	const handleTimeOver = (results: any) => {
		setUserResults(results);
	};

	if (isRunning || (showingDetails !== null && showingDetails > -1)) {
		return (
			<ExerciseWizard
				questions={questions}
				subarea={subarea}
				setResults={setUserResults}
				previewedQuestion={showingDetails}
				defaultAnswers={userResults}
				backToSummary={() => setShowingDetails(-1)}
				settings={settings}
				handleTimeOver={handleTimeOver}
			/>
		);
	}
	if (!isRunning && !userResults) {
		return (
			<Splash
				subarea={subarea}
				handleNavigate={handleNavigate}
				questions={questions.length}
				handleStart={handleStartExercise}
			/>
		);
	}
	if (!isRunning && !!userResults && showingDetails === null) {
		return (
			<Results
				answers={userResults}
				subarea={subarea}
				settings={settings}
				handleNavigate={handleNavigate}
				questions={questions.length}
				handleShowDetails={() => setShowingDetails(-1)}
				handleClose={handleClose}
				handleGoToNext={switchToNextItem}
				nextSubarea={nextSubarea}
				resultsSent={resultsSent}
				setResultsSent={setResultsSent}
			/>
		);
	}
	if (!isRunning && !!userResults && showingDetails === -1) {
		return (
			<Summary
				answers={userResults}
				questions={questions}
				handleViewDetail={(index: number | null) => {
					setShowingDetails(index);
				}}
			/>
		);
	}
	return null;
};

export default React.memo(
	ExerciseContent,
	(
		prevProps: { subarea: { id: number } },
		nextProps: { subarea: { id: number } },
	) => {
		if (prevProps.subarea.id !== nextProps.subarea.id) return false;
		return true;
	},
);
