import React from 'react';
import {translate} from '../../../../../i18n/i18n';
import {translateWithVariable} from '../../../../../i18n/i18n';

const QuitExerciseModal: React.FunctionComponent<{
	finish?: boolean;
	timeout?: boolean;
	type: string;
	handleAbort: Function;
	handleConfirm: Function;
}> = ({ type, handleAbort, handleConfirm, finish, timeout }) => {
	return (
		<div
			className="app-exercise-modal-wrapper"
			onClick={() => handleAbort()}
		>
			<div className="app-exercise-modal-container">
				<h3>
					{finish
						? translateWithVariable('app.quitExerciseModal.finish', {type: type})
						: timeout
						? translate('app.quitExerciseModal.timeExpired')
						: translateWithVariable('app.quitExerciseModal.finishVerify', {type: type})}
				</h3>
				<p>
					{finish || timeout
						? translate('app.quitExerciseModal.resultWillBeSaved')
						: translate('app.quitExerciseModal.resultWillNotBeSaved')}
				</p>
				<button
					className={`app-exercise-modal-button ${
						finish || timeout ? 'positive' : ''
					} `}
					onClick={() => handleConfirm()}
				>
					{timeout
						? translate('app.quitExerciseModal.yourResult')
						: translateWithVariable('app.quitExerciseModal.close', {type: type, value: finish ? translate('app.quitExerciseModal.finishButton') : translate('app.quitExerciseModal.closeButton')})}
				</button>
				{!timeout && (
					<button
						className="app-exercise-modal-button secondary"
						onClick={() => handleAbort()}
					>
						{translate('app.quitExerciseModal.cancel')}
					</button>
				)}
			</div>
		</div>
	);
};

export default QuitExerciseModal;
