import React from "react";
import { useRouteMatch, useLocation, Link, Route, Switch, NavLink } from "react-router-dom";
import { useStyles } from "./styles";

interface Tab{
    [key: string]: {
        title: string,
        component: JSX.Element,
        detailPage?: boolean,
        detailComponent?: JSX.Element,
        hasSubmenu?: boolean,
        hidden?: boolean
     }
}

interface TabNavigatorProps{
    tabs: Tab,
    defaultActive: string
}

const TabNavigator: React.FunctionComponent<TabNavigatorProps> = ({ tabs, defaultActive }) => {
    const classes = useStyles();
    const { url, path } = useRouteMatch();

    const renderTabs = (): Array<JSX.Element> => {
        const els: Array<JSX.Element> = [];
        Object.keys(tabs).map((key: any, index:number) => {
            if(!tabs[key].hidden) {
                els.push(<div key={index} className={classes.Tab} ><NavLink exact={!tabs[key].hasSubmenu} to={key === defaultActive ? url : `${url}/${key}`} activeClassName={classes.TabActive}>{tabs[key].title}</NavLink></div>)
            };
        })
        return els;
    }
    const renderTabContent = (): Array<JSX.Element> => {
        const els: Array<JSX.Element> = [];
        Object.keys(tabs).filter( key => {
            const currentTab = tabs[key];
            return !currentTab.hidden;
        } ).map(key => {
            const targetPath = key === defaultActive ? path : `${path}/${key}`;
            const paths = [targetPath];
            if (tabs[key].hasSubmenu) {
                paths.push(`${targetPath}/:subpage`)
            }
            els.push(
                <Route key={key} path={[...paths]} exact={!tabs[key].hasSubmenu}>{tabs[key].component}</Route>
            )
            if (tabs[key].detailPage) {
                els.push(
                    <Route key={key} path={`${targetPath}/detail/:id`}>{tabs[key].detailComponent}</Route>
                )
            }
        })
        return els;
    }
    return (
        <div className={classes.TabWrapper}>
            <div className={classes.TabHead}>
                {renderTabs()}
            </div>
            <Switch>
                {renderTabContent()}
            </Switch>
        </div>
    )
}

export default TabNavigator
