import React, {ChangeEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "semantic-ui-react";
import {translate} from "../../../../i18n/i18n";
import {fetchMembers} from "../../../../store/members/memberSlice";
import {clearUser, deleteUser, userSelector} from "../../../../store/users/userSlice";
import {color} from "../../../../utils/styles";
import AddMemberModal from "../../../modals/AddMemberModal";
import DeleteModal from "../../../modals/DeleteModal";
import EditMemberModal from "../../../modals/EditMemberModal";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import ContentCard from "../../../shared/ContentCard";
import Table from '../../../shared/Table';
import TableAction from "../../../shared/TableAction";
import {Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {deleteTypes, userBulkFunctions} from "../../../../enums/users/users-enums";
import {useStyles} from "./styles";
import {usePagination} from "../../../../helpers/usePagination";
import {useSorting} from "../../../../helpers/useSorting";
import {useBulkFunction} from "../../../../helpers/useBulkFunction";
import {BulkAction} from "../../../shared/BulkAction";
import {memberBulkFunctions} from "../../../../enums/members/members-enums";

interface TeamOverviewTabProps {
    userData: any, // fix when time
    isMemberLoading: boolean;
}


const MemberOverviewTab: React.FunctionComponent<TeamOverviewTabProps> = ({ userData, isMemberLoading }) => {

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [openEditUser, setOpenEditUser] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedUser, setSelectedUser] = useState<{ id: number, name: string } | null>(null);
    const [shownModal, setShownModal] = useState<string | null>(null);
    const {isLoading, trigger} = useSelector(userSelector);
    const {handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, handleResetPage} = usePagination();
    const {sorting, handleChangeSorting} = useSorting();
    const [deleteType, setDeleteType] = useState<deleteTypes>(deleteTypes.DELETE);
    const {handleSelectAll, handleSelect, isSelected, selected, selectedAll, setSelected} = useBulkFunction(userData);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleEditUser = (id: number) => {
        dispatch(clearUser());
        setSelectedUserId(id)
        setOpenEditUser(true)
    }

    useEffect(() => {
		handleResetPage()
	}, [rowsPerPage, sorting]);

    useEffect( ()=> {
            dispatch({
                ...fetchMembers({
                    requestType: "GET",
                    request: `smartometer/members?page=${page}&limit=${rowsPerPage}&sort=${sorting.column}&type=${sorting.desc ? 'desc' : 'asc'}`,
                }),
            });
            setShowAddUserModal(false)
    }, [page, rowsPerPage, sorting, trigger])

    const resetModals = () => {
        setShownModal(null);
        setSelectedUser(null);
    }

    const setUserToDelete = (id: number, name: string) => {
        setShownModal("delete");
        setSelectedUser({id, name});
    }

    const handleDeleteUser = () => {
        setIsDeleting(true);
        dispatch({
            ...deleteUser({
                requestType: "DELETE",
                request: `smartometer/members/${selectedUser!.id}${deleteType ? `?deleteType=${deleteType}` : ''}`,
            }),
        });
        setIsDeleting(false);
        resetModals();
    }
    const userRowData = {
        select: {
            renderTitle: (): JSX.Element => <label className={classes.label}><Checkbox
                checked={selectedAll}
                color="primary"
                onChange={handleSelectAll}
            /><span className={classes.selectAll}>{translate('tabs.memberOverviewTab.selectAll')}</span></label>,
            align: "left",
            renderType: (item: any): JSX.Element => <Checkbox
                checked={isSelected(item.id)}
                color="primary"
                onClick={(event) => handleSelect(event, item.id)}
            />,
        },
        avatar: {
            title: "",
            align: "left",
            renderType: (item: any): JSX.Element => <Avatar src={item.avatar || '/defaultUser.png'} />,
        },
        name: {
            title: translate('tabs.memberOverviewTab.name'),
            sorting: "first_name",
            align: "left",
            renderType: (item: any): JSX.Element => <p>{item.first_name} {item.last_name}</p>
        },
        role: {
            title: translate('tabs.memberOverviewTab.role'),
            align: "left",
            sorting: "role",
            renderType: (item: any): JSX.Element => <p>{!!item.name ? item.name : "-"}</p>
        },
        edit: {
            title: "",
            align: "right",
            renderType: (item: any): JSX.Element => <TableAction handleClick={() => handleEditUser(item.id)} tooltip={translate('tooltip.edit')} type="edit" />
        },
        delete: {
            title: "",
            align: "right",
            renderType: (item: any): JSX.Element => <TableAction handleClick={() => setUserToDelete(item.id, item.first_name) } tooltip={translate('tooltip.delete')} type="delete" />
        },
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteType((event.target as HTMLInputElement).value as deleteTypes);
    };

    const handleBulkEdit = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        switch (event.target.value) {
            case userBulkFunctions.DELETE:
                setShownModal(userBulkFunctions.DELETE);
                break;
            default:
                break;
        }
    }

    const headerComponent = <>
        {!!selected.length && <BulkAction handleBulkEdit={handleBulkEdit} bulkFunctions={memberBulkFunctions} />}
    </>;

    const handleBulkDeleteMember = () => {
        setIsDeleting(true);
        if(selected.length) {
            dispatch({
                ...deleteUser({
                    requestType: "DELETE",
                    body: {
                        users: selected
                    },
                    request: `smartometer/members`,
                }),
            });
            setIsDeleting(false);
            setSelected([])
            resetModals();
        }
    }

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button type="solid" styles={{ float: "right"}} onClick={() => setShowAddUserModal(true) }  color={color.link}  title={translate('tabs.memberOverviewTab.newMember')}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard title={`${userData.total} ${translate('tabs.memberOverviewTab.teamMembers')}`} headerComponent={headerComponent}>
                            <Table showHeader loading={isMemberLoading} rows={userRowData} data={userData.data} total={userData.total} handleChangePage={handleChangePage} page={page} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangeSorting={handleChangeSorting} sorting={sorting} /></ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <EditMemberModal showModal={openEditUser} closeModal={() => setOpenEditUser(false)} userId={selectedUserId} />
            <AddMemberModal showModal={showAddUserModal} closeModal={() => setShowAddUserModal(false) }/>
            <DeleteModal isLoading={isLoading} showModal={!!selectedUser && shownModal === "delete"} closeModal={resetModals} onDelete={handleDeleteUser} confirmText={translate('tabs.memberOverviewTab.deleteTeamMember')} message={`${translate('tabs.memberOverviewTab.deleteTeamMemberConfirm')} <b>'${selectedUser?.name}'</b> ${translate('tabs.memberOverviewTab.deleteTeamMemberConfirmEnd')}`}>
                {selectedUser && selectedUser.id && userData?.data.find( (user: any) => user.id === selectedUser.id)?.isEndUser ? <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Dieser Nutzer hat auch Zugriff auf Lerninhalte</FormLabel>
                    <RadioGroup aria-label="quiz" name="quiz" value={deleteType} onChange={handleRadioChange}>
                        <FormControlLabel value={deleteTypes.REMOVE} control={<Radio />} label={translate('tabs.memberOverviewTab.removeAccess')}/>
                        <FormControlLabel value={deleteTypes.DELETE} control={<Radio />} label={translate('tabs.memberOverviewTab.deleteUser')} />
                    </RadioGroup>
                </FormControl> : <></>}
            </DeleteModal>
            <DeleteModal isLoading={isLoading} showModal={!!selected.length && shownModal === memberBulkFunctions.DELETE} closeModal={resetModals} onDelete={handleBulkDeleteMember} confirmText={translate('tabs.memberOverviewTab.deleteTeamMember')} message={translate('tabs.memberOverviewTab.deleteTeamMemberConfirm')} />
        </>
    )
};

export default MemberOverviewTab;
