import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface initialStateInterface {
    data: any;
    isLoading: boolean;
    error: string;
}

const initialState: initialStateInterface = {
    data: null,
    isLoading: false,
    error: '',
};

export const trackingSlice = createSlice({
    name: 'tracking',
    initialState,
    reducers: {
        add: (state, action: any) => {
            if (action.state === 'start') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response) {
                state.data = action.response;
                state.isLoading = false;
            }
        },
        get: (state, action: any) => {
            if (action.state === 'start') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response) {
                state.data = action.response;
                state.isLoading = false;
            }
        }
    },
});

export const { add, get } = trackingSlice.actions;
export const resultSelector = (state: RootState) => state.tracking;
export default trackingSlice.reducer;
