import React from "react";
import {Switch, SwitchProps} from "@material-ui/core";
import { useStyles } from "./styles";


export const Toggle = (({ ...props }: SwitchProps) => {
    const classes = useStyles();
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled
            }}
            {...props}
        />
    );
});
