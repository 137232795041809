import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { boolean } from 'yup/lib/locale';
import { color } from '../../../utils/styles';
import { useStyles } from './styles';

interface LoadingOverlayProps {
	isFetching: boolean;
	opaque?: boolean;
}
const LoadingOverlay: React.FunctionComponent<LoadingOverlayProps> = ({
	isFetching,
	opaque,
}) => {
	const [isHidden, setIsHidden] = useState(false);
	const [toggling, setToggling] = useState(false);

	let hidingTimeout: NodeJS.Timeout;

	useEffect(() => {
		if (!isFetching) {
			setToggling(true);
			hidingTimeout = setTimeout(() => setIsHidden(true), 500);
		}
		return () => {
			clearTimeout(hidingTimeout);
		};
	}, [isFetching]);

	const classes = useStyles();
	return (
		<div
			className={`${classes.Overlay} ${isHidden ? classes.Hidden : ''} ${
				toggling ? classes.Hiding : ''
			} ${opaque ? 'opq' : ''} loading-overlay`}
			style={{
				backgroundColor:
					window.localStorage.getItem('institutionColor') ||
					color.link,
			}}
		>
			<CircularProgress />
		</div>
	);
};

export default LoadingOverlay;
