
export interface SortingInterface {
    column: string,
    desc: boolean
}

export const initialSorting: SortingInterface = {
    column: "id",
    desc: false
}
