import React from 'react';
import {Grid, LinearProgress} from '@material-ui/core';
import {useStyles} from './styles';
import {ContentTypes} from '../../../../enums/courses/courses-enums';
import {GraphHeader} from '../GraphHeader';
import {translate} from "../../../../i18n/i18n";

interface ColumnChartProps {
    values: any[];
    header?: boolean;
}

export const ColumnChart: React.FunctionComponent<ColumnChartProps> = ({
                                                                           values,
                                                                           header,
                                                                       }) => {
    const classes = useStyles();

    const exercises = values.filter(
        (current: any) => current.type === ContentTypes.EXERCISE,
    );
    const exams = values.filter(
        (current: any) => current.type === ContentTypes.EXAM,
    );
    const subtitle = `${
        !!exercises.length &&
        `${exercises.length} ${translate('shared.columnChart.exercise')}${exercises.length > 1 ? 'en' : ''},`
    } ${
        !!exams.length &&
        `${exams.length} ${translate('shared.columnChart.exam')}${exams.length > 1 ? 'en' : ''}`
    }`;

    return (
        <div className={classes.wrapper}>
            {header && (
                <GraphHeader
                    headline={translate('shared.columnChart.exercisesAndExams')}
                    subtitle={subtitle}
                />
            )}
            <div>
                <Grid container spacing={2}>
                    {values.map((value: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            <Grid
                                container
                                className={classes.row}
                                justify={'space-between'}
                            >
                                <Grid item>
									<span className={classes.value}>
										{value.value}%
									</span>
                                </Grid>
                                <Grid item>
									<span className={classes.type}>
										{value.name}
									</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.root}>
                                    <LinearProgress
                                        variant="determinate"
                                        classes={
                                            value.type === ContentTypes.EXERCISE
                                                ? {
                                                    root: classes.root,
                                                }
                                                : {
                                                    root: classes.examRoot,
                                                    bar: classes.examColor,
                                                }
                                        }
                                        value={+value.value}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};
