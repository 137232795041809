import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
	Container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	ImageContainer: {
		height: 90,
		width: 90,
		marginBottom: 20,
	},
	Image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: '100px',
		cursor: 'pointer',
	},
}));
