import React from "react";
import { useStyles } from "./style"

interface ContentCardNumberProps{
    value: string | number;
    handleActionClick?: React.MouseEventHandler;
    actionTitle?: string;
}

const ContentCardNumber: React.FunctionComponent<ContentCardNumberProps> = ({ value, handleActionClick, actionTitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.Wrapper}>
            <p className={classes.Number}>{value}</p>
            {!!actionTitle && <div className={classes.Action}><span onClick={handleActionClick}>{actionTitle}</span></div>}
        </div>
    )
}

export default ContentCardNumber;