import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../app/axios';
import UpperTabNavigation from '../../../components/app/UpperTabNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setAllCourses } from '../../../store/app/appSlice';
import OverviewTab from './OverviewTab';
import StatisticsTab from './StatisticsTab';
import { translate } from '../../../i18n/i18n';

interface AppCourseProps {
	handleNavigate: Function;
}

const AppCourse: React.FunctionComponent<AppCourseProps> = ({
	handleNavigate,
}) => {
	const { subpage } = useParams<{ subpage?: string }>();
	const { allCourses } = useSelector(appSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchCourses();
	}, []);

	const fetchCourses = async () => {
		await axios
			.get(`/app/courses`)
			.then(function (response) {
				dispatch(setAllCourses(response.data.success));
			})
			.catch(function (error) {
				if (!error.response) {
					const offlineCourseData =
						window.localStorage.getItem('all_courses');
					if (offlineCourseData) {
						dispatch(setAllCourses(JSON.parse(offlineCourseData)));
					}
				}
			});
	};

	const courseTabs = [
		{ value: '', title: translate('app.appCourse.courses') },
		{ value: 'statistics', title: translate('app.appCourse.statistics') },
	];

	return (
		<div className="preview-window-navigation-content app-navigation-content">
			<UpperTabNavigation
				tabs={courseTabs}
				onNavigate={(val: string) =>
					handleNavigate(`/app/course/${val}`)
				}
				activeTab={subpage || ''}
			/>
			{subpage === 'statistics' ? (
				<StatisticsTab />
			) : (
				<OverviewTab handleNavigate={handleNavigate} />
			)}
		</div>
	);
};

export default AppCourse;
