import React from 'react';

interface CourseKPIItemProps {
	value: string;
	title: string;
}

const CourseKPIItem: React.FunctionComponent<CourseKPIItemProps> = ({
	title,
	value,
}) => {
	return (
		<div className="app-course-kpi-item">
			<p className="app-course-kpi-item-title">{title}</p>
			<p className="app-course-kpi-item-value">{value}</p>
		</div>
	);
};

export default CourseKPIItem;
