import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface inititalStateInterface {
    data: any,
    file: any,
    isLoading: boolean,
    years: any,
    error: string | null
}
const initialState: inititalStateInterface = {
    data: null,
    file: null,
    isLoading: false,
    years: null,
    error: ""
};

export const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        downloadInvoiceAsPdf: (state, action: any) => {
            if (action.state === 'start') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response) {
                state.file = action.response;
                state.isLoading = false;
                state.error = "";
            }
        },
        clearDownload: (state, action: any) => {
            state.file = null;
            state.isLoading = false;
            state.error = "";
        },
        fetchYears: (state, action: any) => {
            if (action.state === 'start') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.years = action.response.success;
                state.isLoading = false;
                state.error = "";
            }
        },
        fetchInvoices: (state, action: any) => {
            state.file = null;
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'start') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.isLoading = false;
            }

            if (action.state === 'finished' && !!action.response.success) {
                state.data = action.response.success;
                state.isLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
    }
})

export const { fetchInvoices, downloadInvoiceAsPdf, clearDownload, fetchYears } = invoiceSlice.actions;
export const invoiceSelector = (state: RootState) => state.invoice;
export default invoiceSlice.reducer
