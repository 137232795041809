import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import {format, parse, parseJSON} from 'date-fns';

import ContentCard from '../../shared/ContentCard';
import ContentCardNumber from '../../shared/ContentCardNumber';
import Table from '../../shared/Table';
import { fetchAppucationCourses } from '../../../store/courses/courseSlice';
import { useTableSearch } from '../../../helpers/useTableSearch/useTableSearch';
import {
	initialSorting,
	SortingInterface,
} from '../../../interfaces/sorting-interface';
import { usePagination } from '../../../helpers/usePagination';
import { translate } from '../../../i18n/i18n';
import TableSearch from '../../shared/TableSearch';

interface CoursesAppucationTabProps {
	courseData: any; // fix when time
	isLoading: boolean;
}

const CoursesAppucationTab: React.FunctionComponent<CoursesAppucationTabProps> =
	({ courseData, isLoading }) => {
		const searchProps = useTableSearch();
		const { search } = searchProps;

		const [sorting, setSorting] =
			useState<SortingInterface>(initialSorting);

		const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } =
			usePagination();
		const dispatch = useDispatch();
		const history = useHistory();
		const { pathname } = useLocation();

		useEffect(() => {
			dispatch({
				...fetchAppucationCourses({
					requestType: 'GET',
					request: `smartometer/get-courses-appucation?page=${page}&published=1&limit=${rowsPerPage}&sort=${
						sorting.column
					}&type=${sorting.desc ? `desc` : `asc`}${
						search !== '' ? `&search=${search}` : ''
					}`,
				}),
			});
		}, [sorting, search, page, rowsPerPage]);

		const handleChangeSorting = (column: string) => {
			if (column === sorting.column && sorting.desc)
				setSorting(initialSorting);
			else if (column === sorting.column && !sorting.desc)
				setSorting({ ...sorting, desc: true });
			else if (column !== sorting.column)
				setSorting({ ...sorting, column: column });
		};

		const courseRowData = {
			name: {
				title: translate('tabs.coursesAppucationTab.course'),
				sorting: 'name',
				align: 'left',
				renderType: (item: any): JSX.Element => (
					<p
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push(`${pathname}/detail/${item.id}`)
						}
					>
						{item.name}
					</p>
				),
			},
			count: {
				title: translate('tabs.coursesAppucationTab.users'),
				sorting: 'users_count',
				align: 'left',
				renderType: (item: any): JSX.Element => (
					<p>{item.users_count}</p>
				),
			},
			created_at: {
				title: translate('tabs.coursesAppucationTab.created'),
				sorting: 'created_at',
				align: 'left',
				renderType: (item: any): JSX.Element => (
					<p>
						{item.created_at &&
							format(
								parseJSON(
									item.created_at
								),
								'dd.MM.yyyy',
							)}
					</p>
				),
			},
			updated_at: {
				title: translate('tabs.coursesAppucationTab.updated'),
				sorting: 'updated_at',
				align: 'left',
				renderType: (item: any): JSX.Element => (
					<p>
						{item.updated_at &&
							format(
                                parseJSON(
									item.updated_at
								),
								'dd.MM.yyyy',
							)}
					</p>
				),
			},
		};

		return (
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(
								'tabs.coursesAppucationTab.externalCouse',
							)}
							small
							helperText={translate(`kpis.courses.bookedCourses`)}
						>
							{!!courseData && (
								<ContentCardNumber
									value={courseData.total || 0}
								/>
							)}
						</ContentCard>
					</Grid.Column>
					<Grid.Column width={4}>
						<ContentCard
							title={translate(
								'tabs.coursesAppucationTab.licence',
							)}
							small
							helperText={translate(`kpis.courses.licenses`)}
						>
							<ContentCardNumber
								value={courseData?.assignments?.length || 0}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<ContentCard
							title={translate(
								'tabs.coursesAppucationTab.course',
							)}
							headerComponent={
								<TableSearch searchProps={searchProps} />
							}
						>
							<Table
								showHeader
								rows={courseRowData}
								data={courseData.data}
								handleChangeSorting={handleChangeSorting}
								loading={isLoading}
								sorting={sorting}
								total={courseData.total}
								handleChangePage={handleChangePage}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangeRowsPerPage={
									handleChangeRowsPerPage
								}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

export default CoursesAppucationTab;
