import * as yup from 'yup';
import YupPassword from 'yup-password';
import { translate } from '../../../i18n/i18n';

YupPassword(yup);
export const validationSchema = yup.object().shape({
	password: yup
		.string()
		.password()
		.minLowercase(
			1,
			translate('authentication.register.errors.passwordRules'),
		)
		.minUppercase(
			1,
			translate('authentication.register.errors.passwordRules'),
		)
		.minSymbols(
			0,
			translate('authentication.register.errors.passwordRules'),
		)
		.minNumbers(
			1,
			translate('authentication.register.errors.passwordRules'),
		) // raise the lowercase requirement to 8
		.min(8, translate('authentication.register.errors.passwordRules')),
	passwordConfirm: yup
		.string()
		.label('Password Confirm')
		.required()
		.oneOf(
			[yup.ref('password')],
			translate('authentication.register.errors.passwordCompareNoMatch'),
		),
});
