import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Label: {
		textAlign: 'left',
		color: '#c7c7c7',
		fontSize: 12,
		textTransform: 'uppercase',
		marginBottom: 6,
	},
	Container: {
		marginBottom: 23,
		paddingBottom: 0,
	},
	TextArea: {
		resize: 'none',
		width: '100%',
		background: '#F6F7F9',
		border: '2px solid transparent',
		marginBottom: 10,
		padding: 21,
		height: 230,
		borderRadius: 8,

		'&:focus-visible': {
			outline: 'none',
			border: '2px solid',
			borderColor: color.link,
		},
	},
	Submit: {
		width: '100%',
		margin: '0 auto',
		marginBottom: 23,
	},
	LogoutLink: {
		color: color.error,
		cursor: 'pointer',

		'&:hover': {
			color: color.red,
			textDecoration: 'underline',
		},
	},
	LogoutWrapper: {
		textAlign: 'left',
	},
}));
