import {QuestionTypes} from "../../../enums/questions/questions-enums";
import {translate} from '../../../i18n/i18n';

export const formatAssignPairsPopulateData = (wordCombinations: any[]) => {
    return wordCombinations.map((wordCombination: any) => {
        return wordCombination
    })
}


export const formatAssignPairUpdateData = (options:any, name: string) => {
    return  {
        question: name,
        type: QuestionTypes.WORD_COMBINATION,
        wordCombinations: options
    }
}


export const validateAssignPairUpdateData = (name:string, wordCombinations:any[]) => {
    let errors = []
    if (!name) {
        errors.push(translate('helpers.assignPairQuestions.nameValidation'))
    }
    if (!wordCombinations.length) {
        errors.push(translate('helpers.assignPairQuestions.questionValidation'))
    }

    return errors
}
