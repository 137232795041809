import { makeStyles } from '@material-ui/core/styles'
import { color } from '../../../utils/styles.js'

export const useStyles = makeStyles(theme => ({
    Avatar: {
        width: "44px",
        height: "44px",
        borderRadius: "22px",
        backgroundColor: color.background,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& svg": {
            opacity: ".2"
        }
    }
}))