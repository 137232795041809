import React, { useEffect, useState } from 'react';
import useUserContext from '../../../contexts/UserContext';
import PreviewNavigation from '../../../components/authoring/Preview/PreviewNavigation/index';
import PreviewListItem from '../../../components/authoring/Preview/PreviewListItem';
import axios from '../../../app/axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { translate } from '../../../i18n/i18n';

interface AppExerciseProps {
	subareas: Array<Subarea>;
	handleNavigate: Function;
	contentId: number;
}

interface Subarea {
	id: number;
	subareas: Array<Subarea>;
	headline: string;
	is_exam: boolean;
	is_exercise: boolean;
	sub_area_id: number;
	wysiwyg: string;
	time_spent?: number;
}

const AppExercise: React.FunctionComponent<AppExerciseProps> = ({
	subareas,
	handleNavigate,
	contentId,
}) => {
	const { subpage } = useParams<{ page: string; subpage?: string }>();

	const parentId = subpage ? parseInt(subpage) : null;

	const exerciseAreas = subareas
		? subareas.filter((area: Subarea) => area.is_exercise && !area.is_exam)
		: [];
	const examAreas = subareas
		? subareas.filter((area: Subarea) => area.is_exercise && area.is_exam)
		: [];

	const currentSubareaItem = () => {
		return (
			subareas.find((subarea: any) => subarea.id === parentId) ||
			([] as any)
		);
	};

	const handleNavigateDown = (item: any) => {
		if (item && item.subareas && item.subareas.length === 0) {
			const redirect = parentId
				? `/app/exercise/${parentId}`
				: '/app/exercise';
			handleNavigate(redirect, {
				content: item.id.toString(),
				navigation: '0',
			});
		} else {
			handleNavigate(`/app/exercise/${item.id.toString()}`, {
				content: contentId,
				navigation: '1',
			});
		}
	};

	const handleActiveNavigateDown = (item: any) => {
		handleNavigate(`/app/exercise/${item.id.toString()}`, {
			content: item.id.toString(),
			navigation: '0',
		});
	};

	return (
		<>
			<div className="preview-window-navigation-content app-navigation-content">
				{!exerciseAreas.length && !examAreas.length && (
					<p className="preview-window-navigation-separator">
						{translate('app.appExercise.noContent')}
					</p>
				)}
				{parentId && (
					<PreviewListItem
						active={parentId === contentId}
						key={currentSubareaItem().id}
						item={currentSubareaItem()}
						parentId={parentId}
						handleClick={() => {
							handleActiveNavigateDown(currentSubareaItem());
						}}
					/>
				)}

				{!!exerciseAreas.length && (
					<p className="preview-window-navigation-separator">
						{translate('app.appExercise.exercises')}
					</p>
				)}

				{!!exerciseAreas.length && (
					<>
						{exerciseAreas.map((exercise) => (
							<PreviewListItem
								active={exercise.id === contentId}
								key={exercise.id}
								item={exercise}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(exercise);
								}}
								showContentProgress={true}
							/>
						))}
					</>
				)}

				{!!examAreas.length && (
					<p className="preview-window-navigation-separator">
						{translate('app.appExercise.exams')}
					</p>
				)}

				{!!examAreas.length && (
					<>
						{examAreas.map((exam) => (
							<PreviewListItem
								active={exam.id === contentId}
								key={exam.id}
								item={exam}
								parentId={parentId}
								handleClick={() => {
									handleNavigateDown(exam);
								}}
								showContentProgress={true}
							/>
						))}
					</>
				)}
			</div>
		</>
	);
};

export default AppExercise;
