import React, {useState} from "react";
import DeleteModal from "../../../../modals/DeleteModal";

import { useStyles } from "./styles";
import {FormControlLabel, FormGroup} from "@material-ui/core";
import {translate} from "../../../../../i18n/i18n";
import TableAction from "../../../../shared/TableAction";
import { useDispatch, useSelector } from "react-redux";
import {Toggle} from "../../../../shared/Toggle";
import {clearRole, deleteRole, roleSelector} from "../../../../../store/roles/roleSlice";
import useUserContext from "../../../../../contexts/UserContext";

interface ToggleSetProps {
    showHeader?: boolean,
    data: Array<Object>,
    onEditRole:  (id:number) => void,
};

enum MODAL_TYPE {
    NONE = "NONE",
    CONFIRM = "CONFIRM",
    EDIT = "EDIT",
}

const PERMISSIONS = [
    "FE_SOM_DASHBOARD",
    "FE_SOM_USERS",
    "FE_SOM_COURSES",
    "FE_SOM_SETTINGS"
];

interface IPermissionListState {
    open: boolean,
    type: MODAL_TYPE,
    payload?: any
}

export const PermissionList: React.FunctionComponent<ToggleSetProps> = ({ showHeader, data, onEditRole }) => {
    const dispatch = useDispatch();
    const { role, rolesIsLoading } = useSelector(roleSelector);
    const {user} = useUserContext();

    const INITIAL_STATE: IPermissionListState = {
        open: false,
        type: MODAL_TYPE.NONE,
        payload: {
            id: 0
        }
    }

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(INITIAL_STATE);

    const handleModal = (payload: object, type: MODAL_TYPE) => {
        setOpenModal({
            ...openModal,
            type: type,
            open: true,
            payload
        });
    }

    const closeModal = () => {
        setOpenModal(INITIAL_STATE);
        dispatch({
            ...clearRole(),
        });
    }

    const handleDeleteRole = () => {
        closeModal();
        dispatch({
            ...deleteRole({
                requestType: "DELETE",
                request: `smartometer/roles/${openModal.payload.id}`,
            }),
        });
    }

    const renderToggles = (toggle:any) => {
        const toggleArray: Array<JSX.Element> = [];
        PERMISSIONS.map( PERMISSION => {
            const hasPermission = !!toggle.permissions.find( (permission:any) => permission.name === PERMISSION);
            const editable = !!toggle.institution_id;
            toggleArray.push( <FormControlLabel
                className={classes.Label}
                control={<Toggle checked={hasPermission} disabled={!editable} name={PERMISSION} />}
                label={translate(`settings.team.roles.PERMISSION.${PERMISSION}`)}
            />);
        })
        return toggleArray;
    }

    const renderRows = () => {
        const rowsArray: Array<JSX.Element> = [];
        data.map((item: any) => {
            const toggle = renderToggles(item);
            const isEditable = item.institution_id !== 1 || user?.institution?.id === 1;
            const row = <div className={classes.Row}>
                <h3 className={classes.Legend}>{item.name} {!isEditable && translate(`settings.team.roles.PERMISSION.NOT_EDITABLE`)}</h3>
                <FormGroup className={classes.PermissionGroup} key={item.id}>{toggle}</FormGroup>
                {isEditable && <div className={classes.Actions}>
                    <TableAction handleClick={() => onEditRole(item.id)} tooltip={translate('tooltip.edit')} type="edit" />
                    <TableAction handleClick={() => handleModal(item, MODAL_TYPE.CONFIRM)} tooltip={translate('tooltip.delete')} type="delete" /></div>}
            </div>;
            rowsArray.push(row)
        })
        return rowsArray;
    }

    const DeleteRole = openModal.open && openModal.type === MODAL_TYPE.CONFIRM  &&  <DeleteModal isLoading={rolesIsLoading} showModal={openModal.open && openModal.type === MODAL_TYPE.CONFIRM} closeModal={closeModal} onDelete={handleDeleteRole} confirmText={translate('tabs.permissionList.deleteRole')} message={`${translate('tabs.permissionList.deleteRoleConfirm')} <b>'${openModal.payload?.name}'</b> ${translate('tabs.permissionList.deleteRoleConfirmEnd')}`} />;

    return data ? (
        <>

            { DeleteRole }
            <div className={classes.ToggleSet}>
                {renderRows()}
            </div>
            </>
    ) : <div>{translate('tabs.permissionList.loading')}</div>

}
