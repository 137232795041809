import React, {ChangeEvent, useEffect, useRef, useState} from 'react';

import {Grid} from 'semantic-ui-react';
import {translate} from '../../../i18n/i18n';
import {Checkbox} from '@material-ui/core';
import {color} from '../../../utils/styles';

import {useStyles} from './styles';
import {initialSorting, SortingInterface,} from '../../../interfaces/sorting-interface';
import {usePagination} from '../../../helpers/usePagination';
import {getQuestionTypesForDropdown, getSubareaTypesForDropdown,} from '../../../helpers/Questions';
import {useSelector} from 'react-redux';

import {BulkAction} from '../../../components/shared/BulkAction';
import {useBulkFunction} from '../../../helpers/useBulkFunction';
import DeleteModal from '../../../components/modals/DeleteModal';
import ContentCard from '../../../components/shared/ContentCard';
import TableAction from '../../../components/shared/TableAction';
import Button from '../../../components/shared/Button';
import Table from '../../../components/shared/Table';

import {questionBulkFunction} from '../../../enums/questions/questions-enums';

import {subAreaSelector} from '../../../store/subareas/subareaSlice';

import QuestionDetails from '../../../components/authoring/QuestionDetails/QuestionDetails';
import {useTableSearch} from '../../../helpers/useTableSearch/useTableSearch';
import TableSearch from '../../../components/shared/TableSearch';
import TableFilter from '../../../components/shared/TableFilter';

import axios from '../../../app/axios';
import {courseSelector} from '../../../store/courses/courseSlice';

const AllQuestions = (props: any) => {
    const classes = useStyles();
    const {current: currentCourse} = useSelector(courseSelector);
    const {data: data} = useSelector(subAreaSelector);
    const [questions, setQuestions] = useState<any[]>([]);
    const [questionsCount, setQuestionsCount] = useState(0);
    const [sorting, setSorting] = useState<SortingInterface>(initialSorting);
    const {
        setSelectedAll,
        handleSelect,
        isSelected,
        selected,
        selectedAll,
        setSelected,
    } = useBulkFunction(questions);
    const {
        handleSearchChange,
        search,
        setSearch,
        showSearch,
        setShowSearch,
        CurrentlySearching,
    } = useTableSearch();
    const searchProps = {
        handleSearchChange,
        search,
        setSearch,
        showSearch,
        setShowSearch,
        CurrentlySearching,
    };
    // const {handleSelectAll, handleSelect, isSelected, selected, selectedAll, setSelected} = useBulkFunction(questions);
    const {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        handleResetPage,
    } = usePagination();

    const allQuestionTypes = getQuestionTypesForDropdown();
    // const allCourses = getCoursesForDropdown(courseData);
    const [loading, setLoading] = useState(false);

    const [createQuestion, setCreateQuestion] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(0);

    const [shownModal, setShownModal] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState<{
        id: number;
        name: string;
    } | null>(null);
    const [trigger, setTrigger] = useState(false);

    let searchTimeout: any = useRef();

    const [filterValues, setFilterValues] = useState<any>({
        question_type: 'ALL',
        subareas: [] as number[],
    });

    useEffect(() => {
        handleResetPage();
    }, [
        sorting,
        search,
        rowsPerPage,
        currentCourse,
        filterValues,
        createQuestion,
    ]);

    useEffect(() => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current)
        }
        searchTimeout.current = setTimeout(async () => {
            await fetchQuestions();
        }, 500)

    }, [
        trigger,
        search,
        sorting,
        page,
        rowsPerPage,
        currentCourse,
        filterValues,
        createQuestion,
    ]);

    const fetchQuestions = async () => {
        setLoading(true);
        try {
            const questions = await axios.get(
                `/courses/${
                    currentCourse.id
                }/questions-subarea?page=${page}&sort=${sorting.column}&type=${
                    sorting.desc ? 'desc' : 'asc'
                }&${
                    filterValues.subareas.length > 0 &&
                    filterValues.subareas
                        .map((subarea: any) => `subareas[]=${subarea}`)
                        ?.join('&')
                }&limit=${rowsPerPage}${
                    search !== '' ? `&search=${search}` : ''
                }${
                    filterValues.question_type !== 'ALL'
                        ? `&question_type=${filterValues.question_type}`
                        : ''
                }`,
            );
            setLoading(false);
            setQuestions(questions.data.success);
            setQuestionsCount(questions.data.count);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedAll(true);
            if (questions && Array.isArray(questions)) {
                setSelected(questions.map((item: any) => item.id));
            }
        } else {
            setSelectedAll(false);
            setSelected([]);
        }
    };

    const handleDeleteQuestion = async () => {
        setIsDeleting(true);
        await axios
            .delete(
                `/courses/${currentCourse.id}/questions/${
                    selectedQuestion && selectedQuestion.id
                }`,
            )
            .then((response: any) => {
                setIsDeleting(false);
                resetModals();
                setTrigger(!trigger);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const handleBulkDeleteQuestion = async () => {
        if (selected.length) {
            const res = {
                questions: selected,
            };
            await axios
                .delete(`courses/${currentCourse.id}/questions`, {data: res})
                .then((response: any) => {
                    setSelected([]);
                    resetModals();
                    setTrigger(!trigger);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    const handleBulkEdit = (
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
        switch (event.target.value) {
            case questionBulkFunction.DELETE:
                setShownModal(questionBulkFunction.DELETE);
                break;
            default:
                break;
        }
    };

    const handleChangeSorting = (column: string) => {
        if (column === sorting.column && sorting.desc)
            setSorting(initialSorting);
        else if (column === sorting.column && !sorting.desc)
            setSorting({...sorting, desc: true});
        else if (column !== sorting.column)
            setSorting({...sorting, column: column});
    };

    // const handleEditQuestion = (id: number, name: string) => {
    //   selectToEdit(id, name)
    // }

    const setQuestionToDelete = (id: number, name: string) => {
        setShownModal('delete');
        setSelectedQuestion({id, name});
    };

    const resetModals = () => {
        setShownModal(null);
        setSelectedQuestion(null);
    };
    const handleSave = () => {
        setCreateQuestion(false);
    };

    const handleAbort = () => {
        setCreateQuestion(false);
    };

    const handleSetFilterValues = (values: any, name: string) => {
        let localFilterValues = {...filterValues, [name]: values};
        setFilterValues(localFilterValues);
    };

    const handleSelectEdit = (id: number, name: string) => {
        setCreateQuestion(true);
        setSelectedQuestionId(id);
    };

    const allSubareas = getSubareaTypesForDropdown(data);

    const filterOptions = [
        {
            type: 'hasMany',
            name: 'subareas',
            placeholder: translate('authoring.allQuestions.subareas'),
            value: filterValues.subareas,
            previewValue: filterValues.subareas,
            options: allSubareas,
        },
        {
            type: 'hasOne',
            name: 'question_type',
            placeholder: translate('authoring.allQuestions.questionType'),
            value: filterValues.question_type,
            previewValue: translate(
                `authoring.questions.questionTypes.${filterValues.question_type}`,
            ),
            options: allQuestionTypes,
        },
    ];

    const questionRowData = {
        select: {
            renderTitle: (): JSX.Element => (
                <label className={classes.label}>
                    <Checkbox
                        checked={selectedAll}
                        color="primary"
                        onChange={handleSelectAll}
                    />
                    <span className={classes.selectAll}>

					</span>
                </label>
            ),
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <Checkbox
                    checked={isSelected(item.id)}
                    color="primary"
                    onClick={(event) => handleSelect(event, item.id)}
                />
            ),
        },
        name: {
            title: translate('authoring.allQuestions.name'),
            sorting: 'question',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p style={{cursor: 'pointer'}}>{item.name.length > 60 ? item.name.substr(0, 60) + '...' : item.name}</p>
            ),
        },

        subareas: {
            title: translate('authoring.allQuestions.exercise'),
            align: 'left',
            renderType: (item: any): JSX.Element => {
                return (
                    <span className={classes.subareasWrapper}>
						{item.subareas
                            ? item.subareas.map(function (subarea: any) {
                                return (
                                    <>
                                        {subarea.headline}
                                        {item.subareas.indexOf(subarea) !==
                                        item.subareas.length - 1 &&
                                        ','}{' '}
                                    </>
                                );
                            })
                            : '-'}
					</span>
                );
            },
        },

        type: {
            title: translate('authoring.allQuestions.type'),
            sorting: 'type',
            align: 'left',
            renderType: (item: any): JSX.Element => (
                <p style={{cursor: 'pointer'}}>{item.type}</p>
            ),
        },

        edit: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <TableAction
                    handleClick={() => handleSelectEdit(item.id, item.question)}
                    tooltip={translate('tooltip.edit')}
                    type="edit"
                />
            ),
        },
        delete: {
            title: '',
            align: 'right',
            renderType: (item: any): JSX.Element => (
                <TableAction
                    handleClick={() =>
                        setQuestionToDelete(item.id, item.question)
                    }
                    tooltip={translate('tooltip.delete')}
                    type="delete"
                />
            ),
        },
    };

    const headerComponent = (
        <>
            {!selected.length && (
                <>
                    <TableFilter
                        options={filterOptions}
                        setFilterValues={handleSetFilterValues}
                    />
                    <TableSearch searchProps={searchProps}
                                 placeholder={translate('authoring.allQuestions.placeholder')}/>
                </>
            )}

            {!!selected.length && (
                <BulkAction
                    handleBulkEdit={handleBulkEdit}
                    bulkFunctions={questionBulkFunction}
                />
            )}
        </>
    );

    return (
        <div className={classes.ListWrapper}>
            <>
                {createQuestion && (
                    <QuestionDetails
                        courseId={currentCourse.id}
                        onSave={handleSave}
                        onAbort={handleAbort}
                        selectedSubAreaId={0}
                        selectedQuestionId={selectedQuestionId}
                    />
                )}
                {!createQuestion && (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}></Grid.Column>
                            <Grid.Column width={8}>
                                <Button
                                    title={translate(
                                        'authoring.allQuestions.newQuestion',
                                    )}
                                    type="solid"
                                    color={color.link}
                                    onClick={() => {
                                        setCreateQuestion(true);
                                        setSelectedQuestionId(0);
                                    }}
                                    icon="add"
                                    styles={{float: 'right'}}
                                    iconColor={color.white}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{marginBottom: 50}}>
                            <Grid.Column width={16}>
                                <ContentCard
                                    title={translate(
                                        'authoring.allQuestions.quetion',
                                    )}
                                    headerComponent={headerComponent}
                                >
                                    <Table
                                        showHeader
                                        rows={questionRowData}
                                        data={questions || []}
                                        loading={loading}
                                        handleChangeSorting={
                                            handleChangeSorting
                                        }
                                        sorting={sorting}
                                        total={questionsCount}
                                        handleChangePage={handleChangePage}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleChangeRowsPerPage={
                                            handleChangeRowsPerPage
                                        }
                                    />
                                </ContentCard>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                <DeleteModal
                    additionalInfo={translate(
                        'authoring.allQuestions.deleteSelectedQuestion',
                    )}
                    isLoading={isDeleting}
                    showModal={!!selectedQuestion && shownModal === 'delete'}
                    closeModal={resetModals}
                    onDelete={handleDeleteQuestion}
                    confirmText={translate(
                        'authoring.allQuestions.deleteQuestion',
                    )}
                    message={`${translate(
                        'authoring.allQuestions.deleteConfirm',
                    )} <b>'${selectedQuestion?.name}'</b>?`}
                />

                <DeleteModal
                    isLoading={isDeleting}
                    showModal={
                        !!selected.length &&
                        shownModal === questionBulkFunction.DELETE
                    }
                    closeModal={resetModals}
                    onDelete={handleBulkDeleteQuestion}
                    confirmText={translate(
                        'authoring.allQuestions.deleteQuestion',
                    )}
                    message={translate(
                        'authoring.allQuestions.deleteQuestionVerify',
                    )}
                />
            </>
        </div>
    );
};

export default AllQuestions;
