import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { color } from '../../../utils/styles';
import { useStyles as modalStyles } from '../../shared/Modal/styles';
import { useStyles } from './styles';
import Modal from '../../shared/Modal';
import { getGroupsForDropdown } from '../../../helpers/Groups';
import axios from '../../../app/axios';
import MultiSelect from '../../shared/MultiSelect';
import { translate } from '../../../i18n/i18n';
import { groupSelector } from '../../../store/groups/groupSlice';
import { fetchUsers } from '../../../store/users/userSlice';

interface EditUserDataModal {
	openModal: boolean;
	closeModal: () => void;
	handleChangeUser?: (prop: string) => void;
	userId: number | string | null;
	ownProfile: boolean;
	onSave: () => void;
}

interface InitialValues {
	avatar?: string | null;
	first_name?: string | null;
	last_name?: string | null;
	email?: string | null;
	groups?: any[];
}

const EditUserDataModal: React.FunctionComponent<EditUserDataModal> = ({
	openModal,
	closeModal,
	userId,
	ownProfile,
	onSave,
}) => {
	const [user, setUser] = useState<{
		first_name: string;
		last_name: string;
		avatar: string;
		email: string;
		groups: any[];
	} | null>(null);

	useEffect(() => {
		if (openModal && !user) fetchUser();
	}, [openModal]);

	useEffect(() => {
		if (!!user && !multiSelectOptions.length && !ownProfile) {
			fetchInitialGroups();
		}
	}, [user]);

	const fetchUser = async () => {
		const res = await axios.get(`/users/${userId}`);
		if (res.data.success) setUser(res.data.success);
	};

	const fetchInitialGroups = async () => {
		const res = await axios.get('/groups');
		if (res.data.success && res.data.success.data.length) {
			const multiSelectValues = res.data.success.data.map(
				(group: any) => ({
					key: group.id,
					text: group.name,
					value: group.id,
				}),
			);
			if (user && user.groups.length) {
				user.groups.map((group: { id: number; name: string }) => {
					const hasItem = multiSelectValues.find(
						(item: any) => item.key === group.id,
					);
					if (!hasItem) {
						multiSelectValues.push({
							key: group.id,
							text: group.name,
							value: group.id,
						});
					}
				});
			}
			setMultiSelectOptions([
				...multiSelectOptions,
				...multiSelectValues,
			]);
		}
	};

	const dispatch = useDispatch();

	const modalClasses = modalStyles();
	const classes = useStyles();
	const formikRef: any = useRef();

	const uploadedImage = React.useRef<any>(null);
	const imageUploader = React.useRef<any>(null);

	// MULTISELECT
	const [multiSelectOptions, setMultiSelectOptions] = useState<any[]>([]);
	const [multiSelectFetching, setMultiSelectFetching] = useState(false);

	const handleMultiSelectSearch = async (searchValue: string) => {
		setMultiSelectFetching(true);
		if (!!searchValue) {
			try {
				const searchedData = await axios.get(
					`/groups?search=${searchValue}`,
				);
				const newItems: any = [];
				searchedData.data.success.data.map((group: any) => {
					const hasItem = multiSelectOptions.find(
						(item: any) => item.key === group.id,
					);
					if (!hasItem) {
						newItems.push({
							key: group.id,
							text: group.name,
							value: group.id,
						});
					}
				});
				setMultiSelectOptions([...multiSelectOptions, ...newItems]);
				setMultiSelectFetching(false);
			} catch (err) {
				setMultiSelectFetching(false);
			}
		} else {
			setTimeout(() => setMultiSelectFetching(false), 500);
		}
	};

	const { data: groupData }: any = useSelector(groupSelector);

	const allGroups = getGroupsForDropdown(groupData);

	const handleFormikSubmit = () => {
		if (formikRef.current) {
			formikRef.current.handleSubmit();
		}
	};

	// useEffect(() => {
	// 	// if (openModal || userId) {
	// 	// if (ownProfile) {
	// 	// 	dispatch({
	// 	// 		...fetchSelf({
	// 	// 			requestType: 'GET',
	// 	// 			request: `smartometer/users/me`,
	// 	// 		}),
	// 	// 	});
	// 	// } else {
	// 	// 	// dispatch({
	// 	// 	// 	...fetchUser({
	// 	// 	// 		requestType: 'GET',
	// 	// 	// 		request: `smartometer/users/${userId}`,
	// 	// 	// 	}),
	// 	// 	// });
	// 	// }

	// 	dispatch({
	// 		...fetchGroups({
	// 			requestType: 'GET',
	// 			request: `smartometer/groups`,
	// 		}),
	// 	});
	// 	// }
	// }, [openModal, userId]);

	const onValidate = (val: any) => {
		const errors: InitialValues = {};
		if (!val.first_name.trim())
			errors.first_name = translate('modals.editUserDataModal.required');
		if (!val.last_name.trim())
			errors.last_name = translate('modals.editUserDataModal.required');
		if (!val.email.trim())
			errors.email = translate('modals.editUserDataModal.required');
		if (
			!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				val.email.trim(),
			)
		)
			errors.email = translate('modals.editUserDataModal.noValidEmail');
		return errors;
	};

	const onSubmit = async (values: InitialValues) => {
		await axios.put(`/users/${userId}`, {
			avatar: values.avatar,
			email: values.email,
			first_name: values.first_name,
			last_name: values.last_name,
			groups: values.groups || [],
		});
		dispatch({
			...fetchUsers({
				requestType: 'GET',
				request: 'smartometer/users',
			}),
		});
		if (userId == window.localStorage.getItem('id')) {
			window.localStorage.setItem('avatar', values.avatar || '');
			window.localStorage.setItem('user', values.email || '');
			window.localStorage.setItem('firstname', values.first_name || '');
			window.localStorage.setItem('lastname', values.last_name || '');
		}
		onSave();
		closeModal();
		setUser(null);
	};

	return (
		<Modal
			showModal={openModal && !!user}
			header={
				ownProfile
					? translate(`editProfile.myProfile`)
					: translate(`users.users.edit.headline`)
			}
			onClose={() => {
				closeModal();
			}}
			onSubmit={handleFormikSubmit}
			isLoading={!user}
			submitActionTitle={translate(`save`)}
			closeActionTitle={translate(`cancel`)}
			submitActionColor={color.link}
			disabled={!user}
			secondaryColor={color.link}
			additionalClasses={modalClasses.CentralModal}
		>
			{!!user && (
				<Formik
					validate={onValidate}
					innerRef={formikRef}
					initialValues={{
						...user,
						groups: user.groups.map(
							(group: { id: number }) => group.id,
						),
					}}
					onSubmit={onSubmit}
				>
					{({
						handleChange,
						values,
						errors,
						setFieldValue,
						touched,
						handleBlur,
					}) => {
						const handleGroupChange = (groups: number[]) =>
							setFieldValue('groups', groups);

						const handleImageUpload = async (e: any) => {
							const [file] = e.target.files;
							if (file) {
								const reader = new FileReader();
								const { current } = uploadedImage;
								current.file = file;
								reader.onload = (e) => {
									current.src = e?.target?.result;
								};
								reader.readAsDataURL(file);
								let formData = new FormData();
								formData.append('file', file);
								const imgRes = await axios({
									method: 'POST',
									url: '/file-upload',
									baseURL: `${process.env.REACT_APP_API_URL}/api`,
									data: formData,
									headers: {
										accept: 'application/json',
										'Accept-Language': 'en-US,en;q=0.8',
									},
								});
								setFieldValue('avatar', imgRes.data.default);
							}
						};

						return (
							<>
								<div style={{ paddingBottom: '30px' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<input
											type="file"
											accept="image/*"
											onChange={handleImageUpload}
											ref={imageUploader}
											name="avatar"
											style={{
												display: 'none',
											}}
										/>
										<div
											style={{
												height: '120px',
												width: '120px',
												marginBottom: '10px',
											}}
											onClick={() =>
												imageUploader.current.click()
											}
										>
											<img
												src={
													values.avatar ||
													'/defaultUser.png'
												}
												ref={uploadedImage}
												style={{
													width: '100%',
													height: '100%',
													objectFit: 'cover',
													borderRadius: '100px',
													cursor: 'pointer',
												}}
											/>
										</div>
									</div>
								</div>
								<div className={classes.InputWrapper}>
									<TextField
										className={`${classes.Input} ${classes.InputDemi}`}
										error={
											touched.first_name &&
											!!errors.first_name
										}
										helperText={
											touched.first_name &&
											!!errors.first_name &&
											errors.first_name
										}
										id="first_name"
										value={values.first_name}
										label={translate(
											'modals.addUserModal.firstname',
										)}
										name="first_name"
										variant="outlined"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<TextField
										className={classes.InputDemi}
										error={
											touched.last_name &&
											!!errors.last_name
										}
										helperText={
											touched.last_name &&
											!!errors.last_name &&
											errors.last_name
										}
										id="last_name"
										value={values.last_name}
										label={translate(
											'modals.addUserModal.lastname',
										)}
										name="last_name"
										variant="outlined"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>

								<TextField
									className={classes.Input}
									error={touched.email && !!errors.email}
									helperText={
										touched.email &&
										!!errors.email &&
										errors.email
									}
									id="email"
									value={values.email}
									label={translate(
										'modals.addUserModal.email',
									)}
									name="email"
									variant="outlined"
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								{!ownProfile && (
									<MultiSelect
										bordered
										style={{
											backgroundColor: '#fff',
											marginTop: 10,
										}}
										type="search"
										maxItems={4}
										handleChange={(newCourses: number[]) =>
											handleGroupChange(newCourses)
										}
										inputTitle={translate(
											'modals.editUserDataModal.groups',
										)}
										modalTitle={translate(
											'modals.editUserDataModal.groups',
										)}
										selected={values.groups!}
										options={multiSelectOptions}
										position="modal"
										handleSearch={handleMultiSelectSearch}
										searchLoading={multiSelectFetching}
									/>
								)}
							</>
						);
					}}
				</Formik>
			)}
		</Modal>
	);
};

export default EditUserDataModal;
