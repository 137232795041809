import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { translate } from '../../../i18n/i18n';

interface LoginState {
	isSuccess: boolean;
	userType: string;
	isEndUser: number;
	selectedCourse: number;
	isLoading: boolean;
	errorMessage: string;
	data: any;
	isSubmitting: boolean;
	isSuccessEmail: boolean;
	shouldRedirect: boolean;
	role: any;
}

const initialState: LoginState = {
	isSuccess: false,
	userType: '',
	isEndUser: 0,
	selectedCourse: 0,
	isLoading: false,
	errorMessage: '',
	data: null,
	isSubmitting: false,
	isSuccessEmail: false,
	shouldRedirect: false,
    role: null
};

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		logIn: (state, action: any) => {
			if (action.state === 'send') {
				state.isSubmitting = true;
			}
			if (action.state === 'finished') {
				state.errorMessage = '';
				const { success, type, selectedCourse } = action.response;
				window.localStorage.setItem('avatar', success.avatar);
				window.localStorage.setItem('user', success.email);
				window.localStorage.setItem('firstname', success.firstname);
				window.localStorage.setItem('lastname', success.lastname);
				window.localStorage.setItem('jwt', success.token);
				window.localStorage.setItem('id', success.id);
				window.localStorage.setItem(
					'institutionLogo',
					success.institutionLogo,
				);
				window.localStorage.setItem(
					'institutionColor',
					success.institutionColor,
				);
				window.localStorage.setItem(
					'institutionFavicon',
					success.institutionFavicon,
				);
				window.localStorage.setItem('isEndUser', success.isEndUser);
				window.localStorage.setItem('userType', type);

                window.localStorage.setItem(
                    'role',
                    JSON.stringify(success.role),
                );

				state.userType = type;
				state.isEndUser = success.isEndUser;
				state.isSuccess = true;
				state.role = success.role;

				if (selectedCourse) {
					state.selectedCourse = selectedCourse;
					window.localStorage.setItem(
						'selectedCourse',
						selectedCourse,
					);
				}
			} else if (action.state === 'error') {
				state.isSubmitting = false;
				state.errorMessage = translate(`authentication.login.error`);
			}
		},
		getInstitution: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.data = action.response.data;
				state.isLoading = false;
			}
		},
		clearState: (state) => {
			state.errorMessage = '';
			state.isSuccess = false;
			state.isSubmitting = false;
			return state;
		},
		register: (state, action: any) => {
			if (action.state === 'send') {
				state.isSubmitting = true;
			}
			if (action.state === 'finished') {
				state.errorMessage = '';
				state.isSuccessEmail = true;
				state.isSubmitting = false;
			} else if (action.state === 'error') {
				state.isSubmitting = false;
				state.errorMessage = 'This Email is already taken';
			}
		},
		sendForgotPassword: (state, action: any) => {
			if (action.state === 'send') {
				state.isSubmitting = true;
				state.isSuccess = false;
			}
			if (action.state === 'finished' || action.state === 'error') {
				state.isSubmitting = false;
				state.isSuccess = true;
			}
		},
		setPassword: (state, action: any) => {
			if (action.state === 'finished') {
				const { success, redirect } = action.response;
				window.localStorage.setItem('avatar', success.avatar);
				window.localStorage.setItem('user', success.email);
				window.localStorage.setItem('firstname', success.firstname);
				window.localStorage.setItem('lastname', success.lastname);
				window.localStorage.setItem('jwt', success.token);
				window.localStorage.setItem('id', success.id);

				state.isSuccess = true;
				state.shouldRedirect = redirect;
			}
		},
	},
});

export const {
	logIn,
	clearState,
	getInstitution,
	register,
	setPassword,
	sendForgotPassword,
} = loginSlice.actions;

export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
