import React from "react";
import {useStyles} from "./styles"

interface ColorFieldProps{
    color: string;
    selectedColor?: string |  null;
    title: string;
    handleSelect: React.MouseEventHandler;
}

const ColorField: React.FunctionComponent<ColorFieldProps> = ({ color, handleSelect, title, selectedColor }) => {
    const classes = useStyles();
    return (
        <div className={classes.ColorFieldWrapper}>
            <div className={classes.ColorField} onClick={handleSelect} style={{ backgroundColor: color }} />
            <div className={`${classes.ColorLabel} ${selectedColor === color ? "active" : ""} `}>{title}</div>
        </div>
    )

}

export default ColorField;
