import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import TextField from '@material-ui/core/TextField';
import {useStyles} from "./styles"
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles"
import {fetchGroups, groupLoadingSelector} from "../../../store/groups/groupSlice";
import axios from "../../../app/axios";
import {translate} from "../../../i18n/i18n";

interface EditGroupModalProps {
    showModal: boolean,
    closeModal: () => void,
    selectedGroup: {
        id: number,
        name: string
    }
}

interface EditGroupInterface {
    name: string;
}

interface EditGroupErrors {
    name?: string;
}

const EditGroupModal: React.FunctionComponent<EditGroupModalProps> = ({showModal, closeModal, selectedGroup}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const groupSubmitting = useSelector(groupLoadingSelector);

    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();

    const initialValues: EditGroupInterface = {
        name: selectedGroup?.name
    }

    useEffect(() => {
        if (isSubmitting) {
            setIsSubmitting(false);
            dispatch({
                ...fetchGroups({
                    requestType: "GET",
                    request: 'smartometer/groups',
                }),
            });
            closeModal();
        }
        ;
        if (!!groupSubmitting) setIsSubmitting(true)
    }, []);

    const handleFormikSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    }

    const onSubmit = async (values: EditGroupInterface, {setErrors}: any) => {
        setIsSubmitting(true);
        try {
            await axios.put(`/groups/${selectedGroup?.id}`, {name: values.name.trim()});
            dispatch({
                ...fetchGroups({
                    requestType: "GET",
                    request: 'smartometer/groups',
                }),
            });
            closeModal();
        } catch (err) {
            if (err.response.data.error === "duplicate name") {
                setErrors({name: translate('modals.editGroupModal.groupExists')});
            }
        }
        setIsSubmitting(false);
    };

    const onValidate = (val: any) => {
        const errors: EditGroupErrors = {}
        if (!val.name.trim()) errors.name = translate('modals.editGroupModal.fieldRequired');
        return errors;
    }

    return (
        <>
            <Modal showModal={showModal} header={translate('modals.editGroupModal.header')} onClose={closeModal}
                   onSubmit={handleFormikSubmit} isLoading={isSubmitting} submitActionTitle={translate('modals.editGroupModal.submit')}
                   closeActionTitle={translate('modals.editGroupModal.close')} submitActionColor={color.link} disabled={isSubmitting}>
                <Formik
                    validate={onValidate}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({handleChange, handleBlur, values, errors, touched}) => (
                        <TextField className={classes.Input} error={touched.name && !!errors.name}
                                   helperText={touched.name && !!errors.name && errors.name} id="name"
                                   value={values.name} label={translate('modals.editGroupModal.name')} name="name" variant="outlined"
                                   onChange={handleChange} onBlur={handleBlur}/>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

export default EditGroupModal;
