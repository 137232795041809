import { makeStyles } from "@material-ui/core/styles";
import {textSizes, color} from "../../../utils/styles"

export const useStyles = makeStyles(theme => ({



    header: {
        fontSize: 24,
        textAlign: 'left',
        paddingBottom: 24
    },
    searchBar: {
        width: "100%",
        paddingBottom: 24
    },
    questionBox: {
        height: 500,
        overflowY: 'scroll'
    },
    courseHeader: {
        fontWeight: 700,
        fontSize: 16,
        color: color.textDark,
        paddingTop: 25,
        margin: 0
    },
    subheader: {
        fontWeight: 400,
        fontSize: 14,
        color: color.lightGray,
        paddingTop: 18,
        margin: 0
    }
}))
