import { makeStyles } from '@material-ui/core';
import { color } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	SelectionPreview: {
		height: 35,
		display: 'flex',
		alignItems: 'center',
		fontSize: 16,
		background: '#fff',
		borderRadius: 20,
		minWidth: 180,
		padding: '0 40px 0 20px',
		position: 'relative',
		cursor: 'default',
		flexWrap: 'nowrap',
	},
	SelectionPreviewBordered: {
		border: '1px solid',
		borderColor: color.appTextSecondary,
	},
	SelectionPreviewModal: {
		height: 55,
		background: '#f8f8f8',
		borderRadius: 30,

		'& .filter-icon': {
			top: 20,
		},
	},
	FilterIcon: {
		position: 'absolute',
		cursor: 'pointer',
		right: 20,
		top: 11,
		width: 20,
		color: color.textDark,
		transition: '.2s',

		'&:hover': {
			color: color.link,
		},
	},
	SelectionPreviewPlaceholder: {
		color: color.appTextSecondary,
		fontSize: 14,
	},
	SelectionPreviewSelected: {
		color: color.textDark,
		fontSize: 14,
		display: 'flex',
		flexWrap: 'nowrap',
	},
	SelectedOptionPreview: {
		color: '#fff',
		background: color.link,
		height: 20,
		lineHeight: '20px',
		padding: '0 12px',
		whiteSpace: 'nowrap',
		marginBottom: 0,
		marginLeft: 5,
		borderRadius: 10,
	},
	SelectedOptionCounter: {
		background: color.link,
		width: 20,
		height: 20,
		lineHeight: '20px',
		borderRadius: 10,
		color: '#fff',
		fontWeight: 600,
		fontSize: 10,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 0,
		marginLeft: 5,
		letterSpacing: '-1px',
	},

	SelectedOption: {
		background: color.link,
		border: '1px solid',
		borderColor: color.link,
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		height: 25,
		lineHeight: '25px',
		borderRadius: 13,
		marginRight: 5,
		marginBottom: 3,
		padding: '0 12px',

		'& svg': {
			color: 'inherit',
			width: 15,
			marginRight: 5,
		},

		'&:hover': {
			background: '#fff',
			color: color.link,
		},
	},
	RemoveAllFilters: {
		background: '#fff',
		color: color.link,

		'&:hover': {
			background: color.link,
			color: '#fff',
		},
	},
	FilteredOptions: {
		height: 300,
		overflowY: 'auto',
	},
	Modal: {
		background: '#fff',
		width: 500,
		maxWidth: '100%',
		padding: '20px 20px 0 20px',
		borderRadius: 5,
	},
	ModalHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',

		'& h2,p': {
			marginBottom: 0,
			fontWeight: 400,
		},

		'& p': {
			color: color.link,
			cursor: 'pointer',
		},
	},
	TextField: {
		width: '100%',
		marginTop: 30,
		marginBottom: 15,
	},
	ModalSelectionWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		maxHeight: 100,
		overflow: 'auto',
		marginBottom: 15,
	},
	FilterHeadline: {
		color: color.appTextSecondary,
		fontSize: 14,
		textTransform: 'uppercase',
		padding: '0 10px 10px 10px',
		fontWeight: 400,
		marginTop: 0,
		marginBottom: 0,
	},
	OptionItem: {
		width: '100%',
		height: 45,
		lineHeight: '45px',
		borderRadius: 5,
		padding: '0 40px 0 10px',
		fontSize: 14,
		color: color.textDark,
		marginBottom: 0,
		cursor: 'pointer',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		transition: '.2s',
		position: 'relative',

		'&:nth-child(odd)': {
			background: '#F9FAFB',
		},

		'&:hover': {
			backgroundColor: color.link,
			color: '#fff',

			'& svg': {
				color: '#fff',
			},
		},

		'& svg': {
			position: 'absolute',
			right: 10,
			top: 10,
			width: 15,
			color: color.link,
			transform: 'rotate(45deg)',
		},
	},
	SpinnerWrapper: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
