import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes } from "../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    Label: {
        color: color.textDark,
        fontSize: textSizes.bodyNormal,
        width: 250,
        display: "flex",
        flexDirection: "column",

        "& p": {
            marginBottom: 0,
        },

        "& span": {
            display: "inline-block",
            width: "100%",
            fontSize: textSizes.notice,
            color: color.textGrey
        }
    },
    ColorInput: {
        width: "100%"
    },
    ColorPickerWrapper: {
        position: "relative",
        width: "100%",
        marginTop: 20,

        "& input": {
            width: 200,
           
        },

        "& .MuiTextField-root": {
            marginRight: 20
        }
    },
    ColorFieldWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        
        "& button:first-of-type": {
            marginLeft: 20
        }
    },
    ColorPicker: {
        position: "absolute",
        top: "100%",
        left: 250,
        width: 230,

        "& .chrome-picker": {
            width: "228px !important"
        }
    },
    ButtonWrapper: {
        display: "flex",
        alignItems: "center",

        "& button": {
            marginRight: 20
        }
    },
    FileInput: {
        position: "relative",
        width: 100,
        height: 44,
        paddingLeft: 20,
        lineHeight: "44px",
        color: color.link,
        cursor: "pointer",
        fontSize: textSizes.bodyNormal,
        overflow: "hidden",
         marginRight: 20,

        "& input": {
            width: 85,
            height: 44,
            position: "absolute",
            left: 20,
            top: 0,
            opacity: 0,
            fontSize: 0,
            cursor: "pointer"
        },
        "& *": {
            cursor: "pointer"
        }
    },
    FileInputWrapper: {
        display: "flex",
        alignItems: "center",

        "& button": {
            marginRight: 20
        }
    }
}))
