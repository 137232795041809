import {QuestionTypes} from "../../enums/questions/questions-enums";
import {translate} from "../../i18n/i18n";

export const getSubareaTypesForDropdown = (subareaData: any) => {
    return subareaData?.map( (subarea: any) => {
        return {
            key: subarea.id,
            text: subarea.headline,
            value: subarea.id
        };
    });
}

export const getQuestionTypesForDropdown = () => {
    return [
        {
            key: 0,
            text: translate(`authoring.questions.questionTypes.ALL`),
            value: 'ALL',
        },
        {
            key: 0,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.SINGLE_CHOICE}`),
            value: QuestionTypes.SINGLE_CHOICE,
        },
        {
            key: 1,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.MULTIPLE_CHOICE}`),
            value: QuestionTypes.MULTIPLE_CHOICE,
        },
        {
            key: 2,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.ORDER_WORDS}`),
            value: QuestionTypes.ORDER_WORDS,
        },
        {
            key: 3,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.WORD_COMBINATION}`),
            value: QuestionTypes.WORD_COMBINATION,
        },
        {
            key: 4,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.ASSIGN_TERM}`),
            value: QuestionTypes.ASSIGN_TERM,
        },
        {
            key: 5,
            text: translate(`authoring.questions.questionTypes.${QuestionTypes.GAP_FILLS}`),
            value: QuestionTypes.GAP_FILLS,
        }
    ]
}
