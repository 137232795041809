import React, {useState} from "react";
import {Dropdown, DropdownProps} from "semantic-ui-react";
import {MissedDeadlineTypes} from "../../enums/assignments/assignments-enums";
import {useStyles} from "./styles";
import {getMissedDeadlineTypesForDropdown} from "../Courses";

export const useMissedDeadlines = () => {

    const classes = useStyles();

    const [missedDeadline, setMissedDeadline] = useState<MissedDeadlineTypes>(MissedDeadlineTypes.ALL);

    const allMissedDeadlineTypes = getMissedDeadlineTypesForDropdown();

    const handleMissedDeadlineSelectChange = (e: any, dropdown: DropdownProps) => {
        const currentType: MissedDeadlineTypes = dropdown.value as MissedDeadlineTypes;

        if(currentType in MissedDeadlineTypes) {
            setMissedDeadline(currentType);
        }
    };

    const MissedDeadlineDropdown = () => <Dropdown
        style={{}}
        className={classes.Multiselect}
        fluid
        name="missedDeadline"
        id="missedDeadline"
        onChange={handleMissedDeadlineSelectChange}
        value={missedDeadline}
        options={allMissedDeadlineTypes}
        placeholder='missedDeadline'
        selection
    />

    return {
        MissedDeadlineDropdown, missedDeadline
    }
}
