import { makeStyles, responsiveFontSizes } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles(theme => ({
	menuBar: {
		height: 108,
		padding: '0 50px 0 30px',
		boxShadow: 'none',
		borderBottomWidth: 2,
		borderBottomStyle: 'solid',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'fixed',
		width: '100%',
		backgroundColor: color.white,
	},
	LeftMenu: {
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			fontSize: 31,
			cursor: 'pointer',
			marginRight: 28,
		},
		'& h2': {
			margin: 0,
			fontSize: textSizes.headline,
			fontWeight: 400,
			cursor: 'pointer',
		},
	},
	RightMenu: {
		display: 'flex',
		alignItems: 'center',

		'& button': {
			marginRight: 10,

			'&:disabled': {
				cursor: 'not-allowed',
				pointerEvents: 'auto',
			},
		},

		'& .more-dd': {
			color: color.lightGray,
		},

		'& i': {
			display: 'none !important',
		},

		'& svg': {
			fontSize: 24,
		},
	},
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	appBar: {
		backgroundColor: '#fff',
		zIndex: theme.zIndex.drawer + 1,
	},
}));
