import { makeStyles } from "@material-ui/core/styles";
import {borderRadius, color} from "../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    UserHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "40px",

        "& .user-avatar": {
            backgroundColor: "#fff",
            width: "150px",
            height: "150px",
            borderRadius: "75px",
            marginRight: "76px",

            "& svg": {
                width: "70px",
                height: "70px"
            }
        },

        "& h1": {
            fontSize: "28px",
            width: "100%",
        }
    },
    UserInfo: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    UserInfoColumns: {
        display: "flex",
        alignItems: "flex-start",

        "& div": {
            marginRight: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },

        "& p": {
            marginBottom: "8px",
        },

        "& .link": {
            cursor: "pointer",
            color: color.link,

            "&.left": {
                marginTop:"8px"
            }
        }
    },
    ButtonWrapper: {
        display: "flex",
        flexDirection: "column",
        marginTop: '-60px'
    },
    GridWrapper: {
        marginBottom: "40px"
    },
    Multiselect: {
        height: 44,
        width: 300,
        position: "absolute !important" as any,
        right: 0,
        borderRadius: borderRadius.medium,
        "& .label": {
            background: color.lightBlue
        },
        "& > .text": {
            marginLeft: "0 !important" as any,
            // marginTop: "10px !important",
        }
    },
    CourseTitle: {
        fontWeight: 700,
        fontSize: 32,
        marginBottom: 26
    },
    EmptyImage: {
        height: 335,
        background: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24,
        marginBottom: 26
    }
}))
