import { pointerCoord } from '@ionic/core/dist/types/utils/helpers';
import { makeStyles } from '@material-ui/styles';
import { color, textSizes } from '../../../utils/styles';

export default makeStyles((theme) => ({
	CookieWrapper: {
		background: 'rgba(0,0,0,.8)',
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		padding: 20,
		alignItems: 'center',
		zIndex: 10000,
	},

	Modal: {
		display: 'flex',
		padding: 40,
		position: 'relative',
		background: '#fff',
		height: 'auto',
		transition: '.2s',
		alignItems: 'center',
		borderRadius: 8,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		maxWidth: 444,
		margin: '0 auto',
	},

	MoreInfoModal: {
		display: 'flex',
		padding: '40px 30px',
		position: 'relative',
		background: '#fff',
		height: 'auto',
		transition: '.2s',
		alignItems: 'center',
		borderRadius: 8,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		maxWidth: 444,
		margin: '0 auto',

		'& svg': {
			alignSelf: 'flex-end',
			cursor: 'pointer',
			marginBottom: 15,
		},
	},

	Logo: {
		maxWidth: 300,
		height: 'auto',
		margin: '20px auto',
	},

	Intro: {
		textAlign: 'center',
		fontSize: textSizes.bodyNormal,
		margin: 0,
	},

	AdjustSettings: {
		color: color.textGrey,
		display: 'inline-block',
		cursor: 'pointer',
		marginTop: 40,
		marginBottom: 20,

		'& svg': {
			width: '8px !important',
			marginLeft: 5,
			paddingTop: 2,
			transition: '.2s',

			'&.up': {
				transform: 'rotate(180deg)',
				paddingTop: 0,
			},
		},
	},

	MoreContent: {
		maxHeight: 0,
		backgroundColor: color.background,
		borderRadius: 5,
		boxSizing: 'border-box',
		overflow: 'hidden',
		transition: '0',
		width: '100%',
		margin: '0 0 0',
		padding: '0 20px',

		'&.open': {
			maxHeight: 400,
			padding: '20px 10px 0 20px',
			margin: '0 0 20px',
		},

		'& h4': {
			marginBottom: 5,

			'& svg': {
				transition: '.2s',
				cursor: 'pointer',
				color: color.appTextSecondary,

				'&:hover': {
					color: color.link,
				},
			},
		},
	},

	AcceptButton: {
		backgroundColor: color.link,
		color: '#fff',
		width: '100%',
		borderRadius: 5,
		cursor: 'pointer',
		height: 50,
		lineHeight: '50px',
		textAlign: 'center',
		fontSize: textSizes.bodyNormal,
        margin: '0 0 10px 0',
	},
	SaveButton: {
		color: color.link,
		width: '100%',
		cursor: 'pointer',
		height: 50,
		lineHeight: '50px',
		textAlign: 'center',
		fontSize: textSizes.bodyNormal,
		margin: '0 0 10px 0',
	},

	CookieItem: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginBottom: 20,

		'& .MuiSwitch-track': {
			backgroundColor: color.appTextSecondary,
		},

		'& .MuiSwitch-colorSecondary.Mui-checked:hover': {
			backgroundColor: 'rgba(20,133,238, .1)',
		},

		'& .MuiSwitch-colorSecondary.Mui-checked': {
			color: color.link,
		},

		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			background: '#2e8ce4',
		},

		'& .Mui-disabled': {
			cursor: 'not-allowed',
		},
	},
	CookieText: {
		flexGrow: 1,
	},
	MoreInfoItem: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '15px 0',
		width: '100%',
		borderTop: '1px solid',
		borderTopColor: color.background,
		fontSize: 12,

		'& p': {
			margin: 0,
			flexGrow: 1,
			textAlign: 'left',
		},

		'& h5': {
			margin: 0,
			width: 100,
			marginRight: 20,
			flexShrink: 0,
		},
	},
}));
