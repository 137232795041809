import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'semantic-ui-react';
import { useStyles } from './styles';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import { translate } from '../../../../../i18n/i18n';

export const RenderAssignTerms: React.FunctionComponent<{
	question: any;
	index: number;
	device: string;
	onChange?: Function;
	userChoice?: any[];
	isValidated?: boolean;
	correctOrder?: any[];
	predefinedOptions?: string[];
    settings?: any;
    isExam?: boolean;
}> = ({
	question,
	index,
	device,
	onChange,
	userChoice,
	isValidated,
	correctOrder,
	predefinedOptions,
    settings,
    isExam
}) => {
	const classes = useStyles();

	const terms = question.terms.map((term: any) => term.term);
	const words =
		predefinedOptions ||
		question.terms
			.map((term: any) => term.words.map((word: any) => word.term))
			.flat();
	const [positions, setPositions] = useState<any[]>([]);

	const [showOptions, setShowOptions] = useState<number | boolean>(false);
	const [selected, setSelected] = useState<number | boolean>(false);
	useEffect(() => {
		setSelected(showOptions);
	}, [showOptions, setSelected]);

	useEffect(() => {
		if (!!userChoice) {
			setPositions(userChoice);
		} else {
			setPositions([]);
		}
	}, [index]);

	const selectAnswer = (termIndex: number, secondWord: string) => {
		if (typeof termIndex === 'number') {
			let newPositions = positions;
			newPositions = newPositions.map((term: any, tIndex: number) =>
				term.filter(
					(word: string, index: number) => word !== secondWord,
				),
			);
			if (!newPositions[termIndex]) {
				newPositions[termIndex] = [secondWord];
			} else {
				newPositions[termIndex].push(secondWord);
			}
			setPositions(newPositions);
			if (onChange)
				onChange(QuestionTypes.ASSIGN_TERM, { order: newPositions });
		}
	};

	const renderChosenTerms = (i: number) => {
		const items: JSX.Element[] = [];

		if (!!correctOrder) {
			const correctTermsArray = correctOrder[i].words.map(
				(item: any) => item.term,
			);
			if (positions[i]) {
				positions[i].map((word: string, index: number) => {
					let isCorrectlyPositioned = false;
					if (isValidated) {
						if (correctTermsArray.indexOf(word) > -1) {
							isCorrectlyPositioned = true;
						}
					}
					items.push(
						<span
							key={word}
							className={`${classes.ListItemLabelTerms} ${
								isValidated && !isCorrectlyPositioned
									? classes.ListItemLabelWrong
									: ''
							} ${
								isValidated && isCorrectlyPositioned
									? classes.ListItemLabelRight
									: ''
							}`}
						>
							{word}
						</span>,
					);
				});
			}
			if (isValidated) {
				correctTermsArray.map((word: string) => {
					if (!positions[i] || positions[i]?.indexOf(word) === -1)
						items.push(
							<span
								key={word}
								className={`${classes.ListItemLabelTerms} ${classes.ListItemLabelCorrected}
							`}
							>
								{word}
							</span>,
						);
				});
			}
		}
		return items;
	};

	return (
		<div
			className={`${classes.questionWrapper}  ${
				typeof showOptions === 'number' && !isValidated ? 'opened' : ''
			}`}
			key={index}
		>
			<p className={classes.question}>
				{question.question} (
                {!isExam && settings?.exercise_show_question_type ? translate(`authoring.questionDetails.assignToTerm`):''}
                {isExam && settings?.exam_show_question_type ? translate(`authoring.questionDetails.assignToTerm`):''})
			</p>
			<FormControl className={classes.fieldControl} component="fieldset">
				{terms.map(function (firstWord: string, i: number) {
					return (
						<li
							key={firstWord}
							className={`${classes.ListItemTerms} ${
								selected === i && classes.selected
							}`}
							onClick={() => {
								if (isValidated) return;
								setShowOptions(i as number);
							}}
						>
							<span className={classes.ListNumberTerms}>
								{firstWord}
							</span>
							{renderChosenTerms(i)}
						</li>
					);
				})}
			</FormControl>
			<div id="control-wrapper-preview"></div>
			{typeof showOptions === 'number' &&
				ReactDOM.createPortal(
					<FormControl
						className={`${classes.options} ${
							device === 'desktop' ? classes.desktopOptions : ''
						}`}
						component="fieldset"
					>
						<div className={classes.icons}>
							<span>
								{translate(
									'authoring.renderAssignTerms.selectText',
								)}
							</span>
							<IconButton
								className={classes.button}
								color="inherit"
								onClick={() => {
									setShowOptions(false);
								}}
							>
								<Icon disabled size="small" name="close" />
							</IconButton>
						</div>
						<div className={classes.answerWrapper}>
							{words.map(function (word: string, i: any) {
								return (
									<li
										key={i}
										className={classes.ListItemOption}
										onClick={() => {
											selectAnswer(showOptions, word);
											setShowOptions(false);
										}}
									>
										<span
											className={`${classes.option} ${
												positions.flat().includes(word)
													? classes.selected
													: ''
											}`}
										>
											{word}
										</span>
									</li>
								);
							})}
						</div>
					</FormControl>,
					onChange
						? document.getElementById('control-wrapper')!
						: document.getElementById('control-wrapper-preview')!,
				)}
		</div>
	);
};
