import React from 'react'
import Modal from '../../shared/Modal';
import {color, textSizes} from "../../../utils/styles"
import {translate} from '../../../i18n/i18n';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface EditGroupModalProps {
    showModal: boolean,
    closeModal: () => void,
    onDelete: () => void,
    message: string,
    confirmText: string,
    isLoading: boolean,
    additionalInfo?: string,
    checkboxText?: string,
    handleChecked?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const DeleteModal: React.FunctionComponent<EditGroupModalProps> = ({
                                                                       showModal,
                                                                       closeModal,
                                                                       onDelete,
                                                                       message,
                                                                       confirmText,
                                                                       isLoading,
                                                                       additionalInfo,
                                                                       checkboxText,
                                                                       handleChecked,
                                                                       children
                                                                   }) => {
    return (
        <Modal showModal={showModal} header={<span dangerouslySetInnerHTML={{__html: message}}></span>}
               onClose={closeModal} onSubmit={onDelete} isLoading={isLoading} submitActionTitle={confirmText}
               closeActionTitle={translate('cancel')} submitActionColor={color.red} disabled={isLoading}>
            {checkboxText && handleChecked && <p><FormControlLabel
                control={<Checkbox style={{ margin: 0 }} color="primary" onChange={(event) => handleChecked(event)} name={checkboxText} />}
                label={checkboxText}
            /></p>}
            {additionalInfo && <p style={{fontSize: textSizes.bodyNormal}}>{additionalInfo}</p>}
            {children ?? ''}
        </Modal>
    )
}

export default DeleteModal;
