import React from 'react';
import { useHistory } from 'react-router-dom';
import AppProfileChangeAvatar from '../../../components/app//Profile/ChangeAvatar';
import AppProfileChangeName from '../../../components/app//Profile/ChangeName';
import AppProfileChangePassword from '../../../components/app/Profile/ChangePassword';
import AppProfileFeedback from '../../../components/app//Profile/Feedback';

export default function AppUser() {
	return (
		<>
			<div
				className="preview-window-navigation-content app-navigation-content"
				style={{ textAlign: 'center' }}
			>
				<AppProfileChangeAvatar />
				<AppProfileChangeName />
				<AppProfileChangePassword />
				<AppProfileFeedback />
			</div>
		</>
	);
}
