import React from 'react';
import { useSelector } from 'react-redux';
import { fetchAll, courseSelector } from '../../store/courses/courseSlice';

import Typography from '@material-ui/core/Typography';
import TabNavigator from '../../components/shared/TabNavigator';
import { translate } from '../../i18n/i18n';
import CoursesTab from '../../components/tabs/CoursesTab';
import CoursesAppucationTab from '../../components/tabs/CoursesAppucationTab';
import CoursesShopTab from '../../components/tabs/CoursesShopTab';
import CoursesShopDetails from './Details';

export default function Courses() {
	const { appucationData: appucationCourseData, isLoading }: any =
		useSelector(courseSelector);

	const coursesTabs = {
		courses: {
			title: translate('courses.header.courses'),
			component: <CoursesTab />,
		},
		coursesBooked: {
			title: translate('courses.header.bookedCourses'),
			component: (
				<CoursesAppucationTab
					courseData={appucationCourseData}
					isLoading={isLoading}
				/>
			),
		},
		coursesShop: {
			title: translate('courses.header.shop'),
			component: <CoursesShopTab />,
			detailPage: true,
			detailComponent: <CoursesShopDetails />,
		},
	};

	return (
		<div style={{ padding: '96px 30px 100px' }}>
			<Typography
				style={{ marginBottom: 32 }}
				variant="h4"
				component="h4"
			>
				{translate('courses.headline')}
			</Typography>
			<TabNavigator tabs={coursesTabs} defaultActive="courses" />
		</div>
	);
}
