import { Help } from '@material-ui/icons';
import React from 'react';
import { useStyles } from './styles';
import useUserContext from '../../../contexts/UserContext';

interface HelpTextProps {
	text: string;
	position: string;
}

const HelpText: React.FunctionComponent<HelpTextProps> = ({
	text,
	position,
}) => {
	const classes = useStyles();
	const { user } = useUserContext();
	return user && user.institution ? (
		<div className={`${classes.HelperWrapper} helper-wrapper`}>
			<Help
				className={classes.Icon}
				style={{
					width: 18,
					color: user.institution.color || 'black',
				}}
			/>
			<div
				className={classes.HelperText}
				style={position === 'right' ? { right: 0 } : { left: 0 }}
			>
				{text}
			</div>
		</div>
	) : null;
};

export default HelpText;
