import * as yup from "yup";
import YupPassword from "yup-password";

YupPassword(yup);
export const validationSchema = yup.object().shape({
    oldPassword: yup
        .string()
        .required('Old password is required'),
    password: yup
        .string()
        .required('New password is required')
        .password()
        .minLowercase(1)
        .minUppercase(1)
        .minSymbols(0)
        .minNumbers(1) // raise the lowercase requirement to 8
        .min(8),
    confirm_password: yup
        .string()
        .required('Confirm password is required')
        .oneOf([yup.ref('password')], 'Passwords does not match'),
});
