import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

interface GraphHeaderProps {
	headline: string;
	subtitle?: string | false;
}

export const GraphHeader: React.FunctionComponent<GraphHeaderProps> = ({
	headline,
	subtitle,
}) => {
	const classes = useStyles();
	return (
		<div className="chart-headline-course">
			<Typography className={classes.headline}>{headline}</Typography>
			<Typography className={classes.subtitle}>
				{subtitle || ''}
			</Typography>
		</div>
	);
};
