import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from './styles';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import {translate} from '../../../../../i18n/i18n';

export const RenderMultipleChoice: React.FunctionComponent<{
	question: any;
	index: number;
	onChange?: Function;
	isValidated?: boolean;
	selectedIndizes?: number[];
	settings?: any;
    isExam?: boolean;
}> = ({ question, index, onChange, isValidated, selectedIndizes, settings, isExam }) => {
	const classes = useStyles();

	return (
		<div className={classes.questionWrapper} key={index}>
			<p className={classes.question}>
				{question.question} {!isExam && !!settings?.exercise_show_question_type ? translate('authoring.renderMultipleChoice.multipleChoice'):''}{isExam && settings?.exam_show_question_type ? translate('authoring.renderMultipleChoice.multipleChoice'):''}
			</p>
			<RadioGroup>
				{question.answers &&
					question.answers.map((answer: any, index: number) => {
						const isSelected = selectedIndizes
							? selectedIndizes?.indexOf(index) > -1
							: false;

						return (
							<FormControlLabel
								key={index}
								className={`${
									isSelected && isValidated
										? classes.VerifiedQuestionRight
										: ''
								} ${
									!isSelected &&
									answer.is_correct &&
									isValidated
										? classes.VerifiedQuestionMissed
										: ''
								} ${
									isSelected &&
									!answer.is_correct &&
									isValidated
										? classes.VerifiedQuestionWrong
										: ''
								}`}
								control={
									<Checkbox
										checked={
											selectedIndizes &&
											selectedIndizes?.indexOf(index) > -1
										}
										disabled={isValidated}
										style={{ margin: 0 }}
										color="primary"
										name={answer.text}
										onChange={
											onChange
												? (e, checked) =>
														onChange(
															QuestionTypes.MULTIPLE_CHOICE,
															{
																i: index,
																checked:
																	checked,
															},
														)
												: () => {}
										}
									/>
								}
								label={answer.text}
							/>
						);
					})}
			</RadioGroup>
		</div>
	);
};
