import React, {useEffect, useState} from 'react'

import {useStyles} from "./styles"
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {useDispatch, useSelector} from "react-redux";
import {fetchGroups, groupSelector} from "../../../store/groups/groupSlice";
import {Dropdown, DropdownProps} from "semantic-ui-react";
import {translate} from "../../../i18n/i18n";

interface EditGroupModalProps {
    showModal: boolean,
    closeModal: () => void,
    onSubmit: (groups: number[]) => void,
    message: string,
    confirmText: string,
    isLoading: boolean,
    additionalInfo?: string;
}

export const AssignGroupModal: React.FunctionComponent<EditGroupModalProps> = ({
                                                                                   showModal,
                                                                                   closeModal,
                                                                                   onSubmit,
                                                                                   message,
                                                                                   confirmText,
                                                                                   isLoading,
                                                                                   additionalInfo
                                                                               }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {data: groupData, isLoading: groupsLoading, error: groupsError}: any = useSelector(groupSelector);
    const [groups, setGroups] = useState<number[]>([])

    const allGroups = groupData?.data?.map(function (group: any) {
        return {
            key: group.id,
            text: group.name,
            value: group.id
        };
    });

    useEffect(() => {
        if (showModal) {
            setGroups([]);
        }
    }, [showModal])


    useEffect(() => {
        dispatch(
            {
                ...fetchGroups({
                    requestType: "GET",
                    request: `smartometer/groups`,
                })
            }
        )
    }, [])

    const handleSelectChange = (e: any, dropdown: DropdownProps) => {
        if (Array.isArray(dropdown.value)) {
            const newGroups: number[] = dropdown.value as number[];
            setGroups(newGroups)
        }
    };

    const handleSubmit = () => {
        onSubmit(groups);
    }

    return allGroups?.length ? (
        <Modal showModal={showModal} header={<span dangerouslySetInnerHTML={{__html: message}}></span>}
               onClose={closeModal} onSubmit={handleSubmit} isLoading={isLoading} submitActionTitle={confirmText}
               closeActionTitle={translate('modals.assignGroupModal.close')} submitActionColor={color.red} disabled={isLoading}>

            <Dropdown
                style={{marginBottom: '20px'}}
                className={classes.Multiselect}
                fluid
                multiple
                name="groups"
                id="groups"
                onChange={handleSelectChange}
                value={groups}
                options={allGroups}
                placeholder={translate('modals.assignGroupModal.groups')}
                search
                selection
            />

        </Modal>
    ) : <></>
}
