import { makeStyles, Theme } from '@material-ui/core/styles';
import { color } from '../../utils/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		background: '#fff',
		padding: '40px',
		borderRadius: '8px',
		minHeight: 500,
		position: 'relative',
		transition: '.2s',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: '#fff',
		background: color.link,
		height: '50px',
		borderRadius: 12,
		fontSize: 16,
		fontWeight: 400,
	},
	root: {
		minHeight: 'calc(100vh - env(safe-area-inset-top));',
		width: '100%',
		display: 'flex',
		padding: '20px 0',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		background: color.darkBackground,
	},
	CheckboxLabel: {
		marginRight: 0,
		marginBottom: 15,

		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: color.link,
		},

		'& label': {
			width: '100%',
			display: 'inline-block',
			hyphens: 'auto',
		},
	},
	footerMenu: {
		paddingTop: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		'& a': {
			color: '#fff',
			textDecoration: 'none',
			margin: '0 10px',

			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
	link: {
		color: color.link,
	},
	paragraph: {
		color: color.textGrey,
		textAlign: 'center',
		fontSize: '16px',
	},
	title: {
		color: color.textDark,
		marginBottom: 16,
		fontWeight: 400,
		lineHeight: 'auto',
		fontSize: '40px',
		marginTop: 0,
		textAlign: 'center',
	},
	subTitle: {
		color: color.textDark,
		marginBottom: 16,
		fontSize: 16,
		lineHeight: '24px',
		textAlign: 'center',
	},
	SubmitSpinner: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		left: 0,
		background: 'rgba(255,255,255,.8)',
		zIndex: 12,
		display: 'flex',
		borderRadius: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
	Logo: {
		marginBottom: 12,
		maxHeight: 50,
		maxWidth: 200,
		width: 'auto',
		height: 'auto',
	},
	TextField: {
		width: '100%',
		marginBottom: 15,

		'& input': {
			height: 50,
			lineHeight: '50px',
			padding: '0 16px !important',
			fontSize: '16px !important',
			borderColor: 'transparent !important',
			borderRadius: '8px !important',

			'&.MuiOutlinedInput-inputAdornedEnd': {
				paddingRight: '50px !important',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#e0e0e0 !important',
			borderRadius: '8px !important',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
			{
				borderColor: `${color.link} !important`,
			},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: `${color.error} !important`,
		},

		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0,
		},

		'& .MuiInputAdornment-positionEnd': {
			position: 'absolute',
			right: 10,
			'& svg': {
				cursor: 'pointer',
			},
		},
	},
	SubmitButton: {
		backgroundColor: color.link,
		cursor: 'pointer',
		width: '100%',
		borderRadius: 8,
		color: '#ffffff',
		textAlign: 'center',
		height: 50,
		lineHeight: '50px',
		fontSize: 16,
		border: 'none',
		'&:disabled': {
			opacity: 0.6,
			cursor: 'not-allowed',
		},
	},
	SubLink: {
		color: color.textGrey,
		fontSize: 14,
		marginTop: 12,
		marginBottom: 0,
		alignSelf: 'flex-start',
	},
	PageLink: {
		borderTop: '1px solid',
		borderTopColor: color.textDark,
		textAlign: 'center',
		width: '100%',
		marginTop: 40,
		paddingTop: 25,
		fontSize: 16,
		color: color.textGrey,
	},
	ErrorMessage: {
		color: color.error,
		fontSize: 14,
		textAlign: 'center',
		marginBottom: 20,
	},
	CheckIcon: {
		width: 100,
		height: 100,
		margin: '30px 0',
		color: color.link,
	},
	AuthLogo: {
		marginBottom: 10,
		width: 65,
		height: 'auto',
	},
}));
