import * as yup from 'yup';
import YupPassword from 'yup-password';
import { translate } from '../../../i18n/i18n';

YupPassword(yup);
export const validationSchema = yup.object().shape({
	exam_time: yup
		.number()
		.nullable(true)
		.typeError('Exam time must specify a number'),
	exam_percentage_for_pass: yup
		.number()
		.nullable(true)
		.typeError('Exam percentage must specify a number'),
	exam_max_questions: yup
		.number()
		.nullable(true)
		.typeError('Exam max questions must specify a number'),
	exam_remove_points_on_wrong: yup
		.number()
		.nullable(true)
		.typeError('Exam remove points on wrong must specify a number'),
	exercise_max_questions: yup
		.number()
		.nullable(true)
		.typeError('Exam max questions must specify a number'),
});
