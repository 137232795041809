import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";


const initialState = {
    data: {},
    isMemberLoading: false,
    error: ""
};

export const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {
        fetchMembers: (state, action: any) => {
            if (action.state === 'send') {
                state.isMemberLoading = true;
            }
            if (action.state === 'start') {
                state.isMemberLoading = true;
            }
            if (action.state === 'finished') {
                state.isMemberLoading = false;
            }

            if (action.state === 'finished' && !!action.response.success) {
                state.data = action.response.success;
                state.isMemberLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isMemberLoading = false;
                state.error = 'Request error';
            }
        },
    }
})

export const { fetchMembers } = memberSlice.actions;
export const memberSelector = (state: RootState) => state.member;
export default memberSlice.reducer
