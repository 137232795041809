import React, { useState, useEffect } from 'react';
import { color } from '../../../../../utils/styles';

interface FileThumbnailProps {
	type: 'favicon' | 'logo' | 'course_img';
	file: any;
	onClick?: React.MouseEventHandler;
}

const FileThumbnail: React.FunctionComponent<FileThumbnailProps> = ({
	type,
	file,
	onClick,
}) => {
	const thumbnailStyle = {
		width: type === 'favicon' ? 36 : type === 'logo' ? 200 : 500,
		height: type === 'favicon' ? 36 : type === 'logo' ? 70 : 195,
		border: `1px solid ${color.borderGrey}`,
		background: '#fff',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		backgroundImage: `url(${file})`,
		padding: 10,
		cursor: 'pointer',
		borderRadius: 4,
	};

	return <div style={thumbnailStyle} onClick={onClick} />;
};

export default FileThumbnail;
