import React from 'react';

import AppActionModal from '../ActionModal';
import { useStyles } from './styles';
import Button from '../../../components/shared/Button';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';

interface AppSuccessModalProps {
	showModal: boolean;
	closeModal: () => void;
	successMessage: string;
}

const AppSuccessModal: React.FunctionComponent<AppSuccessModalProps> = ({
	showModal,
	closeModal,
	successMessage,
}) => {
	const classes = useStyles();

	const letsRock: JSX.Element = (
		<div className={classes.Container}>
			<div className={classes.successContainer}>
				<svg
					className={classes.Success}
					xmlns="http://www.w3.org/2000/svg"
					width="59.109"
					height="59.109"
					viewBox="0 0 59.109 59.109"
				>
					<path
						id="checkbox-marked-circle-outline"
						d="M55.2,31.555A23.662,23.662,0,1,1,38.057,8.827l4.64-4.64A29.029,29.029,0,0,0,31.555,2,29.555,29.555,0,1,0,61.109,31.555M19.467,25.88l-4.167,4.2,13.3,13.3L58.154,13.822l-4.167-4.2L28.6,35.013Z"
						transform="translate(-2 -2)"
						fill="#7a9b53"
					/>
				</svg>
				<span className={classes.successMessage}>{successMessage}</span>
			</div>
			<div
				className="modal-button-wrapper"
				style={{
					width: '100%',
					position: 'fixed',
					bottom: 15,
					maxWidth: 500,
				}}
			>
				<Button
					title={translate('app.profile.successModal.submit')}
					type="solid-fluid"
					color={color.link}
					onClick={closeModal}
				/>
			</div>
		</div>
	);

	return (
		<div>
			<AppActionModal
				showModal={showModal}
				closeModal={closeModal}
				title=""
				slot={letsRock}
				description=""
			/>
		</div>
	);
};

export default AppSuccessModal;
