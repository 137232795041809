import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Input: {
		width: '100%',
		position: 'relative',
		paddingBottom: '20px',

		'& .MuiFormHelperText-root': {
			position: 'absolute',
			bottom: '2px',
		},
	},
	InputWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	InputDemi: {
		width: 'calc(50% - 12.5px)',
		marginBottom: '5px',
	},
}));
