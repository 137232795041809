import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppActionModal from '../../../ActionModal';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { useStyles } from './styles';
import axios from '../../../../../app/axios';
import { Message } from 'semantic-ui-react';

import Button from '../../../../../components/shared/Button';
import { color } from '../../../../../utils/styles';

import { translate } from '../../../../../i18n/i18n';

interface AppProfileChangeNameModalProps {
	showModal: boolean;
	closeModal: () => void;
	handleSuccess: (text: string) => void;
	firstName: string;
	lastName: string;
}

interface ChangeNameInterface {
	first_name: string;
	last_name: string;
}

const AppProfileChangeNameModal: React.FunctionComponent<
	AppProfileChangeNameModalProps
> = ({ showModal, closeModal, handleSuccess, firstName, lastName }) => {
	const history = useHistory();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const initialValues: ChangeNameInterface = {
		first_name: firstName,
		last_name: lastName,
	};

	const onSubmit = async (values: ChangeNameInterface) => {
		setErrorMessage(false);
		setIsLoading(true);
		await axios
			.put(`/app/user`, values)
			.then(function (response) {
				setErrorMessage(false);
				setIsLoading(false);
				closeModal();
				formik.resetForm();
				window.localStorage.setItem('firstname', values.first_name);
				window.localStorage.setItem('lastname', values.last_name);
				handleSuccess('Name erfolgreich geändert.');
			})
			.catch(function (error) {
				setErrorMessage(true);
				setIsLoading(false);
				console.log(error);
			});
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const form: JSX.Element = (
		<div style={{ marginTop: 50 }}>
			<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
				<div style={{ width: '100%', marginBottom: 40 }}>
					<TextField
						value={formik.values.first_name}
						onChange={formik.handleChange}
						className="app-input"
						style={{ width: '100%', marginBottom: 2 }}
						label={translate('app.profile.changeName.firstName')}
						name="first_name"
					/>
					<span className={classes.InputError}>
						{(formik.values.first_name &&
							formik.errors.first_name) ||
							''}
					</span>
				</div>

				<div style={{ width: '100%', marginBottom: 40 }}>
					<TextField
						value={formik.values.last_name}
						onChange={formik.handleChange}
						className="app-input"
						style={{ width: '100%', marginBottom: 2 }}
						label={translate('app.profile.changeName.lastName')}
						name="last_name"
					/>
					<span className={classes.InputError}>
						{(formik.values.last_name && formik.errors.last_name) ||
							''}
					</span>
				</div>

				{errorMessage && (
					<Message
						error
						content={translate(
							'app.profile.changeName.networkingIssues',
						)}
					/>
				)}
				<div
					className="modal-button-wrapper"
					style={{
						width: '100%',
						position: 'fixed',
						bottom: 15,
						paddingRight: 40,
						maxWidth: 500,
					}}
				>
					<Button
						title={translate('app.profile.changeName.submit')}
						type="solid-fluid"
						disabled={
							!formik.values.first_name ||
							!formik.values.last_name
						}
						color={color.link}
						onClick={formik.handleSubmit}
						isLoading={isLoading}
					/>
				</div>
			</form>
			<div></div>
		</div>
	);

	return (
		<div>
			<AppActionModal
				showModal={showModal}
				closeModal={closeModal}
				title={translate('app.profile.changeName.title')}
				slot={form}
				description={translate('app.profile.changeName.description')}
			/>
		</div>
	);
};

export default AppProfileChangeNameModal;
