import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color, textSizes } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	frame: {
		border: `1px solid ${color.borderGrey}`,
		borderRadius: borderRadius.medium,
		marginLeft: `32px !important`,
		marginRight: `32px !important`,
		marginBottom: `32px !important`,
		'&.ui.grid .column:not(.row)': {
			padding: `0px !important`,
			paddingBottom: `0px !important`,
		},
	},
	frameCourse: {
		border: 'none',
		borderRadius: 5,
		margin: 0,
		'&.ui.grid': {
			margin: '0 0 0 0',
		},
		'&.ui.grid .column:not(.row)': {
			padding: `0px !important`,
			paddingBottom: `0px !important`,
		},
	},
	TwelveCol: {
		background: '#F6F7F9',
		borderRadius: 5,
	},
	kpiFrame: {
		'&.column:not(.row)': {
			padding: `0px !important`,
			paddingBottom: `0px !important`,
		},
		padding: `0px !important`,
		paddingBottom: `0px !important`,

		"& .row":{
			height: "25%",
			width: "100%",
			display: "block"
		}
	},
	rowWrap: {
		marginBottom: 40,
	},
	ChartHeader: {
		margin: 32,
		display: 'flex',
		justifyContent: 'space-between',
		'& .ui.multiple.search.dropdown': {
			marginRight: 0,
		},
		'& .ui.label': {
			flexShrink: 0,
		},
	},
	ChartHeaderCourse: {
		margin: 0,
		display: 'flex',
		justifyContent: 'space-between',
		'& .ui.multiple.search.dropdown': {
			marginRight: 0,
		},
		'& .ui.label': {
			flexShrink: 0,
		},
	},
}));
