import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Chart from 'react-apexcharts';
import { format, differenceInDays, subDays } from 'date-fns';
import { color } from '../../../../utils/styles';
import { GraphHeader } from '../GraphHeader';
import {translate} from "../../../../i18n/i18n";

interface DonutChartProps {
	value: number;
	end: Date;
	start: Date;
}

export const DonutChart: React.FunctionComponent<DonutChartProps> = ({
	value,
	end,
	start,
}) => {
	const classes = useStyles();
	const difference = differenceInDays(end, new Date());
	const overall = differenceInDays(end, start);

	return (
		<div className={classes.wrapper}>
			<GraphHeader
				headline={translate('shared.donutChart.courseEnd')}
				subtitle={`${translate('shared.donutChart.deadline')} ${format(end, 'dd.MM.yyyy')}`}
			/>
			<div className={classes.donutWrapper}>
				<Chart
					options={{
						chart: {
							type: 'donut',
							height: 300,
							width: 300,
							zoom: {
								enabled: false,
							},
						},
						plotOptions: {
							pie: {
								startAngle: -90,
								endAngle: 270,
                                expandOnClick: false,
								donut: {
									labels: {
										show: false,
									},
								},
							},
						},
						states: {
							normal: {
								filter: {
									type: 'none',
									value: 0,
								},
							},
							hover: {
								filter: {
									type: 'none',
								},
							},
							active: {
								allowMultipleDataPointsSelection: false,
                                filter: {
                                    type: 'none',
                                    value: 0,
                                },
							},
						},
						fill: {
							colors: [color.link, color.lightBlue],
						},
						labels: [translate('shared.donutChart.daysTillCourseEnd')],
						dataLabels: {
							enabled: false,
							// name: {
							//     offsetY: -10,
							//     show: true,
							//     color: '#888',
							//     fontSize: '17px'
							// },
						},
						stroke: {
							width: 0,
						},
						legend: {
							show: false,
						},
						tooltip: {
							enabled: false,
						},
					}}
					series={[difference, overall - difference]}
					type="donut"
					height={300}
				/>
				<div className={classes.days}>
					<div className={classes.valueCenter}>
						{difference > 0 ? difference : 0}
					</div>
					<label className={classes.label}>{translate('shared.donutChart.days')}</label>
				</div>
			</div>
		</div>
	);
};
