import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color, textSizes } from '../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Values: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 6,
	},
	Progress: {
		background: '#339BF2',
		height: '100%',
		borderRadius: '4px 0 0 4px',
	},
	Chart: {
		height: 20,
		background: color.lightestGrey,
		borderRadius: 4,
	},
	Container: {},
}));
