import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Avatar from '../../shared/Avatar';
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import TableAction from '../../shared/TableAction';
import TableButton from '../../shared/TableButton';
import EditUserDataModal from '../../modals/EditUserDataModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	bulkEdit,
	clearUser,
	deleteUser,
	fetchUsers,
	userSelector,
} from '../../../store/users/userSlice';
import AddUserModal from '../../modals/AddUserModal';
import DeleteModal from '../../modals/DeleteModal';
import { color, margins } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';
import Button from '../../shared/Button';
import { BulkImportModal } from '../../modals/BulkImportModal';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { useStyles } from './styles';
import {
	deleteTypes,
	userBulkFunctions,
	userFunctions,
} from '../../../enums/users/users-enums';
import { AssignGroupModal } from '../../modals/AssignGroupModal';
import { groupSelector } from '../../../store/groups/groupSlice';
import { courseSelector } from '../../../store/courses/courseSlice';
import {
	initialSorting,
	SortingInterface,
} from '../../../interfaces/sorting-interface';
import { getGroupsForDropdown } from '../../../helpers/Groups';
import {
	getAssignmentTypesForDropdown,
	getCoursesForDropdown,
	getMissedDeadlineTypesForDropdown,
} from '../../../helpers/Courses';
import { BulkAction } from '../../shared/BulkAction';
import { useBulkFunction } from '../../../helpers/useBulkFunction';
import { usePagination } from '../../../helpers/usePagination';
import { useTableSearch } from '../../../helpers/useTableSearch/useTableSearch';
import { AssignUserModal } from '../../modals/AssignUserModal';
import {
	addAssignment,
	assignmentSelector,
} from '../../../store/assignments/assignmentSlice';
import { format, parse } from 'date-fns';
import {
	AssignmentTypes,
	MissedDeadlineTypes,
} from '../../../enums/assignments/assignments-enums';

import TableSearch from '../../shared/TableSearch';
import TableFilter from '../../shared/TableFilter';

interface UserOverviewTabProps {
	userData: any; // fix when time
}

const UserOverviewTab: React.FunctionComponent<UserOverviewTabProps> = ({
	userData,
}) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const {
		handleSelectAll,
		handleSelect,
		isSelected,
		selected,
		selectedAll,
		setSelected,
	} = useBulkFunction(userData);
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [openEditUser, setOpenEditUser] = React.useState(false);
	const [showBulkImportModal, setShowBulkImportModal] = React.useState(false);
	const [selectedUserId, setSelectedUserId] = React.useState(0);

	const [selectedUser, setSelectedUser] = useState<{
		id: number;
		name: string;
	} | null>(null);
	const [shownModal, setShownModal] = useState<string | null>(null);
	const { isLoading, error, trigger } = useSelector(userSelector);
	const { isLoading: assignmentIsLoading } = useSelector(assignmentSelector);

	const {
		handleChangePage,
		handleChangeRowsPerPage,
		page,
		rowsPerPage,
		handleResetPage,
	} = usePagination();

	const [deleteType, setDeleteType] = useState<deleteTypes>(
		deleteTypes.DELETE,
	);
	const {
		handleSearchChange,
		search,
		setSearch,
		showSearch,
		setShowSearch,
		CurrentlySearching,
	} = useTableSearch();
	const searchProps = {
		handleSearchChange,
		search,
		setSearch,
		showSearch,
		setShowSearch,
		CurrentlySearching,
	};

	const classes = useStyles();

	const [sorting, setSorting] = useState<SortingInterface>(initialSorting);
	let timeoutForSearch: any = useRef(null);

	const { data: groupData }: any = useSelector(groupSelector);
	const { data: courseData }: any = useSelector(courseSelector);

	const allGroups = getGroupsForDropdown(groupData);
	const allCourses = getCoursesForDropdown(courseData);
	const allAssignmentTypes = getAssignmentTypesForDropdown();
	const allMissedDeadlineTypes = getMissedDeadlineTypesForDropdown();

	const dispatch = useDispatch();

	const handleEditUser = (id: number) => {
		dispatch(clearUser());
		setSelectedUserId(id);
		setOpenEditUser(true);
	};

	const handleAssignUser = (id: number, assignments: any) => {
		setSelectedUserId(id);
		setShownModal(userFunctions.SINGLE_ASSIGN_COURSE);
	};

	const resetModals = () => {
		setShownModal(null);
		setSelectedUser(null);
		dispatch({
			...clearUser(),
		});
	};

	const setUserToDelete = (id: number, name: string) => {
		setShownModal('delete');
		setSelectedUser({ id, name });
	};

	const handleDeleteUser = () => {
		dispatch({
			...deleteUser({
				requestType: 'DELETE',
				request: `smartometer/users/${selectedUser!.id}${
					deleteType ? `?deleteType=${deleteType}` : ''
				}`,
			}),
		});
		setShownModal(null);
		setSelectedUser(null);
	};

	const handleBulkDeleteUser = () => {
		if (selected.length) {
			dispatch({
				...deleteUser({
					requestType: 'DELETE',
					body: {
						users: selected,
					},
					request: `smartometer/users`,
				}),
			});
			setSelected([]);
			resetModals();
		}
	};

	const [filterValues, setFilterValues] = useState<any>({
		assignments: 'ALL',
		missedDeadline: 'ALL',
		groups: [] as number[],
		courses: [] as number[],
	});

	useEffect(() => {
		handleResetPage();
	}, [rowsPerPage, sorting, search, filterValues]);

	useEffect(() => {
		if (!error && page && rowsPerPage && !isLoading) {
			if (timeoutForSearch.current) {
				clearTimeout(timeoutForSearch.current);
			}
			// console.log(filterValues.courses.length);
			timeoutForSearch.current = setTimeout(() => {
				console.log('fetchusers');
				dispatch({
					...fetchUsers({
						requestType: 'GET',
						request: `smartometer/users?page=${page}&limit=${rowsPerPage}${
							filterValues.groups.length > 0
								? `&` +
								  filterValues.groups
										.map(
											(group: any) => `groups[]=${group}`,
										)
										?.join('&')
								: ''
						}${
							filterValues.courses.length > 0
								? `&` +
								  filterValues.courses
										.map(
											(course: any) =>
												`courses[]=${course}`,
										)
										?.join('&')
								: ''
						}&sort=${sorting.column}&type=${
							sorting.desc ? 'desc' : 'asc'
						}${search !== '' ? `&search=${search}` : ''}${
							filterValues.missedDeadline !==
							MissedDeadlineTypes.ALL
								? `&missedDeadline=${filterValues.missedDeadline}`
								: ''
						}${
							filterValues.assignments !== AssignmentTypes.ALL
								? `&hasAssignments=${filterValues.assignments}`
								: ''
						}`,
					}),
				});
			}, 200);
		}
	}, [
		error,
		page,
		rowsPerPage,
		trigger,
		sorting,
		search,
		filterValues,
		assignmentIsLoading,
	]);

	const userRowData = {
		select: {
			renderTitle: (): JSX.Element => (
				<label className={classes.label}>
					<Checkbox
						checked={selectedAll}
						color="primary"
						onChange={handleSelectAll}
					/>
					<span className={classes.selectAll}>
						{translate('tabs.userOverviewTab.selectAll')}
					</span>
				</label>
			),
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Checkbox
					checked={isSelected(item.id)}
					color="primary"
					onClick={(event) => handleSelect(event, item.id)}
				/>
			),
		},
		avatar: {
			title: '',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<Avatar
					src={item.avatar || '/defaultUser.png'}
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push(`${pathname}/detail/${item.id}`)
					}
				/>
			),
		},
		name: {
			title: translate('tabs.userOverviewTab.name'),
			sorting: 'first_name',
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<p
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push(`${pathname}/detail/${item.id}`)
					}
				>
					{item.first_name} {item.last_name}
				</p>
			),
		},
		group: {
			title: translate('tabs.userOverviewTab.group'),
			sorting: '',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				return item.groups
					? item.groups.map(function (group: any, index: number) {
							return (
								<p
									key={index}
									style={{ cursor: 'pointer' }}
									onClick={() =>
										history.push(
											`${pathname}/detail/${item.id}`,
										)
									}
								>
									{group.name}
								</p>
							);
					  })
					: '-';
			},
		},
		course: {
			title: translate('tabs.userOverviewTab.course'),
			sorting: '',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				return item.assignments.length
					? item.assignments
							.filter(
								(assignment: any, index: number) =>
									assignment.pivot.is_finished === 0,
							)
							.map(function (assignment: any, index: number) {
								return (
									assignment.pivot.is_finished === 0 &&
									(index < 3 ? (
										<p key={index}>{assignment.name}</p>
									) : (
										index === 3 && (
											<span key={index}>...</span>
										)
									))
								);
							})
					: '- ';
			},
		},
		deadlineMissed: {
			title: '',
			align: 'left',
			renderType: (item: any): JSX.Element => {
				return !!item.assignments.filter(
					(assignment: any, index: number) => {
						const deadline = parse(
							assignment.pivot.end,
							'yyyy-MM-dd',
							new Date(),
						);
						return (
							assignment.pivot.is_finished === 0 &&
							deadline.getTime() < new Date().getTime()
						);
					},
				).length ? (
					<span
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push(`${pathname}/detail/${item.id}`)
						}
						className={classes.missedDeadlineDot}
					></span>
				) : (
					<></>
				);
			},
		},
		statistics: {
			title: translate('tabs.userOverviewTab.statistics'),
			align: 'left',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() =>
						history.push(`${pathname}/detail/${item.id}/statistik`)
					}
					type="chart"
				/>
			),
		},
		assignment: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableButton
					title={translate('tabs.userOverviewTab.assign')}
					handleClick={() =>
						handleAssignUser(item.id, item.assignments)
					}
				/>
			),
		},
		edit: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() => handleEditUser(item.id)}
					tooltip={translate('tooltip.edit')}
					type="edit"
				/>
			),
		},
		delete: {
			title: '',
			align: 'right',
			renderType: (item: any): JSX.Element => (
				<TableAction
					handleClick={() =>
						setUserToDelete(item.id, item.first_name)
					}
					tooltip={translate('tooltip.delete')}
					type="delete"
				/>
			),
		},
	};

	const handleBulkEdit = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		switch (event.target.value) {
			case userBulkFunctions.ASSIGN_COURSE:
				setShownModal(userBulkFunctions.ASSIGN_COURSE);
				break;
			case userBulkFunctions.ASSIGN_GROUP:
				setShownModal(userBulkFunctions.ASSIGN_GROUP);
				break;
			case userBulkFunctions.DELETE:
				setShownModal(userBulkFunctions.DELETE);
				break;
			default:
				break;
		}
	};
	const handleBulkAddGroup = (groups: number[]) => {
		if (selected.length) {
			dispatch({
				...bulkEdit({
					requestType: 'POST',
					body: {
						users: selected,
						groups: groups,
					},
					request: `smartometer/users/addGroups`,
				}),
			});
			setSelected([]);
			resetModals();
		}
	};

	const handleSubmitAssignUser = (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		resetModals();
	};

	const handleSubmitBulkAssignUser = (
		users: number[],
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					users,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/`,
			}),
		});
		resetModals();
	};

	const handleChangeSorting = (column: string) => {
		if (column === sorting.column && sorting.desc)
			setSorting(initialSorting);
		else if (column === sorting.column && !sorting.desc)
			setSorting({ ...sorting, desc: true });
		else if (column !== sorting.column)
			setSorting({ ...sorting, column: column });
	};

	const handleSetFilterValues = (values: any, name: string) => {
		// console.log({ values, name });
		let localFilterValues = { ...filterValues, [name]: values };
		// console.log(localFilterValues);
		setFilterValues(localFilterValues);
	};

	const filterOptions = [
		{
			type: 'hasOne',
			name: 'assignments',
			placeholder: translate('tabs.userOverviewTab.assignments'),
			value: filterValues.assignments,
			previewValue: translate(
				`users.users.assignments.${filterValues.assignments}`,
			),
			options: allAssignmentTypes,
		},
		{
			type: 'hasOne',
			name: 'missedDeadline',
			placeholder: 'MissedDeadline',
			value: filterValues.missedDeadline,
			previewValue: translate(
				`users.users.deadline.${filterValues.missedDeadline}`,
			),
			options: allMissedDeadlineTypes,
		},
		{
			type: 'hasMany',
			name: 'groups',
			placeholder: translate('tabs.userOverviewTab.groups'),
			value: filterValues.groups,
			previewValue: filterValues.groups,
			filterTitle: translate('shared.dashboardFilter.filterByGroup'),
			options: allGroups,
		},
		{
			type: 'hasMany',
			name: 'courses',
			placeholder: translate('tabs.userOverviewTab.courses'),
			value: filterValues.courses,
			previewValue: filterValues.courses,
			filterTitle: translate('shared.dashboardFilter.filterByCourse'),
			options: allCourses,
		},
	];

	const headerComponent = (
		<div className={classes.HeaderComponent}>
			<div>
				{!selected.length && <TableSearch searchProps={searchProps} />}
			</div>
			<div className={classes.FilterComponent}>
				{!selected.length ? (
					<TableFilter
						options={filterOptions}
						setFilterValues={handleSetFilterValues}
					/>
				) : (
					<BulkAction
						handleBulkEdit={handleBulkEdit}
						bulkFunctions={userBulkFunctions}
					/>
				)}
			</div>
		</div>
	);

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDeleteType((event.target as HTMLInputElement).value as deleteTypes);
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column width={8}></Grid.Column>
					<Grid.Column width={8}>
						<Button
							type="solid"
							styles={{ float: 'right' }}
							onClick={() => setShowAddUserModal(true)}
							color={color.link}
							title={translate(`users.users.createNewButton`)}
						/>
						<Button
							type="outline"
							styles={{
								float: 'right',
								marginRight: margins.buttons,
							}}
							onClick={() => setShowBulkImportModal(true)}
							color={color.link}
							title={translate(`users.users.importButton`)}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<ContentCard
							title={translate('tabs.userOverviewTab.users')}
							headerComponent={headerComponent}
						>
							<Table
								showHeader
								rows={userRowData}
								total={userData.total}
								data={userData.data}
								handleChangePage={handleChangePage}
								page={page}
								loading={isLoading}
								rowsPerPage={rowsPerPage}
								handleChangeRowsPerPage={
									handleChangeRowsPerPage
								}
								handleChangeSorting={handleChangeSorting}
								sorting={sorting}
							/>
						</ContentCard>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<EditUserDataModal
				onSave={() => null}
				ownProfile={false}
				openModal={openEditUser}
				closeModal={() => setOpenEditUser(false)}
				userId={selectedUserId}
			/>
			<AddUserModal
				showModal={showAddUserModal}
				closeModal={() => setShowAddUserModal(false)}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={!!selectedUser && shownModal === 'delete'}
				closeModal={resetModals}
				onDelete={handleDeleteUser}
				confirmText={translate('tabs.userOverviewTab.deleteUser')}
				message={`${translate(
					'tabs.userOverviewTab.deleteUserConfirm',
				)} <b>'${selectedUser?.name}'</b> ${translate(
					'tabs.userOverviewTab.deleteUserConfirmEnd',
				)}`}
			>
				{selectedUser &&
					selectedUser.id &&
					userData?.data.find(
						(user: any) => user.id === selectedUser.id,
					)?.roles_count > 0 && (
						<FormControl
							component="fieldset"
							className={classes.formControl}
						>
							<FormLabel component="legend">
								{translate('tabs.userOverviewTab.userNoAccess')}
							</FormLabel>
							<RadioGroup
								aria-label="quiz"
								name="quiz"
								value={deleteType}
								onChange={handleRadioChange}
							>
								<FormControlLabel
									value={deleteTypes.REMOVE}
									control={<Radio />}
									label={translate(
										'tabs.userOverviewTab.removeCourseAccess',
									)}
								/>
								<FormControlLabel
									value={deleteTypes.DELETE}
									control={<Radio />}
									label={translate(
										'tabs.userOverviewTab.deleteUser',
									)}
								/>
							</RadioGroup>
						</FormControl>
					)}
			</DeleteModal>
			<AssignGroupModal
				isLoading={isLoading}
				showModal={shownModal === userBulkFunctions.ASSIGN_GROUP}
				closeModal={resetModals}
				onSubmit={handleBulkAddGroup}
				confirmText={translate('tabs.userOverviewTab.addToGroup')}
				message={translate('tabs.userOverviewTab.addUserToGrouo')}
			/>
			<AssignUserModal
				isLoading={isLoading}
				user={
					selectedUserId &&
					userData?.data.find(
						(user: any) => user.id === selectedUserId,
					)
				}
				showModal={shownModal === userFunctions.SINGLE_ASSIGN_COURSE}
				closeModal={resetModals}
				onSubmit={handleSubmitAssignUser}
				confirmText={translate('tabs.userOverviewTab.addCourse')}
				message={translate('tabs.userOverviewTab.addUserToCourse')}
			/>
			<AssignUserModal
				isLoading={isLoading}
				users={selected}
				showModal={shownModal === userBulkFunctions.ASSIGN_COURSE}
				closeModal={resetModals}
				onBulkSubmit={handleSubmitBulkAssignUser}
				confirmText={translate('tabs.userOverviewTab.addCourse')}
				message={translate('tabs.userOverviewTab.addUserToCourse')}
			/>
			<DeleteModal
				isLoading={isLoading}
				showModal={
					!!selected.length && shownModal === userBulkFunctions.DELETE
				}
				closeModal={resetModals}
				onDelete={handleBulkDeleteUser}
				confirmText={translate(
					'tabs.userOverviewTab.deleteModalConfirm',
				)}
				message={translate('tabs.userOverviewTab.deleteModalMessage')}
			/>

			<BulkImportModal
				showModal={showBulkImportModal}
				closeModal={() => setShowBulkImportModal(false)}
			/>
		</>
	);
};

export default UserOverviewTab;
