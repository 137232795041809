import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: "0 32px 20px 32px",
    },
    Number: {
        fontSize: "40px",
        lineHeight: "40px",
        fontWeight: 400,
        marginBottom: 0
    },
    Action: {
        textAlign: "right",

        "& span": {
            color: "#1485EE",
            cursor: "pointer",
            textDecoration: "none",

            "&:hover": {
                textDecoration: "underline"
            }
        }
    }
}))