import React, { useState } from 'react';
import AppActionModal from '../../ActionModal';
import Button from '../../../shared/Button';
import { translate } from '../../../../i18n/i18n';
import {color} from "../../../../utils/styles";

interface AppFilterCourseModalProps {
	courses: any[];
	showModal: boolean;
	closeModal: () => void;
	saveFilteredCourses: Function;
	displayedCourse: number;
}

const AppFilterCourseModal: React.FunctionComponent<AppFilterCourseModalProps> =
	({
		showModal,
		closeModal,
		saveFilteredCourses,
		courses,
		displayedCourse,
	}) => {
		const [selectedCourse, setSelectedCourse] = useState(displayedCourse);
		let currentCourses: any[] = [];
		let pastCourses: any[] = [];

		courses.forEach((course) => {
			if (
				!!course.is_finished &&
				!pastCourses.find(
					(item) => item.id === course.id,
				)
			) {
				pastCourses.push(course);
			} else if (
				!course.is_finished &&
				!currentCourses.find(
					(item) => item.id === course.id,
				)
			) {
				currentCourses.push(course);
			}
		});

		const handleCloseModal = () => {
			setSelectedCourse(displayedCourse);
			closeModal();
		};

		const handleSaveSelection = () => {
			saveFilteredCourses(selectedCourse);
		};

		const form: JSX.Element = (
			<div>
				<div style={{ paddingBottom: 100 }}>
					{!!currentCourses.length && (
						<div className="app-course-section">
							<p className="app-course-section-headline">
								{translate('app.filterModal.currentCourse')}
							</p>
							{currentCourses.map((course) => {
                                return (
									<div
										key={course.id}
										className={`app-course-filter-item ${
											selectedCourse === course.id
												? 'app-course-filter-item-active'
												: ''
										}`}
										onClick={() =>
											setSelectedCourse(course.id)
										}
									>
										{course.product_name}
									</div>
								);
							})}
						</div>
					)}
					{!!pastCourses.length && (
						<div className="app-course-section">
							<p className="app-course-section-headline">
								{translate('app.filterModal.finishedCourse')}
							</p>
							{pastCourses.map((course) => {
                                return (
									<div
										key={course.id}
										className={`app-course-filter-item ${
											selectedCourse === course.id
												? 'app-course-filter-item-active'
												: ''
										}`}
										onClick={() =>
											setSelectedCourse(course.id)
										}
									>
										{course.product_name}
									</div>
								);
							})}
						</div>
					)}
				</div>
				<Button
					onClick={handleSaveSelection}
					styles={{
						borderRadius: 5,
						width: 'calc(100% - 40px)',
						marginTop: 10,
						height: 58,
						position: 'fixed',
						bottom: 40,
						left: 20,
					}}
					title={translate('app.filterModal.save')}
					type="solid"
					color={color.link}
				/>
			</div>
		);

		return (
			<div>
				<AppActionModal
					showModal={showModal}
					closeModal={handleCloseModal}
					title={translate('app.filterModal.showStatistics')}
					slot={form}
					description={translate('app.filterModal.selectCourse')}
				/>
			</div>
		);
	};

export default AppFilterCourseModal;
