import { useState } from "react";
import { PairInterface } from "./AssignPair";

export const useAssignPair: any = (assignPairs: PairInterface[], firstWordElement: React.MutableRefObject<HTMLInputElement | null>, secondWordElement: React.MutableRefObject<HTMLInputElement | null>) => {
    const isEditRequest = !!assignPairs;

    const [pairs, setPairs] = useState<PairInterface[]>(assignPairs || []);

    const [editPairId, setEditPairId] = useState<number>(-1);

    const [editError, setEditError] = useState<boolean>(false)
    const [createError, setCreateError] = useState<boolean>(false)

    const handleEditAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const [answerWordPosition, answerIndex] = event.target.name.split('-');
        const newAssignPairs = pairs.map((pair: any, index: number) => {
            const newPair = { ...pair };
            if (Number(answerIndex) === index) newPair[answerWordPosition] = event.target.value;
            return newPair;
        })
        setPairs(newAssignPairs);
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setEditPairId(-1);
            setEditError(false)
        }
    }

    const handleSelectEditedAnswer = (i: number): void => {
        setEditPairId(i);
    };

    const deleteAnswer = (index: number) => {
        setPairs(pairs.filter((pair: any, pIndex: number) => pIndex !== index));
    }

    const addNewOption = (e: any) => {
        if (e.key === 'Enter') {
            setPairs([
                ...pairs,
                {
                    firstWord: firstWordElement?.current?.value || "",
                    secondWord: secondWordElement?.current?.value || ""
                }
            ]);
            if (firstWordElement?.current?.value) {
                firstWordElement.current.value = '';
                firstWordElement?.current.focus();
            }
            if (secondWordElement?.current?.value) {
                secondWordElement.current.value = '';
            }

        }
    }

    return {
        handleSelectEditedAnswer,
        addNewOption,
        deleteAnswer,
        handleEnter,
        handleEditAnswerChange,
        isEditRequest,
        pairs,
        editPairId,
        editError,
        createError
    }
}
