import React from "react";
import { Button } from "@material-ui/core"
import {useStyles} from "./styles"
import {translate} from "../../../../../i18n/i18n";


const ExerciseCountButton: React.FunctionComponent<{ count: number; handleClick: React.MouseEventHandler; disabled: boolean; }> = ({count, handleClick, disabled}) => {
    const classes = useStyles();
  return <Button
        disableRipple
        className={`${disabled ? classes.ButtonDisabled : ""} ${classes.Button}`}
        variant="outlined"
        onClick={!disabled ? handleClick : () => {}}
        startIcon={<span className={classes.Counter}>{count}</span>}
      >{translate('layout.exerciseCountButton.tasks')}</Button>
}

export default ExerciseCountButton;