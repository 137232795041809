import {color} from "../../../utils/styles";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        width: 46,
        height: 24,
        padding: 0,
        backgroundColor: color.white,
        margin: theme.spacing(1),

    },
    switchBase: {
        padding: 1,
        color: color.textGrey,
        '&$checked': {
            transform: 'translateX(22px)',
            color: color.chartBlue,
            '& + $track': {
                backgroundColor: color.lightBlue,
                opacity: 1,
                border: `1px solid ${color.borderGrey}`,
            },
        },
        '&$disabled': {
            '& + $track': {
                backgroundColor: color.white,
                opacity: 1,
                border: `1px solid ${color.borderGrey}`,
            },
        },
        '&$focusVisible $thumb': {
            color: color.textGrey,
            border: '6px solid #fff',
        },


    },
    thumb: {
        marginTop: 3,
        marginLeft: 4,
        width: 15,
        height: 15,
    },
    track: {
        backgroundColor: color.white,
        borderRadius: 26 / 2,
        border: `1px solid ${color.borderGrey}`,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {
        backgroundColor: color.white,
    },
    checked: {},
    focusVisible: {
        backgroundColor: color.white,
    }
}))
