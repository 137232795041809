import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface initialStateInterface {
    data: any,
    single: any,
    isLoading: boolean,
    error: string,
}

const initialState: initialStateInterface = {
    data: null,
    single: null,
    isLoading: false,
    error: "",
};

export const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {
        createSupport: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                const { success, data } = action.response;
                state.isLoading = false;
            }
        }
    }
})

export const { createSupport } = supportSlice.actions;
export const settingSelector = (state: RootState) => state.support;
export default supportSlice.reducer
