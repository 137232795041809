import React from 'react';
import { useStyles } from './styles';
import Modal from '../../shared/Modal';
import confirmIcon from '../../../assets/images/confirm.svg';
import { translate } from '../../../i18n/i18n';
import { useHistory } from 'react-router-dom';
import { color } from '../../../utils/styles';
import { clearState } from '../../../store/app/appSlice';
import { useDispatch } from 'react-redux';
import { clearSettingsState } from '../../../store/settings/settingSlice';

interface ThankYouProps {
	showModal: boolean;
	denyClose: boolean;
	onClose: Function;
}

const ThankYouModal: React.FunctionComponent<ThankYouProps> = ({
	showModal,
	denyClose,
	onClose,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const logout = () => {
		dispatch({
			...clearState(),
		});
		dispatch({
			...clearSettingsState(),
		});
		window.localStorage.clear();
		history.push('/auth/login');
	};

	return (
		<>
			<Modal
				showModal={showModal}
				header={''}
				disabled={false}
				buttonWrapper={classes.MainButtonWrapper}
				noAction={denyClose ? false : true}
				onSubmit={denyClose ? logout : undefined}
				onClose={denyClose ? undefined : onClose}
				submitActionColor={color.link}
				submitActionTitle={translate(`app.profile.actions.logout`)}
			>
				<div className={classes.image}>
					<img src={confirmIcon} />

					<h2 className={classes.header}>
						{translate('modals.thankYouModal.header')}
					</h2>
					<p className={classes.paragraph}>
						{translate('modals.thankYouModal.subheader')}
					</p>
				</div>
			</Modal>
		</>
	);
};

export default ThankYouModal;
