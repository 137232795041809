import {
	AssignmentTypes,
	HasFinishedTypes,
	MissedDeadlineTypes,
} from '../../enums/assignments/assignments-enums';
import { translate } from '../../i18n/i18n';

export const getCoursesForDropdown = (courseData: any) => {
	return courseData?.data?.map((course: any) => {
		return {
			key: course.id,
			text: course.name,
			value: course.id,
		};
	});
};
export const getAssignmentTypesForDropdown = () => {
	return [
		{
			key: 0,
			text: translate(`users.users.assignments.ALL`),
			value: AssignmentTypes.ALL,
		},
		{
			key: 1,
			text: translate(`users.users.assignments.WITH_ASSIGNMENT`),
			value: AssignmentTypes.WITH_ASSIGNMENT,
		},
		{
			key: 2,
			text: translate(`users.users.assignments.WITHOUT_ASSIGNMENT`),
			value: AssignmentTypes.WITHOUT_ASSIGNMENT,
		},
	];
};
export const getMissedDeadlineTypesForDropdown = () => {
	return [
		{
			key: 0,
			text: translate(
				`users.groups.detail.deadline.${MissedDeadlineTypes.ALL}`,
			),
			value: MissedDeadlineTypes.ALL,
		},
		{
			key: 1,
			text: translate(
				`users.groups.detail.deadline.${MissedDeadlineTypes.MISSED}`,
			),
			value: MissedDeadlineTypes.MISSED,
		},
		{
			key: 2,
			text: translate(
				`users.groups.detail.deadline.${MissedDeadlineTypes.NOT_MISSED}`,
			),
			value: MissedDeadlineTypes.NOT_MISSED,
		},
	];
};

export const getHasFinishedTypesForDropdown = () => {
	return [
		{
			key: 0,
			text: translate(`users.users.finished.${HasFinishedTypes.ALL}`),
			value: HasFinishedTypes.ALL,
		},
		{
			key: 1,
			text: translate(
				`users.users.finished.${HasFinishedTypes.FINISHED}`,
			),
			value: HasFinishedTypes.FINISHED,
		},
		{
			key: 2,
			text: translate(
				`users.users.finished.${HasFinishedTypes.UNFINISHED}`,
			),
			value: HasFinishedTypes.UNFINISHED,
		},
	];
};
