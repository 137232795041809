import { ERROR, SEND } from './constants';
import hash from 'object-hash';
import {
	addData,
	addToQueue,
	addTracking,
	getData,
	getFromQueueByKey,
	getTracking,
} from './offlineMode';
import axios from 'axios';

const offlineMiddleware = (store: any) => (next: any) => async (action: any) => {
    const _action = {...action};
    if (!window.navigator.onLine) {
        if (_action && _action.payload && ['QUEUE'].includes(action.payload.requestType)) {
            const {data} = action.payload;
            _action.response = await getData(action.payload.request, data);
        }

        if (_action && _action.payload && ['GET_QUEUE'].includes(action.payload.requestType)) {
            const {data} = action.payload;
            _action.response = await getData(action.payload.request, data);
        }

        next(_action);

        return new Promise(resolve => {
            if (_action.type === undefined) {
                _action.type = '';
            }

            next(_action);
            resolve(true);
        });
    } else {
        next(_action)
    }
};

export default offlineMiddleware;
