import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../utils/styles';

export const useStyles = makeStyles(theme => ({
	ListWrapper: {
		marginTop: 25,
	},
	label: {
        position: "relative"
    },
	selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
    deleteWrapper: {
        "&:hover": {
            cursor: 'pointer !important',
        }
    },
    errorLabel: {
        marginLeft: 40,
        fontSize: 13,
        color: '#f44336'
    },
    TitleInput: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: '#9C9C9C'
        }
    },
}));
