import { makeStyles } from '@material-ui/core/styles';
import { textSizes, color } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Input: {
		// height: 24,
		width: '100%',
		position: 'relative',
		paddingBottom: '20px',

		'& .MuiFormHelperText-root': {
			position: 'absolute',
			bottom: '0',
		},
		// "& .MuiInputBase-input": {
		//     height: 20,
		// }
	},
	InputWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	InputDemi: {
		width: 'calc(50% - 12.5px)',
		marginBottom: '5px',
	},
	Note: {
		fontSize: textSizes.notice,
		color: color.textGrey,
	},
	Multiselect: {
		'& .label': {
			background: color.lightBlue,
		},
	},
	Select: {
		height: 44,
		fontSize: textSizes.bodyNormal,
		width: 'calc(50% - 12.5px) !important',
		marginBottom: '5px',
		marginTop: '4px',
		borderColor: color.borderGrey,
		'& .dropdown.icon': {
			top: '12px !important',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	Label: {
		width: '100%',
		fontSize: textSizes.bodySmall,
		color: color.textDark,
	},
	header: {
		fontSize: textSizes.headline,
		textAlign: 'center',
	},
	image: {
		textAlign: 'center',
	},
	paragraph: {
		fontSize: textSizes.bodyLarge,
		textAlign: 'center',
	},
	MainButtonWrapper: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		'& button:first-child': {
			marginRight: '20px',
		},
		'& button:last-child': {
			marginRight: '0 !important',
		},
	},
	ModalBtnsCentered: {
		'& .actions': {
			width: '100%',
		},
	},
}));
