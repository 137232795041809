import React from 'react';

import {useStyles} from "./styles";
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles";
import {translate} from "../../../i18n/i18n";

interface QuestionValidationModalProps {
    showModal: boolean,
    closeModal: () => void,
    errors: any,
}

const QuestionValidationModal: React.FunctionComponent<QuestionValidationModalProps> = ({
                                                                                            showModal,
                                                                                            closeModal,
                                                                                            errors
                                                                                        }) => {
    const classes = useStyles();

    return (<>

        <Modal showModal={showModal} header={''} onClose={() => {
            closeModal()
        }}
               submitActionColor={color.link} disabled={false}
               secondaryColor={color.link}
               submitActionTitle={translate('modals.questionValidationModal.submit')}
               onSubmit={() => closeModal()}
               buttonWrapper=''
        >
            <h2 className={classes.header}>{translate('modals.questionValidationModal.header')}</h2>

            <ul>
                {(errors && errors.length) && errors.map((error: any) => {
                    return (<li className={classes.errorList}> {error} </li>)
                })
                }
            </ul>
        </Modal>
    </>);
};

export default QuestionValidationModal;
