import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {translate} from '../../../../i18n/i18n';

interface PreviewTabNavigationProps {
	inExerciseNav: boolean;
	handleToggleTab: Function;
}
const PreviewTabNavigation: React.FunctionComponent<PreviewTabNavigationProps> =
	({ inExerciseNav, handleToggleTab }) => {
		return (
			<div className="preview-window-navigation-tabs">
				<div
					className={`preview-window-navigation-tabs-tab ${
						!inExerciseNav
							? 'preview-window-navigation-tabs-tab-active'
							: ''
					}`}
					onClick={inExerciseNav ? () => handleToggleTab() : () => {}}
				>
					<SvgIcon>
						<path
							id="learnicon"
							d="M18.666,20.957l-12-6.763,12-6.763,12,6.763Zm7.794,2.471a7.8,7.8,0,0,1-15.6,0V18.96l7.8,4.4,7.8-4.4v4.468Z"
							transform="translate(-6.667 -7.431)"
						/>
					</SvgIcon>
					<span>{translate('authoring.previewTabNavigation.learning')}</span>
				</div>
				<div
					className={`preview-window-navigation-tabs-tab ${
						inExerciseNav
							? 'preview-window-navigation-tabs-tab-active'
							: ''
					}`}
					onClick={
						!inExerciseNav ? () => handleToggleTab() : () => {}
					}
				>
					<SvgIcon>
						<path
							id="checkbox-marked-circle-outline"
							d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
							transform="translate(-2 -2)"
						/>
					</SvgIcon>
					<span>{translate('authoring.previewTabNavigation.exercise')}</span>
				</div>
			</div>
		);
	};

export default PreviewTabNavigation;
