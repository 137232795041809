import React from 'react';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import {translate} from "../../../../../i18n/i18n";

import { useStyles } from './style';

interface OrientationButtonProps {
	active: string;
	handleSetParams: Function;
	device: string;
}

const OrientationButton: React.FunctionComponent<OrientationButtonProps> = ({
	active,
	device,
	handleSetParams,
}) => {
	const classes = useStyles();
	const handleSetOrientation = () => {
		handleSetParams(
			'orientation',
			active === 'landscape' ? 'portrait' : 'landscape',
		);
	};

	return (
		<div
			onClick={
				device !== 'desktop' ? () => handleSetOrientation() : () => {}
			}
			className={`${classes.Button} ${
				active === 'landscape' ? classes.ButtonLandscape : null
			} ${device === 'desktop' ? classes.ButtonDisabled : null}`}
		>
			<ScreenRotationIcon />
			{active === 'portrait' ? translate('layout.orientationButton.portrait') : translate('layout.orientationButton.landscape')}
		</div>
	);
};

export default OrientationButton;
