import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, color, textSizes } from '../../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	block: {
		border: `1px solid ${color.borderGrey}`,
		borderRight: 'none',
		borderTop: 'none',
		minHeight: 133,
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		cursor: 'pointer',
		position: 'relative',

		'& .helper-wrapper': {
			position: 'absolute',
			right: 10,
			top: 10,
		},
	},
	blockRow: {
		'&:first-of-type .column': {
			borderTop: 'none',
			borderTopRightRadius: borderRadius.medium,
		},
		'&:last-of-type .column': {
			borderBottom: 'none',
			borderBottomRightRadius: borderRadius.medium,
		},
	},
	kpi: {
		fontSize: textSizes.headline,
		fontWeight: 600,
	},
	label: {
		cursor: 'pointer',
		fontSize: textSizes.bodyNormal,
		fontWeight: 400,
		marginBottom: 14,
		color: color.lightGray,
	},
	active: {
		background: '#D0E9FF',
		borderRight: `4px solid ${color.link}`,
		color: color.textDark,
		'& label': {
			color: color.textDark,
		},
	},
}));
