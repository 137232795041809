import { makeStyles } from '@material-ui/core';
import { textSizes } from '../../../utils/styles';

export const useStyles = makeStyles((theme) => ({
	Container: {
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		position: 'fixed',
		background:
			'linear-gradient(236deg, rgba(48,112,237,1) 0%, rgba(31,93,211,1) 100%)',

		'& .app-exercise-loader-content': {
			padding: 20,
			width: '100%',

			['@media (min-width:1024px)']: {
				flexDirection: 'row',
			},
		},
	},
	BGImage: {
		position: 'absolute',
		right: 0,
		top: 0,
		height: 600,
		maxWidth: 600,
		width: '100%',
		backgroundSize: '80%',
		backgroundPosition: ' right -80px top',
		backgroundRepeat: 'no-repeat',
        zIndex: -1
	},
	Content: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 60,
		alignItems: 'center',
		paddingBottom: 30,
		width: '100%',
		height: '100%',
		color: '#ffffff',
		textAlign: 'center',
		position: 'relative',
		fontSize: textSizes.bodyNormal,
		flexGrow: 1,

		['@media (min-width:1024px)']: {
			padding: 0,
			height: 'auto',
			width: 'auto',
			flexGrow: 0,
		},

		'& h3': {
			fontSize: textSizes.bodyLarge,
			margin: '12px 0 15px 0',
		},

		'& p': {
			lineHeight: 1.5,
			margin: '0 0 5px 0',
			maxWidth: 350,
		},

		'& svg': {
			color: '#ffffff',
			fontSize: 46,
		},

		'& h4': {
			marginTop: 40,
			fontSize: 16,
			fontWeight: 600,
		},
	},
	FooterArea: {
		'& img': {
			width: 230,
			maxWidth: '100%',
			height: 'auto',
			display: 'block',
			margin: '0 auto',

			'&.playstore-badge': {
				width: 261,
			},
		},

		'& p': {
			margin: 0,
			marginBottom: 16,
			fontSize: 16,
		},

		['@media (min-width:1024px)']: {
			paddingLeft: 40,
			marginLeft: 40,
			borderLeft: '1px solid #3875E8',
		},
	},

	VisitInBrowser: {
		fontSize: textSizes.bodyLarge,
		fontWeight: 600,
		marginBottom: 60,
        cursor: "pointer",
		'&:hover': {
            color: '#ffffff',
			textDecoration: 'underline',
		},
	},
}));
