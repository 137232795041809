import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Subpage from '../../../components/shared/Subpage';
import { useStyles } from './style';
import Button from '../../../components/shared/Button';
import { color } from '../../../utils/styles';
import { Image } from 'semantic-ui-react';
import { format, parse } from 'date-fns';
import axios from '../../../app/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { AssignUserModal } from '../../../components/modals/AssignUserModal';
import {
	addAssignment,
	assignmentSelector,
} from '../../../store/assignments/assignmentSlice';
import { translate } from '../../../i18n/i18n';

interface CourseDetailPageParams {
	id: string;
}

export default function CourseDetailPage() {
	const params: CourseDetailPageParams = useParams();
	const [shopCourse, setShopCourse] = useState<any>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isLoading } = useSelector(assignmentSelector);

	useEffect(() => {
		fetchCourseShop();
	}, [params.id]);

	const fetchCourseShop = async () => {
		await axios
			.get(`/courses/${params.id}`)
			.then(function (response) {
				setShopCourse(response.data.success);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	const handleAddAssignment = (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		setShowModal(false);
	};

	return (
		<>
			<Subpage
				backButtonText={translate('tabs.coursesShopTab.courseOverview')}
			>
				{!!shopCourse.name ? (
					<>
						<div className={classes.UserHeader}>
							<div className={classes.UserInfo}></div>
							<div className={classes.ButtonWrapper}>
								<Button
									title={translate(
										'tabs.coursesShopTab.assignCourse',
									)}
									type="solid"
									color={color.link}
									onClick={() => setShowModal(true)}
								/>
							</div>
						</div>
						<div className={classes.GridWrapper}>
							<h2 className={classes.CourseTitle}>
								{shopCourse.name}
							</h2>
							{shopCourse.image ? (
								<Image
									style={{
										height: 335,
										objectFit: 'cover',
										marginBottom: 26,
									}}
									src={shopCourse.image}
									fluid
								/>
							) : (
								<div className={classes.EmptyImage}>Bild</div>
							)}
							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: shopCourse.description,
									}}
								></div>
							</div>
						</div>
					</>
				) : (
					<Loader active={true} />
				)}
			</Subpage>
			<AssignUserModal
				selectedCourse={parseInt(params.id) || 0}
				isLoading={isLoading}
				showModal={showModal}
				closeModal={() => setShowModal(false)}
				onSubmit={handleAddAssignment}
				confirmText={translate('tabs.coursesShopTab.addCourse')}
				message={translate('tabs.coursesShopTab.addUserToCourse')}
			/>
		</>
	);
}
