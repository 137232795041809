export interface paginationInterface {
    page: number,
    rowsPerPage: number
};


export const initialPaginationState: paginationInterface = {
    page: 1,
    rowsPerPage: 10
}
