import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import axios from '../../../../app/axios';
import CourseKPIItem from '../../../../components/app/CourseKPIItem';
import { UserContext } from '../../../../contexts/UserContext';
import { appSelector, setCourseKPIs } from '../../../../store/app/appSlice';
import Statistics from '../../../../components/shared/Statistics/course';
import AppFilterCourseModal from '../../../../components/app/selectCourse/FilterModal';
import { translate } from '../../../../i18n/i18n';

const StatisticsTab = () => {
	const dispatch = useDispatch();
	const { allCourses, courseKPIs, currentCourse } = useSelector(appSelector);
	const { user } = useContext(UserContext);
	const [showModal, setShowModal] = useState(false);
	const [hasFetched, setHasFetched] = useState(false);
	const [displayedCourse, setDisplayedCourse] = useState<null | number>(null);

	useEffect(() => {
		if (!!currentCourse && !displayedCourse)
			setDisplayedCourse(currentCourse.assignment_id);
	}, [currentCourse]);

	useEffect(() => {
		if (user?.id && displayedCourse) {
			fetchKPIs(displayedCourse);
		}
	}, [user, displayedCourse]);

	const fetchKPIs = async (displayedCourse: number) => {
		setHasFetched(false);

		await axios
			.get(`/resultsByUser/${user.id}`)
			.then(async function (response) {
				let kpis = { ...response.data.success, byCourse: {} };

				const specificKPIs = await axios.get(
					`/results/by-assignment/${displayedCourse}/${user.id}`,
				);
				kpis.data = specificKPIs.data;
				dispatch(setCourseKPIs(kpis));
				setHasFetched(true);
			})
			.catch(function (error) {
				if (!error.response) {
					// HANDLE OFFLINE DATA HERE
					// const offlineCourseData =
					// 	window.localStorage.getItem('all_courses');
					// if (offlineCourseData) {
					// 	dispatch(setAllCourses(JSON.parse(offlineCourseData)));
					// 	setOfflineError('OLD_DATA');
					// } else {
					// 	setOfflineError('NO_DATA');
					// }
				}
			});
	};

	const calculateReadingDuration = (time: number) => {
		const hours = Math.floor(time / 3600);
		const min = Math.floor((time % 3600) / 60);
		const sec = time % 60;
		return `${hours > 0 ? `${hours}h ` : ''}${`${min}min`}`;
	};

	const handleSaveFilteredCourses = (id: number) => {
		setDisplayedCourse(id);
		setShowModal(false);
	};

	return !allCourses || !courseKPIs || !displayedCourse || !hasFetched ? (
		<div
			style={{
				height: 'calc(100% - 110px)',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress
				style={{ color: '#F6F7F9', width: 100, height: 100 }}
			/>
		</div>
	) : (
		<>
			<div className="app-course-section">
				<CourseKPIItem
					title={translate('tabs.statisticsTab.userLife')}
					value={calculateReadingDuration(courseKPIs.usage)}
				/>
				<CourseKPIItem
					title={translate('tabs.statisticsTab.passRate')}
					value={`${(courseKPIs.passedScore * 100).toFixed(0)}%`}
				/>
				<CourseKPIItem
					title={translate('tabs.statisticsTab.courseCompleted')}
					value={`${courseKPIs.passedCourses.length} / ${courseKPIs.assignedCourses.length}`}
				/>
				<CourseKPIItem
					title={translate('tabs.statisticsTab.deadlineExceeded')}
					value={`${courseKPIs.delayedAssignments.length}`}
				/>
			</div>
			<div className="app-course-section">
				<div className="app-course-kpi-selection">
					<p className="app-course-section-headline">Kursdetails</p>
					{!(
						process.env.REACT_APP_COURSE_ID &&
						!process.env.REACT_APP_IS_APPUCATIONS
					) && (
						<p
							className="app-course-kpi-selection-dropdown"
							onClick={() => {
								setShowModal(true);
							}}
							style={
								allCourses.length === 1
									? { cursor: 'default' }
									: {}
							}
						>
							<span>
								{
									allCourses.find(
										(course) =>
											course.id === displayedCourse,
									)!.product_name
								}
							</span>
							{allCourses.length > 1 && <SyncAltIcon />}
						</p>
					)}
				</div>
			</div>
			<Statistics
				assignment={courseKPIs.data}
				course={courseKPIs.assignedCourses.find(
					(item: any) => item.id === displayedCourse,
				)}
			/>
			<AppFilterCourseModal
				courses={allCourses}
				showModal={showModal}
				closeModal={() => setShowModal(false)}
				saveFilteredCourses={(id: number) =>
					handleSaveFilteredCourses(id)
				}
				displayedCourse={displayedCourse}
			/>
		</>
	);
};

export default StatisticsTab;
