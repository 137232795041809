import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useStyles } from './styles';
import { QuestionTypes } from '../../../../../enums/questions/questions-enums';
import {translate} from '../../../../../i18n/i18n';

export const RenderSingleChoice: React.FunctionComponent<{
	question: any;
	index: number;
	onChange?: Function;
	isValidated?: boolean;
	selectedIndex?: number;
	settings?: any;
	isExam?: boolean;
}> = ({ question, index, onChange, isValidated, selectedIndex , settings, isExam}) => {
	const classes = useStyles();
	return (
		<div className={classes.questionWrapper} key={index}>
			<p className={classes.question}>
				{question.question} {!isExam && settings?.exercise_show_question_type ? translate('authoring.renderSingleChoice.singleChoice'):''}{isExam && settings?.exam_show_question_type ? translate('authoring.renderSingleChoice.singleChoice'):''}
			</p>
			<RadioGroup>
				{question.answers &&
					question.answers.map((answer: any, index: number) => {
						return (
							<FormControlLabel
								key={index}
								className={`${
									selectedIndex === index && isValidated
										? classes.VerifiedQuestionRight
										: ''
								} ${
									selectedIndex !== index &&
									answer.is_correct &&
									isValidated
										? classes.VerifiedQuestionMissed
										: ''
								} ${
									selectedIndex === index &&
									!answer.is_correct &&
									isValidated
										? classes.VerifiedQuestionWrong
										: ''
								}`}
								value={answer.text}
								control={
									<Radio
										disabled={isValidated}
										color="primary"
										style={{ margin: 0 }}
										checked={selectedIndex === index}
										onChange={
											onChange
												? (e, checked) =>
														onChange(
															QuestionTypes.SINGLE_CHOICE,
															{
																i: index,
																checked:
																	checked,
															},
														)
												: () => {}
										}
									/>
								}
								label={answer.text}
							/>
						);
					})}
			</RadioGroup>
		</div>
	);
};
