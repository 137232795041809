import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppProfileChangeNameModal from './Modal';
import AppSuccessModal from '../../SuccessModal';
import { useStyles } from './styles';
import { translate } from '../../../../i18n/i18n';

export default function AppProfileChangeName() {
	const history = useHistory();
	const classes = useStyles();

	const [showModal, setShowModal] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const handleSuccess = (text: string) => {
		setShowSuccess(true);
		setSuccessMessage(text);
	};

	return (
		<>
			{showModal && (
				<AppProfileChangeNameModal
					showModal={showModal}
					closeModal={() => setShowModal(false)}
					handleSuccess={handleSuccess}
					firstName={window.localStorage.getItem('firstname') || ''}
					lastName={window.localStorage.getItem('lastname') || ''}
				/>
			)}
			<AppSuccessModal
				showModal={showSuccess}
				closeModal={() => setShowSuccess(false)}
				successMessage={successMessage}
			/>
			<div className={classes.Container}>
				<div className={classes.NameContainer}>
					<p className={classes.Label}>NAME</p>
					<span className={classes.Input}>
						{window.localStorage.getItem('firstname')}{' '}
						{window.localStorage.getItem('lastname')}
					</span>
				</div>
				<span
					onClick={() => setShowModal(true)}
					className={`${classes.Edit} profile-edit-button`}
				>
					{translate('app.changeName.change')}
				</span>
			</div>
		</>
	);
}
