import Close from '@material-ui/icons/Close';
import React from 'react';
import { translate } from '../../../../../i18n/i18n';

interface SummaryProps {
	questions: any[];
	answers: { [key: number]: { validation: 0 | 1 } };
	handleViewDetail: Function;
}

const Summary: React.FunctionComponent<SummaryProps> = ({
	questions,
	answers,
	handleViewDetail,
}) => {
	const successfulAnswers = Object.keys(answers).filter(
		(key: any) => !!answers[key].validation,
	).length;
	return (
		<div className="app-exercise-summary">
			<div className="app-exercise-header preview-window-content-header">
				<div className="preview-window-content-header-headlines">
					<h3>{translate('app.summary.summary')}</h3>
				</div>
				<Close onClick={() => handleViewDetail(null)} />
			</div>
			<div className="app-exercise-summary-wrapper">
				<div className="app-exercise-summary-counter">
					<h3>{translate('app.summary.correctAnswer')}</h3>
					<p>
						<span>{successfulAnswers}</span> / {questions.length}
					</p>
				</div>
				<div className="app-exercise-summary-questions">
					{Object.keys(answers).map((key: any) => {
						const answer = answers[key];
						const headline = questions[key].question;
						return (
							<div
								key={key}
								className={`app-exercise-summary-question ${
									!!answer.validation ? 'correct' : 'wrong'
								}`}
								onClick={() => handleViewDetail(+key)}
							>
								<span>{+key + 1}</span>
								<h4>{headline}</h4>
								<p>{!!answer.validation ? '1' : '0'}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Summary;
