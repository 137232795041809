import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
	Navigation: {
		['@media (max-height:480px)']: {
			display: 'none',
		},
	},
	Container: {
		display: 'flex',
		justifyContent: 'center',
	},
	AvatarIcon: {
		height: 16,
		width: 16,
		borderRadius: '50%',
		marginBottom: 2,
	},
}));
