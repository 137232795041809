import React from 'react';
import Typography from '@material-ui/core/Typography';
import TabNavigator from '../../components/shared/TabNavigator';
import TeamTab from '../../components/tabs/TeamTab';
import GeneralTab from '../../components/tabs/GeneralTab';
import CertificateTab from '../../components/tabs/CertificateTab';
import { translate } from '../../i18n/i18n';
import VisualSettingsTab from '../../components/tabs/VisualSettingsTab';
import InvoiceTab from '../../components/tabs/InvoiceTab';

export default function Settings() {
	const settingsTabs = {
		general: {
			title: translate('settings.header.general'),
			component: <GeneralTab />,
		},
		presentation: {
			title: translate('settings.header.presentation'),
			component: <VisualSettingsTab />,
		},
		team: {
			title: translate('settings.header.team'),
			component: <TeamTab />,
			hasSubmenu: true,
		},
		certificate: {
			title: translate('settings.header.certificate'),
			component: <CertificateTab />,
		},
		invoice: {
			title: translate('settings.header.invoice'),
			component: <InvoiceTab />,
		},
	};

	return (
		<div style={{ padding: '96px 30px 100px' }}>
			<Typography
				style={{ marginBottom: 32 }}
				variant="h4"
				component="h4"
			>
				{translate('settings.headline')}
			</Typography>
			<TabNavigator tabs={settingsTabs} defaultActive="general" />
		</div>
	);
}
