import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../utils/styles';

export const useStyles = makeStyles(theme => ({
    ListWrapper: {
        marginTop: 25,
    },
    label: {
        position: "relative"
    },
    TitleInput: {
        padding: "4px 8px",
        fontWeight: 400,
        fontSize: 16,
        color: color.textDark,
        width: "calc(50% - 12px)",
        "&::placeholder": {
            color: '#9C9C9C'
        }
    },
    deleteWrapper: {
        width: 15,
        position: "absolute",
        right: -15,
        top: -6,
        "&:hover": {
            cursor: 'pointer !important',
        }
    },
    deleteAnswerWrapper: {
        width: 15,
        position: "absolute",
        right: -45,
        top: -6,
        "&:hover": {
            cursor: 'pointer !important',
        }
    },
    selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
    errorLabel: {
        marginLeft: 40,
        fontSize: 13,
        color: '#f44336'
    },
    pairIcon: {
        width: 24,
        height: 24
    },
    wordWrapper: {
        display: "flex",
        width: "100%",
        marginBottom: 24,
        position: "relative"
    },
    firstWord: {
        padding: "4px 8px",
        display: 'inline-block',
        width: "calc(50% - 12px)"
    },
    secondWord: {
        padding: "4px 8px",
        display: 'inline-block',
        width: "calc(50% - 12px)",
        textAlign: "right"
    }
}));
