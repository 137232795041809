import React from 'react';
import {faAward} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {translate} from "../../../i18n/i18n";

interface FinishButtonProps {
    handleClick: React.MouseEventHandler;
}

const FinishButton: React.FunctionComponent<FinishButtonProps> = ({
                                                                      handleClick
                                                                  }) => {
    const renderIcon = () => {
        return <FontAwesomeIcon className="exam-icon icon" icon={faAward}/>
    };


    return (
        <div
            className={`preview-item-wrapper`}
        >
            <div className="preview-item-tab" onClick={handleClick}>
                {renderIcon()}
                <div className="preview-item-tab-title">{translate(`app.course.backToStart`)}</div>
            </div>

        </div>
    );
};

export default FinishButton;
