import React from 'react';
import { IconAction } from '../../shared/IconAction';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel } from '@material-ui/core';
import { translate } from '../../../i18n/i18n';

import { useStyles } from './styles';

interface ButtonProps {
	searchProps: any;
	placeholder?: string;
}

const TableSearch: React.FunctionComponent<ButtonProps> = ({ searchProps, placeholder }) => {
	const classes = useStyles();
	const {
		handleSearchChange,
		search,
		showSearch,
		setShowSearch,
		CurrentlySearching,
	} = searchProps;

	return (
		<div className={classes.SearchFieldWrapper}>
			<CurrentlySearching />

			{!!showSearch && (
				<TextField
					className={classes.Input}
					placeholder={placeholder || translate('shared.tableSearch.nameAndEmail')}
					value={search}
					onChange={handleSearchChange}
					name="name"
				/>
			)}
			<FormControlLabel
				className={classes.SearchButton}
				onClick={() => {
					setShowSearch(!showSearch);
				}}
				control={
					<IconAction
						handleClick={() => {}}
						type="search"
						active={showSearch}
					/>
				}
				label=""
			/>
		</div>
	);
};

export default TableSearch;
