import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { useStyles } from './styles';
import { IconAction } from '../../shared/IconAction';
import MultiSelect from '../MultiSelect';
import HasOne from './HasOne';
import { translate } from '../../../i18n/i18n';

interface TableActionProps {
	setFilterValues: (e: any, name: string) => void;
	options: any;
}

const TableFilter: React.FunctionComponent<TableActionProps> = ({
	setFilterValues,
	options,
}) => {
	const classes = useStyles();
	const [showFilters, setShowFilters] = useState<boolean>(false);

	const createFilterComponent = (type: string, option: any) =>
		type === 'hasOne' ? (
			<HasOne
				setFilterValues={setFilterValues}
				option={option}
				key={option.name}
			/>
		) : (
			<div
				style={{ marginRight: 15, display: 'inline-block' }}
				key={option.name}
			>
				<MultiSelect
					handleChange={(newValues: number[]) =>
						setFilterValues(newValues, option.name)
					}
					inputTitle={option.placeholder}
					modalTitle={option.filterTitle}
					selected={option.value}
					options={option.options}
					bordered
				/>
			</div>
		);

	return (
		<>
			{!!showFilters &&
				options.map((option: any) =>
					createFilterComponent(option.type, option),
				)}

			<FormControlLabel
				className={classes.FilterButton}
				onClick={() => {
					setShowFilters(!showFilters);
				}}
				control={
					<IconAction
						handleClick={() => {}}
						type="filter"
						active={showFilters}
					/>
				}
				label={translate('shared.tableFilter.filter')}
			/>
		</>
	);
};

export default TableFilter;
