import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Grid} from 'semantic-ui-react';
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import TableAction from '../../shared/TableAction';
import TableButton from '../../shared/TableButton';
import {translate} from '../../../i18n/i18n';
import {format, parse, parseJSON} from 'date-fns';
import {fetchInstitutions, Institution, institutionSelector} from "../../../store/institution/institutionSlice";
import {useStyles} from "../UserOverviewTab/styles";
import {useDispatch, useSelector} from "react-redux";

interface InstitutionsOverviewTabProps {

}

const InstitutionsOverviewTab: React.FunctionComponent<InstitutionsOverviewTabProps> = () => {
    const dispatch = useDispatch();
    const {institutions: institutionData, isLoading}: any = useSelector(institutionSelector);

    useEffect(() => {
        console.log(institutionData, isLoading, !institutionData && !isLoading);
        if (!institutionData && !isLoading) {
            console.log("loading")
            dispatch({
                ...fetchInstitutions({
                    requestType: 'GET',
                    request: `smartometer/institutions`,
                }),
            });
        }
    }, []);

    const history = useHistory();
    const {pathname} = useLocation();
    const classes = useStyles();
    const institutionRowData = {
        id: {
            title: translate('tabs.institutionsOverviewTab.id'),
            sorting: 'id',
            align: 'left',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.id}</p>
            ),
        },
        institution_name: {
            title: translate('tabs.institutionsOverviewTab.name'),
            sorting: 'institution_name',
            align: 'left',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.institution_name}</p>
            ),
        },
        name: {
            title: translate('tabs.institutionsOverviewTab.creator_name'),
            sorting: 'first_name',
            align: 'left',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.first_name} {item.last_name}</p>
            ),
        },
        created_at: {
            title: translate('tabs.institutionsOverviewTab.created_at'),
            sorting: 'created_at',
            align: 'right',
            renderType: (item: Institution): JSX.Element => (
                <p>{format(
                    parseJSON(
                        item.created_at
                    ),
                    'dd.MM.yyyy',
                )}</p>
            ),
        },
        trial_end_at: {
            title: translate('tabs.institutionsOverviewTab.trial_end_at'),
            sorting: 'trial_end_at',
            align: 'right',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.trial_end_at && format(
                    parseJSON(
                        item.trial_end_at
                    ),
                    'dd.MM.yyyy',
                )}</p>
            ),
        },
        paid_period_start_at: {
            title: translate('tabs.institutionsOverviewTab.paid_period_start_at'),
            sorting: 'paid_period_start_at',
            align: 'right',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.paid_period_start_at && format(
                    parseJSON(
                        item.paid_period_start_at
                    ),
                    'dd.MM.yyyy',
                )}</p>
            ),
        },
        users: {
            title: translate('tabs.institutionsOverviewTab.users_count'),
            sorting: 'users',
            align: 'right',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.users_count}</p>
            ),
        },
        appusage: {
            title: translate('tabs.institutionsOverviewTab.appusage'),
            sorting: 'appusage',
            align: 'right',
            renderType: (item: Institution): JSX.Element => (
                <p>{item.appusage}</p>
            ),
        },
    };

    const headerComponent = (
        <div className={classes.HeaderComponent}>
            <div>

            </div>
            <div className={classes.FilterComponent}>

            </div>
        </div>
    );
    console.log(institutionData);
    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard
                            title={translate('tabs.institutionsOverviewTab.institutions')}
                            headerComponent={headerComponent}
                        >{institutionData && <Table
                            showHeader
                            rows={institutionRowData}
                            total={institutionData.length}
                            data={institutionData}
                            loading={isLoading}
                            rowsPerPage={institutionData.length}
                        />}

                        </ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </>
    );
};

export default InstitutionsOverviewTab;
