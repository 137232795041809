import React from "react";
import { useStyles } from "./styles";
import {Edit, MailOutline, Delete, TrendingUp, Close, ArrowDropUp, ArrowDropDown, Search} from '@material-ui/icons';

import filterIcon from '../../../assets/images/filterIcon.svg'
import closeIcon from '../../../assets/images/closeIcon.svg';
import searchIcon from '../../../assets/images/searchIcon.svg';

const FilterIcon = (props: any) => <img {...props} src={filterIcon} />;

const CloseIcon = (props: any) => <img {...props} src={closeIcon} />;

const SearchIcon = (props: any) => <img {...props} src={searchIcon} />;

const ActionComponents: any = {
    edit: Edit,
    mail: MailOutline,
    delete: Delete,
    chart: TrendingUp,
    filter: FilterIcon,
    close: CloseIcon,
    search: SearchIcon,
    ArrowDropUp: ArrowDropUp,
    ArrowDropDown: ArrowDropDown,
}

interface IconActionProps{
    type: string;
    handleClick?: Function;
    active?: boolean;
    className?: string
}

export const IconAction: React.FunctionComponent<IconActionProps> = ({ type, handleClick, active, className }) => {
    const classes = useStyles();
    const Component = active ? ActionComponents['close'] : ActionComponents[type];
    return <Component className={`${classes.Icon} ${className || ''} ${active === true ? ` active ` : 'inactive'}`} onClick={handleClick} />
}
