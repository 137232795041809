import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../utils/styles';
export const useStyles = makeStyles((theme) => ({
	SubpageWrapper: {
		padding: '96px 30px 30px',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		minHeight: '100vh',
		background: color.background,
	},
	SubpageWrapperBannered: {
		padding: '120px 30px 30px',
	},
	BackLink: {
		cursor: 'pointer',
		display: 'inline-block',
		fontSize: '16px',

		'& svg': {
			top: '5px',
			position: 'relative',
			marginRight: '11px',
			transform: 'rotate(225deg)',
		},
	},
	SubpageContent: {
		display: 'block',
		paddingTop: '30px',
		position: 'relative',
	},
}));
