import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { isPlatform, getPlatforms } from '@ionic/react';

import { useStyles } from './styles';
import BackgroundImage from '../../../../assets/images/bg-som.png';
import { UserContext } from '../../../../contexts/UserContext';
import Button from '../../../shared/Button';
import { translate } from '../../../../i18n/i18n';
import { color } from '../../../../utils/styles';

const NoLicenseModal = () => {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(UserContext);
	const logout = () => {
		window.localStorage.clear();
		history.push('/auth/login');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<div className={`app-exercise-loader ${classes.Container}`}>
					<div className="app-exercise-loader-content">
						<div
							className={classes.BGImage}
							style={{
								backgroundImage: `url("${BackgroundImage}")`,
							}}
						></div>
						<div className={classes.Content}>
							<HelpOutline />
							<h3>{translate('app.noLicense.headline')}</h3>
							<p>{translate('app.noLicense.content')}</p>
							<h4>
								{translate('app.noLicense.yourAdmin')}:
							</h4>
							<a
								href={`mailto:kontakt@appucations.de`}
								className={classes.FeedbackWrapper}
							>
								kontakt@appucations.de
							</a>
						</div>
						
						<div className={classes.LogoutLink} onClick={logout}>
							{translate('app.noLicense.logout')}
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
};

export default NoLicenseModal;
