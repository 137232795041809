import React from 'react';
import { useStyles } from './styles';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface TableFilterHasOneProps {
	option: any;
	setFilterValues: (e: any, name: string) => void;
}

const TableFilterHasOne: React.FunctionComponent<TableFilterHasOneProps> = ({
	option,
	setFilterValues,
}) => {
	const classes = useStyles();

	const handleChange = (e: any, dropdown: DropdownProps) => {
		setFilterValues(dropdown.value, option.name);
	};

	return (
		<>
			<Dropdown
				fluid
				className={classes.Multiselect}
				name={option.name}
				id={option.name}
				onChange={handleChange}
				value={option.value}
				options={option.options}
				placeholder={option.placeholder}
				selection
			/>
		</>
	);
};

export default TableFilterHasOne;
