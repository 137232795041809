import {QuestionTypes} from "../../../enums/questions/questions-enums";
import {translate} from '../../../i18n/i18n';

export const formatAssignTermPopulateData = (wordCombinations: any[]) => {
    return wordCombinations.map((wordCombination: any) => {
        return wordCombination
    })
}



export const formatAssignTermSaveData = (options:any, name: string) => {
    return  {
        question: name,
        type: QuestionTypes.ASSIGN_TERM,
        terms: options.map( (term:any) => {
            const words = term.words.map( (word: any) => word.term );
            return {
                ...term,
                words
            }
        } )
    }
}
export const formatAssignTermUpdateData = (options:any, name: string) => {
    return  {
        question: name,
        type: QuestionTypes.ASSIGN_TERM,
        terms: options
    }
}


export const validateAssignTermUpdateData = (name:string, wordCombinations:any[]) => {
    let errors = []
    if (!name) {
        errors.push(translate('helpers.assignTerm.nameValidation'))
    }
    if (!wordCombinations.length) {
        errors.push(translate('helpers.assignTerm.questionValidation'))
    }

    return errors
}
