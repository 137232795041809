import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	HeaderBar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '30px 40px',
		width: '100%',
		background: color.darkBackground,
		color: color.white,

		'& h2': {
			fontSize: textSizes.headline,
			fontWeight: 300,
			marginBottom: 0,
		},

		'& svg': {
			color: color.white,
			cursor: 'pointer',
			width: 25,
			height: 25,
		},
	},
	ButtonWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
}));
