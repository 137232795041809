import { makeStyles } from '@material-ui/core/styles';
import {color} from "../../../../utils/styles";
export const useStyles = makeStyles((theme) => ({
	NameContainer: {
		textAlign: 'left',
	},
	Container: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 23,
	},
	Edit: {
		color: color.link,
		fontSize: 12,
		textTransform: 'uppercase',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	Label: {
		color: '#C7C7C7',
		fontSize: 12,
		marginBottom: 1,
	},
	Input: {
		color: '#252733',
		fontSize: 18,
	},
}));
