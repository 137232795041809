import React, {useEffect, useRef, useState} from 'react';
import {Grid, Loader} from "semantic-ui-react";
import {useStyles} from './styles';
import axios from "../../../app/axios";
import {useHistory} from "react-router-dom";
import {Search} from '@material-ui/icons';
import {translate} from "../../../i18n/i18n";
import CoursePlaceholderImg from "../../../assets/images/SOM-Course.png";


const CoursesShopTab = () => {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const history = useHistory();
    const searchInput = useRef<HTMLInputElement | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');

    const [shopCourses, setShopCourses] = useState([]);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    };

    const courseRedirect = (courseId: number) => {
        history.push(`/courses/coursesShop/detail/${courseId}`)
    }

    useEffect(() => {
        fetchCourseShop()
    }, [searchValue]);

    const fetchCourseShop = async () => {
        setIsLoading(true)
        await axios.get(`/get-courses-appucation-all?search=${searchValue}`)
            .then(function (response) {
                setIsLoading(false)
                setShopCourses(response.data.success)
            })
            .catch(function (error) {
                setIsLoading(false)
                console.error(error)
            });
    }

    return (
        <>
            <div style={{marginBottom: 0, display: 'flex', alignItems: 'center'}}
                 className="coursewizard-input-wrapper exercise-wrapper">
                <Search fontSize="large" style={{marginRight: 16}}/>
                <input className={classes.TitleInput} ref={searchInput} type="text"
                       placeholder={translate('tabs.coursesShopTab.searchCourse')} value={searchValue} onChange={handleSearch}/>
            </div>
            <Loader active={isLoading}/>
            {!isLoading &&
            <Grid>
                <Grid.Row>
                    {shopCourses.map((shopCourse: any, index: number) => {
                        return (
                            <Grid.Column onClick={() => courseRedirect(shopCourse.id)} width={5}>
                                <div className={classes.Card} style={{backgroundImage: `url(${shopCourse.image ? shopCourse.image : CoursePlaceholderImg})`}}>
                                    <div className={classes.CourseCardContent}>
                                        <p className={classes.CourseCardSubTitle}>{shopCourse.name}</p>
                                    </div>
                                </div>
                            </Grid.Column>
                        )
                    })}
                </Grid.Row>
            </Grid>
            }
        </>
    );
};

export default CoursesShopTab;
