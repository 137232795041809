import React from 'react';

interface UpperTabNavigationProps {
	activeTab: string;
	tabs: {
		value: string;
		title: string;
	}[];
	onNavigate: Function;
}

const UpperTabNavigation: React.FunctionComponent<UpperTabNavigationProps> = ({
	activeTab,
	onNavigate,
	tabs,
}) => {
	return (
		<div className="app-upper-tab-container">
			{tabs.map((tab) => {
				const { value, title } = tab;
				return (
					<p
						key={value}
						onClick={() => onNavigate(value)}
						className={`app-upper-tab-tab ${
							activeTab === value ? 'active' : ''
						}`}
					>
						{title}
					</p>
				);
			})}
		</div>
	);
};

export default UpperTabNavigation;
