import * as yup from "yup";
import YupPassword from "yup-password";

YupPassword(yup);
export const validationSchema = yup.object().shape({
    first_name: yup
        .string()
        .required('First name is required'),
    last_name: yup
        .string()
        .required('Last name is required')
});
