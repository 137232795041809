export const useFavicon = () => {
    const setFavicon = (faviconUrl?: string) => {
        const favicon = window.localStorage.getItem('institutionFavicon');
        const faviconEl: HTMLLinkElement | null = document.querySelector('#global_favicon');
        if(favicon && faviconEl) {
            faviconEl.href = favicon;
        } else if(faviconUrl && faviconEl) {
            faviconEl.href = faviconUrl;
        }
    };

    return {
        setFavicon
    }
}
