import {QuestionTypes} from "../../../enums/questions/questions-enums";
import {translate} from '../../../i18n/i18n';

export const formatGapFillUpdateData = (options:any, name: string) => {
        return  {
        question: name,
        type: QuestionTypes.GAP_FILLS,
        text: options[0].text
    }
}


export const validateGapFillUpdateData = (name:string, text:string) => {
    let errors = []

    if (!name) {
        errors.push(translate('helpers.gapFillQuestions.nameValidation'))
    }
    if (!text) {
        errors.push(translate('helpers.gapFillQuestions.textValidation'))
    }
    if (text && !text.indexOf(`<word>`)) {
        errors.push(translate('helpers.gapFillQuestions.gapValidation'))
    }

    return errors
}
