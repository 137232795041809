import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";


const initialState = {
    data: {},
    single: null,
    trigger: false,
    isLoading: false,
    error: "",
};

export const licenseSlice = createSlice({
    name: "license",
    initialState,
    reducers: {
        fetchLicenses: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.data = {...action.response.success}
                state.isLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            }
        },
        fetchLicense: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished' && !!action.response.success) {
                state.single = action.response.success;
                state.isLoading = false;
                state.error = "";
            } else if (action.state === 'error') {
                state.isLoading = false;
                state.error = 'Request error';
            } else {
                state.isLoading = false;
            }
        },
        addLicense: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                const { success, data } = action.response;
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        },
        updateLicense: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        },
        deleteLicense: (state, action: any) => {
            if (action.state === 'send') {
                state.isLoading = true;
            }
            if (action.state === 'finished') {
                state.trigger = !state.trigger;
                state.isLoading = false;
            }
        }
    }
})

export const { fetchLicense, fetchLicenses, addLicense, updateLicense, deleteLicense } = licenseSlice.actions;
export const licenseSelector = (state: RootState) => state.license;
export const groupLoadingSelector = (state: any) => state.group.isLoading;
export default licenseSlice.reducer
