import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';

import AppStoreDE from '../../../assets/images/AppStore-DE.svg';
import AppStoreEN from '../../../assets/images/AppStore-EN.svg';
import PlayStoreDE from '../../../assets/images/PlayStore-DE.png';
import PlayStoreEN from '../../../assets/images/PlayStore-EN.png';
import { textSizes } from '../../../utils/styles';
import BackgroundImage from '../.../../../../assets/images/bg-som.png';
import i18n from '../../../i18n/i18n';
import { useStyles } from './styles';

const AppRedirect = () => {
	const history = useHistory();
	const classes = useStyles();
	const { language } = i18n;

	// useEffect(() => {
	// 	if (
	// 		!window.localStorage.getItem('user') ||
	// 		window.localStorage.getItem('isEndUser') !== 1
	// 	) {
	// 		history.push('/auth/login');
	// 	}
	// });

	const handleRedirectToApp = () => {
		history.push('/app/learn?navigation=0&content=1');
	};

	return (
		<div className={`app-exercise-loader ${classes.Container}`}>
			<div className="app-exercise-loader-content">
				<div
					className={classes.BGImage}
					style={{
						backgroundImage: `url("${BackgroundImage}")`,
					}}
				></div>
				<div className={classes.Content}>
					<MobileScreenShareIcon />
					<h3>Zur App wechseln</h3>
					<p>
						Für die beste Lernerfahrung empfehlen wir unsere
						smartOmeter-Apps für iOS und Android.
					</p>
				</div>
				<div className={classes.FooterArea}>
					<a href="https://www.apple.com">
						{language === 'de-DE' ? (
							<img className="apple-badge" src={AppStoreDE} />
						) : (
							<img className="apple-badge" src={AppStoreEN} />
						)}
					</a>
					<a href="https://www.google.com">
						{language === 'de-DE' ? (
							<img
								className="playstore-badge"
								src={PlayStoreDE}
							/>
						) : (
							<img
								className="playstore-badge"
								src={PlayStoreEN}
							/>
						)}
					</a>
					<p>oder</p>
					<a
						onClick={handleRedirectToApp}
						className={classes.VisitInBrowser}
					>
						App im Browser öffnen
					</a>
				</div>
			</div>
		</div>
	);
};

export default AppRedirect;
