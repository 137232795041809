import { makeStyles } from "@material-ui/core/styles";
import {borderRadius, color, textSizes} from "../../../../utils/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 16,
        borderRadius: borderRadius.medium
    },
    examColor: {
        background: "#FFDC5F"
    },
    examRoot: {
      background: "#FFF2C3",
        width: '100%',
        height: 16,
        borderRadius: borderRadius.medium
    },
    wrapper: {
        margin: 32
    },
    value: {
        color: color.textDark,
        fontSize: textSizes.bodySmall
    },
    type: {
        color: color.lightGray,
        fontSize: textSizes.bodySmall
    },
    row: {
        marginBottom: 8
    },
    headline: {
        fontSize: textSizes.bodyLarge,
        fontWeight: 400
    },
    subtitle: {
        color: color.lightGray,
        fontSize: textSizes.bodySmall,
        marginBottom: 70
    }

}))
