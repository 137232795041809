import React, {useEffect, useState} from 'react'
import Modal from '../../shared/Modal';
import {color} from "../../../utils/styles"
import LinearProgress, {LinearProgressProps} from '@material-ui/core/LinearProgress';
import {translate} from "../../../i18n/i18n";
import {useStyles as modalStyles} from "../../shared/Modal/styles";

import {useStyles} from "./styles";

import {Box, Button as MButton, Typography} from "@material-ui/core";
import {bulkCreate, fetchUsers, finishLoading, resetBulk, userSelector} from "../../../store/users/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {UsersErrors} from "../../../errors-enums";
import Button from "../../shared/Button";
import {UsersBulkExample} from "../../../enums/users/users-enums";


interface BulkImportModalPropsInterface {
    showModal: boolean,
    closeModal: () => void,
}

interface BulkImportModalStateInterface {
    step: number,
    selectedFile: null | File
}

const INITIAL_STATE: BulkImportModalStateInterface = {
    step: 1,
    selectedFile: null
}

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box display="flex" justifyContent="center">
            <Box width="50%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

export const BulkImportModal: React.FunctionComponent<BulkImportModalPropsInterface> = ({showModal, closeModal}) => {
    const classes = useStyles();
    const modalClasses = modalStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [progress, setProgress] = React.useState(10);
    const [startProgress, setStartProgress] = React.useState(false);
    const {bulkError, isLoading} = useSelector(userSelector);

    const dispatch = useDispatch();
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
        if (startProgress) {
            const timer = setInterval(() => {
                if (progress === 100) {
                    setStartProgress(false)
                } else {
                    const diff = Math.random() * 5;
                    const newProgress = progress + diff;
                    setProgress(newProgress);
                }

            }, 100);

            return () => {
                clearInterval(timer);
            };
        }
    }, [progress, startProgress]);

    useEffect(() => {
        if (isSubmitting && !isLoading && !bulkError) {
            setIsSubmitting(false);
            dispatch({
                ...fetchUsers({
                    requestType: "GET",
                    request: 'smartometer/users',
                }),
            });
        }
        ;
        if (!!isLoading) setIsSubmitting(true)
    }, [isLoading, bulkError, setIsSubmitting, dispatch, isSubmitting]);

    const onSubmit = () => {
        setState({
            ...state,
            step: 2
        })
        setIsSubmitting(true);
        if (state.selectedFile) {
            const data = new FormData()
            data.append('file', state.selectedFile)
            try {
                dispatch({
                    ...bulkCreate({
                        requestType: "POST",
                        body: data,
                        request: `/smartometer/usersBulk`,
                    }),
                });
                setProgress(0);
                setStartProgress(true);
                setTimeout(() => {
                    dispatch({
                        ...finishLoading()
                    })
                }, 5000)
            } catch (e) {
                console.error(e);
            }


        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            selectedFile: e?.target?.files ? e?.target?.files[0] : null
        })
    }
    const getRandomStringByLength = (length: number) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const downloadExample = () => {
        const link = document.createElement('a');
        const blob = new Blob([UsersBulkExample.template.replace(/musterfirma/g, getRandomStringByLength(30))], {type: 'text/plain'});
        const fileDownloadUrl = URL.createObjectURL(blob);
        link.href = fileDownloadUrl;
        link.target = "_blank";
        link.setAttribute(
            'download',
            UsersBulkExample.fileName,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const close = () => {
        setState(INITIAL_STATE);
        dispatch({
            ...resetBulk(),
        });
        closeModal();
    }

    const reset = () => {
        setState(INITIAL_STATE);
        dispatch({
            ...resetBulk(),
        });
    }

    const {step, selectedFile} = state;

    const isDuplicated = step === 2 && !isLoading && bulkError && bulkError.type === UsersErrors.DUPLICATES;

    const isMalformatted = step === 2 && !isLoading && bulkError && bulkError.type === UsersErrors.MALFORMATTED;

    const isDone = step === 2 && !isLoading && !bulkError;

    const isCurrentlyLoading = step === 2 && isLoading;

    const noAction = isDuplicated || isMalformatted || isDone || isCurrentlyLoading;
    if (isDone) {
        setTimeout(() => {
            close();
        }, 3000)
    }

    return <Modal
        showModal={showModal}
        header={!noAction ? translate(`users.users.bulkCreate.step1.headline`) : ""}
        onClose={close}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitActionTitle={translate(`users.users.bulkCreate.step1.startImportButtonText`)}
        closeActionTitle={translate(`cancel`)}
        submitActionColor={color.link}
        disabled={!!(bulkError && bulkError.type)}
        additionalClasses={noAction ? modalClasses.CentralModal : modalClasses.Modal}
        noAction={noAction}>
        {step === 1 && (
            <>
                <input
                    accept=".csv"
                    style={{display: 'none'}}
                    id="raised-button-file"
                    onChange={handleChange}
                    type="file"
                />
                <label htmlFor="raised-button-file">
                    <MButton className={classes.Input} variant="outlined" component="span">
                        {selectedFile?.name || translate(`users.users.bulkCreate.step1.placeholder`)}
                    </MButton>
                    <div className={classes.Or}>{translate(`users.users.bulkCreate.step1.or`)}</div>
                    <Button type="outline" styles={{marginBottom: "20px"}}
                            onClick={downloadExample} color={color.link}
                            title={translate(`users.users.bulkCreate.step1.exampleDownload`)}/>
                </label>
            </>
        )}
        {isDuplicated && (
            <>
                <Typography component="h1" variant="h5" className={classes.ErrorHeader}
                            align="center">{translate(`users.users.bulkCreate.step2.error.generalMessage`)}</Typography>

                <div className={classes.Root}>
                    <LinearProgressWithLabel color="secondary" value={50}/>
                </div>
                <Typography paragraph className={classes.ErrorMessage} align="center">{bulkError?.message}</Typography>
                <Button type="solid"
                        styles={{marginBottom: "20px", display: "block", marginLeft: "auto", marginRight: "auto"}}
                        onClick={downloadExample} color={color.link}
                        title={translate(`users.users.bulkCreate.step1.exampleDownload`)}/>
                <Button type="blank"
                        onClick={reset} color={color.link}
                        styles={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                        title={translate(`users.users.bulkCreate.step2.error.tryAgain`)}/>
            </>
        )}
        {isMalformatted && (
            <>
                <Typography component="h1" variant="h5" className={classes.ErrorHeader}
                            align="center">{translate(`users.users.bulkCreate.step2.error.absoluteMessage`)}</Typography>

                <div className={classes.Root}>
                    <LinearProgressWithLabel color="secondary" value={50}/>
                </div>
                <Typography paragraph className={classes.ErrorMessage} align="center">{bulkError?.message}</Typography>
                <Button type="solid"
                        styles={{marginBottom: "20px", display: "block", marginLeft: "auto", marginRight: "auto"}}
                        onClick={downloadExample} color={color.link}
                        title={translate(`users.users.bulkCreate.step1.exampleDownload`)}/>
                <Button type="blank"
                        onClick={reset} color={color.link}
                        styles={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                        title={translate(`users.users.bulkCreate.step2.error.tryAgain`)}/>
            </>
        )}
        {isDone && (
            <>
                <Typography component="h1" variant="h5" className={classes.ErrorHeader}
                            align="center">{translate(`users.users.bulkCreate.step2.success.message`)}</Typography>
                <div className={classes.Root}>
                    <LinearProgressWithLabel value={100}/>
                </div>
            </>
        )}
        {isCurrentlyLoading && !isDone && (
            <>
                <Typography component="h1" variant="h5" className={classes.ErrorHeader}
                            align="center">{translate(`users.users.bulkCreate.step2.importing`)}</Typography>
                <div className={classes.Root}>
                    <LinearProgressWithLabel value={progress}/>
                </div>
            </>

        )}

    </Modal>;
}
