import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useStyles } from './styles';
import Modal from '../../shared/Modal';
import { color } from '../../../utils/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOnSearchChangeData, Grid } from 'semantic-ui-react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { courseSelector, fetchAll } from '../../../store/courses/courseSlice';
import Avatar from '../../shared/Avatar';
import { APPUCATION_LOGO_AS_BASE64 } from '../../../enums/assignments/assignments-enums';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { fetchUsers, userSelector } from '../../../store/users/userSlice';
import useUserContext from '../../../contexts/UserContext';
import { translate } from '../../../i18n/i18n';
import MultiSelect from '../../shared/MultiSelect';
import axios from '../../../app/axios';
import Button from '../../shared/Button';

interface AssignModalProps {
	showModal: boolean;
	closeModal: () => void;
	onBulkSubmit?: (
		id: number[],
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => void;
	onSubmit?: (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => void;
	message: string;
	confirmText: string;
	selectedCourse?: number;
	isLoading: boolean;
	user?: any;
	users?: number[];
}

export const AssignUserModal: React.FunctionComponent<AssignModalProps> = ({
	showModal,
	closeModal,
	onSubmit,
	message,
	confirmText,
	isLoading,
	user,
	users,
	onBulkSubmit,
	selectedCourse,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { data, isLoading: coursesAreLoading }: any =
		useSelector(courseSelector);
	const { data: userData, isLoading: userIsLoading }: any =
		useSelector(userSelector);
	const [courses, setCourses] = useState<number[]>([]);
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
	const [sendEmailDate, setSendEmailDate] = useState<Date | null>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
		null,
	);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [allUsers, setAllUsers] = useState<any>([]);
	const { user: userContext } = useUserContext();

	// MULTISELECT
	const [multiSelectOptions, setMultiSelectOptions] = useState<any[]>([]);
	const [multiSelectFetching, setMultiSelectFetching] = useState(false);
	const [costsArise, setCostsArise] = useState(false);

	useEffect(() => {
		if (data && data?.data?.length && !multiSelectOptions.length) {
			setMultiSelectOptions(
				data.data.map((course: any) => ({
					key: course.id,
					text: course.name,
					value: course.id,
					image: {
						avatar: true,
						src:
							course.institution_id === null
								? APPUCATION_LOGO_AS_BASE64
								: userContext?.institution?.logo,
					},
				})),
			);
		}
	}, [data]);

	const handleMultiSelectSearch = async (searchValue: string) => {
		setMultiSelectFetching(true);
		if (!!searchValue) {
			try {
				const searchedData = await axios.get(
					`/courses?search=${searchValue}&published=1`,
				);
				const newItems: any = [];
				searchedData.data.success.data.map((course: any) => {
					const hasItem = multiSelectOptions.find(
						(item: any) => item.key === course.id,
					);
					if (!hasItem) {
						newItems.push({
							key: course.id,
							text: course.name,
							value: course.id,
							image: {
								avatar: true,
								src:
									course.institution_id === null
										? APPUCATION_LOGO_AS_BASE64
										: userContext?.institution?.logo,
							},
						});
					}
				});
				setMultiSelectOptions([...multiSelectOptions, ...newItems]);
				setMultiSelectFetching(false);
			} catch (err) {
				setMultiSelectFetching(false);
			}
		} else {
			setTimeout(() => setMultiSelectFetching(false), 500);
		}
	};

	const allCourses = data?.data?.map(function (course: any) {
		return {
			key: course.id,
			text: course.name,
			value: course.id,
			image: {
				avatar: true,
				src:
					course.institution_id === null
						? APPUCATION_LOGO_AS_BASE64
						: userContext?.institution?.logo,
			},
			institution_id: course.institution_id,
		};
	});

	useEffect(() => {
		if (showModal) {
			setCourses([]);
			setSelectedStartDate(null);
			setSelectedEndDate(null);
			setSelectedUser(null);
		}
		if (selectedCourse) {
			setCourses([selectedCourse]);
		}
	}, [showModal]);

	const handleUserChange = (e: any, dropdown: DropdownProps) => {
		setSelectedUser(dropdown.value);
	};

	useEffect(() => {
		if (!userIsLoading && userData && userData.data) {
			const additionalUsers = userData.data.map(function (user: any) {
				return {
					key: user.id,
					text: `${user.first_name} ${user.last_name}`,
					value: user.id,
					image: {
						avatar: true,
						src: user.avatar || '/defaultUser.png',
					},
				};
			});
			setAllUsers([
				...allUsers,
				...additionalUsers.filter(
					(user: any) =>
						!allUsers
							.map((aUser: any) => aUser.key)
							.includes(user.key),
				),
			]);
		}
	}, [userIsLoading, userData]);

	const handleSearchChange = (
		event: SyntheticEvent<HTMLElement, Event>,
		data: DropdownOnSearchChangeData,
	) => {
		dispatch({
			...fetchUsers({
				requestType: 'GET',
				request: `smartometer/users?page=1&limit=10&search=${data.searchQuery}`,
			}),
		});
	};

	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const handleSetSendEmailDate = (date: Date | null) => {
		setSendEmailDate(date);
	};

	const handleSubmit = () => {
		user &&
			onSubmit &&
			onSubmit(
				user.id,
				courses,
				selectedStartDate,
				selectedEndDate,
				sendEmailDate,
			);
		users &&
			onBulkSubmit &&
			onBulkSubmit(
				users,
				courses,
				selectedStartDate,
				selectedEndDate,
				sendEmailDate,
			);
		selectedUser &&
			onSubmit &&
			onSubmit(
				selectedUser,
				courses,
				selectedStartDate,
				selectedEndDate,
				sendEmailDate,
			);
	};

	useEffect(() => {
		const isCostsArise = !!courses?.find((courseKey) =>
			allCourses?.find(
				(courseObj: any) =>
					courseObj.key === courseKey &&
					courseObj.institution_id === 1,
			),
		);
		setCostsArise(isCostsArise);
	}, [courses]);

	return allCourses?.length ? (
		<Modal
			showModal={showModal}
			header={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
			onClose={closeModal}
			onSubmit={handleSubmit}
			isLoading={isLoading}
			submitActionTitle={confirmText}
			closeActionTitle={translate('modals.assignUserModal.close')}
			submitActionColor={color.link}
			disabled={
				isLoading ||
				!(!user || !users || !selectedUser) ||
				!selectedStartDate ||
				!selectedEndDate ||
				!courses.length
			}
		>
			<Grid>
				{user && (
					<Grid.Row>
						<Grid.Column width={16}>
							<Avatar
								src={user?.avatar || '/defaultUser.png'}
								className={classes.Avatar}
								style={{ display: 'inline-block' }}
							/>
							<span className={classes.AvatarName}>
								{user?.first_name} {user?.last_name}
							</span>
						</Grid.Column>
					</Grid.Row>
				)}
				{!user && !users && (
					<Grid.Row>
						<Grid.Column width={16}>
							{!users && !user ? (
								<Dropdown
									className={classes.Singleselect}
									fluid
									name="users"
									id="users"
									onChange={handleUserChange}
									onSearchChange={handleSearchChange}
									value={selectedUser}
									options={allUsers}
									placeholder={translate(
										'modals.assignUserModal.selectUser',
									)}
									search
									selection
								/>
							) : (
								<></>
							)}
						</Grid.Column>
					</Grid.Row>
				)}
				<Grid.Row className={classes.row}>
					<Grid.Column width={16}>
						<MultiSelect
							type="search"
							maxItems={3}
							handleChange={(newCourses: number[]) =>
								setCourses(newCourses)
							}
							inputTitle={translate(
								'modals.addUserModal.placeholder',
							)}
							modalTitle={translate(
								'modals.addUserModal.modalTitle',
							)}
							selected={courses}
							options={multiSelectOptions}
							position="modal"
							handleSearch={handleMultiSelectSearch}
							searchLoading={multiSelectFetching}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className={classes.row}>
					<Grid.Column width={8}>
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={deLocale}
						>
							<KeyboardDatePicker
								maxDate={
									selectedEndDate || new Date(2100, 1, 1)
								}
								margin="normal"
								id="date-picker-start"
								placeholder={translate(
									'modals.assignUserModal.start',
								)}
								format="dd.MM.yyyy"
								value={selectedStartDate}
								onChange={handleStartDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true, disabled: true }}
								autoOk={true}
								className={classes.datepicker}
							/>
						</MuiPickersUtilsProvider>
					</Grid.Column>
					<Grid.Column width={8}>
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={deLocale}
						>
							<KeyboardDatePicker
								minDate={
									selectedStartDate || new Date(2000, 1, 1)
								}
								margin="normal"
								id="date-picker-end"
								placeholder={translate(
									'modals.assignUserModal.end',
								)}
								format="dd.MM.yyyy"
								value={selectedEndDate}
								onChange={handleEndDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true, disabled: true }}
								autoOk={true}
								className={classes.datepicker}
							/>
						</MuiPickersUtilsProvider>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className={classes.row}>
					<Grid.Column width={12}>
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={deLocale}
						>
							<KeyboardDatePicker
								minDate={new Date()}
								maxDate={
									selectedStartDate || new Date(2100, 1, 1)
								}
								margin="normal"
								id="date-picker-start"
								placeholder={translate(
									'modals.assignUserModal.notificationDate',
								)}
								format="dd.MM.yyyy"
								value={sendEmailDate}
								onChange={handleSetSendEmailDate}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true, disabled: true }}
								autoOk={true}
								className={classes.datepicker}
							/>
						</MuiPickersUtilsProvider>
					</Grid.Column>
					<Grid.Column width={4}>
						{sendEmailDate && (
							<Button
								disabled={!sendEmailDate}
								onClick={() => handleSetSendEmailDate(null)}
								styles={{
									marginTop: 16,
									height: 55,
								}}
								title={translate('basics.clear')}
								type="solid"
								color={color.link}
							/>
						)}
					</Grid.Column>
				</Grid.Row>
				{costsArise ? (
					<Grid.Row className={classes.costsArise}>
						<Grid.Column width={16}>
							<p
								dangerouslySetInnerHTML={{
									__html: translate(
										'modals.assignUserModal.costsArise',
									),
								}}
							/>
						</Grid.Column>
					</Grid.Row>
				) : (
					<></>
				)}
			</Grid>
		</Modal>
	) : (
		<></>
	);
};
