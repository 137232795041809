import {makeStyles} from '@material-ui/core/styles'
import {color} from '../../../utils/styles.js'

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: 'white',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fff',
        zIndex: theme.zIndex.drawer + 1,
    },
    colorBar: {
        width: '100%',
        height: '2px',
        position: 'fixed',
        top: "72px",
        zIndex: 9998
    },
    upgradeLink: {
        cursor: "pointer",
        color: color.textDark
    },
    TitleInput: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: '#9C9C9C'
        }
    },
    searchContainer: {

    },
    searchItem: {
        paddingLeft: 20, 
        paddingTop: 15,
        paddingBottom: 15,
        marginBottom: 0,
        fontSize: 18,
        fontWeight: 500, 
        borderBottom: '1.5px solid #E6E7EF',
        // background: '#F7F8FC',
        "&:hover": {
            cursor: 'pointer !important',
            background: '#D0E9FF !important'
        }
    }
}))
