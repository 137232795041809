import { makeStyles } from "@material-ui/core/styles";
import { textSizes, color } from "../../../../../utils/styles"

export const useStyles = makeStyles(theme => ({
    Button: {
        height: "44px",
        borderRadius: "8px",
        padding: "0 23px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: color.link,
        textTransform: "none",
        fontSize: textSizes.bodyNormal,
        transition: ".2s",
        cursor: "pointer",
        border: "1px solid",
        borderColor: color.link,

        "&:hover": {
            background: "transparent"
        }
    },
    ButtonDisabled: {
        opacity: .5,
        cursor: "not-allowed"
    },
    Counter: {
        backgroundColor: color.link,
        color: color.white,
        minWidth: 16,
        height: 16,
        borderRadius: 6,
        fontWeight: 700,
        fontSize: "12px !important",
        lineHeight: "15px",
        textAlign: "center",
        padding: "0 5px"
    },
    CounterDisabled: {
        backgroundColor: color.lightGray
    }
}))
