import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownProps, Grid } from 'semantic-ui-react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import ContentCard from '../../shared/ContentCard';
import Table from '../../shared/Table';
import TableAction from '../../shared/TableAction';
import DeleteModal from '../../modals/DeleteModal';

import { Checkbox } from '@material-ui/core';
import { BulkAction } from '../../shared/BulkAction';
import {
    initialSorting,
    SortingInterface,
} from '../../../interfaces/sorting-interface';
import { useStyles } from './styles';
import { usePagination } from '../../../helpers/usePagination';
import { useBulkFunction } from '../../../helpers/useBulkFunction';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import Avatar from '../../shared/Avatar';
import { useTableSearch } from '../../../helpers/useTableSearch/useTableSearch';
import { AssignUserModal } from '../../modals/AssignUserModal';
import Button from '../../shared/Button';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';
import TableSearch from '../../shared/TableSearch';
import MultiSelect from '../../shared/MultiSelect';
import axios from '../../../app/axios';
import {
    addLicense,
    deleteLicense,
    fetchLicenses,
    licenseSelector,
    updateLicense
} from "../../../store/licenses/licenseSlice";
import {EditLicenseModal} from "../../modals/EditLicenseModal";
import {licenseBulkFunctions, licenseFunctions} from "../../../enums/licenses/licenses-enums";
import {AddLicenseModal} from "../../modals/AddLicenseModal";

interface LicenseOverviewTabProps {
    licenseData: any;
}

const LicenseOverviewTab: React.FunctionComponent<LicenseOverviewTabProps> =
    ({ licenseData }) => {
        const [selectedLicense, setSelectedLicense] = useState<{
            id: number;
        } | null>(null);
        const [shownModal, setShownModal] = useState<string | null>(null);
        const {
            handleSelectAll,
            handleSelect,
            isSelected,
            selected,
            selectedAll,
            setSelected,
        } = useBulkFunction(licenseData);
        const { isLoading, trigger } = useSelector(licenseSelector);
        const [sorting, setSorting] =
            useState<SortingInterface>(initialSorting);
        const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
            null,
        );
        const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
            null,
        );
        const searchProps = useTableSearch();
        const { search } = searchProps;
        const {
            handleChangePage,
            handleChangeRowsPerPage,
            page,
            rowsPerPage,
            handleResetPage,
        } = usePagination();

        const dispatch = useDispatch();
        const history = useHistory();
        const classes = useStyles();

        // MULTISELECT
        const [courses, setCourses] = useState<number[]>([]);
        const [multiSelectOptions, setMultiSelectOptions] = useState<number[]>(
            [],
        );
        const [multiSelectFetching, setMultiSelectFetching] = useState(false);
        const handleMultiSelectSearch = async (searchValue: string) => {
            setMultiSelectFetching(true);
            if (!!searchValue) {
                try {
                    const searchedData = await axios.get(
                        `/courses?search=${searchValue}&published=1`,
                    );
                    const newItems: any = [];
                    searchedData.data.success.data.map((course: any) => {
                        const hasItem = multiSelectOptions.find(
                            (item: any) => item.value === course.id,
                        );
                        if (!hasItem) {
                            newItems.push({
                                text: course.name,
                                value: course.id,
                            });
                        }
                    });
                    setMultiSelectOptions([...multiSelectOptions, ...newItems]);
                    setMultiSelectFetching(false);
                } catch (err) {
                    setMultiSelectFetching(false);
                }
            } else {
                setTimeout(() => setMultiSelectFetching(false), 500);
            }
        };

        useEffect(() => {
            if (!multiSelectOptions.length) {
                axios
                    .get('/courses?limit=10&published=1')
                    .then((res: any) => {
                        setMultiSelectOptions(
                            res.data.success.data.map((course: any) => ({
                                text: course.name,
                                value: course.id,
                            })),
                        );
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            }
        }, []);

        useEffect(() => {
            handleResetPage();
        }, [
            sorting,
            search,
            rowsPerPage,
            selectedStartDate,
            selectedEndDate,
        ]);

        useEffect(() => {
            dispatch({
                ...fetchLicenses({
                    requestType: 'GET',
                    request: `smartometer/licenses?page=${page}&limit=${rowsPerPage}&sort=${
                        sorting.column
                    }&type=${sorting.desc ? `desc` : `asc`}${
                        search !== '' ? `&search=${search}` : ''
                    }${
                        selectedStartDate
                            ? `&start=${format(
                            selectedStartDate,
                            'yyyy-MM-dd',
                            )}`
                            : ''
                    }${
                        selectedEndDate
                            ? `&end=${format(selectedEndDate, 'yyyy-MM-dd')}`
                            : ''
                    }${
                        courses.length > 0
                            ? `&${courses
                                .map((course) => `courses[]=${course}`)
                                ?.join('&')}`
                            : ''
                    }`,
                }),
            });
            resetModals();
        }, [
            sorting,
            search,
            trigger,
            page,
            courses,
            rowsPerPage,
            selectedStartDate,
            selectedEndDate,
        ]);

        const handleEdit = (id: number) => {
            setShownModal(licenseFunctions.EDIT);
            setSelectedLicense({ id });
        };

        const handleDeleteAssignment = () => {
            if (selectedLicense?.id) {
                dispatch({
                    ...deleteLicense({
                        requestType: 'DELETE',
                        request: `smartometer/licenses/${selectedLicense.id}`,
                    }),
                });
            }
            resetModals();
        };

        const handleBulkEdit = (
            event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        ) => {
            switch (event.target.value) {
                case licenseBulkFunctions.DELETE:
                    setShownModal(licenseBulkFunctions.DELETE);
                    break;
                default:
                    break;
            }
        };

        const handleChangeSorting = (column: string) => {
            if (column === sorting.column && sorting.desc)
                setSorting(initialSorting);
            else if (column === sorting.column && !sorting.desc)
                setSorting({ ...sorting, desc: true });
            else if (column !== sorting.column)
                setSorting({ ...sorting, column: column });
        };

        const setToDelete = (id: number) => {
            setShownModal('delete');
            setSelectedLicense({ id });
        };

        const resetModals = () => {
            setShownModal(null);
            setSelectedLicense(null);
        };

        const handleBulkDeleteGroups = () => {
            if (!!selected.length) {
                dispatch({
                    ...deleteLicense({
                        requestType: 'DELETE',
                        body: {
                            assignments: selected,
                        },
                        request: `smartometer/assignments`,
                    }),
                });
                setSelected([]);
                resetModals();
            }
        };

        const rowData = {
            select: {
                renderTitle: (): JSX.Element => (
                    <label className={classes.label}>
                        <Checkbox
                            checked={selectedAll}
                            color="primary"
                            onChange={handleSelectAll}
                        />
                        <span className={classes.selectAll}>
							{translate('tabs.assignmentOverviewTab.selectAll')}
						</span>
                    </label>
                ),
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <Checkbox
                        checked={isSelected(item.id)}
                        color="primary"
                        onClick={(event) => handleSelect(event, item.id)}
                    />
                ),
            },
            avatar: {
                title: '',
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <Avatar
                        src={item.user.avatar || '/defaultUser.png'}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push(`/users/detail/${item.user.id}`)
                        }
                    />
                ),
            },
            name: {
                title: 'Name',
                sorting: 'first_name',
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <p
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push(`/users/detail/${item.user.id}`)
                        }
                    >
                        {item.user.first_name} {item.user.last_name}
                    </p>
                ),
            },
            course: {
                title: 'Kurs',
                sorting: 'product_name',
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <p>{item.product && item.product.name}</p>
                ),
            },
            devices: {
                title: 'Anzahl Geräte',
                sorting: 'device_limit',
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <p>{item.device_limit}</p>
                ),
            },
            start: {
                title: 'Start',
                sorting: 'date_of_activation',
                align: 'left',
                renderType: (item: any): JSX.Element => (
                    <p>
                        {item.date_of_activation &&
                        format(
                            parse(item.date_of_activation, 'yyyy-mm-dd', new Date()),
                            'dd.mm.yyyy',
                        )}
                    </p>
                ),
            },
            end: {
                title: 'Ende',
                sorting: 'time_to_live',
                align: 'left',
                renderType: (item: any): JSX.Element => {

                    return (
                        <p>
                            {item.time_to_live &&
                            format(
                                parse(item.time_to_live, 'yyyy-mm-dd', new Date()),
                                'dd.mm.yyyy',
                            )}
                        </p>
                    );
                },
            },
            edit: {
                title: '',
                align: 'right',
                renderType: (item: any): JSX.Element => {
                    return (
                        <TableAction
                            handleClick={() => handleEdit(item.id)}
                            tooltip={translate('tooltip.edit')}
                            type="edit"
                        />
                    );
                },
            },
            delete: {
                title: '',
                align: 'right',
                renderType: (item: any): JSX.Element => (
                    <TableAction
                        handleClick={() => setToDelete(item.id)}
                        tooltip={translate('tooltip.delete')}
                        type="delete"
                    />
                ),
            },
        };

        const handleStartDateChange = (date: Date | null) => {
            if (date && !isNaN(date.getTime())) {
                setSelectedStartDate(date);
            } else {
                setSelectedStartDate(null)
            }

        };

        const handleEndDateChange = (date: Date | null) => {
            if (date && !isNaN(date.getTime())) {
                setSelectedEndDate(date);
            } else {
                setSelectedEndDate(null)
            }
        };

        const headerComponent = (
            <div className={classes.HeaderComponent}>
                <div className={classes.FilterComponent}>
                    {!selected.length && (
                        <>
                            <TableSearch searchProps={searchProps} />
                            <div style={{ marginLeft: 15 }}>
                                <MultiSelect
                                    bordered
                                    handleChange={setCourses}
                                    inputTitle={translate(
                                        'tabs.groupOverviewTab.course',
                                    )}
                                    modalTitle={translate(
                                        'shared.dashboardFilter.filterByCourse',
                                    )}
                                    selected={courses}
                                    options={multiSelectOptions}
                                    handleSearch={handleMultiSelectSearch}
                                    searchLoading={multiSelectFetching}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={classes.FilterComponent}
                    style={{ marginTop: 15 }}
                >
                    {selected.length > 0 ? (
                        <BulkAction
                            handleBulkEdit={handleBulkEdit}
                            bulkFunctions={licenseBulkFunctions}
                        />
                    ) : (
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                        >
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                placeholder={translate(
                                    'tabs.assignmentOverviewTab.startDate',
                                )}
                                format="dd.MM.yyyy"
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className={classes.datepicker}
                            />

                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                placeholder={translate(
                                    'tabs.assignmentOverviewTab.endDate',
                                )}
                                format="dd.MM.yyyy"
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className={classes.datepicker}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                </div>
            </div>
        );

        const handleCreateSubmit = (
            id: number,
            courses: number[],
            start: Date | null,
            end: Date | null,
            deviceLimit: number
        ) => {
            dispatch({
                ...addLicense({
                    requestType: 'POST',
                    body: JSON.stringify({
                        user_id: id,
                        courses,
                        date_of_activation: start && format(start, 'yyyy-MM-dd'),
                        time_to_live: end && format(end, 'yyyy-MM-dd'),
                        device_limit: deviceLimit
                    }),
                    request: `smartometer/licenses`,
                }),
            });
            resetModals();
        };

        const handleEditSubmit = (
            id: number,
            values: any
        ) => {
            debugger;
            dispatch({
                ...updateLicense({
                    requestType: 'PUT',
                    body: JSON.stringify({
                        time_to_live: values.timeToLive && format(values.timeToLive, 'yyyy-MM-dd'),
                        date_of_activation: values.dateOfActivation && format(values.dateOfActivation, 'yyyy-MM-dd'),
                        device_limit: values.deviceLimit
                    }),
                    request: `smartometer/licenses/${id}`,
                }),
            });
            resetModals();
        };

        return (
            <>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}></Grid.Column>
                        <Grid.Column width={8}>
                            <Button
                                type="solid"
                                styles={{ float: 'right' }}
                                onClick={() =>
                                    setShownModal(licenseFunctions.CREATE)
                                }
                                color={color.link}
                                title={translate(
                                    `tabs.licenseOverviewTab.new`,
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ContentCard
                                title="Lizenzen"
                                headerComponent={headerComponent}
                            >
                                <Table
                                    showHeader
                                    rows={rowData}
                                    data={licenseData.data}
                                    loading={isLoading}
                                    handleChangeSorting={handleChangeSorting}
                                    sorting={sorting}
                                    total={licenseData.total}
                                    handleChangePage={handleChangePage}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </ContentCard>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <AddLicenseModal
                    isLoading={isLoading}
                    showModal={shownModal === licenseFunctions.CREATE}
                    closeModal={resetModals}
                    onSubmit={handleCreateSubmit}
                    confirmText={translate(
                        'tabs.assignmentOverviewTab.addToCourse',
                    )}
                    message={translate(
                        'tabs.assignmentOverviewTab.addSelectedUser',
                    )}
                />
                <EditLicenseModal
                    isLoading={isLoading}
                    license={selectedLicense?.id}
                    showModal={shownModal === licenseFunctions.EDIT}
                    closeModal={resetModals}
                    onSubmit={handleEditSubmit}
                    confirmText={translate('tabs.assignmentOverviewTab.save')}
                    message={translate(
                        'tabs.licenseOverviewTab.new',
                    )}
                />
                <DeleteModal
                    isLoading={isLoading}
                    showModal={!!selectedLicense && shownModal === 'delete'}
                    closeModal={resetModals}
                    onDelete={handleDeleteAssignment}
                    confirmText={translate(
                        'tabs.assignmentOverviewTab.deleteAssignment',
                    )}
                    message={translate(
                        'tabs.assignmentOverviewTab.deleteAssignmentConfirm',
                    )}
                />
                <DeleteModal
                    isLoading={isLoading}
                    showModal={
                        !!selected.length &&
                        shownModal === licenseBulkFunctions.DELETE
                    }
                    closeModal={resetModals}
                    onDelete={handleBulkDeleteGroups}
                    confirmText={translate(
                        'tabs.assignmentOverviewTab.deleteAssignment',
                    )}
                    message={translate(
                        'tabs.assignmentOverviewTab.deleteAllAssignmentsConfirm',
                    )}
                />
            </>
        );
    };

export default LicenseOverviewTab;
