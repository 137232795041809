import React, { useEffect, useRef, useState } from 'react';
import routes from './routes.js';
import PrivateRoute from './helpers/PrivateRoute';
import Blocker from './pages/Blocker';
import { getPlatforms } from '@ionic/react';
import { useHistory } from 'react-router-dom';

const Routing = () => {
	const [isMobile, _setIsMobile] = useState(window.innerWidth < 1070);
	const isMobileRef = useRef(isMobile);
	const setIsMobile = (val: boolean) => {
		isMobileRef.current = val;
		_setIsMobile(val);
	};
	const history = useHistory();

	useEffect(() => {
		const checkIfMobile = () => {
			if (window.innerWidth < 1070 && !isMobileRef.current) {
				setIsMobile(true);
			}
			if (window.innerWidth >= 1070 && isMobileRef.current) {
				setIsMobile(false);
			}
		};
		window.addEventListener('resize', checkIfMobile);
		return () => window.removeEventListener('resize', checkIfMobile);
	}, []);

	const onAccessibleRoute =
		window.location.pathname === '/' ||
		window.location.href.indexOf('/login') > -1 ||
		window.location.href.indexOf('/forgotten-password') > -1 ||
		window.location.href.indexOf('/register') > -1 ||
		window.location.href.indexOf('/app') > -1;

	return (getPlatforms().includes('mobileweb') || isMobile) &&
		!onAccessibleRoute ? (
		<Blocker />
	) : (
		<>
			{routes.map((route, index) => {
				return (
					<PrivateRoute
						key={index}
						path={route.path}
						exact={route.exact}
						desktop={route.desktop}
						component={(props: object) => {
							return (
								<>
									{route.layout && props && (
										<route.layout {...props}>
											{route.component ? (
												<route.component {...props} />
											) : null}
										</route.layout>
									)}
								</>
							);
						}}
					/>
				);
			})}
		</>
	);
};

export default Routing;
