import React, {useState} from "react";
import {initialPaginationState} from "../interfaces/pagination-interface";

export const usePagination = () => {
    const [pagination, setPagination] =  useState(initialPaginationState);


    const handleResetPage = () => {
        setPagination({
            ...pagination,
            page: 1
        })
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPagination({
            ...pagination,
            page: page+1
        })
    };


    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPagination({
            ...pagination,
            rowsPerPage: +event.target.value
        })
    };


    const {page, rowsPerPage} = pagination;

    return {pagination, setPagination, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, handleResetPage};
}
