import {QuestionTypes} from "../../../enums/questions/questions-enums";
import {translate} from '../../../i18n/i18n';

export const formatMultiplePopulateData = (answers: any) => {
    return answers.map((answer: any) => {
        return {
            text: answer.text,
            is_correct: answer.is_correct
        }
    })
}


export const formatMultipleCorrectAnswers = (answers: any) => {
    return answers.filter( (answer: any ) => answer.is_correct)
        .map((option: any) => {
            return {
                [option.text]: true
            }
        })
}

export const formatMultipleUpdateData = (options:any, name: string) => {
    const bodyOptions = options.map((option: any) => {
        return {
            text: option.text,
            is_correct: option.is_correct
        }
    })

    return  {
        question: name,
        type: QuestionTypes.MULTIPLE_CHOICE,
        answers: bodyOptions
    }
}

export const formatOrderWordsUpdateData = (options:any, name: string) => {
    const bodyOptions = options.map((option: any, index: number) => {
        return {
            text: option.text,
            order: index+1,
            is_correct: true
        }
    })
    return  {
        question: name,
        type: QuestionTypes.ORDER_WORDS,
        answers: bodyOptions
    }
}



export const validateMultipleUpdateData = (name:string, answers:any, correctAnswer:string) => {
    let errors = []
    const findCorrectAnswers = answers.filter( (answer: any ) => answer.is_correct == true)
    if (!name) {
        errors.push(translate('helpers.multipleQuestions.nameValidation'))
    }
    if (!answers.length) {
        errors.push(translate('helpers.multipleQuestions.questionValidation'))
    }
    if (!findCorrectAnswers.length) {
        errors.push(translate('helpers.multipleQuestions.answerValidation'))
    }

    return errors
}
