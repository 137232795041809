import React from 'react';
import {Grid} from "semantic-ui-react";
import axios from "../../../app/axios";
import { Divider } from 'semantic-ui-react'

import ContentCard from "../../shared/ContentCard";
import { useStyles } from "./styles";
import {translate} from "../../../i18n/i18n";


export default function CertificateTab() {

    const classes = useStyles();

    const downloadExampleCertificate = async () => {
        let data = {
            course_title: 'my course name',
            start_date: '22-02-2020',
            end_date: '28-04-2020'
        }
        await axios({
            method: 'post',
            url: '/certificate/example',
            data,
            responseType: 'blob'
        })
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'CertificateExample.pdf');
                document.body.appendChild(link);
                link.click();
            });
    }
    return (
        <>
            <Grid>
               <Grid.Row>
                    <Grid.Column width={16}>
                        <ContentCard title={translate('tabs.certificateTab.certificateTitle')}>
                        <Divider className={ classes.Divider }/>
                        <Grid className={ classes.Container }>
                            <Grid.Column width={8} className={ classes.TitleContainer }>
                            {translate('tabs.certificateTab.certificateTemplate')}
                            </Grid.Column>
                            <Grid.Column width={8} className={ classes.LinkContainer }>
                                <a className={ classes.Link } onClick={() => downloadExampleCertificate() }> {translate('tabs.certificateTab.preview')} </a>
                            </Grid.Column>
                        </Grid>
                        </ContentCard>
                    </Grid.Column>
                </Grid.Row>
            </Grid> 
        </>
    );
}