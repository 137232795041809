import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	Checkbox,
	FormControlLabel,
	SvgIcon,
	TextField,
} from '@material-ui/core';
import { Form } from 'semantic-ui-react';

import { loginSelector, register } from '../Login/loginSlice';
import { useStyles } from '../styles';
import { translate } from '../../../i18n/i18n';
import { validationSchema } from './validationSchema';
import { RegisterInterface } from '../../../interfaces/register-interface';

var appUrl =
	window.location.protocol +
	'//' +
	window.location.hostname +
	(window.location.port ? ':' + window.location.port : '');

const Register = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { isSuccessEmail, errorMessage } = useSelector(loginSelector);

	useEffect(() => {
		if (!!errorMessage) formik.setSubmitting(false);
	}, [errorMessage]);

	const onSubmit = (values: RegisterInterface) => {
		const body: {
			request_url: string;
			email: string;
			first_name: string;
			last_name: string;
			requester: 'smartometer';
			product_id?: number;
		} = {
			request_url: appUrl,
			email: values.email,
			first_name: values.firstname,
			last_name: values.lastname,
			requester: 'smartometer',
		};
		if (process.env.REACT_APP_COURSE_ID)
			body.product_id = Number(process.env.REACT_APP_COURSE_ID);

		dispatch({
			...register({
				body,
				request: '/smartometer/register',
			}),
		});
	};

	const initialValues: RegisterInterface = {
		firstname: '',
		lastname: '',
		email: '',
		acceptTerms: false,
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const handleValidateEnter = (e: React.KeyboardEvent) => {
		if (
			e.keyCode === 13 &&
			!!formik.values.email &&
			!!formik.values.firstname &&
			!!formik.values.acceptTerms &&
			!!formik.values.lastname &&
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				formik.values.email.trim(),
			)
		) {
			formik.handleSubmit();
		}
	};

	return (
		<>
			<h1 className={classes.title}>
				{translate('authentication.register.header')}
			</h1>
			{!isSuccessEmail ? (
				<p className={`${classes.subTitle} auth-subtitle`}>
					{translate('authentication.subhead')}
				</p>
			) : (
				<p className={classes.subTitle}>
					{translate('authentication.checkMail')}
				</p>
			)}

			<p className={classes.ErrorMessage}>{errorMessage}</p>
			{!isSuccessEmail ? (
				<Form
					onSubmit={formik.handleSubmit}
					style={{ width: '100%' }}
					size="big"
				>
					<TextField
						error={!!errorMessage}
						label={translate(`authentication.register.firstname`)}
						variant="outlined"
						name="firstname"
						className={classes.TextField}
						value={formik.values.firstname}
						onChange={formik.handleChange}
						onKeyDown={(e) => handleValidateEnter(e)}
					/>
					<TextField
						error={!!errorMessage}
						label={translate(`authentication.register.lastname`)}
						variant="outlined"
						name="lastname"
						className={classes.TextField}
						value={formik.values.lastname}
						onChange={formik.handleChange}
						onKeyDown={(e) => handleValidateEnter(e)}
					/>
					<TextField
						error={!!errorMessage}
						label={translate(`authentication.register.email`)}
						variant="outlined"
						name="email"
						className={classes.TextField}
						value={formik.values.email}
						onChange={formik.handleChange}
						onKeyDown={(e) => handleValidateEnter(e)}
					/>
					<FormControlLabel
						className={classes.CheckboxLabel}
						control={
							<Checkbox
								disableRipple
								disableFocusRipple
								disableTouchRipple
								checked={formik.values.acceptTerms}
								name="acceptTerms"
								onChange={formik.handleChange}
							/>
						}
						label={
							<label
								dangerouslySetInnerHTML={{
									__html: translate(
										`authentication.register.acceptance`,
									),
								}}
							/>
						}
					/>

					<button
						type="submit"
						className={classes.SubmitButton}
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{translate(`authentication.registerRedirect`)}
					</button>
				</Form>
			) : (
				<SvgIcon className={classes.CheckIcon}>
					<path
						id="checkbox-marked-circle-outline"
						d="M23.6,14a9.608,9.608,0,1,1-6.96-9.228l1.884-1.884A11.786,11.786,0,0,0,14,2,12,12,0,1,0,26,14M9.092,11.7,7.4,13.4l5.4,5.4,12-12L23.108,5.1,12.8,15.4Z"
						transform="translate(-2 -2)"
					/>
				</SvgIcon>
			)}
			<div className={classes.PageLink}>
				<p>
					{!isSuccessEmail &&
						translate('authentication.alreadyHaveAccount') + ' '}
					<Link
						className={`${classes.link} auth-text-link`}
						to="/auth/login"
					>
						{translate('authentication.logIn')}
					</Link>
				</p>
			</div>
		</>
	);
};

export default Register;
