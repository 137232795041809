import React, {useContext, useEffect, useState} from "react";
import AuthoringNavbar from "../components/layout/AuthoringNavbar";
import AuthoringSidebar from "../components/layout/AuthoringSidebar";
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import LoadingOverlay from "../components/shared/LoadingOverlay";
import axios from "../app/axios";
import ConfirmModal from "../components/modals/ConfirmModal";
import '@nosferatu500/react-sortable-tree/style.css';

import {fetchSubareas, subAreaSelector, unsetSubareaData} from '../store/subareas/subareaSlice';
import {
    courseSelector,
    fetchCourseQuestionCount,
    fetchCurrentCourse,
    fetchCurrentCourseAuthoring,
    unsetCourseData
} from '../store/courses/courseSlice';
import AuthoringSettings from "../pages/Authoring/Settings";
import SectionPage from "../pages/Authoring/SectionPage";
import AllQuestions from "../pages/Authoring/AllQuestions";
import CourseDetailPage from "../pages/Authoring/CourseDetailPage";
import {translate} from "../i18n/i18n";
import {UserContext} from "../contexts/UserContext";

const AuthoringLayout = () => {
    const dispatch = useDispatch();
    const {user, triggerRefetch} = useContext(UserContext);
    const history = useHistory();
    const [modal, setModal] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isSubmitting, setIsSubmittingInternal] = useState(false);
    const [selectedSubarea, setSelectedSubarea] = useState(null);
    const {isLoading, data: subareaData, isFetching: subareasFetching} = useSelector(subAreaSelector);
    const {current: currentCourse, isLoading: currentCourseIsLoading, courseSubareas} = useSelector(courseSelector);
    const {courseId, page} = useParams();
    useEffect(() => {
        if (!window.localStorage.getItem('user') || window.localStorage.getItem('userType') !== 'SOM') {
            history.push('/auth/login');
        }
    });
    useEffect(() => {
        if (!user) {
            triggerRefetch();
        }
    }, [user]);

    useEffect(() => {
        // Show freshdeks button
        var element = document.getElementById("freshdesk");
        element.classList.remove("hide");
        element.innerHTML = translate('help');
    }, []);

    useEffect(() => () => {
        // Unmound hide freshdesk
        var element = document.getElementById("freshdesk");
        document.getElementById("close_freshdesk").click();
        element.classList.add("hide");
    }, []);

    useEffect(() => {
        if (courseId) {
            dispatch({
                ...fetchCurrentCourseAuthoring({
                    requestType: 'GET',
                    request: `smartometer/courses/for-authoring-tool/${courseId}`,
                }),
            });
            dispatch({
                ...fetchCourseQuestionCount({
                    requestType: 'GET',
                    request: `smartometer/courses/${courseId}`,
                }),
            });
            dispatch({
                ...fetchSubareas({
                    requestType: 'GET',
                    request: `smartometer/courses/${courseId}/subarea`,
                }),
            });
        } else {
            setIsFetching(false)
        }
        return () => {
            dispatch({...unsetCourseData()});
            dispatch({...unsetSubareaData()});
        }
    }, [])


    useEffect(() => {
        if (currentCourse && courseId !== currentCourse.id && !courseId) {
            history.replace(`/authoring/${currentCourse.id}`);
        }
    }, [currentCourse])

    useEffect(() => {
        if (subareaData) {
            setIsFetching(false);
            if (subareaData.length) {
                const currentSubarea = subareaData.find(item => item.id === +page);
                if (!currentSubarea) {
                    history.replace(`/authoring/${courseId}`);
                    setSelectedSubarea(null);
                } else setSelectedSubarea(subareaData.indexOf(currentSubarea))
            }
        }
    }, [subareaData])

    const refetchSubareas = () => {
        dispatch({
            ...fetchSubareas({
                requestType: 'GET',
                request: `smartometer/courses/${courseId}/subarea`,
            }),
        })
    }

    const handleCreateEmptySubarea = async (parentId) => {
        const newSubarea = await axios.post(`/courses/${courseId}/subarea`, {
            headline: "Abschnitt",
            sub_area_id: parentId || null
        });
        history.replace(`/authoring/${courseId}/${newSubarea.data.success.id}`)
        refetchSubareas();
    }

    const handleCourseChanges = async (type, val) => {

        if (currentCourse[type] === val || !currentCourse[type] && !val) return;
        setIsSubmittingInternal(true)
        await axios.put(`courses/${courseId}`, {[type]: val});
        setIsSubmittingInternal(false);
        dispatch({
            ...fetchCurrentCourse({
                requestType: 'GET',
                request: `smartometer/courses/${courseId}`,
            }),
        });
        dispatch({
            ...fetchSubareas({
                requestType: 'GET',
                request: `smartometer/courses/${courseId}/subarea`,
            })
        });
        if (!!modal) setModal(null);
    }

    const handleNavigateToSubarea = (index, id) => {
        setSelectedSubarea(index);
        history.push(`/authoring/${courseId}/${id}`);
    }

    const navigateToPage = page => {
        setSelectedSubarea(null);
        if (page === "home") history.replace(`/authoring/${courseId}`);
        if (page === "settings") history.replace(`/authoring/${courseId}/settings`);
        if (page === "all-questions") history.replace(`/authoring/${courseId}/all-questions`);
    }

    const renderComponent = () => {
        if (!courseId || (!!courseId && !page)) {
            return <CourseDetailPage/>;
        } else {
            if (page === "settings") {
                return <AuthoringSettings/>
            } else if (page === "all-questions") {
                return <AllQuestions/>
            } else {
                return <SectionPage handleCreateSubarea={handleCreateEmptySubarea}/>
            }
        }
    }

    return (
        <>
            <div style={{display: 'flex'}}>
                <AuthoringNavbar courseId={currentCourse?.id || null}
                                 disableActions={!currentCourse}
                                 selectedSubarea={subareaData[selectedSubarea]}
                                 handlePublish={() => setModal("publish")}
                                 handleNavigate={navigateToPage}
                                 name={currentCourse?.name || ""}
                                 isPublished={currentCourse?.published || false}/>
                <AuthoringSidebar
                    refetchData={() => refetchSubareas()}
                    disableActions={!currentCourse || isLoading}
                    handleCreateArea={handleCreateEmptySubarea}
                    handleNavigate={navigateToPage}
                    handleNavigateToSubarea={handleNavigateToSubarea}
                    subareas={subareaData}
                    subarea={subareaData[selectedSubarea]}
                    nestedSubareas={courseSubareas}/>
                <main style={{
                    overflowY: "auto",
                    flexGrow: 1,
                    padding: "148px 30px 30px",
                    background: '#F7F8FC',
                    height: '100vh',
                    position: "relative"
                }}>
                    {
                        renderComponent()
                    }
                    <LoadingOverlay isFetching={subareasFetching || isFetching}/>

                    <ConfirmModal
                        additionalInfo={translate('authoring.layoutsAuthoring.youCanRevert')}
                        isLoading={isSubmitting}
                        showModal={modal === "publish"}
                        closeModal={() => setModal(null)}
                        onDelete={() => handleCourseChanges("published", 1)}
                        confirmText={translate('authoring.layoutsAuthoring.publishNow')}
                        message={`${translate('authoring.layoutsAuthoring.publishVerify')} <b>${currentCourse?.name || ""}</b> ${translate('authoring.layoutsAuthoring.publishVerifyEnd')}`}/>
                </main>
            </div>
        </>
    );
}

export default AuthoringLayout;
