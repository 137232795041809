import React from 'react';
import PreviewListItem from '../../../authoring/Preview/PreviewListItem';
import FinishButton from '../../FinishButton';
import { translate } from '../../../../i18n/i18n';
import { useSelector } from 'react-redux';
import { appSelector, setCustomFontSize } from '../../../../store/app/appSlice';

interface GeneralContentProps {
	handleNavigate: Function;
	subarea: any;
	parentName: string;
	nextSubarea?: any;
}

const GeneralContent: React.FunctionComponent<GeneralContentProps> = ({
	handleNavigate,
	subarea,
	parentName,
	nextSubarea,
}) => {
	const { customFontSize } = useSelector(appSelector);
	const switchToNextItem = () => {
		let nextParentId = nextSubarea.sub_area_id;
		if (subarea.id === nextSubarea.sub_area_id) {
			// next subarea is child of current subarea (parent id stays the same)
			nextParentId = subarea.id;
		}
		if (nextSubarea.subareas.length) {
			// next subarea has subareas on its own!
			nextParentId = nextSubarea.id;
		} else if (nextSubarea.sub_area_id === subarea.sub_area_id) {
			// next item is regular sibling
			nextParentId = nextSubarea.sub_area_id;
		}
		document!.querySelector('.app-content-container')!.scrollTo(0, 0);

		handleNavigate(`/app/learn/${nextParentId || ''}`, {
			navigation: '0',
			content: nextSubarea.id.toString(),
		});
	};

	const switchToFirst = () => {
		document!.querySelector('.app-content-container')!.scrollTo(0, 0);

		handleNavigate(`/app/learn/`, {
			navigation: '0',
			content: -1,
		});
	};

	const extractContent = (s: string) => {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText;
	};

	const calculateEstimateTimeRequired = (wysiwyg: any) => {
		return Math.ceil(extractContent(wysiwyg).split(' ').length / 200);
	};

	return (
		<>
			<div className="app-content-general">
				<div className="intro">
					<h4>{parentName}</h4>
					<h2>{subarea!.headline}</h2>
					<p
						style={{
							marginBottom: 12,
							color: '#a8a8a8',
						}}
					>
						{calculateEstimateTimeRequired(subarea!.wysiwyg)}{' '}
						{translate(`app.course.readingTime`)}
					</p>
				</div>
				<div
					style={{
						fontSize: `${customFontSize}rem`,
						lineHeight: '1.5em',
					}}
					dangerouslySetInnerHTML={{
						__html: subarea!.wysiwyg,
					}}
				></div>
			</div>
			{nextSubarea ? (
				<div className="preview-window-content-next app-content-next app-intro-subareas">
					<h3>{translate(`app.course.nextContent`)}</h3>
					<PreviewListItem
						item={nextSubarea}
						nosubareas
						handleClick={switchToNextItem}
						parentId={null}
						active={false}
					/>
				</div>
			) : (
				<div className="preview-window-content-next app-content-next app-intro-subareas">
					<h3>{translate(`app.course.courseEnd`)}</h3>
					<FinishButton handleClick={switchToFirst} />
				</div>
			)}
		</>
	);
};

export default GeneralContent;
