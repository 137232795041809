import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import TabNavigator from '../../components/shared/TabNavigator';

import {useDispatch} from 'react-redux';
import {translate} from '../../i18n/i18n';
import {UserContext} from "../../contexts/UserContext";
import InstitutionsOverviewTab from "../../components/tabs/InstitutionsOverviewTab";

export default function Institutions() {
    const dispatch = useDispatch();
    const {user} = useContext(UserContext);

    const tabs = {
        institutions: {
            title: translate('institutions.institutions.headline'),
            component: <InstitutionsOverviewTab/>,
        },
    };

    return (
        <div style={{padding: '96px 30px 100px'}}>
            <Typography
                style={{marginBottom: 32}}
                variant="h4"
                component="h4"
            >
                {translate('institutions.headline')}
            </Typography>

            <TabNavigator tabs={tabs} defaultActive="institutions"/>
        </div>
    );
}
