import React, { useEffect, useState } from 'react';

import Modal from '../../shared/Modal';
import { color } from '../../../utils/styles';
import { translate } from '../../../i18n/i18n';
import axios from '../../../app/axios';
import MultiSelect from '../../shared/MultiSelect';

interface AssignModalProps {
	showModal: boolean;
	closeModal: () => void;
	onSubmit: (selectedUsers: number[]) => void;
	currentUsers: any[];
	isLoading: boolean;
}

export const AddUserToGroupModal: React.FunctionComponent<AssignModalProps> = ({
	showModal,
	closeModal,
	currentUsers,
	isLoading,
	onSubmit,
}) => {
	const [users, setUsers] = useState<any[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
	const [multiSelectFetching, setMultiSelectFetching] = useState(false);

	const handleMultiSelectSearch = async (searchValue: string) => {
		setMultiSelectFetching(true);
		if (!!searchValue) {
			try {
				const searchedData = await axios.get(
					`/users?search=${searchValue}`,
				);
				const newItems: any = [];
				searchedData.data.success.data.map((user: any) => {
					const hasItem = users.find(
						(item: any) => item.value === user.id,
					);

					if (!hasItem && currentUsers.indexOf(user.id) === -1) {
						newItems.push({
							text: `${user.first_name} ${user.last_name}, ${user.email}`,
							value: user.id,
						});
					}
				});
				setUsers([...users, ...newItems]);
				setMultiSelectFetching(false);
			} catch (err) {
				setMultiSelectFetching(false);
			}
		} else {
			setTimeout(() => setMultiSelectFetching(false), 500);
		}
	};

	useEffect(() => {
		if (showModal) {
			setSelectedUsers([]);
		}
	}, [showModal]);

	useEffect(() => {
		if (!!currentUsers) {
			axios
				.get('/users?limit=10')
				.then((res: any) => {
					setUsers(
						res.data.success.data
							.filter(
								(user: { id: number }) =>
									currentUsers.indexOf(user.id) === -1,
							)
							.map((user: any) => ({
								text: `${user.first_name} ${user.last_name}, ${user.email}`,
								value: user.id,
							})),
					);
				})
				.catch((err: any) => {
					console.log(err);
				});
		}
	}, [currentUsers]);

	return !!users.length ? (
		<Modal
			showModal={showModal}
			header={translate('modals.addUserToGroupModal.addUsersMessage')}
			onClose={closeModal}
			onSubmit={() => onSubmit(selectedUsers)}
			isLoading={isLoading}
			submitActionTitle={translate('modals.addUserToGroupModal.confirm')}
			closeActionTitle={translate('modals.addUserToGroupModal.close')}
			submitActionColor={color.link}
			disabled={isLoading}
		>
			<MultiSelect
				type="search"
				maxItems={3}
				handleChange={(users: number[]) => setSelectedUsers(users)}
				inputTitle={translate('modals.addUserToGroupModal.placeholder')}
				modalTitle={translate('modals.addUserToGroupModal.modalTitle')}
				selected={selectedUsers}
				options={users}
				position="modal"
				handleSearch={handleMultiSelectSearch}
				searchLoading={multiSelectFetching}
			/>
		</Modal>
	) : null;
};
