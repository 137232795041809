import { makeStyles } from '@material-ui/core/styles';
import { color, textSizes } from '../../../utils/styles.js';

export const useStyles = makeStyles((theme) => ({
	SectionPage: {
		position: 'relative',
		paddingTop: 50,
	},
	TitleInput: {
		fontWeight: 700,
		fontSize: 32,
		lineHeight: '44px',
		color: color.textDark,
	},
	inputGroup: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 34,
	},
    inputNumber: {
        minWidth: 224
    },
	Error: {
		fontSize: textSizes.notice,
		color: color.red,
	},
	Label: {
		width: 200,
		fontWeight: 400,
		fontSize: 16,
		color: color.textDark,
		marginRight: 26,
	},
	radioGroup: {
		flexDirection: 'initial',
	},
	Title: {
		fontWeight: 400,
		fontSize: 24,
		color: color.textDark,
	},
	SubTitle: {
		fontWeight: 600,
		fontSize: 18,
		color: color.textDark,
		marginTop: 50,
		marginBottom: 18,
	},
}));
