import {makeStyles} from '@material-ui/core/styles';
import {color, textSizes} from '../../../../utils/styles';

export const useStyles = makeStyles(theme => ({
    ListItem: {
        width: "100%",
        height: "24px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        marginBottom: 16
    },
    ListNumber: {
        background: color.link,
        color: color.white,
        width: "20px",
        height: "20px",
        borderRadius: "20px",
        textAlign: "center",
        fontSize: textSizes.bodySmall,
        lineHeight: "20px",
        fontWeight: 600,
        marginRight: 10

    },
    ListWrapper: {
        paddingLeft: 0
    },
    label: {
        position: "relative"
    },
    selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
    deleteWrapper: {
        height: "24px !important",
        "&:hover": {
            cursor: 'pointer !important',
        }
    },
    TitleInput: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "44px",
        color: color.textDark,

        "&::placeholder": {
            color: '#9C9C9C'
        }
    },
    errorLabel: {
        marginLeft: 40,
        fontSize: 13,
        color: '#f44336'
    },
}));
