import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppActionModal from '../../../ActionModal';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
// import {Button} from 'semantic-ui-react'
import { useStyles } from './styles';
import axios from '../../../../../app/axios';
import { Message } from 'semantic-ui-react';

import Button from '../../../../../components/shared/Button';
import { color } from '../../../../../utils/styles';

import { translate } from '../../../../../i18n/i18n';

interface AppProfileChangePasswordModalProps {
	showModal: boolean;
	closeModal: () => void;
	handleSuccess: (text: string) => void;
}

interface ChangePasswordInterface {
	oldPassword: string;
	password: string;
	confirm_password: string;
}

const AppProfileChangePasswordModal: React.FunctionComponent<
	AppProfileChangePasswordModalProps
> = ({ showModal, closeModal, handleSuccess }) => {
	const history = useHistory();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const initialValues: ChangePasswordInterface = {
		oldPassword: '',
		password: '',
		confirm_password: '',
	};

	const onSubmit = async (values: ChangePasswordInterface) => {
		setErrorMessage(false);
		setIsLoading(true);
		await axios
			.post(`/app/user/change-password`, values)
			.then(function (response) {
				setErrorMessage(false);
				setIsLoading(false);
				closeModal();
				formik.resetForm();
				handleSuccess(translate('app.successModal.PasswordSuccess'));
			})
			.catch(function (error) {
				setErrorMessage(true);
				setIsLoading(false);
				console.log(error);
			});
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const form: JSX.Element = (
		<div style={{ marginTop: 50 }}>
			<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
				<div style={{ width: '100%', marginBottom: 40 }}>
					<TextField
						value={formik.values.oldPassword}
						onChange={formik.handleChange}
						className="app-input"
						style={{ width: '100%', marginBottom: 2 }}
						label={translate(
							'app.profile.ChangePassword.oldPassword',
						)}
						type="password"
						name="oldPassword"
					/>
					<span className={classes.InputError}>
						{(formik.values.oldPassword &&
							formik.errors.oldPassword) ||
							''}
					</span>
				</div>

				<div style={{ width: '100%', marginBottom: 40 }}>
					<TextField
						value={formik.values.password}
						onChange={formik.handleChange}
						className="app-input"
						style={{ width: '100%', marginBottom: 2 }}
						label={translate(
							'app.profile.ChangePassword.newPassword',
						)}
						type="password"
						name="password"
					/>
					<span className={classes.InputError}>
						{(formik.values.password && formik.errors.password) ||
							''}
					</span>
				</div>

				<div style={{ width: '100%', marginBottom: 40 }}>
					<TextField
						value={formik.values.confirm_password}
						onChange={formik.handleChange}
						className="app-input"
						style={{ width: '100%', marginBottom: 2 }}
						label={translate(
							'app.profile.ChangePassword.confirmPassword',
						)}
						type="password"
						name="confirm_password"
					/>
					<span className={classes.InputError}>
						{(formik.values.confirm_password &&
							formik.errors.confirm_password) ||
							''}
					</span>
				</div>
				{errorMessage && (
					<Message
						error
						content={translate(
							'app.profile.ChangePassword.networkingIssues',
						)}
					/>
				)}
				<div
					className="modal-button-wrapper"
					style={{
						width: '100%',
						maxWidth: 500,
						position: 'fixed',
						bottom: 15,
						paddingRight: 40,
					}}
				>
					<Button
						title={translate('app.profile.ChangePassword.submit')}
						type="solid-fluid"
						disabled={
							!formik.values.oldPassword ||
							!formik.values.password ||
							!formik.values.confirm_password
						}
						color={color.link}
						onClick={formik.handleSubmit}
						isLoading={isLoading}
					/>
				</div>
			</form>
			<div></div>
		</div>
	);

	return (
		<div>
			<AppActionModal
				showModal={showModal}
				closeModal={closeModal}
				title={translate('app.profile.ChangePassword.title')}
				slot={form}
				description={translate(
					'app.profile.ChangePassword.description',
				)}
			/>
		</div>
	);
};

export default AppProfileChangePasswordModal;
