import React from 'react';
import DeviceToggleButton from './components/DeviceToggleButton';
import OrientationButton from './components/OrientationButton';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';

enum OrientationEnum {
	portrait = 'portrait',
	landscape = 'landscape',
}

enum DeviceEnum {
	mobile = 'mobile',
	tablet = 'tablet',
	desktop = 'desktop',
}

interface PreviewHeaderProps {
	orientation: OrientationEnum;
	device: DeviceEnum;
	handleClose: React.MouseEventHandler;
	handleUpdateParams: Function;
}

const PreviewHeader: React.FunctionComponent<PreviewHeaderProps> = ({
	orientation,
	device,
	handleClose,
	handleUpdateParams,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.HeaderBar}>
			<h2>Vorschau</h2>
			<div className={classes.ButtonWrapper}>
				<DeviceToggleButton
					type="mobile"
					active={device}
					handleSetDevice={handleUpdateParams}
				/>
				<DeviceToggleButton
					type="tablet"
					active={device}
					handleSetDevice={handleUpdateParams}
				/>
				<DeviceToggleButton
					type="desktop"
					active={device}
					handleSetDevice={handleUpdateParams}
				/>
				<OrientationButton
					device={device}
					handleSetParams={handleUpdateParams}
					active={orientation}
				/>
			</div>
			<CloseIcon onClick={handleClose} />
		</div>
	);
};

export default PreviewHeader;
