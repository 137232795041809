import { makeStyles } from "@material-ui/core/styles";
import {color, textSizes} from "../../../../utils/styles";
export const useStyles = makeStyles(theme => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    label: {
        position: "relative"
    },
    selectAll: {
        position: "absolute",
        top: 0,
        width: 100
    },
}))
