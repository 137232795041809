import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, DropdownProps, Grid } from 'semantic-ui-react';
import { format } from 'date-fns';
import ContentCard from '../../../components/shared/ContentCard';
import TabNavigator from '../../../components/shared/TabNavigator';
import Subpage from '../../../components/shared/Subpage';
import ContentCardNumber from '../../../components/shared/ContentCardNumber';
import {
	clearUserDetails,
	fetchUser,
	fetchUserKPIs,
	fetchUserKPIsPerCourse,
	userSelector,
} from '../../../store/users/userSlice';
import { fetchGroups, groupSelector } from '../../../store/groups/groupSlice';
import { useStyles } from './style';
import Avatar from '../../../components/shared/Avatar';
import Button from '../../../components/shared/Button';
import { color } from '../../../utils/styles';
import UserCourses from './Courses';
import {
	addAssignment,
	assignmentSelector,
} from '../../../store/assignments/assignmentSlice';
import UserStatistics from './Statistics';
import { APPUCATION_LOGO_AS_BASE64 } from '../../../enums/assignments/assignments-enums';
import useUserContext from '../../../contexts/UserContext';
import { translate } from '../../../i18n/i18n';
import { useTracking } from '../../../helpers/useTracking';
import DuoBar from '../../../components/shared/Statistics/DuoBar';
import { LinearProgress } from '@material-ui/core';
import { AssignUserModal } from '../../../components/modals/AssignUserModal';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import EditUserDataModal from '../../../components/modals/EditUserDataModal';
import axios from '../../../app/axios';

interface UserDetailPageParams {
	id: string;
}

export default function UsersDetailPage() {
	const dispatch = useDispatch();
	const { user: userContext } = useUserContext();
	const params: UserDetailPageParams = useParams();
	const {
		data: userData,
		selectedUser,
		selectedUserKPIs: kpis,
		selectedUserCourseKPIs: courseKpis,
		isLoading,
	}: any = useSelector(userSelector);
	const { isLoading: assignemntsLoading } = useSelector(assignmentSelector);
	const { data: groupData }: any = useSelector(groupSelector);
	const [courses, setCourses] = useState<any>();
	const [assignments, setAssignments] = useState<any>();
	const [assignmentsModal, setAssignmentsModal] = useState<any>();
	const [resetPasswordModal, setResetPasswordModal] = useState<any>();
	const [isSubmitting, setIsSubmitting] = useState<any>();
	const [editUserModal, setEditUserModal] = useState<any>();

	const { getLearningTime } = useTracking();
	const classes = useStyles();

	useEffect(
		() => () => {
			dispatch(clearUserDetails());
		},
		[],
	);

	useEffect(() => {
		if (kpis?.assignedCourses?.length) {
			kpis.assignedCourses.forEach((item: any) => {
				dispatch({
					...fetchUserKPIsPerCourse({
						requestType: 'GET',
						request: `smartometer/results/by-assignment/${item.id}/${params.id}`,
					}),
				});
			});
		}
	}, [kpis, params.id]);

	useEffect(() => {
		if (!assignemntsLoading) {
			dispatch({
				...fetchUser({
					requestType: 'GET',
					request: `smartometer/users/${params.id}`,
				}),
			});
			if (!groupData.data) {
				dispatch({
					...fetchGroups({
						requestType: 'GET',
						request: 'smartometer/groups',
					}),
				});
			}
			if (!kpis) {
				dispatch({
					...fetchUserKPIs({
						requestType: 'GET',
						request: `smartometer/resultsByUser/${params.id}`,
					}),
				});
			}
		}
	}, [assignemntsLoading, params.id]);

	useEffect(() => {
		if (courses && selectedUser.assignments) {
			const assignments = selectedUser.assignments.filter(
				(assignment: any) => assignment.pivot.product_id === courses,
			);
			setAssignments(assignments);
		} else if (selectedUser.assignments) {
			setAssignments(selectedUser.assignments);
		}
	}, [courses, selectedUser]);

	const userGroup =
		selectedUser?.groupNames && selectedUser?.groupNames.length > 0
			? selectedUser.groupNames.map((group: any) => group).join(', ')
			: translate('users.detail.none');

	const averageLearningTime =
		selectedUser.assignments && !!selectedUser.assignments.length
			? getLearningTime(selectedUser.trackings) /
			  selectedUser.assignments.length
			: 0;

	const hours = averageLearningTime && Math.floor(averageLearningTime / 3600);
	const min =
		averageLearningTime && Math.floor((averageLearningTime % 3600) / 60);
	const averageLearningTimeAsString = `${hours > 0 ? `${hours}h ` : ''}${`${
		min >= 0 ? `${min}min ` : ''
	}`}`;

	const allCourses: any[] = [];

	selectedUser?.assignments?.forEach(function (assignment: any) {
		const foundItem = allCourses.find(
			(item: any) => item.key === assignment.id,
		);
		if (!foundItem) {
			allCourses.push({
				key: assignment.pivot.id,
				text: assignment.name,
				value: assignment.pivot.id,
				image: {
					avatar: true,
					src:
						assignment.institution_id === null
							? APPUCATION_LOGO_AS_BASE64
							: userContext?.institution?.logo,
				},
			});
		}
	});

	if (allCourses && !!allCourses.length) {
		allCourses.unshift({
			key: 0,
			text: translate(`ALL`),
			value: 0,
		});
	}

	const history = useHistory();

	const statisticsCourses = {
		...courseKpis,
	};

	const handleCreateSubmit = (
		id: number,
		courses: number[],
		start: Date | null,
		end: Date | null,
		send_email_date: Date | null,
	) => {
		dispatch({
			...addAssignment({
				requestType: 'POST',
				body: JSON.stringify({
					courses,
					start: start && format(start, 'yyyy-MM-dd'),
					end: end && format(end, 'yyyy-MM-dd'),
					send_email_date:
						send_email_date &&
						format(send_email_date, 'yyyy-MM-dd'),
				}),
				request: `smartometer/assignments/${id}`,
			}),
		});
		setAssignmentsModal(false);
	};

	const userDetailTabs = {
		courses: {
			title: translate('users.detail.courses'),
			component: (
				<UserCourses
					assignments={selectedUser?.assignments}
					setCourses={setCourses}
					trackings={selectedUser?.trackings}
					isLoading={isLoading}
				/>
			),
		},
		statistik: {
			title: translate('users.detail.statistics'),
			component: (
				<UserStatistics
					selectedCourse={courses}
					courses={kpis?.assignedCourses || []}
					kpis={statisticsCourses}
				/>
			),
		},
	};

	const handleSelectChange = (e: any, dropdown: DropdownProps) => {
		setCourses(dropdown.value);
	};

	const sendPasswordResetEmail = () => {
		setIsSubmitting(true);
		axios
			.post(`/forgot`, { email: selectedUser.email })
			.then(function (response: any) {
				setIsSubmitting(false);
				setResetPasswordModal(false);
			})
			.catch(function (error: any) {
				setIsSubmitting(false);
				setResetPasswordModal(false);
				console.log(error);
			});
	};

	return (
		<>
			<Subpage
				backButtonText={translate('users.detail.userOverview')}
				onClickBackButton={() => history.push('/users')}
			>
				{!!selectedUser && !!kpis ? (
					<>
						<div className={classes.UserHeader}>
							<Avatar
								src={selectedUser.avatar || '/defaultUser.png'}
							></Avatar>
							<div className={classes.UserInfo}>
								<h1>
									{selectedUser.first_name}{' '}
									{selectedUser.last_name}
								</h1>
								<div className={classes.UserInfoColumns}>
									<div>
										<p style={{ maxWidth: 450 }}>
											{translate('users.detail.group')}:{' '}
											{!!selectedUser &&
												!!groupData.data &&
												userGroup}
										</p>
										<p
											className="link"
											onClick={() => {
												setEditUserModal(true);
											}}
										>
											{translate(
												'users.detail.changeDetails',
											)}
										</p>
									</div>
									<div>
										<p>Email: {selectedUser.email}</p>
										<p
											className="link"
											onClick={() =>
												setResetPasswordModal(true)
											}
										>
											{translate(
												'users.detail.resetPassword',
											)}
										</p>
									</div>
								</div>
							</div>
							<div className={classes.ButtonWrapper}>
								<Button
									title={translate(
										'users.detail.assignCourse',
									)}
									type="solid"
									color={color.link}
									onClick={() => setAssignmentsModal(true)}
								/>
								<a href={'mailto:  ' + selectedUser.email}>
									<Button
										title={translate(
											'users.detail.writeEmail',
										)}
										type="outline"
										color={color.textDark}
										backgroundColor={color.white}
										borderColor={color.grey}
										onClick={() => {}}
										styles={{ marginTop: '12px' }}
									/>
								</a>
							</div>
						</div>
						<div className={classes.GridWrapper}>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4}>
										<ContentCard
											title={translate(
												'users.detail.readingTime',
											)}
											small
											helperText={translate(
												`kpis.userDetail.avgLearningTime`,
											)}
										>
											<ContentCardNumber
												value={
													averageLearningTimeAsString
												}
											/>
										</ContentCard>
									</Grid.Column>
									<Grid.Column width={4}>
										<ContentCard
											title={translate(
												'users.detail.results',
											)}
											small
											helperText={translate(
												`kpis.userDetail.avgSuccessRate`,
											)}
										>
											<ContentCardNumber
												value={
													(
														kpis.passedScore * 100
													).toFixed(0) + '%'
												}
											/>
										</ContentCard>
									</Grid.Column>
									<Grid.Column width={4}>
										<ContentCard
											title={translate(
												'users.detail.finishedCourses',
											)}
											small
											helperText={translate(
												`kpis.userDetail.finishedCourses`,
											)}
											helperTextRight
										>
											<div
												className={classes.BarContainer}
											>
												<DuoBar
													percentage={
														+(
															(kpis.passedCourses
																.length /
																kpis
																	.assignedCourses
																	.length) *
															100
														).toFixed(2)
													}
													leftTitle={`${
														kpis.passedCourses
															.length
													} ${translate(
														'users.detail.completed',
													)}`}
													rightTitle={`${
														kpis.assignedCourses
															.length
													} ${translate(
														'users.detail.allocated',
													)}`}
												/>
											</div>
										</ContentCard>
									</Grid.Column>
									<Grid.Column width={4}>
										<ContentCard
											title={translate(
												'users.detail.missedDeadlines',
											)}
											small
											helperText={translate(
												`kpis.userDetail.delays`,
											)}
											helperTextRight
										>
											<ContentCardNumber
												value={
													kpis.delayedAssignments
														.length
												}
											/>
										</ContentCard>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
						{allCourses &&
							history.location.pathname.includes('statistik') && (
								<Dropdown
									className={classes.Multiselect}
									name="courses"
									id="courses"
									onChange={handleSelectChange}
									value={courses}
									options={allCourses}
									placeholder={translate(
										'users.detail.selectCourse',
									)}
									selection
								/>
							)}
						<TabNavigator
							tabs={userDetailTabs}
							defaultActive="courses"
						/>
					</>
				) : (
					<LinearProgress />
				)}
			</Subpage>
			<AssignUserModal
				isLoading={isLoading}
				showModal={assignmentsModal}
				closeModal={() => setAssignmentsModal(false)}
				onSubmit={handleCreateSubmit}
				user={selectedUser}
				confirmText={translate('users.detail.addToCourse')}
				message={translate('users.detail.addUserToCourse')}
			/>
			<ConfirmModal
				additionalInfo={translate('users.detail.userWillRecieveEmail')}
				isLoading={isSubmitting}
				showModal={resetPasswordModal}
				closeModal={() => setResetPasswordModal(false)}
				onDelete={() => sendPasswordResetEmail()}
				confirmText={translate('users.detail.send')}
				message={`${translate('users.detail.sendPasswordEmail')} ${
					selectedUser.first_name
				} ${selectedUser.last_name}`}
			/>
			<EditUserDataModal
				ownProfile={false}
				onSave={() => {
					dispatch({
						...fetchUser({
							requestType: 'GET',
							request: `smartometer/users/${params.id}`,
						}),
					});
				}}
				openModal={editUserModal}
				closeModal={() => setEditUserModal(false)}
				userId={selectedUser.id}
			/>
		</>
	);
}
