export const addToQueue = async (key: string, data: any) => {
    try {
        const savedQueue = window.localStorage.getItem('queue');
        const currentQueue = savedQueue ? JSON.parse(savedQueue) : {};
        currentQueue[key] = data;
        window.localStorage.setItem('queue', JSON.stringify(currentQueue));
    } catch (e) {
        console.error(e);
    }

}

export const addTracking = async (data: any) => {
    const savedTracking = window.localStorage.getItem('tracking');
    const currentQueue = savedTracking ? JSON.parse(savedTracking) : [];
    currentQueue.push(data);
    window.localStorage.setItem('tracking', JSON.stringify(currentQueue));
}

export const addData = async (type:string, data: any) => {
    const savedTracking = window.localStorage.getItem(`data-${type}`);
    const currentQueue = savedTracking ? JSON.parse(savedTracking) : [];
    currentQueue.push(data);
    window.localStorage.setItem(`data-${type}`, JSON.stringify(currentQueue));
}

interface trackingInterface {
    learning_area_id: number,
    sub_area_id: number,
    learning_content_id: number,
    is_completed?: boolean,
    time_spent?: number
}

export const getTracking = async (data: any) => {
    const allTrackings = window.localStorage.getItem('tracking');
    const trackings = allTrackings ? JSON.parse(allTrackings) : [];

    return trackings.filter( (tracking: any) => {
        const params = Object.keys(data);
        let match = true;
        for (const paramsKey of params) {
            if(tracking[paramsKey] !== data[paramsKey]) {
                match = false;
            }
        }
        return match;
    })
    return window.localStorage.getItem('tracking');
}

export const getData = async (type:string, data: any) => {
    const allDataRaw = window.localStorage.getItem(`data-${type}`);
    const allData = allDataRaw ? JSON.parse(allDataRaw) : [];

    return allData.filter( (record: any) => {
        const params = Object.keys(data);
        let match = true;
        for (const paramsKey of params) {
            if(record[paramsKey] !== data[paramsKey]) {
                match = false;
            }
        }
        return match;
    })
    return window.localStorage.getItem(`data-${type}`);
}

export const getFromQueue = async () => {
    const savedQueue = window.localStorage.getItem('queue');
    const currentQueue = savedQueue ? JSON.parse(savedQueue) : [];
    if (currentQueue.length > 0) {
        const firstItem = currentQueue.shift();
        window.localStorage.setItem('queue', JSON.stringify(currentQueue));
        return firstItem;
    }
    return null;
}

export const getFromQueueByKey = async (key: string) => {
    const savedQueue = window.localStorage.getItem('queue');
    const currentQueue = savedQueue ? JSON.parse(savedQueue) : {};
    if (currentQueue[key]) {
        const currentItem = currentQueue[key];

        window.localStorage.setItem('queue', JSON.stringify(currentQueue));
        return currentItem;
    }
    return null;
}
