import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import SplashScreenImg from '../../../assets/images/splashscreen.png';

interface AppSplashProps {
	setShow: Function;
}

const AppStyles = makeStyles(() => ({
	Wrapper: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundImage: `url(${SplashScreenImg})`,
		zIndex: 100,
		transition: '.5s',
		opacity: 1,

		'&.hiding': {
			opacity: 0,
		},
	},
}));

const AppSplash: React.FunctionComponent<AppSplashProps> = ({ setShow }) => {
	const [isHiding, setIsHiding] = useState(false);
	const classes = AppStyles();

	useEffect(() => {
		setTimeout(() => setIsHiding(true), 2000);
	}, []);

	useEffect(() => {
		if (isHiding) {
			setTimeout(() => setShow(false), 500);
		}
	}, [isHiding]);

	return (
		<div className={`${classes.Wrapper} ${isHiding ? 'hiding' : ''}`}></div>
	);
};

export default AppSplash;
