import React, {useRef} from "react";
import {DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";
import TextField from "@material-ui/core/TextField";
import {Delete} from "@material-ui/icons";
import {useStyles} from "../styles";
import {translate} from '../../../../../i18n/i18n';


export interface SingleWordProps {
    id: any
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (event: any) => void,
    editAnswer?: number,
    editError?: boolean,
    answer: any,
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void,
    value?: string,
    handleDelete?: (eindex: number) => void,
}


export const SingleWord: React.FunctionComponent<SingleWordProps> = ({
                                                                         index,
                                                                         moveCard,
                                                                         editAnswer,
                                                                         editError,
                                                                         answer,
                                                                         value,
                                                                         onChange,
                                                                         onBlur,
                                                                         onClick,
                                                                         handleDelete
                                                                     }) => {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    interface DragItem {
        index: number
        id: string
        type: string
    }

    const [{handlerId}, drop] = useDrop({
        accept: "CARD",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [, drag] = useDrag({
        type: "CARD",
        item: () => {
            return {id: answer.id, index}
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    return (<div ref={ref} style={{display: "flex", alignItems: "center"}}
                 data-handler-id={handlerId}>
            <li className={classes.ListItem}>
                <span className={classes.ListNumber}>{index + 1}</span>
                {editAnswer !== index &&
                <span onClick={onClick} style={{fontSize: 16}}> {answer.text} </span>}
                {editAnswer === index &&
                <TextField autoFocus={true} error={editError} helperText={editError && translate('authoring.orderWordsSingle.abort')}
                           id="standard-basic" value={value} onChange={onChange} onBlur={onBlur}/>}
                {handleDelete && <span className={classes.deleteWrapper} onClick={() => {
                    handleDelete(index)
                }}> <Delete style={{width: 18, marginLeft: 10}}/>
                </span>}

            </li>
        </div>
    )


}
