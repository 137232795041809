import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface initialStateInterface {
	data: any;
	filteredData: any;
	isLoading: boolean;
	isLoadingFiltered: boolean;
	initiallyLoaded: boolean;
	error: string;
}

const initialState: initialStateInterface = {
	data: null,
	filteredData: null,
	isLoading: false,
	isLoadingFiltered: false,
	initiallyLoaded: false,
	error: '',
};

export const resultSlice = createSlice({
	name: 'result',
	initialState,
	reducers: {
		dashboard: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished' && !!action.response) {
				state.data = action.response;
				if (!state.filteredData) state.filteredData = action.response;
                state.initiallyLoaded = true;
				state.isLoading = false;
			}
		},
		filteredDashboard: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoadingFiltered = true;
			}
			if (action.state === 'finished' && !!action.response) {
				state.filteredData = action.response;
				state.isLoadingFiltered = false;
				state.initiallyLoaded = true;
			}
		},
	},
});

export const { dashboard, filteredDashboard } = resultSlice.actions;
export const resultSelector = (state: RootState) => state.result;
export default resultSlice.reducer;
