import { makeStyles } from "@material-ui/core/styles";
import {textSizes, color} from "../../../utils/styles"

export const useStyles = makeStyles(theme => ({
    Input: {
        width: "100%",
        position: "relative",
        paddingBottom: "20px",

        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "0"
        }

    },
    InputWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    InputDemi: {
        width: "calc(50% - 12.5px)",
        marginBottom: "5px"
    },
    Note: {
        fontSize: textSizes.notice,
        color: color.textGrey
    }
}))