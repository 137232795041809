import React from "react";
import { useStyles } from "./style";

interface TableActionProps{
    title: string;
    handleClick: React.MouseEventHandler;
}

const TableButton: React.FunctionComponent<TableActionProps> = ({ title, handleClick }) => {
    const classes = useStyles();
    return <div className={classes.Button} onClick={handleClick}>{title}</div>
}

export default TableButton;