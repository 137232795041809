import React from 'react';
import { useStyles } from './styles';

interface DuoBarProps {
	percentage: number;
	leftTitle: string;
	rightTitle: string;
}

const DuoBar: React.FunctionComponent<DuoBarProps> = ({
	leftTitle,
	rightTitle,
	percentage,
}) => {
	const classes = useStyles();
	return (
		<div className={classes.Container}>
			<div className={classes.Values}>
				<span>{leftTitle}</span>
				<span>{rightTitle}</span>
			</div>
			<div className={classes.Chart}>
				<div
					style={{ width: percentage + '%' }}
					className={classes.Progress}
				></div>
			</div>
		</div>
	);
};

export default DuoBar;
